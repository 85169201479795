import { useTranslation } from "@onefront/react-sdk";

import {
  faPause,
  faSpinnerThird,
  faCheck,
  faXmark,
  faCircle,
  faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTheme } from "@vapor/react-material/styles";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";
import Stepper from "@vapor/react-material/Stepper";
import Step from "@vapor/react-material/Step";
import StepLabel from "@vapor/react-material/StepLabel";
import StepContent from "@vapor/react-material/StepContent";
import { useTelematico } from "../../providers/telematicoDataProvider";

const StepIconComponent = ({ stepStatusIconStyles, stepName }) => {
  const { steps } = useTelematico();
  const theme = useTheme();
  return (
    <span className="fa-layers" style={{ width: "30px" }}>
      <FontAwesomeIcon
        icon={faCircle}
        fontSize="24px"
        color={stepStatusIconStyles[steps[stepName].status].backgroundColor}
      />
      <FontAwesomeIcon
        icon={stepStatusIconStyles[steps[stepName].status].icon}
        color={theme.palette.background.default}
        fontSize="12px"
      />
    </span>
  );
};

export const TaxReportPageTelematicoCardContent = () => {
  const theme = useTheme();
  const { steps } = useTelematico();
  const { t } = useTranslation("foa");

  const stepsOrder = ["validation", "sending"];

  const stepStatusIconStyles = {
    todo: {
      icon: faPause,
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.text.disabled
    },
    pending: {
      icon: faSpinnerThird,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.interactiveDefault
    },
    waiting: {
      icon: faSpinnerThird,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.interactiveDefault
    },
    done: {
      icon: faCheck,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.iconSuccess
    },
    inError: {
      icon: faXmark,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.iconCritical
    },
    warning: {
      icon: faTriangleExclamation,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.iconWarning
    }
  };

  const stepStatusLabels = {
    validation: {
      todo: "telematicoValidation",
      pending: "telematicoPendingValidation",
      inError: "telematicoValidationFailed",
      done: "telematicoValidationComplete",
      warning: "telematicoErrorValidation"
    },
    sending: {
      todo: "telematicoSending",
      waiting: "telematicoWaitingResponse",
      pending: "telematicoPendingSending",
      inError: "declarationRejected",
      done: "declarationSuccessfullyAccepted",
      warning: "telematicoSendingAttemptFailed"
    }
  };

  return (
    <Stack
      sx={{ padding: "16px 32px" }}
      alignItems="flex-start"
      gap="8px"
      alignSelf="stretch"
      direction="column"
    >
      <Stack direction="row" gap="3px" alignItems="center">
        <Typography variant="bodyLarge">{t("lastCheckDoneOn")}:</Typography>
        <Typography variant="bodyLarge700">oggi alle 15:17</Typography>
      </Stack>
      <Stepper orientation="vertical">
        {stepsOrder.map((stepName, index) => {
          return (
            <Step key={index} expanded={true}>
              <StepLabel
                sx={{
                  ".MuiStepLabel-label": {
                    fontSize: "24px",
                    fontWeight: 600,
                    lineHeight: "32px",
                    color: `${
                      stepStatusIconStyles[steps[stepName].status].color
                    } !important`
                  }
                }}
                StepIconComponent={() => (
                  <StepIconComponent
                    stepStatusIconStyles={stepStatusIconStyles}
                    stepName={stepName}
                  />
                )}
              >
                {t(stepStatusLabels[stepName][steps[stepName].status])}
              </StepLabel>
              {steps[stepName].status !== "todo" && (
                <StepContent>
                  <Stack direction="row" gap="5px" alignItems="center">
                    <Typography variant="bodyLarge">
                      {t("telematicoUpdatedOn")}:
                    </Typography>
                    <Typography variant="bodyLarge700">
                      {new Date(steps[stepName].timestamp).toLocaleString()}
                    </Typography>
                  </Stack>
                </StepContent>
              )}
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
};

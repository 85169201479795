import { useState } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { useTheme } from "@vapor/react-material/styles";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import Grid from "@vapor/react-material/Grid";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExtendedTextfield } from "@onefront/workspace-forms/components/General/ExtendedTextfield/ExtendedTextfield.js";
import { ExtendedDatePicker } from "@onefront/workspace-forms/components/General/ExtendedDatePicker/ExtendedDatePicker.js";
import { AmountTextfield } from "@onefront/workspace-forms/components/General/AmountTextfield/AmountTextfield.js";

import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";

export const TenantIVAControlledCompany = ({ settings, onChange }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { loading, save, handleOpenDialog, anyChange } =
    useCompanySettingsContext();
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      gap="24px"
      sx={{
        border: `solid 1px ${theme.palette.primary.interactiveDefault}`,
        padding: "16px",
        borderRadius: "10px",
        width: "100%"
      }}
    >
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
        onClick={() => setIsEditMode(true)}
        sx={{ alignSelf: "flex-end" }}
      />
      <Grid container justifyContent="space-between" spacing={4}>
        <Grid item xs={6}>
          <ExtendedTextfield
            label={t("id")}
            noValueLabel={t("notSet")}
            value={settings.id}
            editing={isEditMode}
            name="id"
            onChange={onChange}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedTextfield
            label={t("nameSociety")}
            noValueLabel={t("notSet")}
            value={settings.name}
            editing={isEditMode}
            name="name"
            onChange={onChange}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedDatePicker
            name="startDate"
            noValueLabel={t("notSet")}
            editing={isEditMode}
            value={settings.startDate}
            onChange={(name, value) =>
              onChange({
                target: { name, value: value.replace("T00:00:00", "") }
              })
            }
            label={t("period")}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedDatePicker
            name="endDate"
            noValueLabel={t("notSet")}
            editing={isEditMode}
            value={settings.endDate}
            onChange={(name, value) =>
              onChange({
                target: { name, value: value.replace("T00:00:00", "") }
              })
            }
            label={t("endDate")}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <AmountTextfield
            label={t("moduleNumber")}
            name="moduleNumber"
            value={settings.module}
            onValueChange={(name, value) => {
              onChange({ target: { name, value } });
            }}
            editing={isEditMode}
            noValueLabel={t("notSet")}
            loading={loading}
          />
        </Grid>
      </Grid>
      {isEditMode && (
        <Stack direction="row" alignItems="center" gap="16px">
          <Button
            variant="contained"
            onClick={() => {
              save();
              setIsEditMode(false);
            }}
          >
            {t("save")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              if (anyChange) handleOpenDialog();
              else setIsEditMode(false);
            }}
          >
            {t("cancel")}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

import { useQuery } from "@onefront/react-sdk";

const GET_KPI_DUE_DATES = `query GetKpiDueDates {
  tax_reports_public_kpi_due_dates {
      tenant_id
      period
      year
      total
      deadline
      tax_report
    }
  }`;

export const useGetKpiDueDates = ({ tenantId }) => {
  return useQuery(
    GET_KPI_DUE_DATES,
    {},
    {
      headers: {
        "tenant-id": tenantId
      },
      lazy: true
    }
  );
};

// This file contains the onChangeHandlerField function that is used to update the context state when a field is changed in the form.
// The shape of every strategy shold be an object with the following properties:
// - match: a function that receives the target element and returns a boolean indicating if the strategy should be applied
// - getValue: a function that receives the target element and the field value and returns the new value to be set in the context
// The strategies are applied in order, so the default strategy should always be the last one.
const customCleanFieldValueStrategies = [
  {
    match: (target) => target.name === "startYearFullExentLosses",
    getValue: (target) => new Date(target.value).getFullYear()
  }
];

const baseCleanFieldValueStrategies = [
  {
    match: (target) => target.type === "checkbox",
    getValue: (target) => target.checked
  },
  {
    match: (target) => target.type === "number",
    getValue: (target) => Number(target.value)
  },
  {
    match: (target) => true, //default, always at end of array
    getValue: (target) => target.value
  }
];

const cleanFieldValue = (target) => {
  const strategies = [
    ...customCleanFieldValueStrategies,
    ...baseCleanFieldValueStrategies
  ];

  return strategies.find((strategy) => strategy.match(target)).getValue(target);
};

export const onChangeHandlerField = ({
  target,
  context,
  setContext,
  array = null
}) => {
  const isSimpleLevel = target.name.indexOf(".") === -1;
  if (array) {
    // Copy the desired object from the array and update the context
    const newArray = [...context[array.name]];
    if (!newArray.length) newArray[0] = {};

    if (isSimpleLevel)
      newArray[array.index][target.name] = cleanFieldValue(target);
    else {
      const names = target.name.split(".");
      newArray[array.index][names[0]][names[1]] = cleanFieldValue(target);
    }
    setContext({
      ...context,
      [array.name]: newArray
    });
  } else {
    if (!isSimpleLevel) {
      // We're modifying the property of an object field. Only works for first level object
      const names = target.name.split(".");
      setContext({
        ...context,
        [names[0]]: {
          ...context[names[0]],
          [names[1]]: cleanFieldValue(target)
        }
      });
    } else {
      // Normal field
      setContext({
        ...context,
        [target.name]: cleanFieldValue(target)
      });
    }
  }
};

import Box from "@vapor/react-material/Box";

import { ReportDataTableSectionContent } from "./ReportDataTableSectionContent";
import { ReportDataTableSectionHeader } from "./ReportDataTableSectionHeader";
import { useReportPageData } from "../../providers/reportPageDataProvider";

const boxSection = {
  width: "100%",
  marginBottom: "28px"
};

export const ReportDataTableSection = ({ source, fieldsToShow }) => {
  const { menuItemsRef } = useReportPageData();

  return (
    <Box sx={boxSection} ref={(el) => (menuItemsRef.current[source] = el)}>
      <ReportDataTableSectionHeader
        source={source}
      ></ReportDataTableSectionHeader>
      <ReportDataTableSectionContent
        fieldsToShow={fieldsToShow}
      ></ReportDataTableSectionContent>
    </Box>
  );
};

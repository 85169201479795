import { useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { ExtendedTextfield } from "@onefront/workspace-forms/components/General/ExtendedTextfield/ExtendedTextfield.js";

import settings from "../../../fixtures/company-settings.json";
import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { useWorkspaceData } from "../../../providers/WorkspaceDataProvider";
import { onChangeHandlerField } from "../../../handlers";
import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import { ExtendedToggle } from "../../ExtendedToggle";

export const CommonDataDeclarant = () => {
  const { t } = useTranslation();
  const {
    companySettings,
    setCompanySettings,
    sotCompanySettings,
    isEditMode,
    setIsEditMode,
    loading,
    setAnyChange
  } = useCompanySettingsContext();
  const { taxPayerType } = useWorkspaceData();

  const onChangeHandler = (e) => {
    setAnyChange(true);
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  return (
    <CompanySettingsGrid>
      {/* It seems that the following field is not in the mapping, but 
      the moment we keep as comment */}
      {/* {settings.commonData.commonDataDeclarant.complianceVisa &&
        settings.commonData.commonDataDeclarant.complianceVisa.appliesTo.includes(
          taxPayerType
        ) && (
          <TextField
            type="string"
            id="complianceVisa"
            label={t("complianceVisa")}
            name="complianceVisa"
            value={companySettings.complianceVisa}
            onChange={onChangeHandler}
          ></TextField>
        )} */}
      {settings.commonData.commonDataDeclarant.declarantSameAsRepresentative &&
        settings.commonData.commonDataDeclarant.declarantSameAsRepresentative.appliesTo.includes(
          taxPayerType
        ) && (
          <ExtendedToggle
            name="declarantSameAsRepresentative"
            value={companySettings.declarantSameAsRepresentative}
            onChange={onChangeHandler}
            label={t("declarantSameAsRepresentative")}
            editing={isEditMode}
            noValueLabel={t("notSet")}
            loading={loading}
            positiveLabel={t("checkboxPositive")}
            negativeLabel={t("checkboxNegative")}
          />
        )}
      {settings.commonData.commonDataDeclarant.declarantCompanyTaxCode &&
        settings.commonData.commonDataDeclarant.declarantCompanyTaxCode.appliesTo.includes(
          taxPayerType
        ) && (
          <ExtendedTextfield
            name="declarantCompanyTaxCode"
            value={companySettings.declarantCompanyTaxCode}
            onChange={onChangeHandler}
            label={t("declarantCompanyTaxCode")}
            editing={isEditMode}
            noValueLabel={t("notSet")}
            loading={loading}
          />
        )}
    </CompanySettingsGrid>
  );
};

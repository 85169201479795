import { useRef } from "react";
import { useGraphql } from "../../../hooks/use-graphql";
import { useTenantSwitcher } from "../../use-tenant-switcher";

export const useComet = (
  options = {
    query: "",
    cursorField: "",
    initialCursor: "",
    itemTTL: 0,
    rescheduleDelay: 0,
    errorMultiplier: 0,
    maxDelay: 0,
    maxItems: 2,
    onChange: (nextItems, nextCursor, currItems, prevItems, prevCursor) => {}
  }
) => {
  // Default options
  const rescheduleDelay = options.rescheduleDelay || 2000;
  const errorMultiplier = options.errorMultiplier || 1.5;
  const maxDelay = options.maxDelay || 15000;
  const maxItems = options.maxItems || 100;
  const itemTTL = options.itemTTL || 300000;
  const initialCursor = options.initialCursor || "";
  const onChange = options.onChange || (() => {});

  // Internal state management
  const timersRef = useRef({});
  const delayRef = useRef(rescheduleDelay);
  const cursorRef = useRef(initialCursor);
  const itemsRef = useRef([]);
  const cursorPerTenantRef = useRef({});
  const itemsPerTenantRef = useRef({});
  const pollingHasToStop = useRef(false);

  const lastTenant = useRef();

  const cursorsRef = useRef([]);
  const { query } = useGraphql();

  const onTenantSwitch = () => {
    if (lastTenant.current) {
      cursorPerTenantRef.current[lastTenant.current] = cursorRef.current;
      itemsPerTenantRef.current[lastTenant.current] = itemsRef.current;
      clearTimeout(timersRef.current[lastTenant.current]);
    }
    itemsRef.current = itemsPerTenantRef.current[tenant.id] || [];
    cursorsRef.current = cursorPerTenantRef.current[tenant.id] || initialCursor;
    lastTenant.current = tenant.id;
    //loop();
  };
  const { tenant, tenantIdHttpHeader } = useTenantSwitcher({
    onTenantSwitch
  });

  const dropOldItems = ({ created_at }) =>
    new Date() - new Date(created_at) < itemTTL;

  const mergeItems = (curr, next) => {
    // Filter out old items:
    const items = [...curr.filter(dropOldItems), ...next.filter(dropOldItems)];

    // Apply max amount of items to keep:
    if (items.length > maxItems) {
      items.splice(0, items.length - maxItems);
    }
    return items;
  };

  const loop = async () => {
    try {
      const items = (
        await query(
          options.query,
          {
            cursor: cursorRef.current
          },
          tenantIdHttpHeader
        )
      ).data.comet.items;

      if (items.length) {
        // Calculate next state:
        const nextCursor = items[items.length - 1][options.cursorField];
        const nextItems = mergeItems(itemsRef.current, items);

        // Trigger state update callback:
        if (cursorRef.current !== nextCursor) {
          onChange(
            nextItems,
            nextCursor,
            items,
            itemsRef.current,
            cursorRef.current
          );
        }

        // Update internal state:
        cursorRef.current = nextCursor;
        itemsRef.current = nextItems;
        cursorsRef.current = nextItems.map((item) => item[options.cursorField]);
      }

      // Reset next retry interval after asuccessfull request
      delayRef.current = rescheduleDelay;
    } catch (err) {
      console.error("@Comet::loop", err);
      // Slow down requests up to a max restry interval
      delayRef.current = delayRef.current * errorMultiplier;
      if (delayRef.current > maxDelay) {
        delayRef.current = maxDelay;
      }
    }

    // console.log("@comet::nextLoop in", delayRef.current);
    timersRef.current[tenant.id] = setTimeout(() => {
      if (!pollingHasToStop.current) loop();
    }, delayRef.current);
  };

  const startPolling = () => {
    pollingHasToStop.current = false;
    loop();
  };

  const stopPolling = () => {
    pollingHasToStop.current = true;
  };

  return {
    itemsRef,
    cursorsRef,
    cursorRef,
    startPolling,
    stopPolling
  };
};

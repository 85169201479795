import { useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { ExtendedTextfield } from "@onefront/workspace-forms/components/General/ExtendedTextfield/ExtendedTextfield.js";
import { AmountTextfield } from "@onefront/workspace-forms/components/General/AmountTextfield/AmountTextfield.js";
import { ExtendedSelect } from "@onefront/workspace-forms/components/General/ExtendedSelect/ExtendedSelect.js";
import { ExtendedMenuItem } from "@onefront/workspace-forms/components/General/ExtendedMenuItem/ExtendedMenuItem.js";
import { ExtendedDatePicker } from "@onefront/workspace-forms/components/General/ExtendedDatePicker/ExtendedDatePicker.js";

import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { onChangeHandlerField } from "../../../handlers";

export const TenantPFAdditionalData = () => {
  const { t } = useTranslation();
  const {
    sotCompanySettings,
    companySettings,
    setCompanySettings,
    isEditMode,
    setIsEditMode,
    loading,
    setAnyChange
  } = useCompanySettingsContext();

  const onChangeHandler = (e) => {
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  return (
    <CompanySettingsGrid>
      <ExtendedTextfield
        label={t("contributorAssistantData")}
        noValueLabel={t("notSet")}
        value={companySettings.contributorAssistantData}
        editing={isEditMode}
        name="contributorAssistantData"
        onChange={onChangeHandler}
        loading={loading}
      />
      <AmountTextfield
        label={t("participationShare")}
        name="participationShare"
        value={companySettings.participationShare}
        onValueChange={(name, value) => {
          onChangeHandler({ target: { name, value } });
        }}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
      />
      <AmountTextfield
        label={t("months")}
        name="months"
        value={companySettings.months}
        onValueChange={(name, value) => {
          onChangeHandler({ target: { name, value } });
        }}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
      />
      <ExtendedSelect
        label={t("participationType")}
        name="participationType"
        value={companySettings.participationType}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem label={t("familyBusiness")} value="F">
          {t("familyBusiness")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("maritalBusiness")} value="C">
          {t("maritalBusiness")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("ContributorAssistant")} value="X">
          {t("ContributorAssistant")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <ExtendedDatePicker
        name="participationStartDate"
        noValueLabel={t("notSet")}
        editing={isEditMode}
        value={companySettings.participationStartDate}
        onChange={(name, value) =>
          onChangeHandler({
            target: { name, value: value.replace("T00:00:00", "") }
          })
        }
        label={t("participationStartDate")}
        loading={loading}
      />
      <ExtendedDatePicker
        name="participationEndDate"
        noValueLabel={t("notSet")}
        editing={isEditMode}
        value={companySettings.participationEndDate}
        onChange={(name, value) =>
          onChangeHandler({
            target: { name, value: value.replace("T00:00:00", "") }
          })
        }
        label={t("participationEndDate")}
        loading={loading}
      />
    </CompanySettingsGrid>
  );
};

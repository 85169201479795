import { forwardRef } from "react";

import { useTranslation } from "@onefront/react-sdk";

import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";

/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"; */

import { formatAmount, formatDate } from "../../../../utils";
import TaxReportPageAccordion from "../TaxReportPageAccordion";

const TaxReportPagePayments = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { year, checksData } = props;

  return (
    <TaxReportPageAccordion
      ref={ref}
      title={t("paymentDoneYear", { year: year - 1 })}
      subtitle={t("lastChangeDateTemplate", {
        date: formatDate(checksData?.lastUpdate),
        interpolation: { escapeValue: false }
      })}
    >
      <Typography variant="bodyLarge700">{t("repentances")}</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="bodyLarge">
          {/* TODO: Date pending from analysts*/}
          28/02/{year} : {t("taxCode6033")} - {t("amountPaidBack")}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="bodyLarge700">
            {formatAmount(checksData?.taxCode6033, 2, 2) || " 0,00"}
          </Typography>
          {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
        </Stack>
      </Stack>
    </TaxReportPageAccordion>
  );
});

export default TaxReportPagePayments;

import { useGetConfig, usePost, useGetContext } from "@onefront/react-sdk";

import {
  GraphqlAccessDeniedError,
  GraphqlError
} from "../../../hooks/use-graphql";

const GET_COMPANY_SETTINGS = `query GetCompanySettings($taxpayer_id: String = "") {
        company_data_public_entries(where: {taxpayer_id: {_eq: $taxpayer_id}}) {
          document
          taxpayer_id
          updated_at
          uuid,
          version
        }
      }`;

export const useGetCompanySettings = () => {
  const tenantIdHttpHeader = useGetConfig("constants.headers.tenantId");
  const defaultEndpoint = useGetContext("one.rest.graphql.baseUrl");

  const {
    data,
    fetch: postFetch,
    ...request
  } = usePost(
    defaultEndpoint,
    {
      query: GET_COMPANY_SETTINGS
    },
    {
      lazy: true,
      stateless: false,
      onResponse: (response) => {
        if (response.data.errors && response.data.errors.length) {
          const [error] = response.data.errors;

          // Detect an AccessError
          // (will trigger implicit retry)
          if (error.extensions.code === "access-denied") {
            throw new GraphqlAccessDeniedError(error.message, response);
          }

          // Generic GraphQL Error
          throw new GraphqlError(error.message, response);
        }
      },
      onError: (error) => error instanceof GraphqlAccessDeniedError
    }
  );

  const fetch = ({ taxpayerId, tenantId }) =>
    postFetch({
      data: {
        query: GET_COMPANY_SETTINGS,
        variables: { taxpayer_id: taxpayerId }
      },
      headers: {
        [tenantIdHttpHeader]: tenantId
      }
    });

  return {
    ...request,
    fetch,
    data: data && data.data ? data.data : null
  };
};

import { useTranslation } from "@onefront/react-sdk";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import Stack from "@vapor/react-material/Stack";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import Toggle from "@vapor/react-material/Toggle";
import Button from "@vapor/react-material/Button";
import { useTheme } from "@vapor/react-material/styles";

import { SearchFilter } from "../../../tax-reports-list/components/tax-reports-list-filter/SearchFilter";
import { useReportPageData } from "../../providers/reportPageDataProvider";
import { ReportDataOtherFilters } from "./ReportDataOtherFilters";

export const ReportDataFilters = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { filters, setFilters, setShowFilters, showFilters } =
    useReportPageData();

  const [isReady, setIsReady] = useState(false);
  const [defaultOrderBySourceChecked, setDefaultOrderBySourceChecked] =
    useState(null);

  useEffect(() => {
    if (!isReady && filters !== undefined) {
      setIsReady(true);
      setDefaultOrderBySourceChecked(filters?.filteredBySource);
    }
  }, [filters]);

  const handleToggleFilters = () => {
    setShowFilters((prevShow) => !prevShow);
  };

  if (!isReady) return null;

  return (
    <>
      <Stack direction="row" alignItems="flex-end" spacing={2}>
        <SearchFilter
          label={t("Search")}
          placeholder={null}
          direction={"column"}
          initialValue={filters?.search ?? null}
          onSearch={(newValue) => {
            setFilters({ ...filters, search: newValue });
          }}
        />
        <Button
          startIcon={
            <FontAwesomeIcon icon={showFilters ? faAngleUp : faAngleDown} />
          }
          onClick={handleToggleFilters}
          style={{
            background: theme.palette.primary.white,
            border: "transparent",
            color: theme.palette.primary.interactiveDefault,
            fon: "Roboto",
            fontSize: "14px !important"
          }}
        >
          {t("OtherFilters")}
        </Button>
        <FormControlLabel
          control={
            <Toggle
              defaultChecked={defaultOrderBySourceChecked}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  filteredBySource: e.target.checked
                });
              }}
            />
          }
          label={t("filteredBySource")}
        />
      </Stack>
      <ReportDataOtherFilters />
    </>
  );
};

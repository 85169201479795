const provinces = [
  {
    code: "1",
    regionCode: "1",
    label: "TORINO",
    value: "TO"
  },
  {
    code: "2",
    regionCode: "1",
    label: "VERCELLI",
    value: "VC"
  },
  {
    code: "3",
    regionCode: "1",
    label: "NOVARA",
    value: "NO"
  },
  {
    code: "4",
    regionCode: "1",
    label: "CUNEO",
    value: "CN"
  },
  {
    code: "5",
    regionCode: "1",
    label: "ASTI",
    value: "AT"
  },
  {
    code: "6",
    regionCode: "1",
    label: "ALESSANDRIA",
    value: "AL"
  },
  {
    code: "7",
    regionCode: "1",
    label: "BIELLA",
    value: "BI"
  },
  {
    code: "8",
    regionCode: "1",
    label: "VERBANO CUSIO OSSOLA",
    value: "VB"
  },
  {
    code: "9",
    regionCode: "2",
    label: "AOSTA",
    value: "AO"
  },
  {
    code: "10",
    regionCode: "3",
    label: "VARESE",
    value: "VA"
  },
  {
    code: "11",
    regionCode: "3",
    label: "COMO",
    value: "CO"
  },
  {
    code: "12",
    regionCode: "3",
    label: "SONDRIO",
    value: "SO"
  },
  {
    code: "13",
    regionCode: "3",
    label: "MILANO",
    value: "MI"
  },
  {
    code: "14",
    regionCode: "3",
    label: "BERGAMO",
    value: "BG"
  },
  {
    code: "15",
    regionCode: "3",
    label: "BRESCIA",
    value: "BS"
  },
  {
    code: "16",
    regionCode: "3",
    label: "PAVIA",
    value: "PV"
  },
  {
    code: "17",
    regionCode: "3",
    label: "CREMONA",
    value: "CR"
  },
  {
    code: "18",
    regionCode: "3",
    label: "MANTOVA",
    value: "MN"
  },
  {
    code: "19",
    regionCode: "3",
    label: "LECCO",
    value: "LC"
  },
  {
    code: "20",
    regionCode: "3",
    label: "LODI",
    value: "LO"
  },
  {
    code: "21",
    regionCode: "4",
    label: "BOLZANO",
    value: "BZ"
  },
  {
    code: "22",
    regionCode: "4",
    label: "TRENTO",
    value: "TN"
  },
  {
    code: "23",
    regionCode: "5",
    label: "VERONA",
    value: "VR"
  },
  {
    code: "24",
    regionCode: "5",
    label: "VICENZA",
    value: "VI"
  },
  {
    code: "25",
    regionCode: "5",
    label: "BELLUNO",
    value: "BL"
  },
  {
    code: "26",
    regionCode: "5",
    label: "TREVISO",
    value: "TV"
  },
  {
    code: "27",
    regionCode: "5",
    label: "VENEZIA",
    value: "VE"
  },
  {
    code: "28",
    regionCode: "5",
    label: "PADOVA",
    value: "PD"
  },
  {
    code: "29",
    regionCode: "5",
    label: "ROVIGO",
    value: "RO"
  },
  {
    code: "30",
    regionCode: "6",
    label: "UDINE",
    value: "UD"
  },
  {
    code: "31",
    regionCode: "6",
    label: "GORIZIA",
    value: "GO"
  },
  {
    code: "32",
    regionCode: "6",
    label: "TRIESTE",
    value: "TS"
  },
  {
    code: "33",
    regionCode: "6",
    label: "PORDENONE",
    value: "PN"
  },
  {
    code: "34",
    regionCode: "7",
    label: "IMPERIA",
    value: "IM"
  },
  {
    code: "35",
    regionCode: "7",
    label: "SAVONA",
    value: "SV"
  },
  {
    code: "36",
    regionCode: "7",
    label: "GENOVA",
    value: "GE"
  },
  {
    code: "37",
    regionCode: "7",
    label: "LA SPEZIA",
    value: "SP"
  },
  {
    code: "38",
    regionCode: "8",
    label: "PIACENZA",
    value: "PC"
  },
  {
    code: "39",
    regionCode: "8",
    label: "PARMA",
    value: "PR"
  },
  {
    code: "40",
    regionCode: "8",
    label: "REGGIO EMILIA",
    value: "RE"
  },
  {
    code: "41",
    regionCode: "8",
    label: "MODENA",
    value: "MO"
  },
  {
    code: "42",
    regionCode: "8",
    label: "BOLOGNA",
    value: "BO"
  },
  {
    code: "43",
    regionCode: "8",
    label: "FERRARA",
    value: "FE"
  },
  {
    code: "44",
    regionCode: "8",
    label: "RAVENNA",
    value: "RA"
  },
  {
    code: "45",
    regionCode: "8",
    label: "FORLI CESENA",
    value: "FC"
  },
  {
    code: "46",
    regionCode: "8",
    label: "RIMINI",
    value: "RN"
  },
  {
    code: "47",
    regionCode: "9",
    label: "MASSA CARRARA",
    value: "MS"
  },
  {
    code: "48",
    regionCode: "9",
    label: "LUCCA",
    value: "LU"
  },
  {
    code: "49",
    regionCode: "9",
    label: "PISTOIA",
    value: "PT"
  },
  {
    code: "50",
    regionCode: "9",
    label: "FIRENZE",
    value: "FI"
  },
  {
    code: "51",
    regionCode: "9",
    label: "LIVORNO",
    value: "LI"
  },
  {
    code: "52",
    regionCode: "9",
    label: "PISA",
    value: "PI"
  },
  {
    code: "53",
    regionCode: "9",
    label: "AREZZO",
    value: "AR"
  },
  {
    code: "54",
    regionCode: "9",
    label: "SIENA",
    value: "SI"
  },
  {
    code: "55",
    regionCode: "9",
    label: "GROSSETO",
    value: "GR"
  },
  {
    code: "56",
    regionCode: "9",
    label: "PRATO",
    value: "PO"
  },
  {
    code: "57",
    regionCode: "10",
    label: "PERUGIA",
    value: "PG"
  },
  {
    code: "58",
    regionCode: "10",
    label: "TERNI",
    value: "TR"
  },
  {
    code: "59",
    regionCode: "11",
    label: "PESARO E URBINO",
    value: "PU"
  },
  {
    code: "60",
    regionCode: "11",
    label: "ANCONA",
    value: "AN"
  },
  {
    code: "61",
    regionCode: "11",
    label: "MACERATA",
    value: "MC"
  },
  {
    code: "62",
    regionCode: "11",
    label: "ASCOLI PICENO",
    value: "AP"
  },
  {
    code: "63",
    regionCode: "12",
    label: "VITERBO",
    value: "VT"
  },
  {
    code: "64",
    regionCode: "12",
    label: "RIETI",
    value: "RI"
  },
  {
    code: "65",
    regionCode: "12",
    label: "ROMA",
    value: "RM"
  },
  {
    code: "66",
    regionCode: "12",
    label: "LATINA",
    value: "LT"
  },
  {
    code: "67",
    regionCode: "12",
    label: "FROSINONE",
    value: "FR"
  },
  {
    code: "68",
    regionCode: "13",
    label: "L AQUILA",
    value: "AQ"
  },
  {
    code: "69",
    regionCode: "13",
    label: "TERAMO",
    value: "TE"
  },
  {
    code: "70",
    regionCode: "13",
    label: "PESCARA",
    value: "PE"
  },
  {
    code: "71",
    regionCode: "13",
    label: "CHIETI",
    value: "CH"
  },
  {
    code: "72",
    regionCode: "14",
    label: "CAMPOBASSO",
    value: "CB"
  },
  {
    code: "73",
    regionCode: "14",
    label: "ISERNIA",
    value: "IS"
  },
  {
    code: "74",
    regionCode: "15",
    label: "CASERTA",
    value: "CE"
  },
  {
    code: "75",
    regionCode: "15",
    label: "BENEVENTO",
    value: "BN"
  },
  {
    code: "76",
    regionCode: "15",
    label: "NAPOLI",
    value: "NA"
  },
  {
    code: "77",
    regionCode: "15",
    label: "AVELLINO",
    value: "AV"
  },
  {
    code: "78",
    regionCode: "15",
    label: "SALERNO",
    value: "SA"
  },
  {
    code: "79",
    regionCode: "16",
    label: "FOGGIA",
    value: "FG"
  },
  {
    code: "80",
    regionCode: "16",
    label: "BARI",
    value: "BA"
  },
  {
    code: "81",
    regionCode: "16",
    label: "TARANTO",
    value: "TA"
  },
  {
    code: "82",
    regionCode: "16",
    label: "BRINDISI",
    value: "BR"
  },
  {
    code: "83",
    regionCode: "16",
    label: "LECCE",
    value: "LE"
  },
  {
    code: "84",
    regionCode: "17",
    label: "POTENZA",
    value: "PZ"
  },
  {
    code: "85",
    regionCode: "17",
    label: "MATERA",
    value: "MT"
  },
  {
    code: "86",
    regionCode: "18",
    label: "COSENZA",
    value: "CS"
  },
  {
    code: "87",
    regionCode: "18",
    label: "CATANZARO",
    value: "CZ"
  },
  {
    code: "88",
    regionCode: "18",
    label: "REGGIO CALABRIA",
    value: "RC"
  },
  {
    code: "89",
    regionCode: "18",
    label: "CROTONE",
    value: "KR"
  },
  {
    code: "90",
    regionCode: "18",
    label: "VIBO VALENTIA",
    value: "VV"
  },
  {
    code: "91",
    regionCode: "19",
    label: "TRAPANI",
    value: "TP"
  },
  {
    code: "92",
    regionCode: "19",
    label: "PALERMO",
    value: "PA"
  },
  {
    code: "93",
    regionCode: "19",
    label: "MESSINA",
    value: "ME"
  },
  {
    code: "94",
    regionCode: "19",
    label: "AGRIGENTO",
    value: "AG"
  },
  {
    code: "95",
    regionCode: "19",
    label: "CALTANISSETTA",
    value: "CL"
  },
  {
    code: "96",
    regionCode: "19",
    label: "ENNA",
    value: "EN"
  },
  {
    code: "97",
    regionCode: "19",
    label: "CATANIA",
    value: "CT"
  },
  {
    code: "98",
    regionCode: "19",
    label: "RAGUSA",
    value: "RG"
  },
  {
    code: "99",
    regionCode: "19",
    label: "SIRACUSA",
    value: "SR"
  },
  {
    code: "100",
    regionCode: "20",
    label: "SASSARI",
    value: "SS"
  },
  {
    code: "101",
    regionCode: "20",
    label: "NUORO",
    value: "NU"
  },
  {
    code: "102",
    regionCode: "20",
    label: "CAGLIARI",
    value: "CA"
  },
  {
    code: "103",
    regionCode: "20",
    label: "ORISTANO",
    value: "OR"
  },
  {
    code: "108",
    regionCode: "3",
    label: "MONZA E DELLA BRIANZA",
    value: "MB"
  },
  {
    code: "109",
    regionCode: "11",
    label: "FERMO",
    value: "FM"
  },
  {
    code: "110",
    regionCode: "16",
    label: "BARLETTA ANDRIA TRANI",
    value: "BT"
  },
  {
    code: "111",
    regionCode: "20",
    label: "SUD SARDEGNA",
    value: "SU"
  }
];

const provincesAcronym = provinces.map((x) => x.value);

module.exports = {
  provinces,
  provincesAcronym
};

import { createContext, useContext, useEffect, useState } from "react";
import useQueryString from "use-query-string";

const FiscalYearContext = createContext();

export const localStorageKeyInitialYear = "FiscalYearWrapperInitialFiscalYear";

export const FiscalYearWrapper = ({ children, initialFiscalYear }) => {
  const [queryString] = useQueryString(window.location, (path) => {
    window.history.pushState(null, document.title, path);
  });

  const [fiscalYear, setFiscalYear] = useState(
    +queryString.year || initialFiscalYear
  );

  const hookData = {
    fiscalYear,
    setFiscalYear
  };

  useEffect(() => {
    localStorage.setItem(localStorageKeyInitialYear, fiscalYear);
  }, [fiscalYear]);

  return (
    <FiscalYearContext.Provider value={hookData}>
      {children}
    </FiscalYearContext.Provider>
  );
};

export const useFiscalYearContext = () => useContext(FiscalYearContext);

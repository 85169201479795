import { useEffect, useState, useRef } from "react";

import { useParams } from "react-router-dom";
import { useCurrentTenant } from "@drift/oneplatfront";

import { useGraphql } from "./use-graphql";
import { getTaxReport } from "../utils/get-tax-report";

export const useGetTaxReport = ({ interval = 0 } = {}) => {
  const { taxpayerId, id } = useParams();
  const { tenant } = useCurrentTenant(true);
  const { query } = useGraphql();
  const [data, setData] = useState();
  const timeoutId = useRef();
  const [loading, setLoading] = useState(false);

  const execLoop = () => {
    if (taxpayerId && id && tenant) {
      setLoading(true);
      getTaxReport({
        query,
        id,
        taxpayerId,
        itemId: tenant?.itemId
      }).then((result) => {
        setData(result.data);
        setLoading(false);
        if (interval) {
          timeoutId.current = setTimeout(execLoop, interval);
        }
      });
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  useEffect(() => {
    execLoop();
  }, [taxpayerId, id, tenant]);

  return { data, loading };
};

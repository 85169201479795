import Box from "@vapor/react-material/Box";
import CircularProgress from "@vapor/react-material/CircularProgress";

const LoadingSpinner = () => (
  <Box
    sx={{
      height: "100vh",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 100,
      background: "white",
      opacity: 0.6
    }}
  >
    <CircularProgress />
  </Box>
);

export default LoadingSpinner;

import { createContext, useContext, useEffect, useState, useMemo } from "react";

import { useCurrentTenant } from "@drift/oneplatfront";
import { useSnackbar } from "@onefront/react-sdk";

import { useCqrs } from "../../../services/cqrs";

const TaxReportsListContext = createContext();

export const TaxReportsListWrapper = ({
  children,
  initialTaxReportsList = [],
  initialFilters = {}
}) => {
  const { tenant } = useCurrentTenant(true);
  const { issueCommand } = useCqrs();
  const { enqueueSnackbar } = useSnackbar();
  const [taxReportsList, setTaxReportsList] = useState(initialTaxReportsList);
  const [filters, setFilters] = useState(initialFilters);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const createTaxReport = async (row) => {
    setLoading(true);
    try {
      const payload = {
        taxReport: row.tax_report,
        year: row.year,
        period: row.period,
        type: row.condition,
        tenant_id: tenant?.itemId,
        taxpayer_id: row?.taxpayer_id,
        taxpayerType: row?.taxpayer_type
      };

      const headers = { "taxpayer-id": row.taxpayer_id };
      const cmdResult = await issueCommand("create", "tax-reports", payload, {
        headers,
        shouldComplete: true
      });

      if (cmdResult) {
        const { cmd_id } = cmdResult;
        return cmd_id;
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  const hookData = useMemo(
    () => ({
      taxReportsList,
      setTaxReportsList,
      filters,
      setFilters,
      loading,
      setLoading,
      offset,
      setOffset,
      createTaxReport
    }),
    [
      taxReportsList,
      setTaxReportsList,
      filters,
      setFilters,
      loading,
      setLoading,
      offset,
      setOffset,
      createTaxReport
    ]
  );

  useEffect(() => {
    setLoading(true);
    setOffset(0); //when filters change, we need to reset the offset
  }, [filters]);

  useEffect(() => {
    setLoading(false);
  }, [taxReportsList]);

  return (
    <TaxReportsListContext.Provider value={hookData}>
      {children}
    </TaxReportsListContext.Provider>
  );
};

export const useTaxReportsListContext = () => useContext(TaxReportsListContext);

import Stack from "@vapor/react-material/Stack";

import { ResultCard } from "./ResultCard";

export const TaxReportResult = ({ resultData }) => {
  const getResults = () => {
    return resultData?.map((result, index) => {
      if (result.title !== "") {
        return <ResultCard key={index} result={result} />;
      }
    });
  };

  return (
    <Stack
      sx={{ width: "100%" }}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Stack
        direction="row"
        display="flex"
        alignItems="flex-start"
        width="920px"
        useFlexGap
        flexWrap="wrap"
        gap="16px"
        spacing={{ md: 4 }}
      >
        {getResults()}
      </Stack>
    </Stack>
  );
};

import { useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { ExtendedSelect } from "@onefront/workspace-forms/components/General/ExtendedSelect/ExtendedSelect.js";
import { ExtendedMenuItem } from "@onefront/workspace-forms/components/General/ExtendedMenuItem/ExtendedMenuItem.js";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { onChangeHandlerField } from "../../../handlers";
import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import { ExtendedToggle } from "../../ExtendedToggle";
import { TenantIVAControlledCompany } from "./TenantIVAControlledCompany";

export const TenantIVAAdditionalData = () => {
  const { t } = useTranslation();
  const {
    companySettings,
    setCompanySettings,
    setIsEditMode,
    isEditMode,
    loading,
    sotCompanySettings,
    setAnyChange
  } = useCompanySettingsContext();

  const onChangeHandler = (e) => {
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  const onChangeControlledCompanyHandler = (e, selectedIndex) => {
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings,
      array: {
        name: "controlledCompanies",
        index: selectedIndex
      }
    });
  };

  const onAddNewControlledCompany = () => {
    setCompanySettings({
      ...companySettings,
      controlledCompanies: [...companySettings.controlledCompanies, {}]
    });
  };

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  useEffect(() => {
    if (
      companySettings?.controlledCompanies &&
      !companySettings?.controlledCompanies.length
    ) {
      setCompanySettings({
        ...companySettings,
        controlledCompanies: [{}]
      });
    }
  }, [companySettings?.controlledCompanies]);

  return (
    <CompanySettingsGrid>
      <ExtendedSelect
        label={t("groupCompany")}
        name="groupCompany"
        value={companySettings.groupCompany}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem label={t("controlling")} value="controlling">
          {t("controlling")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("controlling")} value="controlled">
          {t("controlled")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("none")} value="none">
          {t("none")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <Button
        fullRow={true}
        variant="text"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={onAddNewControlledCompany}
      >
        {t("addNewControlledCompany")}
      </Button>
      <Stack fullRow={true} gap={"16px"}>
        {companySettings?.controlledCompanies?.map((settings, index) => (
          <TenantIVAControlledCompany
            settings={settings}
            key={index}
            onChange={(e) => onChangeControlledCompanyHandler(e, index)}
          />
        ))}
      </Stack>
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.PaymentMethod}
        label={t("f24VatPayable")}
        name="PaymentMethod"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedSelect
        disabled={!companySettings.PaymentMethod}
        label={t("f24VatPayable")}
        name="f24VatPayable"
        value={companySettings.f24VatPayable}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem
          label={t("incomeModelPayment")}
          value="incomeModelPayment"
        >
          {t("incomeModelPayment")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("march16")} value="march16">
          {t("march16")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("april17")} value="april17">
          {t("april17")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("may16")} value="may16">
          {t("may16")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("june16")} value="june16">
          {t("june16")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("june30")} value="june30">
          {t("june30")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <ExtendedSelect
        label={t("agriculture")}
        name="agriculture"
        value={companySettings.agriculture}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem label={t("soggettoAgricolo")} value="S">
          {t("soggettoAgricolo")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("soggettoNonAgricolo")} value="N">
          {t("soggettoNonAgricolo")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("asSindAgr")} value="C">
          {t("asSindAgr")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("atAgrConnected")} value="L">
          {t("atAgrConnected")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("enoturismo")} value="E">
          {t("enoturismo")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("oleoturismo")} value="T">
          {t("oleoturismo")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("agriturismo")} value="A">
          {t("agriturismo")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.travelAgency}
        label={t("travelAgency")}
        name="travelAgency"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.article74And73}
        label={t("article74And73")}
        name="article74And73"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedSelect
        label={t("particularCategories")}
        name="particularCategories"
        value={companySettings.particularCategories}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem label={t("autotrasportoMerciContoTerzi")} value="A">
          {t("autotrasportoMerciContoTerzi")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("operatorsOfFuel")} value="E">
          {t("operatorsOfFuel")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("odontotecnici")} value="O">
          {t("odontotecnici")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("nuoveIniziativeImpr")} value="N">
          {t("nuoveIniziativeImpr")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("formerMinimumTaxpayer")} value="F">
          {t("formerMinimumTaxpayer")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("exemptFarmers")} value="S">
          {t("exemptFarmers")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.article74c5}
        label={t("article74c5")}
        name="article74c5"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.innovativeStartups}
        label={t("innovativeStartups")}
        name="innovativeStartups"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
    </CompanySettingsGrid>
  );
};

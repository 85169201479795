import { useState, useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";

import { useWorkspaceData } from "../../../../services/workspace/workspaceProvider";

const TaxpayerButton = ({ onClick, variant }) => {
  const { t } = useTranslation("foa");
  const { workspaceData } = useWorkspaceData();
  const [createTaxpayerUrl, setCreateTaxpayerUrl] = useState("");

  useEffect(() => {
    if (workspaceData?.item)
      setCreateTaxpayerUrl(`${workspaceData.item?.value}/newcustomer`);
  }, [workspaceData]);

  return (
    <>
      <Stack>
        <Button
          onClick={() => {
            window.location.href = createTaxpayerUrl;
          }}
          variant={variant}
          startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
        >
          {t("CreateTaxPayer")}
        </Button>
      </Stack>
    </>
  );
};

export default TaxpayerButton;

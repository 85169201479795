import { useEffect, createContext, useContext, useState } from "react";
import { useGet } from "@onefront/react-sdk";

export const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
  const getWorkspaceUrl = useGet(
    `registryApi://services/rnd:core:customer-management:app`,
    {
      lazy: true
    }
  );
  const [workspaceUrl, setWorkspaceUrl] = useState("");

  useEffect(() => {
    getWorkspaceUrl.fetch();
  }, []);

  useEffect(() => {
    if (getWorkspaceUrl.data?.item)
      setWorkspaceUrl(getWorkspaceUrl.data.item?.value);
  }, [getWorkspaceUrl.data]);

  return (
    <WorkspaceContext.Provider
      value={{ workspaceData: getWorkspaceUrl.data, workspaceUrl }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspaceData = () => useContext(WorkspaceContext);

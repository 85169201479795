import { createElement } from "react";
import { IVATR_V1 } from "./IVATR_V1";
import { DEFAULT_PAGE } from "../DEFAULT_PAGE";

const modelPerYearMapping = {
  2024: IVATR_V1
};

const tabs = {
  contents: {
    checks: "checks",
    reportData: "reportData",
    result: "result",
    model: "model"
  },
  firstContent: "checks"
};

export const IVATR = {
  match: (taxReport) => taxReport.includes("IVATR"),
  render: ({ year }) => {
    const componentToRender = modelPerYearMapping[year] || DEFAULT_PAGE;

    return createElement(componentToRender, { tabs: tabs });
  }
};

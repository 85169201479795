import { useCurrentTenant } from "@drift/oneplatfront";

import VaporPage from "@vapor/react-custom/VaporPage";
import List from "@vapor/react-material/List";
import Box from "@vapor/react-material/Box";
import Typography from "@vapor/react-extended/ExtendedTypography";

import { Company } from "./Company";
import { useCompanyList } from "../../../hooks/companyList";
import { useEffect } from "react";
import { useTranslation } from "@onefront/react-sdk";

import LoadingSpinner from "../../components/LoadingSpinner";

const SelectCompany = () => {
  const { t } = useTranslation("foa");
  return (
    <VaporPage title={t("Companies")}>
      <VaporPage.Section withPadding>
        <Typography
          variant="titleSmall"
          color="primary.main"
          gutterBottom
          component="div"
        >
          {t("SelectStudio")}
        </Typography>
      </VaporPage.Section>
    </VaporPage>
  );
};

export const Companies = () => {
  const { t } = useTranslation("foa");
  const { tenant } = useCurrentTenant(true);
  const companyList = useCompanyList({ tenantId: tenant?.itemId });

  useEffect(() => {
    if (tenant?.itemId) companyList.fetch();
  }, [tenant]);

  if (!tenant) return <SelectCompany />;

  return (
    <VaporPage title={t("Companies")}>
      <VaporPage.Section withPadding>
        {tenant && companyList.data ? (
          <Box sx={{ width: "auto" }} role="presentation">
            <List>
              {companyList?.data?.content?.map((company) => (
                <Company key={company.id} {...company} />
              ))}
            </List>
          </Box>
        ) : (
          <LoadingSpinner />
        )}
      </VaporPage.Section>
    </VaporPage>
  );
};

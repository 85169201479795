import { useNavigate } from "react-router-dom";

import { useTranslation } from "@onefront/react-sdk";

import Grid from "@vapor/react-material/Grid";
import Button from "@vapor/react-material/Button";
import VaporPage from "@vapor/react-custom/VaporPage";
import { useTheme } from "@vapor/react-material/styles";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DetailsSidebar } from "./DetailsSidebar";
import { CompanySettings } from "./CompanySettings";
import { WorkspaceDataWrapper } from "../providers/WorkspaceDataProvider";
import { CompanySettingsWrapper } from "../providers/CompanySettingsProvider";

export const CompanySettingsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    /* this is done here because at this point
      we have the id coming from the URL */
    <WorkspaceDataWrapper>
      <CompanySettingsWrapper>
        <VaporPage
          title={t("companySettingTitle")}
          subtitle={t("companySettingSubtitle")}
          headerLeft={
            <Button
              sx={{ width: "50px", marginInlineStart: "auto" }}
              onClick={() => navigate(-1)}
              startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            />
          }
        >
          <Grid container>
            <Grid
              item
              md={4}
              xl={2.75}
              sx={{
                background: theme.palette.primary.whiteSmoke,
                minHeight: "calc(100vh - 160px)"
              }}
            >
              <DetailsSidebar />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              xl={9.25}
              px={4}
              py={4}
              sx={{
                height: "calc(100vh - 145px)",
                minHeight: "calc(100vh - 145px)",
                overflow: "auto"
              }}
            >
              <CompanySettings />
            </Grid>
          </Grid>
        </VaporPage>
      </CompanySettingsWrapper>
    </WorkspaceDataWrapper>
  );
};

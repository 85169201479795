import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetUserConfig } from "../hooks/useGetUserConfig";
import widgetGoToConfig from "../../../utils/config-widget-go-to.json";

const UserConfigContext = createContext();

export const UserConfigWrapper = ({ children }) => {
  const [userConfig, setUserConfig] = useState([]);
  const [loading, setLoading] = useState(false);
  const { tenant } = useCurrentTenant(true);

  const { data: dataUserConfig } = useGetUserConfig({
    tenantId: tenant?.itemId
  });

  useEffect(() => {
    setUserConfig(widgetGoToConfig);
  }, []);

  useEffect(() => {
    if (dataUserConfig?.user_config_public_entries[0]?.config)
      setUserConfig(dataUserConfig?.user_config_public_entries[0]?.config);
  }, [dataUserConfig]);

  const hookData = useMemo(
    () => ({
      userConfig,
      setUserConfig,
      loading,
      setLoading
    }),
    [userConfig, setUserConfig, loading, setLoading]
  );

  useEffect(() => {
    setLoading(false);
  }, [userConfig]);

  return (
    <UserConfigContext.Provider value={hookData}>
      {children}
    </UserConfigContext.Provider>
  );
};

export const useUserConfigContext = () => useContext(UserConfigContext);

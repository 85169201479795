import React, { useLayoutEffect } from "react";
import Stack from "@vapor/react-material/Stack";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useGetConfig, useTranslation } from "@onefront/react-sdk";
import { generateKpiDueDatesData } from "./Generate-data";
import { taxReportNameMapping } from "../../../../utils/tax-report-name-mapping";

const configureLabel = (root, series, dataItem) => {
  const displayName =
    taxReportNameMapping[dataItem.dataContext.tax_report] ||
    dataItem.dataContext.tax_report;
  return am5.Bullet.new(root, {
    sprite: am5.Label.new(root, {
      text: displayName,
      fontSize: 12,
      centerX: am5.percent(50),
      centerY: am5.percent(50)
    })
  });
};

const generateScrollbars = (root) => {
  const scrollbarX = am5.Scrollbar.new(root, {
    orientation: "horizontal",
    maxHeight: 4
  });

  const scrollbarY = am5.Scrollbar.new(root, {
    orientation: "vertical",
    maxWidth: 4
  });

  scrollbarX.startGrip.get("icon").set("forceHidden", true);
  scrollbarX.endGrip.get("icon").set("forceHidden", true);

  scrollbarY.startGrip.get("icon").set("forceHidden", true);
  scrollbarY.endGrip.get("icon").set("forceHidden", true);

  scrollbarX.thumb.setAll({
    fill: "#00C3EA"
  });
  scrollbarY.thumb.setAll({
    fill: "#00C3EA"
  });

  scrollbarX.endGrip.get("background").setAll({
    fill: "#F2F5F8",
    stroke: "#00C3EA"
  });
  scrollbarX.startGrip.get("background").setAll({
    fill: "#F2F5F8",
    stroke: "#00C3EA"
  });

  scrollbarY.startGrip.get("background").setAll({
    fill: "#F2F5F8",
    stroke: "#00C3EA"
  });
  scrollbarY.endGrip.get("background").setAll({
    fill: "#F2F5F8",
    stroke: "#00C3EA"
  });

  scrollbarX.startGrip.setAll({
    height: 24,
    width: 24
  });
  scrollbarX.endGrip.setAll({
    height: 24,
    width: 24
  });
  scrollbarY.startGrip.setAll({
    height: 24,
    width: 24
  });
  scrollbarY.endGrip.setAll({
    height: 24,
    width: 24
  });

  return { scrollbarX, scrollbarY };
};

const calculateBulletSize = (value, minValue, maxValue, minSize, maxSize) => {
  if (maxValue === minValue) {
    // If all values are the same, return the average size
    return (minSize + maxSize) / 2;
  }
  return (
    minSize + ((value - minValue) / (maxValue - minValue)) * (maxSize - minSize)
  );
};

const ChartDateBased = ({ kpiDueDates, selectedChip, period, kpiStatuses }) => {
  const { t } = useTranslation();

  const state = [
    t("sent"),
    t("closed"),
    t("ongoing"),
    t("withAlerts"),
    t("withErrors"),
    t("todo"),
    t("rejected")
  ];

  const statusMapping = {
    sent: state[0],
    closed: state[1],
    ongoing: state[2],
    withAlerts: state[3],
    withErrors: state[4],
    todo: state[5],
    rejected: state[6]
  };

  const tooltipHTML = (dataItem, kpiStatuses) => {
    const displayName =
      taxReportNameMapping[dataItem.dataContext.tax_report] ||
      dataItem.dataContext.tax_report;

    const taxReport = dataItem.dataContext.tax_report;
    const year = dataItem.dataContext.year;
    const statusData =
      kpiStatuses.find((status) => status.year === year)?.data[taxReport] || {};

    const totalValue = statusData.total?.total || 0;

    const statusEntries = Object.entries(statusData)
      .filter(([status, { total }]) => status !== "total" && total > 0) // Filter out statuses with values of 0
      .map(([status, { total }]) => {
        const translatedStatus = statusMapping[status] || status;
        return `<div>${translatedStatus} (${total})</div>`;
      })
      .join("");

    return `
      <div style="display:flex; flex-direction: column; justify-content:center; align-items:flex-start; width: 100%; font-family: Roboto; font-size: 12px; font-style: normal; font-weight: 400; color: white; line-height:16px">
        <div>${displayName} (tot. ${totalValue})</div>
        ${statusEntries}  
      </div>
    `;
  };
  const licenseKey = useGetConfig("am5Chart.license");

  useLayoutEffect(() => {
    const root = am5.Root.new("chartdiv-date");

    const myTheme = am5.Theme.new(root);

    myTheme.rule("Tooltip", ["tooltip"]).setAll({
      getFillFromSprite: false,
      getStrokeFromSprite: false,
      autoTextColor: false,
      getLabelFillFromSprite: false,
      pointerOrientation: "down",
      dy: -7
    });

    myTheme.rule("PointedRectangle", ["tooltip", "background"]).setAll({
      fill: am5.color(0x004666),
      fillOpacity: 1,
      stroke: am5.color(0x004666)
    });

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    am5.addLicense(licenseKey);

    const data = generateKpiDueDatesData(kpiDueDates, period, kpiStatuses);

    data.sort((a, b) => a.date - b.date);

    const dataForSeries = [[], [], []];

    const DEPOSITS_ONLY = "depositsOnly";
    const PERIODIC_DECLARATIONS = "periodicDeclarations";
    const ANNUAL_DECLARATIONS = "annualDeclarations";

    if (selectedChip === DEPOSITS_ONLY) {
      dataForSeries[1] = data.filter((item) => item.payments > 0);
    } else if (selectedChip === PERIODIC_DECLARATIONS) {
      dataForSeries[2] = data.filter((item) => item.periodicDeclarations > 0);
    } else if (selectedChip === ANNUAL_DECLARATIONS) {
      dataForSeries[0] = data.filter((item) => item.annualDeclarations > 0);
    } else {
      dataForSeries[1] = data.filter((item) => item.payments > 0);
      dataForSeries[2] = data.filter((item) => item.periodicDeclarations > 0);
      dataForSeries[0] = data.filter((item) => item.annualDeclarations > 0);
    }

    const dataForSeriesFiltered = dataForSeries.map((item) =>
      item.slice(0, period)
    );

    const isLogarithmicScale = (data) => {
      const values = data.map((item) => item.valueY);
      const minValue = Math.min(...values);
      const maxValue = Math.max(...values);
      return maxValue - minValue > 100;
    };

    root.dateFormatter.setAll({
      dateFormat: "MMM",
      dateFields: ["valueX"]
    });

    root.numberFormatter.setAll({
      numberFormat: "#.#"
    });

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelY: "zoomXY"
      })
    );

    const currentDate = new Date();
    currentDate.setHours(0);
    currentDate.setMinutes(0);

    const finalDate = new Date();
    finalDate.setMonth(finalDate.getMonth() + period);

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: "month",
          count: 1
        },
        extraMin: 0.11,
        extraMax: 0.02,
        min: currentDate.getTime(),
        max: finalDate.getTime(),
        markUnitChange: false,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 20
        })
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 30
        }),
        paddingBottom: 30,
        logarithmic: isLogarithmicScale(dataForSeriesFiltered.flat()),
        extraMax: 0.5,
        autoZoom: false,
        min: 0
      })
    );

    yAxis.set(
      "numberFormatter",
      am5.NumberFormatter.new(root, {
        numberFormat: "#.#"
      })
    );

    const series = [];
    // Series 0

    series.push(
      chart.series.push(
        am5xy.LineSeries.new(root, {
          calculateAggregates: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "annualDeclarations",
          valueXField: "date",
          valueField: "annualDeclarations"
        })
      )
    );

    const circleTemplate = am5.Template.new({});
    series[0].bullets.push(function (root, series, dataItem) {
      const minSize = 15;
      const maxSize = 60;
      const minValue = Math.min(
        ...dataForSeries[0].map((item) => item.annualDeclarations)
      );
      const maxValue = Math.max(
        ...dataForSeries[0].map((item) => item.annualDeclarations)
      );

      const size = calculateBulletSize(
        dataItem.dataContext.annualDeclarations,
        minValue,
        maxValue,
        minSize,
        maxSize
      );
      const graphics = am5.Circle.new(
        root,
        {
          fill: am5.Color.fromString(dataItem.dataContext.color),
          opacity: 0.8,
          width: size,
          height: size
        },
        circleTemplate
      );

      graphics.setAll({
        tooltipHTML: tooltipHTML(dataItem, kpiStatuses)
      });
      return am5.Bullet.new(root, {
        sprite: graphics
      });
    });

    series[0].bullets.push(configureLabel);

    series[0].set("heatRules", [
      {
        target: circleTemplate,
        min: 15,
        max: 60,
        dataField: "value",
        key: "radius"
      }
    ]);

    // Series 1
    series.push(
      chart.series.push(
        am5xy.LineSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "payments",
          valueXField: "date",
          valueField: "payments"
        })
      )
    );

    const starTemplate = am5.Template.new({});
    series[1].bullets.push(function (root, series, dataItem) {
      const minSize = 17;
      const maxSize = 70;
      const minValue = Math.min(
        ...dataForSeries[1].map((item) => item.payments)
      );
      const maxValue = Math.max(
        ...dataForSeries[1].map((item) => item.payments)
      );

      const size = calculateBulletSize(
        dataItem.dataContext.payments,
        minValue,
        maxValue,
        minSize,
        maxSize
      );

      const graphics = am5.Star.new(
        root,
        {
          fill: am5.Color.fromString(dataItem.dataContext.color),
          opacity: 0.8,
          spikes: 4,
          innerRadius: am5.percent(70),
          radius: size
        },
        starTemplate
      );

      // Set tooltip for each bullet
      graphics.setAll({
        tooltipHTML: tooltipHTML(dataItem, kpiStatuses)
      });

      return am5.Bullet.new(root, {
        sprite: graphics
      });
    });

    series[1].bullets.push(configureLabel);

    series[1].set("heatRules", [
      {
        target: starTemplate,
        dataField: "value",
        key: "width"
      }
    ]);
    // Series 2
    series.push(
      chart.series.push(
        am5xy.LineSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "periodicDeclarations",
          valueXField: "date",
          valueField: "periodicDeclarations"
        })
      )
    );

    const rectangleTemplate = am5.Template.new({});
    series[2].bullets.push(function (root, series, dataItem) {
      const minSize = 10;
      const maxSize = 20;
      const minValue = Math.min(
        ...dataForSeries[2].map((item) => item.periodicDeclarations)
      );
      const maxValue = Math.max(
        ...dataForSeries[2].map((item) => item.periodicDeclarations)
      );

      let size = calculateBulletSize(
        dataItem.dataContext.periodicDeclarations,
        minValue,
        maxValue,
        minSize,
        maxSize
      );

      const graphics = am5.RoundedRectangle.new(
        root,
        {
          width: am5.percent(size),
          height: am5.percent(size),
          fill: am5.Color.fromString(dataItem.dataContext.color),
          opacity: 0.8
        },
        rectangleTemplate
      );

      graphics.set("centerX", am5.percent(50));
      graphics.set("centerY", am5.percent(50));
      graphics.setAll({
        tooltipHTML: tooltipHTML(dataItem, kpiStatuses)
      });
      return am5.Bullet.new(root, {
        sprite: graphics
      });
    });

    series[2].bullets.push(configureLabel);

    series[2].set("heatRules", [
      {
        min: 10,
        max: 30,
        target: rectangleTemplate,
        dataField: "value",
        key: "radius"
      }
    ]);

    const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineX.set("visible", false);
    cursor.lineY.set("visible", false);

    series.forEach((serie) => {
      serie.strokes.template.set("strokeOpacity", 0);
      serie.data.processor = am5.DataProcessor.new(root, {
        dateFields: ["date"],
        dateFormat: "MMM yyyy"
      });
    });

    const { scrollbarX, scrollbarY } = generateScrollbars(root);
    chart.set("scrollbarX", scrollbarX);
    chart.set("scrollbarY", scrollbarY);

    series.forEach((serie, idx) => {
      serie.data.setAll(dataForSeriesFiltered[idx]);
      serie.appear(1000);
    });

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [kpiDueDates, selectedChip, period]);

  return (
    <Stack sx={{ width: "100%", height: "400px" }} id="chartdiv-date"></Stack>
  );
};

export default ChartDateBased;

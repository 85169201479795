import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { useGet, usePost } from "@onefront/react-sdk";

import { useCqrs } from "../../../services/cqrs";
import { useTaxReportData } from "./taxReportDataProvider";

const TelematicoDataContext = createContext();

const DEFAULT_STEPS = {
  validation: { status: "todo", timestamp: null },
  sending: { status: "todo", timestamp: null }
};

const cloneSteps = (steps) => {
  return {
    validation: { ...steps.validation },
    sending: { ...steps.sending }
  };
};

const stepsChanged = (prevSteps, newSteps) => {
  return JSON.stringify(newSteps) !== JSON.stringify(prevSteps);
};

const VALIDATE_TELEMATICO_URL =
  "rnd::saas:tax-orchestrator:backendExecutorApi://api/v1/tax-reports-telematici/validate-fornitura-document";
const DOWNLOAD_OUTCOME_URL =
  "rnd::saas:tax-orchestrator:oneplatformConnectorApi://governmentAgencyHub/download-outcome";

export const TelematicoWrapper = ({ children }) => {
  const [steps, setSteps] = useState(DEFAULT_STEPS);
  const [prevSteps, setPrevSteps] = useState(null);
  const [prevTaxReportId, setPrevTaxReportId] = useState(null); // uuid
  const [loading, setLoading] = useState(false);
  const { fetch: validateTelematicoFetch, data: validateTelematicoData } =
    usePost(VALIDATE_TELEMATICO_URL);
  const { issueCommand } = useCqrs();
  const { taxReportData, setTaxReportData } = useTaxReportData();
  const { fetch: fetchTelematiciDocument, data: dataTelematici } = useGet(
    DOWNLOAD_OUTCOME_URL,
    {
      headers: {
        "delegated-token-user-id":
          taxReportData?.document?.generalData?.user?.sub
      },
      lazy: true
    }
  );
  useEffect(() => {
    if (taxReportData?.document && steps) {
      const sendCommand = async () => {
        const res = await issueCommand(
          "modify",
          "tax-reports",
          {
            uuid: taxReportData.document.uuid,
            version: taxReportData.document.version,
            tenant_id: taxReportData.document.tenant_id,
            taxpayer_id: taxReportData.taxpayer_id,
            modify: {
              modifyData: {
                states: {
                  telematico: {
                    steps
                  }
                }
              }
            }
          },
          {
            headers: { "taxpayer-id": taxReportData.taxpayer_id },
            shouldComplete: true
          }
        );

        if (res.response) setTaxReportData(res.response.update_tax_report);
        else {
          const newSteps = cloneSteps(steps);
          newSteps.validation.status = "inError";
          newSteps.validation.timestamp = Date.now();
          setSteps(newSteps);
        }
      };

      const storedSteps = taxReportData.document.states?.telematico?.steps;
      if (!storedSteps || stepsChanged(storedSteps, steps)) {
        sendCommand();
      }
    }
  }, [steps]);

  useEffect(() => {
    if (taxReportData?.uuid !== prevTaxReportId) {
      setPrevTaxReportId(taxReportData?.uuid);
      if (!taxReportData?.document?.states?.telematico) {
        setPrevSteps(null);
        setSteps(DEFAULT_STEPS);
      }
    }
    if (taxReportData?.document?.states?.telematico?.steps) {
      const newSteps = taxReportData.document.states.telematico.steps;

      if (stepsChanged(prevSteps, newSteps)) {
        setSteps(newSteps);
        setPrevSteps(newSteps);
      }
    }
  }, [taxReportData]);

  useEffect(() => {
    if (validateTelematicoData?.status === "success") {
      const newSteps = cloneSteps(steps);
      newSteps.validation.status = "done";
      newSteps.validation.timestamp = Date.now();
      setSteps(newSteps);
    }
  }, [validateTelematicoData]);

  const sendTelematico = async (taxReportData) => {
    setLoading(true);

    const res = await issueCommand(
      "upload-telematici",
      "tax-reports",
      {
        uuid: taxReportData.document.uuid,
        version: taxReportData.document.version,
        tenant_id: taxReportData.document.tenant_id,
        taxpayer_id: taxReportData.taxpayer_id
      },
      {
        headers: {
          "taxpayer-id": taxReportData.taxpayer_id
        },
        shouldComplete: true
      }
    );

    if (res.response) setTaxReportData(res.response.update_tax_report);
    else console.error(res.error);
    setLoading(false);
  };

  const validateTelematico = async () => {
    setLoading(true);
    await validateTelematicoFetch({
      data: { tax_report_uuid: taxReportData.uuid }
    });
    setLoading(false);
  };

  const downloadReceipt = async () => {
    setLoading(true);
    const { url } = taxReportData?.document?.data?.telematici ?? { url: null };

    await fetchTelematiciDocument({ params: { url } });
    setLoading(false);
  };

  useEffect(() => {
    if (dataTelematici?.fileContent) {
      const textBlob = new Blob([dataTelematici.fileContent], {
        type: "text/plain"
      });

      const url = URL.createObjectURL(textBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `telematico${taxReportData.uuid}.txt`;
      a.click();
    }
  }, [dataTelematici]);

  const data = useMemo(
    () => ({
      steps,
      sendTelematico,
      downloadReceipt,
      validateTelematico,
      loading
    }),
    [steps, sendTelematico, downloadReceipt, validateTelematico, loading]
  );

  return (
    <TelematicoDataContext.Provider value={data}>
      {children}
    </TelematicoDataContext.Provider>
  );
};

export const useTelematico = () => useContext(TelematicoDataContext);

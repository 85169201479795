import { useCallback } from "react";

import { useTranslation } from "@onefront/react-sdk";

import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";

import { useTaxReportData } from "../providers/taxReportDataProvider";
import { useTelematico } from "../providers/telematicoDataProvider";
import { useCqrs } from "../../../services/cqrs";
import { useFiscaliSaasIframe } from "../../../services/fiscali-saas-iframe/FiscaliSaasIframeProvider";

export const TaxReportPageFooter = () => {
  const { t } = useTranslation();
  const { sendTelematico, steps } = useTelematico();
  const { taxReportData, setTaxReportData } = useTaxReportData();
  const { issueCommand, isIssueCommandLoading } = useCqrs();
  const {
    storeTaxReportChanges,
    fiscaliRequestPending,
    cancelAndExit,
    closeFiscaliTaxReport
  } = useFiscaliSaasIframe();

  const openTaxReport = useCallback(async () => {
    const res = await issueCommand(
      "open",
      "tax-reports",
      {
        uuid: taxReportData.document.uuid,
        version: taxReportData.document.version,
        tenant_id: taxReportData.document.tenant_id,
        taxpayer_id: taxReportData.taxpayer_id
      },
      {
        headers: {
          "taxpayer-id": taxReportData.taxpayer_id
        },
        shouldComplete: true
      }
    );

    if (res.response) setTaxReportData(res.response.update_tax_report);
    else {
      console.error(res);
    }
  }, [taxReportData, issueCommand]);

  const createSupplementary = useCallback(async () => {
    const res = await issueCommand(
      "duplicate",
      "tax-reports",
      {
        uuid: taxReportData.document.uuid,
        version: taxReportData.document.version,
        tenant_id: taxReportData.document.tenant_id,
        taxpayer_id: taxReportData.taxpayer_id,
        document: taxReportData.document
      },
      {
        headers: {
          "taxpayer-id": taxReportData.taxpayer_id
        },
        shouldComplete: true
      }
    );
  }, [taxReportData, issueCommand]);

  const closeTaxReport = async () => {
    const res = await closeFiscaliTaxReport(taxReportData);
    if (res) setTaxReportData(res);
  };

  const saveTaxReport = async () => {
    const newTaxReportData = await storeTaxReportChanges(taxReportData);
    if (newTaxReportData) setTaxReportData(newTaxReportData);
  };

  return (
    <VaporToolbar
      size="medium"
      variant="regular"
      contentLeft={
        ["ongoing", "withErrors", "withAlerts"].includes(
          taxReportData?.document?.states?.taxReport
        ) ? (
          <Stack direction="row" gap="8px">
            <Button
              disabled={isIssueCommandLoading || fiscaliRequestPending}
              onClick={cancelAndExit}
            >
              {t("cancelChangesDeclaration")}
            </Button>
            <Button
              disabled={isIssueCommandLoading || fiscaliRequestPending}
              onClick={saveTaxReport}
              variant="outlined"
            >
              {t("saveChangesDeclaration")}
            </Button>
          </Stack>
        ) : null
      }
      contentRight={
        <Stack direction="row" gap="8px">
          {["rejected", "closed"].includes(
            taxReportData?.document?.states?.taxReport
          ) && (
            <Button
              disabled={isIssueCommandLoading || fiscaliRequestPending}
              variant="outlined"
              onClick={openTaxReport}
            >
              {t("openDeclaration")}
            </Button>
          )}
          {["ongoing", "withErrors", "withAlerts"].includes(
            taxReportData?.document?.states?.taxReport
          ) && (
            <Button
              onClick={closeTaxReport}
              disabled={isIssueCommandLoading || fiscaliRequestPending}
              variant="contained"
            >
              {t("closeDeclaration")}
            </Button>
          )}
          {taxReportData?.document?.states?.taxReport === "sent" && (
            <Button
              onClick={createSupplementary}
              disabled={isIssueCommandLoading || fiscaliRequestPending}
              variant="contained"
            >
              {t("createSupplementary")}
            </Button>
          )}
          {steps.validation.status === "done" &&
            steps.sending.status === "todo" &&
            taxReportData?.document?.states?.wfsState === "Closed" && (
              <Button
                onClick={() => sendTelematico(taxReportData)}
                disabled={isIssueCommandLoading || fiscaliRequestPending}
                variant="contained"
              >
                {t("sendTelematico")}
              </Button>
            )}
        </Stack>
      }
    />
  );
};

import { useState, useEffect } from "react";
import { useTranslation } from "@onefront/react-sdk";

import Chip from "@vapor/react-material/Chip";
import Stack from "@vapor/react-material/Stack";

import { SelectFilter } from "../../../tax-reports-list/components/tax-reports-list-filter/SelectFilter";
import { useReportPageData } from "../../providers/reportPageDataProvider";

export const ReportDataOtherFilters = () => {
  const { t } = useTranslation();
  const { filters, setFilters, showFilters, sourcesOfData } =
    useReportPageData();

  const [chipSelected, setChipSelected] = useState(filters?.chipSelected ?? "");

  useEffect(() => {
    setChipSelected(filters?.chipSelected ?? "");
  }, [filters]);

  const handleClick = (value) => {
    const newFilters = { ...filters };

    if (filters?.chipSelected !== value) newFilters.chipSelected = value;
    else delete newFilters.chipSelected;

    setFilters(newFilters);
  };

  if (!showFilters) return null;
  return (
    <Stack
      direction="row"
      alignItems="flex-end"
      spacing={2}
      sx={{ paddingTop: "16px" }}
    >
      <SelectFilter
        value={filters?.sourceOfData ?? "allSources"}
        setValue={(newValue) => {
          setFilters({ ...filters, sourceOfData: newValue });
        }}
        fullWidth={false}
        options={[{ value: "allSources" }].concat(
          sourcesOfData.map((item) => ({ value: item }))
        )}
        label={t("sourceOfData")}
        style={{
          minWidth: "210px"
        }}
      />
      <Stack direction="row" spacing={2} sx={{ marginTop: "15px" }}>
        <Chip
          clickable
          onClick={() => {
            handleClick("modifiedData");
          }}
          variant={chipSelected === "modifiedData" ? "outlined" : "filled"}
          size="small"
          label={t("modifiedData")}
        />
        <Chip
          clickable
          onClick={() => {
            handleClick("warnings");
          }}
          variant={chipSelected === "warnings" ? "outlined" : "filled"}
          size="small"
          label={t("warnings")}
        />
        <Chip
          clickable
          onClick={() => {
            handleClick("withErrors");
          }}
          variant={chipSelected === "withErrors" ? "outlined" : "filled"}
          size="small"
          label={t("withErrors")}
        />
      </Stack>
    </Stack>
  );
};

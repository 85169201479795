import { forwardRef } from "react";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";
import Button from "@vapor/react-material/Button";

import TaxReportPageAccordion from "../TaxReportPageAccordion";
import { useTaxReportData } from "../../providers/taxReportDataProvider";
import { formatDate } from "../../../../utils";

const TaxReportPageLipeVerification = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { taxReportData } = useTaxReportData();
  const { checksData } = props;

  return (
    <TaxReportPageAccordion ref={ref} title={t("checkSendingLipe")}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" sx={{ gap: "10px" }}>
          <Typography variant="bodyLarge">
            {`LI.PE I Trim ${
              taxReportData?.document?.year
                ? taxReportData?.document?.year - 1
                : ""
            }:`}
          </Typography>
          {checksData?.lipe_I !== "" ? (
            <Typography variant="bodyLarge700">
              {t("sentOnDateTemplate", {
                date: formatDate(checksData?.lipe_I),
                interpolation: { escapeValue: false }
              })}
            </Typography>
          ) : (
            <Typography variant="bodyLarge700">{t("notSent")}</Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" sx={{ gap: "10px" }}>
          <Typography variant="bodyLarge">
            {`LI.PE II Trim ${
              taxReportData?.document?.year
                ? taxReportData?.document?.year - 1
                : ""
            }:`}
          </Typography>
          {checksData?.lipe_II !== "" ? (
            <Typography variant="bodyLarge700">
              {t("sentOnDateTemplate", {
                date: formatDate(checksData?.lipe_II),
                interpolation: { escapeValue: false }
              })}
            </Typography>
          ) : (
            <Typography variant="bodyLarge700">{t("notSent")}</Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" sx={{ gap: "10px" }}>
          <Typography variant="bodyLarge">
            {`LI.PE III Trim ${
              taxReportData?.document?.year
                ? taxReportData?.document?.year - 1
                : ""
            }:`}
          </Typography>
          {checksData?.lipe_III !== "" ? (
            <Typography variant="bodyLarge700">
              {t("sentOnDateTemplate", {
                date: formatDate(checksData?.lipe_III),
                interpolation: { escapeValue: false }
              })}
            </Typography>
          ) : (
            <Typography variant="bodyLarge700">{t("notSent")}</Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" sx={{ gap: "10px" }}>
          <Typography variant="bodyLarge">
            {`LI.PE IV Trim ${
              taxReportData?.document?.year
                ? taxReportData?.document?.year - 1
                : ""
            }:`}
          </Typography>
          {checksData?.lipe_IV !== "" ? (
            <Typography variant="bodyLarge700">
              {t("sentOnDateTemplate", {
                date: formatDate(checksData?.lipe_IV),
                interpolation: { escapeValue: false }
              })}
            </Typography>
          ) : (
            <Typography variant="bodyLarge700">{t("notSent")}</Typography>
          )}
          {checksData?.lipe_IV && checksData?.lipe_IV === 0 ? (
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="bodyLarge">{t("sendIt")}</Typography>
                <Button
                  disabled
                  variant="primary"
                  onClick={() => {}}
                  size="small"
                >
                  {t("send")}
                </Button>
              </Stack>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      </Stack>
    </TaxReportPageAccordion>
  );
});

export default TaxReportPageLipeVerification;

import { useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { ExtendedTextfield } from "@onefront/workspace-forms/components/General/ExtendedTextfield/ExtendedTextfield.js";
import { ExtendedDatePicker } from "@onefront/workspace-forms/components/General/ExtendedDatePicker/ExtendedDatePicker.js";

import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { useWorkspaceData } from "../../../providers/WorkspaceDataProvider";
import { onChangeHandlerField } from "../../../handlers";
import { ExtendedToggle } from "../../ExtendedToggle";
import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import settings from "../../../fixtures/company-settings.json";

export const CommonDataGeneral = () => {
  const {
    sotCompanySettings,
    companySettings,
    setCompanySettings,
    isEditMode,
    setIsEditMode,
    loading,
    setAnyChange
  } = useCompanySettingsContext();
  const { taxPayerType } = useWorkspaceData();
  const { t } = useTranslation();

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  const onChangeHandler = (e) => {
    setAnyChange(true);
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  const commonDataGeneral = settings.commonData.commonDataGeneral;

  if (!commonDataGeneral) return null; //TODO: loading or skeleton???
  return (
    <CompanySettingsGrid>
      {commonDataGeneral.extortionUsuryVictims?.appliesTo.includes(
        taxPayerType
      ) && (
        <ExtendedToggle
          onChange={onChangeHandler}
          value={companySettings.extortionUsuryVictims}
          label={t("extortionUsuryVictims")}
          name="extortionUsuryVictims"
          editing={isEditMode}
          noValueLabel={t("notSet")}
          loading={loading}
          positiveLabel={t("checkboxPositive")}
          negativeLabel={t("checkboxNegative")}
        />
      )}
      {commonDataGeneral.landBuildingsDbManagement?.appliesTo.includes(
        taxPayerType
      ) && (
        <ExtendedToggle
          onChange={onChangeHandler}
          value={companySettings.landBuildingsDbManagement}
          label={t("landBuildingsDbManagement")}
          name="landBuildingsDbManagement"
          editing={isEditMode}
          noValueLabel={t("notSet")}
          loading={loading}
          positiveLabel={t("checkboxPositive")}
          negativeLabel={t("checkboxNegative")}
        />
      )}
      {commonDataGeneral.startYearFullExentLosses?.appliesTo.includes(
        taxPayerType
      ) && (
        <ExtendedDatePicker
          name="startYearFullExentLosses"
          noValueLabel={t("notSet")}
          editing={isEditMode}
          minDate={"2000-01-01"}
          maxDate={"2100-01-01"}
          format={"yyyy"}
          value={
            companySettings.startYearFullExentLosses > 1900
              ? `${companySettings.startYearFullExentLosses}-01-01`
              : undefined
          }
          onChange={(name, value) =>
            onChangeHandler({
              target: { name, value }
            })
          }
          label={t("startYearFullExentLosses")}
          loading={loading}
          views={["year"]}
        />
      )}
      {commonDataGeneral.sicilyProductionPlants?.appliesTo.includes(
        taxPayerType
      ) && (
        <ExtendedToggle
          onChange={onChangeHandler}
          value={companySettings.sicilyProductionPlants}
          label={t("sicilyProductionPlants")}
          name="sicilyProductionPlants"
          editing={isEditMode}
          noValueLabel={t("notSet")}
          loading={loading}
          positiveLabel={t("checkboxPositive")}
          negativeLabel={t("checkboxNegative")}
        />
      )}
      {commonDataGeneral?.serviceStartDate?.appliesTo?.includes(
        taxPayerType
      ) && (
        <ExtendedDatePicker
          name="serviceStartDate"
          editing={isEditMode}
          value={companySettings.serviceStartDate}
          onChange={(name, value) =>
            onChangeHandler({
              target: { name, value: value.split("T")[0] }
            })
          }
          label={t("serviceStartDate")}
          loading={loading}
          noValueLabel={t("notSet")}
        />
      )}
      {commonDataGeneral?.serviceEndDate?.appliesTo.includes(taxPayerType) && (
        <ExtendedDatePicker
          name="serviceEndDate"
          editing={isEditMode}
          value={companySettings.serviceEndDate}
          onChange={(name, value) =>
            onChangeHandler({
              target: { name, value: value.split("T")[0] }
            })
          }
          label={t("serviceEndDate")}
          loading={loading}
          noValueLabel={t("notSet")}
        />
      )}
      {commonDataGeneral?.imuChargeCode?.appliesTo.includes(taxPayerType) && (
        <ExtendedTextfield
          label={t("imuChargeCode")}
          noValueLabel={t("notSet")}
          value={companySettings.imuChargeCode}
          editing={isEditMode}
          name="imuChargeCode"
          onChange={onChangeHandler}
          loading={loading}
        />
      )}
      {commonDataGeneral.imuTelematicDelivery?.appliesTo.includes(
        taxPayerType
      ) && (
        <ExtendedToggle
          onChange={onChangeHandler}
          value={companySettings.imuTelematicDelivery}
          label={t("imuTelematicDelivery")}
          name="imuTelematicDelivery"
          editing={isEditMode}
          noValueLabel={t("notSet")}
          loading={loading}
          positiveLabel={t("checkboxPositive")}
          negativeLabel={t("checkboxNegative")}
        />
      )}
    </CompanySettingsGrid>
  );
};

import React, { useEffect, useState } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Accordion from "@vapor/react-material/Accordion";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import Box from "@vapor/react-material/Box";

import { useWorkspaceData } from "../../../../../services/workspace/workspaceProvider";
import { formatFloatAmount, orderDateHour } from "../../../../../utils";
import { TaxPayerCell } from "../../../../tax-reports-list/components/tax-reports-list-table/TaxPayerCell";
import { AnnualDataDialog } from "../../../helpers/tax-report-dialog-data";
import { useTaxReportData } from "../../../providers/taxReportDataProvider";
import { DialogInfoTaxReport } from "../../DialogInfoTaxReport";
import { FiscaliSaasIframeWrapper } from "../../FiscaliSaasIframeWrapper";
import { TaxReportOtherTaxes } from "../../Result/TaxReportOtherTaxes";
import { TaxReportPageActions } from "../../Result/TaxReportPageActions";
import { TaxReportPageISAState } from "../../Result/TaxReportPageISAState";
import { TaxReportPageQuadri } from "../../Result/TaxReportPageQuadri";
import { TaxReportPageResult } from "../../Result/TaxReportPageResult";
import { TaxReportResult } from "../../Result/TaxReportResult";

import PropTypes from "prop-types";
export const WSP_V1 = ({ tabs, name, ...props }) => {
  const { t } = useTranslation();
  const {
    taxReportData,
    taxReportVisibleContent,
    setTaxReportVisibleContent,
    possibleContents,
    setPossibleContents
  } = useTaxReportData();
  const { workspaceUrl } = useWorkspaceData();
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [expanded, setExpanded] = useState("panel1");

  const dateTaxReport = orderDateHour(taxReportData?.updated_at);

  DialogInfoTaxReport.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };

  const handleClose = (newValue) => {
    setOpenDialog(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setPossibleContents(tabs?.contents);
    setTaxReportVisibleContent(tabs?.firstContent);
  }, []);

  useEffect(() => {
    setOpenDialog(taxReportData.document?.states?.wfsState === "Todo");
  }, [taxReportData]);

  const rq =
    taxReportData?.document?.data?.fiscaliSaas?.dataOut?.[
      "RQ Imposte sostitutive&0"
    ];
  const rw =
    taxReportData?.document?.data?.fiscaliSaas?.dataOut?.[
      "RW Trasferimenti esteri&0"
    ];
  const rx =
    taxReportData?.document?.data?.fiscaliSaas?.dataOut?.[
      "RX Compensazioni e rimborsi&0"
    ];

  const resultData = [
    {
      title: t("ethicalTaxBalance"),
      value: rq?.["RQ_49_c6"]
        ? formatFloatAmount(rq?.["RQ_49_c6"], 2, 2)
        : "0,00",
      subtitle: rq?.["RQ_49_c7"] ? t("whichRepaymentRequest") : "",
      otherValue: rq?.["RQ_49_c7"]
        ? formatFloatAmount(rq?.["RQ_49_c6"], 2, 2)
        : "",
      text: t("iAdvance"),
      valueText: t("dataNotAvailable"),
      subText: t("iiAdvance"),
      valueSubText: t("dataNotAvailable")
    },
    {
      title: t("separatedTaxesBalance"),
      value: t("dataNotAvailable"),
      text: t("iAdvance"),
      valueText: t("dataNotAvailable"),
      subText: t("iiAdvance"),
      valueSubText: t("dataNotAvailable")
    },
    {
      title: t("VATFEBalance"),
      value: rw?.["RW_06_c5"]
        ? formatFloatAmount(rw?.["RW_06_c5"], 2, 2)
        : "0,00",
      subtitle: rw?.["RW_06_c6"] ? t("whichRepaymentRequest") : "",
      otherValue: rw?.["RW_06_c6"]
        ? formatFloatAmount(rw?.["RW_06_c6"], 2, 2)
        : "",
      text: t("iAdvance"),
      valueText: t("dataNotAvailable"),
      subText: t("iiAdvance"),
      valueSubText: t("dataNotAvailable")
    },
    {
      title: t("IVIEBalance"),
      value: rw?.["RW_07_c5"]
        ? formatFloatAmount(rw?.["RW_07_c5"], 2, 2)
        : "0,00",
      subtitle: rw?.["RW_07_c6"] ? t("whichRepaymentRequest") : "",
      otherValue: rw?.["RW_07_c6"]
        ? formatFloatAmount(rw?.["RW_07_c6"], 2, 2)
        : "",
      text: t("iAdvance"),
      valueText: t("dataNotAvailable"),
      subText: t("iiAdvance"),
      valueSubText: t("dataNotAvailable")
    }
  ];
  const sxFirstRow = { marginTop: "16px" };
  const sx = { marginTop: "8px" };
  const dataWSP = [
    [
      {
        rowStyle: { marginTop: "16px" },
        cell3: <Typography variant="bodyLarge700">{t("credito")}</Typography>,
        cell4: <Typography variant="bodyLarge700">{t("debito")}</Typography>
      },
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">
            {t("IRPEFcreditWithholdingTaxes")}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: (
          <Typography variant="body">
            {t("excessWithholdingsPreviousDeclaration")}
          </Typography>
        ),
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_51_c1"] ? formatFloatAmount(rx?.["RX_51_c1"], 2, 2) : "0"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("compensatedF24")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {" "}
            {rx?.["RX_51_c2"] ? formatFloatAmount(rx?.["RX_51_c2"], 2, 2) : "0"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: (
          <Typography variant="body">
            {t("withholdingsPresentDeclaration")}
          </Typography>
        ),
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_51_c3"] ? formatFloatAmount(rx?.["RX_51_c3"], 2, 2) : "0"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("usedCredit")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_51_CredUtil"]
              ? formatFloatAmount(rx?.["RX_51_CredUtil"], 2, 2)
              : "0"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("otherUses")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_51_AltriUtil"]
              ? formatFloatAmount(rx?.["RX_51_AltriUtil"], 2, 2)
              : "0"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("askedRefund")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_51_c4"] ? formatFloatAmount(rx?.["RX_51_c4"], 2, 2) : "0"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("inCompensation")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_51_c5"] ? formatFloatAmount(rx?.["RX_51_c5"], 2, 2) : "0"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("remainingF24")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_51_ResiduoF24"]
              ? formatFloatAmount(rx?.["RX_51_ResiduoF24"], 2, 2)
              : "0"}
          </Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">
            {t("ethicalTaxBalance")}
          </Typography>
        ),
        cell4: (
          <Typography variant="bodyLarge700">
            {rq?.["RQ_49_c6"] ? formatFloatAmount(rq?.["RQ_49_c6"], 2, 2) : "0"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rq?.["RQ_49_c7"] ? formatFloatAmount(rq?.["RQ_49_c7"], 2, 2) : "0"}
          </Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">
            {t("separatedTaxesBalance")}
          </Typography>
        ),
        cell4: (
          <Typography variant="bodyLarge700">
            {t("dataNotAvailable")}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">{t("VATFEBalance")}</Typography>
        ),
        cell4: (
          <Typography variant="bodyLarge700">
            {rw?.["RW_06_c5"] ? formatFloatAmount(rw?.["RW_06_c5"], 2, 2) : "0"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rw?.["RW_06_c6"] ? formatFloatAmount(rw?.["RW_06_c6"], 2, 2) : "0"}
          </Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">{t("IVIEBalance")}</Typography>
        ),
        cell4: (
          <Typography variant="bodyLarge700">
            {rw?.["RW_07_c5"] ? formatFloatAmount(rw?.["RW_07_c5"], 2, 2) : "0"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rw?.["RW_07_c6"] ? formatFloatAmount(rw?.["RW_07_c6"], 2, 2) : "0"}
          </Typography>
        )
      }
    ]
  ];

  return (
    <FiscaliSaasIframeWrapper>
      <Box sx={{ padding: "20px", height: "100%", overflow: "hidden" }}>
        {
          //Result
          taxReportVisibleContent === possibleContents.result && (
            <TaxReportPageResult>
              <TaxReportResult resultData={resultData} />
              <TaxReportOtherTaxes data={dataWSP} />
              <TaxReportPageQuadri />
              <TaxReportPageActions />
              <TaxReportPageISAState />
            </TaxReportPageResult>
          )
        }
        {isOpenDialog && (
          <DialogInfoTaxReport
            data={taxReportData}
            isOpen={isOpenDialog}
            open={isOpenDialog}
            onClose={handleClose}
          >
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
              >
                <Typography variant="titleSmall">
                  {t("dialogTaxReportData1")}
                </Typography>
                <Typography variant="body">
                  {t("dialogTaxReportDate", dateTaxReport).replaceAll(
                    "&#x2F;",
                    "/"
                  )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <TaxPayerCell
                  row={{
                    taxpayer_type: taxReportData?.document?.taxpayerType,
                    taxpayer_name:
                      taxReportData?.document?.input?.workspaces?.companyData
                        ?.personalData?.businessName,
                    taxpayer_id: taxReportData?.taxpayer_id
                  }}
                  modal={true}
                  workspaceUrl={workspaceUrl}
                />
                <AnnualDataDialog />
              </AccordionDetails>
            </Accordion>
          </DialogInfoTaxReport>
        )}
      </Box>
    </FiscaliSaasIframeWrapper>
  );
};

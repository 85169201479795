import { forwardRef } from "react";

import { useTranslation } from "@onefront/react-sdk";

import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";

/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"; */

import TaxReportPageAccordion from "../TaxReportPageAccordion";
import { formatAmount, formatDate } from "../../../../utils";

const TaxReportPageVatPayment = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { checksData } = props;

  return (
    <TaxReportPageAccordion
      ref={ref}
      title={t("vatAdvancePayment")}
      subtitle={t("lastChangeDateTemplate", {
        date: formatDate(checksData?.lastUpdate),
        interpolation: { escapeValue: false }
      })}
      accordionDetailsSx={{ minHeight: "176px" }}
    >
      {checksData?.advance && checksData?.advance !== 0 ? (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="bodyLarge">{t("advance")}</Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="bodyLarge700">
              {formatAmount(checksData?.advance)}
            </Typography>
            {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
          </Stack>
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" sx={{ gap: "10px" }}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="bodyLarge">{t("advance")}</Typography>
              <Typography variant="bodyLarge700">{t("notDue")}</Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" sx={{ gap: "10px" }}>
          <Typography variant="bodyLarge">{t("calculationMethod")}</Typography>
          <Typography variant="bodyLarge700">
            {t(checksData?.vatInAdvanceType)}
          </Typography>
        </Stack>
      </Stack>
    </TaxReportPageAccordion>
  );
});

export default TaxReportPageVatPayment;

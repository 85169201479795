import { useRef } from "react";

export const useDebounce = (timeout = 1000) => {
  const timerRef = useRef();

  return (fn) => {
    // Skip update if currently used:
    if (timerRef.current) return;

    // Schedule the next update:
    timerRef.current = setTimeout(() => {
      fn();
      timerRef.current = null;
    }, timeout);
  };
};

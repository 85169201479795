import Typography from "@vapor/react-extended/ExtendedTypography";
import { Card, CardContent, Stack } from "@vapor/react-material";
import { useTheme } from "@vapor/react-material/styles";
import { useTranslation } from "@onefront/react-sdk";

export const ResultCard = ({ result }) => {
  const { t } = useTranslation("foa");
  const theme = useTheme();

  const title = `${result?.title} (€)`;
  const subtitle = result?.subtitle;
  const value = result?.value;
  const otherValue = result?.otherValue;
  const text = `${result?.text} :`;
  const subText = `${result?.subText} :`;
  const valueText = result?.valueText;
  const valueSubText = result?.valueSubText;

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          width: "280px",
          minHeight: "135px",
          borderRadius: "8px",
          border: "solid 1px #C9D9E8",
          boxShadow: "none"
        }}
      >
        <CardContent
          sx={
            valueText && { borderBottom: "solid 1px #C9D9E8", width: "280px" }
          }
        >
          <Stack>
            <Typography
              sx={{
                fontFamily: "Cairo",
                fontWeight: "400",
                fontSize: "16px",
                textWrap: "nowrap"
              }}
              color={theme.palette.primary.textDisabledColor}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Cairo",
                fontWeight: "700",
                fontSize: "32px"
              }}
            >
              {t(value)}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Typography>{subtitle}</Typography>
            <Typography
              sx={{
                color:
                  otherValue >= 0
                    ? theme.palette.primary.successed
                    : theme.palette.primary.baseCriticalColor
              }}
            >
              {otherValue}
            </Typography>
          </Stack>
        </CardContent>
        {valueText && (
          <CardContent>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              sx={{ paddingBottom: "8px" }}
            >
              <Typography>{text}</Typography>
              <Typography
                sx={{
                  color:
                    valueText >= 0
                      ? theme.palette.primary.successed
                      : theme.palette.primary.baseCriticalColor
                }}
              >
                {valueText}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Typography>{subText}</Typography>
              <Typography
                sx={{
                  color:
                    valueSubText >= 0
                      ? theme.palette.primary.successed
                      : theme.palette.primary.baseCriticalColor
                }}
              >
                {valueSubText}
              </Typography>
            </Stack>
          </CardContent>
        )}
      </Card>
    </>
  );
};

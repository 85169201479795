import { useTranslation } from "@onefront/react-sdk";

import Stack from "@vapor/react-material/Stack";
import Accordion from "@vapor/react-extended/Accordion";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Tooltip } from "@vapor/react-material/Tooltip";
import { useTheme } from "@vapor/react-material/styles";

import { faAngleDown, faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TaxReportPageISAState = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Accordion
      defaultExpanded={true}
      sx={{
        width: "100%",
        padding: "32px 0px 0px 24px",
        boxShadow: "none"
      }}
    >
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faAngleDown} />}>
        <Typography variant="titleSmall">{t("isaState")}</Typography>
        <Typography
          sx={{
            textAlign: "right",
            color: theme.palette.primary.textSubduedColor
          }}
          variant="body"
        >
          Description text
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" gap="24px">
          <Stack direction="column" gap="8px">
            <Stack direction="row" gap="4px">
              <Typography variant="bodyLarge">{t("isaCode")}:</Typography>
              <Typography variant="bodyLarge700">CG37U</Typography>
            </Stack>
            <Stack direction="row" gap="4px" alignItems="center">
              <Typography variant="bodyLarge">{t("isaScore")}</Typography>
              <Typography variant="bodyLarge700">10</Typography>
              <Tooltip
                title={
                  <>
                    <div>
                      {`>= 9 Esclusione disciplina società non operative ed
                      esclusioni redditometri`}
                    </div>
                    <div>{`>= 8,5 Accertamenti solo analitici`}</div>
                    <div>
                      {`>=9,8 Esonero dal visto di conformità per utilizzo in
                      compensazione fino a 20.000 euro annui di crediti relativi
                      alle imposte dirette e all'IRAP`}
                    </div>
                  </>
                }
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ color: theme.palette.primary.interactiveDefault }}
                />
              </Tooltip>
            </Stack>
          </Stack>
          <Stack direction="column" gap="8px">
            <Stack direction="row" gap="4px">
              <Typography variant="bodyLarge">
                {t("isaPrepopulatedData")}:
              </Typography>
              <Typography variant="bodyLarge700">{t("notPresent")}</Typography>
            </Stack>
            <Stack direction="row" gap="4px">
              <Typography variant="bodyLarge">
                {t("declaredRevenues")}:
              </Typography>
              <Typography
                variant="bodyLarge700"
                sx={{ flex: "1", textAlign: "end" }}
              >
                5.149.495,00
              </Typography>
            </Stack>
            <Stack direction="row" gap="4px">
              <Typography variant="bodyLarge">
                {t("positiveComponentsInModel")}:
              </Typography>
              <Typography
                variant="bodyLarge700"
                sx={{ flex: "1", textAlign: "end" }}
              >
                5.149.495,00
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="column" gap="8px">
            <Stack direction="row" gap="4px">
              <Typography variant="bodyLarge">
                {t("adaptationMethod")}:
              </Typography>
              <Typography variant="bodyLarge700">
                {t("noAdaptation")}
              </Typography>
            </Stack>
            <Stack direction="row" gap="4px">
              <Typography variant="bodyLarge">
                {t("adaptationValue")}:
              </Typography>
              <Typography
                variant="bodyLarge700"
                sx={{ flex: "1", textAlign: "end" }}
              >
                5.149.495,00
              </Typography>
            </Stack>
            <Stack direction="row" gap="4px">
              <Typography variant="bodyLarge">
                {t("ivaValueToGiveForAdaptation")}:
              </Typography>
              <Typography
                variant="bodyLarge700"
                sx={{ flex: "1", textAlign: "end" }}
              >
                5.149.495,00
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

import { useTranslation } from "@onefront/react-sdk";

import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import Grid from "@vapor/react-material/Grid";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { faIdCard, faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@vapor/react-material/styles";

import { useSidebarMenu } from "../providers/MenuProvider";
import { useCompanySettingsContext } from "../providers/CompanySettingsProvider";

export const CompanySettingsGrid = ({ children, editable = true }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { selectedItem } = useSidebarMenu();
  const { isEditMode, setIsEditMode, save, handleOpenDialog, anyChange } =
    useCompanySettingsContext();

  return (
    <>
      <Stack
        direction="column"
        alignItems="flex-start"
        gap="24px"
        sx={{ width: "100%" }}
      >
        <Stack
          sx={{ height: "32px" }}
          alignItems="center"
          gap="16px"
          direction="row"
        >
          <FontAwesomeIcon
            icon={faIdCard}
            color={theme.palette.primary.textTitleColor}
            size="xl"
          />
          <Typography
            variant="titleMedium"
            color={theme.palette.primary.textTitleColor}
            component="div"
          >
            {t(selectedItem?.parentKey)}
          </Typography>
        </Stack>
        <Stack
          sx={{ height: "32px", width: "100%" }}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Typography
            variant="titleSmall"
            color={theme.palette.primary.textTitleColor}
            component="div"
          >
            {t(selectedItem?.translationKey)}
          </Typography>
          <Stack direction="row">
            {!isEditMode && editable && (
              <Button
                variant="text"
                startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
                onClick={() => setIsEditMode(true)}
              >
                {t("modify")}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Grid
        container
        spacing={4}
        justifyContent="space-between"
        sx={{ width: "70%" }}
      >
        {Array.isArray(children) ? (
          children.map((child, index) => {
            return (
              child && (
                <Grid item xs={child?.props?.fullRow ? 12 : 6} key={index}>
                  {child}
                </Grid>
              )
            );
          })
        ) : (
          <Grid item xs={children?.props?.fullRow ? 12 : 6}>
            {children}
          </Grid>
        )}
      </Grid>
      {isEditMode && (
        <Stack direction="row" alignItems="center" gap="16px">
          <Button variant="contained" onClick={save}>
            {t("save")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              if (anyChange) handleOpenDialog();
              else setIsEditMode(false);
            }}
          >
            {t("cancel")}
          </Button>
        </Stack>
      )}
    </>
  );
};

import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box, CircularProgress } from "@vapor/react-material";
import Stack from "@vapor/react-material/Stack";
import { useEffect, useState } from "react";
import { useTranslation } from "@onefront/react-sdk";

const messages = [
  "initializing_tax_report_step_1",
  "initializing_tax_report_step_2",
  "initializing_tax_report_step_3",
  "initializing_tax_report_step_4"
];

export const TaxReportInitialLoading = ({
  fiscaliRequestPending,
  isIssueCommandLoading,
  ...props
}) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isIssueCommandLoading && fiscaliRequestPending) {
      setMessageIndex(3);
    } else {
      const interval = setInterval(() => {
        setMessageIndex((prevIndex) =>
          prevIndex < 2 ? prevIndex + 1 : prevIndex
        );
      }, 4000);

      return () => clearInterval(interval);
    }
  }, [isIssueCommandLoading, fiscaliRequestPending]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
      }}
    >
      <Stack direction="column" sx={{ alignItems: "center" }} spacing={2}>
        <CircularProgress />
        <Typography variant="h6">
          {t("initializing_tax_report_title")}
        </Typography>
        <Typography variant="body2">{t(messages[messageIndex])}</Typography>
      </Stack>
    </Box>
  );
};

import { useTheme } from "@vapor/react-material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";

import Stack from "@vapor/react-material/Stack";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Tooltip from "@vapor/react-material/Tooltip";

import TaxPayerAvatar from "../../../components/TaxPayerAvatar";

export const TaxPayerCell = ({ row, modal = false, workspaceUrl }) => {
  const { t } = useTranslation("foa");
  const heightExternalLinkIcon = modal ? 24 : 20;

  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={modal ? "flex-start" : "space-between"}
      sx={
        !modal && {
          width: "100%"
        }
      }
    >
      <Stack direction="row" alignItems="center">
        <TaxPayerAvatar
          taxPayerType={row?.taxpayer_type}
          sx={{
            backgroundColor: theme.palette.primary.primary10,
            height: 32,
            width: 32
          }}
        />

        <Typography
          variant="body"
          sx={{
            marginLeft: "8px",
            marginRight: "8px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            ...(!modal && { width: "210px" })
          }}
        >
          {row?.taxpayer_name}
        </Typography>
      </Stack>
      <Tooltip
        slotProps={{
          tooltip: {
            sx: {
              "&.MuiTooltip-tooltip": {
                maxWidth: "241px"
              }
            }
          }
        }}
        placement="top"
        arrow
        title={t("workspaceIconTooltip")}
      >
        <FontAwesomeIcon
          icon={faExternalLink}
          color={theme.palette.primary.primary60Color}
          style={{
            cursor: "pointer",
            height: heightExternalLinkIcon,
            width: 18
          }}
          onClick={() => {
            window.location.href = `${workspaceUrl}/details/${row?.taxpayer_id}/registry`;
          }}
        />
      </Tooltip>
    </Stack>
  );
};

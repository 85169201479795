import { TaxReportPage } from "./components/TaxReportPage";
import { TelematicoWrapper } from "./providers/telematicoDataProvider";

const BASE_PATH = "/tax-report/:taxpayerId/:id";

export const taxReportPage = () => {
  return [
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        path: BASE_PATH,
        element: <TaxReportPage />
      }
    },
    {
      target: "$REACT_ROOT_WRAPPER",
      handler: {
        component: TelematicoWrapper
      }
    }
  ];
};

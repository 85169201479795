import { useMemo } from "react";

import { useTranslation } from "@onefront/react-sdk";

import Typography from "@vapor/react-extended/ExtendedTypography";
import ListItemButton from "@vapor/react-material/ListItemButton";
import { useTheme } from "@vapor/react-material/styles";

import { useReportPageData } from "../../providers/reportPageDataProvider";

export const ReportDataSectionsMenuItem = ({ section }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    reportDataSelectedSection: selectedSection,
    setReportDataSelectedSection,
    menuItemsRef
  } = useReportPageData();

  const isSelected = useMemo(
    () => selectedSection === section,
    [selectedSection, section]
  );

  const handleClick = (section) => {
    setReportDataSelectedSection(section);
    if (menuItemsRef.current[section])
      menuItemsRef.current[section].scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
        block: "nearest"
      });
  };

  return (
    <ListItemButton
      sx={{
        width: "90%",
        background: isSelected ? theme.palette.primary.azure : null,
        border: `${isSelected ? "1px solid #008FD6" : null} !important`,
        "&:hover": {
          background: `${
            isSelected
              ? theme.palette.primary.azure
              : theme.palette.primary.interactiveDefaultColor
          } !important`,
          "& .MuiTypography-root": {
            color: `${
              isSelected
                ? theme.palette.primary.textTitleColor
                : theme.palette.primary.white
            } !important`
          }
        }
      }}
      onClick={() => handleClick(section)}
    >
      <Typography
        variant="body"
        sx={{
          color: theme.palette.primary.textTitleColor,
          "&:hover": {
            color: `${
              isSelected
                ? theme.palette.primary.textTitleColor
                : theme.palette.primary.white
            } !important`
          }
        }}
      >
        {t(section)}
      </Typography>
    </ListItemButton>
  );
};

import { useQuery } from "@onefront/react-sdk";

const GET_WIDGET_GO_TO = `query WidgetGoTo($limit: Int = 5, $tax_reports: [String!] = []) {
    tax_compliance_public_entries(
      where: {deadline: {_gt: "now"}, tax_report: {_in: $tax_reports}}, 
      limit: $limit
      order_by: [{deadline: asc}, {tax_report: asc}, {year: asc}], distinct_on: [tax_report, year, deadline]) {
      tax_report
      year
      deadline
    }}`;

export const useGetWidgetGoTo = ({ limit, taxReports, tenantId }) =>
  useQuery(
    GET_WIDGET_GO_TO,
    { limit, tax_reports: taxReports },
    {
      headers: {
        "tenant-id": tenantId
      },
      lazy: true
    }
  );

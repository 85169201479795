import { createContext, useContext, useEffect, useMemo, useState } from "react";

const KpiDueDatesContext = createContext();

export const KpiDueDatesWrapper = ({ children, initialKpiDueDates = [] }) => {
  const [kpiDueDates, setKpiDueDates] = useState(initialKpiDueDates);
  const [loading, setLoading] = useState(false);

  const hookData = useMemo(
    () => ({
      kpiDueDates,
      setKpiDueDates,
      loading,
      setLoading
    }),
    [kpiDueDates, setKpiDueDates, loading, setLoading]
  );

  useEffect(() => {
    setLoading(false);
  }, [kpiDueDates]);

  return (
    <KpiDueDatesContext.Provider value={hookData}>
      {children}
    </KpiDueDatesContext.Provider>
  );
};

export const useKpiDueDatesContext = () => useContext(KpiDueDatesContext);

import { useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { ExtendedSelect } from "@onefront/workspace-forms/components/General/ExtendedSelect/ExtendedSelect.js";
import { ExtendedMenuItem } from "@onefront/workspace-forms/components/General/ExtendedMenuItem/ExtendedMenuItem.js";
import { ExtendedDatePicker } from "@onefront/workspace-forms/components/General/ExtendedDatePicker/ExtendedDatePicker.js";
import { AmountTextfield } from "@onefront/workspace-forms/components/General/AmountTextfield/AmountTextfield.js";

import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { onChangeHandlerField } from "../../../handlers";
import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import { ExtendedToggle } from "../../ExtendedToggle";

export const TenantSCGeneral = () => {
  const { t } = useTranslation();
  const {
    companySettings,
    setCompanySettings,
    sotCompanySettings,
    isEditMode,
    setIsEditMode,
    loading,
    setAnyChange
  } = useCompanySettingsContext();

  const onChangeHandler = (e) => {
    setAnyChange(true);
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  return (
    <CompanySettingsGrid>
      <ExtendedSelect
        label={t("cooperativeType")}
        name="cooperativeType"
        value={companySettings.cooperativeType}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem
          label={t("withPrevailingMutuality")}
          value="withPrevailingMutuality"
        >
          {t("withPrevailingMutuality")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("mainlyMutualWithoutArticle10")}
          value="mainlyMutualWithoutArticle10"
        >
          {t("mainlyMutualWithoutArticle10")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("mainlyMutualWithArticle10")}
          value="mainlyMutualWithArticle10"
        >
          {t("mainlyMutualWithArticle10")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("ofProductionAndWork")}
          value="ofProductionAndWork"
        >
          {t("ofProductionAndWork")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("ofConsumptionWithMutuality")}
          value="ofConsumptionWithMutuality"
        >
          {t("ofConsumptionWithMutuality")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("socialsWithoutArticle11")}
          value="socialsWithoutArticle11"
        >
          {t("socialsWithoutArticle11")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("socialsWithArticle11")}
          value="socialsWithArticle11"
        >
          {t("socialsWithArticle11")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("withoutPrevailingMutuality")}
          value="withoutPrevailingMutuality"
        >
          {t("withoutPrevailingMutuality")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <ExtendedDatePicker
        name="budgetApprovalDate"
        noValueLabel={t("notSet")}
        editing={isEditMode}
        value={companySettings.BudgetApprovalDate}
        onChange={(name, value) =>
          onChangeHandler({
            target: { name, value: value.replace("T00:00:00", "") }
          })
        }
        label={t("budgetApprovalDate")}
        loading={loading}
      />
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.nonResidentEntity}
        label={t("nonResidentEntity")}
        name="nonResidentEntity"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <AmountTextfield
        label={t("netAssets")}
        name="netAssets"
        value={companySettings.netAssets}
        onValueChange={(name, value) => {
          onChangeHandler({ target: { name, value } });
        }}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
      />
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.iap}
        label={t("iap")}
        name="iap"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.irapPublicEntities}
        label={t("irapPublicEntities")}
        name="irapPublicEntities"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedSelect
        label={t("iresAdditionals")}
        name="iresAdditionals"
        value={companySettings.iresAdditionals}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem
          label={t("creditInstitutions")}
          value="creditInstitutions"
        >
          {t("creditInstitutions")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("motorwayConcessions")}
          value="motorwayConcessions"
        >
          {t("motorwayConcessions")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <ExtendedSelect
        label={t("iresAdditionalsPreviousYear")}
        name="iresAdditionalsPreviousYear"
        value={companySettings.iresAdditionalsPreviousYear}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem
          label={t("creditInstitutionsPreviousYear")}
          value="creditInstitutionsPreviousYear"
        >
          {t("creditInstitutionsPreviousYear")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("motorwayConcessionsPreviousYear")}
          value="motorwayConcessionsPreviousYear"
        >
          {t("motorwayConcessionsPreviousYear")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.taxCalculationManagement}
        label={t("taxCalculationManagement")}
        name="taxCalculationManagement"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.withdrawalFromTaxDraft}
        label={t("withdrawalFromTaxDraft")}
        name="withdrawalFromTaxDraft"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.imuEncModelManagement}
        label={t("imuEncModelManagement")}
        name="imuEncModelManagement"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
    </CompanySettingsGrid>
  );
};

import { forwardRef, useEffect, useState } from "react";

import { useSnackbar, useTranslation } from "@onefront/react-sdk";

/* import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */
import VaporUploader from "@vapor/react-custom/VaporUploader";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";

import { formatAmount, formatDate } from "../../../../utils";
import { useCqrs } from "../../../../services/cqrs";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useTaxReportData } from "../../providers/taxReportDataProvider";
import TaxReportPageAccordion from "../TaxReportPageAccordion";

const TaxReportPagePreviousTaxData = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { issueCommand, isIssueCommandLoading } = useCqrs();
  const { taxReportData, setTaxReportData } = useTaxReportData();
  const { year, checksData } = props;
  const [
    isPreviousYearTelematicoUploaded,
    setIsPreviousYearTelematicoUploaded
  ] = useState(false);

  useEffect(() => {
    if (taxReportData?.document?.data)
      setIsPreviousYearTelematicoUploaded(
        taxReportData?.document?.data?.previousYearTelematico === undefined ||
          taxReportData?.document?.data?.previousYearTelematico === null
      );
  }, [taxReportData]);

  const onFileUpload = async (filesItems) => {
    const fr = new FileReader();
    fr.onload = async () => {
      try {
        const cmdResult = await issueCommand(
          "import-telematico",
          "tax-reports",
          {
            uuid: taxReportData?.document?.uuid,
            version: taxReportData?.document?.version,
            tenant_id: taxReportData?.document?.tenant_id,
            taxpayer_id: taxReportData?.taxpayer_id,
            user: taxReportData?.document?.user,
            telematico: fr.result
          },
          {
            headers: { "taxpayer-id": taxReportData.taxpayer_id },
            shouldComplete: true
          }
        );

        if (cmdResult.response) {
          setTaxReportData(cmdResult.response.update_tax_report);
          setIsPreviousYearTelematicoUploaded(false);
        }
      } catch (error) {
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000
        });
      }
    };
    fr.readAsText(filesItems[0]);
  };

  return (
    <TaxReportPageAccordion
      ref={ref}
      title={t("previousYearDeclaration")}
      subtitle={t("lastChangeDateTemplate", {
        date: formatDate(checksData?.lastUpdate),
        interpolation: { escapeValue: false }
      })}
      accordionDetailsSx={{ minHeight: "176px" }}
    >
      {isIssueCommandLoading && (
        <Box
          sx={{
            width: "100%"
          }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {!isPreviousYearTelematicoUploaded && (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyLarge">{t("reportedVL8")}</Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="bodyLarge700">
                {formatAmount(
                  taxReportData?.document?.data?.previousYearTelematico?.VL_08
                )}
              </Typography>
              {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyLarge">{t("reportedVL9")}</Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="bodyLarge700">
                {formatAmount(
                  taxReportData?.document?.data?.previousYearTelematico?.VL_09
                )}
              </Typography>
              {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyLarge">
              {t("yearlyCredit")} {year - 1} {t("reportedVL25")}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="bodyLarge700">
                {formatAmount(
                  taxReportData?.document?.data?.previousYearTelematico?.VL_25
                )}
              </Typography>
              {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="bodyLarge">
              {t("shellCompanies")}
              {taxReportData?.document?.data?.previousYearTelematico?.VA_15}
            </Typography>
          </Stack>
        </>
      )}
      {isPreviousYearTelematicoUploaded && (
        <Box>
          <VaporUploader
            draggable
            draggableText={t("dragHere")}
            buttonLabel={t("uploadFile")}
            dropText={t("dropFile")}
            label={t("telematicImport")}
            description={t("onlyTxt")}
            buttonVariant="outlined"
            onFileUpload={onFileUpload}
            disabled={isIssueCommandLoading}
          />
        </Box>
      )}
    </TaxReportPageAccordion>
  );
});

export default TaxReportPagePreviousTaxData;

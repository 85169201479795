import { DEFAULT_PAGE } from "./DEFAULT_PAGE";
import { IRAP } from "./IRAP";
import { IVATR } from "./IVATR";
import { IVN } from "./IVN";
import { LIPE } from "./LIPE";
import { WNC } from "./WNC";
import { WPF } from "./WPF";
import { WSC } from "./WSC";
import { WSP } from "./WSP";

export const taxReportBodyStrategies = [
  IVN,
  WSP,
  WPF,
  WSC,
  WNC,
  IRAP,
  LIPE,
  IVATR,
  DEFAULT_PAGE
];

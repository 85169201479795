import { FiscaliSaasIframeProvider } from "./FiscaliSaasIframeProvider";

export const fiscaliSaasIframeService = () => {
  return [
    {
      target: "$REACT_ROOT_WRAPPER",
      handler: {
        component: FiscaliSaasIframeProvider
      }
    }
  ];
};

import { Card } from "@vapor/react-material/Card";
import { Stack } from "@vapor/react-material/Stack";
import IconButton from "@vapor/react-material/IconButton";
import React, { useState } from "react";

import Typography from "@vapor/react-extended/ExtendedTypography";

import { useTheme } from "@vapor/react-material/styles";
import { useNavigate } from "react-router-dom";

import {
  faArchive,
  faArrowRight,
  faCheckCircle,
  faCircleNotch,
  faCircleX,
  faExclamationCircle,
  faFileCircleCheck,
  faFilePlus,
  faPaperPlane,
  faSquarePollVertical
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PropTypes from "prop-types";
import TypologyInformations from "./TypologyInformations";

const ItemKpi = ({
  status,
  type,
  value,
  declarationType,
  year,
  text,
  order,
  kpi,
  selectedYear
}) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const queryObject = {
    year,
    state: type
  };

  if (declarationType !== "total")
    queryObject.declarationType = declarationType;
  if (text !== "") queryObject.searchText = text;
  if (order !== "0") queryObject.orderBy = order;

  const queryString = new URLSearchParams(queryObject).toString();

  const typeStatus = {
    todo: {
      icon: faFilePlus,
      color: theme.palette.warning.light
    },
    ongoing: {
      icon: faCircleNotch,
      color: theme.palette.success.light
    },
    withErrors: {
      icon: faExclamationCircle,
      color: theme.palette.error.light
    },
    withAlerts: {
      icon: faExclamationCircle,
      color: theme.palette.warning.light
    },
    closed: {
      icon: faCheckCircle,
      color: theme.palette.success.light
    },
    generated: {
      icon: faFileCircleCheck,
      color: theme.palette.success.light
    },
    sent: {
      icon: faPaperPlane,
      color: theme.palette.success.light
    },
    rejected: {
      icon: faCircleX,
      color: theme.palette.error.light
    },
    archived: {
      icon: faArchive,
      color: theme.palette.success.light
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedKpi, setSelectedKpi] = useState(null);

  const handlePopoverOpen = (event, kpi) => {
    setAnchorEl(event.currentTarget);
    setSelectedKpi(declarationType);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Card
      sx={{
        borderRadius: "8px",
        height: "79px",
        Width: "123px"
      }}
    >
      <Stack
        direction="row"
        alignItems="stretch"
        justifyContent="space-between"
      >
        <Stack direction="column">
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                color: "#5C6B75",
                margin: "8px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                flexGrow: 1
              }}
              variant="caption"
            >
              {status}
            </Typography>
            <IconButton
              onClick={(e) => handlePopoverOpen(e, kpi)}
              sx={{ padding: "4px" }}
            >
              <FontAwesomeIcon icon={faSquarePollVertical} color="#008FD6" />
            </IconButton>
            <TypologyInformations
              handlePopoverClose={handlePopoverClose}
              anchorEl={anchorEl}
              open={open}
              selectedKpi={selectedKpi}
              selectedStatus={type}
              selectedYear={selectedYear}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ marginLeft: "8px" }}
          >
            <FontAwesomeIcon
              key="status"
              style={{
                fontSize: "24px",
                fontWeight: "900",
                lineHeight: "20px"
              }}
              icon={typeStatus[type].icon}
              color={typeStatus[type].color}
            />
            <Typography
              sx={{ color: "#101214", margin: "8px" }}
              variant="displayLarge"
            >
              {value}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: "26px",
            padding: "55px 8px 0px 0px !important",
            height: "80px",
            backgroundColor: "#F4F5F6"
          }}
          alignItems="flex-end"
        >
          <FontAwesomeIcon
            fontSize="16px"
            icon={faArrowRight}
            color={theme.palette.primary.primary60Color}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`./tax-reports?${queryString}`)}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

ItemKpi.propTypes = {
  status: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.number,
  declarationType: PropTypes.string,
  year: PropTypes.number,
  text: PropTypes.string,
  order: PropTypes.string,
  kpi: PropTypes.object,
  selectedYear: PropTypes.number
};

export default ItemKpi;

import { useMemo } from "react";

import ListItem from "@vapor/react-material/ListItem";
import Divider from "@vapor/react-material/Divider";
import { Button } from "@vapor/react-material/Button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "@onefront/react-sdk";

export const Company = ({ id, personalData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fullName = useMemo(() => {
    return (
      personalData?.businessName ??
      `${personalData?.name} ${personalData?.surname}`
    );
  }, [personalData?.businessName, personalData?.name, personalData?.surname]);

  return (
    <>
      <ListItem>
        {fullName}
        <Button
          variant="outlined"
          sx={{ marginInlineStart: "auto" }}
          onClick={() => {
            navigate(`/company/${id}`);
          }}
          startIcon={<FontAwesomeIcon icon={faCog} />}
        >
          {t("Settings")}
        </Button>
      </ListItem>
      <Divider />
    </>
  );
};

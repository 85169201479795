import Typography from "@vapor/react-extended/ExtendedTypography";
import Button from "@vapor/react-material/Button";
import Card from "@vapor/react-material/Card";
import CardActions from "@vapor/react-material/CardActions";
import CardContent from "@vapor/react-material/CardContent";
import CardHeader from "@vapor/react-material/CardHeader";
import Stack from "@vapor/react-material/Stack";

import { useTheme } from "@vapor/react-material/styles";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

const BlockInfo = ({ title, Breakpoint, foot, children, padding = "16px" }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const Header = () => (
    <Stack sx={{ borderBottom: "1px solid #C9D0D4" }}>
      <Typography
        sx={{
          margin: "10px",
          fontSize: 18,
          colorFont: "red"
        }}
        color={theme.palette.primary.textTitleColor}
      >
        {title}
      </Typography>
    </Stack>
  );

  return (
    <Card
      sx={{
        width: `${Breakpoint}px` // * 33.3}%`
      }}
    >
      <CardHeader component={Header} />
      <CardContent sx={{ padding: padding }}>{children}</CardContent>
      {foot && (
        <CardActions
          style={{
            backgroundColor: "#F4F5F6",
            justifyContent: "flex-end"
          }}
        >
          <Button onClick={() => navigate(`./tax-reports`)}>{foot}</Button>
        </CardActions>
      )}
    </Card>
  );
};

BlockInfo.propTypes = {
  title: PropTypes.string,
  Breakpoint: PropTypes.number,
  foot: PropTypes.string,
  children: PropTypes.node
};

export default BlockInfo;

import { forwardRef } from "react";
import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-extended/ExtendedTypography";
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"; */

import TaxReportPageAccordion from "../TaxReportPageAccordion";

const TaxReportPageLipe = forwardRef((props, ref) => {
  const { data, title, subtitle } = props;

  return (
    <TaxReportPageAccordion ref={ref} title={title} subtitle={subtitle}>
      <Stack direction="column" sx={{ gap: "32px" }}>
        {data &&
          data.map((item, index) => (
            <Stack direction="column" key={index} sx={{ gap: "16px" }}>
              {item.month && (
                <Typography variant="bodyLarge700">{item.month}</Typography>
              )}
              {item.items.map((row, index) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  key={`row-${index}`}
                >
                  <Typography variant="bodyLarge">{row.text}</Typography>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="bodyLarge700">{row.amount}</Typography>
                    {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          ))}
      </Stack>
    </TaxReportPageAccordion>
  );
});

export default TaxReportPageLipe;

import { Stack } from "@vapor/react-material/Stack";
import { useEffect, useState } from "react";
import ItemKpi from "./ItemKpi";

import { useTranslation } from "@onefront/react-sdk";

import { useKpiListContext } from "../../providers/kpi-list-wrapper";

import PropTypes from "prop-types";

const FilterByKpi = ({ year = 2024, taxReport, text, order }) => {
  const { t } = useTranslation("foa");

  const { kpiList } = useKpiListContext();

  const [kpi, setKpi] = useState({});
  const [kpiData, setKpiData] = useState([]);

  const state = [
    "sent",
    "closed",
    "ongoing",
    "withAlerts",
    "withErrors",
    "todo",
    "rejected"
  ];

  useEffect(() => {
    setKpiData(kpiList.filter((kpi) => kpi.year === year));
  }, [year, kpiList]);

  useEffect(() => {
    Array.isArray(kpiData) && kpiData?.length > 0
      ? setKpi(kpiData?.[0].data?.[taxReport])
      : setKpi({});
  }, [kpiData, taxReport]);

  return (
    <Stack
      sx={{ margin: "16px 0" }}
      direction="row"
      justifyContent="flex-start"
      spacing={1}
      useFlexGap
      flexWrap="wrap"
    >
      {state.map((status) => (
        <ItemKpi
          key={status}
          status={t(status)}
          type={status}
          value={kpi?.[status]?.total || 0}
          declarationType={taxReport}
          year={year}
          text={text}
          order={order}
          kpi={kpi}
          selectedYear={year}
        />
      ))}
    </Stack>
  );
};

FilterByKpi.propTypes = {
  year: PropTypes.number,
  taxReport: PropTypes.string,
  text: PropTypes.string,
  order: PropTypes.string
};

export default FilterByKpi;

import { WorkspaceProvider } from "./workspaceProvider";

export const workspaceService = () => {
  return [
    {
      target: "$REACT_ROOT_WRAPPER",
      handler: {
        component: WorkspaceProvider
      }
    }
  ];
};

import { useTranslation, useProxy } from "@onefront/react-sdk";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";

export const ReportDataButton = () => {
  const { t } = useTranslation();
  const { createJumpUrl } = useProxy();

  return (
    <>
      <Stack>
        <Button
          onClick={() => {
            window.location.href = createJumpUrl(
              `rnd::saas:tax-orchestrator:ivaSettlementApp://iva`
            );
          }}
          variant="contained"
        >
          {t("goToVATSettlement")}
        </Button>
      </Stack>
    </>
  );
};

import Chip from "@vapor/react-material/Chip";
import {
  faCircle as faCircleSolid,
  faDiamond as faDiamondSolid
} from "@fortawesome/free-solid-svg-icons";
import { faRectangle as faRectangleSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import { useState } from "react";

const Chips = ({ setSelectedChip }) => {
  const { t } = useTranslation();
  const [selectedChipLocal, setSelectedChipLocal] = useState(null);
  const [state, setState] = useState();

  const DEPOSITS_ONLY = "depositsOnly";
  const PERIODIC_DECLARATIONS = "periodicDeclarations";
  const ANNUAL_DECLARATIONS = "annualDeclarations";

  const chips = [DEPOSITS_ONLY, PERIODIC_DECLARATIONS, ANNUAL_DECLARATIONS];

  const chipsIcons = [faDiamondSolid, faRectangleSolid, faCircleSolid];

  const handleUnselectChip = () => {
    setSelectedChipLocal(null);
    setSelectedChip(null);
    setState(null);
  };

  const handleChipClick = (chip) => {
    if (chip === selectedChipLocal) {
      handleUnselectChip();
    } else {
      setSelectedChipLocal(chip);
      setSelectedChip(chip);
      setState(chip);
    }
  };

  return (
    <>
      {chips.map((chip, index) => (
        <Chip
          size="medium"
          icon={<FontAwesomeIcon icon={chipsIcons[index]} />}
          key={chip}
          clickable
          label={t(chip)}
          onClick={() => handleChipClick(chip)}
          variant={state === chip ? "outlined" : "filled"}
        />
      ))}
    </>
  );
};

export default Chips;

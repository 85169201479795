import { createElement } from "react";
import { constants } from "@fiscal-orchestrator/fior-utils";
import { IVN_V1 } from "./IVN_V1";
import { IVN_V2 } from "./IVN_V2";
import { DEFAULT_PAGE } from "../DEFAULT_PAGE";

const modelPerYearMapping = {
  2023: IVN_V1,
  2024: IVN_V2
};

const tabs = {
  contents: {
    checks: "checks",
    reportData: "reportData",
    result: "result",
    model: "model"
  },
  firstContent: "checks"
};

export const IVN = {
  match: (taxReport) => {
    return taxReport === constants.taxReportTypes.iva;
  },
  render: ({ year }) => {
    const componentToRender = modelPerYearMapping[year] || DEFAULT_PAGE;
    return createElement(componentToRender, { tabs: tabs });
  }
};

export const taxReportColor = [
  { name: "IVN", color: "#FC4E3D" },
  { name: "LIPE I", color: "#E2AB37" },
  { name: "LIPE II", color: "#E2AB37" },
  { name: "LIPE III", color: "#E2AB37" },
  { name: "LIPE IV", color: "#E2AB37" },
  { name: "IVATR I", color: "#FF387A" },
  { name: "IVATR II", color: "#FF387A" },
  { name: "IVATR III", color: "#FF387A" },
  { name: "WSC", color: "#349d84" },
  { name: "WPF", color: "#349d84" },
  { name: "WSP", color: "#349d84" },
  { name: "IRAP", color: "#943101" }
];

export const getColorForTaxReport = (taxReport) => {
  const colorItem = taxReportColor.find(
    (item) => item.name.toLowerCase().trim() === taxReport.toLowerCase().trim()
  );
  return colorItem ? colorItem.color : "#000000";
};

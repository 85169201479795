import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useCurrentTenant } from "@drift/oneplatfront";
import { useSnackbar, useTokenData } from "@onefront/react-sdk";
import { ValidationError } from "@reviso/foa-schemas";

import { useCqrs } from "../../../services/cqrs";
import {
  formatToSaveCompanySettings,
  generateDefaultCompanySettings,
  preparePayloadCompanyData,
  simplifyCompanySettings
} from "../components/helpers";
import { useGetCompanySettings } from "../hooks/getCompanySettings";
import { useWorkspaceData } from "./WorkspaceDataProvider";

const CompanySettingsContext = createContext();

export const CompanySettingsWrapper = ({ children }) => {
  const { tenant } = useCurrentTenant(true);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const {
    data,
    fetch: companySettingsFetch,
    loading
  } = useGetCompanySettings();
  const sub = useTokenData("sub");
  const { issueCommand } = useCqrs();
  const { taxPayerType } = useWorkspaceData();
  const [companySettings, setCompanySettings] = useState({});
  const [sotCompanySettings, setSotCompanySettings] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [firstSave, setFirstSave] = useState(true);
  const [documentVersion, setDocumentVersion] = useState();
  const [companySettingsUuid, setCompanySettingsUuid] = useState();
  const [issueCommandLoading, setIssueCommandLoading] = useState(false);
  const [anyChange, setAnyChange] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const save = async () => {
    try {
      setIssueCommandLoading(true);
      const res = formatToSaveCompanySettings(
        generateDefaultCompanySettings(),
        companySettings,
        taxPayerType
      );
      const companyData = {
        sub,
        tenantId: tenant.itemId,
        taxpayer_type: taxPayerType,
        companySettings: res,
        ...(firstSave
          ? {}
          : {
              uuid: companySettingsUuid,
              version: documentVersion
            })
      };

      const resp = await issueCommand(
        firstSave ? "create" : "update",
        "company-data",
        preparePayloadCompanyData(companyData),
        {
          headers: {
            "taxpayer-id": id
          },
          shouldComplete: true
        }
      );

      if (!resp?.error?.errors) {
        if (firstSave) {
          setFirstSave(false);
          setCompanySettingsUuid(resp.cmd_id);
        }
        setDocumentVersion(resp.cmd_id);
        const simplifiedCompanySettings = simplifyCompanySettings(
          resp.payload.document
        );
        setSotCompanySettings(simplifiedCompanySettings.values);
        setCompanySettings(simplifiedCompanySettings.values);
        setIsEditMode(false);
        setAnyChange(false);
      }
    } catch (e) {
      console.error(e);
      if (e instanceof ValidationError) {
        //TODO: temporary solution to show form errors
        enqueueSnackbar(
          `${e.errors[0]?.instancePath}: ${e.errors[0]?.message}`,
          {
            variant: "error",
            autoHideDuration: 3000
          }
        );
      }
    } finally {
      setIssueCommandLoading(false);
    }
  };

  const load = () => {
    if (tenant?.itemId) {
      companySettingsFetch({
        taxpayerId: id,
        tenantId: tenant?.itemId
      });
    }
  };

  const cancel = () => {
    setIsEditMode(false);
    load();
  };

  useEffect(() => {
    load();
  }, [tenant?.itemId]);

  useEffect(() => {
    if (data) {
      if (data.company_data_public_entries?.length > 0) {
        setFirstSave(false);
        const { uuid, document, version } = data.company_data_public_entries[0];

        const simplifiedCompanySettings = simplifyCompanySettings(document);
        setCompanySettingsUuid(uuid);
        setCompanySettings(simplifiedCompanySettings.values);
        setSotCompanySettings(simplifiedCompanySettings.values);
        setDocumentVersion(version);
      }
    } else {
      const defaultCompanySettings = simplifyCompanySettings(
        generateDefaultCompanySettings()
      ).values;
      setCompanySettings(defaultCompanySettings);
      setSotCompanySettings(defaultCompanySettings);
    }
  }, [data]);

  return (
    <CompanySettingsContext.Provider
      value={{
        companySettings,
        setCompanySettings,
        isEditMode,
        setIsEditMode,
        loading: loading || issueCommandLoading,
        save,
        cancel,
        setAnyChange,
        anyChange,
        sotCompanySettings,
        isDialogOpen,
        handleOpenDialog
      }}
    >
      {children}
    </CompanySettingsContext.Provider>
  );
};

export const useCompanySettingsContext = () =>
  useContext(CompanySettingsContext);

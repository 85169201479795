import React, { useEffect, useState } from "react";
import { useCurrentTenant } from "@drift/oneplatfront";

import VaporPage from "@vapor/react-custom/VaporPage";
import Box from "@vapor/react-material/Box";

import { useGetTaxReport } from "../../../hooks/useGetTaxReport";
import { useTaxReportData } from "../providers/taxReportDataProvider";
import { TaxReportPageHeader } from "./TaxReportPageHeader";
import { TaxReportPageSubheader } from "./TaxReportPageSubheader";
import { TaxReportPageFooter } from "./TaxReportPageFooter";
import { taxReportBodyStrategies } from "./tax-report-body";
import { useWorkspaceData } from "../../../services/workspace/workspaceProvider";
import { DialogDeleteTaxReport } from "./DialogDeleteTaxReport";
import { TaxReportPageMultipluginHeader } from "./TaxReportPageMultipluginHeader";
import { useCqrs } from "../../../services/cqrs";
import { useFiscaliSaasIframe } from "../../../services/fiscali-saas-iframe/FiscaliSaasIframeProvider";
import { getTaxReport } from "../../../utils/get-tax-report";
import { useGraphql } from "../../../hooks/use-graphql";
import { TaxReportInitialLoading } from "./TaxReportInitialLoading";
import LoadingSpinner from "../../components/LoadingSpinner";

export const TaxReportPage = () => {
  const { data, loading } = useGetTaxReport();
  const { tenant } = useCurrentTenant(true);
  const { query } = useGraphql();
  const {
    taxReportData,
    setTaxReportData,
    initializingTaxReport,
    setInitializingTaxReport,
    resetData
  } = useTaxReportData();
  const { workspaceUrl } = useWorkspaceData();
  const { initializeFiscaliTaxReport, fiscaliRequestPending } =
    useFiscaliSaasIframe();
  const { isIssueCommandLoading } = useCqrs();
  const [pageBody, setPageBody] = useState(null);
  const [otherPluginsTaxReportData, setOtherPluginsTaxReportData] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleChangeTaxReportTypeTab = (taxReportType) => {
    const selectedtaxReport =
      taxReportType === taxReportData.document.taxReport
        ? taxReportData
        : otherPluginsTaxReportData.find(
            (taxReport) => taxReport.document.taxReport === taxReportType
          );
    const strategy = taxReportBodyStrategies.find((strategy) =>
      strategy.match(selectedtaxReport.document.taxReport)
    );
    setPageBody(strategy.render({ year: selectedtaxReport.document.year }));
  };

  useEffect(() => {
    return () => {
      resetData();
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (taxReportData?.document) {
        if (taxReportData?.document?.multipluginReferences) {
          const otherPluginsData = [];
          for (const otherPluginTaxReportId of Object.values(
            taxReportData.document.multipluginReferences
          )) {
            const otherPluginTaxReport = await getTaxReport({
              query,
              id: otherPluginTaxReportId,
              taxpayerId: taxReportData.taxpayer_id,
              itemId: tenant?.itemId
            });
            otherPluginsData.push(
              otherPluginTaxReport.data?.tax_reports_get_curr_item[0]
            );
          }
          setOtherPluginsTaxReportData(otherPluginsData);
        } else {
          const strategy = taxReportBodyStrategies.find((strategy) =>
            strategy.match(taxReportData.document.taxReport)
          );
          setPageBody(strategy.render({ year: taxReportData.document.year }));
        }
      }
    })();
  }, [taxReportData]);

  useEffect(() => {
    (async () => {
      if (!loading) {
        setTaxReportData(data?.tax_reports_get_curr_item[0]);
        if (
          data?.tax_reports_get_curr_item[0]?.document?.states?.wfsState !==
          "Todo"
        ) {
          const newTaxReport = await initializeFiscaliTaxReport(
            data?.tax_reports_get_curr_item[0]
          );

          if (newTaxReport) setTaxReportData(newTaxReport);
        }
      }
    })();
  }, [data]);

  if (
    initializingTaxReport &&
    !fiscaliRequestPending &&
    !isIssueCommandLoading
  ) {
    setInitializingTaxReport(false);
  }

  if (
    initializingTaxReport &&
    (fiscaliRequestPending || isIssueCommandLoading)
  ) {
    return (
      <TaxReportInitialLoading
        {...{ fiscaliRequestPending, isIssueCommandLoading }}
      />
    );
  }

  return (
    <>
      {/* 104px is the sum ofof the header and footer heights */}
      <VaporPage
        sx={{
          height: "calc(100vh - 108px)",
          minHeight: "calc(100vh - 108px)",
          display: "flex",
          flexDirection: "column",
          ">.MuiBox-root": {
            display: "flex",
            flexDirection: "column",
            height: "100%"
          }
        }}
      >
        {isIssueCommandLoading && <LoadingSpinner />}
        <TaxReportPageHeader
          workspaceUrl={workspaceUrl}
          setShowDeleteModal={setShowDeleteModal}
        />
        {taxReportData?.document?.multipluginReferences &&
          otherPluginsTaxReportData && (
            <TaxReportPageMultipluginHeader
              onChangeTaxReportTypeTab={handleChangeTaxReportTypeTab}
            />
          )}
        <TaxReportPageSubheader />
        <Box sx={{ overflowY: "auto", flex: "1", overflow: "hidden" }}>
          {pageBody !== null && pageBody}
        </Box>
      </VaporPage>
      {showDeleteModal && (
        <DialogDeleteTaxReport
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          taxReportData={taxReportData}
        />
      )}
      <TaxReportPageFooter />
    </>
  );
};

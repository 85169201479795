import { useEffect } from "react";
import { useTranslation } from "@onefront/react-sdk";
import { ExtendedTextfield } from "@onefront/workspace-forms/components/General/ExtendedTextfield/ExtendedTextfield.js";
import { ExtendedDatePicker } from "@onefront/workspace-forms/components/General/ExtendedDatePicker/ExtendedDatePicker.js";
import { ExtendedSelect } from "@onefront/workspace-forms/components/General/ExtendedSelect/ExtendedSelect.js";
import { ExtendedMenuItem } from "@onefront/workspace-forms/components/General/ExtendedMenuItem/ExtendedMenuItem.js";

import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { onChangeHandlerField } from "../../../handlers";
import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import { ExtendedToggle } from "../../ExtendedToggle";

export const TenantSCPaymentDetails = () => {
  const { t } = useTranslation();
  const {
    sotCompanySettings,
    companySettings,
    setCompanySettings,
    isEditMode,
    setIsEditMode,
    loading,
    setAnyChange
  } = useCompanySettingsContext();

  const onChangeHandler = (e) => {
    setAnyChange(true);
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  return (
    <CompanySettingsGrid>
      <ExtendedSelect
        label={t("trust")}
        name="trust"
        value={companySettings.trust}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem label={t("opaque")} value="opaque">
          {t("opaque")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("transparent")} value="transparent">
          {t("transparent")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("mixed")} value="mixed">
          {t("mixed")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <ExtendedSelect
        label={t("transparency")}
        name="transparency"
        value={companySettings.transparency}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem label={t("art115")} value="art115">
          {t("art115")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("art116")} value="art116">
          {t("art116")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <ExtendedDatePicker
        name="transparencyEndDate"
        editing={isEditMode}
        value={companySettings.transparencyEndDate}
        onChange={(name, value) =>
          onChangeHandler({
            target: { name, value: value.replace("T00:00:00", "") }
          })
        }
        label={t("transparencyEndDate")}
        loading={loading}
        noValueLabel={t("notSet")}
      />
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.consolidated}
        label={t("consolidated")}
        name="consolidated"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedDatePicker
        name="consolidatedEndDate"
        editing={isEditMode}
        value={companySettings.consolidatedEndDate}
        onChange={(name, value) =>
          onChangeHandler({
            target: { name, value: value.replace("T00:00:00", "") }
          })
        }
        label={t("consolidatedEndDate")}
        loading={loading}
        noValueLabel={t("notSet")}
      />
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.consolidating}
        label={t("consolidating")}
        name="consolidating"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedTextfield
        label={t("prospectus")}
        noValueLabel={t("notSet")}
        value={companySettings.prospectus}
        editing={isEditMode}
        name="prospectus"
        onChange={onChangeHandler}
        loading={loading}
      />
    </CompanySettingsGrid>
  );
};

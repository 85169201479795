import React, { useEffect, useState } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Accordion from "@vapor/react-material/Accordion";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import Box from "@vapor/react-material/Box";

import { useWorkspaceData } from "../../../../../services/workspace/workspaceProvider";
import { formatFloatAmount, orderDateHour } from "../../../../../utils";
import { TaxPayerCell } from "../../../../tax-reports-list/components/tax-reports-list-table/TaxPayerCell";
import { AnnualDataDialog } from "../../../helpers/tax-report-dialog-data";
import { useTaxReportData } from "../../../providers/taxReportDataProvider";
import { DialogInfoTaxReport } from "../../DialogInfoTaxReport";
import { TaxReportOtherTaxes } from "../../Result/TaxReportOtherTaxes";
import { TaxReportPageActions } from "../../Result/TaxReportPageActions";
import { TaxReportPageISAState } from "../../Result/TaxReportPageISAState";
import { TaxReportPageQuadri } from "../../Result/TaxReportPageQuadri";
import { TaxReportPageResult } from "../../Result/TaxReportPageResult";
import { TaxReportResult } from "../../Result/TaxReportResult";

import PropTypes from "prop-types";
import { FiscaliSaasIframeWrapper } from "../../FiscaliSaasIframeWrapper";

export const WPF_V1 = ({ tabs, name, ...props }) => {
  const { t } = useTranslation();
  const {
    taxReportData,
    taxReportVisibleContent,
    setTaxReportVisibleContent,
    possibleContents,
    setPossibleContents
  } = useTaxReportData();
  const { workspaceUrl } = useWorkspaceData();
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [expanded, setExpanded] = useState("panel1");

  const dateTaxReport = orderDateHour(taxReportData?.updated_at);

  DialogInfoTaxReport.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };

  const handleClose = (newValue) => {
    setOpenDialog(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setPossibleContents(tabs?.contents);
    setTaxReportVisibleContent(tabs?.firstContent);
  }, []);

  useEffect(() => {
    setOpenDialog(taxReportData.document?.states?.wfsState === "Todo");
  }, [taxReportData]);
  const lc =
    taxReportData?.document?.data?.fiscaliSaas?.dataOut?.[
      "RP Oneri e spese, LC Cedolare secca sulle locazioni&0"
    ];
  const lm =
    taxReportData?.document?.data?.fiscaliSaas?.dataOut?.[
      "LM Imprenditoria giovanile&0"
    ];
  const rr =
    taxReportData?.document?.data?.fiscaliSaas?.dataOut?.[
      "RR Contributi Previdenziali&0"
    ];
  const rn =
    taxReportData?.document?.data?.fiscaliSaas?.dataOut?.["RN Determ. IRPEF&0"];
  const rv =
    taxReportData?.document?.data?.fiscaliSaas?.dataOut?.["RV Add. Irpef&0"];
  const rx =
    taxReportData?.document?.data?.fiscaliSaas?.dataOut?.[
      "RX Compensazioni e rimborsi&0"
    ];

  const resultData = [
    {
      title: t("dryCoupon"),
      value: rx?.["RX_01_c1:4"]
        ? formatFloatAmount(rx?.["RX_01_c1:4"], 2, 2)
        : "0,00",
      subtitle: rx?.["RX_01_c2:4"] ? t("whichRepaymentRequest") : "",
      otherValue: rx?.["RX_01_c2:4"]
        ? formatFloatAmount(rx?.["RX_01_c2:4"], 2, 2)
        : "",
      text: t("iAdvance"),
      valueText: lc?.["LC_02_c1"]
        ? formatFloatAmount(lc?.["LC_02_c1"], 2, 2)
        : "0,00",
      subText: t("iiAdvance"),
      valueSubText: lc?.["LC_02_c2"]
        ? formatFloatAmount(lc?.["LC_02_c2"], 2, 2)
        : "0,00"
    },
    {
      title: t("SubstituteTax"),
      value: t("dataNotAvailable"),
      subtitle: "",
      otherValue: "",
      text: t("iAdvance"),
      valueText: t("dataNotAvailable"),
      subText: t("iiAdvance"),
      valueSubText: t("dataNotAvailable")
    },
    {
      title: t("IvsContributions"),
      value: rr?.["RR_02_c29:1"]
        ? formatFloatAmount(rr?.["RR_02_c29:1"], 2, 2)
        : "0,00",
      subtitle: rr?.["RR_02_c30:1"] ? t("whichRepaymentRequest") : "",
      otherValue: rr?.["RR_02_c30:1"]
        ? formatFloatAmount(rr?.["RR_02_c30:1"], 2, 2)
        : "",
      text: t("iAdvance"),
      valueText: t("dataNotAvailable"),
      subText: t("iiAdvance"),
      valueSubText: t("dataNotAvailable")
    },
    {
      title: t("totalCredit"),
      value: t("dataNotAvailable")
    }
  ];

  const sxFirstRow = { marginTop: "16px" };
  const sx = { marginTop: "8px" };
  const dataWPF = [
    [
      {
        rowStyle: { marginTop: "16px" },
        cell3: <Typography variant="bodyLarge700">{t("credito")}</Typography>,
        cell4: <Typography variant="bodyLarge700">{t("debito")}</Typography>
      },
      {
        rowStyle: sxFirstRow,
        cell1: <Typography variant="bodyLarge700">{t("IRPEF")}</Typography>,
        cell4: (
          <Typography variant="body500">
            {rx?.["RX_01_c1:1"]
              ? formatFloatAmount(rx?.["RX_01_c1:1"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">
            {rn?.["RN_62_c1"]
              ? formatFloatAmount(rn?.["RN_62_c1"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">
            {rn?.["RN_62_c2"]
              ? formatFloatAmount(rn?.["RN_62_c2"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_01_c2:1"]
              ? formatFloatAmount(rx?.["RX_01_c2:1"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("AmountRefunded")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_01_c4:1"]
              ? formatFloatAmount(rx?.["RX_01_c4:1"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("Overpayments")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {" "}
            {rx?.["RX_01_c3:1"]
              ? formatFloatAmount(rx?.["RX_01_c3:1"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("usedCredit")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_01_CredUtil:1"]
              ? formatFloatAmount(rx?.["RX_01_CredUtil:1"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("otherUses")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_01_AltriUtil:1"]
              ? formatFloatAmount(rx?.["RX_01_AltriUtil:1"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: (
          <Typography variant="body">
            {t("CreditOffsettingDeduction")}
          </Typography>
        ),
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_01_c5:1"]
              ? formatFloatAmount(rx?.["RX_01_c5:1"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("remainingF24")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_01_ResiduoF24:1"]
              ? formatFloatAmount(rx?.["RX_01_ResiduoF24:1"], 2, 2)
              : "0,00"}
          </Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">
            {t("IvsContributions")}
          </Typography>
        ),
        cell4: (
          <Typography variant="bodyLarge700">
            {rr?.["RR_02_c29:1"]
              ? formatFloatAmount(rr?.["RR_02_c29:1"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rr?.["RR_02_c30:1"]
              ? formatFloatAmount(rr?.["RR_02_c30:1"], 2, 2)
              : "0,00"}
          </Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">
            {t("totalCreditQuadroRU")}
          </Typography>
        ),
        cell3: (
          <Typography variant="bodyLarge700">
            {t("dataNotAvailable")}
          </Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: <Typography variant="bodyLarge700">{t("dryCoupon")}</Typography>,
        cell4: (
          <Typography variant="body500">
            {rx?.["RX_01_c1:4"]
              ? formatFloatAmount(rx?.["RX_01_c1:4"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">
            {lc?.["LC_02_c1"]
              ? formatFloatAmount(lc?.["LC_02_c1"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">
            {lc?.["LC_02_c2"]
              ? formatFloatAmount(lc?.["LC_02_c2"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_01_c2:4"]
              ? formatFloatAmount(rx?.["RX_01_c2:4"], 2, 2)
              : "0,00"}
          </Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">
            {t("regionalAdditionalTax")}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("debito")}</Typography>,
        cell4: (
          <Typography variant="body500">
            {rx?.["RX_01_c1:2"]
              ? formatFloatAmount(rx?.["RX_01_c1:2"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_01_c2:2"]
              ? formatFloatAmount(rx?.["RX_01_c2:2"], 2, 2)
              : "0,00"}
          </Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">
            {t("municipalAdditionalTax")}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("debito")}</Typography>,
        cell4: (
          <Typography variant="body500">
            {rx?.["RX_01_c1:3"]
              ? formatFloatAmount(rx?.["RX_01_c1:3"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("advance")}</Typography>,
        cell4: (
          <Typography variant="body500">
            {rv?.["RV_17_c8"]
              ? formatFloatAmount(rv?.["RV_17_c8"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_01_c2:3"]
              ? formatFloatAmount(rx?.["RX_01_c2:3"], 2, 2)
              : "0,00"}
          </Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">{t("ethicalTax")}</Typography>
        ),
        cell4: (
          <Typography variant="bodyLarge700">
            {rx?.["RX_01_c1:36"]
              ? formatFloatAmount(rx?.["RX_01_c1:36"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_01_c2:36"]
              ? formatFloatAmount(rx?.["RX_01_c2:36"], 2, 2)
              : "0,00"}
          </Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: <Typography variant="bodyLarge700">{t("VATFE")}</Typography>,
        cell4: (
          <Typography variant="bodyLarge700">
            {rx?.["RX_01_c1:26"]
              ? formatFloatAmount(rx?.["RX_01_c1:26"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_01_c2:26"]
              ? formatFloatAmount(rx?.["RX_01_c2:26"], 2, 2)
              : "0,00"}
          </Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: <Typography variant="bodyLarge700">{t("IVIE")}</Typography>,
        cell4: (
          <Typography variant="bodyLarge700">
            {rx?.["RX_01_c1:25"]
              ? formatFloatAmount(rx?.["RX_01_c1:25"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {rx?.["RX_01_c2:25"]
              ? formatFloatAmount(rx?.["RX_01_c2:25"], 2, 2)
              : "0,00"}
          </Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">{t("separatedTaxes")}</Typography>
        ),
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">{t("SubstituteTax")}</Typography>
        ),
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      }
    ],
    [
      {
        rowStyle: sxFirstRow,
        cell1: (
          <Typography variant="bodyLarge700">
            {t("IRPEFAdvantageRegime")}
          </Typography>
        ),
        cell4: (
          <Typography variant="bodyLarge700">
            {lm?.["LM_46_c1"]
              ? formatFloatAmount(lm?.["LM_46_c1"], 2, 2)
              : lm?.["LM_46_c2"]
              ? formatFloatAmount(lm?.["LM_46_c2"], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="bodyLarge700">
            {lm?.["LM_47_c1"]
              ? formatFloatAmount(lm?.["LM_47_c1"], 2, 2)
              : lm?.["LM_47_c2"]
              ? formatFloatAmount(lm?.["LM_47_c2"], 2, 2)
              : "0,00"}
          </Typography>
        )
      }
    ]
  ];

  return (
    <FiscaliSaasIframeWrapper>
      <Box sx={{ padding: "20px", height: "100%", overflow: "hidden" }}>
        {
          //Result
          taxReportVisibleContent === possibleContents.result && (
            <TaxReportPageResult>
              <TaxReportResult resultData={resultData} />
              <TaxReportOtherTaxes data={dataWPF} />
              <TaxReportPageQuadri />
              <TaxReportPageActions />
              <TaxReportPageISAState />
            </TaxReportPageResult>
          )
        }
        {isOpenDialog && (
          <DialogInfoTaxReport
            data={taxReportData}
            isOpen={isOpenDialog}
            open={isOpenDialog}
            onClose={handleClose}
          >
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
              >
                <Typography variant="titleSmall">
                  {t("dialogTaxReportData1")}
                </Typography>
                <Typography variant="body">
                  {t("dialogTaxReportDate", dateTaxReport).replaceAll(
                    "&#x2F;",
                    "/"
                  )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <TaxPayerCell
                  row={{
                    taxpayer_type: taxReportData?.document?.taxpayerType,
                    taxpayer_name:
                      taxReportData?.document?.input?.workspaces?.companyData
                        ?.personalData?.businessName,
                    taxpayer_id: taxReportData?.taxpayer_id
                  }}
                  modal={true}
                  workspaceUrl={workspaceUrl}
                />
                <AnnualDataDialog />
              </AccordionDetails>
            </Accordion>
          </DialogInfoTaxReport>
        )}
      </Box>
    </FiscaliSaasIframeWrapper>
  );
};

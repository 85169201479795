import { ExtendedTypography } from "@vapor/react-extended";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Skeleton,
  Toggle
} from "@vapor/react-material";
import React from "react";

export const ExtendedToggle = ({
  name,
  label,
  editing = true,
  loading = false,
  toggleLabel = {
    placement: "end",
    label: ""
  },
  value = false,
  onChange,
  helperText,
  required = true,
  error,
  noValueLabel = "",
  positiveLabel = "true",
  negativeLabel = "false"
}) => {
  const handleChange = (event) => {
    event.target.name = name;
    event.target.value = event.target.checked;
    onChange?.(event, event.target.name, event.target.value);
  };

  if (!editing) {
    return (
      <FormControl fullWidth>
        <ExtendedTypography variant="body500" component={"div"} sx={{ mb: 1 }}>
          {label}
        </ExtendedTypography>
        {loading ? (
          <Skeleton
            variant="rectangular"
            width={25}
            height={10}
            animation="wave"
            sx={{
              borderRadius: "8px",
              mb: "30px"
            }}
          />
        ) : (
          <ExtendedTypography
            variant="body"
            gutterBottom
            component="div"
            sx={{
              mb: 0,
              display: "flex",
              flexDirection: "column",
              gap: 1
            }}
            color={noValueLabel ? "primary.main" : "primary.textDisabledColor"}
          >
            {value === undefined || value === null
              ? noValueLabel
              : value
              ? positiveLabel
              : negativeLabel}
          </ExtendedTypography>
        )}
      </FormControl>
    );
  }

  if (loading) {
    return (
      <>
        <ExtendedTypography variant="body500" component={"div"} sx={{ mb: 1 }}>
          {label}
        </ExtendedTypography>
        <Skeleton
          variant="rectangular"
          width={25}
          height={10}
          animation="wave"
          sx={{
            borderRadius: "8px",
            mb: "8px"
          }}
        />
      </>
    );
  }

  return (
    <FormControl fullWidth error={error} required={required}>
      <ExtendedTypography variant="body500" component={"div"} sx={{ mb: 1 }}>
        {label}
      </ExtendedTypography>
      <FormGroup>
        <FormControlLabel
          onChange={handleChange}
          value={value}
          control={
            <Toggle
              sx={{ mb: "12px" }}
              color="primary"
              checked={value ?? false}
            />
          }
          label={toggleLabel.label}
        />
      </FormGroup>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

import { useMemo } from "react";

import { useTranslation } from "@onefront/react-sdk";

import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";
import List from "@vapor/react-material/List";
import { useTheme } from "@vapor/react-material/styles";

import { ReportDataSectionsMenuItem } from "./ReportDataSectionsMenuItem";
import { useReportPageData } from "../../providers/reportPageDataProvider";

export const ReportDataSections = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { filters, sectionsList, filteredData: data } = useReportPageData();

  const isEmpty = useMemo(
    () => Object.values(data).every((array) => array.length === 0),
    [data]
  );

  const sectionColor = useMemo(
    () =>
      filters?.filteredBySource
        ? theme.palette.primary.white
        : theme.palette.primary.interactiveDefault,
    [filters]
  );

  return sectionsList?.length ? (
    <Stack direction="column" marginTop={"20px"}>
      <Typography variant="titleSmall" color={sectionColor}>
        {t("reportDataSections")}
      </Typography>
      {isEmpty ? null : (
        <List>
          {sectionsList?.map((section) => {
            const sectionToShow = data[section]?.filter(
              ({ value }) => value !== 0
            );
            return (
              sectionToShow?.length > 0 && (
                <ReportDataSectionsMenuItem section={section} key={section} />
              )
            );
          })}
        </List>
      )}
    </Stack>
  ) : null;
};

import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Divider from "@vapor/react-material/Divider";

import { useTranslation } from "react-i18next";
import { useAtecoCodes } from "../hooks/ateco-codes";
import { useTaxReportData } from "../providers/taxReportDataProvider";

const group1 = [
  {
    field: "vatIdentifier",
    label: "dialogTaxReportData1Text1"
  },
  {
    field: "taxIdentifier",
    label: "dialogTaxReportData1Text2"
  },
  {
    field: ["atecoCode", "atecoCodeDescription"],
    label: "dialogTaxReportData1Text3"
  },
  {
    field: "classifier",
    label: "dialogTaxReportData1Text4"
  },
  {
    field: "periodicity",
    label: "dialogTaxReportData1Text5"
  },
  {
    field: "business",
    label: "dialogTaxReportData1Text6"
  }
  // TODO: What is "Numero del modulo riferito al singolo business"?
];

const group2 = [
  {
    field: "name",
    label: "dialogTaxReportData2Text4"
  },
  {
    field: "activityCode",
    label: "dialogTaxReportData1Text3"
  },
  {
    field: "modelType",
    label: "dialogTaxReportData2Text1"
  },
  {
    field: "complaintType",
    label: "dialogTaxReportData2Text2"
  },
  {
    field: "module",
    label: "dialogTaxReportData2Text3"
  }
];

const getFieldValue = (companyData, field, t) => {
  if (typeof field === "string") {
    if (field === "periodicity") {
      return t(companyData?.business?.[0]?.vatConfiguration?.vatPeriodicity);
    }
    if (field === "classifier") {
      return companyData?.classifier;
    }
    if (field === "business") {
      return companyData?.business?.length;
    }
    return companyData?.personalData?.[field];
  }
  if (Array.isArray(field)) {
    return field.map((item) => companyData?.business?.[0]?.[item]).join(" - ");
  }
};

export const AnnualDataDialog = () => {
  const { taxReportData } = useTaxReportData();
  const { t } = useTranslation();

  return (
    <Box sx={{ paddingLeft: "40px" }}>
      {taxReportData?.document?.input?.workspaces?.companyData &&
        group1.map((item) => {
          return (
            <Stack key={item.label} spacing={1}>
              <Typography variant="body">
                {t(item.label)}:{" "}
                <Typography variant="bodySmall700">
                  {getFieldValue(
                    taxReportData?.document?.input?.workspaces?.companyData,
                    item.field,
                    t
                  )}
                </Typography>
              </Typography>
            </Stack>
          );
        })}
    </Box>
  );
};

export const MultiactivityDialog = ({ companySettingsDetails }) => {
  const { t } = useTranslation();
  const { data: atecoCodes } = useAtecoCodes();

  if (companySettingsDetails.company_data_public_entries.length > 0) {
    const { modulesManagement } =
      companySettingsDetails.company_data_public_entries[0].document;

    const settings = modulesManagement.map((item) => {
      return group2.reduce((acc, curr) => {
        let value = item[curr.field]?.value;
        if (curr.field === "activityCode") {
          const atecoDesc = atecoCodes?.item?.value?.find(
            (ateco) => ateco.code === value
          )?.description;
          if (atecoDesc) {
            value = value + " - " + atecoDesc;
          }
        }
        if (curr.field === "modelType") {
          value = t(value);
        }
        if (curr.field === "complaintType") {
          value = t(value);
        }
        return {
          ...acc,
          [curr.label]: value
        };
      }, {});
    });

    return settings.map((item, index) => {
      return Object.entries(item).map(([key, value]) => {
        return (
          <>
            {key === "dialogTaxReportData2Text4" ? (
              <>
                <Typography
                  key={key + value}
                  variant="BodyLarge500"
                  sx={{ paddingTop: "10px" }}
                >
                  {value}
                </Typography>
                <Divider />
              </>
            ) : (
              <Typography key={key} variant="body">
                {t(key)}:{" "}
                <Typography key={value} variant="bodySmall700">
                  {value}
                </Typography>
              </Typography>
            )}
          </>
        );
      });
    });
  }
};

import { useState, useRef } from "react";

export const useUpdatr = (timeout = 250) => {
  const timerRef = useRef();
  const valueRef = useRef(0);
  const [value, setValue] = useState(valueRef.current);

  const bump = (_timeout = timeout, callback) =>
    new Promise((resolve) => {
      // Skip update if currently used:
      if (timerRef.current) return;

      const nextTimeout =
        typeof _timeout === "function" ? _timeout(valueRef.current) : _timeout;

      // Schedule the next update:
      timerRef.current = setTimeout(
        () => {
          timerRef.current = null;
          valueRef.current++;
          callback && callback(valueRef.current);
          setValue(valueRef.current);
          resolve();
        },
        typeof nextTimeout === "number" ? nextTimeout : timeout
      );
    });

  return {
    value,
    bump
  };
};

import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";

import { ReportDataTableSection } from "./ReportDataTableSection";
import { useBrowserWindowSize } from "../../../../hooks/useBrowserWindowSize";
import { useTaxReportData } from "../../providers/taxReportDataProvider";
import { useReportPageData } from "../../providers/reportPageDataProvider";
import { EmptyReportData } from "./EmptyReportData";

export const ReportDataTable = () => {
  const { setHeaderMinimized } = useTaxReportData();
  const { filteredData: data } = useReportPageData();
  const { showFilters } = useReportPageData();
  const { height } = useBrowserWindowSize();

  const maxHeightDataTable = showFilters
    ? `${height - 515}px`
    : `${height - 425}px`;

  const listenToScroll = () => {
    const winScroll = document.getElementById(
      "reportData-stack-scrollable"
    ).scrollTop;
    winScroll > 0 ? setHeaderMinimized(true) : setHeaderMinimized(false);
  };

  const boxSection = {
    width: "100%",
    marginBottom: "28px"
  };

  const isEmpty = Object.values(data).every((array) => array.length === 0);

  if (isEmpty)
    return (
      <Box sx={boxSection}>
        <EmptyReportData />
      </Box>
    );

  return (
    <Stack
      id="reportData-stack-scrollable"
      direction="column"
      onScroll={listenToScroll}
      sx={{
        alignItems: "flex-start",
        flexGrow: "1",
        maxHeight: maxHeightDataTable,
        overflowY: "auto"
      }}
    >
      {Object.entries(data).map(([source, fields]) => {
        const fieldsToShow = fields.filter(({ value }) => value !== 0);
        return (
          fieldsToShow.length > 0 && (
            <ReportDataTableSection
              key={source}
              source={source}
              fieldsToShow={fieldsToShow}
            ></ReportDataTableSection>
          )
        );
      })}
    </Stack>
  );
};

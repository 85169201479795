import { forwardRef } from "react";

import { useTranslation } from "@onefront/react-sdk";

import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";

/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"; */

import TaxReportPageAccordion from "../TaxReportPageAccordion";
import { formatDate } from "../../../../utils";

const TaxReportPageCalculationCompany = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { year } = props;
  const date = new Date(`${year}/03/31`);

  return (
    <TaxReportPageAccordion
      ref={ref}
      title={t("shellCompanyCalculation")}
      subtitle={t("calculationDoneOnTemplate", {
        //TODO: Pending from analysts
        date: formatDate(date),
        interpolation: { escapeValue: false }
      })}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="bodyLarge">
          {t("nonConvenientTaxpayer")} {year - 1}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          {/* TODO: Pending from analysts*/}
          <Typography variant="bodyLarge700">dati non disponibili</Typography>
          {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
        </Stack>
      </Stack>
    </TaxReportPageAccordion>
  );
});

export default TaxReportPageCalculationCompany;

import Divider from "@vapor/react-material/Divider";
import Dialog from "@vapor/react-material/Dialog";
import DialogContent from "@vapor/react-material/DialogContent";
import IconButton from "@vapor/react-material/IconButton";
import DialogTitle from "@vapor/react-material/DialogTitle";
import DialogActions from "@vapor/react-material/DialogActions";
import TextField from "@vapor/react-material/TextField";
import Button from "@vapor/react-material/Button";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

import { useTranslation } from "@onefront/react-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

import PropTypes from "prop-types";

export const NotesDialog = ({
  fieldName,
  isNoteDialogOpen,
  setIsNoteDialogOpen,
  notes,
  setNotes
}) => {
  const { t } = useTranslation();
  const [note, setNote] = useState(""); //local state for the note to improve performance

  useEffect(() => {
    setNote(notes[fieldName]);
  }, [fieldName]);

  const handleCancel = () => {
    setIsNoteDialogOpen(false);
    setNote("");
  };

  return (
    <Dialog
      scroll="paper"
      fullWidth
      maxWidth="sm"
      open={isNoteDialogOpen}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {t("notesAboutField", { field: fieldName })}
        <IconButton color="primary" title={t("Close")} onClick={handleCancel}>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <Divider variant="fullWidth" />
      <DialogContent>
        <TextField
          multiline
          rows={8}
          value={note}
          onChange={(e) => {
            // TODO: This is temporary, will have the same value for all the notes in the report data
            setNote(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setIsNoteDialogOpen(false);
          }}
        >
          {t("close")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setIsNoteDialogOpen(false);
            setNotes({ ...notes, [fieldName]: note });
            setNote("");
          }}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NotesDialog.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isNoteDialogOpen: PropTypes.bool.isRequired,
  setIsNoteDialogOpen: PropTypes.func.isRequired,
  notes: PropTypes.object.isRequired,
  setNotes: PropTypes.func.isRequired
};

import Box from "@vapor/react-material/Box";
import Divider from "@vapor/react-material/Divider";
import Stack from "@vapor/react-material/Stack";
import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

import { WorkspaceSidePanelDetails } from "@onefront/workspace-ui";

import { useWorkspaceData } from "../providers/WorkspaceDataProvider";
import { SidebarMenu } from "./SidebarMenu";
import DetailsSidebarSkeleton from "./DetailsSidebarSkeleton";

export const DetailsSidebar = () => {
  const { workspaceData, loading } = useWorkspaceData();

  return (
    <Stack direction={"column"} spacing={2.5}>
      <Box px={4} pt={4}>
        {loading || !workspaceData ? (
          <DetailsSidebarSkeleton />
        ) : (
          <WorkspaceSidePanelDetails workspace={workspaceData} />
        )}
      </Box>
      <>
        <Divider light />
        <Box px={4} pt={2}>
          <ExtendedTypography
            component={"div"}
            variant="titleSmall"
            color="primary.textTitleColor"
          >
            Dati
          </ExtendedTypography>
        </Box>

        <Box
          px={4}
          style={{
            height: "calc(100vh - 455px)",
            minHeight: "calc(100vh - 455px)",
            overflow: "auto"
          }}
        >
          <SidebarMenu />
        </Box>
      </>
    </Stack>
  );
};

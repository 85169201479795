import { createContext, useContext, useEffect, useState } from "react";
import {
  sessionStorageKeyInitialReportData,
  sessionStorageKeyInitialShowFiltersReportData
} from "../../tax-report-page/providers/reportPageDataProvider";

const TaxReportData = createContext();

export const TaxReportDataWrapper = ({ children, initialTaxReportData }) => {
  const [possibleContents, setPossibleContents] = useState({});
  const [taxReportData, setTaxReportData] = useState(initialTaxReportData);
  const [taxReportStatus, setTaxReportStatus] = useState("ongoing");
  const [taxReportVisibleContent, setTaxReportVisibleContent] = useState();
  const [headerMinimized, setHeaderMinimized] = useState(false);
  const [taxReportAssignee, setTaxReportAssignee] = useState();
  const [taxReportLastUpdate, setTaxReportLastUpdate] = useState();
  const [actualTaxReportType, setActualTaxReportType] = useState();
  const [initializingTaxReport, setInitializingTaxReport] = useState(false);

  useEffect(() => {
    if (actualTaxReportType !== taxReportData?.document?.taxReport) {
      setActualTaxReportType(taxReportData?.document?.taxReport);
    }
  }, [taxReportData]);

  const resetData = () => {
    setTaxReportData(initialTaxReportData);
    setTaxReportStatus("ongoing");
    setTaxReportVisibleContent();
    setHeaderMinimized(false);
    setTaxReportAssignee();
    setTaxReportLastUpdate();
    setActualTaxReportType();
    sessionStorage.removeItem(sessionStorageKeyInitialReportData);
    sessionStorage.removeItem(sessionStorageKeyInitialShowFiltersReportData);
  };

  const hookData = {
    taxReportData,
    setTaxReportData,
    taxReportStatus,
    setTaxReportStatus,
    taxReportVisibleContent,
    setTaxReportVisibleContent,
    headerMinimized,
    setHeaderMinimized,
    taxReportAssignee,
    setTaxReportAssignee,
    taxReportLastUpdate,
    setTaxReportLastUpdate,
    possibleContents,
    setPossibleContents,
    initializingTaxReport,
    setInitializingTaxReport,
    resetData
  };

  return (
    <TaxReportData.Provider value={hookData}>{children}</TaxReportData.Provider>
  );
};

export const useTaxReportData = () => useContext(TaxReportData);

import Stack from "@vapor/react-material/Stack";
import { TaxReportTodoList } from "./TaxReportTodoList";
import { useTaxReportData } from "../../providers/taxReportDataProvider";

export const TaxReportPageResult = ({ children }) => {
  const { setHeaderMinimized } = useTaxReportData();

  const listenToScroll = () => {
    const winScroll = document.getElementById("scrollMaster").scrollTop;
    winScroll > 0 ? setHeaderMinimized(true) : setHeaderMinimized(false);
  };

  return (
    <Stack direction="row" sx={{ height: "100%", overflow: "hidden" }}>
      <Stack
        id="scrollMaster"
        onScroll={listenToScroll}
        direction="column"
        alignItems="flex-start"
        gap="16px"
        sx={{
          paddingLeft: "0px",
          width: "75%",
          marginRight: "53px",
          overflow: "auto"
        }}
      >
        {children}
      </Stack>
      <TaxReportTodoList style={{ width: "25%" }} />
    </Stack>
  );
};

import Typography from "@vapor/react-extended/ExtendedTypography";

import Stack from "@vapor/react-material/Stack";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "@onefront/react-sdk";

import { formatDate } from "../../../utils/date";

import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@vapor/react-material/styles";

const GoToWidget = ({ widgetGoToList }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const theme = useTheme();

  return (
    <Stack spacing={1}>
      {widgetGoToList?.map((tax) => (
        <Stack
          sx={{ borderBottom: "solid 1px #C9CDCF", height: "60px" }}
          direction="row"
          key={tax.tax_report}
        >
          <Stack
            justifyContent="center"
            direction="column"
            sx={{ padding: "16px", width: "310px" }}
          >
            <Typography variant="body">{t(tax.tax_report)}</Typography>
            <Typography variant="body" sx={{ color: "#566B76" }}>{`${t(
              "Deadline"
            )}: ${formatDate(tax.deadline)}`}</Typography>
          </Stack>
          <Stack justifyContent="center" sx={{ padding: "0 12px" }}>
            <FontAwesomeIcon
              onClick={() => {
                navigate(`/tax-reports/?declarationType=${tax.tax_report}`);
              }}
              icon={faArrowRight}
              color={theme.palette.primary.primary60Color}
              size="lg"
              style={{ cursor: "pointer" }}
            />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default GoToWidget;

import Stack from "@vapor/react-material/Stack";

import { useTaxReportsListContext } from "../providers/tax-reports-list-wrapper";
import TaxReportsListPage from "./TaxReportsListPage";
import TaxReportsListFilters from "./tax-reports-list-filter/TaxReportsListFilters";
import TaxReportsListTable from "./tax-reports-list-table/TaxReportsListTable";
import LoadingSpinner from "../../components/LoadingSpinner";

const TaxReportsList = () => {
  const { loading } = useTaxReportsListContext();

  return (
    <TaxReportsListPage>
      {loading && <LoadingSpinner />}
      <Stack>
        <Stack sx={{ margin: "14px 21px" }}>
          <TaxReportsListFilters />
        </Stack>
        <Stack
          sx={{
            overflow: "auto"
          }}
        >
          <TaxReportsListTable />
        </Stack>
      </Stack>
    </TaxReportsListPage>
  );
};

export default TaxReportsList;

import { useState } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@vapor/react-material/Button";
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import { useTheme } from "@vapor/react-material/styles";

import { TaxIdentifier } from "@onefront/workspace-forms/components/Fields/PersonalData/TaxIdentifier.js";
import { VatIdentifier } from "@onefront/workspace-forms/components/Fields/PersonalData/VatIdentifier.js";
import { AmountTextfield } from "@onefront/workspace-forms/components/General/AmountTextfield/AmountTextfield.js";
import { ExtendedDatePicker } from "@onefront/workspace-forms/components/General/ExtendedDatePicker/ExtendedDatePicker.js";
import { ExtendedMenuItem } from "@onefront/workspace-forms/components/General/ExtendedMenuItem/ExtendedMenuItem.js";
import { ExtendedSelect } from "@onefront/workspace-forms/components/General/ExtendedSelect/ExtendedSelect.js";
import { ExtendedTextfield } from "@onefront/workspace-forms/components/General/ExtendedTextfield/ExtendedTextfield.js";

import { constants } from "@fiscal-orchestrator/fior-utils";
import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";

export const TenantIVAModule = ({ business, onChange }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { loading, save, handleOpenDialog, anyChange } =
    useCompanySettingsContext();
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      gap="24px"
      sx={{
        border: `solid 1px ${theme.palette.primary.interactiveDefault}`,
        padding: "16px",
        borderRadius: "10px",
        width: "100%"
      }}
    >
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
        onClick={() => setIsEditMode(true)}
        sx={{ alignSelf: "flex-end" }}
      />
      <Grid container justifyContent="space-between" spacing={4}>
        <Grid item xs={6}>
          <ExtendedTextfield
            label={t("declarantId")}
            noValueLabel={t("notSet")}
            value={business?.declarantId}
            editing={isEditMode}
            name="declarantId"
            onChange={onChange}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedTextfield
            label={t("companyNameOrSurname")}
            noValueLabel={t("notSet")}
            value={business?.companyNameOrSurname}
            editing={isEditMode}
            name="companyNameOrSurname"
            onChange={onChange}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedTextfield
            label={t("name")}
            noValueLabel={t("notSet")}
            value={business?.name}
            editing={isEditMode}
            name="name"
            onChange={onChange}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <TaxIdentifier
            value={business?.taxCode}
            editing={isEditMode}
            name="taxCode"
            onChange={onChange}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <VatIdentifier
            value={business?.vatCode}
            editing={isEditMode}
            name="vatCode"
            onChange={onChange}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <AmountTextfield
            label={t("module")}
            decimalScale={0}
            name="module"
            value={business?.module}
            onValueChange={(name, value) => {
              onChange({ target: { name, value } });
            }}
            editing={isEditMode}
            noValueLabel={t("notSet")}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedTextfield
            label={t("activityCode")}
            noValueLabel={t("notSet")}
            value={business?.atecoCode}
            editing={isEditMode}
            name="atecoCode"
            onChange={onChange}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedTextfield
            label={t("activityDescription")}
            noValueLabel={t("notSet")}
            value={business?.atecoCodeDescription}
            editing={isEditMode}
            name="atecoCodeDescription"
            onChange={onChange}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedSelect
            label={t("modelType")}
            name="modelType"
            value={business?.modelType}
            onChange={onChange}
            editing={isEditMode}
            loading={loading}
          >
            <ExtendedMenuItem
              label={t("unifiedAccounting")}
              value="unifiedAccounting"
            >
              {t("unifiedAccounting")}
            </ExtendedMenuItem>
            <ExtendedMenuItem
              label={t("separatedArt36main")}
              value="separatedArt36main"
            >
              {t("separatedArt36main")}
            </ExtendedMenuItem>
            <ExtendedMenuItem
              label={t("separatedArt36secondary")}
              value="separatedArt36secondary"
            >
              {t("separatedArt36secondary")}
            </ExtendedMenuItem>
          </ExtendedSelect>
        </Grid>
        <Grid item xs={6}>
          <ExtendedTextfield
            label={t("mainActivityVatCode")}
            noValueLabel={t("notSet")}
            value={business?.mainActivityVatCode}
            editing={isEditMode}
            name="mainActivityVatCode"
            onChange={onChange}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedSelect
            label={t("specialCases")}
            name="vatConfiguration.specialVatRegime"
            value={business?.vatConfiguration?.specialVatRegime}
            onChange={onChange}
            editing={isEditMode}
            loading={loading}
          >
            <ExtendedMenuItem
              label={t("nessuno")}
              value={constants?.specialVatRegime?.noSpecialRegime}
            >
              {t("nessuno")}
            </ExtendedMenuItem>
            <ExtendedMenuItem
              label={t("transformationOperations")}
              value="transformationOperations"
            >
              {t("transformationOperations")}
            </ExtendedMenuItem>
            <ExtendedMenuItem label={t("bankruptcy")} value="bankruptcy">
              {t("bankruptcy")}
            </ExtendedMenuItem>
            <ExtendedMenuItem label={t("split")} value="split">
              {t("split")}
            </ExtendedMenuItem>
            <ExtendedMenuItem label={t("succession")} value="succession">
              {t("succession")}
            </ExtendedMenuItem>
          </ExtendedSelect>
        </Grid>
        <Grid item xs={6}>
          <ExtendedDatePicker
            name="transformationDate"
            noValueLabel={t("notSet")}
            editing={isEditMode}
            value={business?.transformationDate}
            onChange={(name, value) =>
              onChange({
                target: { name, value: value.replace("T00:00:00", "") }
              })
            }
            label={t("transformationDate")}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedSelect
            label={t("complaintType")}
            name="vatConfiguration.vatPeriodicity"
            value={business?.vatConfiguration?.vatPeriodicity}
            onChange={onChange}
            editing={isEditMode}
            loading={loading}
          >
            <ExtendedMenuItem
              label={t("quarterly")}
              value={constants?.taxReportPeriods?.vatPeriodicities?.quarterly}
            >
              {t("quarterly")}
            </ExtendedMenuItem>
            <ExtendedMenuItem
              label={t("monthly")}
              value={constants?.taxReportPeriods?.vatPeriodicities?.monthly}
            >
              {t("monthly")}
            </ExtendedMenuItem>
          </ExtendedSelect>
        </Grid>
        <Grid item xs={6}>
          <ExtendedDatePicker
            name="startDate"
            noValueLabel={t("notSet")}
            editing={isEditMode}
            value={business?.startDate}
            onChange={(name, value) =>
              onChange({
                target: { name, value: value.replace("T00:00:00", "") }
              })
            }
            label={t("periodStartDate")}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedDatePicker
            name="endDate"
            noValueLabel={t("notSet")}
            editing={isEditMode}
            value={business?.endDate}
            onChange={(name, value) =>
              onChange({
                target: { name, value: value.replace("T00:00:00", "") }
              })
            }
            label={t("periodEndDate")}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedSelect
            label={t("periodType")}
            name="periodType"
            value={business?.vatPeriodicity}
            onChange={onChange}
            editing={isEditMode}
            loading={loading}
          >
            <ExtendedMenuItem label={t("normal")} value="normal">
              {t("normal")}
            </ExtendedMenuItem>
            <ExtendedMenuItem label={t("firstPeriod")} value="first">
              {t("firstPeriod")}
            </ExtendedMenuItem>
            <ExtendedMenuItem label={t("secondPeriod")} value="second">
              {t("secondPeriod")}
            </ExtendedMenuItem>
          </ExtendedSelect>
        </Grid>
      </Grid>
      {isEditMode && (
        <Stack direction="row" alignItems="center" gap="16px">
          <Button
            variant="contained"
            onClick={() => {
              save();
              setIsEditMode(false);
            }}
          >
            {t("save")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              if (anyChange) handleOpenDialog();
              else setIsEditMode(false);
            }}
          >
            {t("cancel")}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export const validateDichInfo = (document) => {
  const errors = [];
  const errorMessageByCheck = [
    {
      check: !document?.input?.workspaces?.companyData?.id,
      message: "Missing companyData id"
    },
    {
      check:
        !document?.input?.workspaces?.companyData?.personalData?.taxIdentifier,
      message: "Missing company taxIdentifier"
    },
    {
      check:
        document?.input?.workspaces?.companyData?.classifier === "PERSON" &&
        !document?.input?.workspaces?.companyData?.personalData?.surname,
      message: "Missing surname"
    },
    {
      check:
        document?.input?.workspaces?.companyData?.classifier === "PERSON" &&
        !document?.input?.workspaces?.companyData?.personalData?.name,
      message: "Missing name"
    },
    {
      check:
        document?.input?.workspaces?.companyData?.classifier === "COMPANY" &&
        !document?.input?.workspaces?.companyData?.personalData?.businessName,
      message: "Missing businessName"
    }
  ];

  for (const { check, message } of errorMessageByCheck) {
    if (check) {
      errors.push(message);
    }
  }

  return { isValid: errors.length === 0, errors };
};

import {
  createContext,
  useContext,
  useLayoutEffect,
  useRef,
  useState
} from "react";

import { useGetConfig } from "@onefront/react-sdk";
import { generatePayload, isMultiplugin } from "@reviso/fiscali-saas";

import { preparePayloadSaveCommand } from "../../features/tax-report-page/helpers/tax-report-page-helper";
import {
  useWebImport,
  useWebInit,
  useWebKill,
  useWebPdf,
  useWebTele
} from "../../features/tax-report-page/hooks/fiscali-saas";
import { useCqrs } from "../cqrs";
import { EmbedIFrame } from "./EmbedIFrame";
import { FiscaliCommService } from "./FiscaliCommServiceClass";

const commonStyle = { position: "absolute", border: 0 };

const FiscaliSaasIframeContext = createContext();

export const FiscaliSaasIframeProvider = ({ children }) => {
  const iframeQueryStringValue = useGetConfig("fiscaliSaas.iframeQueryString");
  const webInit = useWebInit();
  const webKill = useWebKill();
  const webImport = useWebImport();
  const webPdf = useWebPdf();
  const webTele = useWebTele();
  const { issueCommand } = useCqrs();

  const iframeRef = useRef(null);
  const [containerRef, setContainerRef] = useState(null);
  const [webInitUrl, setWebInitUrl] = useState(null);
  const [isIframeInstantiated, setIframeInstantiated] = useState(false);
  const [interframeCommService, setInterframeCommService] = useState(null);
  const [iframeStyle, setIframeStyle] = useState({
    top: 0,
    left: 0,
    height: 0,
    width: 0
  });
  const [resizeObserver, setResizeObserver] = useState(null);
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [currentBlob, setCurrentBlob] = useState(null);
  const [currentDataOut, setCurrentDataOut] = useState(null);
  const [currentSheetList, setCurrentSheetList] = useState(null);
  const [numberOfErrors, setNumberOfErrors] = useState(0);
  const [errorsList, setErrorsList] = useState(null);
  const [modifiedList, setModifiedList] = useState(null);
  const [fiscaliRequestPending, setFiscaliRequestPending] = useState(false);
  const [callReset, setCallReset] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const getIframeData = () => {
    interframeCommService.getBlob();
    interframeCommService.getSheetList();
    interframeCommService.getDataOut();
    interframeCommService.getErrors();
    interframeCommService.getModified();
  };

  const saveFiscaliTaxReport = async (fiscaliResponse, taxReport) => {
    setFiscaliRequestPending(true);
    const payload = {
      version: taxReport.document.version,
      ...preparePayloadSaveCommand(taxReport.document, fiscaliResponse.data)
    };
    const options = {
      headers: { "taxpayer-id": taxReport.taxpayer_id },
      shouldComplete: true
    };
    const res = await issueCommand("save", "tax-reports", payload, options);
    setFiscaliRequestPending(false);
    if (res.response) {
      return res.response.update_tax_report;
    } else {
      console.error("Could not save tax report");
      console.error(res);
    }
  };

  const storeTaxReportChanges = async (taxReportData) => {
    if (taxReportData?.document?.fiscaliSaas) {
      setFiscaliRequestPending(true);
      const webImportPayload = generatePayload({
        document: taxReportData.document,
        command: "webImport"
      });

      if (isMultiplugin(taxReportData.document.taxReport)) {
        for (const pluginType of Object.keys(currentDataOut)) {
          const relatedExtraParam =
            taxReportData.document.fiscaliSaas.ExtraParams.find(
              (x) => x.target === pluginType
            );
          relatedExtraParam.value = currentDataOut[pluginType];
        }
      } else {
        webImportPayload.DataOut =
          currentDataOut?.[taxReportData.document.fiscaliSaas.DichType];
      }

      webImportPayload.Blob = currentBlob;

      const webImportRes = await webImport.fetch({
        data: webImportPayload
      });

      const webPdfPayload = generatePayload({
        document: taxReportData.document,
        command: "webPDF"
      });

      webPdfPayload.DataOut =
        currentDataOut[taxReportData.document.fiscaliSaas.DichType];
      webPdfPayload.Blob = currentBlob;

      const webPdfRes = await webPdf.fetch({
        data: webPdfPayload
      });

      if (webPdfRes?.data?.PdfFile)
        webImportRes.data.Pdf = webPdfRes.data.PdfFile;

      const webTelePayload = generatePayload({
        document: taxReportData.document,
        command: "webTele"
      });

      const webTeleRes = await webTele.fetch({
        data: webTelePayload
      });

      if (webTeleRes?.data?.TeleFile)
        webImportRes.data.Tele = webTeleRes.data.TeleFile;

      if (webImportRes.data) webImportRes.data.SheetList = currentSheetList;

      const saveRes = await saveFiscaliTaxReport(webImportRes, taxReportData);
      setFiscaliRequestPending(false);

      return saveRes;
    }
  };

  const callWebInit = async (taxReport) => {
    const webInitPayload = generatePayload({
      document: taxReport.document,
      command: "webInit"
    });

    setFiscaliRequestPending(true);
    const res = await webInit.fetch({
      data: webInitPayload
    });

    if (res?.data?.WebUrl) {
      if (isIframeInstantiated) {
        interframeCommService.openUrl(
          btoa(res?.data?.WebUrl),
          webInitPayload.DichType
        );
      } else {
        //TODO: fix this trick
        setWebInitUrl(res.data.WebUrl.substring(37));
      }
    }

    setFiscaliRequestPending(false);

    return res;
  };

  const callWebKill = async (taxReportData) => {
    if (taxReportData?.document?.fiscaliSaas) {
      setFiscaliRequestPending(true);
      const webkillPayload = generatePayload({
        document: taxReportData.document,
        command: "webKill"
      });

      if (!webkillPayload.errors) {
        await webKill.fetch({
          data: webkillPayload
        });
      }

      setFiscaliRequestPending(false);
    }
  };

  const initializeFiscaliTaxReport = async (taxReport) => {
    if (taxReport?.document?.fiscaliSaas?.Blob) {
      if (!interframeCommService) {
        const fiscaliCommService = new FiscaliCommService();
        setInterframeCommService(fiscaliCommService);
      }

      await callWebKill(taxReport);
      const res = await callWebInit(taxReport);

      if (taxReport.document.states.taxReport !== "closed") {
        const saveRes = await saveFiscaliTaxReport(res, taxReport);

        return saveRes;
      }
    }

    return;
  };

  const closeFiscaliTaxReport = async (taxReportData) => {
    const payload = {
      version: taxReportData.document.version,
      ...taxReportData.document
    };
    const res = await issueCommand("close", "tax-reports", payload, {
      headers: { "taxpayer-id": taxReportData.taxpayer_id },
      shouldComplete: true
    });

    if (res.response) return res.response.update_tax_report;
    else {
      console.error(res);

      return;
    }
  };

  const resetFiscaliTaxReport = async (taxReportData) => {
    await callWebKill(taxReportData);
    const res = await callWebInit(taxReportData);

    return res;
  };

  const sendResetEvent = () => {
    setCallReset(true);
    setTimeout(() => {
      setCallReset(false);
    }, 0);
  };

  const startHandshake = () => {
    interframeCommService.startHandshake(
      iframeRef.current.contentWindow,
      (message) => {
        if (message?.cmd === "hide") sendResetEvent();
        if (message?.Command === "GetDataOut")
          setCurrentDataOut(message?.DataOut);
        if (message?.Command === "GetBlob") setCurrentBlob(message?.Blob);
        if (message?.Command === "GetSheetList")
          setCurrentSheetList(message?.SheetList);
        if (message?.Command === "GetErrori") {
          setNumberOfErrors(message?.ErrorList?.length);
          setErrorsList(message?.ErrorList);
        }
        if (message?.Command === "GetVariati")
          setModifiedList(message?.VariatiList);
        if (message?.Command === "PluginStatusChanged") getIframeData();
        if (message?.Command === "ScrollDichQuadro")
          setScrollTop(message.ScrollData.scrollTop);
      }
    );
    setIframeInstantiated(true);
  };

  const onLoadIframe = () => {
    startHandshake();
    interframeCommService.hideLeftBar();
    interframeCommService.hideBottomBar();
    getIframeData();
  };

  useLayoutEffect(() => {
    if (!containerRef?.current) {
      if (resizeObserver) resizeObserver.disconnect();
      setIsIframeVisible(false);
      return;
    }
    const newResizeObserver = new ResizeObserver(() => {
      if (containerRef.current) {
        const { height, width } = containerRef.current.getBoundingClientRect();
        const { offsetTop, offsetLeft } = containerRef.current;
        setIframeStyle({ top: offsetTop, left: offsetLeft, height, width });
        setIsIframeVisible(true);
      }
    });
    setResizeObserver(newResizeObserver);
    newResizeObserver.observe(containerRef.current);
    return () => resizeObserver?.disconnect();
  }, [containerRef]);

  const ctx = {
    initializeFiscaliTaxReport,
    callWebKill,
    storeTaxReportChanges,
    closeFiscaliTaxReport,
    resetFiscaliTaxReport,
    setContainerRef,
    getIframeData,
    cancelAndExit: () => interframeCommService.cancelAndExit(),
    saveFiscaliTaxReport,
    gotoQuadro: (field) => interframeCommService.gotoQuadro(field),
    gotoCampo: (field) => interframeCommService.gotoCampo(field),
    currentDataOut,
    currentSheetList,
    numberOfErrors,
    errorsList,
    modifiedList,
    currentBlob,
    fiscaliRequestPending,
    callReset,
    scrollTop
  };

  return (
    <FiscaliSaasIframeContext.Provider value={ctx}>
      {webInitUrl && (
        <EmbedIFrame
          ref={iframeRef}
          src={`rnd::saas:tax-orchestrator:fiscaliSaasFrame://${webInitUrl}&ofbu=${iframeQueryStringValue}`}
          style={
            isIframeVisible
              ? {
                  ...commonStyle,
                  ...iframeStyle
                }
              : { ...commonStyle, height: "0px", width: "0px" }
          }
          onLoad={onLoadIframe}
        />
      )}
      {children}
    </FiscaliSaasIframeContext.Provider>
  );
};

export const useFiscaliSaasIframe = () => useContext(FiscaliSaasIframeContext);

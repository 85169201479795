import { useMutation } from "@onefront/react-sdk";

const POPULATE_VIEW = `mutation populate($companiesList: [Companies] = []) {
    populateTaxReportsList(companiesList: $companiesList) {
      mergedList {
        taxpayer_id
        year
        tenant_id
        tax_report
        period
      }
    }
  }`;

export const usePopulateTaxpayerTaxReports = () => useMutation(POPULATE_VIEW);

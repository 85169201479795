import { useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExtendedDatePicker } from "@onefront/workspace-forms/components/General/ExtendedDatePicker/ExtendedDatePicker.js";

import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { onChangeHandlerField } from "../../../handlers";
import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import { TenantIVASupervisoryBodySubscription } from "./TenantIVASupervisoryBodySubscription";

export const TenantIVATelematicData = () => {
  const { t } = useTranslation();
  const {
    companySettings,
    setCompanySettings,
    setIsEditMode,
    isEditMode,
    loading,
    sotCompanySettings,
    setAnyChange
  } = useCompanySettingsContext();

  const onChangeHandler = (e) => {
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  const onChangeTenantIvaSupervisoryHandler = (e, selectedIndex) => {
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings,
      array: {
        name: "supervisoryBodySubscription",
        index: selectedIndex
      }
    });
  };

  const onAddNewSupervisoryBodySubscription = () => {
    setCompanySettings({
      ...companySettings,
      supervisoryBodySubscription: [
        ...companySettings.supervisoryBodySubscription,
        {}
      ]
    });
  };

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  useEffect(() => {
    if (
      companySettings?.supervisoryBodySubscription &&
      !companySettings?.supervisoryBodySubscription.length
    ) {
      setCompanySettings({
        ...companySettings,
        supervisoryBodySubscription: [{}]
      });
    }
  }, [companySettings?.supervisoryBodySubscription]);

  return (
    <CompanySettingsGrid>
      <Button
        fullRow={true}
        variant="text"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={onAddNewSupervisoryBodySubscription}
      >
        {t("addNewSupervisoryBodySubscription")}
      </Button>
      <Stack fullRow={true} gap={"16px"}>
        {companySettings.supervisoryBodySubscription?.map((settings, index) => (
          <TenantIVASupervisoryBodySubscription
            settings={settings}
            key={index}
            onChange={(e) => onChangeTenantIvaSupervisoryHandler(e, index)}
          />
        ))}
      </Stack>
      <ExtendedDatePicker
        name="transformationDate"
        noValueLabel={t("notSet")}
        editing={isEditMode}
        value={companySettings.transformationDate}
        onChange={(name, value) =>
          onChangeHandler({
            target: { name, value: value.replace("T00:00:00", "") }
          })
        }
        label={t("transformationDate")}
        loading={loading}
      />
    </CompanySettingsGrid>
  );
};

import { forwardRef } from "react";

import { useTranslation } from "@onefront/react-sdk";
import { useGet } from "@onefront/react-sdk";

import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";
import Button from "@vapor/react-material/Button";

/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"; */

import { formatAmount, formatDate } from "../../../../utils";
import TaxReportPageAccordion from "../TaxReportPageAccordion";

const F24_PATH = "/_/f24://";

const TaxReportPageCheckingF24 = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { year, checksData } = props;
  const { data: portalUrl } = useGet(`registryApi://services/PORTAL`);
  const f24Url = portalUrl?.item?.value
    ? `${portalUrl.item.value}${F24_PATH}`
    : null;

  return (
    <TaxReportPageAccordion
      ref={ref}
      title={t("paymentF24Omitted", { year: year - 1 })}
      subtitle={t("lastChangeDateTemplate", {
        date: formatDate(checksData?.lastUpdate),
        interpolation: { escapeValue: false }
      })}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={6}>
          <Typography variant="bodyLarge">{t("taxCode6031")}</Typography>
          <Button
            onClick={() => {
              if (f24Url) window.open(f24Url);
            }}
            size="small"
          >
            {t("repent")}
          </Button>
          <Button
            disabled
            variant="primary"
            onClick={() => console.log("click")}
            size="small"
          >
            {t("notifyTheUserToConfirmRepent")}
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="bodyLarge700">
            {formatAmount(checksData?.taxCode6031, 2, 2) || " 0,00"}
          </Typography>
          {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={6}>
          <Typography variant="bodyLarge">{t("taxCode6032")}</Typography>
          <Button
            onClick={() => {
              if (f24Url) window.open(f24Url);
            }}
            size="small"
          >
            {t("repent")}
          </Button>
          <Button
            disabled
            variant="primary"
            onClick={() => console.log("click")}
            size="small"
          >
            {t("notifyTheUserToConfirmRepent")}
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="bodyLarge700">
            {formatAmount(checksData?.taxCode6032, 2, 2) || " 0,00"}
          </Typography>
          {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
        </Stack>
      </Stack>
    </TaxReportPageAccordion>
  );
});

export default TaxReportPageCheckingF24;

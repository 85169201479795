import { createContext, useContext, useEffect, useMemo, useState } from "react";

const KpiListContext = createContext();

export const KpiListWrapper = ({ children, initialKpiList = [] }) => {
  const [kpiList, setKpiList] = useState(initialKpiList);
  const [loading, setLoading] = useState(false);

  const hookData = useMemo(
    () => ({
      kpiList,
      setKpiList,
      loading,
      setLoading
    }),
    [kpiList, setKpiList, loading, setLoading]
  );

  useEffect(() => {
    setLoading(false);
  }, [kpiList]);

  return (
    <KpiListContext.Provider value={hookData}>
      {children}
    </KpiListContext.Provider>
  );
};

export const useKpiListContext = () => useContext(KpiListContext);

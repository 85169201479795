import { useTheme } from "@vapor/react-material/styles";

import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TaxReportPageCard = ({
  title,
  emptyContentIcon,
  emptyContentText,
  actionButtons,
  children
}) => {
  const theme = useTheme();

  return (
    <Stack
      alignItems="flex-start"
      flexShrink="0"
      alignSelf="stretch"
      sx={{
        border: "1px solid",
        borderColor: theme.palette.primary.neutral20
      }}
    >
      <Typography
        variant="titleSmall"
        color={theme.palette.text.primary}
        sx={{
          padding: "16px 16px 16px 24px",
          width: "100%"
        }}
      >
        {title}
      </Typography>
      {children ? (
        children
      ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap="10px"
          sx={{
            padding: "0px 106px",
            width: "100%"
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            gap="8px"
            sx={{
              height: "150px",
              width: "283px",
              marginBottom: "11px"
            }}
          >
            <FontAwesomeIcon
              icon={emptyContentIcon}
              style={{
                fontSize: "80px",
                fontWeight: "400",
                lineHeight: "24px",
                color: theme.palette.primary.textHintColor
              }}
            />
            <Typography variant="titleMedium" sx={{ textAlign: "center" }}>
              {emptyContentText}
            </Typography>
          </Stack>
        </Stack>
      )}
      {actionButtons?.length > 0 && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          gap="12px"
          alignSelf="stretch"
          sx={{
            background: theme.palette.primary.background,
            padding: "12px"
          }}
        >
          {actionButtons}
        </Stack>
      )}
    </Stack>
  );
};

import React, { useEffect, useState, useMemo } from "react";
import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import Popover from "@vapor/react-material/Popover";
import Typography from "@vapor/react-material/Typography";
import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetKpiStatuses } from "./../../hooks/useGetKpiStatuses";
import { useTranslation } from "@onefront/react-sdk";

const TypologyInformations = ({
  handlePopoverClose,
  anchorEl,
  open,
  selectedKpi,
  selectedStatus,
  selectedYear
}) => {
  const { t } = useTranslation();
  const { tenant } = useCurrentTenant(true);
  const { data } = useGetKpiStatuses({
    tenantId: tenant?.itemId,
    year: selectedYear
  });

  const [taxReportsList, setTaxReportsList] = useState({});

  useEffect(() => {
    if (data && Array.isArray(data.result) && data.result.length > 1) {
      const kpiData = data.result[1].data;
      if (kpiData && typeof kpiData === "object") {
        setTaxReportsList(kpiData[selectedKpi] || {});
      } else {
        console.error("kpiData is not an object:", kpiData);
      }
    }
  }, [data, selectedKpi, selectedYear]);

  const filteredData = useMemo(() => {
    const result = Array.isArray(taxReportsList[selectedStatus])
      ? taxReportsList[selectedStatus]
      : Object.values(taxReportsList[selectedStatus] || {});
    return result;
  }, [taxReportsList, selectedStatus, selectedYear]);

  const typologyCounts = useMemo(() => {
    const result = filteredData.reduce(
      (acc, kpi) => {
        acc.ordinary += kpi.ordinary || 0;
        acc.corrective += kpi.corrective || 0;
        acc.supplementary += kpi.supplementary || 0;
        return acc;
      },
      { ordinary: 0, corrective: 0, supplementary: 0 }
    );
    return result;
  }, [filteredData, selectedYear]);

  return (
    <Stack>
      <Box>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left"
          }}
          sx={{
            marginTop: "-60px",
            marginLeft: "20px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "240px",
              height: "120px",
              justifyContent: "space-between"
            }}
            p={2}
          >
            <Typography
              sx={{ width: "209px", height: "20px" }}
              variant="body"
              color="primary.main"
            >
              {t("ordinary")}: ({typologyCounts.ordinary})
            </Typography>
            <Typography variant="body" color="primary.main">
              {t("corrective")}: ({typologyCounts.corrective})
            </Typography>
            <Typography variant="body" color="primary.main">
              {t("supplementary")}: ({typologyCounts.supplementary})
            </Typography>
          </Box>
        </Popover>
      </Box>
    </Stack>
  );
};

export default TypologyInformations;

import { constants } from "@fiscal-orchestrator/fior-utils";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Accordion from "@vapor/react-material/Accordion";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import Box from "@vapor/react-material/Box";
import React, { useEffect, useState } from "react";
import { useGetTaxReport } from "../../../../../hooks/useGetTaxReport";
import { useWorkspaceData } from "../../../../../services/workspace/workspaceProvider";
import { formatFloatAmount, orderDateHour } from "../../../../../utils";
import { TaxPayerCell } from "../../../../tax-reports-list/components/tax-reports-list-table/TaxPayerCell";
import { AnnualDataDialog } from "../../../helpers/tax-report-dialog-data";
import { useTaxReportData } from "../../../providers/taxReportDataProvider";
import { DialogInfoTaxReport } from "../../DialogInfoTaxReport";
import { FiscaliSaasIframeWrapper } from "../../FiscaliSaasIframeWrapper";
import { TaxReportOtherTaxes } from "../../Result/TaxReportOtherTaxes";
import { TaxReportPageResult } from "../../Result/TaxReportPageResult";
import { TaxReportResult } from "../../Result/TaxReportResult";

import PropTypes from "prop-types";
export const IRAP_V1 = ({ tabs, name, ...props }) => {
  const { data } = useGetTaxReport();
  const { t } = useTranslation();
  const {
    taxReportData,
    taxReportVisibleContent,
    setTaxReportVisibleContent,
    possibleContents,
    setPossibleContents
  } = useTaxReportData();
  const { workspaceUrl } = useWorkspaceData();

  const [isOpenDialog, setOpenDialog] = useState(false);
  const [expanded, setExpanded] = useState("panel1");

  let usedCreditFieldName = "";
  let otherUsesFieldName = "";
  let remainingF24FieldName = "";

  if (
    taxReportData?.document?.taxReport ===
    constants.taxReportTypes.redditiPersonSociety
  ) {
    usedCreditFieldName = "RX_51_CredUtil";
    otherUsesFieldName = "RX_51_AltriUtil";
    remainingF24FieldName = "RX_51_ResiduoF24";
  } else {
    usedCreditFieldName = "RX_01_CredUtil:1";
    otherUsesFieldName = "RX_01_AltriUtil:1";
    remainingF24FieldName = "RX_01_ResiduoF24:1";
  }

  const ir =
    taxReportData?.document?.data?.fiscaliSaas?.dataOut?.["IR Ripartizione&0"];
  const sxFirstRow = { marginTop: "16px" };
  const sx = { marginTop: "8px" };

  const dataWIR = [
    [
      {
        rowStyle: { marginTop: "16px" },
        cell3: <Typography variant="bodyLarge700">{t("credito")}</Typography>,
        cell4: <Typography variant="bodyLarge700">{t("debito")}</Typography>
      },
      {
        rowStyle: sxFirstRow,
        cell1: <Typography variant="bodyLarge700">{t("IRAP")}</Typography>,
        cell4: (
          <Typography variant="body500">
            {ir?.["IR_26"] ? formatFloatAmount(ir?.["IR_26"], 2, 2) : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("iiAdvance")}</Typography>,
        cell4: (
          <Typography variant="body500">{t("dataNotAvailable")}</Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("credito")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {ir?.["IR_27"] ? formatFloatAmount(ir?.["IR_27"], 2, 2) : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("Overpayments")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {" "}
            {ir?.["IR_28"] ? formatFloatAmount(ir?.["IR_28"], 2, 2) : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("usedCredit")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {ir?.[usedCreditFieldName]
              ? formatFloatAmount(ir?.[usedCreditFieldName], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("otherUses")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {ir?.[otherUsesFieldName]
              ? formatFloatAmount(ir?.[otherUsesFieldName], 2, 2)
              : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("AmountRefunded")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {ir?.["IR_29"] ? formatFloatAmount(ir?.["IR_29"], 2, 2) : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: (
          <Typography variant="body">
            {t("CreditOffsettingDeduction")}
          </Typography>
        ),
        cell3: (
          <Typography variant="body500">
            {ir?.["IR_30"] ? formatFloatAmount(ir?.["IR_30"], 2, 2) : "0,00"}
          </Typography>
        )
      },
      {
        rowStyle: sx,
        cell1: <Typography variant="body">{t("remainingF24")}</Typography>,
        cell3: (
          <Typography variant="body500">
            {ir?.[remainingF24FieldName]
              ? formatFloatAmount(ir?.[remainingF24FieldName], 2, 2)
              : "0,00"}
          </Typography>
        )
      }
    ]
  ];
  const resultData = [
    {
      title: t("IRAP"),
      value: ir?.["IR_26"] ? formatFloatAmount(ir?.["IR_26"], 2, 2) : "0,00",
      subtitle: ir?.["IR_27"] ? t("whichRepaymentRequest") : "",
      otherValue: ir?.["IR_27"] ? formatFloatAmount(ir?.["IR_27"], 2, 2) : "",
      text: t("iAdvance"),
      valueText: t("dataNotAvailable"),
      subText: t("iiAdvance"),
      valueSubText: t("dataNotAvailable")
    },
    {
      title: t("totalCredit"),
      value: t("dataNotAvailable")
    }
  ];

  const dateTaxReport = orderDateHour(taxReportData?.updated_at);

  DialogInfoTaxReport.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };

  const handleClose = (newValue) => {
    setOpenDialog(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setPossibleContents(tabs?.contents);
    setTaxReportVisibleContent(tabs?.firstContent);
  }, []);

  useEffect(() => {
    setOpenDialog(
      data?.tax_reports_get_curr_item[0]?.document?.states?.wfsState === "Todo"
    );
  }, [data]);

  return (
    <FiscaliSaasIframeWrapper>
      <Box sx={{ padding: "20px", height: "100%", overflow: "hidden" }}>
        {
          //Result
          taxReportVisibleContent === possibleContents.result && (
            <>
              <TaxReportPageResult>
                <TaxReportResult resultData={resultData} />
                <TaxReportOtherTaxes data={dataWIR} />
              </TaxReportPageResult>
            </>
          )
        }
        {isOpenDialog && (
          <DialogInfoTaxReport
            data={data}
            isOpen={isOpenDialog}
            open={isOpenDialog}
            onClose={handleClose}
          >
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
              >
                <Typography variant="titleSmall">
                  {t("dialogTaxReportData1")}
                </Typography>
                <Typography variant="body">
                  {t("dialogTaxReportDate", dateTaxReport).replaceAll(
                    "&#x2F;",
                    "/"
                  )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <TaxPayerCell
                  row={{
                    taxpayer_type: taxReportData?.document?.taxpayerType,
                    taxpayer_name:
                      taxReportData?.document?.input?.workspaces?.companyData
                        ?.personalData?.businessName,
                    taxpayer_id: taxReportData?.taxpayer_id
                  }}
                  modal={true}
                  workspaceUrl={workspaceUrl}
                />
                <AnnualDataDialog />
              </AccordionDetails>
            </Accordion>
            {/* <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <Typography variant="titleSmall">
                {t("dialogTaxReportData2")}
              </Typography>
              {companySettingsDetails?.data?.company_data_public_entries
                .length > 0 && (
                <Typography variant="body">
                  {t("dialogTaxReportDate", dateCompanySetting).replaceAll(
                    "&#x2F;",
                    "/"
                  )}
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={1}>
                {companySettingsDetails.data !== null && (
                  <MultiactivityDialog
                    companySettingsDetails={companySettingsDetails.data}
                  />
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <Typography variant="titleSmall">
                {t("dialogTaxReportData3")}
              </Typography>
              <Typography variant="body">
                {t(
                  "dialogTaxReportDate",
                  orderDateHour(taxReportData?.updated_at)
                ).replaceAll("&#x2F;", "/")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body">Data: </Typography>
              <Typography variant="bodySmall700">To be defined</Typography>
            </AccordionDetails>
          </Accordion>
          {companySettingsDetails?.data?.company_data_public_entries?.length ===
            0 && (
            <Box sx={{ marginTop: "15px" }}>
              <Stack spacing={2}>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      color={theme.palette.primary.baseWarningColor}
                      style={{
                        marginRight: "12px",
                        cursor: "pointer",
                        height: 20,
                        width: 18
                      }}
                    />
                    <Typography variant="body">
                      {t("dialogTaxReportError1")}
                    </Typography>
                  </Stack>
                  <Button
                    sx={{ minWidth: "150px !important" }}
                    size="small"
                    onClick={() => {
                      //! Don´t work with mock server data
                      navigate(`/company/${taxReportData?.tenant_id}`);
                    }}
                  >
                    {t("dialogTaxReportErrorButton")}
                  </Button>
                </Stack>
              </Stack>
              <Stack spacing={2}>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      color={theme.palette.primary.baseWarningColor}
                      style={{
                        marginRight: "12px",
                        cursor: "pointer",
                        height: 20,
                        width: 18
                      }}
                    />
                    <Typography variant="body">
                      {t("dialogTaxReportError2")}
                    </Typography>
                  </Stack>
                  <Button
                    sx={{ minWidth: "150px !important" }}
                    size="small"
                    onClick={() => {
                      //! Don´t work with mock server data
                      navigate(`/company/${taxReportData?.tenant_id}`);
                    }}
                  >
                    {t("dialogTaxReportErrorButton")}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          )} */}
          </DialogInfoTaxReport>
        )}
      </Box>
    </FiscaliSaasIframeWrapper>
  );
};

import { useFiscalYearContext } from "../providers/fiscal-year";
import {
  faAngleRight,
  faAngleLeft,
  faCalendar
} from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "@onefront/react-sdk";
import { Button, IconButton } from "@vapor/react-material";
import Stack from "@vapor/react-material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { useTheme } from "@vapor/react-material/styles";

//Declarate min and max year
const CUR_YEAR = new Date().getFullYear();
const maxYear = CUR_YEAR + 1;
const minYear = CUR_YEAR - 5;

const TaxReportsButtonYears = () => {
  const theme = useTheme();
  const { fiscalYear: year, setFiscalYear } = useFiscalYearContext();
  const { t } = useTranslation("foa");
  //Handle year change
  const handleOneMoreYear = () => {
    if (year < maxYear) setFiscalYear(+year + 1);
  };
  const handleOneLessYear = () => {
    if (year > minYear) setFiscalYear(+year - 1);
  };

  return (
    <>
      <Stack
        direction="row"
        style={{
          padding: "2px 8px 2px 7px",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <IconButton onClick={handleOneLessYear} size="small">
          <FontAwesomeIcon
            style={{ fontSize: "24px" }}
            icon={faAngleLeft}
            color={theme.palette.primary.interactiveDefault}
          />
        </IconButton>
        <IconButton onClick={handleOneMoreYear} size="small">
          <FontAwesomeIcon
            style={{ fontSize: "24px", lineHeight: "20px" }}
            icon={faAngleRight}
            color={theme.palette.primary.interactiveDefault}
          />
        </IconButton>
        <Typography
          id="year"
          sx={{ fontSize: "24px" }}
          variant="BodyLarge"
          color={theme.palette.primary.interactiveDefault}
        >
          {year}
        </Typography>
      </Stack>
      <Button
        disabled={year === CUR_YEAR}
        sx={{ margin: "0 16px" }}
        onClick={() => setFiscalYear(CUR_YEAR)}
        startIcon={<FontAwesomeIcon icon={faCalendar} size="lg" />}
        variant="outlined"
      >
        {t("CurrentYear")}
      </Button>
    </>
  );
};

export default TaxReportsButtonYears;

import { createContext, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { useGet } from "@onefront/react-sdk";

import { calculateTaxPayerType } from "@reviso/fiscali-saas";

const WorkspaceDataContext = createContext();

export const WorkspaceDataWrapper = ({ children }) => {
  const { id } = useParams();

  const {
    data: workspaceData,
    fetch,
    loading
  } = useGet(
    `rnd::saas:tax-orchestrator:oneplatformConnectorApi://api/v1/workspaces/${id}`,
    {
      lazy: true
    }
  );

  const taxPayerType = useMemo(() => {
    return (
      workspaceData &&
      calculateTaxPayerType({
        classifier: workspaceData.classifier,
        businessLegalForm: workspaceData.personalData?.businessLegalForm
      })
    );
  }, [workspaceData]);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <WorkspaceDataContext.Provider
      value={{ workspaceData, loading, taxPayerType }}
    >
      {children}
    </WorkspaceDataContext.Provider>
  );
};

export const useWorkspaceData = () => useContext(WorkspaceDataContext);

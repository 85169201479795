import declarationTypeOptions from "../../../../components/declarationTypes.json";
import conditionOptions from "../conditions";
import statusesOptions from "../statuses";
export const generateFilters = (chipsFilters, searchText, modifiedBy) =>
  chipsFilters
    .concat(searchText ? [{ type: "searchText", value: searchText }] : [])
    .concat(modifiedBy ? [{ type: "modifiedBy", value: modifiedBy }] : [])
    .reduce((acc, filter) => {
      if (filter.type === "searchText" && filter.value)
        return { ...acc, searchText };
      if (filter.type === "modifiedBy" && filter.value)
        return { ...acc, modifiedBy };
      if (!acc[filter.type]) acc[filter.type] = [];
      acc[filter.type].push(filter.value);
      return acc;
    }, {});

export const generateChips = (
  declarationType,
  status,
  condition,
  modifiedBy
) => {
  const chips = [];

  if (declarationType && declarationType.length) {
    const declarationOptionsMap = declarationTypeOptions
      .flatMap((x) => x.options)
      .reduce((objMap, option) => {
        objMap[option.value] = option.label;

        return objMap;
      }, {});

    chips.push(
      ...declarationType
        .filter((x) => !!declarationOptionsMap[x])
        .map((value) => ({
          type: "declarationType",
          value,
          label: declarationOptionsMap[value]
        }))
    );
  }

  if (status) {
    const statusOption = statusesOptions.find(
      (option) => option.value === status
    );

    if (statusOption) {
      chips.push({
        type: "state",
        value: statusOption.value,
        label: statusOption.label
      });
    }
  }

  if (condition) {
    const conditionOption = conditionOptions.find(
      (option) => option.value === condition
    );

    if (conditionOption) {
      chips.push({
        type: "condition",
        value: conditionOption.value,
        label: conditionOption.label
      });
    }
  }

  if (modifiedBy) {
    chips.push({
      type: "modifiedBy",
      value: modifiedBy,
      label: modifiedBy
    });
  }

  return chips;
};

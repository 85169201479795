import { useEffect, useState } from "react";
import VaporPage from "@vapor/react-custom/VaporPage";
import Alert from "@vapor/react-material/Alert";
import Stack from "@vapor/react-material/Stack";
import Container from "@vapor/react-material/Container";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Button } from "@vapor/react-material/Button";
import { useTranslation } from "@onefront/react-sdk";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@vapor/react-material/styles";

import { usePopulateTaxpayerTaxReports } from "../hooks/usePopulateTaxpayerTaxReports";
import { useCompanyList } from "../../../hooks/companyList";
import { useCurrentTenant } from "@drift/oneplatfront";
import { useImportTaxRepotList } from "../hooks/useImportTaxReportList";
import { useFiscalYearContext } from "../../../features/tax-reports-list/providers/fiscal-year";
import { useGetTelematicoWaitingTaxReports } from "../hooks/useGetWaitingTelematiciTaxReports";
import { useCheckTelematiciState } from "../hooks/useCheckTelematiciState";

export const Actions = () => {
  const { t } = useTranslation("foa");

  const theme = useTheme();
  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");

  const { fetch: populateTaxPayerFetch } = usePopulateTaxpayerTaxReports();
  const { tenant } = useCurrentTenant(true);
  const companyList = useCompanyList({ tenantId: tenant?.itemId });

  const yearsDefault = Array.from({ length: 2 }, (_, index) => 2023 + index);

  const importTax = useImportTaxRepotList({
    tenantId: tenant?.itemId,
    years: yearsDefault
  });
  const { fiscalYear: year } = useFiscalYearContext();

  const { fetch: fetchTelematicoWaitingTaxReports } =
    useGetTelematicoWaitingTaxReports({ tenantId: tenant?.itemId });
  const { fetch: checkTelematiciState } = useCheckTelematiciState();

  const loadTaxReportList = async () => {
    const requestPayload = {
      companiesList: companyList.data.content
        .filter((x) => x.personalData)
        .map((x) => {
          return {
            tenantId: tenant.itemId,
            taxIdentifier: x.personalData.taxIdentifier,
            vatIdentifier: x.personalData.vatIdentifier,
            businessName: x.personalData.businessName,
            year,
            taxPayerId: x.id
          };
        })
    };
    setAlertText(t("Loading data success"));
    setAlert(true);
    await populateTaxPayerFetch(requestPayload);
  };

  const checkTelematiciTaxReportList = async () => {
    const response = await fetchTelematicoWaitingTaxReports();
    const { tax_reports_public_telematici_states } = response?.data?.data ?? {
      tax_reports_public_telematici_states: []
    };
    tax_reports_public_telematici_states.forEach(async ({ tax_report_id }) => {
      await checkTelematiciState({ data: { uuid: tax_report_id } });
    });
    setAlertText(t("Telematici has been checked"));
    setAlert(true);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (tenant) {
      companyList.fetch();
      importTax.fetch();
    }
  }, [tenant]);

  return (
    <VaporPage title={t("Actions")}>
      <VaporPage.Section withPadding>
        <Container maxWidth="sm">
          <Stack spacing={2}>
            <Stack>
              <Button
                variant="outlined"
                onClick={async () => {
                  await loadTaxReportList();
                }}
                disabled={
                  year === undefined ||
                  year === null ||
                  !companyList ||
                  !companyList.data
                }
              >
                {t("ImportTaxReportList")}
              </Button>
            </Stack>

            <Stack>
              <Button
                variant="outlined"
                onClick={async () => {
                  await checkTelematiciTaxReportList();
                }}
              >
                {t("CheckTelematiciTaxReportList")}
              </Button>
            </Stack>

            {alert && (
              <Stack>
                <Alert variant="outlined">
                  <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" alignItems="center">
                      <Typography>{alertText}</Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          cursor: "pointer"
                        }}
                        color={theme.palette.primary.interactiveDefault}
                        onClick={() => {
                          setAlert(false);
                          navigate(`/tax-reports`);
                        }}
                      >
                        {t("GoTaxReportsList")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Alert>
              </Stack>
            )}
          </Stack>
        </Container>
      </VaporPage.Section>
    </VaporPage>
  );
};

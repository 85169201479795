import React, { useEffect, useState } from "react";

import { useTranslation } from "@onefront/react-sdk";

import Box from "@vapor/react-material/Box";
import MenuItem from "@vapor/react-material/MenuItem";
import Select from "@vapor/react-material/Select";
import Stack from "@vapor/react-material/Stack";

//Chart
import ChartPie from "./ChartPie";

const DeclarationStatus = ({ kpiList }) => {
  const { t } = useTranslation("foa");

  //const { kpiList } = useKpiListContext();
  const [filteredKpiList, setFilteredKpiList] = useState([]);
  const [taxReport, setTaxReport] = useState("IVN");
  const [year, setYear] = useState(new Date().getFullYear());

  const years = Array.from(
    { length: new Date().getFullYear() - 2022 },
    (_, index) => 2023 + index
  );
  useEffect(() => {
    const newKpiList = kpiList.filter((kpi) => kpi.year === year);
    setFilteredKpiList(newKpiList);
  }, [year, kpiList]);

  const handleTaxReportChange = (event) => {
    setTaxReport(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction="row">
        <Select
          sx={{
            minWidth: "100px",
            marginLeft: "5px !important",
            height: "40px"
          }}
          value={year}
          size="small"
          onChange={handleYearChange}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
        <Select
          sx={{ width: "200px", marginLeft: "8px !important" }}
          value={taxReport}
          size="small"
          onChange={handleTaxReportChange}
        >
          <MenuItem value="IVN">{t("IVN")}</MenuItem>
          <MenuItem value="WPF">{t("WPF")}</MenuItem>
          <MenuItem value="WSC">{t("WSC")}</MenuItem>
          <MenuItem value="WSP">{t("WSP")}</MenuItem>
          <MenuItem value="IRAP">{t("IRAP")}</MenuItem>
          <MenuItem value="LIPE I">{`${t("LIPE")} I`}</MenuItem>
          <MenuItem value="LIPE II">{`${t("LIPE")} II`}</MenuItem>
          <MenuItem value="LIPE III">{`${t("LIPE")} III`}</MenuItem>
          <MenuItem value="LIPE IV">{`${t("LIPE")} IV`}</MenuItem>
          <MenuItem value="IVATR I">{`${t("IVATR")} I`}</MenuItem>
          <MenuItem value="IVATR II">{`${t("IVATR")} II`}</MenuItem>
          <MenuItem value="IVATR III">{`${t("IVATR")} III`}</MenuItem>
        </Select>
      </Stack>
      <Stack>
        <ChartPie taxReport={taxReport} kpiList={filteredKpiList} year={year} />
      </Stack>
    </Box>
  );
};

export default DeclarationStatus;

const formatAmount = (amount, minimumFractionDigits, maximumFractionDigits) => {
  return amount?.toLocaleString("it-IT", {
    minimumFractionDigits,
    maximumFractionDigits
  });
};

const formatFloatAmount = (
  amount,
  minimumFractionDigits,
  maximumFractionDigits
) => {
  return formatAmount(
    parseFloat(amount),
    minimumFractionDigits,
    maximumFractionDigits
  );
};

export { formatAmount, formatFloatAmount };

import { useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { ExtendedTextfield } from "@onefront/workspace-forms/components/General/ExtendedTextfield/ExtendedTextfield.js";
import { ExtendedSelect } from "@onefront/workspace-forms/components/General/ExtendedSelect/ExtendedSelect.js";
import { ExtendedMenuItem } from "@onefront/workspace-forms/components/General/ExtendedMenuItem/ExtendedMenuItem.js";

import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { onChangeHandlerField } from "../../../handlers";

export const TenantPfSelfEmployment = () => {
  const { t } = useTranslation();
  const {
    sotCompanySettings,
    companySettings,
    setCompanySettings,
    isEditMode,
    setIsEditMode,
    loading,
    setAnyChange
  } = useCompanySettingsContext();

  const onChangeHandler = (e) => {
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  return (
    <CompanySettingsGrid>
      <ExtendedTextfield
        name="inpsOfficeCodeSelfemployment"
        value={companySettings.inpsOfficeCodeSelfemployment}
        onChange={onChangeHandler}
        label={t("inpsOfficeCode")}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
      />
      <ExtendedTextfield
        name="inpsOfficeNameSelfemployment"
        value={companySettings.inpsOfficeNameSelfemployment}
        onChange={onChangeHandler}
        label={t("inpsOfficeNameSelfemployment")}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
      />
      <ExtendedSelect
        label={t("ivsPositionSelfemployment")}
        name="ivsPositionSelfemployment"
        value={companySettings.ivsPositionSelfemployment}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem
          label={t("otherSubgetsSeparatedMgmt")}
          value="otherSubgetsSeparatedMgmtSelfemployment"
        >
          {t("otherSubgetsSeparatedMgmt")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("noProtectionProfessionalsSeparatedMgmt")}
          value="noProtectionProfessionalsSeparatedMgmtSelfemployment"
        >
          {t("noProtectionProfessionalsSeparatedMgmt")}
        </ExtendedMenuItem>
      </ExtendedSelect>
    </CompanySettingsGrid>
  );
};

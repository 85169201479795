import { useQuery } from "@onefront/react-sdk";

//TODO this file should return all the tax report with telematici sending status = "waiting"
const GET_WAITING_TELEMATICI_TAX_REPORTS = `query GetWaitingTelematiciStates {
  tax_reports_public_telematici_states(where: {sending_status: {_eq: "waiting"}}) {
    tax_report_id
    taxpayer_id
    tenant_id
    validation_status
    sending_status
  }
}
`;

export const useGetTelematicoWaitingTaxReports = ({ tenantId }) => {
  return useQuery(
    GET_WAITING_TELEMATICI_TAX_REPORTS,
    {},
    {
      lazy: true,
      headers: {
        "tenant-id": tenantId
      }
    }
  );
};

import { useState, useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import Autocomplete from "@vapor/react-material/Autocomplete";
import Typography from "@vapor/react-extended/ExtendedTypography";
import TextField from "@vapor/react-material/TextField";
import Checkbox from "@vapor/react-material/Checkbox";

import declarationTypes from "./declarationTypes";

export const TreeViewSelectFilter = ({
  label,
  value,
  setValue,
  multiple = true,
  style
}) => {
  const { t } = useTranslation();
  const [selection, setSelection] = useState(multiple ? [] : null);

  const flattedDeclarationTypes = declarationTypes.flatMap((x) => x.options);

  useEffect(() => {
    const newSelection = Array.isArray(value)
      ? value
          .map((item) => {
            return flattedDeclarationTypes.find((x) => x.value === item);
          })
          .filter(Boolean)
      : flattedDeclarationTypes.find((x) => x.value === value);
    setSelection(newSelection);
  }, [value]);

  return (
    <Autocomplete
      multiple={multiple}
      options={flattedDeclarationTypes}
      disableClearable={false}
      disableCloseOnSelect={multiple}
      getOptionLabel={(option) => (Array.isArray(option) ? "" : option?.label)}
      value={selection}
      onChange={(_, newValue) => setValue(newValue)}
      renderOption={
        multiple
          ? ({ key, ...otherProps }, option, { selected }) => (
              <li key={key} {...otherProps}>
                <Checkbox
                  size="small"
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )
          : null
      }
      sx={{
        "& .MuiInputBase-root": { flexWrap: "nowrap" },
        width: "100%",
        ...style
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t("filterDeclarations")}
          label={label}
        />
      )}
      renderTags={(values) => {
        const joinedValues = values?.map((value) => value.label).join(", ");
        return joinedValues ? (
          <Typography
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden"
            }}
          >
            {joinedValues}
          </Typography>
        ) : null;
      }}
      onInputChange={(e, value) => {
        // This method is done for the purpose of clearing the value when the user clicks on the clear button
        if (!value) setValue(null);
      }}
    />
  );
};

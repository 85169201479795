import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "@onefront/react-sdk";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@vapor/react-material/Button";
import Dialog from "@vapor/react-material/Dialog";
import DialogActions from "@vapor/react-material/DialogActions";
import DialogContent from "@vapor/react-material/DialogContent";
import DialogTitle from "@vapor/react-material/DialogTitle";
import Divider from "@vapor/react-material/Divider";
import Alert from "@vapor/react-material/Alert";
import IconButton from "@vapor/react-material/IconButton";
import { useTheme } from "@vapor/react-material/styles";

import { useCqrs } from "../../../services/cqrs";
import declarationTypeOptions from "../../components/declarationTypes.json";
import { useIntermediary } from "../hooks/useIntermediary";
import { useTaxReportData } from "../providers/taxReportDataProvider";
import { useFiscaliSaasIframe } from "../../../services/fiscali-saas-iframe/FiscaliSaasIframeProvider";
import { useWorkspaceData } from "../../../services/workspace/workspaceProvider";
import { validateDichInfo } from "../helpers/validate-dich-info";

export const DialogInfoTaxReport = ({ open, onClose, children }) => {
  const { t } = useTranslation();
  const { issueCommand, isIssueCommandLoading } = useCqrs();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    taxReportData,
    setTaxReportData,
    taxReportAssignee,
    setInitializingTaxReport
  } = useTaxReportData();
  const { initializeFiscaliTaxReport } = useFiscaliSaasIframe();
  const intermediaryId =
    taxReportData?.document?.input?.workspaces?.companyData?.intermediaryId;
  const { workspaceUrl } = useWorkspaceData();
  const { fullIntermediary: intermediary, loading: loadingIntermediary } =
    useIntermediary({
      intermediaryId,
      tenantId: taxReportData?.document?.tenant_id
    });
  const [thereAreErrors, setThereAreErrors] = useState(false);

  /*****************/
  //Actions dialog
  const handleCancel = () => {
    onClose();
    navigate("/tax-reports");
  };

  const handleOk = async () => {
    const newDocument = structuredClone(taxReportData.document);
    newDocument.data = {
      ...structuredClone(newDocument.data),
      todo: {
        lastModificationDate: new Date(),
        // TODO: Check this date
        deliveryAt: new Date(),
        assignedTo: taxReportAssignee,
        list: [
          "Verifica risultato e correggi errori",
          "Firma lettera di impegno",
          "Valida telematico",
          "Firma della dichiarazione",
          "Invia telematico",
          "Acquisisci ricevute di invio",
          "Archivia (Facoltativo)",
          "Crea correttiva o integrativa (Facoltativo)"
        ].reduce((acc, cur) => [...acc, { label: cur, checked: false }], [])
      }
    };
    newDocument.input.workspaces.intermediaryData = intermediary;

    setInitializingTaxReport(true);
    const res = await issueCommand("initialize", "tax-reports", newDocument, {
      headers: {
        "taxpayer-id": taxReportData.taxpayer_id
      },
      shouldComplete: true
    });

    if (res.response) {
      setTaxReportData(res.response.update_tax_report);
      const newTaxReport = await initializeFiscaliTaxReport(
        res.response.update_tax_report
      );
      if (newTaxReport) setTaxReportData(newTaxReport);
      onClose();
    }
  };
  /*****************/

  const declarationOptionsMap = declarationTypeOptions
    .flatMap((x) => x.options)
    .reduce((objMap, option) => {
      objMap[option.value] = option.label;

      return objMap;
    }, {});

  const dialogTitle = taxReportData?.document?.taxReport.includes("IVN")
    ? "dialogTitleTaxReportIVN"
    : "dialogTitleTaxReport";

  useEffect(() => {
    if (taxReportData) {
      const { isValid } = validateDichInfo(taxReportData?.document);
      setThereAreErrors(!isValid);
    }
  }, [taxReportData]);

  return (
    <Dialog
      fullWidth
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          minWidth: "40%"
        }
      }}
    >
      <DialogTitle>
        {taxReportData &&
          t(dialogTitle, {
            taxReport:
              declarationOptionsMap[taxReportData?.document?.taxReport],
            taxPayerName:
              taxReportData?.document?.input?.workspaces?.companyData
                ?.personalData?.businessName
          })}
        <IconButton
          color={theme.palette.background.default}
          size="medium"
          onClick={handleCancel}
        >
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <Divider variant="fullWidth" />
      {isIssueCommandLoading}
      <DialogContent>
        {children}
        {thereAreErrors && (
          <Alert
            severity="error"
            variant="outlined"
            icon={
              <FontAwesomeIcon
                icon={{
                  icon: [
                    512,
                    512,
                    ["minus-circle"],
                    "f056",
                    "M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"
                  ],
                  iconName: "circle-minus",
                  prefix: "fas"
                }}
              />
            }
          >
            <strong>{t("warning")}</strong>: {`${t("missingAnagraphicData")}.`}
            <a
              href={
                workspaceUrl +
                "/details/" +
                taxReportData?.document?.input?.workspaces?.companyData?.id +
                "/registry"
              }
            >
              {t("goToTaxPayer")}
            </a>
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleCancel}
          disabled={isIssueCommandLoading || loadingIntermediary}
        >
          {t("dialogTaxReportCancelButton")}
        </Button>
        <Button
          variant="contained"
          onClick={handleOk}
          disabled={
            isIssueCommandLoading || loadingIntermediary || thereAreErrors
          }
        >
          {t("dialogTaxReportOkButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { useTranslation } from "@onefront/react-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleX, faList } from "@fortawesome/pro-regular-svg-icons";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";
import { useTheme } from "@vapor/react-material/styles";

import { ReportDataButton } from "./ReportDataButton";

export const EmptyReportData = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Stack
        alignItems="flex-end"
        justifyContent="space-between"
        direction="row"
        sx={{
          marginBottom: "9px"
        }}
      >
        <FontAwesomeIcon
          icon={faList}
          style={{ fontSize: "100px" }}
          color={theme.palette.primary.azureishWhite}
        />
        <FontAwesomeIcon
          icon={faCircleX}
          style={{
            fontSize: "48px",
            marginBottom: "-5px",
            marginLeft: "-27px"
          }}
          color={theme.palette.primary.interactiveDefault}
        />
      </Stack>
      <Typography
        sx={{
          lineHeight: "32px",
          marginTop: "8px",
          fontWeight: 700,
          color: theme.palette.primary.textDefaultColor
        }}
        variant="titleMedium"
      >
        {t("noDataToDisplay")}
      </Typography>
      <Typography
        variant="body"
        sx={{
          marginTop: "8px",
          marginBottom: "24px",
          fontSize: "16px",
          fontFamily: "Roboto",
          color: theme.palette.primary.textDisabledColor,
          textAlign: "center"
        }}
      >
        {t("noAccountingDataAvailable")} <br />
        {t("receiveAccountingData")}
      </Typography>
      <ReportDataButton />
    </Stack>
  );
};

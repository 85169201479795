import { useState, Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChevronDown,
  faChevronUp,
  faExternalLink,
  faMessage,
  faSquareQuestion
} from "@fortawesome/pro-regular-svg-icons";

import { useTranslation } from "@onefront/react-sdk";

import { useTheme } from "@vapor/react-material/styles";
import Box from "@vapor/react-material/Box";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Tooltip from "@vapor/react-material/Tooltip";
import IconButton from "@vapor/react-material/IconButton";
import DataGridPro from "@vapor/react-x-data-grid/DataGridPro";

import { NotesDialog } from "./NotesDialog";
import { ReportDataFieldStatus } from "./ReportDataFieldStatus";
import { useTaxReportData } from "../../providers/taxReportDataProvider";
import { useFiscaliSaasIframe } from "../../../../services/fiscali-saas-iframe/FiscaliSaasIframeProvider";
import { formatFloatAmount } from "../../../../utils";
import { useReportPageData } from "../../providers/reportPageDataProvider";

const NoteIcon = ({ fieldName, setIsNoteDialogOpen, setCurrentNoteField }) => {
  const theme = useTheme();

  return (
    <FontAwesomeIcon
      key="notes"
      icon={faMessage}
      color={theme.palette.primary.primary60Color}
      style={{
        cursor: "pointer",
        height: 20,
        width: 18
      }}
      onClick={() => {
        setCurrentNoteField(fieldName);
        setIsNoteDialogOpen(true);
      }}
    />
  );
};

const DirectLinkIcon = ({ row }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <FontAwesomeIcon
      key="notes"
      icon={faExternalLink}
      color={theme.palette.primary.primary60Color}
      style={{
        cursor: "pointer",
        height: 20,
        width: 18
      }}
      title={t("notImplementedYet")}
    />
  );
};

const DescriptionCell = ({ row }, maxDescrLength = 53) => {
  if (row.description.length < maxDescrLength) return row.description;

  return (
    <>
      <Tooltip arrow title={row.description}>
        <IconButton>
          <FontAwesomeIcon icon={faSquareQuestion} />
        </IconButton>
      </Tooltip>
      {row.description.substring(0, maxDescrLength)}...
    </>
  );
};

const DestinationCell = ({ row }) => {
  const theme = useTheme();
  const { setTaxReportVisibleContent, setHeaderMinimized } = useTaxReportData();
  const { gotoCampo } = useFiscaliSaasIframe();
  const field = row.destination.slice(0, 2) + "_" + row.destination.slice(2);
  const gotoField = (field) => {
    setHeaderMinimized(false);
    setTaxReportVisibleContent("model");
    gotoCampo(field);
  };
  return (
    <>
      <Typography>{row.destination}</Typography>
      <FontAwesomeIcon
        icon={faArrowRight}
        color={theme.palette.primary.primary60Color}
        style={{
          size: "16px",
          height: 24,
          width: 24,
          marginLeft: "auto"
        }}
        onClick={() => gotoField(field)}
      />
    </>
  );
};

const dataGridSx = {
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  "&>.MuiDataGrid-main": {
    "&>.MuiDataGrid-columnHeaders": {
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none"
    },

    "& div div div div >.MuiDataGrid-cell": {
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none"
    }
  }
};

const getColumnsFilteredBySource = (
  t,
  setIsNoteDialogOpen,
  setCurrentNoteField
) => [
  // TODO: Check and collapse when they are correctly defined
  {
    field: "description",
    headerName: t("Description"),
    flex: 2.5,
    renderCell: ({ row }) => DescriptionCell({ row }, 69)
  },
  {
    field: "destination",
    headerName: t("Destination"),
    flex: 0.5,
    renderCell: ({ row }) => DestinationCell({ row })
  },
  {
    field: "value",
    align: "right",
    headerAlign: "right",
    headerName: t("Amount"),
    flex: 0.75,
    renderCell: ({ row }) => formatFloatAmount(row.value ?? 0, 2)
  },
  // For these two, we need to set a different field (columns have to be unique) and a renderHeader (prevents the field name appearing in the column)
  {
    field: "notes",
    renderHeader: () => null,
    flex: 0.25,
    sortable: false,
    renderCell: ({ row }) =>
      NoteIcon({
        fieldName: row.name,
        setIsNoteDialogOpen,
        setCurrentNoteField
      })
  },
  {
    field: "direct_link",
    flex: 0.25,
    renderHeader: () => null,
    sortable: false,
    renderCell: ({ row }) => DirectLinkIcon(row)
  }
];

const getColumnsNotFilteredBySource = (
  t,
  setIsNoteDialogOpen,
  setCurrentNoteField
) => [
  {
    field: "name",
    headerName: t("Line"),
    flex: 1
  },
  {
    field: "description",
    headerName: t("Description"),
    flex: 3,
    renderCell: ({ row }) => DescriptionCell({ row })
  },
  {
    field: "status",
    headerName: t("Status"),
    flex: 1,
    maxWidth: 200,
    renderCell: ReportDataFieldStatus
  },
  {
    field: "value",
    align: "right",
    headerAlign: "right",
    headerName: t("Amount"),
    flex: 0.75,
    renderCell: ({ row }) => formatFloatAmount(row.value ?? 0, 2)
  },
  {
    field: "notes",
    renderHeader: () => null,
    flex: 0.25,
    sortable: false,
    renderCell: ({ row }) =>
      NoteIcon({
        fieldName: row.name,
        setIsNoteDialogOpen,
        setCurrentNoteField
      })
  }
];

const getDetailPanelContent = ({ row }, t, theme) => {
  const columns = [
    {
      field: "description",
      headerName: t("Detail"),
      flex: 4,
      renderCell: ({ row }) => DescriptionCell({ row }, 110)
    },
    {
      field: "value",
      align: "right",
      headerName: t("Amount"),
      flex: 0.5,
      renderCell: ({ row }) => formatFloatAmount(row.value ?? 0, 2)
    },
    {
      flex: 0.25,
      sortable: false,
      renderCell: ({ row }) => DirectLinkIcon(row)
    }
  ];

  const dataGridSubsection = {
    fontSize: "16px !important",
    color: theme.palette.primary.textTitleColor,
    overflow: "hidden !important"
  };

  const boxSubsection = {
    width: "100%",
    paddingLeft: "40px",
    paddingRight: "40px",
    overflow: "hidden !important"
  };

  return (
    <>
      {Object.entries(row.sources).map(([source, fields], index) => (
        <Fragment key={index}>
          <Box key={row.name} sx={boxSubsection}>
            <Typography sx={dataGridSubsection}>{t(source)}</Typography>
            <DataGridPro
              sx={dataGridSx}
              rows={fields}
              columns={columns}
              getRowId={(subRow) => `${row.name}-${source}-${subRow.name}`}
              autoPageSize
              disableColumnMenu
              hideFooter
              disableColumnResize={true}
              checkboxSelection
            />
          </Box>
        </Fragment>
      ))}
    </>
  );
};

export const ReportDataTableSectionContent = ({ fieldsToShow }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { filters, dataToShow } = useReportPageData();
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);
  const [notes, setNotes] = useState({});
  const [currentNoteField, setCurrentNoteField] = useState("");

  const detailPanelContent = filters?.filteredBySource
    ? null
    : ({ row }) => getDetailPanelContent({ row }, t, theme);

  const columns = filters?.filteredBySource
    ? getColumnsFilteredBySource(
        t,
        setIsNoteDialogOpen,
        setIsNoteDialogOpen,
        dataToShow
      )
    : getColumnsNotFilteredBySource(
        t,
        setIsNoteDialogOpen,
        setCurrentNoteField
      );

  return (
    <>
      <DataGridPro
        sx={{
          ...dataGridSx,
          maxHeight: "calc(41vh)"
        }}
        rows={fieldsToShow}
        checkboxSelection
        disableRowSelectionOnClick
        columns={columns}
        getRowId={(row) =>
          `${filters?.filteredBySource ? row.destination : row.source}-${
            row.name
          }`
        }
        hideFooter
        autoPageSize
        disableColumnMenu
        disableColumnResize={true}
        getDetailPanelContent={detailPanelContent}
        slots={{
          detailPanelExpandIcon: () => (
            <FontAwesomeIcon
              key="notes"
              icon={faChevronDown}
              color={theme.palette.primary.primary60Color}
              style={{
                cursor: "pointer",
                height: 20,
                width: 18
              }}
            />
          ),
          detailPanelCollapseIcon: () => (
            <FontAwesomeIcon
              key="notes"
              icon={faChevronUp}
              color={theme.palette.primary.primary60Color}
              style={{
                cursor: "pointer",
                height: 20,
                width: 18
              }}
            />
          )
        }}
      />
      <NotesDialog
        fieldName={currentNoteField}
        isNoteDialogOpen={isNoteDialogOpen}
        setIsNoteDialogOpen={setIsNoteDialogOpen}
        notes={notes}
        setNotes={setNotes}
      />
    </>
  );
};

import React, { useEffect, useState } from "react";
import Box from "@vapor/react-material/Box";

import { useGetTaxReport } from "../../../../../hooks/useGetTaxReport";
import { DialogInfoTaxReport } from "../../DialogInfoTaxReport";

export const Default = () => {
  const { data } = useGetTaxReport();

  const [isOpenDialog, setOpenDialog] = useState(false);

  const handleClose = (newValue) => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setOpenDialog(
      data?.tax_reports_get_curr_item[0]?.document?.states?.wfsState === "Todo"
    );
  }, [data]);

  return (
    <Box sx={{ padding: "20px" }}>
      {isOpenDialog && (
        <DialogInfoTaxReport
          data={data}
          isOpen={isOpenDialog}
          open={isOpenDialog}
          onClose={handleClose}
        />
      )}
    </Box>
  );
};

import { useEffect, useRef, Fragment, useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "@onefront/react-sdk";
import { Box, Stack, Divider } from "@vapor/react-material";
import { constants } from "@fiscal-orchestrator/fior-utils";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";

import { useTaxReportData } from "../../../providers/taxReportDataProvider";
import { TaxReportPageAccordionMenu } from "../../Checks/TaxReportPageAccordionMenu";
import { useBrowserWindowSize } from "../../../../../hooks/useBrowserWindowSize";
import TaxReportPagevatCreditSettlements from "../../Checks/TaxReportPagevatCreditSettlements";
import TaxReportPageISAScore from "../../Checks/TaxReportPageISAScore";
import TaxReportPagePreviousRefundCheck from "../../Checks/TaxReportPagePreviousRefundCheck";
import { TaxReportPageResult } from "../../Result/TaxReportPageResult";
import { TaxReportPageActions } from "../../Result/TaxReportPageActions";
import { ResultCard } from "../../Result/ResultCard";
import { AnnualDataDialog } from "../../../helpers/tax-report-dialog-data";
import { DialogInfoTaxReport } from "../../DialogInfoTaxReport";
import { TaxPayerCell } from "../../../../tax-reports-list/components/tax-reports-list-table/TaxPayerCell";
import {
  formatFloatAmount,
  formatDate,
  orderDateHour
} from "../../../../../utils";
import { useWorkspaceData } from "../../../../../services/workspace/workspaceProvider";
import { FiscaliSaasIframeWrapper } from "../../FiscaliSaasIframeWrapper";
import { ReportData } from "../../ReportData/ReportData";
import { ReportPageDataWrapper } from "../../../providers/reportPageDataProvider";

export const IVATR_V1 = ({ tabs }) => {
  const { t } = useTranslation("foa");

  const {
    taxReportData,
    setTaxReportVisibleContent,
    possibleContents,
    setPossibleContents,
    taxReportVisibleContent
  } = useTaxReportData();

  const { workspaceUrl } = useWorkspaceData();
  const { height } = useBrowserWindowSize();
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const vatCreditSettlementsRef = useRef(null);
  const previousRefundCheckRef = useRef(null);
  const isaScoreRef = useRef(null);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChange = () => (event, isExpanded) => {
    setExpanded(!isExpanded);
  };

  useEffect(() => {
    setPossibleContents(tabs?.contents);
    setTaxReportVisibleContent(tabs?.firstContent);
  }, []);

  DialogInfoTaxReport.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };

  useEffect(() => {
    setOpenDialog(taxReportData?.document?.states?.wfsState === "Todo");
  }, [taxReportData]);

  const companyData = taxReportData?.document?.input?.workspaces?.companyData;
  const dateTaxReport = orderDateHour(taxReportData?.updated_at);
  const vatPeriodicity = companyData?.business?.find(
    (item) => item.type === "MAIN"
  )?.vatConfiguration?.vatPeriodicity;
  const period = taxReportData?.document?.period;
  let checksSections = [];
  const months = constants.taxReportPeriods.periodToMonths[period];

  if (period !== "Q1") {
    const type = constants.taxReportPeriods.quartersToInteger[period] - 1;
    const typeTaxReport = constants.taxReportPeriods.IVATRNames[type];

    checksSections = [
      {
        text: t("vatCreditSettlements"),
        ref: vatCreditSettlementsRef,
        component: TaxReportPagevatCreditSettlements,
        props: {
          title: t("vatCreditSettlements"),
          subtitle: t("lastChangeDateTemplate", {
            date: formatDate(taxReportData?.updated_at),
            interpolation: { escapeValue: false }
          }),
          year: taxReportData?.document?.year,
          months,
          periodicity: vatPeriodicity,
          //TODO: This value should be calculated based on the data from mapping from IVA-TR
          // totalAmountRefunded: PENDING
          // VAT credit settlement > €2,582.28 (basic requirement) for requesting all or part of the amount for reimbursement.
          // Report LiPe result in CREDIT for the same period
          // the presence of a credit vat settlement can be obtained from the vat settlement result for the same period form the API:
          // VAT- businessSettlements--> Total “VatAmountCredit” in the case of a single business. In case of multi-business the tag should be the same “VatAmountCredit”
          vatCreditSettlements:
            taxReportData?.document?.data?.checks?.vatCreditSettlements
        }
      },
      {
        text: t("previousRefundCheck"),
        ref: previousRefundCheckRef,
        component: TaxReportPagePreviousRefundCheck,
        props: {
          title: t("previousRefundCheck"),
          subtitle: t("lastChangeDateTemplate", {
            date: formatDate(taxReportData?.updated_at),
            interpolation: { escapeValue: false }
          }),
          typeTaxReport,
          //TODO: This value should be database searched
          filledPreviousTaxReport: true,
          previousRefundCheck:
            taxReportData?.document?.data?.checks?.previousRefundCheck
        }
      },
      {
        text: t("isaScore"),
        ref: isaScoreRef,
        component: TaxReportPageISAScore,
        props: {
          title: t("isaScore"),
          subtitle: t("lastChangeDateTemplate", {
            date: formatDate(taxReportData?.updated_at),
            interpolation: { escapeValue: false }
          }),
          isaScore: taxReportData?.document?.data?.checks?.isaScore
        }
      }
    ];
  } else {
    checksSections = [
      {
        text: t("vatCreditSettlements"),
        ref: vatCreditSettlementsRef,
        component: TaxReportPagevatCreditSettlements,
        props: {
          title: t("vatCreditSettlements"),
          subtitle: t("lastChangeDateTemplate", {
            date: formatDate(taxReportData?.updated_at),
            interpolation: { escapeValue: false }
          }),
          year: taxReportData?.document?.year,
          months,
          periodicity: vatPeriodicity,
          //TODO: This value should be calculated based on the data from mapping from IVA-TR
          // totalAmountRefunded: PENDING
          // VAT credit settlement > €2,582.28 (basic requirement) for requesting all or part of the amount for reimbursement.
          //Report LiPe result in CREDIT for the same period
          //the presence of a credit vat settlement can be obtained from the vat settlement result for the same period form the API:
          //VAT- businessSettlements--> Total “VatAmountCredit” in the case of a single business. In case of multi-business the tag should be the same “VatAmountCredit”
          vatCreditSettlements:
            taxReportData?.document?.data?.checks?.vatCreditSettlements
        }
      },
      {
        text: t("isaScore"),
        ref: isaScoreRef,
        component: TaxReportPageISAScore,
        props: {
          title: t("isaScore"),
          subtitle: t("lastChangeDateTemplate", {
            date: formatDate(taxReportData?.updated_at),
            interpolation: { escapeValue: false }
          }),
          isaScore: taxReportData?.document?.data?.checks?.isaScore
        }
      }
    ];
  }
  const resultData = [];
  //  TODO: This value should be calculated based on field TD6 of the VAT TR form
  resultData.push({
    title: t("amountRequestedReimbursement"),
    value: taxReportData?.document?.data?.fiscaliSaas?.dataOut?.[
      "Frontespizio IVA TR&0#IVT_Cas_TA_TE$TA-TB-TC-TD"
    ]?.TD_06
      ? formatFloatAmount(
          taxReportData?.document?.data?.fiscaliSaas?.dataOut?.[
            "Frontespizio IVA TR&0#IVT_Cas_TA_TE$TA-TB-TC-TD"
          ]?.TD_06,
          2,
          2
        )
      : "0,00"
  });
  //  TODO: This value should be calculated based on field TD7 of the VAT TR form
  resultData.push({
    title: t("ForCompensation"),
    value: taxReportData?.document?.data?.fiscaliSaas?.dataOut?.[
      "Frontespizio IVA TR&0#IVT_Cas_TA_TE$TA-TB-TC-TD"
    ]?.TD_07
      ? formatFloatAmount(
          taxReportData?.document?.data?.fiscaliSaas?.dataOut?.[
            "Frontespizio IVA TR&0#IVT_Cas_TA_TE$TA-TB-TC-TD"
          ]?.TD_07,
          2,
          2
        )
      : "0,00"
  });
  const getResults = () => {
    return resultData?.map((result, index) => {
      if (result.title !== "") {
        return <ResultCard key={index} result={result} />;
      }
    });
  };

  return (
    <FiscaliSaasIframeWrapper>
      {
        //Checks
        taxReportVisibleContent === possibleContents.checks && (
          <Box sx={{ padding: "20px", height: "100%", overflow: "hidden" }}>
            <Stack direction="row">
              <TaxReportPageAccordionMenu links={checksSections} />
              <Stack
                direction="column"
                sx={{
                  paddingTop: "32px",
                  alignItems: "flex-start",
                  flexGrow: "1",
                  maxHeight: `${height - 341}px`,
                  overflowY: "auto"
                }}
              >
                {checksSections.map((link, index) => (
                  <Fragment key={index}>
                    <link.component ref={link.ref} {...link.props} />
                    <Divider />
                  </Fragment>
                ))}
                <Stack
                  direction="column"
                  sx={{
                    marginTop: "27px",
                    alignItems: "flex-end",
                    width: "100%"
                  }}
                ></Stack>
              </Stack>
            </Stack>
          </Box>
        )
      }
      {
        // Report data
        taxReportVisibleContent === possibleContents.reportData && (
          <ReportPageDataWrapper>
            <ReportData
              reportData={taxReportData?.document?.data?.reportData}
            />
          </ReportPageDataWrapper>
        )
      }
      {
        //Result
        taxReportVisibleContent === possibleContents.result && (
          <Box sx={{ padding: "20px", height: "100%", overflow: "hidden" }}>
            <TaxReportPageResult>
              <Stack>
                <Stack sx={{ width: "100%" }} alignItems="flex-start">
                  <Stack
                    direction="row"
                    display="flex"
                    alignItems="flex-start"
                    width="920px"
                    useFlexGap
                    flexWrap="wrap"
                    gap="16px"
                  >
                    {getResults()}
                  </Stack>
                </Stack>
              </Stack>
              <TaxReportPageActions />
              <Accordion
                sx={{
                  marginBlockStart: "15px",
                  width: "100%",
                  padding: "32px 0px 0px 24px",
                  boxShadow: "none"
                }}
                defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
                  aria-controls="panel1a-content"
                >
                  <Typography variant="titleSmall">
                    {t("relatedTaxreport")}
                  </Typography>
                </AccordionSummary>
              </Accordion>
            </TaxReportPageResult>
          </Box>
        )
      }
      {isOpenDialog && (
        <DialogInfoTaxReport
          data={taxReportData}
          isOpen={isOpenDialog}
          open={isOpenDialog}
          onClose={handleClose}
        >
          <Accordion expanded={expanded} onChange={handleChange()}>
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <Typography variant="titleSmall">
                {t("dialogTaxReportData1")}
              </Typography>
              <Typography variant="body">
                {t("dialogTaxReportDate", dateTaxReport).replaceAll(
                  "&#x2F;",
                  "/"
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TaxPayerCell
                row={{
                  taxpayer_type: taxReportData?.document?.taxpayerType,
                  taxpayer_name:
                    taxReportData?.document?.input?.workspaces?.companyData
                      ?.personalData?.businessName,
                  taxpayer_id: taxReportData?.taxpayer_id
                }}
                modal={true}
                workspaceUrl={workspaceUrl}
              />
              <AnnualDataDialog />
            </AccordionDetails>
          </Accordion>
        </DialogInfoTaxReport>
      )}
    </FiscaliSaasIframeWrapper>
  );
};

import { createElement } from "react";
import { LIPE_V1 } from "./LIPE_V1";
import { DEFAULT_PAGE } from "../DEFAULT_PAGE";

const modelPerYearMapping = {
  2024: LIPE_V1
};

const tabs = {
  contents: {
    checks: "checks",
    reportData: "reportData",
    result: "result",
    model: "model"
  },
  firstContent: "checks"
};

export const LIPE = {
  match: (taxReport) => {
    return (
      taxReport === "LIPE I" ||
      taxReport === "LIPE II" ||
      taxReport === "LIPE III" ||
      taxReport === "LIPE IV"
    );
  },
  render: ({ year }) => {
    const componentToRender = modelPerYearMapping[year] || DEFAULT_PAGE;

    return createElement(componentToRender, { tabs: tabs });
  }
};

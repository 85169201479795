import { useState } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { useTheme } from "@vapor/react-material/styles";
import Stack from "@vapor/react-material/Stack";
import Button from "@vapor/react-material/Button";
import Grid from "@vapor/react-material/Grid";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExtendedTextfield } from "@onefront/workspace-forms/components/General/ExtendedTextfield/ExtendedTextfield.js";
import { ExtendedSelect } from "@onefront/workspace-forms/components/General/ExtendedSelect/ExtendedSelect.js";
import { ExtendedMenuItem } from "@onefront/workspace-forms/components/General/ExtendedMenuItem/ExtendedMenuItem.js";

import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { ExtendedToggle } from "../../ExtendedToggle";

export const TenantIVASupervisoryBodySubscription = ({
  settings,
  onChange
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { loading, save, handleOpenDialog, anyChange } =
    useCompanySettingsContext();
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      gap="24px"
      sx={{
        border: `solid 1px ${theme.palette.primary.interactiveDefault}`,
        padding: "16px",
        borderRadius: "10px",
        width: "100%"
      }}
    >
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
        onClick={() => setIsEditMode(true)}
        sx={{ alignSelf: "flex-end" }}
      />
      <Grid container justifyContent="space-between" spacing={4}>
        <Grid item xs={6}>
          <ExtendedSelect
            label={t("subjectCode")}
            name="subjectCode"
            value={settings.subjectCode}
            onChange={onChange}
            editing={isEditMode}
            loading={loading}
          >
            <ExtendedMenuItem label={t("nessuno")} value="0">
              {t("nessuno")}
            </ExtendedMenuItem>
            <ExtendedMenuItem label={t("auditor")} value="1">
              {t("auditor")}
            </ExtendedMenuItem>
            <ExtendedMenuItem label={t("responsibleAudit")} value="2">
              {t("responsibleAudit")}
            </ExtendedMenuItem>
            <ExtendedMenuItem label={t("auditingCompany")} value="3">
              {t("auditingCompany")}
            </ExtendedMenuItem>
            <ExtendedMenuItem label={t("boardAuditors")} value="4">
              {t("boardAuditors")}
            </ExtendedMenuItem>
          </ExtendedSelect>
        </Grid>
        <Grid item xs={6}>
          <ExtendedTextfield
            label={t("taxCode")}
            noValueLabel={t("notSet")}
            value={settings.taxCode}
            editing={isEditMode}
            name="taxCode"
            onChange={onChange}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <ExtendedToggle
            onChange={onChange}
            value={settings.signature}
            label={t("signature")}
            name="signature"
            editing={isEditMode}
            noValueLabel={t("notSet")}
            loading={loading}
            positiveLabel={t("checkboxPositive")}
            negativeLabel={t("checkboxNegative")}
          />
        </Grid>
      </Grid>
      {isEditMode && (
        <Stack direction="row" alignItems="center" gap="16px">
          <Button
            variant="contained"
            onClick={() => {
              save();
              setIsEditMode(false);
            }}
          >
            {t("save")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              if (anyChange) handleOpenDialog();
              else setIsEditMode(false);
            }}
          >
            {t("cancel")}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

import { useTranslation } from "@onefront/react-sdk";
import Dialog from "@vapor/react-material/Dialog";
import DialogActions from "@vapor/react-material/DialogActions";
import DialogContent from "@vapor/react-material/DialogContent";
import DialogTitle from "@vapor/react-material/DialogTitle";
import Divider from "@vapor/react-material/Divider";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@vapor/react-material/Button";
import IconButton from "@vapor/react-material/IconButton";
import { useTheme } from "@vapor/react-material/styles";

export const TaxReportChangesNotSavedDialog = ({
  open,
  onCloseDialog,
  onClickOk,
  onClickExit
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Dialog
      fullWidth
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          minWidth: "40%"
        }
      }}
    >
      <DialogTitle>
        {t("unsavedChanges")}
        <IconButton
          color={theme.palette.background.default}
          size="medium"
          onClick={onCloseDialog}
        >
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <Divider variant="fullWidth" />
      <DialogContent>{t("warningUnsavedChanges")}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClickExit}>
          {t("exitWithoutSaving")}
        </Button>
        <Button variant="contained" onClick={onClickOk}>
          {t("SaveAndExit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { useMemo } from "react";

import { useTheme } from "@vapor/react-material/styles";
import {
  faBuilding,
  faUser,
  faUsers
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Avatar from "@vapor/react-material/Avatar";

const TaxPayerAvatar = ({ taxPayerType, sx }) => {
  const theme = useTheme();

  const iconType = useMemo(() => {
    const taxPayerTypeToIcon = {
      SP: faUsers,
      PF: faUser,
      SC: faBuilding
    };

    return taxPayerType && taxPayerTypeToIcon[taxPayerType];
  }, [taxPayerType]);

  return (
    <Avatar sx={{ ...sx }}>
      <FontAwesomeIcon
        icon={iconType}
        color={theme.palette.primary.primary80Color}
      />
    </Avatar>
  );
};

export default TaxPayerAvatar;

import { forwardRef } from "react";

import Accordion from "@vapor/react-extended/Accordion";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import Typography from "@vapor/react-extended/ExtendedTypography";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";

const TaxReportPageAccordion = forwardRef((props, ref) => {
  return (
    <Accordion
      sx={{ marginBlockStart: "15px", width: "100%" }}
      defaultExpanded={true}
    >
      <AccordionSummary
        ref={ref}
        expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
        aria-controls="panel1a-content"
      >
        <Typography variant="titleSmall">{props.title}</Typography>
        <Typography variant="body">{props.subtitle}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "16px 48px",
          alignIitems: "flex-start",
          gap: "16px",
          alignSelf: "stretch",
          ...props.accordionDetailsSx
        }}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
});

export default TaxReportPageAccordion;

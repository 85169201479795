import { useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { ExtendedDatePicker } from "@onefront/workspace-forms/components/General/ExtendedDatePicker/ExtendedDatePicker.js";
import { ExtendedTextfield } from "@onefront/workspace-forms/components/General/ExtendedTextfield/ExtendedTextfield.js";
import { ExtendedAutocomplete } from "@onefront/workspace-forms/components/General/ExtendedAutocomplete/ExtendedAutocomplete.js";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { useTheme } from "@vapor/react-material/styles";

import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { onChangeHandlerField } from "../../../handlers";
import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import { cities } from "../../../../../fixtures/cities";
import { provinces } from "../../../../../fixtures/provinces";
import { regions } from "../../../../../fixtures/regions";

export const TenantPFFiscalData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    sotCompanySettings,
    companySettings,
    setCompanySettings,
    isEditMode,
    setIsEditMode,
    loading,
    setAnyChange
  } = useCompanySettingsContext();

  const year = new Date().getFullYear();

  const onChangeHandler = (e) => {
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  const getAutocompleteValue = (value, values, itemField = "value") =>
    typeof value !== "string"
      ? value
      : values.find((item) => item[itemField] === value);

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  return (
    <CompanySettingsGrid>
      <Typography
        variant="subtitle"
        color={theme.palette.primary.textTitleColor}
        component="div"
        fullRow={true}
      >
        {t("yearResidence", { year: year - 1 })}
      </Typography>
      <ExtendedDatePicker
        name="previousYearResidence.residenceChangeDate"
        noValueLabel={t("notSet")}
        editing={isEditMode}
        value={companySettings.previousYearResidence?.residenceChangeDate}
        onChange={(name, value) =>
          onChangeHandler({
            target: { name, value: value.replace("T00:00:00", "") }
          })
        }
        label={t("residenceChangeDate")}
        loading={loading}
      />
      <ExtendedAutocomplete
        label={t("city")}
        name="previousYearResidence.cityCode"
        loading={loading}
        value={getAutocompleteValue(
          companySettings.previousYearResidence?.cityCode,
          cities,
          "code"
        )}
        noValueLabel={t("notSet")}
        editing={isEditMode}
        options={cities}
        getOptionLabel={(option) => option.label || ""}
        getOptionKey={(option) => option.code}
        onChange={onChangeHandler}
      />
      <ExtendedAutocomplete
        label={t("province")}
        name="previousYearResidence.province"
        loading={loading}
        value={getAutocompleteValue(
          companySettings.previousYearResidence?.province,
          provinces
        )}
        noValueLabel={t("notSet")}
        editing={isEditMode}
        options={provinces}
        getOptionLabel={(option) => option.label || ""}
        getOptionKey={(option) => option.code}
        onChange={onChangeHandler}
      />
      <ExtendedAutocomplete
        label={t("region")}
        name="previousYearResidence.regionCode"
        loading={loading}
        value={getAutocompleteValue(
          companySettings.previousYearResidence?.regionCode,
          regions
        )}
        noValueLabel={t("notSet")}
        editing={isEditMode}
        options={regions}
        getOptionLabel={(option) => option.label || ""}
        getOptionKey={(option) => option.code}
        onChange={onChangeHandler}
      />
      <ExtendedTextfield
        label={t("citiesMerge")}
        noValueLabel={t("notSet")}
        value={companySettings.previousYearResidence?.citiesMerge}
        editing={isEditMode}
        name="previousYearResidence.citiesMerge"
        onChange={onChangeHandler}
        loading={loading}
      />
      <ExtendedTextfield
        label={t("particularCases")}
        noValueLabel={t("notSet")}
        value={companySettings.previousYearResidence?.particularCases}
        editing={isEditMode}
        name="previousYearResidence.particularCases"
        onChange={onChangeHandler}
        loading={loading}
      />
      <Typography
        variant="subtitle"
        color={theme.palette.primary.textTitleColor}
        component="div"
        fullRow={true}
      >
        {t("yearResidence", { year })}
      </Typography>
      <ExtendedAutocomplete
        label={t("city")}
        name="currentYearResidence.cityCode"
        loading={loading}
        value={getAutocompleteValue(
          companySettings.currentYearResidence?.cityCode,
          cities,
          "code"
        )}
        noValueLabel={t("notSet")}
        editing={isEditMode}
        options={cities}
        getOptionLabel={(option) => option.label || ""}
        getOptionKey={(option) => option.code}
        onChange={onChangeHandler}
      />
      <ExtendedAutocomplete
        label={t("province")}
        name="currentYearResidence.province"
        loading={loading}
        value={getAutocompleteValue(
          companySettings.currentYearResidence?.province,
          provinces
        )}
        noValueLabel={t("notSet")}
        editing={isEditMode}
        options={provinces}
        getOptionLabel={(option) => option.label || ""}
        getOptionKey={(option) => option.code}
        onChange={onChangeHandler}
      />
      <ExtendedTextfield
        label={t("citiesMerge")}
        noValueLabel={t("notSet")}
        value={companySettings.currentYearResidence?.citiesMerge}
        editing={isEditMode}
        name="currentYearResidence.citiesMerge"
        onChange={onChangeHandler}
        loading={loading}
      />
    </CompanySettingsGrid>
  );
};

import { forwardRef } from "react";

import { useTranslation } from "@onefront/react-sdk";

import Typography from "@vapor/react-extended/ExtendedTypography";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";

import TaxReportPageAccordion from "../TaxReportPageAccordion";

const TaxReportPageAccountingModule = forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <TaxReportPageAccordion
      ref={ref}
      title={t("accountingReport")}
      subtitle={t("lastChangeDateTemplate", {
        date: "dati non disponibili",
        interpolation: { escapeValue: false }
      })}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="bodyLarge700">
          {t("downloadReportFromAccounting")}
        </Typography>
        <Button onClick={() => console.log("click")} size="small">
          {t("exportFile")}
        </Button>
      </Stack>
    </TaxReportPageAccordion>
  );
});

export default TaxReportPageAccountingModule;

import { useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";

import { onChangeHandlerField } from "../../../handlers";
import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import { TenantIVAModule } from "./TenantIVAModule";

import { useGetWorkspaceBusiness } from "../../../hooks/useGetWorkspaceBusiness";
import { useWorkspaceData } from "../../../providers/WorkspaceDataProvider";

import { mapBusiness } from "./helpers/map-business";

export const TenantIVAModuleManagement = () => {
  const { t } = useTranslation();
  const {
    companySettings,
    setCompanySettings,
    setIsEditMode,
    sotCompanySettings,
    setAnyChange
  } = useCompanySettingsContext();
  const { workspaceData } = useWorkspaceData();

  const onChangeModuleManagementHandler = (e, selectedIndex) => {
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings,
      array: {
        name: "modulesManagement",
        index: selectedIndex
      }
    });
  };

  const onAddNewModule = () => {
    setCompanySettings({
      ...companySettings,
      modulesManagement: [...(companySettings.modulesManagement || []), {}]
    });
  };

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  const { fetch: getWorkspaceBusinessFetch, data: workspaceBusiness } =
    useGetWorkspaceBusiness({
      taxpayerId: workspaceData?.id
    });

  useEffect(() => {
    if (workspaceData?.id) getWorkspaceBusinessFetch();
  }, [workspaceData]);

  useEffect(() => {
    if (
      ((companySettings.modulesManagement &&
        !companySettings.modulesManagement.length) ||
        companySettings.modulesManagement === null) &&
      workspaceBusiness &&
      workspaceBusiness.length
    ) {
      setCompanySettings({
        ...companySettings,
        modulesManagement: mapBusiness(workspaceBusiness, workspaceData)
      });
    }
  }, [workspaceBusiness]);

  return (
    <CompanySettingsGrid editable={false}>
      <Button
        fullRow={true}
        variant="text"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={onAddNewModule}
      >
        {t("addNewModule")}
      </Button>
      <Stack fullRow={true} gap={"16px"}>
        {companySettings?.modulesManagement?.map((business, index) => {
          business.module = business.module ?? index + 1;
          return (
            <TenantIVAModule
              business={business}
              key={index}
              onChange={(e) => onChangeModuleManagementHandler(e, index)}
            />
          );
        })}
      </Stack>
    </CompanySettingsGrid>
  );
};

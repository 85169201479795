import { CompanySettingsPage } from "./components/CompanySettingsPage";
import { MenuWrapper } from "./providers/MenuProvider";

const BASE_PATH = "/company/:id";

export const companySettings = () => [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      path: BASE_PATH,
      element: <CompanySettingsPage />
    }
  },
  {
    target: "$REACT_ROOT_WRAPPER",
    handler: {
      component: MenuWrapper
    }
  }
];

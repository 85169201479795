import { useState } from "react";

import { useGridApiContext } from "@mui/x-data-grid-pro";
import Stack from "@vapor/react-material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight
} from "@fortawesome/pro-solid-svg-icons";

export const TaxReportPageQuadriGroupColumnRender = ({
  id,
  field,
  rowNode,
  row
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const apiRef = useGridApiContext();

  const handleClick = (event) => {
    if (rowNode.type !== "group") {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        marginLeft: `${18 * (row.path.length - 1)}px`,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }}
    >
      {row.hasChildren && (
        <FontAwesomeIcon
          key="status"
          icon={isOpen ? faChevronDown : faChevronRight}
          size="xs"
          onClick={handleClick}
          style={{
            padding: "12px",
            cursor: "pointer"
          }}
        />
      )}
      <span
        style={{
          marginLeft: row.hasChildren ? "0px" : "30px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }}
      >
        {row?.description}
      </span>
    </Stack>
  );
};

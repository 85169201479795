export const preparePayloadEditCommand = (document, webInit) => {
  if (
    typeof document !== "object" ||
    document === null ||
    Array.isArray(document) ||
    Object.keys(document).length === 0 ||
    typeof webInit !== "object" ||
    webInit === null ||
    Array.isArray(webInit) ||
    Object.keys(webInit).length === 0
  ) {
    throw new Error("Invalid payload");
  }

  const newDocument = structuredClone(document);

  newDocument.fiscaliSaas = {
    ...newDocument.fiscaliSaas,
    ...webInit
  };

  return newDocument;
};

export const preparePayloadSaveCommand = (document, webClose) => {
  if (
    typeof document !== "object" ||
    document === null ||
    Array.isArray(document) ||
    Object.keys(document).length === 0 ||
    typeof webClose !== "object" ||
    webClose === null ||
    Array.isArray(webClose) ||
    Object.keys(webClose).length === 0
  ) {
    throw new Error("Invalid payload");
  }

  const newDocument = structuredClone(document);
  newDocument.fiscaliSaas = {
    ...newDocument.fiscaliSaas,
    ...webClose
  };
  return newDocument;
};

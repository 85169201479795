import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "@onefront/react-sdk";

import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import Accordion from "@vapor/react-material/Accordion";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";

import { useTaxReportData } from "../../../providers/taxReportDataProvider";

//Dialog
import { DialogInfoTaxReport } from "../../DialogInfoTaxReport";

//Step 1
import TaxReportPageVatPayment from "../../Checks/TaxReportPageVatPayment";
import TaxReportPageVatRefunds from "../../Checks/TaxReportPageVatRefunds";
import TaxReportPageCheckingF24 from "../../Checks/TaxReportPageCheckingF24";
import TaxReportPagePayments from "../../Checks/TaxReportPagePayments";
import TaxReportPagePreviousTaxData from "../../Checks/TaxReportPagePreviousTaxData";
import TaxReportPageLipeVerification from "../../Checks/TaxReportPageLipeVerification";
import TaxReportPageCalculationCompany from "../../Checks/TaxReportPageCalculationCompany";
import { TaxReportPageResult } from "../../Result/TaxReportPageResult";
import { TaxReportPageAccordionMenu } from "../../Checks/TaxReportPageAccordionMenu";
import { TaxReportResult } from "../../Result/TaxReportResult";
import { TaxReportPageActions } from "../../Result/TaxReportPageActions";
import { TaxReportPageQuadri } from "../../Result/TaxReportPageQuadri";
import { TaxPayerCell } from "../../../../tax-reports-list/components/tax-reports-list-table/TaxPayerCell";
import { AnnualDataDialog } from "../../../helpers/tax-report-dialog-data";
import { orderDateHour } from "../../../../../utils";
import { formatFloatAmount } from "../../../../../utils/format-numbers";
import { useWorkspaceData } from "../../../../../services/workspace/workspaceProvider";
import { FiscaliSaasIframeWrapper } from "../../FiscaliSaasIframeWrapper";
import { ReportData } from "../../ReportData/ReportData";
import { TaxReportPageChecksStack } from "../../Checks/TaxReportPageChecksStack";
import { ReportPageDataWrapper } from "../../../providers/reportPageDataProvider";

export const IVN_V2 = ({ tabs }) => {
  const { t } = useTranslation();
  const {
    taxReportData,
    taxReportVisibleContent,
    setTaxReportVisibleContent,
    possibleContents,
    setPossibleContents
  } = useTaxReportData();
  const { workspaceUrl } = useWorkspaceData();

  const [isOpenDialog, setOpenDialog] = useState(false);
  const [expanded, setExpanded] = useState("panel1");

  const previousTaxDataRef = useRef(null);
  const vatRefundsRef = useRef(null);
  const chekingF24Ref = useRef(null);
  const paymentsRef = useRef(null);
  const calculationCompanyRef = useRef(null);
  const lipeVerificationRef = useRef(null);
  const vatPaymentRef = useRef(null);

  const checksSectionsProps = {
    year: 2024,
    checksData: taxReportData?.document?.data?.fiscaliSaas?.checks
  };

  const checksSections = [
    {
      text: t("previousYearDeclaration"),
      ref: previousTaxDataRef,
      component: TaxReportPagePreviousTaxData,
      props: checksSectionsProps
    },
    {
      text: t("ivaYearlyRefund"),
      ref: vatRefundsRef,
      component: TaxReportPageVatRefunds,
      props: checksSectionsProps
    },
    {
      text: t("paymentF24Omitted", { year: checksSectionsProps.year }),
      ref: chekingF24Ref,
      component: TaxReportPageCheckingF24,
      props: checksSectionsProps
    },
    {
      text: t("paymentDoneYear", { year: checksSectionsProps.year }),
      ref: paymentsRef,
      component: TaxReportPagePayments,
      props: checksSectionsProps
    },
    {
      text: t("shellCompanyCalculation"),
      ref: calculationCompanyRef,
      component: TaxReportPageCalculationCompany,
      props: checksSectionsProps
    },
    {
      text: t("checkSendingLipe"),
      ref: lipeVerificationRef,
      component: TaxReportPageLipeVerification,
      props: checksSectionsProps
    },
    {
      text: t("vatAdvancePayment"),
      ref: vatPaymentRef,
      component: TaxReportPageVatPayment,
      props: checksSectionsProps
    }
  ];

  const modIVA0 =
    taxReportData?.document?.data?.fiscaliSaas?.dataOut?.["Modulo IVA&0"];

  const resultData = [
    {
      title: modIVA0?.["VX_01"]
        ? t("DebitDeclaration")
        : t("CreditDeclaration"),
      subtitle: modIVA0?.["VX_01"] ? "" : t("RepaymentRequest"),
      value: modIVA0?.["VX_02_c1"]
        ? formatFloatAmount(modIVA0?.["VX_02_c1"], 2, 2)
        : modIVA0?.["VX_01"]
        ? formatFloatAmount(modIVA0?.["VX_01"], 2, 2)
        : "0,00",
      otherValue: modIVA0?.["VX_01"]
        ? ""
        : modIVA0?.["VX_04_c1"]
        ? formatFloatAmount(modIVA0?.["VX_04_c1"], 2, 2)
        : "0,00"
    },
    {
      title: t("BusinessVolume"),
      value: modIVA0?.["VE_50"]
        ? formatFloatAmount(
            taxReportData?.document?.data?.fiscaliSaas?.dataOut?.[
              "Modulo IVA&0"
            ]?.["VE_50"],
            2,
            2
          )
        : "0,00"
    },
    {
      title: modIVA0?.["VX_01"] ? "" : t("ForCompensation"),
      value: modIVA0?.["VX_01"]
        ? ""
        : modIVA0?.["VX_05"]
        ? formatFloatAmount(modIVA0?.["VX_05"], 2, 2)
        : "0,00"
    },
    {
      title:
        modIVA0?.["VX_01"] && modIVA0?.["VX_01_NumRate"] ? t("NumberRate") : "",
      value: modIVA0?.["VX_01_NumRate"]
        ? formatFloatAmount(modIVA0?.["VX_01_NumRate"], 2, 2)
        : "0"
    }
  ];

  const dateTaxReport = orderDateHour(taxReportData?.updated_at);

  DialogInfoTaxReport.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };

  const handleClose = (newValue) => {
    setOpenDialog(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setPossibleContents(tabs?.contents);
    setTaxReportVisibleContent(tabs?.firstContent);
  }, []);

  useEffect(() => {
    setOpenDialog(taxReportData?.document?.states?.wfsState === "Todo");
  }, [taxReportData]);

  return (
    <FiscaliSaasIframeWrapper>
      {
        //Checks
        taxReportVisibleContent === possibleContents.checks && (
          <Box sx={{ padding: "20px", height: "100%", overflow: "hidden" }}>
            <Stack direction="row">
              <TaxReportPageAccordionMenu links={checksSections} />
              <TaxReportPageChecksStack checksSections={checksSections} />
            </Stack>
          </Box>
        )
      }
      {
        // Report data
        taxReportVisibleContent === possibleContents.reportData && (
          <ReportPageDataWrapper>
            <ReportData
              reportData={taxReportData?.document?.data?.reportData}
              sheetList={taxReportData?.document?.data?.fiscaliSaas?.sheetList}
            />
          </ReportPageDataWrapper>
        )
      }
      {
        //Result
        taxReportVisibleContent === possibleContents.result && (
          <Box sx={{ padding: "20px", height: "100%", overflow: "hidden" }}>
            <TaxReportPageResult>
              <TaxReportResult resultData={resultData} />
              <TaxReportPageActions />
              <TaxReportPageQuadri
                sheetList={
                  taxReportData?.document?.data?.fiscaliSaas?.sheetList
                }
              />
            </TaxReportPageResult>
          </Box>
        )
      }
      {isOpenDialog && (
        <DialogInfoTaxReport
          data={taxReportData}
          isOpen={isOpenDialog}
          open={isOpenDialog}
          onClose={handleClose}
        >
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <Typography variant="titleSmall">
                {t("dialogTaxReportData1")}
              </Typography>
              <Typography variant="body">
                {t("dialogTaxReportDate", dateTaxReport).replaceAll(
                  "&#x2F;",
                  "/"
                )}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <TaxPayerCell
                row={{
                  taxpayer_type: taxReportData?.document?.taxpayerType,
                  taxpayer_name:
                    taxReportData?.document?.input?.workspaces?.companyData
                      ?.personalData?.businessName,
                  taxpayer_id: taxReportData?.taxpayer_id
                }}
                modal={true}
                workspaceUrl={workspaceUrl}
              />
              <AnnualDataDialog />
            </AccordionDetails>
          </Accordion>
          {/* <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <Typography variant="titleSmall">
                {t("dialogTaxReportData2")}
              </Typography>
              {companySettingsDetails?.data?.company_data_public_entries
                .length > 0 && (
                <Typography variant="body">
                  {t("dialogTaxReportDate", dateCompanySetting).replaceAll(
                    "&#x2F;",
                    "/"
                  )}
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={1}>
                {companySettingsDetails.data !== null && (
                  <MultiactivityDialog
                    companySettingsDetails={companySettingsDetails.data}
                  />
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <Typography variant="titleSmall">
                {t("dialogTaxReportData3")}
              </Typography>
              <Typography variant="body">
                {t(
                  "dialogTaxReportDate",
                  orderDateHour(taxReportData?.updated_at)
                ).replaceAll("&#x2F;", "/")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body">Data: </Typography>
              <Typography variant="bodySmall700">To be defined</Typography>
            </AccordionDetails>
          </Accordion>
          {companySettingsDetails?.data?.company_data_public_entries?.length ===
            0 && (
            <Box sx={{ marginTop: "15px" }}>
              <Stack spacing={2}>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      color={theme.palette.primary.baseWarningColor}
                      style={{
                        marginRight: "12px",
                        cursor: "pointer",
                        height: 20,
                        width: 18
                      }}
                    />
                    <Typography variant="body">
                      {t("dialogTaxReportError1")}
                    </Typography>
                  </Stack>
                  <Button
                    sx={{ minWidth: "150px !important" }}
                    size="small"
                    onClick={() => {
                      //! Don´t work with mock server data
                      navigate(`/company/${taxReportData?.tenant_id}`);
                    }}
                  >
                    {t("dialogTaxReportErrorButton")}
                  </Button>
                </Stack>
              </Stack>
              <Stack spacing={2}>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      color={theme.palette.primary.baseWarningColor}
                      style={{
                        marginRight: "12px",
                        cursor: "pointer",
                        height: 20,
                        width: 18
                      }}
                    />
                    <Typography variant="body">
                      {t("dialogTaxReportError2")}
                    </Typography>
                  </Stack>
                  <Button
                    sx={{ minWidth: "150px !important" }}
                    size="small"
                    onClick={() => {
                      //! Don´t work with mock server data
                      navigate(`/company/${taxReportData?.tenant_id}`);
                    }}
                  >
                    {t("dialogTaxReportErrorButton")}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          )} */}
        </DialogInfoTaxReport>
      )}
    </FiscaliSaasIframeWrapper>
  );
};

import { useTranslation } from "@onefront/react-sdk";

import { useCompanySettingsContext } from "../../providers/CompanySettingsProvider";
import { onChangeHandlerField } from "../../handlers";
import { CompanySettingsGrid } from "../CompanySettingsGrid";
import { ExtendedToggle } from "../ExtendedToggle";

export const TenantSP = () => {
  const { t } = useTranslation();
  const { companySettings, setCompanySettings, isEditMode, loading } =
    useCompanySettingsContext();

  const onChangeHandler = (e) => {
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  return (
    <CompanySettingsGrid>
      <ExtendedToggle
        name="landBuildingsFramework"
        value={companySettings.landBuildingsFramework}
        onChange={onChangeHandler}
        label={t("landBuildingsFramework")}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
    </CompanySettingsGrid>
  );
};

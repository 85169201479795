import { useTheme } from "@vapor/react-material/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faTimesCircle,
  faCheckCircle,
  faPaperPlane,
  faExclamationCircle,
  faFilePlus,
  faArchive,
  faFileCircleCheck
} from "@fortawesome/pro-solid-svg-icons";

import Typography from "@vapor/react-extended/ExtendedTypography";
import { useTranslation } from "@onefront/react-sdk";
export const TaxReportStatus = ({ row, header }) => {
  const theme = useTheme();

  const { t } = useTranslation("foa");

  const typeStatus = {
    todo: {
      icon: faFilePlus,
      color: theme.palette.warning.light
    },
    ongoing: {
      icon: faCircleNotch,
      color: theme.palette.success.dark
    },
    withErrors: {
      icon: faExclamationCircle,
      color: theme.palette.error.dark
    },
    withAlerts: {
      icon: faExclamationCircle,
      color: theme.palette.warning.light
    },
    closed: {
      icon: faCheckCircle,
      color: theme.palette.success.dark
    },
    generated: {
      icon: faFileCircleCheck,
      color: theme.palette.success.dark
    },
    sent: {
      icon: faPaperPlane,
      color: theme.palette.success.dark
    },
    rejected: {
      icon: faTimesCircle,
      color: theme.palette.error.dark
    },
    archived: {
      icon: faArchive,
      color: theme.palette.success.dark
    }
  };

  return (
    <>
      <FontAwesomeIcon
        key="status"
        style={{
          marginRight: "4px",
          fontSize: "16px",
          fontWeight: "900",
          lineHeight: "20px"
        }}
        icon={typeStatus[row.status].icon}
        color={
          header
            ? theme.palette.primary.yankeesBlue
            : typeStatus[row.status].color
        }
      />
      <Typography variant="Body">{t(row.status)}</Typography>
    </>
  );
};

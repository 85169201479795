import { useTranslation } from "@onefront/react-sdk";
import { Fragment } from "react";

import Stack from "@vapor/react-material/Stack";
import Grid from "@vapor/react-material/Grid";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Accordion from "@vapor/react-extended/Accordion";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import Divider from "@vapor/react-material/Divider";
import { useTheme } from "@vapor/react-material/styles";

import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const sxGridItem = { textAlign: "end" };
const GridRow = ({ rowStyle = null, cell1, cell2, cell3, cell4 }) => (
  <Grid container sx={rowStyle}>
    <Grid item xs={6}>
      {cell1}
    </Grid>
    <Grid item xs sx={sxGridItem}>
      {cell2}
    </Grid>
    <Grid item xs sx={sxGridItem}>
      {cell3}
    </Grid>
    <Grid item xs sx={sxGridItem}>
      {cell4 && (
        <Typography
          variant="bodyLarge"
          color="primary.interactiveDefault"
          sx={{ fontWeight: "400" }}
        >
          {cell4}
        </Typography>
      )}
    </Grid>
  </Grid>
);

export const TaxReportOtherTaxes = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const sxDivider = { marginTop: "24px" };

  return (
    <Accordion
      defaultExpanded={true}
      sx={{
        width: "100%",
        padding: "32px 0px 0px 24px",
        boxShadow: "none"
      }}
    >
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faAngleDown} />}>
        <Typography variant="titleSmall">{t("otherTaxes")}</Typography>
        <Typography
          sx={{
            textAlign: "right",
            color: theme.palette.primary.textSubduedColor
          }}
          variant="body"
        >
          {t("detailsCreditsDebits")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data.map((row, index) => (
          <Fragment key={index}>
            <Stack direction="column">
              {row.map((column, index) => (
                <GridRow key={`gridrow-${index}`} {...column} />
              ))}
            </Stack>
            <Divider light sx={sxDivider} />
          </Fragment>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

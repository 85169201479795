import {
  faAngleDown,
  faAngleUp,
  faRemove
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VaporTag } from "@vapor/react-custom";
import { Button, Stack } from "@vapor/react-material";
import { useEffect, useState } from "react";
import useQueryString from "use-query-string";

import LoadingSpinner from "../../../components/LoadingSpinner";
import Notification from "../../../components/Notification";
import TaxpayerButton from "./TaxpayerButton.jsx";

import { useCurrentTenant } from "@drift/oneplatfront";
import { useTranslation } from "@onefront/react-sdk";
import { TreeViewSelectFilter } from "../../../components/TreeViewSelectFilter.jsx";
import { useGetTaxpayerTaxReports } from "../../hooks/useGetTaxpayerTaxReports.js";
import { useFiscalYearContext } from "../../providers/fiscal-year.js";
import { useTaxReportsListContext } from "../../providers/tax-reports-list-wrapper.js";
import { SearchFilter } from "./SearchFilter.jsx";
import { SelectFilter } from "./SelectFilter.jsx";
import conditionOptions from "./conditions";
import statusesOptions from "./statuses";

import FormControl from "@vapor/react-material/FormControl";
import { useCompanyList } from "../../../../hooks/companyList.js";
import { generateChips, generateFilters } from "./helpers/index.js";

const commonFilterStyle = { flex: 1, flexShrink: 0, maxWidth: "24%" };

const TaxReportsListFilters = () => {
  const [queryString] = useQueryString(window.location, (path) => {
    window.history.pushState(null, document.title, path);
  });

  const { t } = useTranslation("foa");

  const { tenant } = useCurrentTenant(true);
  const { fiscalYear: year, setFiscalYear } = useFiscalYearContext({
    initialFiscalYear: parseInt(queryString.year) ?? new Date().getFullYear()
  });

  const companyList = useCompanyList({ tenantId: tenant?.itemId });

  const { setTaxReportsList, setFilters, filters, loading } =
    useTaxReportsListContext();
  const { fetch, cleanedData } = useGetTaxpayerTaxReports({
    tenantId: tenant?.itemId
  });

  const [triggerFetch, setTriggerFetch] = useState(false);

  const searchText = filters.searchText || "";
  const setSearchText = (value) => {
    setFilters({ ...filters, searchText: value });
    setTriggerFetch(!triggerFetch);
  };
  const declarationType = filters.declarationType || [];
  const setDeclarationType = (value) => {
    setFilters({ ...filters, declarationType: value });
    setTriggerFetch(!triggerFetch);
  };

  const status = filters.state?.[0] || "";
  const setStatus = (value) => {
    setFilters({ ...filters, state: [value] });
    setTriggerFetch(!triggerFetch);
  };
  const condition = filters.condition?.[0] || "";
  const setCondition = (value) => {
    setFilters({ ...filters, condition: [value] });
    setTriggerFetch(!triggerFetch);
  };
  const modifiedBy = filters.modifiedBy || "";
  const setModifiedBy = (value) => {
    setFilters({ ...filters, modifiedBy: value });
    setTriggerFetch(!triggerFetch);
  };

  const [showFilters, setShowFilters] = useState(false);
  const [chipsFilters, setChipsFilters] = useState([]);

  const clearFilters = () => {
    setFilters(searchText ? { searchText } : {});
    setTriggerFetch(!triggerFetch);
  };

  useEffect(() => {
    if (Object.keys(queryString).length > 0) {
      const queryFilters = [
        "declarationType",
        "state",
        "condition",
        "modifiedBy",
        "searchText",
        "status"
      ].reduce((acc, cur) => {
        if (
          (cur === "searchText" || cur === "modifiedBy") &&
          queryString[cur] !== undefined
        )
          return { ...acc, [cur]: queryString[cur] };
        if (
          cur === "declarationType" &&
          queryString.declarationType !== undefined
        )
          return {
            ...acc,
            declarationType: queryString.declarationType.split(",")
          };
        if (queryString[cur] !== undefined)
          return {
            ...acc,
            [cur]: [queryString[cur]]
          };
        return acc;
      }, {});
      setFilters(queryFilters);
      setTriggerFetch(!triggerFetch);
    }
    if (tenant) companyList.fetch();
  }, []);

  useEffect(() => {
    if (queryString.year) setFiscalYear(+queryString.year);
  }, [queryString]);

  // Update showed Chips of filters
  useEffect(() => {
    if (tenant) {
      const chip = generateChips(
        declarationType,
        status,
        condition,
        modifiedBy
      );
      const filter = generateFilters(chip, searchText, modifiedBy);
      setChipsFilters(chip);

      setTaxReportsList({});
      fetch({
        year: year,
        filtersApplied: filter,
        order: "asc",
        orderBy: ["taxpayer_name"],
        offset: 0
      });
    }
  }, [year, triggerFetch, tenant]);

  useEffect(() => {
    if (cleanedData) setTaxReportsList(cleanedData);
  }, [cleanedData]);

  const [infoText] = useState(t("taxReportExampleInfo"));
  const [openInfo, setOpenInfo] = useState(false);

  /*Handlers*******************************/
  const handlerCloseInfo = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenInfo(false);
  };

  //TODO this should be in a wrapper

  if (companyList.error) return t("ErrorLoadingCompanies");

  if (companyList.loading && !loading) return <LoadingSpinner />;

  return (
    <>
      <Stack
        justifyContent="space-between"
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          sx={{ marginTop: "16px" }}
        >
          <SearchFilter
            initialValue={searchText}
            onSearch={(newValue) => {
              setSearchText(newValue);
            }}
          />
          <Button
            onClick={() => {
              setShowFilters(!showFilters);
            }}
            startIcon={
              <FontAwesomeIcon icon={showFilters ? faAngleUp : faAngleDown} />
            }
          >
            {t("OtherFilters")}
          </Button>
        </Stack>
        <TaxpayerButton />
      </Stack>
      {chipsFilters.length > 0 && (
        <Stack
          spacing={1}
          direction="row"
          sx={{
            marginTop: "8px",
            display: "inline-flex",
            alignItems: "center"
          }}
        >
          <Button onClick={clearFilters}>{t("ClearFilters")}</Button>
          {chipsFilters.map((filter, index) => (
            <VaporTag
              key={`filter-${index}`}
              deleteIcon={<FontAwesomeIcon icon={faRemove} />}
              size="small"
              label={filter.label}
              onDelete={(e) => {
                if (filter.type === "declarationType")
                  setDeclarationType(
                    declarationType.filter((value) => value !== filter.value)
                  );
                if (filter.type === "state") setStatus("");
                if (filter.type === "condition") setCondition("");
                if (filter.type === "modifiedBy") setModifiedBy("");
              }}
            />
          ))}
        </Stack>
      )}
      {showFilters && (
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="end"
          gap="24px"
          sx={{ marginTop: "16px" }}
        >
          <TreeViewSelectFilter
            label={t("declarationTypes")}
            value={declarationType}
            setValue={(value) => setDeclarationType(value?.map((x) => x.value))}
            style={commonFilterStyle}
          />
          <SelectFilter
            value={status}
            setValue={setStatus}
            options={statusesOptions}
            label={t("Status")}
            style={commonFilterStyle}
          />
          <SelectFilter
            value={condition}
            setValue={setCondition}
            options={conditionOptions}
            label={t("Condition")}
            style={commonFilterStyle}
          />
          <FormControl fullWidth={true} sx={commonFilterStyle}>
            <SearchFilter
              initialValue={modifiedBy}
              onSearch={(newValue) => {
                setModifiedBy(newValue);
              }}
              label={t("lastModifiedBy")}
              variant="primary"
            />
          </FormControl>
        </Stack>
      )}
      <Notification
        isOpen={openInfo}
        text={infoText}
        close={handlerCloseInfo}
      />
    </>
  );
};

export default TaxReportsListFilters;

import Select from "@vapor/react-material/Select";
import MenuItem from "@vapor/react-material/MenuItem";
import InputLabel from "@vapor/react-material/InputLabel";
import FormControl from "@vapor/react-material/FormControl";

import { useTranslation } from "@onefront/react-sdk";

export const SelectFilter = ({
  value,
  setValue,
  options,
  label,
  style,
  fullWidth = true
}) => {
  const { t } = useTranslation("foa");

  return (
    <FormControl fullWidth={fullWidth} sx={style}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      >
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value} sx={{ height: "40px" }}>
            {t(item.value)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

import { useEffect, useState } from "react";

import { getMultipluginRefs } from "@reviso/fiscali-saas";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import ExtendedTab from "@vapor/react-extended/ExtendedTab";

import { useTaxReportData } from "../providers/taxReportDataProvider";

export const TaxReportPageMultipluginHeader = ({
  onChangeTaxReportTypeTab
}) => {
  const { taxReportData } = useTaxReportData();
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState();

  useEffect(() => {
    if (taxReportData) {
      const multipluginReferences = getMultipluginRefs(
        taxReportData.document?.taxReport,
        taxReportData.document?.taxpayerType
      );

      const uniqueArray = [
        ...new Set([taxReportData.document.taxReport, ...multipluginReferences])
      ];
      setTabs(uniqueArray);
      if (!selectedTab) {
        setSelectedTab(uniqueArray[0]);
        onChangeTaxReportTypeTab(uniqueArray[0]);
      }
    }
  }, [taxReportData]);

  return (
    <ExtendedTabs
      value={selectedTab}
      size="small"
      variant="standard"
      onChange={(_, taxReportType) => {
        setSelectedTab(taxReportType);
        onChangeTaxReportTypeTab(taxReportType);
      }}
    >
      {tabs.map((tab, index) => (
        <ExtendedTab value={tab} label={tab} selected={true} key={index} />
      ))}
    </ExtendedTabs>
  );
};

import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef
} from "react";

import { useTaxReportData } from "./taxReportDataProvider";
import { formatDataForReportData } from "./utils";
import { convertSheetListStringToTableRows } from "../helpers/convert-sheet-list-string-to-table-rows";
import { useFiscaliSaasIframe } from "../../../services/fiscali-saas-iframe/FiscaliSaasIframeProvider";

const ReportPageDataContext = createContext();

const getInitialFilters = () => {
  try {
    const sessionFilters = sessionStorage.getItem(
      sessionStorageKeyInitialReportData
    );
    return sessionFilters ? JSON.parse(sessionFilters) : {};
  } catch {
    return {};
  }
};
const getInitialShowFilters = () => {
  try {
    const sessionShowFilters = sessionStorage.getItem(
      sessionStorageKeyInitialShowFiltersReportData
    );
    if (!sessionShowFilters) return false;
    return sessionShowFilters === "true";
  } catch {
    return false;
  }
};

export const sessionStorageKeyInitialReportData = "ReportDataFilters";
export const sessionStorageKeyInitialShowFiltersReportData =
  "ReportDataShowFilters";

export const ReportPageDataWrapper = ({ children }) => {
  const { taxReportData } = useTaxReportData();
  const { errorsList, modifiedList } = useFiscaliSaasIframe();

  const [filters, setFilters] = useState(getInitialFilters());
  const [reportDataSelectedSection, setReportDataSelectedSection] =
    useState("");
  const [showFilters, setShowFilters] = useState(getInitialShowFilters());
  const menuItemsRef = useRef({});

  const { filteredData, sourcesOfData, sectionsList } = useMemo(
    () =>
      formatDataForReportData(
        taxReportData?.document?.data?.reportData ?? [],
        filters ?? {},
        errorsList,
        modifiedList
      ),
    [taxReportData?.document?.data?.reportData, filters]
  );

  const dataToShow = useMemo(
    () =>
      convertSheetListStringToTableRows(
        taxReportData?.document?.data?.fiscaliSaas?.sheetList
      ),
    [taxReportData?.document?.data?.fiscaliSaas?.sheetList]
  );

  useEffect(() => {
    if (sectionsList?.length) {
      const firstSectionWithData =
        Object.values(filteredData).find((array) => array.length > 0) || null;

      const selectedSectionMenu = filters?.filteredBySource
        ? sectionsList?.[0]
        : firstSectionWithData?.[0].section;
      setReportDataSelectedSection(selectedSectionMenu);
    }
  }, [taxReportData?.document?.data?.reportData]);

  useEffect(() => {
    sessionStorage.setItem(
      sessionStorageKeyInitialReportData,
      JSON.stringify(filters)
    );

    if (sectionsList?.length && !filteredData?.[reportDataSelectedSection]) {
      const firstSectionWithData =
        Object.values(filteredData).find((array) => array.length > 0) || null;

      const selectedSectionMenu = filters?.filteredBySource
        ? firstSectionWithData?.[0].source
        : firstSectionWithData?.[0].section;
      setReportDataSelectedSection(selectedSectionMenu);
    }
  }, [filters]);

  useEffect(() => {
    const keys = Object.keys(filteredData).reduce((acc, key) => {
      if (filteredData[key].length) acc[key] = null;
      return acc;
    }, {});

    menuItemsRef.current = keys;
  }, [filteredData]);

  useEffect(() => {
    sessionStorage.setItem(
      sessionStorageKeyInitialShowFiltersReportData,
      showFilters
    );
  }, [showFilters]);

  return (
    <ReportPageDataContext.Provider
      value={{
        filters,
        filteredData,
        sourcesOfData,
        sectionsList,
        dataToShow,
        reportDataSelectedSection,
        showFilters,
        menuItemsRef,
        setReportDataSelectedSection,
        setFilters,
        setShowFilters
      }}
    >
      {children}
    </ReportPageDataContext.Provider>
  );
};

export const useReportPageData = () => useContext(ReportPageDataContext);

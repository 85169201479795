import { useState } from "react";

import InputLabel from "@vapor/react-material/InputLabel";
import TextField from "@vapor/react-material/TextField";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faClose } from "@fortawesome/pro-regular-svg-icons";

import { useTranslation } from "@onefront/react-sdk";

export const SearchFilter = ({
  initialValue,
  onSearch,
  variant = "contained",
  label = "",
  placeholder = "",
  direction = "column"
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState(initialValue || "");

  return (
    <Stack direction={direction}>
      {label !== "" && <InputLabel>{label}</InputLabel>}
      <Stack>
        <TextField
          placeholder={placeholder !== null ? t("Search") : null}
          sx={{
            ".MuiButtonBase-root": {
              minWidth: "auto !important",
              width: "40px !important"
            }
          }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearch(searchText);
            }
          }}
          InputProps={{
            endAdornment: (
              <>
                {searchText !== "" && (
                  <Button
                    onClick={() => {
                      setSearchText("");
                      onSearch("");
                    }}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                )}
                <Button
                  variant={variant}
                  onClick={() => {
                    onSearch(searchText);
                  }}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </>
            )
          }}
        />
      </Stack>
    </Stack>
  );
};

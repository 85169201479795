import { useQuery } from "@onefront/react-sdk";

const GET_KPI_STATUSES = `query GetKpiStatuses {
    result:tax_reports_public_kpi_statuses {
      tenant_id
      year
      data
    }
  }`;

export const useGetKpiStatuses = ({ tenantId }) =>
  useQuery(
    GET_KPI_STATUSES,
    {},
    {
      headers: {
        "tenant-id": tenantId
      }
    }
  );

import { useEffect, useRef, useState } from "react";

import {
  useGet,
  usePost,
  useSnackbar,
  useTranslation
} from "@onefront/react-sdk";

import {
  faAngleDown,
  faCircleCheck,
  faDownload,
  faSignature
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "@vapor/react-extended/Accordion";
import Typography from "@vapor/react-extended/ExtendedTypography";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import { useTheme } from "@vapor/react-material/styles";

import { useCqrs } from "../../../../services/cqrs";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useTaxReportData } from "../../providers/taxReportDataProvider";
import { useTelematico } from "../../providers/telematicoDataProvider";
import { TaxReportPageCard } from "./TaxReportPageCard";
import { TaxReportPageSignaturePanel } from "./TaxReportPageSignaturePanel";
import { TaxReportPageTelematicoCardContent } from "./TaxReportPageTelematicoCardContent";

const MAX_NUMBER_OF_ACTIONS = 4;

export const TaxReportPageActions = () => {
  const theme = useTheme();
  const fileInput = useRef(null);
  const { t } = useTranslation("foa");
  const { issueCommand, isIssueCommandLoading } = useCqrs();
  const { enqueueSnackbar } = useSnackbar();
  const {
    steps,
    validateTelematico,
    downloadReceipt,
    loading: telematicoLoading
  } = useTelematico();
  const { taxReportData, setTaxReportData } = useTaxReportData();
  const [loading, setLoading] = useState(false);

  const getCommitmentLetterTemplate = useGet(
    "rnd::saas:tax-orchestrator:oneplatformConnectorApi://commitmentLetter",
    {
      lazy: true,
      responseType: "blob"
    }
  );

  const getCommitmentLetter = useGet(
    `rnd::saas:tax-orchestrator:oneplatformConnectorApi://api/v1/doc-storage/documents/${taxReportData?.document?.commitmentLetter?.file?.id}`,
    {
      lazy: true
    }
  );

  const uploadCommitmentLetter = usePost(
    "rnd::saas:tax-orchestrator:oneplatformConnectorApi://api/v1/doc-storage/documents",
    {
      lazy: true
    }
  );

  const numberOfActionsCompleted = 0;

  const defaultTelematicoActionPanelButtons = [
    {
      label: t("validateTelematico"),
      icon: faCircleCheck,
      fun: validateTelematico,
      disableCondition:
        steps.sending.status !== "todo" ||
        taxReportData?.document?.states?.wfsState === "Ongoing" ||
        isIssueCommandLoading
    }
  ];

  const sendingTelematicoAtionPanelButtons = [
    {
      label: t("downloadReceipt"),
      icon: faDownload,
      fun: downloadReceipt,
      disableCondition:
        steps?.validation?.status !== "done" ||
        steps?.sending?.status !== "done" ||
        isIssueCommandLoading
    }
  ];

  const [telematicoPossibleActions, setTelematicoPossibleActions] = useState(
    defaultTelematicoActionPanelButtons
  );

  const downloadCommitmentLetterTemplate = async () => {
    setLoading(true);
    const res = await getCommitmentLetterTemplate.fetch();
    try {
      if (res.data) {
        const pdfBlob = new Blob([res.data], {
          type: "application/pdf"
        });
        const url = URL.createObjectURL(pdfBlob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Commitment_Letter_Template.pdf";

        if (taxReportData.document?.states === "commitmentLetterUploaded") {
          // if the commitmentLetter is already uploaded there is no need of updated the status
          a.click();
        } else {
          const cmdResult = await issueCommand(
            "modify",
            "tax-reports",
            {
              uuid: taxReportData.document.uuid,
              version: taxReportData.document.version,
              tenant_id: taxReportData.document.tenant_id,
              taxpayer_id: taxReportData.taxpayer_id,
              modify: {
                modifyData: {
                  states: {
                    ...taxReportData.document?.states,
                    commitmentLetter: "templateDownloaded"
                  }
                }
              }
            },
            {
              headers: {
                "taxpayer-id": taxReportData.taxpayer_id
              },
              shouldComplete: true
            }
          );

          if (cmdResult.response) {
            setTaxReportData(cmdResult.response.update_tax_report);
            a.click();
          }
        }
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file, "UTF-8");
    reader.onload = async (evt) => {
      setLoading(true);
      try {
        const base64Content = evt.target.result.split(",")[1];
        const res = await uploadCommitmentLetter.fetch({
          data: { fileName: file.name, content: base64Content }
        });

        if (res.data) {
          const cmdResult = await issueCommand(
            "modify",
            "tax-reports",
            {
              uuid: taxReportData.document.uuid,
              version: taxReportData.document.version,
              tenant_id: taxReportData.document.tenant_id,
              taxpayer_id: taxReportData.taxpayer_id,
              modify: {
                modifyData: {
                  commitmentLetter: {
                    ...taxReportData.document?.commitmentLetter,
                    file: {
                      ...res.data,
                      fileName: file.name,
                      timestamp: new Date().toISOString()
                    }
                  },
                  states: {
                    ...taxReportData.document.states,
                    commitmentLetter: "commitmentLetterUploaded"
                  }
                }
              }
            },
            {
              headers: {
                "taxpayer-id": taxReportData.taxpayer_id
              },
              shouldComplete: true
            }
          );

          if (cmdResult.response)
            setTaxReportData(cmdResult.response.update_tax_report);
        }
      } catch (error) {
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000
        });
      } finally {
        setLoading(false);
      }
    };
  };

  const showCommitmentLetter = async () => {
    setLoading(true);
    const res = await getCommitmentLetter.fetch();
    const base64Response = res.data.content;
    const pdfData = Uint8Array.from(atob(base64Response), (c) =>
      c.charCodeAt(0)
    );
    const blob = new Blob([pdfData], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url);
    setLoading(false);
  };

  const deleteCommitmentLetter = async () => {
    setLoading(true);

    const cmdResult = await issueCommand(
      "modify",
      "tax-reports",
      {
        uuid: taxReportData.document.uuid,
        version: taxReportData.document.version,
        tenant_id: taxReportData.document.tenant_id,
        taxpayer_id: taxReportData.taxpayer_id,
        modify: {
          modifyData: {
            commitmentLetter: {
              file: null
            },
            states: {
              ...taxReportData.document.states,
              commitmentLetter: "templateDownloaded"
            }
          }
        }
      },
      {
        headers: {
          "taxpayer-id": taxReportData.taxpayer_id
        },
        shouldComplete: true
      }
    );

    if (cmdResult.response)
      setTaxReportData(cmdResult.response.update_tax_report);

    setLoading(false);
  };

  const changeCommitmentDate = async (newValue) => {
    setLoading(true);

    const cmdResult = await issueCommand(
      "modify",
      "tax-reports",
      {
        uuid: taxReportData.document.uuid,
        version: taxReportData.document.version,
        tenant_id: taxReportData.document.tenant_id,
        taxpayer_id: taxReportData.taxpayer_id,
        modify: {
          modifyData: {
            commitmentLetter: {
              ...taxReportData.document?.commitmentLetter,
              commitmentDate: newValue.toISOString()
            }
          }
        }
      },
      {
        headers: {
          "taxpayer-id": taxReportData.taxpayer_id
        },
        shouldComplete: true
      }
    );

    if (cmdResult.response)
      setTaxReportData(cmdResult.response.update_tax_report);

    setLoading(false);
  };

  useEffect(() => {
    if (steps.validation.status === "todo")
      setTelematicoPossibleActions(defaultTelematicoActionPanelButtons);

    if (steps.validation.status === "done")
      setTelematicoPossibleActions(sendingTelematicoAtionPanelButtons);
  }, [steps, taxReportData]);

  return (
    <Accordion
      defaultExpanded={true}
      sx={{
        width: "100%",
        padding: "32px 0px 0px 24px",
        boxShadow: "none"
      }}
    >
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faAngleDown} />}>
        <Typography variant="titleSmall">{t("actions")}</Typography>
        <Typography
          sx={{
            textAlign: "right",
            color: theme.palette.primary.textSubduedColor
          }}
          variant="body"
        >
          {t("numberOfCompletedActions", {
            completedActions: numberOfActionsCompleted,
            maxNumberOfCompletedActions: MAX_NUMBER_OF_ACTIONS
          })}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack sx={{ padding: "0px 48px 32px 48px", gap: "36px" }}>
          <TaxReportPageCard
            title={t("commitmentLetter")}
            actionButtons={
              taxReportData?.document?.states?.commitmentLetter !==
              "commitmentLetterUploaded"
                ? [
                    <Button
                      startIcon={<FontAwesomeIcon icon={faDownload} />}
                      variant="outlined"
                      key="downloadCommitmentLetterTemplate"
                      onClick={downloadCommitmentLetterTemplate}
                      disabled={isIssueCommandLoading}
                    >
                      {t("downloadCommitmentLetterTemplate")}
                    </Button>,
                    <input
                      type="file"
                      ref={fileInput}
                      key="commitmentLetterFileInput"
                      style={{ display: "none" }}
                      accept=".pdf"
                      onChangeCapture={handleFileChange}
                    />,
                    <Button
                      startIcon={<FontAwesomeIcon icon={faSignature} />}
                      variant="contained"
                      key="uploadSignature"
                      disabled={isIssueCommandLoading}
                      onClick={() => {
                        fileInput.current.click();
                      }}
                    >
                      {t("uploadSignature")}
                    </Button>
                  ]
                : []
            }
          >
            <TaxReportPageSignaturePanel
              commitmentLetter={taxReportData?.document?.commitmentLetter}
              status={taxReportData?.document?.states?.commitmentLetter}
              deleteCommitmentLetter={deleteCommitmentLetter}
              showCommitmentLetter={showCommitmentLetter}
              changeCommitmentDate={changeCommitmentDate}
            />
            {loading && (
              <Box
                sx={{
                  width: "100%"
                }}
              >
                <LoadingSpinner />
              </Box>
            )}
          </TaxReportPageCard>
          <TaxReportPageCard
            title="Telematico"
            actionButtons={telematicoPossibleActions.map((item, index) => {
              return (
                <Button
                  key={index}
                  startIcon={<FontAwesomeIcon icon={item.icon} />}
                  variant="contained"
                  disabled={item.disableCondition || isIssueCommandLoading}
                  onClick={async () => await item.fun()}
                >
                  {item.label}
                </Button>
              );
            })}
          >
            <TaxReportPageTelematicoCardContent />
            {telematicoLoading && (
              <Box
                sx={{
                  width: "100%"
                }}
              >
                <LoadingSpinner />
              </Box>
            )}
          </TaxReportPageCard>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

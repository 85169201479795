import { useEffect, useState } from "react";

import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import Button from "@vapor/react-material/Button";
import Checkbox from "@vapor/react-material/Checkbox";
import Divider from "@vapor/react-material/Divider";
import TextField from "@vapor/react-material/TextField";
import Typography from "@vapor/react-extended/ExtendedTypography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useTranslation } from "@onefront/react-sdk";

import { useTaxReportData } from "../../providers/taxReportDataProvider";
import { useCqrs } from "../../../../services/cqrs";

const EditableTodo = ({ todo, index, modifyTodoChecked, modifyTodoLabel }) => {
  const [checked, setChecked] = useState(todo.checked);
  const [label, setLabel] = useState(todo.label);

  return (
    <Stack direction="row" sx={{ marginLeft: "-11px", marginRight: "16px" }}>
      <Checkbox
        sx={{ padding: "0px 8px 0px 9px" }}
        checked={checked}
        onChange={async (e) => {
          setChecked(!checked);
          modifyTodoChecked(todo, index);
        }}
      />
      <TextField
        type="string"
        variant="standard"
        value={label}
        // We need to change the value correctly everytime we type, but we only have to send the final label
        // when we lose the focus of the input
        onChange={(e) => {
          setLabel(e.target.value);
        }}
        onBlur={async (e) => {
          if (label !== todo.label) modifyTodoLabel(label, index);
        }}
      ></TextField>
    </Stack>
  );
};

export const TaxReportTodoList = ({ style }) => {
  const { t } = useTranslation("foa");
  const { issueCommand } = useCqrs();
  const { taxReportData, setTaxReportData } = useTaxReportData();
  const [suggestedActivity, setSuggestedActivity] = useState();

  const sendMofifyTodo = async (newTodoList) => {
    const res = await issueCommand(
      "modify",
      "tax-reports",
      {
        uuid: taxReportData.document.uuid,
        version: taxReportData.document.version,
        tenant_id: taxReportData.document.tenant_id,
        taxpayer_id: taxReportData.taxpayer_id,
        modify: {
          modifyData: {
            data: {
              todo: {
                list: newTodoList
              }
            }
          }
        }
      },
      {
        headers: {
          "taxpayer-id": taxReportData.taxpayer_id
        },
        shouldComplete: false
      }
    );

    const newTaxReportData = { ...taxReportData };
    newTaxReportData.document.data.todo.list = newTodoList;
    newTaxReportData.document.version = res.cmd_id;
    setTaxReportData(newTaxReportData);
  };

  const modifyTodoChecked = async (todo, index) => {
    const newTodoList = [...taxReportData.document.data.todo.list];
    newTodoList[index].checked = !todo.checked;

    sendMofifyTodo(newTodoList);
  };

  const modifyTodoLabel = async (label, index) => {
    const newTodoList = [...taxReportData.document.data.todo.list];
    newTodoList[index].label = label;

    sendMofifyTodo(newTodoList);
  };

  const addTodo = async () => {
    const newTodoList = [...taxReportData.document.data.todo.list];
    newTodoList.push({
      label: "New todo",
      checked: false,
      editable: true
    });

    sendMofifyTodo(newTodoList);
  };

  //This method will be removed when we will
  // understand how to format the date and what to use to do that
  const formatDate = (dateString) =>
    dateString ? new Date(dateString).toLocaleString() : undefined;

  useEffect(() => {
    const firstUncheckedTodo =
      taxReportData?.document?.data?.todo?.list?.find(
        (todo) => todo.checked === false
      ) ?? {};
    setSuggestedActivity(firstUncheckedTodo.label);
  }, [taxReportData]);

  return (
    <Box
      sx={{
        padding: "0px 24px 64px 24px",
        borderLeft: "1px solid var(--neutral-20, #B9C8D0)",
        overflow: "auto",
        ...style
      }}
    >
      <Stack alignItems="flex-start" gap="16px" alignSelf="stretch">
        <Typography variant="titleSmall" sx={{ color: "#000" }}>
          {t("delivery")}
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "#000" }}>
          {t("lastModificationDate")}:
          {formatDate(
            taxReportData?.document?.data?.todo?.lastModificationDate
          )}
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "#000" }}>
          {t("deliveryAt")}:
          {formatDate(taxReportData?.document?.data?.todo?.deliveryAt)}
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "#000" }}>
          {t("assignedTo")}: {taxReportData?.document?.data?.todo?.assignedTo}
        </Typography>
        <Divider light />
        <Typography variant="titleSmall" sx={{ color: "#000" }}>
          {t("suggestedActivity")}
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "#000" }}>
          {suggestedActivity}
        </Typography>
        <Typography variant="titleSmall" sx={{ color: "#000" }}>
          {t("todo")}
        </Typography>
        <FormGroup
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "8px",
            alignSelf: "stretch"
          }}
        >
          {taxReportData?.document?.data?.todo?.list?.map((todo, index) => {
            if (todo.editable)
              return (
                <EditableTodo
                  key={index}
                  todo={todo}
                  index={index}
                  modifyTodoChecked={modifyTodoChecked}
                  modifyTodoLabel={modifyTodoLabel}
                />
              );
            return (
              <FormControlLabel
                key={index}
                control={<Checkbox sx={{ padding: "0px 8px 0px 9px" }} />}
                label={todo.label}
                checked={todo.checked}
                onChange={async () => {
                  modifyTodoChecked(todo, index);
                }}
              />
            );
          })}
        </FormGroup>
        <Button variant="text" size="small" onClick={addTodo}>
          + {t("add")}
        </Button>
      </Stack>
    </Box>
  );
};

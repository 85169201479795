import { formatAmount } from "../../../../../../utils";

import { constants } from "@fiscal-orchestrator/fior-utils";

export const purchasesData = (months, checksData, vatPeriodicity, t) => {
  if (vatPeriodicity === "NORMAL_MONTHLY")
    return months.map((month) => {
      const data = checksData?.find((item) => item.month === month);

      return {
        month: t(constants.taxReportPeriods.monthsNameMonthly[month]),
        items: [
          {
            text: t("vatPurchases"),
            amount: formatAmount(data?.vatPurchases, 2, 2) || " 0,00"
          },
          {
            text: t("vatPurchasesReverseCharge"),
            amount:
              formatAmount(data?.vatPurchasesReverseCharge, 2, 2) || " 0,00"
          },
          {
            text: t("nonDeductibleVat"),
            amount: formatAmount(data?.nonDeductibleVat, 2, 2) || "0,00"
          },
          {
            text: t("nonDeductibleVatProrata"),
            amount: formatAmount(data?.nonDeductibleVatProrata, 2, 2) || " 0,00"
          }
        ]
      };
    });
  return [
    {
      month: "",
      items: [
        {
          text: t("vatPurchases"),
          amount: formatAmount(checksData?.[0]?.vatPurchases, 2, 2) || " 0,00"
        },
        {
          text: t("vatPurchasesReverseCharge"),
          amount:
            formatAmount(checksData?.[0]?.vatPurchasesReverseCharge, 2, 2) ||
            " 0,00"
        },
        {
          text: t("nonDeductibleVat"),
          amount:
            formatAmount(checksData?.[0]?.nonDeductibleVat, 2, 2) || " 0,00"
        },
        {
          text: t("nonDeductibleVatProrata"),
          amount:
            formatAmount(checksData?.[0]?.nonDeductibleVatProrata, 2, 2) ||
            " 0,00"
        }
      ]
    }
  ];
};

export const settlementResultData = (months, checksData, vatPeriodicity, t) => {
  if (vatPeriodicity === "NORMAL_MONTHLY")
    return months.map((month) => {
      const data = checksData?.find((item) => item.month === month);
      return {
        month: t(constants.taxReportPeriods.monthsNameMonthly[month]),
        items: [
          {
            text: t("creditTax"),
            amount: formatAmount(data?.creditTax, 2, 2) || " 0,00"
          },
          {
            text: t("debitTax"),
            amount: formatAmount(data?.debitTax, 2, 2) || " 0,00"
          },
          {
            text: t("interest1OnQuarterlyDebitVat"),
            amount:
              formatAmount(data?.interest1OnQuarterlyDebitVat, 2, 2) || " 0,00"
          }
        ]
      };
    });
  return [
    {
      items: [
        {
          text: t("creditTax"),
          amount: formatAmount(checksData?.[0]?.creditTax, 2, 2) || " 0,00"
        },
        {
          text: t("debitTax"),
          amount: formatAmount(checksData?.[0]?.debitTax, 2, 2) || " 0,00"
        },
        {
          text: t("interest1OnQuarterlyDebitVat"),
          amount:
            formatAmount(checksData?.[0]?.interest1OnQuarterlyDebitVat, 2, 2) ||
            " 0,00"
        }
      ]
    }
  ];
};

export const taxSummaryDataMonthly = (checksData, t) => {
  return [
    {
      items: [
        {
          text: t("previousPeriodVatCredit"),
          amount:
            formatAmount(checksData?.[0]?.previousPeriodVatCredit, 2, 2) ||
            " 0,00"
        },
        {
          text: t("previousPeriodVatDebit"),
          amount:
            formatAmount(checksData?.[0]?.previousPeriodVatDebit, 2, 2) ||
            " 0,00"
        },
        {
          text: t("tributeCode"),
          amount: formatAmount(checksData?.[0]?.tributeCode, 2, 2) || " 0,00"
        },
        {
          text: t("interest1OnDebitAmount"),
          amount:
            formatAmount(checksData?.[0]?.interest1OnDebitAmount, 2, 2) ||
            " 0,00"
        },
        {
          text: t("debitoDaRiportare"),
          amount:
            formatAmount(checksData?.[0]?.debitoDaRiportare, 2, 2) || " 0,00"
        },
        {
          text: t("creditTax"),
          amount: formatAmount(checksData?.[0]?.creditTax, 2, 2) || " 0,00"
        }
      ]
    }
  ];
};

export const taxSummaryDataQuarterly = (checksData, t) => {
  return [
    {
      items: [
        {
          text: t("previousPeriodVatCredit"),
          amount:
            formatAmount(checksData?.[0]?.previousPeriodVatCredit, 2, 2) ||
            " 0,00"
        },
        {
          text: t("previousPeriodVatDebit"),
          amount:
            formatAmount(checksData?.[0]?.previousPeriodVatDebit, 2, 2) ||
            " 0,00"
        },
        {
          text: t("tributeCode"),
          amount: formatAmount(checksData?.[0]?.tributeCode, 2, 2) || " 0,00"
        },
        {
          text: t("debitoDaRiportare"),
          amount:
            formatAmount(checksData?.[0]?.debitoDaRiportare, 2, 2) || "0,00"
        },
        {
          text: t("creditTax"),
          amount: formatAmount(checksData?.[0]?.creditTax, 2, 2) || " 0,00"
        }
      ]
    }
  ];
};

const itemsActiveTransactions = (data, t) => {
  return [
    {
      text: t("vatOnSales"),
      amount: formatAmount(data?.vatOnSales, 2, 2) || "0,00"
    },
    {
      text: t("vatOnSplitPayment"),
      amount: formatAmount(data?.vatOnSplitPayment, 2, 2) || "0,00"
    },
    {
      text: t("vatOnConsideration"),
      amount: formatAmount(data?.vatOnConsideration, 2, 2) || "0,00"
    },
    {
      text: t("vatSelfInvoiceReverseCharge"),
      amount: formatAmount(data?.vatSelfInvoiceReverseCharge, 2, 2) || "0,00"
    }
  ];
};

export const activeTransactionsData = (
  months,
  checksData,
  vatPeriodicity,
  t
) => {
  if (vatPeriodicity === "NORMAL_MONTHLY")
    return months.map((month) => {
      const data = checksData?.find((item) => item.month === month);
      return {
        month: t(constants.taxReportPeriods.monthsNameMonthly[month]),
        items: itemsActiveTransactions(data, t)
      };
    });

  return [
    {
      items: itemsActiveTransactions(checksData?.[0], t)
    }
  ];
};

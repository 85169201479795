import { useState, createContext, useContext } from "react";

import { CommonDataGeneral } from "../components/tabs-settings/common-data/CommonDataGeneral";
import { CommonDataDeclarant } from "../components/tabs-settings/common-data/CommonDataDeclarant";
import { TenantIVAModuleManagement } from "../components/tabs-settings/tenant-IVA/TenantIVAModuleManagement";
import { TenantIVAAdditionalData } from "../components/tabs-settings/tenant-IVA/TenantIVAAdditionalData";
import { TenantIVATelematicData } from "../components/tabs-settings/tenant-IVA/TenantIVATelematicData";
import { TenantPFFiscalData } from "../components/tabs-settings/tenant-PF/TenantPFFiscalData";
import { TenantPFAdditionalData } from "../components/tabs-settings/tenant-PF/TenantPFAdditionalData";
import { TenantPFGeneralSettings } from "../components/tabs-settings/tenant-PF/TenantPFGeneralSettings";
import { TenantPFFirstPositionArtisansAndTraders } from "../components/tabs-settings/tenant-PF/TenantPFFirstPositionArtisansAndTraders";
import { TenantPFSecondPositionArtisansAndTraders } from "../components/tabs-settings/tenant-PF/TenantPFSecondPositionArtisansAndTraders";
import { TenantPfSelfEmployment } from "../components/tabs-settings/tenant-PF/TenantPfSelfEmployment";
import { TenantSCGeneral } from "../components/tabs-settings/tenant-SC/TenantSCGeneral";
import { TenantSCSpecialRegimes } from "../components/tabs-settings/tenant-SC/TenantSCSpecialRegimes";
import { TenantSCPaymentDetails } from "../components/tabs-settings/tenant-SC/TenantSCPaymentDetails";
import { TenantSP } from "../components/tabs-settings/TenantSP";

const COMMON_DATA_GENERAL_SETTINGS = {
  translationKey: "generalSettings",
  key: "commonDataGeneralSettings",
  parentKey: "commonData",
  component: CommonDataGeneral
};

const COMMON_DATA_MENU = {
  translationKey: "commonData",
  key: "commonData",
  children: [
    COMMON_DATA_GENERAL_SETTINGS,
    {
      translationKey: "additionalPersonalData",
      key: "commonDataAdditionalPersonalData",
      parentKey: "commonData",
      component: CommonDataDeclarant
    }
  ]
};

const COMMON_DATA_MENU_REDUCED = {
  translationKey: "commonData",
  key: "commonData",
  children: [COMMON_DATA_GENERAL_SETTINGS]
};

const IVA_MENU = {
  translationKey: "vatTenant",
  key: "vatTenant",
  children: [
    {
      translationKey: "modulesManagement",
      key: "ivaModulesManagement",
      parentKey: "vatTenant",
      component: TenantIVAModuleManagement
    },
    {
      translationKey: "additionalPersonalData",
      key: "ivaAdditionalPersonalData",
      parentKey: "vatTenant",
      component: TenantIVAAdditionalData
    },
    {
      translationKey: "supervisoryBodySubscription/telematicData",
      key: "ivaTelematicData",
      parentKey: "vatTenant",
      component: TenantIVATelematicData
    }
  ]
};
const PF_MENU = {
  translationKey: "individuals",
  key: "individuals",
  children: [
    {
      translationKey: "configurationsSettings",
      key: "individualsConfigurationsSettings",
      parentKey: "individuals",
      children: [
        {
          translationKey: "fiscalMasterData",
          key: "individualsFiscalMasterData",
          component: TenantPFFiscalData
        },
        {
          translationKey: "generalSettings",
          key: "individualsGeneralSettings",
          component: TenantPFGeneralSettings
        },
        {
          translationKey: "additionalPersonalData",
          key: "individualsAdditionalPersonalData",
          component: TenantPFAdditionalData
        }
      ]
    },
    {
      translationKey: "socialSecurityData",
      key: "individualsSocialSecurityData",
      parentKey: "individuals",
      children: [
        {
          translationKey: "firstPositionArtisiansTraders",
          key: "individualsFirstPositionArtisiansTraders",
          component: TenantPFFirstPositionArtisansAndTraders
        },
        {
          translationKey: "secondPositionArtisiansTraders",
          key: "individualsSecondPositionArtisiansTraders",
          component: TenantPFSecondPositionArtisansAndTraders
        },
        {
          translationKey: "selfemployment",
          key: "individualsSelfEmployment",
          component: TenantPfSelfEmployment
        }
      ]
    }
  ]
};
const SC_MENU = {
  translationKey: "corporations",
  key: "corporations",
  children: [
    {
      translationKey: "generalSettings",
      key: "scGeneralSettings",
      parentKey: "corporations",
      component: TenantSCGeneral
    },
    {
      translationKey: "specialRegimes",
      key: "scSpecialRegimes",
      parentKey: "corporations",
      component: TenantSCSpecialRegimes
    },
    {
      translationKey: "paymentData",
      key: "scPaymentData",
      parentKey: "corporations",
      component: TenantSCPaymentDetails
    }
  ]
};
const SP_MENU = {
  translationKey: "partnerships",
  key: "partnerships",
  component: TenantSP
};

const menuItemsByTaxPayerType = {
  PF: [COMMON_DATA_MENU_REDUCED, PF_MENU, IVA_MENU],
  SC: [COMMON_DATA_MENU, SC_MENU, IVA_MENU],
  SP: [COMMON_DATA_MENU, SP_MENU, IVA_MENU],
  IVA: [COMMON_DATA_MENU, IVA_MENU]
};

const MenuContext = createContext();

export const MenuWrapper = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <MenuContext.Provider
      value={{ selectedItem, setSelectedItem, menuItemsByTaxPayerType }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useSidebarMenu = () => useContext(MenuContext);

import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "@onefront/react-sdk";
import PropTypes from "prop-types";

import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import Accordion from "@vapor/react-material/Accordion";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { constants } from "@fiscal-orchestrator/fior-utils";

import { DialogInfoTaxReport } from "../../DialogInfoTaxReport";
import { TaxPayerCell } from "../../../../tax-reports-list/components/tax-reports-list-table/TaxPayerCell";
import { orderDateHour } from "../../../../../utils";
import { useTaxReportData } from "../../../providers/taxReportDataProvider";
import { AnnualDataDialog } from "../../../helpers/tax-report-dialog-data";
import TaxReportPageLipe from "../../Checks/TaxReportPageLipe";
import { TaxReportPageAccordionMenu } from "../../Checks/TaxReportPageAccordionMenu";
import { TaxReportPageResult } from "../../Result/TaxReportPageResult";
import { TaxReportPageActions } from "../../Result/TaxReportPageActions";
import { TaxReportResult } from "../../Result/TaxReportResult";
import {
  purchasesData,
  settlementResultData,
  taxSummaryDataMonthly,
  taxSummaryDataQuarterly,
  activeTransactionsData
} from "../LIPE/helpers";
import { TaxReportPageLipeSummary } from "../../Result/TaxReportPageLipeSummary";
import { useWorkspaceData } from "../../../../../services/workspace/workspaceProvider";
import { formatFloatAmount, formatDate } from "../../../../../utils";
import { FiscaliSaasIframeWrapper } from "../../FiscaliSaasIframeWrapper";
import { ReportData } from "../../ReportData/ReportData";
import { TaxReportPageChecksStack } from "../../Checks/TaxReportPageChecksStack";
import { ReportPageDataWrapper } from "../../../providers/reportPageDataProvider";

export const LIPE_V1 = ({ tabs }) => {
  const { t } = useTranslation("foa");
  const {
    taxReportData,
    setTaxReportVisibleContent,
    possibleContents,
    setPossibleContents,
    taxReportVisibleContent
  } = useTaxReportData();
  const { workspaceUrl } = useWorkspaceData();
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const activeTransactionsLipeRef = useRef(null);
  const [checksSections, setChecksSections] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [modelSummary, setModelSummary] = useState([]);
  const [dateTaxReport, setDateTaxReport] = useState();
  const purchasesLipeRef = useRef(null);
  const SettlementResultLipeRef = useRef(null);
  const taxSummaryLipeRef = useRef(null);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChange = () => (event, isExpanded) => {
    setExpanded(!isExpanded);
  };

  useEffect(() => {
    setPossibleContents(tabs?.contents);
    setTaxReportVisibleContent(tabs?.firstContent);
  }, []);

  useEffect(() => {
    setDateTaxReport(orderDateHour(taxReportData?.updated_at));
    const companyData = taxReportData?.document?.input?.workspaces?.companyData;

    const period =
      constants.taxReportPeriods.quarters[taxReportData?.document?.period];

    let months = [];
    months =
      constants.taxReportPeriods.periodToMonths[
        taxReportData?.document?.period
      ];

    const checksData = taxReportData?.document?.data?.fiscaliSaas?.checks;
    const periodCode = constants.taxReportPeriods.periodCodes[period] || "";

    const modDatiContabili =
      taxReportData?.document?.data?.fiscaliSaas?.dataOut;

    const vatPeriodicity = companyData?.business?.find(
      (item) => item.type === "MAIN"
    )?.vatConfiguration?.vatPeriodicity;

    let newChecksSections = [];
    if (companyData?.business?.length === 1) {
      newChecksSections = [
        {
          text: t("activeTransactions"),
          ref: activeTransactionsLipeRef,
          component: TaxReportPageLipe,
          props: {
            data: activeTransactionsData(months, checksData, vatPeriodicity, t),
            title: t("activeTransactions"),
            subtitle: t("lastChangeDateTemplate", {
              date: formatDate(taxReportData?.updated_at),
              interpolation: { escapeValue: false }
            })
          }
        },
        {
          text: t("purchases"),
          ref: purchasesLipeRef,
          component: TaxReportPageLipe,
          props: {
            data: purchasesData(months, checksData, vatPeriodicity, t),
            title: t("purchases"),
            subtitle: t("lastChangeDateTemplate", {
              date: formatDate(taxReportData?.updated_at),
              interpolation: { escapeValue: false }
            })
          }
        },
        {
          text: t("settlementResult"),
          ref: SettlementResultLipeRef,
          component: TaxReportPageLipe,
          props: {
            data: settlementResultData(months, checksData, vatPeriodicity, t),
            title: t("settlementResult"),
            subtitle: t("lastChangeDateTemplate", {
              date: formatDate(taxReportData?.updated_at),
              interpolation: { escapeValue: false }
            })
          }
        }
      ];
    } else if (vatPeriodicity === "NORMAL_MONTHLY") {
      newChecksSections = [
        {
          text: t("taxSummary"),
          ref: taxSummaryLipeRef,
          component: TaxReportPageLipe,
          props: {
            data: taxSummaryDataMonthly(checksData, t),
            title: t("taxSummary"),
            subtitle: t("lastChangeDateTemplate", {
              date: formatDate(taxReportData?.updated_at),
              interpolation: { escapeValue: false }
            })
          }
        }
      ];
    } else {
      newChecksSections = [
        {
          text: t("taxSummary"),
          ref: taxSummaryLipeRef,
          component: TaxReportPageLipe,
          props: {
            data: taxSummaryDataQuarterly(checksData, t),
            title: t("taxSummary"),
            subtitle: t("lastChangeDateTemplate", {
              date: formatDate(taxReportData?.updated_at),
              interpolation: { escapeValue: false }
            })
          }
        }
      ];
    }
    setChecksSections(newChecksSections);

    const resultsAmount = {
      credit: `${periodCode}_VP_ImportoACredito`,
      debit: `${periodCode}_VP_ImportoDaVersare`
    };

    const resultsVAT = {
      taxableVAT: `${periodCode}_VP_IvaEsigibile`,
      deductedVAT: `${periodCode}_VP_IvaDetratta`
    };

    const creditPreviousPeriod = `${periodCode}_VP_CreditoPeriodoPrecedente`;
    const creditPreviousYear = `${periodCode}_VP_CreditoAnnoPrecedente`;
    const taxCredit = `${periodCode}_VP_CreditiImposta`;
    const advance = `${periodCode}_VP_Acconto`;

    const monthCode = `${periodCode}_VP_Mese`;

    const results = [];
    Array.from({ length: 3 }, (_, i) => i).forEach((i) => {
      if (modDatiContabili?.[`Dati contabili ${period} Trimestre&${i}`]) {
        results[i] =
          modDatiContabili?.[`Dati contabili ${period} Trimestre&${i}`];
      }
    });

    const amountCodes = results.map((result) =>
      result?.[resultsAmount.credit]
        ? resultsAmount.credit
        : resultsAmount.debit
    );

    let i =
      vatPeriodicity === constants.taxReportPeriods.vatPeriodicities.quarterly
        ? 1
        : 3;
    const newResultData = [];

    Array.from({ length: i }).forEach((_, index) => {
      let title = "";
      if (
        vatPeriodicity === constants.taxReportPeriods.vatPeriodicities.quarterly
      ) {
        title =
          amountCodes[index] === resultsAmount.credit
            ? t("inputVAT") + " " + t("lineVP14field2")
            : t("VATpayable") + " " + t("lineVP14field1");
      } else {
        title =
          amountCodes[index] === resultsAmount.credit
            ? t("inputVAT") +
              " " +
              t(constants.taxReportPeriods.monthsNameMonthly[months[index]])
            : t("VATpayable") +
              " " +
              t(constants.taxReportPeriods.monthsNameMonthly[months[index]]);
      }
      const value = results[index]?.[amountCodes[index]]
        ? formatFloatAmount(results[index]?.[amountCodes[index]], 2, 2)
        : "0,00";

      newResultData.push({ title, value });
    });

    setResultData(newResultData);

    const newModelSummary = [];
    let month = "";
    Array.from({ length: i }).forEach((_, index) => {
      if (
        vatPeriodicity === constants.taxReportPeriods.vatPeriodicities.quarterly
      ) {
        month = t(
          constants.taxReportPeriods.monthsNameQuaterly[
            constants.taxReportPeriods.quartersToInteger[
              taxReportData?.document?.period
            ]
          ]
        );
      } else {
        month = t(constants.taxReportPeriods.monthsNameMonthly[months[index]]);
      }

      let resultVAT = 0;
      let vatDifference = 0;
      let taxableVAT = true;
      let ind = results.findIndex(
        (result, i) => months[i] === result?.[monthCode]
      );
      if (
        vatPeriodicity ===
          constants.taxReportPeriods.vatPeriodicities.monthly &&
        index !== -1
      ) {
        i = ind;
      }

      if (results.length !== 0) {
        let taxable = results[index]?.[resultsVAT.taxableVAT]
          ? results[index]?.[resultsVAT.taxableVAT]
          : 0;
        let deducted = results[index]?.[resultsVAT.deductedVAT]
          ? results[index]?.[resultsVAT.deductedVAT]
          : 0;
        vatDifference = taxable - deducted;
        taxableVAT = vatDifference >= 0;
        resultVAT = taxableVAT ? vatDifference : Math.abs(vatDifference);
      }
      const codeTax =
        vatPeriodicity === constants.taxReportPeriods.vatPeriodicities.quarterly
          ? t("firstQuarterVAT") +
            " " +
            t("taxCode60") +
            3 +
            constants.taxReportPeriods.quartersToInteger[
              taxReportData?.document?.period
            ]
          : t("VATpayable") +
            " " +
            t("taxCode60") +
            months[index].toString().padStart(2, "0");
      const items = [
        {
          text: !taxableVAT
            ? t("deductedVAT") + " " + t("lineVP6Field2")
            : t("taxableVAT") + " " + t("lineVP6Field1"),
          amount: resultVAT !== 0 ? formatFloatAmount(resultVAT, 2, 2) : "0,00"
        },
        {
          text: t("creditPreviousPeriodLineVP8"),
          amount: results[index]?.[creditPreviousPeriod]
            ? formatFloatAmount(results[index]?.[creditPreviousPeriod], 2, 2)
            : "0,00"
        },
        {
          text: t("creditPreviousYearLineVP9"),
          amount: results[index]?.[creditPreviousYear]
            ? formatFloatAmount(results[index]?.[creditPreviousYear], 2, 2)
            : "0,00"
        },
        {
          text: t("taxCreditLineVP11"),
          amount: results[index]?.[taxCredit]
            ? formatFloatAmount(results[index]?.[taxCredit], 2, 2)
            : "0,00"
        },
        {
          text:
            amountCodes[index] === resultsAmount.credit
              ? t("inputVAT") + " " + t("lineVP14field2")
              : codeTax,
          amount: results[index]?.[amountCodes[index]]
            ? formatFloatAmount(results[index]?.[amountCodes[index]], 2, 2)
            : "0,00"
        }
      ];
      newModelSummary.push({ months: month, items });
      if (
        (periodCode === constants.taxReportPeriods.periods.Q4 &&
          vatPeriodicity ===
            constants.taxReportPeriods.vatPeriodicities.quarterly) ||
        (periodCode === constants.taxReportPeriods.periods.Q4 &&
          vatPeriodicity ===
            constants.taxReportPeriods.vatPeriodicities.monthly &&
          months[index] === 12)
      ) {
        newModelSummary[index].items.push({
          text: t("advance"),
          amount: results[index]?.[advance]
            ? formatFloatAmount(results[index]?.[advance], 2, 2)
            : "0,00"
        });
      }
    });
    setModelSummary(newModelSummary);
    setOpenDialog(taxReportData?.document?.states?.wfsState === "Todo");
  }, [taxReportData]);

  DialogInfoTaxReport.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };

  return (
    <FiscaliSaasIframeWrapper>
      {
        //Checks
        taxReportVisibleContent === possibleContents.checks && (
          <Box sx={{ padding: "20px", height: "100%", overflow: "hidden" }}>
            <Stack direction="row">
              <TaxReportPageAccordionMenu links={checksSections} />
              <TaxReportPageChecksStack checksSections={checksSections} />
            </Stack>
          </Box>
        )
      }
      {
        // Report data
        taxReportVisibleContent === possibleContents.reportData && (
          <ReportPageDataWrapper>
            <ReportData
              reportData={taxReportData?.document?.data?.reportData}
            />
          </ReportPageDataWrapper>
        )
      }
      {
        //Result
        taxReportVisibleContent === possibleContents.result && (
          <Box sx={{ padding: "20px", height: "100%", overflow: "hidden" }}>
            <TaxReportPageResult>
              <TaxReportResult resultData={resultData} />
              <TaxReportPageLipeSummary data={modelSummary} />
              <TaxReportPageActions />
              <Accordion
                sx={{
                  marginBlockStart: "15px",
                  width: "100%",
                  padding: "32px 0px 0px 24px",
                  boxShadow: "none"
                }}
                defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
                  aria-controls="panel1a-content"
                >
                  <Typography variant="titleSmall">
                    {t("relatedTaxreport")}
                  </Typography>
                </AccordionSummary>
              </Accordion>
            </TaxReportPageResult>
          </Box>
        )
      }
      {isOpenDialog && (
        <DialogInfoTaxReport
          data={taxReportData}
          isOpen={isOpenDialog}
          open={isOpenDialog}
          onClose={handleClose}
        >
          <Accordion expanded={expanded} onChange={handleChange()}>
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              <Typography variant="titleSmall">
                {t("dialogTaxReportData1")}
              </Typography>
              <Typography variant="body">
                {t("dialogTaxReportDate", dateTaxReport).replaceAll(
                  "&#x2F;",
                  "/"
                )}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <TaxPayerCell
                row={{
                  taxpayer_type: taxReportData?.document?.taxpayerType,
                  taxpayer_name:
                    taxReportData?.document?.input?.workspaces?.companyData
                      ?.personalData?.businessName,
                  taxpayer_id: taxReportData?.taxpayer_id
                }}
                modal={true}
                workspaceUrl={workspaceUrl}
              />
              <AnnualDataDialog />
            </AccordionDetails>
          </Accordion>
        </DialogInfoTaxReport>
      )}
    </FiscaliSaasIframeWrapper>
  );
};

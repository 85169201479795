import { createContext, useContext, useEffect, useMemo, useState } from "react";

const WidgetGoToContext = createContext();

export const WidgetGoToWrapper = ({ children }) => {
  const [widgetGoToList, setWidgetGoToList] = useState([]);
  const [loading, setLoading] = useState(false);

  const hookData = useMemo(
    () => ({
      widgetGoToList,
      setWidgetGoToList,
      loading,
      setLoading
    }),
    [widgetGoToList, setWidgetGoToList, loading, setLoading]
  );

  useEffect(() => {
    setLoading(false);
  }, [widgetGoToList]);

  return (
    <WidgetGoToContext.Provider value={hookData}>
      {children}
    </WidgetGoToContext.Provider>
  );
};

export const useWidgetGoToContext = () => useContext(WidgetGoToContext);

import { Dashboard } from "./Dashboard";
import { NavContent } from "./NavContent";

import { KpiListWrapper } from "./providers/kpi-list-wrapper";
import { WidgetGoToWrapper } from "./providers/widget-go-to-wrapper";
import { UserConfigWrapper } from "./providers/user-config-wrapper";
import { KpiDueDatesWrapper } from "./providers/kpi-due-dates-wrapper";

const BASE_PATH = "/";

export const dashboard = () => {
  const items = [
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        path: "/",
        element: <Dashboard />
      }
    },
    {
      target: "$REACT_ROOT_WRAPPER",
      handler: {
        component: KpiDueDatesWrapper,
        props: {
          initialKpiList: []
        }
      }
    },
    {
      target: "$REACT_ROOT_WRAPPER",
      handler: {
        component: KpiListWrapper,
        props: {
          initialKpiDueDates: []
        }
      }
    },
    {
      target: "$REACT_ROOT_WRAPPER",
      handler: {
        component: WidgetGoToWrapper
      }
    },
    {
      target: "$REACT_ROOT_WRAPPER",
      handler: {
        component: UserConfigWrapper
      }
    }
  ];

  if (process.env.NODE_ENV !== "production")
    items.push({
      target: "$ONE_LAYOUT_NAV_CONTENT",
      handler: {
        component: NavContent,
        props: {
          basePath: BASE_PATH
        }
      }
    });

  return items;
};

import { forwardRef } from "react";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack, Alert } from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle /* ,
  faArrowRight */
} from "@fortawesome/pro-solid-svg-icons";
import { constants } from "@fiscal-orchestrator/fior-utils";

import TaxReportPageAccordion from "../TaxReportPageAccordion";

const TaxReportPagevatCreditSettlements = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { title, subtitle, year, months, periodicity, vatCreditSettlements } =
    props;
  const indexToVatKey = {
    0: "firstMonth",
    1: "secondMonth",
    2: "thirdMonth"
  };
  const totalAmountRefunded = Object.values(indexToVatKey).reduce(
    (acc, cur) => acc + (vatCreditSettlements?.[cur] || 0),
    0
  );

  let monthsName = [];
  months?.forEach((month) => {
    monthsName.push(t(constants.taxReportPeriods.monthsNameMonthly[month]));
  });

  return (
    <TaxReportPageAccordion ref={ref} title={title} subtitle={subtitle}>
      {totalAmountRefunded < 2582.28 && (
        <Alert
          sx={{ "&.MuiAlert-root": { padding: "16px" } }}
          icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          severity="warning"
          variant="outlined"
        >
          <Typography
            variant="caption"
            fontSize="16px"
            height="24px"
            sx={{ fontWeight: "bold" }}
          >
            {t("info")}
          </Typography>
          <Typography variant="caption">
            {t("messageVATCreditSettlements")}
          </Typography>
        </Alert>
      )}
      <Typography
        variant="bodyLarge700"
        fontSize="16px"
        height="24px"
        sx={{ fontWeight: "bold" }}
      >
        {year}
      </Typography>
      <Stack sx={{ gap: "16px" }}>
        {periodicity === "NORMAL_QUARTERLY" ? (
          <Stack
            direction="row"
            sx={{ gap: "10px" }}
            justifyContent="space-between"
          >
            <Typography variant="bodyLarge">
              {`${t("balance")} ${monthsName.join(", ")}`}
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="bodyLarge700">
                {/*  TODO: This value should be calculated based on the data from mapping from IVA-TR   */}
                {totalAmountRefunded || t("dataNotAvailable")}
              </Typography>
              {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
            </Stack>
          </Stack>
        ) : (
          monthsName.map((month, index) => (
            <Stack
              key={index}
              direction="row"
              sx={{ gap: "10px" }}
              justifyContent="space-between"
            >
              <Typography variant="bodyLarge">
                {`${t("balance")} ${month}`}
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="bodyLarge700">
                  {/*  TODO: This value should be calculated based on the data from mapping from IVA-TR   */}
                  {vatCreditSettlements?.[indexToVatKey[index]] ||
                    t("dataNotAvailable")}
                </Typography>
                {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
              </Stack>
            </Stack>
          ))
        )}
      </Stack>
    </TaxReportPageAccordion>
  );
});

export default TaxReportPagevatCreditSettlements;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSnackbar, useTranslation } from "@onefront/react-sdk";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faFileLines,
  faArrowRight
} from "@fortawesome/pro-regular-svg-icons";
import { useTheme } from "@vapor/react-material/styles";
import Stack from "@vapor/react-material/Stack";
import Menu from "@vapor/react-material/Menu";
import MenuItem from "@vapor/react-material/MenuItem";
import Tooltip from "@vapor/react-material/Tooltip";

import { useTaxReportData } from "../../../tax-report-page/providers/taxReportDataProvider";
import { useTaxReportsListContext } from "../../providers/tax-reports-list-wrapper";
import { useGetDocument } from "../../../../hooks/useGetDocument";
import { generatePdf } from "../../../../utils";

export const ActionCell = ({ row }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    data: dataPdf,
    fetch: fetchPdf,
    error: errorPdf
  } = useGetDocument({ pdfId: row.pdf_id });
  const { setTaxReportData, setTaxReportAssignee, setTaxReportLastUpdate } =
    useTaxReportData();
  const { createTaxReport } = useTaxReportsListContext();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isPdfDisabled = row.status === "todo" || row.pdf_id === null;
  const colorPdf = isPdfDisabled
    ? theme.palette.grey[400]
    : theme.palette.primary.primary60Color;
  const cursorPdf = isPdfDisabled ? "default" : "pointer";

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (dataPdf?.content) {
      try {
        window.open(generatePdf(dataPdf.content), "_blank");
      } catch (error) {
        console.log({ error });
      }
    }
  }, [dataPdf]);

  useEffect(() => {
    if (errorPdf) {
      enqueueSnackbar(errorPdf, {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  }, [errorPdf]);

  return (
    <Stack direction="row" justifyContent="space-around" sx={{ width: "100%" }}>
      <FontAwesomeIcon
        icon={faEllipsisV}
        color={theme.palette.primary.primary60Color}
        size="xl"
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigate(`/company/${row.taxpayer_id}`)}>
          {t("companySettings")}
        </MenuItem>
      </Menu>
      <Tooltip
        slotProps={{
          tooltip: {
            sx: {
              "&.MuiTooltip-tooltip": {
                maxWidth: "193px"
              }
            }
          }
        }}
        placement="top"
        arrow
        title={isPdfDisabled ? t("documentTooltip") : ""}
      >
        <FontAwesomeIcon
          onClick={() => {
            if (!isPdfDisabled) {
              fetchPdf();
            }
          }}
          icon={faFileLines}
          color={colorPdf}
          size="xl"
          style={{ cursor: cursorPdf }}
        />
      </Tooltip>
      <FontAwesomeIcon
        onClick={async () => {
          if (row.tax_report_id) {
            setTaxReportData(null);
            setTaxReportAssignee(row.assignee);
            setTaxReportLastUpdate(row.last_update);
            navigate(`/tax-report/${row.taxpayer_id}/${row.tax_report_id}`);
          } else {
            const cmdId = await createTaxReport(row);
            if (cmdId) {
              setTaxReportData(null);
              navigate(`/tax-report/${row.taxpayer_id}/${cmdId}`);
            }
          }
        }}
        icon={faArrowRight}
        color={theme.palette.primary.primary60Color}
        size="xl"
        style={{ cursor: "pointer" }}
      />
    </Stack>
  );
};

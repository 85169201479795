import { constants } from "@fiscal-orchestrator/fior-utils";
import { useQuery } from "@onefront/react-sdk";
import { useEffect, useState } from "react";

const GET_TAXPAYER_TAX_REPORTS = `query GetTaxpayerTaxReports($where: tax_reports_public_taxpayers_tax_reports_bool_exp!, $order_by: [tax_reports_public_taxpayers_tax_reports_order_by!], $limit: Int!, $offset: Int!) {
  tax_reports_public_taxpayers_tax_reports(where:$where, order_by: $order_by, limit: $limit, offset: $offset) {
    condition
    id
    last_update
    period
    status
    tenant_id
    tax_code
    tax_report
    tax_report_id
    taxpayer_name
    taxpayer_type
    taxpayer_id
    vat_number
    year
    deadline
    assignee
    modified_by
    pdf_id
  }
  tax_reports_public_taxpayers_tax_reports_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
`;

const generateWhereConditions = ({ year, filtersApplied }) => {
  const where = {
    year: { _eq: year }
  };

  if (!filtersApplied) return where;

  if (!!filtersApplied.searchText && filtersApplied.searchText !== "") {
    where._or = [
      { taxpayer_name: { _ilike: `%${filtersApplied.searchText}%` } },
      { vat_number: { _ilike: `%${filtersApplied.searchText}%` } },
      { tax_code: { _ilike: `%${filtersApplied.searchText}%` } },
      {
        _and: {
          modified_by: { _ilike: `%${filtersApplied.searchText}%` },
          status: { _neq: constants.taxReportStatuses.TO_DO }
        }
      }
    ];
  }

  if (
    !!filtersApplied.condition &&
    Array.isArray(filtersApplied.condition) &&
    filtersApplied.condition.length > 0
  ) {
    where.condition = { _in: filtersApplied.condition };
  }
  if (
    !!filtersApplied.declarationType &&
    Array.isArray(filtersApplied.declarationType) &&
    filtersApplied.declarationType.length > 0
  ) {
    where.tax_report = { _in: filtersApplied.declarationType };
  }
  if (
    !!filtersApplied.state &&
    Array.isArray(filtersApplied.state) &&
    filtersApplied.state.length > 0
  ) {
    where.status = { _in: filtersApplied.state };
  }

  if (!!filtersApplied.modifiedBy) {
    where.modified_by = { _ilike: "%" + filtersApplied.modifiedBy + "%" };
    where.status = { ...where.status, _neq: constants.taxReportStatuses.TO_DO };
  }

  if (!!filtersApplied.status) {
    where.status = { in: filtersApplied.status };
  }
  return where;
};

const generateOrderBy = ({ order, orderBy }) => {
  if (Array.isArray(orderBy)) {
    const orderByGenerated = orderBy.reduce((acc, curr) => {
      return { ...acc, [curr]: order };
    }, {});
    return orderByGenerated;
  }

  return { [orderBy]: order };
};

const generateArgs = ({ year, filtersApplied, order, orderBy, offset }) => ({
  where: generateWhereConditions({ year, filtersApplied }),
  order_by: generateOrderBy({ order, orderBy }),
  limit: 20,
  offset
});

const cleanData = (data) => {
  //if the status is "todo" the last_update should be empty
  if (data?.tax_reports_public_taxpayers_tax_reports) {
    return {
      ...data,
      tax_reports_public_taxpayers_tax_reports:
        data.tax_reports_public_taxpayers_tax_reports.map((item) => {
          return {
            ...item,
            last_update:
              item.status === constants.taxReportStatuses.TO_DO
                ? ""
                : item.last_update,
            modified_by:
              item.status === constants.taxReportStatuses.TO_DO
                ? ""
                : item.modified_by
          };
        })
    };
  }
  return data;
};

export const useGetTaxpayerTaxReports = ({ tenantId }) => {
  const [cleanedData, setCleanedData] = useState(null); //cleaned data is the data that is going to be used in the list component

  const { data, fetch, loading } = useQuery(
    GET_TAXPAYER_TAX_REPORTS,
    {},
    {
      headers: {
        "tenant-id": tenantId
      },
      lazy: true
    }
  );

  const getTaxPayerFetch = ({
    year,
    filtersApplied,
    order,
    orderBy,
    offset
  }) => {
    fetch({
      ...generateArgs({ year, filtersApplied, order, orderBy, offset })
    });
  };

  useEffect(() => {
    if (data) {
      setCleanedData(cleanData(data));
    }
  }, [data]);

  return { cleanedData, fetch: getTaxPayerFetch, loading };
};

import { TaxReportDataWrapper } from "../tax-report-page/providers/taxReportDataProvider";
import TaxReportsList from "./components/TaxReportsList";
import {
  FiscalYearWrapper,
  localStorageKeyInitialYear
} from "./providers/fiscal-year";
import { TaxReportsListWrapper } from "./providers/tax-reports-list-wrapper";

const BASE_PATH = "/tax-reports";

export const taxReportsList = () => {
  const initialFiscalYear =
    localStorage.getItem(localStorageKeyInitialYear) ??
    new Date().getFullYear();

  return [
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        path: BASE_PATH,
        element: <TaxReportsList />
      }
    },
    {
      target: "$REACT_ROOT_WRAPPER",
      handler: {
        component: TaxReportsListWrapper,
        props: {
          initialTaxReportsList: []
        }
      }
    },
    {
      target: "$REACT_ROOT_WRAPPER",
      handler: {
        component: FiscalYearWrapper,
        props: {
          initialFiscalYear
        }
      }
    },
    {
      target: "$REACT_ROOT_WRAPPER",
      handler: {
        component: TaxReportDataWrapper,
        props: {
          initialTaxReportData: []
        }
      }
    }
  ];
};

import { validateCompanySettings, ValidationError } from "@reviso/foa-schemas";

export const simplifyCompanySettings = (companySettings) =>
  Object.entries(companySettings).reduce(
    (acc, [key, value]) => {
      if (!value) return acc;
      if (value.value !== undefined) {
        const newAcc = {
          ...acc,
          values: { ...acc.values, [key]: value.value }
        };
        if (value.validity.expires) {
          newAcc.validities = { ...newAcc.validities, [key]: value.validity };
        }

        return newAcc;
      }
      if (typeof value === "object") {
        if (Array.isArray(value)) {
          const fieldValues = [];
          const fieldValidities = [];
          value.forEach((value) => {
            const arr = simplifyCompanySettings(value);
            fieldValues.push(arr.values);
            fieldValidities.push(arr.validities);
          });

          return {
            ...acc,
            values: {
              ...acc.values,
              [key]: fieldValues
            },
            validities: {
              ...acc.validities,
              [key]: fieldValidities
            }
          };
        }
        const simplifiedSettings = simplifyCompanySettings(value);

        return {
          ...acc,
          values: {
            ...acc.values,
            [key]: simplifiedSettings.values
          },
          validities: {
            ...acc.validities,
            [key]: simplifiedSettings.validities
          }
        };
      }

      return acc;
    },
    { values: {}, validities: {} }
  );

const customCleanFieldValueStrategies = {
  cityCode: (value) => value.code,
  regionCode: (value) => value.value,
  province: (value) => value.value
};

const cleanFieldValue = (name, value) =>
  customCleanFieldValueStrategies[name]?.(value) ?? value;

export const formatToSaveCompanySettings = (
  companySettingsInitial,
  companySettingsModified,
  taxpayerType
) => {
  // Maybe we need to change this en the future depending on the incoming payload
  const finalSettings = Object.entries(companySettingsModified).reduce(
    (acc, [key, value]) => {
      if (!value) return acc;
      if (Array.isArray(value)) {
        const fieldValues = [];

        value.forEach((elem, index) => {
          const arr = formatToSaveCompanySettings(
            companySettingsInitial?.[key]?.[index],
            elem
          );

          fieldValues.push(arr);
        });

        return {
          ...acc,
          [key]: fieldValues
        };
      }

      const cleanedValue = cleanFieldValue(key, value);

      if (typeof cleanedValue === "object") {
        return {
          ...acc,
          [key]: formatToSaveCompanySettings(
            companySettingsInitial?.[key],
            value
          )
        };
      }

      const prevInitialProperty = companySettingsInitial?.[key] ?? {};
      const validity = { ...prevInitialProperty.validity };
      if (prevInitialProperty.value !== cleanedValue) {
        validity.expires = true;
        //TODO: Determine in a later task
        // validity.startDate = "...",
        // validity.endDate = "..."
      }
      return { ...acc, [key]: { value: cleanedValue, validity } };
    },
    {}
  );

  const result = validateCompanySettings(finalSettings, taxpayerType);
  if (result.errors) throw new ValidationError(result.errors);

  return finalSettings;
};

export const preparePayloadCompanyData = ({
  sub,
  tenantId,
  taxpayer_type,
  companySettings,
  uuid,
  version
}) => {
  const payload = {
    userData: { sub },
    tenant_id: tenantId,
    document: companySettings,
    taxpayer_type: taxpayer_type
  };

  if (uuid) payload.uuid = uuid;
  if (version) payload.version = version;

  return payload;
};

export const getDefaultFinalField = (type) => {
  const types = {
    number: 0,
    boolean: false
  };

  const value = types[type] ?? "";

  return { value, validity: { expires: true } };
};
// TODO: Check which ones doesn't expire
export const generateDefaultCompanySettings = () => ({
  extortionUsuryVictims: getDefaultFinalField("boolean"),
  landBuildingsDbManagement: getDefaultFinalField("boolean"),
  startYearFullExentLosses: getDefaultFinalField("number"),
  sicilyProductionPlants: getDefaultFinalField("boolean"),
  serviceStartDate: getDefaultFinalField("string"),
  serviceEndDate: getDefaultFinalField("string"),
  imuChargeCode: getDefaultFinalField("string"),
  imuTelematicDelivery: getDefaultFinalField("boolean"),
  complianceVisa: getDefaultFinalField("string"),
  declarantSameAsRepresentative: getDefaultFinalField("boolean"),
  declarantCompanyTaxCode: getDefaultFinalField("number"),
  previousYearResidence: {
    residenceChangeDate: getDefaultFinalField("string"),
    cityCode: getDefaultFinalField("string"),
    cityName: getDefaultFinalField("string"),
    province: getDefaultFinalField("string"),
    regionCode: getDefaultFinalField("string"),
    regionName: getDefaultFinalField("string"),
    citiesMerge: getDefaultFinalField("string"),
    particularCases: getDefaultFinalField("string")
  },
  currentYearResidence: {
    cityCode: getDefaultFinalField("string"),
    province: getDefaultFinalField("string"),
    cityName: getDefaultFinalField("string"),
    citiesMerge: getDefaultFinalField("string")
  },
  exemptSeizedAssets: getDefaultFinalField("boolean"),
  imuDeduction: getDefaultFinalField("string"),
  paymentPresentationOrdinaryDeadlines: getDefaultFinalField("boolean"),
  businessFrameworkOnly: getDefaultFinalField("boolean"),
  landBuildingsFramework: getDefaultFinalField("boolean"),
  cooperativeType: getDefaultFinalField("string"),
  budgetApprovalDate: getDefaultFinalField("string"),
  nonResidentEntity: getDefaultFinalField("boolean"),
  netAssets: getDefaultFinalField("number"),
  iap: getDefaultFinalField("boolean"),
  irapPublicEntities: getDefaultFinalField("boolean"),
  iresAdditionals: getDefaultFinalField("string"),
  iresAdditionalsPreviousYear: getDefaultFinalField("string"),
  taxCalculationManagement: getDefaultFinalField("boolean"),
  withdrawalFromTaxDraft: getDefaultFinalField("boolean"),
  imuEncModelManagement: getDefaultFinalField("boolean"),
  trust: getDefaultFinalField("string"),
  transparency: getDefaultFinalField("string"),
  transparencyEndDate: getDefaultFinalField("string"),
  consolidated: getDefaultFinalField("boolean"),
  consolidatedEndDate: getDefaultFinalField("string"),
  consolidating: getDefaultFinalField("boolean"),
  prospectus: getDefaultFinalField("string"),
  modeloF24EntiPubblici: getDefaultFinalField("boolean"),
  contributorAssistantData: getDefaultFinalField("string"),
  participationShare: getDefaultFinalField("number"),
  months: getDefaultFinalField("number"),
  participationType: getDefaultFinalField("string"),
  participationStartDate: getDefaultFinalField("string"),
  participationEndDate: getDefaultFinalField("string"),
  subscriptionDateFirst: getDefaultFinalField("string"),
  cancellationDateFirst: getDefaultFinalField("string"),
  code1First: getDefaultFinalField("number"),
  code2First: getDefaultFinalField("number"),
  code3First: getDefaultFinalField("number"),
  inpsOfficeCodeFirst: getDefaultFinalField("string"),
  inpsOfficeNameFirst: getDefaultFinalField("string"),
  ivsPositionFirst: getDefaultFinalField("string"),
  ivs50percentReductionFirst: getDefaultFinalField("boolean"),
  landlordsFirst: getDefaultFinalField("boolean"),
  insurersThirdFourthFirst: getDefaultFinalField("boolean"),
  flatRateConcessionsFirst: getDefaultFinalField("boolean"),
  flatRateYearFirst: getDefaultFinalField("string"),
  nonRegisteredPharmacistFirst: getDefaultFinalField("boolean"),
  fixedInstallmentsIvsFirst: getDefaultFinalField("boolean"),
  subscriptionDateSecond: getDefaultFinalField("string"),
  cancellationDateSecond: getDefaultFinalField("string"),
  code1Second: getDefaultFinalField("number"),
  code2Second: getDefaultFinalField("number"),
  code3Second: getDefaultFinalField("number"),
  inpsOfficeCodeSecond: getDefaultFinalField("string"),
  inpsOfficeNameSecond: getDefaultFinalField("string"),
  ivsPositionSecond: getDefaultFinalField("string"),
  ivs50percentReductionSecond: getDefaultFinalField("boolean"),
  landlordsSecond: getDefaultFinalField("boolean"),
  insurersThirdFourthSecond: getDefaultFinalField("boolean"),
  flatRateConcessionsSecond: getDefaultFinalField("boolean"),
  flatRateYearSecond: getDefaultFinalField("string"),
  nonRegisteredPharmacistSecond: getDefaultFinalField("boolean"),
  inpsOfficeCodeSelfemployment: getDefaultFinalField("string"),
  inpsOfficeNameSelfemployment: getDefaultFinalField("string"),
  ivsPositionSelfemployment: getDefaultFinalField("string"),
  modulesManagement: [],
  groupCompany: getDefaultFinalField("string"),
  controlledCompanies: [],
  f24VatPayable: getDefaultFinalField("string"),
  agriculture: getDefaultFinalField("string"),
  travelAgency: getDefaultFinalField("boolean"),
  article74And73: getDefaultFinalField("boolean"),
  particularCategories: getDefaultFinalField("string"),
  article74c5: getDefaultFinalField("boolean"),
  innovativeStartups: getDefaultFinalField("boolean"),
  supervisoryBodySubscription: [],
  eFileSubmissionDate: getDefaultFinalField("string")
});

export const mappingFromWorkspace = (
  business,
  personalData,
  index,
  modulesManagement
) => {
  const complaintType = () => {
    if (
      business?.vatConfiguration?.vatPeriodicity === "NORMAL_MONTHLY" ||
      "MONTHLY_ADVANCE"
    )
      return "monthly";
    else return "quarterly";
  };

  return {
    // Fields with "" don't exist
    declarantId: "",
    companyNameOrSurname:
      personalData?.businessLegalForm === "PF"
        ? personalData?.surname ?? ""
        : personalData?.businessName ?? "",
    name: personalData?.name ?? "",
    taxCode: personalData?.taxIdentifier ?? "",
    vatCode: personalData?.vatIdentifier ?? "",
    module: index ?? "",
    activityCode: business?.atecoCode ?? "",
    activityDescription: business?.atecoCodeDescription ?? "",
    modelType: "",
    mainActivityVatCode: business?.vatConfiguration?.id ?? "",
    specialCases: business?.vatConfiguration?.specialVatRegime ?? "",
    transformationDate: "",
    complaintType: complaintType(),
    periodStartDate: business?.startDate?.split("T")[0],
    periodEndDate: business?.endDate?.split("T")[0],
    periodType: "",
    ...modulesManagement
  };
};

export const mappinSettingsFromWorkspace = (workspace, settings) => {
  if (workspace.business) {
    settings.modulesManagement = workspace.business
      .sort((a, b) => (a.type === "MAIN" && b.type === "SECONDARY" ? -1 : 1))
      .map((item, index) =>
        mappingFromWorkspace(
          item,
          workspace.personalData,
          index + 1,
          settings.modulesManagement[index]
        )
      );
  }

  return settings;
};

import { useNavigate } from "react-router-dom";

import { useTranslation } from "@onefront/react-sdk";

import Dialog from "@vapor/react-material/Dialog";
import DialogActions from "@vapor/react-material/DialogActions";
import DialogContent from "@vapor/react-material/DialogContent";
import DialogTitle from "@vapor/react-material/DialogTitle";
import Divider from "@vapor/react-material/Divider";
import Button from "@vapor/react-material/Button";
import IconButton from "@vapor/react-material/IconButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { useCqrs } from "../../../services/cqrs";
import LoadingSpinner from "../../components/LoadingSpinner";

export const DialogDeleteTaxReport = ({ open, onClose, taxReportData }) => {
  const { t } = useTranslation("foa");
  const { issueCommand, isIssueCommandLoading } = useCqrs();
  const navigate = useNavigate();

  const handleCancel = () => onClose();

  const handleOk = async () => {
    const document = {
      uuid: taxReportData?.uuid,
      version: taxReportData?.document.version,
      user: taxReportData?.document.user
    };

    const res = await issueCommand("delete", "tax-reports", document, {
      headers: {
        "taxpayer-id": taxReportData?.taxpayer_id
      },
      shouldComplete: true
    });

    if (!res.error) navigate("/tax-reports");

    onClose();
  };

  const taxPayerName =
    taxReportData?.document?.generalData?.company?.businessName;
  const dialogDeleteTitle = t("dialogDeleteTitle");
  const dialogContent = t("taxPayerNameDelete", { taxPayerName });

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "40%"
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {dialogDeleteTitle}
          <IconButton size="medium" onClick={handleCancel}>
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </DialogTitle>
        <Divider variant="alert" />
        {isIssueCommandLoading && <LoadingSpinner />}
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCancel}>
            {t("dialogTaxReportCancelButton")}
          </Button>
          <Button variant="contained" onClick={handleOk} color="error">
            {t("dialogTaxReportOkButton")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { useEffect, useState } from "react";
import { useTheme } from "@vapor/react-material/styles";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack } from "@vapor/react-material/Stack";

import { useTranslation } from "@onefront/react-sdk";

import {
  generateChips,
  generateFilters
} from "../tax-reports-list-filter/helpers";

import { useCurrentTenant } from "@drift/oneplatfront";
import { useFiscalYearContext } from "../../providers/fiscal-year";
import { useGetTaxpayerTaxReports } from "../../hooks/useGetTaxpayerTaxReports.js";
import { useTaxReportsListContext } from "../../providers/tax-reports-list-wrapper";

const loadMore = (filters, setOffset, offset, year, fetch) => {
  const newOffset = offset + 20;
  setOffset(newOffset);

  const status = filters.state?.[0] || "";
  const modifiedBy = filters.modifiedBy || "";
  const searchText = filters.searchText || "";
  const condition = filters.condition?.[0] || "";
  const declarationType = filters.declarationType || [];

  const chip = generateChips(declarationType, status, condition, modifiedBy);
  const filter = generateFilters(chip, searchText, modifiedBy);

  fetch({
    year: year,
    filtersApplied: filter,
    order: "asc",
    orderBy: ["taxpayer_name"],
    offset: newOffset
  });
};

const LoadMoreButton = () => {
  const { t } = useTranslation("foa");
  const theme = useTheme();

  const { fiscalYear: year } = useFiscalYearContext();
  const { tenant } = useCurrentTenant(true);
  const { cleanedData, fetch, loading } = useGetTaxpayerTaxReports({
    tenantId: tenant?.itemId
  });

  const { taxReportsList, setTaxReportsList, filters, offset, setOffset } =
    useTaxReportsListContext();

  const [disabled, setDisabled] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    setOffset(0);
  }, []);

  useEffect(() => {
    if (!loading) setLoadingMore(false);
  }, [loading]);

  useEffect(() => {
    if (cleanedData?.tax_reports_public_taxpayers_tax_reports?.length > 0) {
      const newList = [
        ...taxReportsList.tax_reports_public_taxpayers_tax_reports,
        ...cleanedData.tax_reports_public_taxpayers_tax_reports
      ];

      setTaxReportsList({
        ...taxReportsList,
        tax_reports_public_taxpayers_tax_reports: newList
      });
    }
  }, [cleanedData]);

  useEffect(() => {
    const {
      tax_reports_public_taxpayers_tax_reports: list,
      tax_reports_public_taxpayers_tax_reports_aggregate: count
    } = taxReportsList;

    const curRows = list?.length ?? 0;
    const totalRows = count?.aggregate?.count || 0;

    setDisabled(curRows >= totalRows);
  }, [taxReportsList]);

  return (
    <Stack
      onClick={
        loadingMore || disabled
          ? null
          : () => {
              setLoadingMore(true);
              loadMore(filters, setOffset, offset, year, fetch);
            }
      }
      justifyContent="flex-end"
      direction="row"
      sx={{ margin: "20px" }}
    >
      <Typography
        color={
          disabled || loading
            ? theme.palette.primary.textHintColor
            : theme.palette.primary.interactiveDefault
        }
        sx={{
          fontSize: "14px",
          cursor: "pointer",
          "&:hover": { textDecoration: "underline" }
        }}
      >
        {t("buttonLoadMore")}
      </Typography>
    </Stack>
  );
};

export default LoadMoreButton;

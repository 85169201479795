const getTaxReportQuery = `query get_taxreport($uuid: String = "") {
    tax_reports_get_curr_item(args: {uuid: $uuid}, where: {uuid: {_eq: $uuid}}) {
        document
        taxpayer_id
        updated_at
        uuid
    }
}`;

export const getTaxReport = ({ query, id, taxpayerId, itemId }) =>
  query(getTaxReportQuery, { uuid: id }, { taxpayerId, "tenant-id": itemId });

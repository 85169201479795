import { useNavigate } from "react-router";

import { useTranslation, useSnackbar } from "@onefront/react-sdk";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import Button from "@vapor/react-material/Button";
import VaporPage from "@vapor/react-custom/VaporPage";

import TaxReportsButtonYears from "./TaxReportsButtonYears";

import { useCurrentTenant } from "@drift/oneplatfront";
import { useImportTaxRepotList } from "../hooks/useImportTaxReportList";
import { usePopulateTaxpayerTaxReports } from "../hooks/usePopulateTaxpayerTaxReports";
import { useCompanyList } from "../../../hooks/companyList";

import { useEffect, useState } from "react";

const currentYear = new Date().getFullYear();
const years = Array.from(
  { length: currentYear - 2022 },
  (_, index) => 2023 + index
);

const TaxReportsListPage = ({ children }) => {
  const { t } = useTranslation("foa");
  const navigate = useNavigate();
  const [prevTenant, setPrevTenant] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const { tenant } = useCurrentTenant(true);
  const companyList = useCompanyList({ tenantId: tenant?.itemId });

  const importTax = useImportTaxRepotList({
    tenantId: tenant?.itemId,
    years
  });

  const { fetch: populateTaxPayerFetch } = usePopulateTaxpayerTaxReports();

  useEffect(() => {
    (async () => {
      if (
        !tenant ||
        (prevTenant?.id === tenant?.id && prevTenant?.itemId === tenant?.itemId)
      )
        return; // prevent multiple calls
      setPrevTenant(tenant);

      // if data is not fetched, fetch it
      try {
        const importTaxData = await importTax.fetch();
        const companyListRes = await companyList.fetch();

        if (!companyListRes.data || !importTaxData) return;
        //if we have the data, populate the tax reports table
        for (const year of years) {
          const requestPayload = {
            companiesList: companyListRes.data.content
              .filter((x) => x.personalData)
              .map((x) => {
                return {
                  tenantId: tenant.itemId,
                  taxIdentifier: x.personalData.taxIdentifier,
                  vatIdentifier: x.personalData.vatIdentifier,
                  businessName: x.personalData.businessName,
                  name: x.personalData.name,
                  surname: x.personalData.surname,
                  year,
                  taxPayerId: x.id
                };
              })
          };

          await populateTaxPayerFetch(requestPayload);
        }
      } catch (e) {
        enqueueSnackbar(t("errorGettingTaxReportList"), {
          variant: "error",
          autoHideDuration: 3000
        });
        console.error(e);
      }
    })();
  }, [tenant]);

  return (
    <VaporPage
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",

        "> .css-0": {
          flex: 1,
          height: "100%",
          position: "relative",
          "> .MuiStack-root": {
            position: "absolute",
            height: "100%",
            width: "100%"
          }
        }
      }}
      title={t("TaxReportsList")}
      headerLeft={
        <Button
          sx={{ width: "50px", marginInlineStart: "auto" }}
          onClick={() => {
            navigate("/");
          }}
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        />
      }
      headerRight={<TaxReportsButtonYears />}
    >
      {children}
    </VaporPage>
  );
};

export default TaxReportsListPage;

import React, { useState } from "react";

import MenuItem from "@vapor/react-material/MenuItem";
import Select from "@vapor/react-material/Select";
import Stack from "@vapor/react-material/Stack";
import ChartBar from "./ChartBar";

const SendingStatus = () => {
  const years = Array.from({ length: 2 }, (_, index) => 2023 + index);

  const [year, setYear] = useState(new Date().getFullYear());

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  return (
    <Stack sx={{ width: "100%" }} spacing={3}>
      <Stack>
        <Select
          sx={{ width: "100px" }}
          value={year}
          size="small"
          onChange={handleYearChange}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack>
        <ChartBar year={year} />
      </Stack>
    </Stack>
  );
};

export default SendingStatus;

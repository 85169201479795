import { useTranslation } from "@onefront/react-sdk";

import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-material/Typography";

import { ReportDataTable } from "./ReportDataTable";
import { ReportDataSections } from "./ReportDataSections";
import { ReportDataFilters } from "./ReportDataFilters";
import { useTaxReportData } from "../../providers/taxReportDataProvider";

export const ReportData = () => {
  const { t } = useTranslation();
  const { taxReportData } = useTaxReportData();

  return !taxReportData?.document?.data?.reportData ||
    !taxReportData?.document?.data?.reportData.length ? (
    <Box sx={{ marginTop: "50px" }}>
      <Stack alignItems="center" justifyContent="center">
        <Typography variant="titleLarge">{t("NoData")}</Typography>
      </Stack>
    </Box>
  ) : (
    <Box
      sx={{
        padding: "0 36px",
        marginTop: "12px"
      }}
    >
      {/* Nav and table */}
      <Stack direction="row" alignItems={"baseline"}>
        {/* Nav */}
        <Box
          sx={{
            width: "20%"
          }}
        >
          {<ReportDataSections />}
        </Box>
        {/* Filters */}
        <Box
          sx={{
            width: "100%"
          }}
        >
          <ReportDataFilters />
          {/* Table */}
          <Stack
            sx={{
              paddingTop: "32px" //Margin between filters and table scroll cannot be added here
            }}
          >
            <ReportDataTable />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export const convertSheetListStringToTableRows = (sheetList) => {
  if (typeof sheetList === "string") {
    try {
      const parsedSheetList = JSON.parse(sheetList);

      const getFlatItems = (items) => {
        const toReturn = [...items];

        items.forEach((item) => {
          if (item.items) toReturn.push(...getFlatItems(item.items));
        });

        return toReturn;
      };

      const flatItems = getFlatItems(parsedSheetList.items);

      return flatItems.reduce(
        (acc, item, index) => {
          const path = item.chiave.split("\t").filter((x) => x !== "");
          acc.newRows.push({
            name: path[path.length - 1],
            description: item.descrizione,
            path: path,
            lastYearStatus: "",
            id: index,
            status: item.statoCompilazione,
            hasChildren: item.items?.length,
            dataCalc: item.dataCalc,
            treeKey: item.chiave
          });

          if (item.errori && path.length === 1)
            acc.newErrorNumber += +item.errori;
          if (item.evidenzia && path.length === 1)
            acc.newWarningNumber += +item.evidenzia;

          return acc;
        },
        { newRows: [], newErrorNumber: 0, newWarningNumber: 0 }
      );
    } catch (error) {
      console.error(error);
    }
  }

  return null;
};

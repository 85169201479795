import { useNavigate } from "react-router-dom";

import { useTranslation, useGetConfig } from "@onefront/react-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-regular-svg-icons";
import { faCircleX } from "@fortawesome/pro-solid-svg-icons";
import { LicenseInfo } from "@mui/x-license-pro";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import DataGridPro from "@vapor/react-x-data-grid/DataGridPro";
import Box from "@vapor/react-material/Box";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { useTheme } from "@vapor/react-material/styles";

import { useTaxReportsListContext } from "../../providers/tax-reports-list-wrapper";
import TaxpayerButton from "../tax-reports-list-filter/TaxpayerButton";
import { TaxReportStatus } from "./TaxReportStatus";
import { TaxPayerCell } from "./TaxPayerCell";
import { TaxReportCell } from "./TaxReportCell";
import { ActionCell } from "./ActionCell";
import LoadMoreButton from "./LoadMoreButton";
import { useWorkspaceData } from "../../../../services/workspace/workspaceProvider";
import { formatDateTime } from "../../../../utils/date";
import { useTaxReportData } from "../../../tax-report-page/providers/taxReportDataProvider";

const TaxReportsListTable = () => {
  const { t } = useTranslation();
  const { workspaceUrl } = useWorkspaceData();
  const { taxReportsList, createTaxReport } = useTaxReportsListContext();
  const navigate = useNavigate();
  const { setTaxReportData, setTaxReportAssignee, setTaxReportLastUpdate } =
    useTaxReportData();
  const licenseKey = useGetConfig("dataGrid.licenseKey");
  LicenseInfo.setLicenseKey(licenseKey);

  const columns = [
    {
      field: "taxpayer_name",
      headerName: t("Name_Contributor"),
      width: 300,
      renderCell: ({ row }) => TaxPayerCell({ row, workspaceUrl })
    },
    {
      field: "vat_number",
      headerName: t("vatCodeAbbreviation"),
      width: 180
    },
    {
      field: "tax_code",
      headerName: t("fiscalCode"),
      width: 180
    },
    {
      field: "tax_report",
      headerName: t("Declaration"),
      width: 300,
      renderCell: ({ row }) => TaxReportCell({ row }),
      sortComparator: (v1, v2) => t(v1).localeCompare(t(v2))
    },
    {
      field: "condition",
      headerName: t("Condition"),
      renderCell: ({ row }) => t(row?.condition)
    },
    {
      field: "status",
      headerName: t("Status"),
      width: 150,
      renderCell: TaxReportStatus
    },
    /*  {
      field: "assignee",
      headerName: t("Assignee"),
      flex: 1,
      minWidth: 300
    },
    {
      field: "deadline",
      headerName: t("Deadline"),
      width: 150,
      valueFormatter: (params) => {
        return params.value ? orderDate(params.value) : "";
      }
    }, */
    {
      field: "modified_by",
      headerName: t("lastModifiedBy"),
      flex: 1,
      minWidth: 150
    },
    {
      field: "last_update",
      headerName: t("lastUpdate"),
      width: 160,
      valueFormatter: (params) =>
        params.value ? formatDateTime(params.value) : ""
    },
    {
      field: "actions",
      headerName: t("Actions"),
      type: "actions",
      width: 150,
      renderCell: (params) => {
        return <ActionCell row={params.row} />;
      }
    }
  ];

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        margin: "0 21px",
        minHeight:
          taxReportsList?.tax_reports_public_taxpayers_tax_reports?.length === 0
            ? "440px"
            : null
      }}
    >
      <DataGridPro
        sx={{
          border: 0,
          // Min height for noRowsOverlay
          "--DataGrid-overlayHeight": "300px",
          ".MuiBadge-anchorOriginTopRight": { display: "none" }
        }}
        localeText={{
          toolbarExport: t("Export"),
          toolbarExportCSV: t("ExportCSV"),
          toolbarExportPrint: t("ExportPrint")
        }}
        rows={
          Array.isArray(
            taxReportsList?.tax_reports_public_taxpayers_tax_reports
          )
            ? taxReportsList?.tax_reports_public_taxpayers_tax_reports
            : []
        }
        checkboxSelection
        columns={columns}
        getRowId={(row) => {
          return row.id;
        }}
        disableColumnMenu
        hideFooter
        defaultGroupingExpansionDepth={1}
        disableRowSelectionOnClick={true}
        disableColumnSelector={true}
        disableColumnResize={true}
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "taxpayer_name"],
            right: ["actions"]
          },
          sorting: {
            sortModel: [
              { field: "taxpayer_name", sort: "asc" },
              { field: "tax_report", sort: "asc" }
            ]
          }
        }}
        onRowClick={async ({ row }) => {
          if (row.tax_report_id) {
            setTaxReportData(null);
            setTaxReportAssignee(row.assignee);
            setTaxReportLastUpdate(row.last_update);
            navigate(`/tax-report/${row.taxpayer_id}/${row.tax_report_id}`);
          } else {
            const cmdId = await createTaxReport(row);
            if (cmdId) {
              setTaxReportData(null);
              navigate(`/tax-report/${row.taxpayer_id}/${cmdId}`);
            }
          }
        }}
        slots={{
          toolbar: () => (
            <GridToolbarContainer
              sx={{
                "&.MuiDataGrid-toolbarContainer": {
                  justifyContent: "flex-start"
                }
              }}
            >
              <GridToolbarExport />
              <Button disabled="true" size="small">
                {t("closeDeclarations")}
              </Button>
              <Button disabled="true" size="small">
                {t("ExportPrint")}
              </Button>
              <Button disabled="true" size="small">
                {t("validate")}
              </Button>
              <Button disabled="true" size="small">
                {t("sendTelematico")}
              </Button>
            </GridToolbarContainer>
          ),
          noRowsOverlay: () => <NoRowsOverlay />
        }}
      />
      <LoadMoreButton />
    </Box>
  );
};

const NoRowsOverlay = () => {
  const theme = useTheme();
  const { t } = useTranslation("foa");
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Stack
        alignItems="flex-end"
        justifyContent="space-between"
        direction="row"
        sx={{
          marginBottom: "9px"
        }}
      >
        <FontAwesomeIcon
          icon={faList}
          style={{ fontSize: "100px" }}
          color={theme.palette.primary.azureishWhite}
        />
        <FontAwesomeIcon
          icon={faCircleX}
          style={{
            fontSize: "48px",
            marginBottom: "-5px",
            marginLeft: "-27px"
          }}
          color={theme.palette.primary.interactiveDefault}
        />
      </Stack>
      <Typography variant="titleMedium">
        {t("taxReportsListNoRowsOverlayTitle")}
      </Typography>
      <Typography
        variant="body"
        sx={{
          marginTop: "8px",
          marginBottom: "24px"
        }}
      >
        {t("taxReportsListNoRowsOverlaySubtitle")}
      </Typography>
      <TaxpayerButton variant="contained" />
    </Stack>
  );
};

export default TaxReportsListTable;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  useGetConfig,
  useSnackbar,
  useTokenData,
  useTranslation
} from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import { LicenseInfo } from "@mui/x-license-pro";
import LinearProgress from "@vapor/react-material/LinearProgress";
import DataGridPro from "@vapor/react-x-data-grid/DataGridPro";
import { useCurrentTenant } from "@drift/oneplatfront";
import { useTheme } from "@vapor/react-material/styles";
import MenuItem from "@vapor/react-material/MenuItem";
import Select from "@vapor/react-material/Select";
import TextField from "@vapor/react-material/TextField";
import {
  faArrowRight,
  faClose,
  faSearch
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useCqrs } from "../../../../services/cqrs";
import { TaxReportCell } from "../../../tax-reports-list/components/tax-reports-list-table/TaxReportCell.jsx";
import { TaxReportStatus } from "../../../tax-reports-list/components/tax-reports-list-table/TaxReportStatus";
import { useTaxReportData } from "../../../tax-report-page/providers/taxReportDataProvider";
import { useGetTaxpayerTaxReports } from "../../../tax-reports-list/hooks/useGetTaxpayerTaxReports.js";
import FilterByKpi from "./FilterByKpi";
import { TreeViewSelectFilter } from "../../../components/TreeViewSelectFilter.jsx";

const TaxReportListWidget = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const licenseKey = useGetConfig("dataGrid.licenseKey");
  LicenseInfo.setLicenseKey(licenseKey);

  const { tenant } = useCurrentTenant(true);

  const years = Array.from({ length: 2 }, (_, index) => 2023 + index);

  const [taxReportsList, setTaxReportsList] = useState({});
  const [searchText, setSearchText] = useState("");
  const [byLetter, setByLetter] = useState("");
  const [orderBy, setOrderBy] = useState("0");

  const { cleanedData: data, fetch } = useGetTaxpayerTaxReports({
    tenantId: tenant?.itemId
  });

  const [year, setYear] = useState(new Date().getFullYear());

  const [taxReport, setTaxReport] = useState("total");
  useEffect(() => {
    if (tenant) {
      fetch({
        year: year,
        filtersApplied: {
          searchText: searchText,
          declarationType: taxReport !== "total" ? [taxReport] : []
        },
        order: "asc",
        orderBy: orderBy !== "0" ? orderBy : "taxpayer_name",
        offset: 0
      });
    }
  }, [year, tenant, taxReport, searchText, orderBy]);

  useEffect(() => {
    if (data) setTaxReportsList(data);
  }, [data]);

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  /* Create TaxReport */
  const { setTaxReportData, setTaxReportAssignee, setTaxReportLastUpdate } =
    useTaxReportData();
  const { sub, email, family_name: surname, given_name: name } = useTokenData();
  const { enqueueSnackbar } = useSnackbar();
  const { issueCommand } = useCqrs();
  const [loading, setLoading] = useState(false);

  const createTaxReport = async (row) => {
    setLoading(true);
    try {
      const payload = {
        taxReport: row.tax_report,
        year: row.year,
        period: row.period,
        type: row.condition,
        user: {
          sub,
          name,
          surname,
          email
        },
        tenant_id: tenant?.itemId,
        taxpayer_id: row?.taxpayer_id
      };

      const headers = { "taxpayer-id": row?.taxpayer_id };
      const cmdResult = await issueCommand("create", "tax-reports", payload, {
        headers,
        shouldComplete: true
      });

      if (cmdResult) {
        const { cmd_id } = cmdResult;
        setTaxReportData(null);
        navigate(`/tax-report/${row.taxpayer_id}/${cmd_id}`);
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  const ActionCell = ({ row }) => {
    return (
      <FontAwesomeIcon
        onClick={() => {
          const { assignee, last_update, tax_report_id, taxpayer_id } = row;
          if (tax_report_id) {
            setTaxReportData(null);
            setTaxReportAssignee(assignee);
            setTaxReportLastUpdate(last_update);
            navigate(`/tax-report/${taxpayer_id}/${tax_report_id}`);
          } else createTaxReport(row);
        }}
        icon={faArrowRight}
        color={theme.palette.primary.primary60Color}
        size="lg"
        style={{ cursor: "pointer" }}
      />
    );
  };

  const TaxPayerCell = ({ row }) => {
    return (
      <Stack direction="column">
        <Stack>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#101214",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "180px"
            }}
          >
            {row?.taxpayer_name}
          </Typography>
        </Stack>
        <Stack spacing={0.5} direction="row">
          <Stack>
            <Typography
              sx={{
                fontSize: "12px",
                color: "#687883",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "180px"
              }}
            >
              {`${row?.vat_number} / ${row?.tax_code}`}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const columns = [
    {
      field: "taxpayer_name",
      headerName: t("Name_Contributor"),
      width: 200,
      renderCell: ({ row }) => TaxPayerCell({ row })
    },
    {
      field: "tax_report",
      headerName: t("Declaration"),
      width: 200,
      renderCell: ({ row }) => TaxReportCell({ row })
    },
    {
      field: "condition",
      headerName: t("Condition"),
      renderCell: ({ row }) => t(row?.condition)
    },
    {
      field: "status",
      headerName: t("Status"),
      width: 160,
      renderCell: TaxReportStatus
    },
    {
      field: "actions",
      type: "actions",
      width: 70,
      headerAlign: "right",
      renderCell: ({ row }) => {
        return <ActionCell row={row} />;
      }
    }
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction="row" spacing={1} alignItems="end">
        <TextField
          sx={{ width: "250px" }}
          type="text"
          value={byLetter}
          onChange={(e) => {
            setByLetter(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setSearchText(byLetter);
            }
          }}
          InputProps={{
            endAdornment: (
              <>
                {searchText !== "" && (
                  <Button
                    sx={{
                      minWidth: "30px !important"
                    }}
                    onClick={() => {
                      setSearchText("");
                      setByLetter("");
                    }}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                )}
                <Button
                  onClick={() => {
                    setSearchText(byLetter);
                  }}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </>
            )
          }}
        />
        <TreeViewSelectFilter
          value={taxReport === "total" ? "" : taxReport}
          setValue={(value) => {
            setTaxReport(value?.value || "total");
          }}
          multiple={false}
          style={{ width: "250px" }}
        />
        <Select
          sx={{
            minWidth: "100px",
            marginLeft: "10px !important",
            height: "40px"
          }}
          value={year}
          size="small"
          onChange={handleYearChange}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack direction="row">
        <FilterByKpi
          year={year}
          taxReport={taxReport}
          text={searchText}
          order={orderBy}
        />
      </Stack>
      <Box sx={{ height: "260px" }}>
        <DataGridPro
          sx={{
            border: 0,
            "& .MuiDataGrid-columnHeader": {
              color: "#101214"
            }
          }}
          slots={{
            loadingOverlay: LinearProgress
          }}
          loading={loading}
          columns={columns}
          getRowId={(row) => {
            return row.id;
          }}
          rows={
            Array.isArray(data?.tax_reports_public_taxpayers_tax_reports)
              ? data?.tax_reports_public_taxpayers_tax_reports
              : []
          }
          initialState={{
            pinnedColumns: {
              right: ["actions"]
            }
          }}
          disableColumnMenu
          hideFooter
          defaultGroupingExpansionDepth={1}
          disableRowSelectionOnClick={true}
          disableColumnSelector={true}
          disableColumnResize={true}
        />
      </Box>
    </Box>
  );
};

export default TaxReportListWidget;

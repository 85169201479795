import pkg from "../package.json";

/**
 * OneFront SDK
 * ============
 *
 * This package provides most of the Services that you may need
 * in a OnePlatform Application.
 *
 * You write simple React Components and interact with the SDK
 * using modern hooks API.
 *
 * The SDK guides you into dividing you App into isolated Features
 * that you will glue together using ForrestJS APIs.
 */
import OneBox from "@drift/oneplatfront";

/**
 * Import the App's Features
 * =========================
 *
 * A Feature is an isolated portion of business logic that
 * interacts with the App's Services
 */
import { actions } from "./features/actions-page/index";
import { companies } from "./features/companies";
import { companySettings } from "./features/company-settings";
import { dashboard } from "./features/dashboard";
import { taxReportPage } from "./features/tax-report-page/index";
import { taxReportsList } from "./features/tax-reports-list";
import { cqrs } from "./services/cqrs";
import { fiscaliSaasIframeService } from "./services/fiscali-saas-iframe";
import { workspaceService } from "./services/workspace";

const APP_ID = process.env.REACT_APP_APP_ID || "demo";
console.log(`AppId: ${APP_ID}; Version: ${pkg.version}`);

const proxyBaseUrl = process.env.REACT_APP_PROXY_BASE_URL || "/api";

const DELEGATED_APP_ID = process.env.REACT_APP_DELEGATED_APP_ID || "app3010";
console.log(`Delegated AppId: ${DELEGATED_APP_ID}`);

const REACT_APP_FISCALI_SAAS_QUERY_STRING =
  process.env.REACT_APP_FISCALI_SAAS_QUERY_STRING || "default";

const AM5CHART_LICENSE_KEY = process.env.REACT_APP_AM5CHART_LICENSE_KEY || "";
const AM5MAP_LICENSE_KEY = process.env.REACT_APP_AM5MAP_LICENSE_KEY || "";

const DATAGRID_PRO_LICENSE_KEY =
  process.env.REACT_APP_DATAGRID_PRO_LICENSE_KEY || "";

/**
 * Configure and Run the App
 * =========================
 *
 * The boot process returns a Promise that you can handle
 * to figure out whether all was good or not.
 */
OneBox.run({
  trace: "compact",
  settings: {
    one: {
      axios: {
        proxy: { baseUrl: proxyBaseUrl }
      },
      loading: {
        delay: 2000
      },
      rest: {
        graphql: {
          baseUrl: "rnd::saas:tax-orchestrator:hasuraApi/v1/graphql"
        }
      },
      box: {
        module: {
          name: "rnd::saas:tax-orchestrator:app",
          version: pkg.version
        }
      },
      auth: {
        token: {
          verify: false
          // refresh: true
        }
      },
      layout: { title: "FioR" },
      login: {
        target: {
          params: ["mode=redirect", `appId=${APP_ID}`]
        },
        delegatedToken: {
          appId: `${DELEGATED_APP_ID}`
        }
      },
      i18n: {
        options: {
          fallbackLng: "it",
          defaultNS: "foa"
        }
      },
      notistack: {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      }
    },
    constants: {
      headers: {
        tenantId: "tenant-id",
        taxpayerId: "taxpayer-id"
      },
      errorManagement: { prefix: "errorManagement_" }
    },
    dataGrid: {
      licenseKey: DATAGRID_PRO_LICENSE_KEY
    },
    am5Chart: {
      license: AM5CHART_LICENSE_KEY
    },
    am5Map: {
      license: AM5MAP_LICENSE_KEY
    },
    fiscaliSaas: {
      iframeQueryString: REACT_APP_FISCALI_SAAS_QUERY_STRING
    }
  },
  services: [cqrs, workspaceService, fiscaliSaasIframeService],
  features: [
    dashboard,
    companies,
    companySettings,
    taxReportsList,
    taxReportPage,
    actions
  ]
}).catch(console.error);

import { forwardRef } from "react";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack, Alert } from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle /* , faArrowRight */
} from "@fortawesome/pro-solid-svg-icons";
import TaxReportPageAccordion from "../TaxReportPageAccordion";

const TaxReportPagePreviousRefundCheck = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    title,
    subtitle,
    typeTaxReport,
    filledPreviousTaxReport,
    previousRefundCheck
  } = props;

  return (
    <TaxReportPageAccordion ref={ref} title={title} subtitle={subtitle}>
      {!filledPreviousTaxReport && (
        <Alert
          sx={{ "&.MuiAlert-root": { padding: "16px" } }}
          icon={<FontAwesomeIcon icon={faInfoCircle} />}
          severity="info"
          variant="outlined"
        >
          <Typography
            variant="caption"
            fontSize="16px"
            height="24px"
            sx={{ fontWeight: "bold" }}
          >
            {t("info")}
          </Typography>
          <Typography variant="caption">{`${typeTaxReport} ${t(
            "quarterlyNotFilled"
          )}`}</Typography>
        </Alert>
      )}
      {filledPreviousTaxReport && (
        <Stack sx={{ gap: "16px" }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" sx={{ gap: "10px" }}>
              <Typography variant="bodyLarge">
                {t("amountClaimedReimbursement")}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="bodyLarge700">
                {/*  TODO: This value should be calculated based on the data from mapping from IVA-TR   
                            from field TD6 of VAT TR form previous quarter */}
                {previousRefundCheck?.amountClaimedReimbursement ||
                  t("dataNotAvailable")}
              </Typography>
              {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" sx={{ gap: "10px" }}>
              <Typography variant="bodyLarge">
                {t("amountCompensation")}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="bodyLarge700">
                {/*  TODO: This value should be calculated based on the data from mapping from IVA-TR   
                          from field TD7 of VAT form TR previous quarter */}
                {previousRefundCheck?.amountCompensation ||
                  t("dataNotAvailable")}
              </Typography>
              {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
            </Stack>
          </Stack>
        </Stack>
      )}
    </TaxReportPageAccordion>
  );
});

export default TaxReportPagePreviousRefundCheck;

import { useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { ExtendedDatePicker } from "@onefront/workspace-forms/components/General/ExtendedDatePicker/ExtendedDatePicker.js";
import { AmountTextfield } from "@onefront/workspace-forms/components/General/AmountTextfield/AmountTextfield.js";
import { ExtendedTextfield } from "@onefront/workspace-forms/components/General/ExtendedTextfield/ExtendedTextfield.js";
import { ExtendedSelect } from "@onefront/workspace-forms/components/General/ExtendedSelect/ExtendedSelect.js";
import { ExtendedMenuItem } from "@onefront/workspace-forms/components/General/ExtendedMenuItem/ExtendedMenuItem.js";

import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { onChangeHandlerField } from "../../../handlers";
import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import { ExtendedToggle } from "../../ExtendedToggle";

export const TenantPFFirstPositionArtisansAndTraders = () => {
  const { t } = useTranslation();
  const {
    sotCompanySettings,
    companySettings,
    setCompanySettings,
    isEditMode,
    setIsEditMode,
    loading,
    setAnyChange
  } = useCompanySettingsContext();

  const onChangeHandler = (e) => {
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  return (
    <CompanySettingsGrid>
      <ExtendedDatePicker
        name="subscriptionDate"
        noValueLabel={t("notSet")}
        editing={isEditMode}
        value={companySettings.subscriptionDateFirst}
        onChange={(name, value) =>
          onChangeHandler({
            target: { name, value: value.replace("T00:00:00", "") }
          })
        }
        label={t("subscriptionDate")}
        loading={loading}
      />
      <ExtendedDatePicker
        name="cancellationDateFirst"
        noValueLabel={t("notSet")}
        editing={isEditMode}
        value={companySettings.cancellationDateFirst}
        onChange={(name, value) =>
          onChangeHandler({
            target: { name, value: value.replace("T00:00:00", "") }
          })
        }
        label={t("cancellationDateFirst")}
        loading={loading}
      />
      <AmountTextfield
        label={t("code1")}
        name="code1First"
        value={companySettings.code1First}
        onValueChange={(name, value) => {
          onChangeHandler({ target: { name, value } });
        }}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
      />
      <AmountTextfield
        label={t("code2")}
        name="code2First"
        value={companySettings.code2First}
        onValueChange={(name, value) => {
          onChangeHandler({ target: { name, value } });
        }}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
      />
      <AmountTextfield
        label={t("code3")}
        name="code3First"
        value={companySettings.code3First}
        onValueChange={(name, value) => {
          onChangeHandler({ target: { name, value } });
        }}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
      />
      <ExtendedTextfield
        name="inpsOfficeCodeFirst"
        value={companySettings.inpsOfficeCodeFirst}
        onChange={onChangeHandler}
        label={t("inpsOfficeCode")}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
      />
      <ExtendedTextfield
        name="inpsOfficeNameFirst"
        value={companySettings.inpsOfficeNameFirst}
        onChange={onChangeHandler}
        label={t("inpsOfficeName")}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
      />
      <ExtendedSelect
        label={t("ivsPosition")}
        name="ivsPositionFirst"
        value={companySettings.ivsPositionFirst}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem label={t("artisians")} value="Artisans">
          {t("artisians")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("traders")} value="Traders">
          {t("traders")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("ArtisansWithoutExperience")}
          value="ArtisansWithoutExperience"
        >
          {t("ArtisansWithoutExperience")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("TradersWithoutExperience")}
          value="TradersWithoutExperience"
        >
          {t("TradersWithoutExperience")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("Magistrates")} value="Magistrates">
          {t("Magistrates")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("otherSubgetsSeparatedMgmt")}
          value="otherSubgetsSeparatedMgmt"
        >
          {t("otherSubgetsSeparatedMgmt")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("noProtectionProfessionalsSeparatedMgmt")}
          value="noProtectionProfessionalsSeparatedMgmt"
        >
          {t("noProtectionProfessionalsSeparatedMgmt")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <ExtendedToggle
        name="ivs50percentReductionFirst"
        value={companySettings.ivs50percentReductionFirst}
        onChange={onChangeHandler}
        label={t("ivs50percentReductionFirst")}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedToggle
        name="landlordsFirst"
        value={companySettings.landlordsFirst}
        onChange={onChangeHandler}
        label={t("landlordsFirst")}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedToggle
        name="insurersThirdFourthFirst"
        value={companySettings.insurersThirdFourthFirst}
        onChange={onChangeHandler}
        label={t("insurersThirdFourth")}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedToggle
        name="flatRateConcessionsFirst"
        value={companySettings.flatRateConcessionsFirst}
        onChange={onChangeHandler}
        label={t("flatRateConcessions")}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedSelect
        label={t("flatRateYear")}
        name="flatRateYearFirst"
        value={companySettings.flatRateYearFirst}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem
          label={t("flatRateAccession")}
          value="flatRateAccession"
        >
          {t("flatRateAccession")}
        </ExtendedMenuItem>
        <ExtendedMenuItem label={t("flatRateWaiver")} value="flatRateWaiver">
          {t("flatRateWaiver")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <ExtendedToggle
        name="nonRegisteredPharmacistFirst"
        value={companySettings.nonRegisteredPharmacistFirst}
        onChange={onChangeHandler}
        label={t("nonRegisteredPharmacist")}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedToggle
        name="fixedInstallmentsIvsFirst"
        value={companySettings.fixedInstallmentsIvsFirst}
        onChange={onChangeHandler}
        label={t("fixedInstallmentsIvs")}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
    </CompanySettingsGrid>
  );
};

import { forwardRef } from "react";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Stack, Alert } from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle /* , faArrowRight */
} from "@fortawesome/pro-solid-svg-icons";
import TaxReportPageAccordion from "../TaxReportPageAccordion";

const TaxReportPageISAScore = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { title, subtitle, isaScore } = props;

  return (
    <TaxReportPageAccordion ref={ref} title={title} subtitle={subtitle}>
      <Alert
        sx={{ "&.MuiAlert-root": { padding: "16px" } }}
        icon={<FontAwesomeIcon icon={faInfoCircle} />}
        severity="info"
        variant="outlined"
      >
        <Typography
          variant="caption"
          fontSize="16px"
          height="24px"
          sx={{ fontWeight: "bold" }}
        >
          {t("info")}
        </Typography>
        <Typography variant="caption">{t("messageISAScore")}</Typography>
      </Alert>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" sx={{ gap: "10px" }}>
          <Typography variant="bodyLarge">{title}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="bodyLarge700">
            {/*  TODO: This value we don't have it yet, is pending on the part of */}
            {isaScore || t("dataNotAvailable")}
          </Typography>
          {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
        </Stack>
      </Stack>
    </TaxReportPageAccordion>
  );
});

export default TaxReportPageISAScore;

const cities = [
  {
    code: "1",
    value: "F774",
    label: "MOTTA DE CONTI",
    province: "VC"
  },
  {
    code: "2",
    value: "G016",
    label: "OLCENENGO",
    province: "VC"
  },
  {
    code: "3",
    value: "G018",
    label: "OLDENICO",
    province: "VC"
  },
  {
    code: "4",
    value: "G266",
    label: "PALAZZOLO VERCELLESE",
    province: "VC"
  },
  {
    code: "5",
    value: "G471",
    label: "PERTENGO",
    province: "VC"
  },
  {
    code: "6",
    value: "G528",
    label: "PEZZANA",
    province: "VC"
  },
  {
    code: "7",
    value: "G666",
    label: "PILA",
    province: "VC"
  },
  {
    code: "8",
    value: "G685",
    label: "PIODE",
    province: "VC"
  },
  {
    code: "9",
    value: "G940",
    label: "POSTUA",
    province: "VC"
  },
  {
    code: "10",
    value: "G985",
    label: "PRAROLO",
    province: "VC"
  },
  {
    code: "11",
    value: "H108",
    label: "QUARONA",
    province: "VC"
  },
  {
    code: "12",
    value: "H132",
    label: "QUINTO VERCELLESE",
    province: "VC"
  },
  {
    code: "13",
    value: "H188",
    label: "RASSA",
    province: "VC"
  },
  {
    code: "16",
    value: "H293",
    label: "RIMELLA",
    province: "VC"
  },
  {
    code: "18",
    value: "H346",
    label: "RIVE",
    province: "VC"
  },
  {
    code: "19",
    value: "H365",
    label: "ROASIO",
    province: "VC"
  },
  {
    code: "20",
    value: "H549",
    label: "RONSECCO",
    province: "VC"
  },
  {
    code: "21",
    value: "H577",
    label: "ROSSA",
    province: "VC"
  },
  {
    code: "22",
    value: "H364",
    label: "ROVASENDA",
    province: "VC"
  },
  {
    code: "24",
    value: "H690",
    label: "SALASCO",
    province: "VC"
  },
  {
    code: "25",
    value: "H707",
    label: "SALI VERCELLESE",
    province: "VC"
  },
  {
    code: "26",
    value: "H725",
    label: "SALUGGIA",
    province: "VC"
  },
  {
    code: "27",
    value: "H861",
    label: "SAN GERMANO VERCELLESE",
    province: "VC"
  },
  {
    code: "28",
    value: "I337",
    label: "SANTHIA",
    province: "VC"
  },
  {
    code: "29",
    value: "I544",
    label: "SCOPA",
    province: "VC"
  },
  {
    code: "30",
    value: "I545",
    label: "SCOPELLO",
    province: "VC"
  },
  {
    code: "31",
    value: "I663",
    label: "SERRAVALLE SESIA",
    province: "VC"
  },
  {
    code: "32",
    value: "I984",
    label: "STROPPIANA",
    province: "VC"
  },
  {
    code: "33",
    value: "L420",
    label: "TRICERRO",
    province: "VC"
  },
  {
    code: "34",
    value: "L429",
    label: "TRINO",
    province: "VC"
  },
  {
    code: "35",
    value: "L451",
    label: "TRONZANO VERCELLESE",
    province: "VC"
  },
  {
    code: "36",
    value: "L566",
    label: "VALDUGGIA",
    province: "VC"
  },
  {
    code: "37",
    value: "L669",
    label: "VARALLO",
    province: "VC"
  },
  {
    code: "38",
    value: "L750",
    label: "VERCELLI",
    province: "VC"
  },
  {
    code: "39",
    value: "M003",
    label: "VILLARBOIT",
    province: "VC"
  },
  {
    code: "40",
    value: "M028",
    label: "VILLATA",
    province: "VC"
  },
  {
    code: "41",
    value: "M106",
    label: "VOCCA",
    province: "VC"
  },
  {
    code: "42",
    value: "A088",
    label: "AGRATE CONTURBIA",
    province: "NO"
  },
  {
    code: "43",
    value: "A264",
    label: "AMENO",
    province: "NO"
  },
  {
    code: "44",
    value: "A414",
    label: "ARMENO",
    province: "NO"
  },
  {
    code: "45",
    value: "A429",
    label: "ARONA",
    province: "NO"
  },
  {
    code: "46",
    value: "A653",
    label: "BARENGO",
    province: "NO"
  },
  {
    code: "47",
    value: "A752",
    label: "BELLINZAGO NOVARESE",
    province: "NO"
  },
  {
    code: "48",
    value: "A844",
    label: "BIANDRATE",
    province: "NO"
  },
  {
    code: "49",
    value: "A911",
    label: "BOCA",
    province: "NO"
  },
  {
    code: "50",
    value: "A929",
    label: "BOGOGNO",
    province: "NO"
  },
  {
    code: "51",
    value: "A953",
    label: "BOLZANO NOVARESE",
    province: "NO"
  },
  {
    code: "52",
    value: "B016",
    label: "BORGOLAVEZZARO",
    province: "NO"
  },
  {
    code: "53",
    value: "B019",
    label: "BORGOMANERO",
    province: "NO"
  },
  {
    code: "54",
    value: "B043",
    label: "BORGO TICINO",
    province: "NO"
  },
  {
    code: "55",
    value: "B176",
    label: "BRIGA NOVARESE",
    province: "NO"
  },
  {
    code: "56",
    value: "B183",
    label: "BRIONA",
    province: "NO"
  },
  {
    code: "57",
    value: "B431",
    label: "CALTIGNAGA",
    province: "NO"
  },
  {
    code: "58",
    value: "B473",
    label: "CAMERI",
    province: "NO"
  },
  {
    code: "59",
    value: "B823",
    label: "CARPIGNANO SESIA",
    province: "NO"
  },
  {
    code: "60",
    value: "B864",
    label: "CASALBELTRAME",
    province: "NO"
  },
  {
    code: "61",
    value: "B883",
    label: "CASALEGGIO NOVARA",
    province: "NO"
  },
  {
    code: "62",
    value: "B897",
    label: "CASALINO",
    province: "NO"
  },
  {
    code: "63",
    value: "B920",
    label: "CASALVOLONE",
    province: "NO"
  },
  {
    code: "64",
    value: "C149",
    label: "CASTELLAZZO NOVARESE",
    province: "NO"
  },
  {
    code: "65",
    value: "C166",
    label: "CASTELLETTO SOPRA TICINO",
    province: "NO"
  },
  {
    code: "66",
    value: "C364",
    label: "CAVAGLIETTO",
    province: "NO"
  },
  {
    code: "67",
    value: "C365",
    label: "CAVAGLIO D AGOGNA",
    province: "NO"
  },
  {
    code: "68",
    value: "C378",
    label: "CAVALLIRIO",
    province: "NO"
  },
  {
    code: "69",
    value: "C483",
    label: "CERANO",
    province: "NO"
  },
  {
    code: "70",
    value: "C829",
    label: "COLAZZA",
    province: "NO"
  },
  {
    code: "71",
    value: "C926",
    label: "COMIGNAGO",
    province: "NO"
  },
  {
    code: "72",
    value: "D162",
    label: "CRESSA",
    province: "NO"
  },
  {
    code: "73",
    value: "D216",
    label: "CUREGGIO",
    province: "NO"
  },
  {
    code: "74",
    value: "D309",
    label: "DIVIGNANO",
    province: "NO"
  },
  {
    code: "75",
    value: "D347",
    label: "DORMELLETTO",
    province: "NO"
  },
  {
    code: "76",
    value: "D492",
    label: "FARA NOVARESE",
    province: "NO"
  },
  {
    code: "77",
    value: "D675",
    label: "FONTANETO D AGOGNA",
    province: "NO"
  },
  {
    code: "78",
    value: "D872",
    label: "GALLIATE",
    province: "NO"
  },
  {
    code: "79",
    value: "D911",
    label: "GARBAGNA NOVARESE",
    province: "NO"
  },
  {
    code: "80",
    value: "D921",
    label: "GARGALLO",
    province: "NO"
  },
  {
    code: "82",
    value: "E001",
    label: "GHEMME",
    province: "NO"
  },
  {
    code: "83",
    value: "E120",
    label: "GOZZANO",
    province: "NO"
  },
  {
    code: "84",
    value: "E143",
    label: "GRANOZZO CON MONTICELLO",
    province: "NO"
  },
  {
    code: "85",
    value: "E177",
    label: "GRIGNASCO",
    province: "NO"
  },
  {
    code: "86",
    value: "E314",
    label: "INVORIO",
    province: "NO"
  },
  {
    code: "87",
    value: "E436",
    label: "LANDIONA",
    province: "NO"
  },
  {
    code: "88",
    value: "E544",
    label: "LESA",
    province: "NO"
  },
  {
    code: "89",
    value: "E803",
    label: "MAGGIORA",
    province: "NO"
  },
  {
    code: "90",
    value: "E880",
    label: "MANDELLO VITTA",
    province: "NO"
  },
  {
    code: "91",
    value: "E907",
    label: "MARANO TICINO",
    province: "NO"
  },
  {
    code: "92",
    value: "F047",
    label: "MASSINO VISCONTI",
    province: "NO"
  },
  {
    code: "93",
    value: "F093",
    label: "MEINA",
    province: "NO"
  },
  {
    code: "94",
    value: "F188",
    label: "MEZZOMERICO",
    province: "NO"
  },
  {
    code: "95",
    value: "F191",
    label: "MIASINO",
    province: "NO"
  },
  {
    code: "96",
    value: "F317",
    label: "MOMO",
    province: "NO"
  },
  {
    code: "97",
    value: "F859",
    label: "NEBBIUNO",
    province: "NO"
  },
  {
    code: "98",
    value: "F886",
    label: "NIBBIOLA",
    province: "NO"
  },
  {
    code: "99",
    value: "F952",
    label: "NOVARA",
    province: "NO"
  },
  {
    code: "100",
    value: "G019",
    label: "OLEGGIO",
    province: "NO"
  },
  {
    code: "101",
    value: "G020",
    label: "OLEGGIO CASTELLO",
    province: "NO"
  },
  {
    code: "102",
    value: "G134",
    label: "ORTA SAN GIULIO",
    province: "NO"
  },
  {
    code: "103",
    value: "G349",
    label: "PARUZZARO",
    province: "NO"
  },
  {
    code: "104",
    value: "G421",
    label: "PELLA",
    province: "NO"
  },
  {
    code: "105",
    value: "G520",
    label: "PETTENASCO",
    province: "NO"
  },
  {
    code: "106",
    value: "G703",
    label: "PISANO",
    province: "NO"
  },
  {
    code: "107",
    value: "G775",
    label: "POGNO",
    province: "NO"
  },
  {
    code: "108",
    value: "G809",
    label: "POMBIA",
    province: "NO"
  },
  {
    code: "109",
    value: "H001",
    label: "PRATO SESIA",
    province: "NO"
  },
  {
    code: "110",
    value: "H213",
    label: "RECETTO",
    province: "NO"
  },
  {
    code: "111",
    value: "H502",
    label: "ROMAGNANO SESIA",
    province: "NO"
  },
  {
    code: "112",
    value: "H518",
    label: "ROMENTINO",
    province: "NO"
  },
  {
    code: "113",
    value: "I025",
    label: "SAN MAURIZIO D OPAGLIO",
    province: "NO"
  },
  {
    code: "114",
    value: "I052",
    label: "SAN NAZZARO SESIA",
    province: "NO"
  },
  {
    code: "115",
    value: "I116",
    label: "SAN PIETRO MOSEZZO",
    province: "NO"
  },
  {
    code: "116",
    value: "I736",
    label: "SILLAVENGO",
    province: "NO"
  },
  {
    code: "117",
    value: "I767",
    label: "SIZZANO",
    province: "NO"
  },
  {
    code: "118",
    value: "I857",
    label: "SORISO",
    province: "NO"
  },
  {
    code: "119",
    value: "I880",
    label: "SOZZAGO",
    province: "NO"
  },
  {
    code: "120",
    value: "L007",
    label: "SUNO",
    province: "NO"
  },
  {
    code: "121",
    value: "L104",
    label: "TERDOBBIATE",
    province: "NO"
  },
  {
    code: "122",
    value: "L223",
    label: "TORNACO",
    province: "NO"
  },
  {
    code: "123",
    value: "L356",
    label: "TRECATE",
    province: "NO"
  },
  {
    code: "124",
    value: "L668",
    label: "VAPRIO D AGOGNA",
    province: "NO"
  },
  {
    code: "125",
    value: "L670",
    label: "VARALLO POMBIA",
    province: "NO"
  },
  {
    code: "127",
    value: "L808",
    label: "VESPOLATE",
    province: "NO"
  },
  {
    code: "128",
    value: "L847",
    label: "VICOLUNGO",
    province: "NO"
  },
  {
    code: "129",
    value: "M062",
    label: "VINZAGLIO",
    province: "NO"
  },
  {
    code: "130",
    value: "A016",
    label: "ACCEGLIO",
    province: "CN"
  },
  {
    code: "131",
    value: "A113",
    label: "AISONE",
    province: "CN"
  },
  {
    code: "132",
    value: "A124",
    label: "ALBA",
    province: "CN"
  },
  {
    code: "133",
    value: "A139",
    label: "ALBARETTO DELLA TORRE",
    province: "CN"
  },
  {
    code: "134",
    value: "A238",
    label: "ALTO",
    province: "CN"
  },
  {
    code: "135",
    value: "A394",
    label: "ARGENTERA",
    province: "CN"
  },
  {
    code: "136",
    value: "A396",
    label: "ARGUELLO",
    province: "CN"
  },
  {
    code: "137",
    value: "A555",
    label: "BAGNASCO",
    province: "CN"
  },
  {
    code: "138",
    value: "A571",
    label: "BAGNOLO PIEMONTE",
    province: "CN"
  },
  {
    code: "139",
    value: "A589",
    label: "BALDISSERO D ALBA",
    province: "CN"
  },
  {
    code: "140",
    value: "A629",
    label: "BARBARESCO",
    province: "CN"
  },
  {
    code: "141",
    value: "A660",
    label: "BARGE",
    province: "CN"
  },
  {
    code: "142",
    value: "A671",
    label: "BAROLO",
    province: "CN"
  },
  {
    code: "143",
    value: "A709",
    label: "BASTIA MONDOVI",
    province: "CN"
  },
  {
    code: "144",
    value: "A716",
    label: "BATTIFOLLO",
    province: "CN"
  },
  {
    code: "145",
    value: "A735",
    label: "BEINETTE",
    province: "CN"
  },
  {
    code: "146",
    value: "A750",
    label: "BELLINO",
    province: "CN"
  },
  {
    code: "147",
    value: "A774",
    label: "BELVEDERE LANGHE",
    province: "CN"
  },
  {
    code: "148",
    value: "A779",
    label: "BENE VAGIENNA",
    province: "CN"
  },
  {
    code: "149",
    value: "A782",
    label: "BENEVELLO",
    province: "CN"
  },
  {
    code: "150",
    value: "A798",
    label: "BERGOLO",
    province: "CN"
  },
  {
    code: "151",
    value: "A805",
    label: "BERNEZZO",
    province: "CN"
  },
  {
    code: "152",
    value: "A979",
    label: "BONVICINO",
    province: "CN"
  },
  {
    code: "153",
    value: "B018",
    label: "BORGOMALE",
    province: "CN"
  },
  {
    code: "154",
    value: "B033",
    label: "BORGO SAN DALMAZZO",
    province: "CN"
  },
  {
    code: "155",
    value: "B079",
    label: "BOSIA",
    province: "CN"
  },
  {
    code: "156",
    value: "B084",
    label: "BOSSOLASCO",
    province: "CN"
  },
  {
    code: "157",
    value: "B101",
    label: "BOVES",
    province: "CN"
  },
  {
    code: "158",
    value: "B111",
    label: "BRA",
    province: "CN"
  },
  {
    code: "159",
    value: "B167",
    label: "BRIAGLIA",
    province: "CN"
  },
  {
    code: "160",
    value: "B175",
    label: "BRIGA ALTA",
    province: "CN"
  },
  {
    code: "161",
    value: "B200",
    label: "BRONDELLO",
    province: "CN"
  },
  {
    code: "162",
    value: "B204",
    label: "BROSSASCO",
    province: "CN"
  },
  {
    code: "163",
    value: "B285",
    label: "BUSCA",
    province: "CN"
  },
  {
    code: "164",
    value: "B467",
    label: "CAMERANA",
    province: "CN"
  },
  {
    code: "166",
    value: "B573",
    label: "CANALE",
    province: "CN"
  },
  {
    code: "167",
    value: "B621",
    label: "CANOSIO",
    province: "CN"
  },
  {
    code: "168",
    value: "B692",
    label: "CAPRAUNA",
    province: "CN"
  },
  {
    code: "169",
    value: "B719",
    label: "CARAGLIO",
    province: "CN"
  },
  {
    code: "170",
    value: "B720",
    label: "CARAMAGNA PIEMONTE",
    province: "CN"
  },
  {
    code: "171",
    value: "B755",
    label: "CARDE",
    province: "CN"
  },
  {
    code: "172",
    value: "B841",
    label: "CARRU",
    province: "CN"
  },
  {
    code: "173",
    value: "B845",
    label: "CARTIGNANO",
    province: "CN"
  },
  {
    code: "174",
    value: "B894",
    label: "CASALGRASSO",
    province: "CN"
  },
  {
    code: "175",
    value: "C046",
    label: "CASTAGNITO",
    province: "CN"
  },
  {
    code: "176",
    value: "C081",
    label: "CASTELDELFINO",
    province: "CN"
  },
  {
    code: "178",
    value: "C165",
    label: "CASTELLETTO STURA",
    province: "CN"
  },
  {
    code: "179",
    value: "C167",
    label: "CASTELLETTO UZZONE",
    province: "CN"
  },
  {
    code: "180",
    value: "C173",
    label: "CASTELLINALDO D ALBA",
    province: "CN"
  },
  {
    code: "181",
    value: "C176",
    label: "CASTELLINO TANARO",
    province: "CN"
  },
  {
    code: "182",
    value: "C205",
    label: "CASTELMAGNO",
    province: "CN"
  },
  {
    code: "183",
    value: "C214",
    label: "CASTELNUOVO DI CEVA",
    province: "CN"
  },
  {
    code: "184",
    value: "C314",
    label: "CASTIGLIONE FALLETTO",
    province: "CN"
  },
  {
    code: "185",
    value: "C317",
    label: "CASTIGLIONE TINELLA",
    province: "CN"
  },
  {
    code: "186",
    value: "C323",
    label: "CASTINO",
    province: "CN"
  },
  {
    code: "187",
    value: "C375",
    label: "CAVALLERLEONE",
    province: "CN"
  },
  {
    code: "188",
    value: "C376",
    label: "CAVALLERMAGGIORE",
    province: "CN"
  },
  {
    code: "189",
    value: "C441",
    label: "CELLE DI MACRA",
    province: "CN"
  },
  {
    code: "190",
    value: "C466",
    label: "CENTALLO",
    province: "CN"
  },
  {
    code: "191",
    value: "C504",
    label: "CERESOLE ALBA",
    province: "CN"
  },
  {
    code: "192",
    value: "C530",
    label: "CERRETTO LANGHE",
    province: "CN"
  },
  {
    code: "193",
    value: "C547",
    label: "CERVASCA",
    province: "CN"
  },
  {
    code: "194",
    value: "C550",
    label: "CERVERE",
    province: "CN"
  },
  {
    code: "195",
    value: "C589",
    label: "CEVA",
    province: "CN"
  },
  {
    code: "196",
    value: "C599",
    label: "CHERASCO",
    province: "CN"
  },
  {
    code: "197",
    value: "C653",
    label: "CHIUSA DI PESIO",
    province: "CN"
  },
  {
    code: "198",
    value: "C681",
    label: "CIGLIE",
    province: "CN"
  },
  {
    code: "199",
    value: "C738",
    label: "CISSONE",
    province: "CN"
  },
  {
    code: "200",
    value: "C792",
    label: "CLAVESANA",
    province: "CN"
  },
  {
    code: "201",
    value: "D022",
    label: "CORNELIANO D ALBA",
    province: "CN"
  },
  {
    code: "202",
    value: "D062",
    label: "CORTEMILIA",
    province: "CN"
  },
  {
    code: "203",
    value: "D093",
    label: "COSSANO BELBO",
    province: "CN"
  },
  {
    code: "204",
    value: "D120",
    label: "COSTIGLIOLE SALUZZO",
    province: "CN"
  },
  {
    code: "205",
    value: "D133",
    label: "CRAVANZANA",
    province: "CN"
  },
  {
    code: "206",
    value: "D172",
    label: "CRISSOLO",
    province: "CN"
  },
  {
    code: "207",
    value: "D205",
    label: "CUNEO",
    province: "CN"
  },
  {
    code: "208",
    value: "D271",
    label: "DEMONTE",
    province: "CN"
  },
  {
    code: "209",
    value: "D291",
    label: "DIANO D ALBA",
    province: "CN"
  },
  {
    code: "210",
    value: "D314",
    label: "DOGLIANI",
    province: "CN"
  },
  {
    code: "211",
    value: "D372",
    label: "DRONERO",
    province: "CN"
  },
  {
    code: "212",
    value: "D401",
    label: "ELVA",
    province: "CN"
  },
  {
    code: "213",
    value: "D410",
    label: "ENTRACQUE",
    province: "CN"
  },
  {
    code: "214",
    value: "D412",
    label: "ENVIE",
    province: "CN"
  },
  {
    code: "215",
    value: "D499",
    label: "FARIGLIANO",
    province: "CN"
  },
  {
    code: "216",
    value: "D511",
    label: "FAULE",
    province: "CN"
  },
  {
    code: "217",
    value: "D523",
    label: "FEISOGLIO",
    province: "CN"
  },
  {
    code: "218",
    value: "D742",
    label: "FOSSANO",
    province: "CN"
  },
  {
    code: "219",
    value: "D751",
    label: "FRABOSA SOPRANA",
    province: "CN"
  },
  {
    code: "220",
    value: "D752",
    label: "FRABOSA SOTTANA",
    province: "CN"
  },
  {
    code: "221",
    value: "D782",
    label: "FRASSINO",
    province: "CN"
  },
  {
    code: "222",
    value: "D856",
    label: "GAIOLA",
    province: "CN"
  },
  {
    code: "223",
    value: "D894",
    label: "GAMBASCA",
    province: "CN"
  },
  {
    code: "224",
    value: "D920",
    label: "GARESSIO",
    province: "CN"
  },
  {
    code: "225",
    value: "D967",
    label: "GENOLA",
    province: "CN"
  },
  {
    code: "226",
    value: "E111",
    label: "GORZEGNO",
    province: "CN"
  },
  {
    code: "227",
    value: "E115",
    label: "GOTTASECCA",
    province: "CN"
  },
  {
    code: "228",
    value: "E118",
    label: "GOVONE",
    province: "CN"
  },
  {
    code: "229",
    value: "E182",
    label: "GRINZANE CAVOUR",
    province: "CN"
  },
  {
    code: "230",
    value: "E251",
    label: "GUARENE",
    province: "CN"
  },
  {
    code: "231",
    value: "E282",
    label: "IGLIANO",
    province: "CN"
  },
  {
    code: "232",
    value: "E327",
    label: "ISASCA",
    province: "CN"
  },
  {
    code: "233",
    value: "E406",
    label: "LAGNASCO",
    province: "CN"
  },
  {
    code: "234",
    value: "E430",
    label: "LA MORRA",
    province: "CN"
  },
  {
    code: "235",
    value: "E540",
    label: "LEQUIO BERRIA",
    province: "CN"
  },
  {
    code: "236",
    value: "E539",
    label: "LEQUIO TANARO",
    province: "CN"
  },
  {
    code: "237",
    value: "E546",
    label: "LESEGNO",
    province: "CN"
  },
  {
    code: "238",
    value: "E564",
    label: "LEVICE",
    province: "CN"
  },
  {
    code: "239",
    value: "E597",
    label: "LIMONE PIEMONTE",
    province: "CN"
  },
  {
    code: "240",
    value: "E615",
    label: "LISIO",
    province: "CN"
  },
  {
    code: "241",
    value: "E789",
    label: "MACRA",
    province: "CN"
  },
  {
    code: "242",
    value: "E809",
    label: "MAGLIANO ALFIERI",
    province: "CN"
  },
  {
    code: "243",
    value: "E808",
    label: "MAGLIANO ALPI",
    province: "CN"
  },
  {
    code: "244",
    value: "E887",
    label: "MANGO",
    province: "CN"
  },
  {
    code: "245",
    value: "E894",
    label: "MANTA",
    province: "CN"
  },
  {
    code: "246",
    value: "E939",
    label: "MARENE",
    province: "CN"
  },
  {
    code: "247",
    value: "E945",
    label: "MARGARITA",
    province: "CN"
  },
  {
    code: "248",
    value: "E963",
    label: "MARMORA",
    province: "CN"
  },
  {
    code: "249",
    value: "E973",
    label: "MARSAGLIA",
    province: "CN"
  },
  {
    code: "250",
    value: "E988",
    label: "MARTINIANA PO",
    province: "CN"
  },
  {
    code: "251",
    value: "F114",
    label: "MELLE",
    province: "CN"
  },
  {
    code: "252",
    value: "F279",
    label: "MOIOLA",
    province: "CN"
  },
  {
    code: "253",
    value: "F309",
    label: "MOMBARCARO",
    province: "CN"
  },
  {
    code: "254",
    value: "F312",
    label: "MOMBASIGLIO",
    province: "CN"
  },
  {
    code: "255",
    value: "F326",
    label: "MONASTERO DI VASCO",
    province: "CN"
  },
  {
    code: "256",
    value: "F329",
    label: "MONASTEROLO CASOTTO",
    province: "CN"
  },
  {
    code: "257",
    value: "F330",
    label: "MONASTEROLO DI SAVIGLIANO",
    province: "CN"
  },
  {
    code: "258",
    value: "F338",
    label: "MONCHIERO",
    province: "CN"
  },
  {
    code: "259",
    value: "F351",
    label: "MONDOVI",
    province: "CN"
  },
  {
    code: "260",
    value: "F355",
    label: "MONESIGLIO",
    province: "CN"
  },
  {
    code: "261",
    value: "F358",
    label: "MONFORTE D ALBA",
    province: "CN"
  },
  {
    code: "262",
    value: "F385",
    label: "MONTA",
    province: "CN"
  },
  {
    code: "263",
    value: "F405",
    label: "MONTALDO DI MONDOVI",
    province: "CN"
  },
  {
    code: "264",
    value: "F408",
    label: "MONTALDO ROERO",
    province: "CN"
  },
  {
    code: "265",
    value: "F424",
    label: "MONTANERA",
    province: "CN"
  },
  {
    code: "266",
    value: "F550",
    label: "MONTELUPO ALBESE",
    province: "CN"
  },
  {
    code: "267",
    value: "F558",
    label: "MONTEMALE DI CUNEO",
    province: "CN"
  },
  {
    code: "268",
    value: "F608",
    label: "MONTEROSSO GRANA",
    province: "CN"
  },
  {
    code: "269",
    value: "F654",
    label: "MONTEU ROERO",
    province: "CN"
  },
  {
    code: "270",
    value: "F666",
    label: "MONTEZEMOLO",
    province: "CN"
  },
  {
    code: "271",
    value: "F669",
    label: "MONTICELLO D ALBA",
    province: "CN"
  },
  {
    code: "272",
    value: "F723",
    label: "MORETTA",
    province: "CN"
  },
  {
    code: "273",
    value: "F743",
    label: "MOROZZO",
    province: "CN"
  },
  {
    code: "274",
    value: "F809",
    label: "MURAZZANO",
    province: "CN"
  },
  {
    code: "275",
    value: "F811",
    label: "MURELLO",
    province: "CN"
  },
  {
    code: "276",
    value: "F846",
    label: "NARZOLE",
    province: "CN"
  },
  {
    code: "277",
    value: "F863",
    label: "NEIVE",
    province: "CN"
  },
  {
    code: "278",
    value: "F883",
    label: "NEVIGLIE",
    province: "CN"
  },
  {
    code: "279",
    value: "F894",
    label: "NIELLA BELBO",
    province: "CN"
  },
  {
    code: "280",
    value: "F895",
    label: "NIELLA TANARO",
    province: "CN"
  },
  {
    code: "281",
    value: "F961",
    label: "NOVELLO",
    province: "CN"
  },
  {
    code: "282",
    value: "F972",
    label: "NUCETTO",
    province: "CN"
  },
  {
    code: "283",
    value: "G066",
    label: "ONCINO",
    province: "CN"
  },
  {
    code: "284",
    value: "G114",
    label: "ORMEA",
    province: "CN"
  },
  {
    code: "285",
    value: "G183",
    label: "OSTANA",
    province: "CN"
  },
  {
    code: "286",
    value: "G228",
    label: "PAESANA",
    province: "CN"
  },
  {
    code: "287",
    value: "G240",
    label: "PAGNO",
    province: "CN"
  },
  {
    code: "288",
    value: "G302",
    label: "PAMPARATO",
    province: "CN"
  },
  {
    code: "289",
    value: "G339",
    label: "PAROLDO",
    province: "CN"
  },
  {
    code: "290",
    value: "G457",
    label: "PERLETTO",
    province: "CN"
  },
  {
    code: "291",
    value: "G458",
    label: "PERLO",
    province: "CN"
  },
  {
    code: "292",
    value: "G526",
    label: "PEVERAGNO",
    province: "CN"
  },
  {
    code: "293",
    value: "G532",
    label: "PEZZOLO VALLE UZZONE",
    province: "CN"
  },
  {
    code: "294",
    value: "G561",
    label: "PIANFEI",
    province: "CN"
  },
  {
    code: "295",
    value: "G575",
    label: "PIASCO",
    province: "CN"
  },
  {
    code: "296",
    value: "G625",
    label: "PIETRAPORZIO",
    province: "CN"
  },
  {
    code: "297",
    value: "G683",
    label: "PIOBESI D ALBA",
    province: "CN"
  },
  {
    code: "298",
    value: "G697",
    label: "PIOZZO",
    province: "CN"
  },
  {
    code: "299",
    value: "G742",
    label: "POCAPAGLIA",
    province: "CN"
  },
  {
    code: "300",
    value: "G800",
    label: "POLONGHERA",
    province: "CN"
  },
  {
    code: "301",
    value: "G837",
    label: "PONTECHIANALE",
    province: "CN"
  },
  {
    code: "302",
    value: "G970",
    label: "PRADLEVES",
    province: "CN"
  },
  {
    code: "303",
    value: "A074",
    label: "AGLIE",
    province: "TO"
  },
  {
    code: "304",
    value: "A109",
    label: "AIRASCA",
    province: "TO"
  },
  {
    code: "305",
    value: "A117",
    label: "ALA DI STURA",
    province: "TO"
  },
  {
    code: "306",
    value: "A157",
    label: "ALBIANO D IVREA",
    province: "TO"
  },
  {
    code: "308",
    value: "A218",
    label: "ALMESE",
    province: "TO"
  },
  {
    code: "309",
    value: "A221",
    label: "ALPETTE",
    province: "TO"
  },
  {
    code: "353",
    value: "B592",
    label: "CANDIOLO",
    province: "TO"
  },
  {
    code: "354",
    value: "B605",
    label: "CANISCHIO",
    province: "TO"
  },
  {
    code: "355",
    value: "B628",
    label: "CANTALUPA",
    province: "TO"
  },
  {
    code: "356",
    value: "B637",
    label: "CANTOIRA",
    province: "TO"
  },
  {
    code: "357",
    value: "B705",
    label: "CAPRIE",
    province: "TO"
  },
  {
    code: "358",
    value: "B733",
    label: "CARAVINO",
    province: "TO"
  },
  {
    code: "359",
    value: "B762",
    label: "CAREMA",
    province: "TO"
  },
  {
    code: "360",
    value: "B777",
    label: "CARIGNANO",
    province: "TO"
  },
  {
    code: "361",
    value: "B791",
    label: "CARMAGNOLA",
    province: "TO"
  },
  {
    code: "362",
    value: "B867",
    label: "CASALBORGONE",
    province: "TO"
  },
  {
    code: "363",
    value: "B953",
    label: "CASCINETTE D IVREA",
    province: "TO"
  },
  {
    code: "364",
    value: "B955",
    label: "CASELETTE",
    province: "TO"
  },
  {
    code: "365",
    value: "B960",
    label: "CASELLE TORINESE",
    province: "TO"
  },
  {
    code: "366",
    value: "C045",
    label: "CASTAGNETO PO",
    province: "TO"
  },
  {
    code: "367",
    value: "C048",
    label: "CASTAGNOLE PIEMONTE",
    province: "TO"
  },
  {
    code: "368",
    value: "C133",
    label: "CASTELLAMONTE",
    province: "TO"
  },
  {
    code: "369",
    value: "C241",
    label: "CASTELNUOVO NIGRA",
    province: "TO"
  },
  {
    code: "370",
    value: "C307",
    label: "CASTIGLIONE TORINESE",
    province: "TO"
  },
  {
    code: "371",
    value: "C369",
    label: "CAVAGNOLO",
    province: "TO"
  },
  {
    code: "372",
    value: "C404",
    label: "CAVOUR",
    province: "TO"
  },
  {
    code: "373",
    value: "C487",
    label: "CERCENASCO",
    province: "TO"
  },
  {
    code: "374",
    value: "C497",
    label: "CERES",
    province: "TO"
  },
  {
    code: "375",
    value: "C505",
    label: "CERESOLE REALE",
    province: "TO"
  },
  {
    code: "376",
    value: "C564",
    label: "CESANA TORINESE",
    province: "TO"
  },
  {
    code: "377",
    value: "C604",
    label: "CHIALAMBERTO",
    province: "TO"
  },
  {
    code: "378",
    value: "C610",
    label: "CHIANOCCO",
    province: "TO"
  },
  {
    code: "379",
    value: "C624",
    label: "CHIAVERANO",
    province: "TO"
  },
  {
    code: "380",
    value: "C627",
    label: "CHIERI",
    province: "TO"
  },
  {
    code: "381",
    value: "C629",
    label: "CHIESANUOVA",
    province: "TO"
  },
  {
    code: "382",
    value: "C639",
    label: "CHIOMONTE",
    province: "TO"
  },
  {
    code: "383",
    value: "C655",
    label: "CHIUSA DI SAN MICHELE",
    province: "TO"
  },
  {
    code: "384",
    value: "C665",
    label: "CHIVASSO",
    province: "TO"
  },
  {
    code: "385",
    value: "C679",
    label: "CICONIO",
    province: "TO"
  },
  {
    code: "386",
    value: "C711",
    label: "CINTANO",
    province: "TO"
  },
  {
    code: "387",
    value: "C715",
    label: "CINZANO",
    province: "TO"
  },
  {
    code: "388",
    value: "C722",
    label: "CIRIE",
    province: "TO"
  },
  {
    code: "389",
    value: "C793",
    label: "CLAVIERE",
    province: "TO"
  },
  {
    code: "390",
    value: "C801",
    label: "COASSOLO TORINESE",
    province: "TO"
  },
  {
    code: "391",
    value: "C803",
    label: "COAZZE",
    province: "TO"
  },
  {
    code: "392",
    value: "C860",
    label: "COLLEGNO",
    province: "TO"
  },
  {
    code: "393",
    value: "C867",
    label: "COLLERETTO CASTELNUOVO",
    province: "TO"
  },
  {
    code: "394",
    value: "C868",
    label: "COLLERETTO GIACOSA",
    province: "TO"
  },
  {
    code: "395",
    value: "C955",
    label: "CONDOVE",
    province: "TO"
  },
  {
    code: "396",
    value: "D008",
    label: "CORIO",
    province: "TO"
  },
  {
    code: "397",
    value: "D092",
    label: "COSSANO CANAVESE",
    province: "TO"
  },
  {
    code: "398",
    value: "D197",
    label: "CUCEGLIO",
    province: "TO"
  },
  {
    code: "399",
    value: "D202",
    label: "CUMIANA",
    province: "TO"
  },
  {
    code: "400",
    value: "D208",
    label: "CUORGNE",
    province: "TO"
  },
  {
    code: "401",
    value: "D373",
    label: "DRUENTO",
    province: "TO"
  },
  {
    code: "402",
    value: "D433",
    label: "EXILLES",
    province: "TO"
  },
  {
    code: "403",
    value: "D520",
    label: "FAVRIA",
    province: "TO"
  },
  {
    code: "404",
    value: "D524",
    label: "FELETTO",
    province: "TO"
  },
  {
    code: "405",
    value: "D532",
    label: "FENESTRELLE",
    province: "TO"
  },
  {
    code: "406",
    value: "D562",
    label: "FIANO",
    province: "TO"
  },
  {
    code: "407",
    value: "D608",
    label: "FIORANO CANAVESE",
    province: "TO"
  },
  {
    code: "408",
    value: "D646",
    label: "FOGLIZZO",
    province: "TO"
  },
  {
    code: "409",
    value: "D725",
    label: "FORNO CANAVESE",
    province: "TO"
  },
  {
    code: "410",
    value: "D781",
    label: "FRASSINETTO",
    province: "TO"
  },
  {
    code: "411",
    value: "D805",
    label: "FRONT",
    province: "TO"
  },
  {
    code: "412",
    value: "D812",
    label: "FROSSASCO",
    province: "TO"
  },
  {
    code: "413",
    value: "D931",
    label: "GARZIGLIANA",
    province: "TO"
  },
  {
    code: "414",
    value: "D933",
    label: "GASSINO TORINESE",
    province: "TO"
  },
  {
    code: "415",
    value: "D983",
    label: "GERMAGNANO",
    province: "TO"
  },
  {
    code: "416",
    value: "E009",
    label: "GIAGLIONE",
    province: "TO"
  },
  {
    code: "417",
    value: "E020",
    label: "GIAVENO",
    province: "TO"
  },
  {
    code: "418",
    value: "E067",
    label: "GIVOLETTO",
    province: "TO"
  },
  {
    code: "419",
    value: "E154",
    label: "GRAVERE",
    province: "TO"
  },
  {
    code: "420",
    value: "E199",
    label: "GROSCAVALLO",
    province: "TO"
  },
  {
    code: "421",
    value: "E203",
    label: "GROSSO",
    province: "TO"
  },
  {
    code: "422",
    value: "E216",
    label: "GRUGLIASCO",
    province: "TO"
  },
  {
    code: "423",
    value: "E301",
    label: "INGRIA",
    province: "TO"
  },
  {
    code: "424",
    value: "E311",
    label: "INVERSO PINASCA",
    province: "TO"
  },
  {
    code: "425",
    value: "E345",
    label: "ISOLABELLA",
    province: "TO"
  },
  {
    code: "426",
    value: "E368",
    label: "ISSIGLIO",
    province: "TO"
  },
  {
    code: "427",
    value: "E379",
    label: "IVREA",
    province: "TO"
  },
  {
    code: "428",
    value: "E394",
    label: "LA CASSA",
    province: "TO"
  },
  {
    code: "429",
    value: "E423",
    label: "LA LOGGIA",
    province: "TO"
  },
  {
    code: "430",
    value: "E445",
    label: "LANZO TORINESE",
    province: "TO"
  },
  {
    code: "431",
    value: "E484",
    label: "LAURIANO",
    province: "TO"
  },
  {
    code: "432",
    value: "E518",
    label: "LEINI",
    province: "TO"
  },
  {
    code: "433",
    value: "E520",
    label: "LEMIE",
    province: "TO"
  },
  {
    code: "434",
    value: "E551",
    label: "LESSOLO",
    province: "TO"
  },
  {
    code: "435",
    value: "E566",
    label: "LEVONE",
    province: "TO"
  },
  {
    code: "436",
    value: "E635",
    label: "LOCANA",
    province: "TO"
  },
  {
    code: "437",
    value: "E660",
    label: "LOMBARDORE",
    province: "TO"
  },
  {
    code: "438",
    value: "E661",
    label: "LOMBRIASCO",
    province: "TO"
  },
  {
    code: "439",
    value: "E683",
    label: "LORANZE",
    province: "TO"
  },
  {
    code: "310",
    value: "A222",
    label: "ALPIGNANO",
    province: "TO"
  },
  {
    code: "311",
    value: "A275",
    label: "ANDEZENO",
    province: "TO"
  },
  {
    code: "312",
    value: "A282",
    label: "ANDRATE",
    province: "TO"
  },
  {
    code: "313",
    value: "A295",
    label: "ANGROGNA",
    province: "TO"
  },
  {
    code: "314",
    value: "A405",
    label: "ARIGNANO",
    province: "TO"
  },
  {
    code: "315",
    value: "A518",
    label: "AVIGLIANA",
    province: "TO"
  },
  {
    code: "316",
    value: "A525",
    label: "AZEGLIO",
    province: "TO"
  },
  {
    code: "317",
    value: "A584",
    label: "BAIRO",
    province: "TO"
  },
  {
    code: "318",
    value: "A587",
    label: "BALANGERO",
    province: "TO"
  },
  {
    code: "319",
    value: "A590",
    label: "BALDISSERO CANAVESE",
    province: "TO"
  },
  {
    code: "320",
    value: "A591",
    label: "BALDISSERO TORINESE",
    province: "TO"
  },
  {
    code: "321",
    value: "A599",
    label: "BALME",
    province: "TO"
  },
  {
    code: "322",
    value: "A607",
    label: "BANCHETTE",
    province: "TO"
  },
  {
    code: "323",
    value: "A625",
    label: "BARBANIA",
    province: "TO"
  },
  {
    code: "324",
    value: "A651",
    label: "BARDONECCHIA",
    province: "TO"
  },
  {
    code: "325",
    value: "A673",
    label: "BARONE CANAVESE",
    province: "TO"
  },
  {
    code: "326",
    value: "A734",
    label: "BEINASCO",
    province: "TO"
  },
  {
    code: "327",
    value: "A853",
    label: "BIBIANA",
    province: "TO"
  },
  {
    code: "328",
    value: "A910",
    label: "BOBBIO PELLICE",
    province: "TO"
  },
  {
    code: "329",
    value: "A941",
    label: "BOLLENGO",
    province: "TO"
  },
  {
    code: "330",
    value: "A990",
    label: "BORGARO TORINESE",
    province: "TO"
  },
  {
    code: "331",
    value: "B003",
    label: "BORGIALLO",
    province: "TO"
  },
  {
    code: "332",
    value: "B015",
    label: "BORGOFRANCO D IVREA",
    province: "TO"
  },
  {
    code: "333",
    value: "B021",
    label: "BORGOMASINO",
    province: "TO"
  },
  {
    code: "334",
    value: "B024",
    label: "BORGONE SUSA",
    province: "TO"
  },
  {
    code: "335",
    value: "B075",
    label: "BOSCONERO",
    province: "TO"
  },
  {
    code: "336",
    value: "B121",
    label: "BRANDIZZO",
    province: "TO"
  },
  {
    code: "337",
    value: "B171",
    label: "BRICHERASIO",
    province: "TO"
  },
  {
    code: "338",
    value: "B205",
    label: "BROSSO",
    province: "TO"
  },
  {
    code: "339",
    value: "B209",
    label: "BROZOLO",
    province: "TO"
  },
  {
    code: "340",
    value: "B216",
    label: "BRUINO",
    province: "TO"
  },
  {
    code: "341",
    value: "B225",
    label: "BRUSASCO",
    province: "TO"
  },
  {
    code: "342",
    value: "B232",
    label: "BRUZOLO",
    province: "TO"
  },
  {
    code: "343",
    value: "B278",
    label: "BURIASCO",
    province: "TO"
  },
  {
    code: "344",
    value: "B279",
    label: "BUROLO",
    province: "TO"
  },
  {
    code: "345",
    value: "B284",
    label: "BUSANO",
    province: "TO"
  },
  {
    code: "346",
    value: "B297",
    label: "BUSSOLENO",
    province: "TO"
  },
  {
    code: "347",
    value: "B305",
    label: "BUTTIGLIERA ALTA",
    province: "TO"
  },
  {
    code: "348",
    value: "B350",
    label: "CAFASSE",
    province: "TO"
  },
  {
    code: "349",
    value: "B435",
    label: "CALUSO",
    province: "TO"
  },
  {
    code: "350",
    value: "B462",
    label: "CAMBIANO",
    province: "TO"
  },
  {
    code: "351",
    value: "B512",
    label: "CAMPIGLIONE FENILE",
    province: "TO"
  },
  {
    code: "352",
    value: "B588",
    label: "CANDIA CANAVESE",
    province: "TO"
  },
  {
    code: "2076",
    value: "L655",
    label: "VALTORTA",
    province: "BG"
  },
  {
    code: "2077",
    value: "L707",
    label: "VEDESETA",
    province: "BG"
  },
  {
    code: "2078",
    value: "L752",
    label: "VERDELLINO",
    province: "BG"
  },
  {
    code: "2079",
    value: "L753",
    label: "VERDELLO",
    province: "BG"
  },
  {
    code: "2080",
    value: "L795",
    label: "VERTOVA",
    province: "BG"
  },
  {
    code: "2081",
    value: "L827",
    label: "VIADANICA",
    province: "BG"
  },
  {
    code: "2082",
    value: "L865",
    label: "VIGANO SAN MARTINO",
    province: "BG"
  },
  {
    code: "2083",
    value: "L894",
    label: "VIGOLO",
    province: "BG"
  },
  {
    code: "2084",
    value: "L929",
    label: "VILLA D ADDA",
    province: "BG"
  },
  {
    code: "2085",
    value: "A215",
    label: "VILLA D ALME",
    province: "BG"
  },
  {
    code: "2086",
    value: "L936",
    label: "VILLA DI SERIO",
    province: "BG"
  },
  {
    code: "2087",
    value: "L938",
    label: "VILLA D OGNA",
    province: "BG"
  },
  {
    code: "2088",
    value: "M045",
    label: "VILLONGO",
    province: "BG"
  },
  {
    code: "2089",
    value: "M050",
    label: "VILMINORE DI SCALVE",
    province: "BG"
  },
  {
    code: "2090",
    value: "M144",
    label: "ZANDOBBIO",
    province: "BG"
  },
  {
    code: "2091",
    value: "M147",
    label: "ZANICA",
    province: "BG"
  },
  {
    code: "2092",
    value: "M184",
    label: "ZOGNO",
    province: "BG"
  },
  {
    code: "2093",
    value: "D111",
    label: "COSTA SERINA",
    province: "BG"
  },
  {
    code: "2094",
    value: "A193",
    label: "ALGUA",
    province: "BG"
  },
  {
    code: "2095",
    value: "D016",
    label: "CORNALBA",
    province: "BG"
  },
  {
    code: "2096",
    value: "F085",
    label: "MEDOLAGO",
    province: "BG"
  },
  {
    code: "2097",
    value: "I813",
    label: "SOLZA",
    province: "BG"
  },
  {
    code: "2584",
    value: "I007",
    label: "SAN MARTINO DEL LAGO",
    province: "CR"
  },
  {
    code: "2585",
    value: "I497",
    label: "SCANDOLARA RAVARA",
    province: "CR"
  },
  {
    code: "2586",
    value: "I498",
    label: "SCANDOLARA RIPA D OGLIO",
    province: "CR"
  },
  {
    code: "2587",
    value: "I627",
    label: "SERGNANO",
    province: "CR"
  },
  {
    code: "2588",
    value: "I683",
    label: "SESTO ED UNITI",
    province: "CR"
  },
  {
    code: "2589",
    value: "I790",
    label: "SOLAROLO RAINERIO",
    province: "CR"
  },
  {
    code: "2590",
    value: "I827",
    label: "SONCINO",
    province: "CR"
  },
  {
    code: "2591",
    value: "I849",
    label: "SORESINA",
    province: "CR"
  },
  {
    code: "2592",
    value: "I865",
    label: "SOSPIRO",
    province: "CR"
  },
  {
    code: "2593",
    value: "I906",
    label: "SPINADESCO",
    province: "CR"
  },
  {
    code: "2594",
    value: "I909",
    label: "SPINEDA",
    province: "CR"
  },
  {
    code: "2595",
    value: "I914",
    label: "SPINO D ADDA",
    province: "CR"
  },
  {
    code: "2596",
    value: "I935",
    label: "STAGNO LOMBARDO",
    province: "CR"
  },
  {
    code: "2597",
    value: "L164",
    label: "TICENGO",
    province: "CR"
  },
  {
    code: "2598",
    value: "L221",
    label: "TORLINO VIMERCATI",
    province: "CR"
  },
  {
    code: "2599",
    value: "L225",
    label: "TORNATA",
    province: "CR"
  },
  {
    code: "2600",
    value: "L258",
    label: "TORRE DE PICENARDI",
    province: "CR"
  },
  {
    code: "2601",
    value: "L296",
    label: "TORRICELLA DEL PIZZO",
    province: "CR"
  },
  {
    code: "2602",
    value: "L389",
    label: "TRESCORE CREMASCO",
    province: "CR"
  },
  {
    code: "2603",
    value: "L426",
    label: "TRIGOLO",
    province: "CR"
  },
  {
    code: "2604",
    value: "L535",
    label: "VAIANO CREMASCO",
    province: "CR"
  },
  {
    code: "2605",
    value: "L539",
    label: "VAILATE",
    province: "CR"
  },
  {
    code: "2606",
    value: "L806",
    label: "VESCOVATO",
    province: "CR"
  },
  {
    code: "2607",
    value: "M116",
    label: "VOLONGO",
    province: "CR"
  },
  {
    code: "2608",
    value: "M127",
    label: "VOLTIDO",
    province: "CR"
  },
  {
    code: "2609",
    value: "A038",
    label: "ACQUANEGRA SUL CHIESE",
    province: "MN"
  },
  {
    code: "2610",
    value: "A470",
    label: "ASOLA",
    province: "MN"
  },
  {
    code: "2611",
    value: "A575",
    label: "BAGNOLO SAN VITO",
    province: "MN"
  },
  {
    code: "2615",
    value: "B110",
    label: "BOZZOLO",
    province: "MN"
  },
  {
    code: "2616",
    value: "B612",
    label: "CANNETO SULL OGLIO",
    province: "MN"
  },
  {
    code: "2618",
    value: "B901",
    label: "CASALMORO",
    province: "MN"
  },
  {
    code: "2619",
    value: "B907",
    label: "CASALOLDO",
    province: "MN"
  },
  {
    code: "2620",
    value: "B911",
    label: "CASALROMANO",
    province: "MN"
  },
  {
    code: "2621",
    value: "C059",
    label: "CASTELBELFORTE",
    province: "MN"
  },
  {
    code: "2622",
    value: "C076",
    label: "CASTEL D ARIO",
    province: "MN"
  },
  {
    code: "2623",
    value: "C118",
    label: "CASTEL GOFFREDO",
    province: "MN"
  },
  {
    code: "2624",
    value: "C195",
    label: "CASTELLUCCHIO",
    province: "MN"
  },
  {
    code: "2625",
    value: "C312",
    label: "CASTIGLIONE DELLE STIVIERE",
    province: "MN"
  },
  {
    code: "2626",
    value: "C406",
    label: "CAVRIANA",
    province: "MN"
  },
  {
    code: "2627",
    value: "C502",
    label: "CERESARA",
    province: "MN"
  },
  {
    code: "3216",
    value: "F218",
    label: "MINERBE",
    province: "VR"
  },
  {
    code: "3217",
    value: "F461",
    label: "MONTECCHIA DI CROSARA",
    province: "VR"
  },
  {
    code: "3218",
    value: "F508",
    label: "MONTEFORTE D ALPONE",
    province: "VR"
  },
  {
    code: "3219",
    value: "F789",
    label: "MOZZECANE",
    province: "VR"
  },
  {
    code: "3220",
    value: "F861",
    label: "NEGRAR DI VALPOLICELLA",
    province: "VR"
  },
  {
    code: "3221",
    value: "F918",
    label: "NOGARA",
    province: "VR"
  },
  {
    code: "3222",
    value: "F921",
    label: "NOGAROLE ROCCA",
    province: "VR"
  },
  {
    code: "3223",
    value: "G080",
    label: "OPPEANO",
    province: "VR"
  },
  {
    code: "3224",
    value: "G297",
    label: "PALU",
    province: "VR"
  },
  {
    code: "3225",
    value: "G365",
    label: "PASTRENGO",
    province: "VR"
  },
  {
    code: "3226",
    value: "G481",
    label: "PESCANTINA",
    province: "VR"
  },
  {
    code: "3227",
    value: "G489",
    label: "PESCHIERA DEL GARDA",
    province: "VR"
  },
  {
    code: "3228",
    value: "G945",
    label: "POVEGLIANO VERONESE",
    province: "VR"
  },
  {
    code: "3229",
    value: "H048",
    label: "PRESSANA",
    province: "VR"
  },
  {
    code: "3230",
    value: "H356",
    label: "RIVOLI VERONESE",
    province: "VR"
  },
  {
    code: "3231",
    value: "H522",
    label: "RONCA",
    province: "VR"
  },
  {
    code: "3232",
    value: "H540",
    label: "RONCO ALL ADIGE",
    province: "VR"
  },
  {
    code: "3233",
    value: "H606",
    label: "ROVERCHIARA",
    province: "VR"
  },
  {
    code: "3234",
    value: "H610",
    label: "ROVEREDO DI GUA",
    province: "VR"
  },
  {
    code: "3235",
    value: "H608",
    label: "ROVERE VERONESE",
    province: "VR"
  },
  {
    code: "3236",
    value: "H714",
    label: "SALIZZOLE",
    province: "VR"
  },
  {
    code: "3237",
    value: "H783",
    label: "SAN BONIFACIO",
    province: "VR"
  },
  {
    code: "3238",
    value: "H916",
    label: "SAN GIOVANNI ILARIONE",
    province: "VR"
  },
  {
    code: "3239",
    value: "H924",
    label: "SAN GIOVANNI LUPATOTO",
    province: "VR"
  },
  {
    code: "3240",
    value: "H944",
    label: "SANGUINETTO",
    province: "VR"
  },
  {
    code: "3241",
    value: "I003",
    label: "SAN MARTINO BUON ALBERGO",
    province: "VR"
  },
  {
    code: "3242",
    value: "H712",
    label: "SAN MAURO DI SALINE",
    province: "VR"
  },
  {
    code: "3243",
    value: "I105",
    label: "SAN PIETRO DI MORUBIO",
    province: "VR"
  },
  {
    code: "3244",
    value: "I109",
    label: "SAN PIETRO IN CARIANO",
    province: "VR"
  },
  {
    code: "3245",
    value: "I259",
    label: "SANT AMBROGIO DI VALPOLICELLA",
    province: "VR"
  },
  {
    code: "3246",
    value: "I292",
    label: "SANT ANNA D ALFAEDO",
    province: "VR"
  },
  {
    code: "3247",
    value: "I414",
    label: "SAN ZENO DI MONTAGNA",
    province: "VR"
  },
  {
    code: "3248",
    value: "I594",
    label: "SELVA DI PROGNO",
    province: "VR"
  },
  {
    code: "3249",
    value: "I775",
    label: "SOAVE",
    province: "VR"
  },
  {
    code: "3250",
    value: "I821",
    label: "SOMMACAMPAGNA",
    province: "VR"
  },
  {
    code: "3251",
    value: "I826",
    label: "SONA",
    province: "VR"
  },
  {
    code: "3252",
    value: "I850",
    label: "SORGA",
    province: "VR"
  },
  {
    code: "3253",
    value: "L136",
    label: "TERRAZZO",
    province: "VR"
  },
  {
    code: "3254",
    value: "L287",
    label: "TORRI DEL BENACO",
    province: "VR"
  },
  {
    code: "3255",
    value: "L364",
    label: "TREGNAGO",
    province: "VR"
  },
  {
    code: "3256",
    value: "L396",
    label: "TREVENZUOLO",
    province: "VR"
  },
  {
    code: "3257",
    value: "L567",
    label: "VALEGGIO SUL MINCIO",
    province: "VR"
  },
  {
    code: "3258",
    value: "L722",
    label: "VELO VERONESE",
    province: "VR"
  },
  {
    code: "3259",
    value: "L781",
    label: "VERONA",
    province: "VR"
  },
  {
    code: "3260",
    value: "D193",
    label: "VERONELLA",
    province: "VR"
  },
  {
    code: "3261",
    value: "L810",
    label: "VESTENANOVA",
    province: "VR"
  },
  {
    code: "3262",
    value: "L869",
    label: "VIGASIO",
    province: "VR"
  },
  {
    code: "3263",
    value: "L912",
    label: "VILLA BARTOLOMEA",
    province: "VR"
  },
  {
    code: "3264",
    value: "L949",
    label: "VILLAFRANCA DI VERONA",
    province: "VR"
  },
  {
    code: "3265",
    value: "M172",
    label: "ZEVIO",
    province: "VR"
  },
  {
    code: "3266",
    value: "M178",
    label: "ZIMELLA",
    province: "VR"
  },
  {
    code: "3267",
    value: "A093",
    label: "AGUGLIARO",
    province: "VI"
  },
  {
    code: "3268",
    value: "A154",
    label: "ALBETTONE",
    province: "VI"
  },
  {
    code: "3269",
    value: "A220",
    label: "ALONTE",
    province: "VI"
  },
  {
    code: "3270",
    value: "A231",
    label: "ALTAVILLA VICENTINA",
    province: "VI"
  },
  {
    code: "3271",
    value: "A236",
    label: "ALTISSIMO",
    province: "VI"
  },
  {
    code: "3272",
    value: "A377",
    label: "ARCUGNANO",
    province: "VI"
  },
  {
    code: "3273",
    value: "A444",
    label: "ARSIERO",
    province: "VI"
  },
  {
    code: "3274",
    value: "A459",
    label: "ARZIGNANO",
    province: "VI"
  },
  {
    code: "441",
    value: "E758",
    label: "LUSERNA SAN GIOVANNI",
    province: "TO"
  },
  {
    code: "442",
    value: "E759",
    label: "LUSERNETTA",
    province: "TO"
  },
  {
    code: "443",
    value: "E763",
    label: "LUSIGLIE",
    province: "TO"
  },
  {
    code: "444",
    value: "E782",
    label: "MACELLO",
    province: "TO"
  },
  {
    code: "445",
    value: "E817",
    label: "MAGLIONE",
    province: "TO"
  },
  {
    code: "446",
    value: "E941",
    label: "MARENTINO",
    province: "TO"
  },
  {
    code: "447",
    value: "F041",
    label: "MASSELLO",
    province: "TO"
  },
  {
    code: "448",
    value: "F053",
    label: "MATHI",
    province: "TO"
  },
  {
    code: "449",
    value: "F058",
    label: "MATTIE",
    province: "TO"
  },
  {
    code: "450",
    value: "F067",
    label: "MAZZE",
    province: "TO"
  },
  {
    code: "451",
    value: "F074",
    label: "MEANA DI SUSA",
    province: "TO"
  },
  {
    code: "452",
    value: "F140",
    label: "MERCENASCO",
    province: "TO"
  },
  {
    code: "454",
    value: "F182",
    label: "MEZZENILE",
    province: "TO"
  },
  {
    code: "455",
    value: "F315",
    label: "MOMBELLO DI TORINO",
    province: "TO"
  },
  {
    code: "456",
    value: "F318",
    label: "MOMPANTERO",
    province: "TO"
  },
  {
    code: "457",
    value: "F327",
    label: "MONASTERO DI LANZO",
    province: "TO"
  },
  {
    code: "458",
    value: "F335",
    label: "MONCALIERI",
    province: "TO"
  },
  {
    code: "459",
    value: "D553",
    label: "MONCENISIO",
    province: "TO"
  },
  {
    code: "460",
    value: "F407",
    label: "MONTALDO TORINESE",
    province: "TO"
  },
  {
    code: "461",
    value: "F411",
    label: "MONTALENGHE",
    province: "TO"
  },
  {
    code: "462",
    value: "F420",
    label: "MONTALTO DORA",
    province: "TO"
  },
  {
    code: "463",
    value: "F422",
    label: "MONTANARO",
    province: "TO"
  },
  {
    code: "464",
    value: "F651",
    label: "MONTEU DA PO",
    province: "TO"
  },
  {
    code: "465",
    value: "F733",
    label: "MORIONDO TORINESE",
    province: "TO"
  },
  {
    code: "466",
    value: "F889",
    label: "NICHELINO",
    province: "TO"
  },
  {
    code: "467",
    value: "F906",
    label: "NOASCA",
    province: "TO"
  },
  {
    code: "468",
    value: "F925",
    label: "NOLE",
    province: "TO"
  },
  {
    code: "469",
    value: "F927",
    label: "NOMAGLIO",
    province: "TO"
  },
  {
    code: "470",
    value: "F931",
    label: "NONE",
    province: "TO"
  },
  {
    code: "471",
    value: "F948",
    label: "NOVALESA",
    province: "TO"
  },
  {
    code: "472",
    value: "G010",
    label: "OGLIANICO",
    province: "TO"
  },
  {
    code: "473",
    value: "G087",
    label: "ORBASSANO",
    province: "TO"
  },
  {
    code: "474",
    value: "G109",
    label: "ORIO CANAVESE",
    province: "TO"
  },
  {
    code: "475",
    value: "G151",
    label: "OSASCO",
    province: "TO"
  },
  {
    code: "476",
    value: "G152",
    label: "OSASIO",
    province: "TO"
  },
  {
    code: "477",
    value: "G196",
    label: "OULX",
    province: "TO"
  },
  {
    code: "478",
    value: "G202",
    label: "OZEGNA",
    province: "TO"
  },
  {
    code: "479",
    value: "G262",
    label: "PALAZZO CANAVESE",
    province: "TO"
  },
  {
    code: "480",
    value: "G303",
    label: "PANCALIERI",
    province: "TO"
  },
  {
    code: "481",
    value: "G330",
    label: "PARELLA",
    province: "TO"
  },
  {
    code: "482",
    value: "G387",
    label: "PAVAROLO",
    province: "TO"
  },
  {
    code: "483",
    value: "G392",
    label: "PAVONE CANAVESE",
    province: "TO"
  },
  {
    code: "485",
    value: "G398",
    label: "PECETTO TORINESE",
    province: "TO"
  },
  {
    code: "486",
    value: "G463",
    label: "PEROSA ARGENTINA",
    province: "TO"
  },
  {
    code: "487",
    value: "G462",
    label: "PEROSA CANAVESE",
    province: "TO"
  },
  {
    code: "488",
    value: "G465",
    label: "PERRERO",
    province: "TO"
  },
  {
    code: "489",
    value: "G477",
    label: "PERTUSIO",
    province: "TO"
  },
  {
    code: "490",
    value: "G505",
    label: "PESSINETTO",
    province: "TO"
  },
  {
    code: "491",
    value: "G559",
    label: "PIANEZZA",
    province: "TO"
  },
  {
    code: "492",
    value: "G672",
    label: "PINASCA",
    province: "TO"
  },
  {
    code: "493",
    value: "G674",
    label: "PINEROLO",
    province: "TO"
  },
  {
    code: "494",
    value: "G678",
    label: "PINO TORINESE",
    province: "TO"
  },
  {
    code: "495",
    value: "G684",
    label: "PIOBESI TORINESE",
    province: "TO"
  },
  {
    code: "496",
    value: "G691",
    label: "PIOSSASCO",
    province: "TO"
  },
  {
    code: "497",
    value: "G705",
    label: "PISCINA",
    province: "TO"
  },
  {
    code: "498",
    value: "G719",
    label: "PIVERONE",
    province: "TO"
  },
  {
    code: "499",
    value: "G777",
    label: "POIRINO",
    province: "TO"
  },
  {
    code: "500",
    value: "G805",
    label: "POMARETTO",
    province: "TO"
  },
  {
    code: "501",
    value: "G826",
    label: "PONT CANAVESE",
    province: "TO"
  },
  {
    code: "502",
    value: "G900",
    label: "PORTE",
    province: "TO"
  },
  {
    code: "503",
    value: "G973",
    label: "PRAGELATO",
    province: "TO"
  },
  {
    code: "504",
    value: "G978",
    label: "PRALI",
    province: "TO"
  },
  {
    code: "505",
    value: "G979",
    label: "PRALORMO",
    province: "TO"
  },
  {
    code: "506",
    value: "G982",
    label: "PRAMOLLO",
    province: "TO"
  },
  {
    code: "507",
    value: "G986",
    label: "PRAROSTINO",
    province: "TO"
  },
  {
    code: "508",
    value: "G988",
    label: "PRASCORSANO",
    province: "TO"
  },
  {
    code: "509",
    value: "G997",
    label: "PRATIGLIONE",
    province: "TO"
  },
  {
    code: "510",
    value: "H100",
    label: "QUAGLIUZZO",
    province: "TO"
  },
  {
    code: "511",
    value: "H120",
    label: "QUASSOLO",
    province: "TO"
  },
  {
    code: "512",
    value: "H127",
    label: "QUINCINETTO",
    province: "TO"
  },
  {
    code: "513",
    value: "H207",
    label: "REANO",
    province: "TO"
  },
  {
    code: "514",
    value: "H270",
    label: "RIBORDONE",
    province: "TO"
  },
  {
    code: "515",
    value: "H333",
    label: "RIVALBA",
    province: "TO"
  },
  {
    code: "516",
    value: "H335",
    label: "RIVALTA DI TORINO",
    province: "TO"
  },
  {
    code: "517",
    value: "H337",
    label: "RIVA PRESSO CHIERI",
    province: "TO"
  },
  {
    code: "518",
    value: "H338",
    label: "RIVARA",
    province: "TO"
  },
  {
    code: "519",
    value: "H340",
    label: "RIVAROLO CANAVESE",
    province: "TO"
  },
  {
    code: "520",
    value: "H344",
    label: "RIVAROSSA",
    province: "TO"
  },
  {
    code: "521",
    value: "H355",
    label: "RIVOLI",
    province: "TO"
  },
  {
    code: "522",
    value: "H367",
    label: "ROBASSOMERO",
    province: "TO"
  },
  {
    code: "523",
    value: "H386",
    label: "ROCCA CANAVESE",
    province: "TO"
  },
  {
    code: "524",
    value: "H498",
    label: "ROLETTO",
    province: "TO"
  },
  {
    code: "525",
    value: "H511",
    label: "ROMANO CANAVESE",
    province: "TO"
  },
  {
    code: "526",
    value: "H539",
    label: "RONCO CANAVESE",
    province: "TO"
  },
  {
    code: "527",
    value: "H547",
    label: "RONDISSONE",
    province: "TO"
  },
  {
    code: "528",
    value: "H554",
    label: "RORA",
    province: "TO"
  },
  {
    code: "529",
    value: "H555",
    label: "ROURE",
    province: "TO"
  },
  {
    code: "530",
    value: "H583",
    label: "ROSTA",
    province: "TO"
  },
  {
    code: "531",
    value: "H627",
    label: "RUBIANA",
    province: "TO"
  },
  {
    code: "532",
    value: "H631",
    label: "RUEGLIO",
    province: "TO"
  },
  {
    code: "533",
    value: "H691",
    label: "SALASSA",
    province: "TO"
  },
  {
    code: "534",
    value: "H684",
    label: "SALBERTRAND",
    province: "TO"
  },
  {
    code: "535",
    value: "H702",
    label: "SALERANO CANAVESE",
    province: "TO"
  },
  {
    code: "536",
    value: "H734",
    label: "SALZA DI PINEROLO",
    province: "TO"
  },
  {
    code: "537",
    value: "H753",
    label: "SAMONE",
    province: "TO"
  },
  {
    code: "538",
    value: "H775",
    label: "SAN BENIGNO CANAVESE",
    province: "TO"
  },
  {
    code: "539",
    value: "H789",
    label: "SAN CARLO CANAVESE",
    province: "TO"
  },
  {
    code: "540",
    value: "H804",
    label: "SAN COLOMBANO BELMONTE",
    province: "TO"
  },
  {
    code: "541",
    value: "H820",
    label: "SAN DIDERO",
    province: "TO"
  },
  {
    code: "542",
    value: "H847",
    label: "SAN FRANCESCO AL CAMPO",
    province: "TO"
  },
  {
    code: "543",
    value: "H855",
    label: "SANGANO",
    province: "TO"
  },
  {
    code: "544",
    value: "H862",
    label: "SAN GERMANO CHISONE",
    province: "TO"
  },
  {
    code: "545",
    value: "H873",
    label: "SAN GILLIO",
    province: "TO"
  },
  {
    code: "546",
    value: "H890",
    label: "SAN GIORGIO CANAVESE",
    province: "TO"
  },
  {
    code: "547",
    value: "H900",
    label: "SAN GIORIO DI SUSA",
    province: "TO"
  },
  {
    code: "548",
    value: "H936",
    label: "SAN GIUSTO CANAVESE",
    province: "TO"
  },
  {
    code: "549",
    value: "H997",
    label: "SAN MARTINO CANAVESE",
    province: "TO"
  },
  {
    code: "550",
    value: "I024",
    label: "SAN MAURIZIO CANAVESE",
    province: "TO"
  },
  {
    code: "551",
    value: "I030",
    label: "SAN MAURO TORINESE",
    province: "TO"
  },
  {
    code: "552",
    value: "I090",
    label: "SAN PIETRO VAL LEMINA",
    province: "TO"
  },
  {
    code: "553",
    value: "I126",
    label: "SAN PONSO",
    province: "TO"
  },
  {
    code: "554",
    value: "I137",
    label: "SAN RAFFAELE CIMENA",
    province: "TO"
  },
  {
    code: "555",
    value: "I152",
    label: "SAN SEBASTIANO DA PO",
    province: "TO"
  },
  {
    code: "556",
    value: "I154",
    label: "SAN SECONDO DI PINEROLO",
    province: "TO"
  },
  {
    code: "557",
    value: "I258",
    label: "SANT AMBROGIO DI TORINO",
    province: "TO"
  },
  {
    code: "558",
    value: "I296",
    label: "SANT ANTONINO DI SUSA",
    province: "TO"
  },
  {
    code: "559",
    value: "I327",
    label: "SANTENA",
    province: "TO"
  },
  {
    code: "560",
    value: "I465",
    label: "SAUZE DI CESANA",
    province: "TO"
  },
  {
    code: "561",
    value: "I466",
    label: "SAUZE D OULX",
    province: "TO"
  },
  {
    code: "562",
    value: "I490",
    label: "SCALENGHE",
    province: "TO"
  },
  {
    code: "563",
    value: "I511",
    label: "SCARMAGNO",
    province: "TO"
  },
  {
    code: "564",
    value: "I539",
    label: "SCIOLZE",
    province: "TO"
  },
  {
    code: "565",
    value: "I692",
    label: "SESTRIERE",
    province: "TO"
  },
  {
    code: "566",
    value: "I701",
    label: "SETTIMO ROTTARO",
    province: "TO"
  },
  {
    code: "567",
    value: "I703",
    label: "SETTIMO TORINESE",
    province: "TO"
  },
  {
    code: "568",
    value: "I702",
    label: "SETTIMO VITTONE",
    province: "TO"
  },
  {
    code: "569",
    value: "I886",
    label: "SPARONE",
    province: "TO"
  },
  {
    code: "570",
    value: "I969",
    label: "STRAMBINELLO",
    province: "TO"
  },
  {
    code: "571",
    value: "I970",
    label: "STRAMBINO",
    province: "TO"
  },
  {
    code: "572",
    value: "L013",
    label: "SUSA",
    province: "TO"
  },
  {
    code: "573",
    value: "L066",
    label: "TAVAGNASCO",
    province: "TO"
  },
  {
    code: "574",
    value: "L219",
    label: "TORINO",
    province: "TO"
  },
  {
    code: "575",
    value: "L238",
    label: "TORRAZZA PIEMONTE",
    province: "TO"
  },
  {
    code: "576",
    value: "L247",
    label: "TORRE CANAVESE",
    province: "TO"
  },
  {
    code: "577",
    value: "L277",
    label: "TORRE PELLICE",
    province: "TO"
  },
  {
    code: "578",
    value: "L327",
    label: "TRANA",
    province: "TO"
  },
  {
    code: "580",
    value: "L345",
    label: "TRAVERSELLA",
    province: "TO"
  },
  {
    code: "581",
    value: "L340",
    label: "TRAVES",
    province: "TO"
  },
  {
    code: "582",
    value: "L445",
    label: "TROFARELLO",
    province: "TO"
  },
  {
    code: "583",
    value: "L515",
    label: "USSEAUX",
    province: "TO"
  },
  {
    code: "584",
    value: "L516",
    label: "USSEGLIO",
    province: "TO"
  },
  {
    code: "585",
    value: "L538",
    label: "VAIE",
    province: "TO"
  },
  {
    code: "586",
    value: "L555",
    label: "VAL DELLA TORRE",
    province: "TO"
  },
  {
    code: "587",
    value: "L578",
    label: "VALGIOIE",
    province: "TO"
  },
  {
    code: "588",
    value: "L629",
    label: "VALLO TORINESE",
    province: "TO"
  },
  {
    code: "589",
    value: "L644",
    label: "VALPERGA",
    province: "TO"
  },
  {
    code: "590",
    value: "B510",
    label: "VALPRATO SOANA",
    province: "TO"
  },
  {
    code: "591",
    value: "L685",
    label: "VARISELLA",
    province: "TO"
  },
  {
    code: "592",
    value: "L698",
    label: "VAUDA CANAVESE",
    province: "TO"
  },
  {
    code: "593",
    value: "L726",
    label: "VENAUS",
    province: "TO"
  },
  {
    code: "594",
    value: "L727",
    label: "VENARIA REALE",
    province: "TO"
  },
  {
    code: "595",
    value: "L779",
    label: "VEROLENGO",
    province: "TO"
  },
  {
    code: "596",
    value: "L787",
    label: "VERRUA SAVOIA",
    province: "TO"
  },
  {
    code: "597",
    value: "L811",
    label: "VESTIGNE",
    province: "TO"
  },
  {
    code: "598",
    value: "L830",
    label: "VIALFRE",
    province: "TO"
  },
  {
    code: "600",
    value: "L857",
    label: "VIDRACCO",
    province: "TO"
  },
  {
    code: "601",
    value: "L898",
    label: "VIGONE",
    province: "TO"
  },
  {
    code: "602",
    value: "L948",
    label: "VILLAFRANCA PIEMONTE",
    province: "TO"
  },
  {
    code: "603",
    value: "L982",
    label: "VILLANOVA CANAVESE",
    province: "TO"
  },
  {
    code: "604",
    value: "M002",
    label: "VILLARBASSE",
    province: "TO"
  },
  {
    code: "605",
    value: "L999",
    label: "VILLAR DORA",
    province: "TO"
  },
  {
    code: "606",
    value: "M004",
    label: "VILLAREGGIA",
    province: "TO"
  },
  {
    code: "607",
    value: "M007",
    label: "VILLAR FOCCHIARDO",
    province: "TO"
  },
  {
    code: "608",
    value: "M013",
    label: "VILLAR PELLICE",
    province: "TO"
  },
  {
    code: "609",
    value: "M014",
    label: "VILLAR PEROSA",
    province: "TO"
  },
  {
    code: "610",
    value: "M027",
    label: "VILLASTELLONE",
    province: "TO"
  },
  {
    code: "611",
    value: "M060",
    label: "VINOVO",
    province: "TO"
  },
  {
    code: "612",
    value: "M069",
    label: "VIRLE PIEMONTE",
    province: "TO"
  },
  {
    code: "613",
    value: "M071",
    label: "VISCHE",
    province: "TO"
  },
  {
    code: "614",
    value: "M080",
    label: "VISTRORIO",
    province: "TO"
  },
  {
    code: "615",
    value: "M094",
    label: "VIU",
    province: "TO"
  },
  {
    code: "616",
    value: "M122",
    label: "VOLPIANO",
    province: "TO"
  },
  {
    code: "617",
    value: "M133",
    label: "VOLVERA",
    province: "TO"
  },
  {
    code: "618",
    value: "A119",
    label: "ALAGNA VALSESIA",
    province: "VC"
  },
  {
    code: "619",
    value: "A130",
    label: "ALBANO VERCELLESE",
    province: "VC"
  },
  {
    code: "620",
    value: "A198",
    label: "ALICE CASTELLO",
    province: "VC"
  },
  {
    code: "621",
    value: "A358",
    label: "ARBORIO",
    province: "VC"
  },
  {
    code: "622",
    value: "A466",
    label: "ASIGLIANO VERCELLESE",
    province: "VC"
  },
  {
    code: "623",
    value: "A600",
    label: "BALMUCCIA",
    province: "VC"
  },
  {
    code: "624",
    value: "A601",
    label: "BALOCCO",
    province: "VC"
  },
  {
    code: "625",
    value: "A847",
    label: "BIANZE",
    province: "VC"
  },
  {
    code: "626",
    value: "A914",
    label: "BOCCIOLETO",
    province: "VC"
  },
  {
    code: "627",
    value: "B009",
    label: "BORGO D ALE",
    province: "VC"
  },
  {
    code: "628",
    value: "B041",
    label: "BORGOSESIA",
    province: "VC"
  },
  {
    code: "629",
    value: "B046",
    label: "BORGO VERCELLI",
    province: "VC"
  },
  {
    code: "631",
    value: "B280",
    label: "BURONZO",
    province: "VC"
  },
  {
    code: "632",
    value: "B505",
    label: "CAMPERTOGNO",
    province: "VC"
  },
  {
    code: "633",
    value: "B752",
    label: "CARCOFORO",
    province: "VC"
  },
  {
    code: "634",
    value: "B767",
    label: "CARESANA",
    province: "VC"
  },
  {
    code: "635",
    value: "B768",
    label: "CARESANABLOT",
    province: "VC"
  },
  {
    code: "636",
    value: "B782",
    label: "CARISIO",
    province: "VC"
  },
  {
    code: "637",
    value: "B928",
    label: "CASANOVA ELVO",
    province: "VC"
  },
  {
    code: "638",
    value: "B952",
    label: "SAN GIACOMO VERCELLESE",
    province: "VC"
  },
  {
    code: "640",
    value: "C548",
    label: "CERVATTO",
    province: "VC"
  },
  {
    code: "641",
    value: "C680",
    label: "CIGLIANO",
    province: "VC"
  },
  {
    code: "642",
    value: "C757",
    label: "CIVIASCO",
    province: "VC"
  },
  {
    code: "643",
    value: "C884",
    label: "COLLOBIANO",
    province: "VC"
  },
  {
    code: "644",
    value: "D113",
    label: "COSTANZANA",
    province: "VC"
  },
  {
    code: "645",
    value: "D132",
    label: "CRAVAGLIANA",
    province: "VC"
  },
  {
    code: "646",
    value: "D154",
    label: "CRESCENTINO",
    province: "VC"
  },
  {
    code: "647",
    value: "D187",
    label: "CROVA",
    province: "VC"
  },
  {
    code: "648",
    value: "D281",
    label: "DESANA",
    province: "VC"
  },
  {
    code: "649",
    value: "D641",
    label: "FOBELLO",
    province: "VC"
  },
  {
    code: "650",
    value: "D676",
    label: "FONTANETTO PO",
    province: "VC"
  },
  {
    code: "651",
    value: "D712",
    label: "FORMIGLIANA",
    province: "VC"
  },
  {
    code: "652",
    value: "D938",
    label: "GATTINARA",
    province: "VC"
  },
  {
    code: "653",
    value: "E007",
    label: "GHISLARENGO",
    province: "VC"
  },
  {
    code: "654",
    value: "E163",
    label: "GREGGIO",
    province: "VC"
  },
  {
    code: "655",
    value: "E237",
    label: "GUARDABOSONE",
    province: "VC"
  },
  {
    code: "656",
    value: "E433",
    label: "LAMPORO",
    province: "VC"
  },
  {
    code: "657",
    value: "E528",
    label: "LENTA",
    province: "VC"
  },
  {
    code: "658",
    value: "E583",
    label: "LIGNANA",
    province: "VC"
  },
  {
    code: "659",
    value: "E626",
    label: "LIVORNO FERRARIS",
    province: "VC"
  },
  {
    code: "660",
    value: "E711",
    label: "LOZZOLO",
    province: "VC"
  },
  {
    code: "661",
    value: "F297",
    label: "MOLLIA",
    province: "VC"
  },
  {
    code: "662",
    value: "F342",
    label: "MONCRIVELLO",
    province: "VC"
  },
  {
    code: "663",
    value: "H011",
    label: "PRAZZO",
    province: "CN"
  },
  {
    code: "664",
    value: "H059",
    label: "PRIERO",
    province: "CN"
  },
  {
    code: "665",
    value: "H068",
    label: "PRIOCCA",
    province: "CN"
  },
  {
    code: "666",
    value: "H069",
    label: "PRIOLA",
    province: "CN"
  },
  {
    code: "667",
    value: "H085",
    label: "PRUNETTO",
    province: "CN"
  },
  {
    code: "668",
    value: "H150",
    label: "RACCONIGI",
    province: "CN"
  },
  {
    code: "669",
    value: "H247",
    label: "REVELLO",
    province: "CN"
  },
  {
    code: "670",
    value: "H285",
    label: "RIFREDDO",
    province: "CN"
  },
  {
    code: "671",
    value: "H326",
    label: "RITTANA",
    province: "CN"
  },
  {
    code: "672",
    value: "H362",
    label: "ROASCHIA",
    province: "CN"
  },
  {
    code: "673",
    value: "H363",
    label: "ROASCIO",
    province: "CN"
  },
  {
    code: "674",
    value: "H377",
    label: "ROBILANTE",
    province: "CN"
  },
  {
    code: "675",
    value: "H378",
    label: "ROBURENT",
    province: "CN"
  },
  {
    code: "676",
    value: "H385",
    label: "ROCCABRUNA",
    province: "CN"
  },
  {
    code: "677",
    value: "H391",
    label: "ROCCA CIGLIE",
    province: "CN"
  },
  {
    code: "678",
    value: "H395",
    label: "ROCCA DE BALDI",
    province: "CN"
  },
  {
    code: "679",
    value: "H407",
    label: "ROCCAFORTE MONDOVI",
    province: "CN"
  },
  {
    code: "680",
    value: "H447",
    label: "ROCCASPARVERA",
    province: "CN"
  },
  {
    code: "681",
    value: "H453",
    label: "ROCCAVIONE",
    province: "CN"
  },
  {
    code: "682",
    value: "H462",
    label: "ROCCHETTA BELBO",
    province: "CN"
  },
  {
    code: "683",
    value: "H472",
    label: "RODDI",
    province: "CN"
  },
  {
    code: "684",
    value: "H473",
    label: "RODDINO",
    province: "CN"
  },
  {
    code: "685",
    value: "H474",
    label: "RODELLO",
    province: "CN"
  },
  {
    code: "686",
    value: "H578",
    label: "ROSSANA",
    province: "CN"
  },
  {
    code: "687",
    value: "H633",
    label: "RUFFIA",
    province: "CN"
  },
  {
    code: "688",
    value: "H695",
    label: "SALE DELLE LANGHE",
    province: "CN"
  },
  {
    code: "689",
    value: "H704",
    label: "SALE SAN GIOVANNI",
    province: "CN"
  },
  {
    code: "690",
    value: "H710",
    label: "SALICETO",
    province: "CN"
  },
  {
    code: "691",
    value: "H716",
    label: "SALMOUR",
    province: "CN"
  },
  {
    code: "692",
    value: "H727",
    label: "SALUZZO",
    province: "CN"
  },
  {
    code: "693",
    value: "H746",
    label: "SAMBUCO",
    province: "CN"
  },
  {
    code: "694",
    value: "H755",
    label: "SAMPEYRE",
    province: "CN"
  },
  {
    code: "695",
    value: "H770",
    label: "SAN BENEDETTO BELBO",
    province: "CN"
  },
  {
    code: "696",
    value: "H812",
    label: "SAN DAMIANO MACRA",
    province: "CN"
  },
  {
    code: "697",
    value: "H851",
    label: "SANFRE",
    province: "CN"
  },
  {
    code: "698",
    value: "H852",
    label: "SANFRONT",
    province: "CN"
  },
  {
    code: "699",
    value: "I037",
    label: "SAN MICHELE MONDOVI",
    province: "CN"
  },
  {
    code: "700",
    value: "I210",
    label: "SANT ALBANO STURA",
    province: "CN"
  },
  {
    code: "701",
    value: "I316",
    label: "SANTA VITTORIA D ALBA",
    province: "CN"
  },
  {
    code: "702",
    value: "I367",
    label: "SANTO STEFANO BELBO",
    province: "CN"
  },
  {
    code: "703",
    value: "I372",
    label: "SANTO STEFANO ROERO",
    province: "CN"
  },
  {
    code: "704",
    value: "I470",
    label: "SAVIGLIANO",
    province: "CN"
  },
  {
    code: "705",
    value: "I484",
    label: "SCAGNELLO",
    province: "CN"
  },
  {
    code: "706",
    value: "I512",
    label: "SCARNAFIGI",
    province: "CN"
  },
  {
    code: "707",
    value: "I646",
    label: "SERRALUNGA D ALBA",
    province: "CN"
  },
  {
    code: "708",
    value: "I659",
    label: "SERRAVALLE LANGHE",
    province: "CN"
  },
  {
    code: "709",
    value: "I750",
    label: "SINIO",
    province: "CN"
  },
  {
    code: "710",
    value: "I817",
    label: "SOMANO",
    province: "CN"
  },
  {
    code: "711",
    value: "I822",
    label: "SOMMARIVA DEL BOSCO",
    province: "CN"
  },
  {
    code: "712",
    value: "I823",
    label: "SOMMARIVA PERNO",
    province: "CN"
  },
  {
    code: "713",
    value: "I985",
    label: "STROPPO",
    province: "CN"
  },
  {
    code: "714",
    value: "L048",
    label: "TARANTASCA",
    province: "CN"
  },
  {
    code: "715",
    value: "L252",
    label: "TORRE BORMIDA",
    province: "CN"
  },
  {
    code: "716",
    value: "L241",
    label: "TORRE MONDOVI",
    province: "CN"
  },
  {
    code: "717",
    value: "L278",
    label: "TORRE SAN GIORGIO",
    province: "CN"
  },
  {
    code: "718",
    value: "L281",
    label: "TORRESINA",
    province: "CN"
  },
  {
    code: "719",
    value: "L367",
    label: "TREISO",
    province: "CN"
  },
  {
    code: "720",
    value: "L410",
    label: "TREZZO TINELLA",
    province: "CN"
  },
  {
    code: "721",
    value: "L427",
    label: "TRINITA",
    province: "CN"
  },
  {
    code: "722",
    value: "L558",
    label: "VALDIERI",
    province: "CN"
  },
  {
    code: "723",
    value: "L580",
    label: "VALGRANA",
    province: "CN"
  },
  {
    code: "724",
    value: "L631",
    label: "VALLORIATE",
    province: "CN"
  },
  {
    code: "726",
    value: "L729",
    label: "VENASCA",
    province: "CN"
  },
  {
    code: "727",
    value: "L758",
    label: "VERDUNO",
    province: "CN"
  },
  {
    code: "728",
    value: "L771",
    label: "VERNANTE",
    province: "CN"
  },
  {
    code: "729",
    value: "L804",
    label: "VERZUOLO",
    province: "CN"
  },
  {
    code: "730",
    value: "L817",
    label: "VEZZA D ALBA",
    province: "CN"
  },
  {
    code: "731",
    value: "L841",
    label: "VICOFORTE",
    province: "CN"
  },
  {
    code: "732",
    value: "L888",
    label: "VIGNOLO",
    province: "CN"
  },
  {
    code: "733",
    value: "L942",
    label: "VILLAFALLETTO",
    province: "CN"
  },
  {
    code: "734",
    value: "L974",
    label: "VILLANOVA MONDOVI",
    province: "CN"
  },
  {
    code: "735",
    value: "L990",
    label: "VILLANOVA SOLARO",
    province: "CN"
  },
  {
    code: "736",
    value: "M015",
    label: "VILLAR SAN COSTANZO",
    province: "CN"
  },
  {
    code: "737",
    value: "M055",
    label: "VINADIO",
    province: "CN"
  },
  {
    code: "738",
    value: "M063",
    label: "VIOLA",
    province: "CN"
  },
  {
    code: "739",
    value: "M136",
    label: "VOTTIGNASCO",
    province: "CN"
  },
  {
    code: "740",
    value: "A072",
    label: "AGLIANO TERME",
    province: "AT"
  },
  {
    code: "741",
    value: "A173",
    label: "ALBUGNANO",
    province: "AT"
  },
  {
    code: "742",
    value: "A312",
    label: "ANTIGNANO",
    province: "AT"
  },
  {
    code: "743",
    value: "A352",
    label: "ARAMENGO",
    province: "AT"
  },
  {
    code: "744",
    value: "A479",
    label: "ASTI",
    province: "AT"
  },
  {
    code: "745",
    value: "A527",
    label: "AZZANO D ASTI",
    province: "AT"
  },
  {
    code: "746",
    value: "A588",
    label: "BALDICHIERI D ASTI",
    province: "AT"
  },
  {
    code: "747",
    value: "A770",
    label: "BELVEGLIO",
    province: "AT"
  },
  {
    code: "748",
    value: "A812",
    label: "BERZANO DI SAN PIETRO",
    province: "AT"
  },
  {
    code: "749",
    value: "B221",
    label: "BRUNO",
    province: "AT"
  },
  {
    code: "750",
    value: "B236",
    label: "BUBBIO",
    province: "AT"
  },
  {
    code: "751",
    value: "B306",
    label: "BUTTIGLIERA D ASTI",
    province: "AT"
  },
  {
    code: "752",
    value: "B376",
    label: "CALAMANDRANA",
    province: "AT"
  },
  {
    code: "753",
    value: "B418",
    label: "CALLIANO",
    province: "AT"
  },
  {
    code: "754",
    value: "B425",
    label: "CALOSSO",
    province: "AT"
  },
  {
    code: "755",
    value: "B469",
    label: "CAMERANO CASASCO",
    province: "AT"
  },
  {
    code: "756",
    value: "B594",
    label: "CANELLI",
    province: "AT"
  },
  {
    code: "757",
    value: "B633",
    label: "CANTARANA",
    province: "AT"
  },
  {
    code: "758",
    value: "B707",
    label: "CAPRIGLIO",
    province: "AT"
  },
  {
    code: "759",
    value: "B991",
    label: "CASORZO MONFERRATO",
    province: "AT"
  },
  {
    code: "760",
    value: "C022",
    label: "CASSINASCO",
    province: "AT"
  },
  {
    code: "761",
    value: "C049",
    label: "CASTAGNOLE DELLE LANZE",
    province: "AT"
  },
  {
    code: "762",
    value: "C047",
    label: "CASTAGNOLE MONFERRATO",
    province: "AT"
  },
  {
    code: "763",
    value: "C064",
    label: "CASTEL BOGLIONE",
    province: "AT"
  },
  {
    code: "764",
    value: "C127",
    label: "CASTELL ALFERO",
    province: "AT"
  },
  {
    code: "765",
    value: "C154",
    label: "CASTELLERO",
    province: "AT"
  },
  {
    code: "766",
    value: "C161",
    label: "CASTELLETTO MOLINA",
    province: "AT"
  },
  {
    code: "767",
    value: "A300",
    label: "CASTELLO DI ANNONE",
    province: "AT"
  },
  {
    code: "768",
    value: "C226",
    label: "CASTELNUOVO BELBO",
    province: "AT"
  },
  {
    code: "769",
    value: "C230",
    label: "CASTELNUOVO CALCEA",
    province: "AT"
  },
  {
    code: "770",
    value: "C232",
    label: "CASTELNUOVO DON BOSCO",
    province: "AT"
  },
  {
    code: "771",
    value: "C253",
    label: "CASTEL ROCCHERO",
    province: "AT"
  },
  {
    code: "772",
    value: "C438",
    label: "CELLARENGO",
    province: "AT"
  },
  {
    code: "773",
    value: "C440",
    label: "CELLE ENOMONDO",
    province: "AT"
  },
  {
    code: "774",
    value: "C528",
    label: "CERRETO D ASTI",
    province: "AT"
  },
  {
    code: "775",
    value: "C533",
    label: "CERRO TANARO",
    province: "AT"
  },
  {
    code: "776",
    value: "C583",
    label: "CESSOLE",
    province: "AT"
  },
  {
    code: "777",
    value: "C658",
    label: "CHIUSANO D ASTI",
    province: "AT"
  },
  {
    code: "778",
    value: "C701",
    label: "CINAGLIO",
    province: "AT"
  },
  {
    code: "779",
    value: "C739",
    label: "CISTERNA D ASTI",
    province: "AT"
  },
  {
    code: "780",
    value: "C804",
    label: "COAZZOLO",
    province: "AT"
  },
  {
    code: "781",
    value: "C807",
    label: "COCCONATO",
    province: "AT"
  },
  {
    code: "783",
    value: "D046",
    label: "CORSIONE",
    province: "AT"
  },
  {
    code: "784",
    value: "D050",
    label: "CORTANDONE",
    province: "AT"
  },
  {
    code: "785",
    value: "D051",
    label: "CORTANZE",
    province: "AT"
  },
  {
    code: "786",
    value: "D052",
    label: "CORTAZZONE",
    province: "AT"
  },
  {
    code: "787",
    value: "D072",
    label: "CORTIGLIONE",
    province: "AT"
  },
  {
    code: "788",
    value: "D101",
    label: "COSSOMBRATO",
    province: "AT"
  },
  {
    code: "789",
    value: "D119",
    label: "COSTIGLIOLE D ASTI",
    province: "AT"
  },
  {
    code: "790",
    value: "D207",
    label: "CUNICO",
    province: "AT"
  },
  {
    code: "791",
    value: "D388",
    label: "DUSINO SAN MICHELE",
    province: "AT"
  },
  {
    code: "792",
    value: "D554",
    label: "FERRERE",
    province: "AT"
  },
  {
    code: "793",
    value: "D678",
    label: "FONTANILE",
    province: "AT"
  },
  {
    code: "794",
    value: "D802",
    label: "FRINCO",
    province: "AT"
  },
  {
    code: "795",
    value: "E134",
    label: "GRANA",
    province: "AT"
  },
  {
    code: "796",
    value: "E159",
    label: "GRAZZANO BADOGLIO",
    province: "AT"
  },
  {
    code: "797",
    value: "E295",
    label: "INCISA SCAPACCINO",
    province: "AT"
  },
  {
    code: "798",
    value: "E338",
    label: "ISOLA D ASTI",
    province: "AT"
  },
  {
    code: "799",
    value: "E633",
    label: "LOAZZOLO",
    province: "AT"
  },
  {
    code: "800",
    value: "E917",
    label: "MARANZANA",
    province: "AT"
  },
  {
    code: "801",
    value: "E944",
    label: "MARETTO",
    province: "AT"
  },
  {
    code: "802",
    value: "F254",
    label: "MOASCA",
    province: "AT"
  },
  {
    code: "803",
    value: "F308",
    label: "MOMBALDONE",
    province: "AT"
  },
  {
    code: "804",
    value: "F311",
    label: "MOMBARUZZO",
    province: "AT"
  },
  {
    code: "805",
    value: "F316",
    label: "MOMBERCELLI",
    province: "AT"
  },
  {
    code: "806",
    value: "F323",
    label: "MONALE",
    province: "AT"
  },
  {
    code: "807",
    value: "F325",
    label: "MONASTERO BORMIDA",
    province: "AT"
  },
  {
    code: "808",
    value: "F336",
    label: "MONCALVO",
    province: "AT"
  },
  {
    code: "809",
    value: "F343",
    label: "MONCUCCO TORINESE",
    province: "AT"
  },
  {
    code: "810",
    value: "F361",
    label: "MONGARDINO",
    province: "AT"
  },
  {
    code: "811",
    value: "F386",
    label: "MONTABONE",
    province: "AT"
  },
  {
    code: "812",
    value: "F390",
    label: "MONTAFIA",
    province: "AT"
  },
  {
    code: "813",
    value: "F409",
    label: "MONTALDO SCARAMPI",
    province: "AT"
  },
  {
    code: "814",
    value: "F468",
    label: "MONTECHIARO D ASTI",
    province: "AT"
  },
  {
    code: "815",
    value: "F527",
    label: "MONTEGROSSO D ASTI",
    province: "AT"
  },
  {
    code: "816",
    value: "F556",
    label: "MONTEMAGNO",
    province: "AT"
  },
  {
    code: "818",
    value: "F709",
    label: "MORANSENGO",
    province: "AT"
  },
  {
    code: "819",
    value: "F902",
    label: "NIZZA MONFERRATO",
    province: "AT"
  },
  {
    code: "820",
    value: "G048",
    label: "OLMO GENTILE",
    province: "AT"
  },
  {
    code: "821",
    value: "G358",
    label: "PASSERANO MARMORITO",
    province: "AT"
  },
  {
    code: "822",
    value: "G430",
    label: "PENANGO",
    province: "AT"
  },
  {
    code: "823",
    value: "G593",
    label: "PIEA",
    province: "AT"
  },
  {
    code: "824",
    value: "G676",
    label: "PINO D ASTI",
    province: "AT"
  },
  {
    code: "825",
    value: "G692",
    label: "PIOVA MASSAIA",
    province: "AT"
  },
  {
    code: "826",
    value: "G894",
    label: "PORTACOMARO",
    province: "AT"
  },
  {
    code: "827",
    value: "H102",
    label: "QUARANTI",
    province: "AT"
  },
  {
    code: "828",
    value: "H219",
    label: "REFRANCORE",
    province: "AT"
  },
  {
    code: "829",
    value: "H250",
    label: "REVIGLIASCO D ASTI",
    province: "AT"
  },
  {
    code: "830",
    value: "H366",
    label: "ROATTO",
    province: "AT"
  },
  {
    code: "831",
    value: "H376",
    label: "ROBELLA",
    province: "AT"
  },
  {
    code: "832",
    value: "H392",
    label: "ROCCA D ARAZZO",
    province: "AT"
  },
  {
    code: "833",
    value: "H451",
    label: "ROCCAVERANO",
    province: "AT"
  },
  {
    code: "834",
    value: "H466",
    label: "ROCCHETTA PALAFEA",
    province: "AT"
  },
  {
    code: "835",
    value: "H468",
    label: "ROCCHETTA TANARO",
    province: "AT"
  },
  {
    code: "836",
    value: "H811",
    label: "SAN DAMIANO D ASTI",
    province: "AT"
  },
  {
    code: "837",
    value: "H899",
    label: "SAN GIORGIO SCARAMPI",
    province: "AT"
  },
  {
    code: "838",
    value: "H987",
    label: "SAN MARTINO ALFIERI",
    province: "AT"
  },
  {
    code: "839",
    value: "I017",
    label: "SAN MARZANO OLIVETO",
    province: "AT"
  },
  {
    code: "840",
    value: "I076",
    label: "SAN PAOLO SOLBRITO",
    province: "AT"
  },
  {
    code: "842",
    value: "I555",
    label: "SCURZOLENGO",
    province: "AT"
  },
  {
    code: "843",
    value: "I637",
    label: "SEROLE",
    province: "AT"
  },
  {
    code: "844",
    value: "I678",
    label: "SESSAME",
    province: "AT"
  },
  {
    code: "845",
    value: "I698",
    label: "SETTIME",
    province: "AT"
  },
  {
    code: "846",
    value: "I781",
    label: "SOGLIO",
    province: "AT"
  },
  {
    code: "847",
    value: "L168",
    label: "TIGLIOLE",
    province: "AT"
  },
  {
    code: "848",
    value: "L203",
    label: "TONCO",
    province: "AT"
  },
  {
    code: "849",
    value: "L204",
    label: "TONENGO",
    province: "AT"
  },
  {
    code: "850",
    value: "L531",
    label: "VAGLIO SERRA",
    province: "AT"
  },
  {
    code: "851",
    value: "L574",
    label: "VALFENERA",
    province: "AT"
  },
  {
    code: "852",
    value: "L807",
    label: "VESIME",
    province: "AT"
  },
  {
    code: "853",
    value: "L829",
    label: "VIALE",
    province: "AT"
  },
  {
    code: "854",
    value: "L834",
    label: "VIARIGI",
    province: "AT"
  },
  {
    code: "855",
    value: "L879",
    label: "VIGLIANO D ASTI",
    province: "AT"
  },
  {
    code: "856",
    value: "L945",
    label: "VILLAFRANCA D ASTI",
    province: "AT"
  },
  {
    code: "857",
    value: "L984",
    label: "VILLANOVA D ASTI",
    province: "AT"
  },
  {
    code: "858",
    value: "M019",
    label: "VILLA SAN SECONDO",
    province: "AT"
  },
  {
    code: "859",
    value: "M058",
    label: "VINCHIO",
    province: "AT"
  },
  {
    code: "860",
    value: "A052",
    label: "ACQUI TERME",
    province: "AL"
  },
  {
    code: "861",
    value: "A146",
    label: "ALBERA LIGURE",
    province: "AL"
  },
  {
    code: "862",
    value: "A182",
    label: "ALESSANDRIA",
    province: "AL"
  },
  {
    code: "863",
    value: "A189",
    label: "ALFIANO NATTA",
    province: "AL"
  },
  {
    code: "864",
    value: "A197",
    label: "ALICE BEL COLLE",
    province: "AL"
  },
  {
    code: "866",
    value: "A227",
    label: "ALTAVILLA MONFERRATO",
    province: "AL"
  },
  {
    code: "867",
    value: "A245",
    label: "ALZANO SCRIVIA",
    province: "AL"
  },
  {
    code: "868",
    value: "A436",
    label: "ARQUATA SCRIVIA",
    province: "AL"
  },
  {
    code: "869",
    value: "A523",
    label: "AVOLASCA",
    province: "AL"
  },
  {
    code: "870",
    value: "A605",
    label: "BALZOLA",
    province: "AL"
  },
  {
    code: "871",
    value: "A689",
    label: "BASALUZZO",
    province: "AL"
  },
  {
    code: "872",
    value: "A708",
    label: "BASSIGNANA",
    province: "AL"
  },
  {
    code: "873",
    value: "A738",
    label: "BELFORTE MONFERRATO",
    province: "AL"
  },
  {
    code: "874",
    value: "A793",
    label: "BERGAMASCO",
    province: "AL"
  },
  {
    code: "875",
    value: "A813",
    label: "BERZANO DI TORTONA",
    province: "AL"
  },
  {
    code: "876",
    value: "A889",
    label: "BISTAGNO",
    province: "AL"
  },
  {
    code: "877",
    value: "A998",
    label: "BORGHETTO DI BORBERA",
    province: "AL"
  },
  {
    code: "878",
    value: "B029",
    label: "BORGORATTO ALESSANDRINO",
    province: "AL"
  },
  {
    code: "879",
    value: "B037",
    label: "BORGO SAN MARTINO",
    province: "AL"
  },
  {
    code: "880",
    value: "B071",
    label: "BOSCO MARENGO",
    province: "AL"
  },
  {
    code: "881",
    value: "B080",
    label: "BOSIO",
    province: "AL"
  },
  {
    code: "882",
    value: "B109",
    label: "BOZZOLE",
    province: "AL"
  },
  {
    code: "883",
    value: "B179",
    label: "BRIGNANO FRASCATA",
    province: "AL"
  },
  {
    code: "884",
    value: "B311",
    label: "CABELLA LIGURE",
    province: "AL"
  },
  {
    code: "885",
    value: "B453",
    label: "CAMAGNA MONFERRATO",
    province: "AL"
  },
  {
    code: "886",
    value: "B482",
    label: "CAMINO",
    province: "AL"
  },
  {
    code: "887",
    value: "B629",
    label: "CANTALUPO LIGURE",
    province: "AL"
  },
  {
    code: "888",
    value: "B701",
    label: "CAPRIATA D ORBA",
    province: "AL"
  },
  {
    code: "889",
    value: "B736",
    label: "CARBONARA SCRIVIA",
    province: "AL"
  },
  {
    code: "890",
    value: "B765",
    label: "CARENTINO",
    province: "AL"
  },
  {
    code: "891",
    value: "B769",
    label: "CAREZZANO",
    province: "AL"
  },
  {
    code: "892",
    value: "B818",
    label: "CARPENETO",
    province: "AL"
  },
  {
    code: "893",
    value: "B836",
    label: "CARREGA LIGURE",
    province: "AL"
  },
  {
    code: "894",
    value: "B840",
    label: "CARROSIO",
    province: "AL"
  },
  {
    code: "895",
    value: "B847",
    label: "CARTOSIO",
    province: "AL"
  },
  {
    code: "896",
    value: "B870",
    label: "CASAL CERMELLI",
    province: "AL"
  },
  {
    code: "897",
    value: "B882",
    label: "CASALEGGIO BOIRO",
    province: "AL"
  },
  {
    code: "898",
    value: "B885",
    label: "CASALE MONFERRATO",
    province: "AL"
  },
  {
    code: "899",
    value: "B902",
    label: "CASALNOCETO",
    province: "AL"
  },
  {
    code: "900",
    value: "B941",
    label: "CASASCO",
    province: "AL"
  },
  {
    code: "901",
    value: "M388",
    label: "CASSANO SPINOLA",
    province: "AL"
  },
  {
    code: "902",
    value: "C027",
    label: "CASSINE",
    province: "AL"
  },
  {
    code: "903",
    value: "C030",
    label: "CASSINELLE",
    province: "AL"
  },
  {
    code: "904",
    value: "C137",
    label: "CASTELLANIA COPPI",
    province: "AL"
  },
  {
    code: "905",
    value: "C142",
    label: "CASTELLAR GUIDOBONO",
    province: "AL"
  },
  {
    code: "906",
    value: "C148",
    label: "CASTELLAZZO BORMIDA",
    province: "AL"
  },
  {
    code: "907",
    value: "C156",
    label: "CASTELLETTO D ERRO",
    province: "AL"
  },
  {
    code: "908",
    value: "C158",
    label: "CASTELLETTO D ORBA",
    province: "AL"
  },
  {
    code: "909",
    value: "C160",
    label: "CASTELLETTO MERLI",
    province: "AL"
  },
  {
    code: "910",
    value: "C162",
    label: "CASTELLETTO MONFERRATO",
    province: "AL"
  },
  {
    code: "911",
    value: "C229",
    label: "CASTELNUOVO BORMIDA",
    province: "AL"
  },
  {
    code: "912",
    value: "C243",
    label: "CASTELNUOVO SCRIVIA",
    province: "AL"
  },
  {
    code: "913",
    value: "C274",
    label: "CASTELSPINA",
    province: "AL"
  },
  {
    code: "914",
    value: "C387",
    label: "CAVATORE",
    province: "AL"
  },
  {
    code: "915",
    value: "C432",
    label: "CELLA MONTE",
    province: "AL"
  },
  {
    code: "916",
    value: "C503",
    label: "CERESETO",
    province: "AL"
  },
  {
    code: "917",
    value: "C507",
    label: "CERRETO GRUE",
    province: "AL"
  },
  {
    code: "918",
    value: "C531",
    label: "CERRINA MONFERRATO",
    province: "AL"
  },
  {
    code: "919",
    value: "C962",
    label: "CONIOLO",
    province: "AL"
  },
  {
    code: "920",
    value: "C977",
    label: "CONZANO",
    province: "AL"
  },
  {
    code: "921",
    value: "D102",
    label: "COSTA VESCOVATO",
    province: "AL"
  },
  {
    code: "922",
    value: "D149",
    label: "CREMOLINO",
    province: "AL"
  },
  {
    code: "924",
    value: "D272",
    label: "DENICE",
    province: "AL"
  },
  {
    code: "925",
    value: "D277",
    label: "DERNICE",
    province: "AL"
  },
  {
    code: "926",
    value: "D447",
    label: "FABBRICA CURONE",
    province: "AL"
  },
  {
    code: "927",
    value: "D528",
    label: "FELIZZANO",
    province: "AL"
  },
  {
    code: "928",
    value: "D559",
    label: "FRACONALTO",
    province: "AL"
  },
  {
    code: "929",
    value: "D759",
    label: "FRANCAVILLA BISIO",
    province: "AL"
  },
  {
    code: "930",
    value: "D770",
    label: "FRASCARO",
    province: "AL"
  },
  {
    code: "931",
    value: "D777",
    label: "FRASSINELLO MONFERRATO",
    province: "AL"
  },
  {
    code: "932",
    value: "D780",
    label: "FRASSINETO PO",
    province: "AL"
  },
  {
    code: "933",
    value: "D797",
    label: "FRESONARA",
    province: "AL"
  },
  {
    code: "934",
    value: "D813",
    label: "FRUGAROLO",
    province: "AL"
  },
  {
    code: "935",
    value: "D814",
    label: "FUBINE MONFERRATO",
    province: "AL"
  },
  {
    code: "936",
    value: "D835",
    label: "GABIANO",
    province: "AL"
  },
  {
    code: "937",
    value: "D890",
    label: "GAMALERO",
    province: "AL"
  },
  {
    code: "938",
    value: "D910",
    label: "GARBAGNA",
    province: "AL"
  },
  {
    code: "940",
    value: "D944",
    label: "GAVI",
    province: "AL"
  },
  {
    code: "941",
    value: "E015",
    label: "GIAROLE",
    province: "AL"
  },
  {
    code: "942",
    value: "E164",
    label: "GREMIASCO",
    province: "AL"
  },
  {
    code: "943",
    value: "E188",
    label: "GROGNARDO",
    province: "AL"
  },
  {
    code: "944",
    value: "E191",
    label: "GRONDONA",
    province: "AL"
  },
  {
    code: "945",
    value: "E255",
    label: "GUAZZORA",
    province: "AL"
  },
  {
    code: "946",
    value: "E360",
    label: "ISOLA SANT ANTONIO",
    province: "AL"
  },
  {
    code: "947",
    value: "E543",
    label: "LERMA",
    province: "AL"
  },
  {
    code: "949",
    value: "E870",
    label: "MALVICINO",
    province: "AL"
  },
  {
    code: "950",
    value: "F015",
    label: "MASIO",
    province: "AL"
  },
  {
    code: "951",
    value: "F096",
    label: "MELAZZO",
    province: "AL"
  },
  {
    code: "952",
    value: "F131",
    label: "MERANA",
    province: "AL"
  },
  {
    code: "953",
    value: "F232",
    label: "MIRABELLO MONFERRATO",
    province: "AL"
  },
  {
    code: "954",
    value: "F281",
    label: "MOLARE",
    province: "AL"
  },
  {
    code: "955",
    value: "F293",
    label: "MOLINO DEI TORTI",
    province: "AL"
  },
  {
    code: "956",
    value: "F313",
    label: "MOMBELLO MONFERRATO",
    province: "AL"
  },
  {
    code: "957",
    value: "F320",
    label: "MOMPERONE",
    province: "AL"
  },
  {
    code: "958",
    value: "F337",
    label: "MONCESTINO",
    province: "AL"
  },
  {
    code: "959",
    value: "F365",
    label: "MONGIARDINO LIGURE",
    province: "AL"
  },
  {
    code: "960",
    value: "F374",
    label: "MONLEALE",
    province: "AL"
  },
  {
    code: "961",
    value: "F387",
    label: "MONTACUTO",
    province: "AL"
  },
  {
    code: "962",
    value: "F403",
    label: "MONTALDEO",
    province: "AL"
  },
  {
    code: "963",
    value: "F404",
    label: "MONTALDO BORMIDA",
    province: "AL"
  },
  {
    code: "964",
    value: "F455",
    label: "MONTECASTELLO",
    province: "AL"
  },
  {
    code: "965",
    value: "F469",
    label: "MONTECHIARO D ACQUI",
    province: "AL"
  },
  {
    code: "966",
    value: "F518",
    label: "MONTEGIOCO",
    province: "AL"
  },
  {
    code: "967",
    value: "F562",
    label: "MONTEMARZINO",
    province: "AL"
  },
  {
    code: "968",
    value: "F707",
    label: "MORANO SUL PO",
    province: "AL"
  },
  {
    code: "969",
    value: "F713",
    label: "MORBELLO",
    province: "AL"
  },
  {
    code: "970",
    value: "F737",
    label: "MORNESE",
    province: "AL"
  },
  {
    code: "971",
    value: "F751",
    label: "MORSASCO",
    province: "AL"
  },
  {
    code: "972",
    value: "F814",
    label: "MURISENGO",
    province: "AL"
  },
  {
    code: "973",
    value: "F965",
    label: "NOVI LIGURE",
    province: "AL"
  },
  {
    code: "974",
    value: "F995",
    label: "OCCIMIANO",
    province: "AL"
  },
  {
    code: "975",
    value: "F997",
    label: "ODALENGO GRANDE",
    province: "AL"
  },
  {
    code: "976",
    value: "F998",
    label: "ODALENGO PICCOLO",
    province: "AL"
  },
  {
    code: "977",
    value: "G042",
    label: "OLIVOLA",
    province: "AL"
  },
  {
    code: "978",
    value: "G124",
    label: "ORSARA BORMIDA",
    province: "AL"
  },
  {
    code: "979",
    value: "G193",
    label: "OTTIGLIO",
    province: "AL"
  },
  {
    code: "980",
    value: "G197",
    label: "OVADA",
    province: "AL"
  },
  {
    code: "981",
    value: "G199",
    label: "OVIGLIO",
    province: "AL"
  },
  {
    code: "982",
    value: "G204",
    label: "OZZANO MONFERRATO",
    province: "AL"
  },
  {
    code: "983",
    value: "G215",
    label: "PADERNA",
    province: "AL"
  },
  {
    code: "984",
    value: "G334",
    label: "PARETO",
    province: "AL"
  },
  {
    code: "985",
    value: "G338",
    label: "PARODI LIGURE",
    province: "AL"
  },
  {
    code: "986",
    value: "G367",
    label: "PASTURANA",
    province: "AL"
  },
  {
    code: "987",
    value: "G397",
    label: "PECETTO DI VALENZA",
    province: "AL"
  },
  {
    code: "988",
    value: "G619",
    label: "PIETRA MARAZZI",
    province: "AL"
  },
  {
    code: "990",
    value: "G807",
    label: "POMARO MONFERRATO",
    province: "AL"
  },
  {
    code: "991",
    value: "G839",
    label: "PONTECURONE",
    province: "AL"
  },
  {
    code: "992",
    value: "G858",
    label: "PONTESTURA",
    province: "AL"
  },
  {
    code: "993",
    value: "G861",
    label: "PONTI",
    province: "AL"
  },
  {
    code: "994",
    value: "G872",
    label: "PONZANO MONFERRATO",
    province: "AL"
  },
  {
    code: "995",
    value: "G877",
    label: "PONZONE",
    province: "AL"
  },
  {
    code: "996",
    value: "G960",
    label: "POZZOL GROPPO",
    province: "AL"
  },
  {
    code: "997",
    value: "G961",
    label: "POZZOLO FORMIGARO",
    province: "AL"
  },
  {
    code: "998",
    value: "G987",
    label: "PRASCO",
    province: "AL"
  },
  {
    code: "999",
    value: "H021",
    label: "PREDOSA",
    province: "AL"
  },
  {
    code: "1000",
    value: "H104",
    label: "QUARGNENTO",
    province: "AL"
  },
  {
    code: "1001",
    value: "H121",
    label: "QUATTORDIO",
    province: "AL"
  },
  {
    code: "1002",
    value: "H272",
    label: "RICALDONE",
    province: "AL"
  },
  {
    code: "1003",
    value: "H334",
    label: "RIVALTA BORMIDA",
    province: "AL"
  },
  {
    code: "1004",
    value: "H343",
    label: "RIVARONE",
    province: "AL"
  },
  {
    code: "1005",
    value: "H406",
    label: "ROCCAFORTE LIGURE",
    province: "AL"
  },
  {
    code: "1006",
    value: "H414",
    label: "ROCCA GRIMALDA",
    province: "AL"
  },
  {
    code: "1007",
    value: "H465",
    label: "ROCCHETTA LIGURE",
    province: "AL"
  },
  {
    code: "1008",
    value: "H569",
    label: "ROSIGNANO MONFERRATO",
    province: "AL"
  },
  {
    code: "1009",
    value: "H677",
    label: "SALA MONFERRATO",
    province: "AL"
  },
  {
    code: "1010",
    value: "H694",
    label: "SALE",
    province: "AL"
  },
  {
    code: "1011",
    value: "H810",
    label: "SAN CRISTOFORO",
    province: "AL"
  },
  {
    code: "1012",
    value: "H878",
    label: "SAN GIORGIO MONFERRATO",
    province: "AL"
  },
  {
    code: "1013",
    value: "I144",
    label: "SAN SALVATORE MONFERRATO",
    province: "AL"
  },
  {
    code: "1014",
    value: "I150",
    label: "SAN SEBASTIANO CURONE",
    province: "AL"
  },
  {
    code: "1015",
    value: "I190",
    label: "SANT AGATA FOSSILI",
    province: "AL"
  },
  {
    code: "1016",
    value: "I429",
    label: "SARDIGLIANO",
    province: "AL"
  },
  {
    code: "1017",
    value: "I432",
    label: "SAREZZANO",
    province: "AL"
  },
  {
    code: "1018",
    value: "I645",
    label: "SERRALUNGA DI CREA",
    province: "AL"
  },
  {
    code: "1019",
    value: "I657",
    label: "SERRAVALLE SCRIVIA",
    province: "AL"
  },
  {
    code: "1020",
    value: "I711",
    label: "SEZZADIO",
    province: "AL"
  },
  {
    code: "1021",
    value: "I738",
    label: "SILVANO D ORBA",
    province: "AL"
  },
  {
    code: "1022",
    value: "I798",
    label: "SOLERO",
    province: "AL"
  },
  {
    code: "1023",
    value: "I808",
    label: "SOLONGHELLO",
    province: "AL"
  },
  {
    code: "1024",
    value: "I901",
    label: "SPIGNO MONFERRATO",
    province: "AL"
  },
  {
    code: "1025",
    value: "I911",
    label: "SPINETO SCRIVIA",
    province: "AL"
  },
  {
    code: "1026",
    value: "I941",
    label: "STAZZANO",
    province: "AL"
  },
  {
    code: "1027",
    value: "I977",
    label: "STREVI",
    province: "AL"
  },
  {
    code: "1028",
    value: "L027",
    label: "TAGLIOLO MONFERRATO",
    province: "AL"
  },
  {
    code: "1029",
    value: "L059",
    label: "TASSAROLO",
    province: "AL"
  },
  {
    code: "1030",
    value: "L139",
    label: "TERRUGGIA",
    province: "AL"
  },
  {
    code: "1031",
    value: "L143",
    label: "TERZO",
    province: "AL"
  },
  {
    code: "1032",
    value: "L165",
    label: "TICINETO",
    province: "AL"
  },
  {
    code: "1033",
    value: "L304",
    label: "TORTONA",
    province: "AL"
  },
  {
    code: "1034",
    value: "L403",
    label: "TREVILLE",
    province: "AL"
  },
  {
    code: "1035",
    value: "L432",
    label: "TRISOBBIO",
    province: "AL"
  },
  {
    code: "1036",
    value: "L570",
    label: "VALENZA",
    province: "AL"
  },
  {
    code: "1037",
    value: "L633",
    label: "VALMACCA",
    province: "AL"
  },
  {
    code: "1038",
    value: "L881",
    label: "VIGNALE MONFERRATO",
    province: "AL"
  },
  {
    code: "1039",
    value: "L887",
    label: "VIGNOLE BORBERA",
    province: "AL"
  },
  {
    code: "1040",
    value: "L904",
    label: "VIGUZZOLO",
    province: "AL"
  },
  {
    code: "1041",
    value: "L931",
    label: "VILLADEATI",
    province: "AL"
  },
  {
    code: "1042",
    value: "L963",
    label: "VILLALVERNIA",
    province: "AL"
  },
  {
    code: "1043",
    value: "L970",
    label: "VILLAMIROGLIO",
    province: "AL"
  },
  {
    code: "1044",
    value: "L972",
    label: "VILLANOVA MONFERRATO",
    province: "AL"
  },
  {
    code: "1045",
    value: "M009",
    label: "VILLAROMAGNANO",
    province: "AL"
  },
  {
    code: "1046",
    value: "M077",
    label: "VISONE",
    province: "AL"
  },
  {
    code: "1047",
    value: "M120",
    label: "VOLPEDO",
    province: "AL"
  },
  {
    code: "1048",
    value: "M121",
    label: "VOLPEGLINO",
    province: "AL"
  },
  {
    code: "1049",
    value: "M123",
    label: "VOLTAGGIO",
    province: "AL"
  },
  {
    code: "1050",
    value: "A107",
    label: "AILOCHE",
    province: "BI"
  },
  {
    code: "1051",
    value: "A280",
    label: "ANDORNO MICCA",
    province: "BI"
  },
  {
    code: "1052",
    value: "A784",
    label: "BENNA",
    province: "BI"
  },
  {
    code: "1053",
    value: "A859",
    label: "BIELLA",
    province: "BI"
  },
  {
    code: "1054",
    value: "A876",
    label: "BIOGLIO",
    province: "BI"
  },
  {
    code: "1055",
    value: "B058",
    label: "BORRIANA",
    province: "BI"
  },
  {
    code: "1056",
    value: "B229",
    label: "BRUSNENGO",
    province: "BI"
  },
  {
    code: "1057",
    value: "B417",
    label: "CALLABIANA",
    province: "BI"
  },
  {
    code: "1058",
    value: "B457",
    label: "CAMANDONA",
    province: "BI"
  },
  {
    code: "1059",
    value: "B465",
    label: "CAMBURZANO",
    province: "BI"
  },
  {
    code: "1060",
    value: "M373",
    label: "CAMPIGLIA CERVO",
    province: "BI"
  },
  {
    code: "1061",
    value: "B586",
    label: "CANDELO",
    province: "BI"
  },
  {
    code: "1062",
    value: "B708",
    label: "CAPRILE",
    province: "BI"
  },
  {
    code: "1063",
    value: "B933",
    label: "CASAPINTA",
    province: "BI"
  },
  {
    code: "1064",
    value: "C155",
    label: "CASTELLETTO CERVO",
    province: "BI"
  },
  {
    code: "1065",
    value: "C363",
    label: "CAVAGLIA",
    province: "BI"
  },
  {
    code: "1067",
    value: "C532",
    label: "CERRIONE",
    province: "BI"
  },
  {
    code: "1068",
    value: "C819",
    label: "COGGIOLA",
    province: "BI"
  },
  {
    code: "1069",
    value: "D094",
    label: "COSSATO",
    province: "BI"
  },
  {
    code: "1070",
    value: "D165",
    label: "CREVACUORE",
    province: "BI"
  },
  {
    code: "1072",
    value: "D219",
    label: "CURINO",
    province: "BI"
  },
  {
    code: "1073",
    value: "D339",
    label: "DONATO",
    province: "BI"
  },
  {
    code: "1074",
    value: "D350",
    label: "DORZANO",
    province: "BI"
  },
  {
    code: "1075",
    value: "D848",
    label: "GAGLIANICO",
    province: "BI"
  },
  {
    code: "1076",
    value: "E024",
    label: "GIFFLENGA",
    province: "BI"
  },
  {
    code: "1077",
    value: "E130",
    label: "GRAGLIA",
    province: "BI"
  },
  {
    code: "1078",
    value: "M371",
    label: "LESSONA",
    province: "BI"
  },
  {
    code: "1079",
    value: "E821",
    label: "MAGNANO",
    province: "BI"
  },
  {
    code: "1080",
    value: "F037",
    label: "MASSAZZA",
    province: "BI"
  },
  {
    code: "1081",
    value: "F042",
    label: "MASSERANO",
    province: "BI"
  },
  {
    code: "1082",
    value: "F167",
    label: "MEZZANA MORTIGLIENGO",
    province: "BI"
  },
  {
    code: "1083",
    value: "F189",
    label: "MIAGLIANO",
    province: "BI"
  },
  {
    code: "1084",
    value: "F369",
    label: "MONGRANDO",
    province: "BI"
  },
  {
    code: "1086",
    value: "F776",
    label: "MOTTALCIATA",
    province: "BI"
  },
  {
    code: "1087",
    value: "F833",
    label: "MUZZANO",
    province: "BI"
  },
  {
    code: "1088",
    value: "F878",
    label: "NETRO",
    province: "BI"
  },
  {
    code: "1089",
    value: "F992",
    label: "OCCHIEPPO INFERIORE",
    province: "BI"
  },
  {
    code: "1090",
    value: "F993",
    label: "OCCHIEPPO SUPERIORE",
    province: "BI"
  },
  {
    code: "1091",
    value: "G521",
    label: "PETTINENGO",
    province: "BI"
  },
  {
    code: "1092",
    value: "G577",
    label: "PIATTO",
    province: "BI"
  },
  {
    code: "1093",
    value: "G594",
    label: "PIEDICAVALLO",
    province: "BI"
  },
  {
    code: "1095",
    value: "G798",
    label: "POLLONE",
    province: "BI"
  },
  {
    code: "1096",
    value: "G820",
    label: "PONDERANO",
    province: "BI"
  },
  {
    code: "1097",
    value: "G927",
    label: "PORTULA",
    province: "BI"
  },
  {
    code: "1098",
    value: "G980",
    label: "PRALUNGO",
    province: "BI"
  },
  {
    code: "1099",
    value: "G974",
    label: "PRAY",
    province: "BI"
  },
  {
    code: "1102",
    value: "H538",
    label: "RONCO BIELLESE",
    province: "BI"
  },
  {
    code: "1103",
    value: "H553",
    label: "ROPPOLO",
    province: "BI"
  },
  {
    code: "1104",
    value: "H561",
    label: "ROSAZZA",
    province: "BI"
  },
  {
    code: "1105",
    value: "H662",
    label: "SAGLIANO MICCA",
    province: "BI"
  },
  {
    code: "1106",
    value: "H681",
    label: "SALA BIELLESE",
    province: "BI"
  },
  {
    code: "1107",
    value: "H726",
    label: "SALUSSOLA",
    province: "BI"
  },
  {
    code: "1108",
    value: "H821",
    label: "SANDIGLIANO",
    province: "BI"
  },
  {
    code: "1112",
    value: "I847",
    label: "SORDEVOLO",
    province: "BI"
  },
  {
    code: "1113",
    value: "I868",
    label: "SOSTEGNO",
    province: "BI"
  },
  {
    code: "1114",
    value: "I980",
    label: "STRONA",
    province: "BI"
  },
  {
    code: "1115",
    value: "L075",
    label: "TAVIGLIANO",
    province: "BI"
  },
  {
    code: "1116",
    value: "L116",
    label: "TERNENGO",
    province: "BI"
  },
  {
    code: "1117",
    value: "L193",
    label: "TOLLEGNO",
    province: "BI"
  },
  {
    code: "1118",
    value: "L239",
    label: "TORRAZZO",
    province: "BI"
  },
  {
    code: "1120",
    value: "L556",
    label: "VALDENGO",
    province: "BI"
  },
  {
    code: "1121",
    value: "L586",
    label: "VALLANZENGO",
    province: "BI"
  },
  {
    code: "1123",
    value: "L620",
    label: "VALLE SAN NICOLAO",
    province: "BI"
  },
  {
    code: "1124",
    value: "L712",
    label: "VEGLIO",
    province: "BI"
  },
  {
    code: "1125",
    value: "L785",
    label: "VERRONE",
    province: "BI"
  },
  {
    code: "1126",
    value: "L880",
    label: "VIGLIANO BIELLESE",
    province: "BI"
  },
  {
    code: "1127",
    value: "L933",
    label: "VILLA DEL BOSCO",
    province: "BI"
  },
  {
    code: "1128",
    value: "L978",
    label: "VILLANOVA BIELLESE",
    province: "BI"
  },
  {
    code: "1129",
    value: "M098",
    label: "VIVERONE",
    province: "BI"
  },
  {
    code: "1130",
    value: "M179",
    label: "ZIMONE",
    province: "BI"
  },
  {
    code: "1131",
    value: "M196",
    label: "ZUBIENA",
    province: "BI"
  },
  {
    code: "1132",
    value: "M201",
    label: "ZUMAGLIA",
    province: "BI"
  },
  {
    code: "1133",
    value: "A317",
    label: "ANTRONA SCHIERANCO",
    province: "VB"
  },
  {
    code: "1134",
    value: "A325",
    label: "ANZOLA D OSSOLA",
    province: "VB"
  },
  {
    code: "1135",
    value: "A409",
    label: "ARIZZANO",
    province: "VB"
  },
  {
    code: "1136",
    value: "A427",
    label: "AROLA",
    province: "VB"
  },
  {
    code: "1137",
    value: "A497",
    label: "AURANO",
    province: "VB"
  },
  {
    code: "1138",
    value: "A534",
    label: "BACENO",
    province: "VB"
  },
  {
    code: "1139",
    value: "A610",
    label: "BANNIO ANZINO",
    province: "VB"
  },
  {
    code: "1140",
    value: "A725",
    label: "BAVENO",
    province: "VB"
  },
  {
    code: "1141",
    value: "A733",
    label: "BEE",
    province: "VB"
  },
  {
    code: "1142",
    value: "A742",
    label: "BELGIRATE",
    province: "VB"
  },
  {
    code: "1143",
    value: "A834",
    label: "BEURA CARDEZZA",
    province: "VB"
  },
  {
    code: "1144",
    value: "A925",
    label: "BOGNANCO",
    province: "VB"
  },
  {
    code: "1145",
    value: "B207",
    label: "BROVELLO CARPUGNINO",
    province: "VB"
  },
  {
    code: "1146",
    value: "B380",
    label: "CALASCA CASTIGLIONE",
    province: "VB"
  },
  {
    code: "1147",
    value: "B463",
    label: "CAMBIASCA",
    province: "VB"
  },
  {
    code: "1148",
    value: "B610",
    label: "CANNERO RIVIERA",
    province: "VB"
  },
  {
    code: "1149",
    value: "B615",
    label: "CANNOBIO",
    province: "VB"
  },
  {
    code: "1150",
    value: "B694",
    label: "CAPREZZO",
    province: "VB"
  },
  {
    code: "1151",
    value: "B876",
    label: "CASALE CORTE CERRO",
    province: "VB"
  },
  {
    code: "1153",
    value: "C478",
    label: "CEPPO MORELLI",
    province: "VB"
  },
  {
    code: "1154",
    value: "C567",
    label: "CESARA",
    province: "VB"
  },
  {
    code: "1155",
    value: "D099",
    label: "COSSOGNO",
    province: "VB"
  },
  {
    code: "1156",
    value: "D134",
    label: "CRAVEGGIA",
    province: "VB"
  },
  {
    code: "1157",
    value: "D168",
    label: "CREVOLADOSSOLA",
    province: "VB"
  },
  {
    code: "1158",
    value: "D177",
    label: "CRODO",
    province: "VB"
  },
  {
    code: "1160",
    value: "D332",
    label: "DOMODOSSOLA",
    province: "VB"
  },
  {
    code: "1161",
    value: "D374",
    label: "DRUOGNO",
    province: "VB"
  },
  {
    code: "1163",
    value: "D706",
    label: "FORMAZZA",
    province: "VB"
  },
  {
    code: "1164",
    value: "D984",
    label: "GERMAGNO",
    province: "VB"
  },
  {
    code: "1165",
    value: "E003",
    label: "GHIFFA",
    province: "VB"
  },
  {
    code: "1166",
    value: "E028",
    label: "GIGNESE",
    province: "VB"
  },
  {
    code: "1167",
    value: "E153",
    label: "GRAVELLONA TOCE",
    province: "VB"
  },
  {
    code: "1168",
    value: "E269",
    label: "GURRO",
    province: "VB"
  },
  {
    code: "1169",
    value: "E304",
    label: "INTRAGNA",
    province: "VB"
  },
  {
    code: "1170",
    value: "E685",
    label: "LOREGLIA",
    province: "VB"
  },
  {
    code: "1171",
    value: "E790",
    label: "MACUGNAGA",
    province: "VB"
  },
  {
    code: "1172",
    value: "E795",
    label: "MADONNA DEL SASSO",
    province: "VB"
  },
  {
    code: "1173",
    value: "E853",
    label: "MALESCO",
    province: "VB"
  },
  {
    code: "1174",
    value: "F010",
    label: "MASERA",
    province: "VB"
  },
  {
    code: "1175",
    value: "F048",
    label: "MASSIOLA",
    province: "VB"
  },
  {
    code: "1176",
    value: "F146",
    label: "MERGOZZO",
    province: "VB"
  },
  {
    code: "1177",
    value: "F192",
    label: "MIAZZINA",
    province: "VB"
  },
  {
    code: "1178",
    value: "F483",
    label: "MONTECRESTESE",
    province: "VB"
  },
  {
    code: "1179",
    value: "F639",
    label: "MONTESCHENO",
    province: "VB"
  },
  {
    code: "1180",
    value: "F932",
    label: "NONIO",
    province: "VB"
  },
  {
    code: "1181",
    value: "G007",
    label: "OGGEBBIO",
    province: "VB"
  },
  {
    code: "1182",
    value: "G062",
    label: "OMEGNA",
    province: "VB"
  },
  {
    code: "1183",
    value: "G117",
    label: "ORNAVASSO",
    province: "VB"
  },
  {
    code: "1184",
    value: "G280",
    label: "PALLANZENO",
    province: "VB"
  },
  {
    code: "1185",
    value: "G600",
    label: "PIEDIMULERA",
    province: "VB"
  },
  {
    code: "1186",
    value: "G658",
    label: "PIEVE VERGONTE",
    province: "VB"
  },
  {
    code: "1187",
    value: "H030",
    label: "PREMENO",
    province: "VB"
  },
  {
    code: "1188",
    value: "H033",
    label: "PREMIA",
    province: "VB"
  },
  {
    code: "1189",
    value: "H037",
    label: "PREMOSELLO CHIOVENDA",
    province: "VB"
  },
  {
    code: "1190",
    value: "H106",
    label: "QUARNA SOPRA",
    province: "VB"
  },
  {
    code: "1191",
    value: "H107",
    label: "QUARNA SOTTO",
    province: "VB"
  },
  {
    code: "1192",
    value: "H203",
    label: "RE",
    province: "VB"
  },
  {
    code: "1193",
    value: "H777",
    label: "SAN BERNARDINO VERBANO",
    province: "VB"
  },
  {
    code: "1194",
    value: "I249",
    label: "SANTA MARIA MAGGIORE",
    province: "VB"
  },
  {
    code: "1196",
    value: "I976",
    label: "STRESA",
    province: "VB"
  },
  {
    code: "1197",
    value: "L187",
    label: "TOCENO",
    province: "VB"
  },
  {
    code: "1198",
    value: "L333",
    label: "TRAREGO VIGGIONA",
    province: "VB"
  },
  {
    code: "1199",
    value: "L336",
    label: "TRASQUERA",
    province: "VB"
  },
  {
    code: "1200",
    value: "L450",
    label: "TRONTANO",
    province: "VB"
  },
  {
    code: "1201",
    value: "L651",
    label: "VALSTRONA",
    province: "VB"
  },
  {
    code: "1202",
    value: "L666",
    label: "VANZONE CON SAN CARLO",
    province: "VB"
  },
  {
    code: "1203",
    value: "L691",
    label: "VARZO",
    province: "VB"
  },
  {
    code: "1204",
    value: "L746",
    label: "VERBANIA",
    province: "VB"
  },
  {
    code: "1206",
    value: "L889",
    label: "VIGNONE",
    province: "VB"
  },
  {
    code: "1207",
    value: "L906",
    label: "VILLADOSSOLA",
    province: "VB"
  },
  {
    code: "1208",
    value: "M042",
    label: "VILLETTE",
    province: "VB"
  },
  {
    code: "1209",
    value: "M111",
    label: "VOGOGNA",
    province: "VB"
  },
  {
    code: "1210",
    value: "A205",
    label: "ALLEIN",
    province: "AO"
  },
  {
    code: "1211",
    value: "A305",
    label: "ANTEY SAINT ANDRE",
    province: "AO"
  },
  {
    code: "1212",
    value: "A326",
    label: "AOSTA",
    province: "AO"
  },
  {
    code: "1213",
    value: "A424",
    label: "ARNAD",
    province: "AO"
  },
  {
    code: "1214",
    value: "A452",
    label: "ARVIER",
    province: "AO"
  },
  {
    code: "1215",
    value: "A521",
    label: "AVISE",
    province: "AO"
  },
  {
    code: "1216",
    value: "A094",
    label: "AYAS",
    province: "AO"
  },
  {
    code: "1217",
    value: "A108",
    label: "AYMAVILLES",
    province: "AO"
  },
  {
    code: "1218",
    value: "A643",
    label: "BARD",
    province: "AO"
  },
  {
    code: "1219",
    value: "A877",
    label: "BIONAZ",
    province: "AO"
  },
  {
    code: "1220",
    value: "B192",
    label: "BRISSOGNE",
    province: "AO"
  },
  {
    code: "1221",
    value: "B230",
    label: "BRUSSON",
    province: "AO"
  },
  {
    code: "1222",
    value: "C593",
    label: "CHALLAND SAINT ANSELME",
    province: "AO"
  },
  {
    code: "1223",
    value: "C594",
    label: "CHALLAND SAINT VICTOR",
    province: "AO"
  },
  {
    code: "1224",
    value: "C595",
    label: "CHAMBAVE",
    province: "AO"
  },
  {
    code: "1225",
    value: "B491",
    label: "CHAMOIS",
    province: "AO"
  },
  {
    code: "1226",
    value: "C596",
    label: "CHAMPDEPRAZ",
    province: "AO"
  },
  {
    code: "1227",
    value: "B540",
    label: "CHAMPORCHER",
    province: "AO"
  },
  {
    code: "1228",
    value: "C598",
    label: "CHARVENSOD",
    province: "AO"
  },
  {
    code: "1229",
    value: "C294",
    label: "CHATILLON",
    province: "AO"
  },
  {
    code: "1230",
    value: "C821",
    label: "COGNE",
    province: "AO"
  },
  {
    code: "1231",
    value: "D012",
    label: "COURMAYEUR",
    province: "AO"
  },
  {
    code: "1232",
    value: "D338",
    label: "DONNAS",
    province: "AO"
  },
  {
    code: "1233",
    value: "D356",
    label: "DOUES",
    province: "AO"
  },
  {
    code: "1234",
    value: "D402",
    label: "EMARESE",
    province: "AO"
  },
  {
    code: "1235",
    value: "D444",
    label: "ETROUBLES",
    province: "AO"
  },
  {
    code: "1236",
    value: "D537",
    label: "FENIS",
    province: "AO"
  },
  {
    code: "1237",
    value: "D666",
    label: "FONTAINEMORE",
    province: "AO"
  },
  {
    code: "1238",
    value: "D839",
    label: "GABY",
    province: "AO"
  },
  {
    code: "1239",
    value: "E029",
    label: "GIGNOD",
    province: "AO"
  },
  {
    code: "1240",
    value: "E165",
    label: "GRESSAN",
    province: "AO"
  },
  {
    code: "1241",
    value: "E167",
    label: "GRESSONEY LA TRINITE",
    province: "AO"
  },
  {
    code: "1242",
    value: "E168",
    label: "GRESSONEY SAINT JEAN",
    province: "AO"
  },
  {
    code: "1243",
    value: "E273",
    label: "HONE",
    province: "AO"
  },
  {
    code: "1244",
    value: "E306",
    label: "INTROD",
    province: "AO"
  },
  {
    code: "1245",
    value: "E369",
    label: "ISSIME",
    province: "AO"
  },
  {
    code: "1246",
    value: "E371",
    label: "ISSOGNE",
    province: "AO"
  },
  {
    code: "1247",
    value: "E391",
    label: "JOVENCAN",
    province: "AO"
  },
  {
    code: "1248",
    value: "A308",
    label: "LA MAGDELEINE",
    province: "AO"
  },
  {
    code: "1249",
    value: "E458",
    label: "LA SALLE",
    province: "AO"
  },
  {
    code: "1250",
    value: "E470",
    label: "LA THUILE",
    province: "AO"
  },
  {
    code: "1251",
    value: "E587",
    label: "LILLIANES",
    province: "AO"
  },
  {
    code: "1252",
    value: "F367",
    label: "MONTJOVET",
    province: "AO"
  },
  {
    code: "1253",
    value: "F726",
    label: "MORGEX",
    province: "AO"
  },
  {
    code: "1254",
    value: "F987",
    label: "NUS",
    province: "AO"
  },
  {
    code: "1255",
    value: "G045",
    label: "OLLOMONT",
    province: "AO"
  },
  {
    code: "1256",
    value: "G012",
    label: "OYACE",
    province: "AO"
  },
  {
    code: "1257",
    value: "G459",
    label: "PERLOZ",
    province: "AO"
  },
  {
    code: "1258",
    value: "G794",
    label: "POLLEIN",
    province: "AO"
  },
  {
    code: "1259",
    value: "G545",
    label: "PONTBOSET",
    province: "AO"
  },
  {
    code: "1260",
    value: "G860",
    label: "PONTEY",
    province: "AO"
  },
  {
    code: "1261",
    value: "G854",
    label: "PONT SAINT MARTIN",
    province: "AO"
  },
  {
    code: "1262",
    value: "H042",
    label: "PRE SAINT DIDIER",
    province: "AO"
  },
  {
    code: "1263",
    value: "H110",
    label: "QUART",
    province: "AO"
  },
  {
    code: "1264",
    value: "H262",
    label: "RHEMES NOTRE DAME",
    province: "AO"
  },
  {
    code: "1265",
    value: "H263",
    label: "RHEMES SAINT GEORGES",
    province: "AO"
  },
  {
    code: "1266",
    value: "H497",
    label: "ROISAN",
    province: "AO"
  },
  {
    code: "1267",
    value: "H669",
    label: "SAINT CHRISTOPHE",
    province: "AO"
  },
  {
    code: "1268",
    value: "H670",
    label: "SAINT DENIS",
    province: "AO"
  },
  {
    code: "1269",
    value: "H671",
    label: "SAINT MARCEL",
    province: "AO"
  },
  {
    code: "1270",
    value: "H672",
    label: "SAINT NICOLAS",
    province: "AO"
  },
  {
    code: "1271",
    value: "H673",
    label: "SAINT OYEN",
    province: "AO"
  },
  {
    code: "1272",
    value: "H674",
    label: "SAINT PIERRE",
    province: "AO"
  },
  {
    code: "1273",
    value: "H675",
    label: "SAINT RHEMY EN BOSSES",
    province: "AO"
  },
  {
    code: "1274",
    value: "H676",
    label: "SAINT VINCENT",
    province: "AO"
  },
  {
    code: "1275",
    value: "I442",
    label: "SARRE",
    province: "AO"
  },
  {
    code: "1276",
    value: "L217",
    label: "TORGNON",
    province: "AO"
  },
  {
    code: "1277",
    value: "L582",
    label: "VALGRISENCHE",
    province: "AO"
  },
  {
    code: "1278",
    value: "L643",
    label: "VALPELLINE",
    province: "AO"
  },
  {
    code: "1279",
    value: "L647",
    label: "VALSAVARENCHE",
    province: "AO"
  },
  {
    code: "1280",
    value: "L654",
    label: "VALTOURNENCHE",
    province: "AO"
  },
  {
    code: "1281",
    value: "L783",
    label: "VERRAYES",
    province: "AO"
  },
  {
    code: "1282",
    value: "C282",
    label: "VERRES",
    province: "AO"
  },
  {
    code: "1283",
    value: "L981",
    label: "VILLENEUVE",
    province: "AO"
  },
  {
    code: "1284",
    value: "A085",
    label: "AGRA",
    province: "VA"
  },
  {
    code: "1285",
    value: "A167",
    label: "ALBIZZATE",
    province: "VA"
  },
  {
    code: "1286",
    value: "A290",
    label: "ANGERA",
    province: "VA"
  },
  {
    code: "1287",
    value: "A371",
    label: "ARCISATE",
    province: "VA"
  },
  {
    code: "1288",
    value: "A441",
    label: "ARSAGO SEPRIO",
    province: "VA"
  },
  {
    code: "1289",
    value: "A531",
    label: "AZZATE",
    province: "VA"
  },
  {
    code: "1290",
    value: "A532",
    label: "AZZIO",
    province: "VA"
  },
  {
    code: "1291",
    value: "A619",
    label: "BARASSO",
    province: "VA"
  },
  {
    code: "1292",
    value: "A645",
    label: "BARDELLO",
    province: "VA"
  },
  {
    code: "1293",
    value: "A728",
    label: "BEDERO VALCUVIA",
    province: "VA"
  },
  {
    code: "1294",
    value: "A819",
    label: "BESANO",
    province: "VA"
  },
  {
    code: "1295",
    value: "A825",
    label: "BESNATE",
    province: "VA"
  },
  {
    code: "1296",
    value: "A826",
    label: "BESOZZO",
    province: "VA"
  },
  {
    code: "1297",
    value: "A845",
    label: "BIANDRONNO",
    province: "VA"
  },
  {
    code: "1298",
    value: "A891",
    label: "BISUSCHIO",
    province: "VA"
  },
  {
    code: "1299",
    value: "A918",
    label: "BODIO LOMNAGO",
    province: "VA"
  },
  {
    code: "1300",
    value: "B126",
    label: "BREBBIA",
    province: "VA"
  },
  {
    code: "1301",
    value: "B131",
    label: "BREGANO",
    province: "VA"
  },
  {
    code: "1302",
    value: "B150",
    label: "BRENTA",
    province: "VA"
  },
  {
    code: "1303",
    value: "B166",
    label: "BREZZO DI BEDERO",
    province: "VA"
  },
  {
    code: "1304",
    value: "B182",
    label: "BRINZIO",
    province: "VA"
  },
  {
    code: "1305",
    value: "B191",
    label: "BRISSAGO VALTRAVAGLIA",
    province: "VA"
  },
  {
    code: "1306",
    value: "B219",
    label: "BRUNELLO",
    province: "VA"
  },
  {
    code: "1307",
    value: "B228",
    label: "BRUSIMPIANO",
    province: "VA"
  },
  {
    code: "1308",
    value: "B258",
    label: "BUGUGGIATE",
    province: "VA"
  },
  {
    code: "1309",
    value: "B300",
    label: "BUSTO ARSIZIO",
    province: "VA"
  },
  {
    code: "1310",
    value: "B326",
    label: "CADEGLIANO VICONAGO",
    province: "VA"
  },
  {
    code: "1312",
    value: "B368",
    label: "CAIRATE",
    province: "VA"
  },
  {
    code: "1313",
    value: "B634",
    label: "CANTELLO",
    province: "VA"
  },
  {
    code: "1314",
    value: "B732",
    label: "CARAVATE",
    province: "VA"
  },
  {
    code: "1315",
    value: "B754",
    label: "CARDANO AL CAMPO",
    province: "VA"
  },
  {
    code: "1316",
    value: "B796",
    label: "CARNAGO",
    province: "VA"
  },
  {
    code: "1317",
    value: "B805",
    label: "CARONNO PERTUSELLA",
    province: "VA"
  },
  {
    code: "1318",
    value: "B807",
    label: "CARONNO VARESINO",
    province: "VA"
  },
  {
    code: "1319",
    value: "B875",
    label: "CASALE LITTA",
    province: "VA"
  },
  {
    code: "1320",
    value: "B921",
    label: "CASALZUIGNO",
    province: "VA"
  },
  {
    code: "1321",
    value: "B949",
    label: "CASCIAGO",
    province: "VA"
  },
  {
    code: "1322",
    value: "B987",
    label: "CASORATE SEMPIONE",
    province: "VA"
  },
  {
    code: "1323",
    value: "C004",
    label: "CASSANO MAGNAGO",
    province: "VA"
  },
  {
    code: "1324",
    value: "B999",
    label: "CASSANO VALCUVIA",
    province: "VA"
  },
  {
    code: "1325",
    value: "C139",
    label: "CASTELLANZA",
    province: "VA"
  },
  {
    code: "1326",
    value: "B312",
    label: "CASTELLO CABIAGLIO",
    province: "VA"
  },
  {
    code: "1327",
    value: "C273",
    label: "CASTELSEPRIO",
    province: "VA"
  },
  {
    code: "1328",
    value: "C181",
    label: "CASTELVECCANA",
    province: "VA"
  },
  {
    code: "1329",
    value: "C300",
    label: "CASTIGLIONE OLONA",
    province: "VA"
  },
  {
    code: "1330",
    value: "C343",
    label: "CASTRONNO",
    province: "VA"
  },
  {
    code: "1331",
    value: "C382",
    label: "CAVARIA CON PREMEZZO",
    province: "VA"
  },
  {
    code: "1332",
    value: "C409",
    label: "CAZZAGO BRABBIA",
    province: "VA"
  },
  {
    code: "1333",
    value: "C732",
    label: "CISLAGO",
    province: "VA"
  },
  {
    code: "1334",
    value: "C751",
    label: "CITTIGLIO",
    province: "VA"
  },
  {
    code: "1335",
    value: "C796",
    label: "CLIVIO",
    province: "VA"
  },
  {
    code: "1336",
    value: "C810",
    label: "COCQUIO TREVISAGO",
    province: "VA"
  },
  {
    code: "1337",
    value: "C911",
    label: "COMABBIO",
    province: "VA"
  },
  {
    code: "1338",
    value: "C922",
    label: "COMERIO",
    province: "VA"
  },
  {
    code: "1339",
    value: "D144",
    label: "CREMENAGA",
    province: "VA"
  },
  {
    code: "1340",
    value: "D185",
    label: "CROSIO DELLA VALLE",
    province: "VA"
  },
  {
    code: "1341",
    value: "D192",
    label: "CUASSO AL MONTE",
    province: "VA"
  },
  {
    code: "1342",
    value: "D199",
    label: "CUGLIATE FABIASCO",
    province: "VA"
  },
  {
    code: "1343",
    value: "D204",
    label: "CUNARDO",
    province: "VA"
  },
  {
    code: "1344",
    value: "D217",
    label: "CURIGLIA CON MONTEVIASCO",
    province: "VA"
  },
  {
    code: "1345",
    value: "D238",
    label: "CUVEGLIO",
    province: "VA"
  },
  {
    code: "1346",
    value: "D239",
    label: "CUVIO",
    province: "VA"
  },
  {
    code: "1347",
    value: "D256",
    label: "DAVERIO",
    province: "VA"
  },
  {
    code: "1348",
    value: "D384",
    label: "DUMENZA",
    province: "VA"
  },
  {
    code: "1349",
    value: "D385",
    label: "DUNO",
    province: "VA"
  },
  {
    code: "1350",
    value: "D467",
    label: "FAGNANO OLONA",
    province: "VA"
  },
  {
    code: "1351",
    value: "D543",
    label: "FERNO",
    province: "VA"
  },
  {
    code: "1352",
    value: "D551",
    label: "FERRERA DI VARESE",
    province: "VA"
  },
  {
    code: "1353",
    value: "D869",
    label: "GALLARATE",
    province: "VA"
  },
  {
    code: "1354",
    value: "D871",
    label: "GALLIATE LOMBARDO",
    province: "VA"
  },
  {
    code: "1355",
    value: "D946",
    label: "GAVIRATE",
    province: "VA"
  },
  {
    code: "1356",
    value: "D951",
    label: "GAZZADA SCHIANNO",
    province: "VA"
  },
  {
    code: "1357",
    value: "D963",
    label: "GEMONIO",
    province: "VA"
  },
  {
    code: "1358",
    value: "D981",
    label: "GERENZANO",
    province: "VA"
  },
  {
    code: "1359",
    value: "D987",
    label: "GERMIGNAGA",
    province: "VA"
  },
  {
    code: "1360",
    value: "E079",
    label: "GOLASECCA",
    province: "VA"
  },
  {
    code: "1361",
    value: "E101",
    label: "GORLA MAGGIORE",
    province: "VA"
  },
  {
    code: "1362",
    value: "E102",
    label: "GORLA MINORE",
    province: "VA"
  },
  {
    code: "1363",
    value: "E104",
    label: "GORNATE OLONA",
    province: "VA"
  },
  {
    code: "1364",
    value: "E144",
    label: "GRANTOLA",
    province: "VA"
  },
  {
    code: "1365",
    value: "E292",
    label: "INARZO",
    province: "VA"
  },
  {
    code: "1366",
    value: "E299",
    label: "INDUNO OLONA",
    province: "VA"
  },
  {
    code: "1367",
    value: "E367",
    label: "ISPRA",
    province: "VA"
  },
  {
    code: "1368",
    value: "E386",
    label: "JERAGO CON ORAGO",
    province: "VA"
  },
  {
    code: "1369",
    value: "E494",
    label: "LAVENA PONTE TRESA",
    province: "VA"
  },
  {
    code: "1370",
    value: "E496",
    label: "LAVENO MOMBELLO",
    province: "VA"
  },
  {
    code: "1371",
    value: "E510",
    label: "LEGGIUNO",
    province: "VA"
  },
  {
    code: "1372",
    value: "E665",
    label: "LONATE CEPPINO",
    province: "VA"
  },
  {
    code: "1373",
    value: "E666",
    label: "LONATE POZZOLO",
    province: "VA"
  },
  {
    code: "1374",
    value: "E707",
    label: "LOZZA",
    province: "VA"
  },
  {
    code: "1375",
    value: "E734",
    label: "LUINO",
    province: "VA"
  },
  {
    code: "1376",
    value: "E769",
    label: "LUVINATE",
    province: "VA"
  },
  {
    code: "1378",
    value: "E856",
    label: "MALGESSO",
    province: "VA"
  },
  {
    code: "1379",
    value: "E863",
    label: "MALNATE",
    province: "VA"
  },
  {
    code: "1380",
    value: "E929",
    label: "MARCHIROLO",
    province: "VA"
  },
  {
    code: "1381",
    value: "E965",
    label: "MARNATE",
    province: "VA"
  },
  {
    code: "1382",
    value: "F002",
    label: "MARZIO",
    province: "VA"
  },
  {
    code: "1383",
    value: "F007",
    label: "MASCIAGO PRIMO",
    province: "VA"
  },
  {
    code: "1384",
    value: "F134",
    label: "MERCALLO",
    province: "VA"
  },
  {
    code: "1385",
    value: "F154",
    label: "MESENZANA",
    province: "VA"
  },
  {
    code: "1386",
    value: "F526",
    label: "MONTEGRINO VALTRAVAGLIA",
    province: "VA"
  },
  {
    code: "1387",
    value: "F703",
    label: "MONVALLE",
    province: "VA"
  },
  {
    code: "1388",
    value: "F711",
    label: "MORAZZONE",
    province: "VA"
  },
  {
    code: "1389",
    value: "F736",
    label: "MORNAGO",
    province: "VA"
  },
  {
    code: "1390",
    value: "G008",
    label: "OGGIONA CON SANTO STEFANO",
    province: "VA"
  },
  {
    code: "1391",
    value: "G028",
    label: "OLGIATE OLONA",
    province: "VA"
  },
  {
    code: "1392",
    value: "G103",
    label: "ORIGGIO",
    province: "VA"
  },
  {
    code: "1393",
    value: "G105",
    label: "ORINO",
    province: "VA"
  },
  {
    code: "1396",
    value: "G906",
    label: "PORTO CERESIO",
    province: "VA"
  },
  {
    code: "1397",
    value: "G907",
    label: "PORTO VALTRAVAGLIA",
    province: "VA"
  },
  {
    code: "1398",
    value: "H173",
    label: "RANCIO VALCUVIA",
    province: "VA"
  },
  {
    code: "1399",
    value: "H174",
    label: "RANCO",
    province: "VA"
  },
  {
    code: "1400",
    value: "H723",
    label: "SALTRIO",
    province: "VA"
  },
  {
    code: "1401",
    value: "H736",
    label: "SAMARATE",
    province: "VA"
  },
  {
    code: "1402",
    value: "I441",
    label: "SARONNO",
    province: "VA"
  },
  {
    code: "1403",
    value: "I688",
    label: "SESTO CALENDE",
    province: "VA"
  },
  {
    code: "1404",
    value: "I793",
    label: "SOLBIATE ARNO",
    province: "VA"
  },
  {
    code: "1405",
    value: "I794",
    label: "SOLBIATE OLONA",
    province: "VA"
  },
  {
    code: "1406",
    value: "I819",
    label: "SOMMA LOMBARDO",
    province: "VA"
  },
  {
    code: "1407",
    value: "L003",
    label: "SUMIRAGO",
    province: "VA"
  },
  {
    code: "1408",
    value: "L032",
    label: "TAINO",
    province: "VA"
  },
  {
    code: "1409",
    value: "L115",
    label: "TERNATE",
    province: "VA"
  },
  {
    code: "1410",
    value: "L319",
    label: "TRADATE",
    province: "VA"
  },
  {
    code: "1411",
    value: "L342",
    label: "TRAVEDONA MONATE",
    province: "VA"
  },
  {
    code: "1412",
    value: "A705",
    label: "TRONZANO LAGO MAGGIORE",
    province: "VA"
  },
  {
    code: "1413",
    value: "L480",
    label: "UBOLDO",
    province: "VA"
  },
  {
    code: "1414",
    value: "L577",
    label: "VALGANNA",
    province: "VA"
  },
  {
    code: "1415",
    value: "L671",
    label: "VARANO BORGHI",
    province: "VA"
  },
  {
    code: "1416",
    value: "L682",
    label: "VARESE",
    province: "VA"
  },
  {
    code: "1417",
    value: "L703",
    label: "VEDANO OLONA",
    province: "VA"
  },
  {
    code: "1419",
    value: "L733",
    label: "VENEGONO INFERIORE",
    province: "VA"
  },
  {
    code: "1420",
    value: "L734",
    label: "VENEGONO SUPERIORE",
    province: "VA"
  },
  {
    code: "1421",
    value: "L765",
    label: "VERGIATE",
    province: "VA"
  },
  {
    code: "1422",
    value: "L876",
    label: "VIGGIU",
    province: "VA"
  },
  {
    code: "1423",
    value: "M101",
    label: "VIZZOLA TICINO",
    province: "VA"
  },
  {
    code: "1424",
    value: "H872",
    label: "SANGIANO",
    province: "VA"
  },
  {
    code: "1425",
    value: "A143",
    label: "ALBAVILLA",
    province: "CO"
  },
  {
    code: "1426",
    value: "A153",
    label: "ALBESE CON CASSANO",
    province: "CO"
  },
  {
    code: "1427",
    value: "A164",
    label: "ALBIOLO",
    province: "CO"
  },
  {
    code: "1428",
    value: "A224",
    label: "ALSERIO",
    province: "CO"
  },
  {
    code: "1429",
    value: "A249",
    label: "ALZATE BRIANZA",
    province: "CO"
  },
  {
    code: "1430",
    value: "A319",
    label: "ANZANO DEL PARCO",
    province: "CO"
  },
  {
    code: "1431",
    value: "A333",
    label: "APPIANO GENTILE",
    province: "CO"
  },
  {
    code: "1432",
    value: "A391",
    label: "ARGEGNO",
    province: "CO"
  },
  {
    code: "1433",
    value: "A430",
    label: "AROSIO",
    province: "CO"
  },
  {
    code: "1434",
    value: "A476",
    label: "ASSO",
    province: "CO"
  },
  {
    code: "1435",
    value: "A670",
    label: "BARNI",
    province: "CO"
  },
  {
    code: "1436",
    value: "M335",
    label: "BELLAGIO",
    province: "CO"
  },
  {
    code: "1437",
    value: "A778",
    label: "BENE LARIO",
    province: "CO"
  },
  {
    code: "1438",
    value: "A791",
    label: "BEREGAZZO CON FIGLIARO",
    province: "CO"
  },
  {
    code: "1439",
    value: "A870",
    label: "BINAGO",
    province: "CO"
  },
  {
    code: "1440",
    value: "A898",
    label: "BIZZARONE",
    province: "CO"
  },
  {
    code: "1441",
    value: "A904",
    label: "BLESSAGNO",
    province: "CO"
  },
  {
    code: "1442",
    value: "A905",
    label: "BLEVIO",
    province: "CO"
  },
  {
    code: "1443",
    value: "B134",
    label: "BREGNANO",
    province: "CO"
  },
  {
    code: "1444",
    value: "B144",
    label: "BRENNA",
    province: "CO"
  },
  {
    code: "1445",
    value: "B172",
    label: "BRIENNO",
    province: "CO"
  },
  {
    code: "1446",
    value: "B218",
    label: "BRUNATE",
    province: "CO"
  },
  {
    code: "1447",
    value: "B262",
    label: "BULGAROGRASSO",
    province: "CO"
  },
  {
    code: "1448",
    value: "B313",
    label: "CABIATE",
    province: "CO"
  },
  {
    code: "1449",
    value: "B346",
    label: "CADORAGO",
    province: "CO"
  },
  {
    code: "1450",
    value: "B355",
    label: "CAGLIO",
    province: "CO"
  },
  {
    code: "1452",
    value: "B513",
    label: "CAMPIONE D ITALIA",
    province: "CO"
  },
  {
    code: "1453",
    value: "B639",
    label: "CANTU",
    province: "CO"
  },
  {
    code: "1454",
    value: "B641",
    label: "CANZO",
    province: "CO"
  },
  {
    code: "1455",
    value: "B653",
    label: "CAPIAGO INTIMIANO",
    province: "CO"
  },
  {
    code: "1456",
    value: "B730",
    label: "CARATE URIO",
    province: "CO"
  },
  {
    code: "1457",
    value: "B742",
    label: "CARBONATE",
    province: "CO"
  },
  {
    code: "1458",
    value: "B778",
    label: "CARIMATE",
    province: "CO"
  },
  {
    code: "1459",
    value: "B785",
    label: "CARLAZZO",
    province: "CO"
  },
  {
    code: "1460",
    value: "B851",
    label: "CARUGO",
    province: "CO"
  },
  {
    code: "1462",
    value: "B974",
    label: "CASLINO D ERBA",
    province: "CO"
  },
  {
    code: "1463",
    value: "B977",
    label: "CASNATE CON BERNATE",
    province: "CO"
  },
  {
    code: "1464",
    value: "C020",
    label: "CASSINA RIZZARDI",
    province: "CO"
  },
  {
    code: "1465",
    value: "C206",
    label: "CASTELMARTE",
    province: "CO"
  },
  {
    code: "1466",
    value: "C220",
    label: "CASTELNUOVO BOZZENTE",
    province: "CO"
  },
  {
    code: "1469",
    value: "C381",
    label: "CAVARGNA",
    province: "CO"
  },
  {
    code: "1470",
    value: "C482",
    label: "CERANO D INTELVI",
    province: "CO"
  },
  {
    code: "1471",
    value: "C516",
    label: "CERMENATE",
    province: "CO"
  },
  {
    code: "1472",
    value: "C520",
    label: "CERNOBBIO",
    province: "CO"
  },
  {
    code: "1473",
    value: "C724",
    label: "CIRIMIDO",
    province: "CO"
  },
  {
    code: "1475",
    value: "C787",
    label: "CLAINO CON OSTENO",
    province: "CO"
  },
  {
    code: "1476",
    value: "C902",
    label: "COLONNO",
    province: "CO"
  },
  {
    code: "1477",
    value: "C933",
    label: "COMO",
    province: "CO"
  },
  {
    code: "1479",
    value: "D041",
    label: "CORRIDO",
    province: "CO"
  },
  {
    code: "1480",
    value: "D147",
    label: "CREMIA",
    province: "CO"
  },
  {
    code: "1481",
    value: "D196",
    label: "CUCCIAGO",
    province: "CO"
  },
  {
    code: "1482",
    value: "D232",
    label: "CUSINO",
    province: "CO"
  },
  {
    code: "1483",
    value: "D310",
    label: "DIZZASCO",
    province: "CO"
  },
  {
    code: "1484",
    value: "D329",
    label: "DOMASO",
    province: "CO"
  },
  {
    code: "1485",
    value: "D341",
    label: "DONGO",
    province: "CO"
  },
  {
    code: "1486",
    value: "D355",
    label: "DOSSO DEL LIRO",
    province: "CO"
  },
  {
    code: "1488",
    value: "D416",
    label: "ERBA",
    province: "CO"
  },
  {
    code: "1489",
    value: "D445",
    label: "EUPILIO",
    province: "CO"
  },
  {
    code: "1490",
    value: "D462",
    label: "FAGGETO LARIO",
    province: "CO"
  },
  {
    code: "1491",
    value: "D482",
    label: "FALOPPIO",
    province: "CO"
  },
  {
    code: "1492",
    value: "D531",
    label: "FENEGRO",
    province: "CO"
  },
  {
    code: "1493",
    value: "D579",
    label: "FIGINO SERENZA",
    province: "CO"
  },
  {
    code: "1494",
    value: "D605",
    label: "FINO MORNASCO",
    province: "CO"
  },
  {
    code: "1495",
    value: "D930",
    label: "GARZENO",
    province: "CO"
  },
  {
    code: "1496",
    value: "D974",
    label: "GERA LARIO",
    province: "CO"
  },
  {
    code: "1499",
    value: "E139",
    label: "GRANDATE",
    province: "CO"
  },
  {
    code: "1500",
    value: "E141",
    label: "GRANDOLA ED UNITI",
    province: "CO"
  },
  {
    code: "1502",
    value: "E172",
    label: "GRIANTE",
    province: "CO"
  },
  {
    code: "1503",
    value: "E235",
    label: "GUANZATE",
    province: "CO"
  },
  {
    code: "1504",
    value: "E309",
    label: "INVERIGO",
    province: "CO"
  },
  {
    code: "1505",
    value: "E405",
    label: "LAGLIO",
    province: "CO"
  },
  {
    code: "1506",
    value: "E416",
    label: "LAINO",
    province: "CO"
  },
  {
    code: "1507",
    value: "E428",
    label: "LAMBRUGO",
    province: "CO"
  },
  {
    code: "1509",
    value: "E462",
    label: "LASNIGO",
    province: "CO"
  },
  {
    code: "1511",
    value: "E569",
    label: "LEZZENO",
    province: "CO"
  },
  {
    code: "1512",
    value: "E593",
    label: "LIMIDO COMASCO",
    province: "CO"
  },
  {
    code: "1513",
    value: "E607",
    label: "LIPOMO",
    province: "CO"
  },
  {
    code: "1514",
    value: "E623",
    label: "LIVO",
    province: "CO"
  },
  {
    code: "1515",
    value: "E638",
    label: "LOCATE VARESINO",
    province: "CO"
  },
  {
    code: "1516",
    value: "E659",
    label: "LOMAZZO",
    province: "CO"
  },
  {
    code: "1517",
    value: "E679",
    label: "LONGONE AL SEGRINO",
    province: "CO"
  },
  {
    code: "1518",
    value: "E735",
    label: "LUISAGO",
    province: "CO"
  },
  {
    code: "1519",
    value: "E749",
    label: "LURAGO D ERBA",
    province: "CO"
  },
  {
    code: "1520",
    value: "E750",
    label: "LURAGO MARINONE",
    province: "CO"
  },
  {
    code: "1521",
    value: "E753",
    label: "LURATE CACCIVIO",
    province: "CO"
  },
  {
    code: "1522",
    value: "E830",
    label: "MAGREGLIO",
    province: "CO"
  },
  {
    code: "1523",
    value: "E951",
    label: "MARIANO COMENSE",
    province: "CO"
  },
  {
    code: "1524",
    value: "F017",
    label: "MASLIANICO",
    province: "CO"
  },
  {
    code: "1525",
    value: "F120",
    label: "MENAGGIO",
    province: "CO"
  },
  {
    code: "1526",
    value: "F151",
    label: "MERONE",
    province: "CO"
  },
  {
    code: "1528",
    value: "F305",
    label: "MOLTRASIO",
    province: "CO"
  },
  {
    code: "1529",
    value: "F372",
    label: "MONGUZZO",
    province: "CO"
  },
  {
    code: "1530",
    value: "F427",
    label: "MONTANO LUCINO",
    province: "CO"
  },
  {
    code: "1531",
    value: "F564",
    label: "MONTEMEZZO",
    province: "CO"
  },
  {
    code: "1532",
    value: "F688",
    label: "MONTORFANO",
    province: "CO"
  },
  {
    code: "1533",
    value: "F788",
    label: "MOZZATE",
    province: "CO"
  },
  {
    code: "1534",
    value: "F828",
    label: "MUSSO",
    province: "CO"
  },
  {
    code: "1535",
    value: "F877",
    label: "NESSO",
    province: "CO"
  },
  {
    code: "1536",
    value: "F958",
    label: "NOVEDRATE",
    province: "CO"
  },
  {
    code: "1537",
    value: "G025",
    label: "OLGIATE COMASCO",
    province: "CO"
  },
  {
    code: "1538",
    value: "G056",
    label: "OLTRONA DI SAN MAMETTE",
    province: "CO"
  },
  {
    code: "1539",
    value: "G126",
    label: "ORSENIGO",
    province: "CO"
  },
  {
    code: "1542",
    value: "G415",
    label: "PEGLIO",
    province: "CO"
  },
  {
    code: "1544",
    value: "G556",
    label: "PIANELLO DEL LARIO",
    province: "CO"
  },
  {
    code: "1545",
    value: "G665",
    label: "PIGRA",
    province: "CO"
  },
  {
    code: "1546",
    value: "G737",
    label: "PLESIO",
    province: "CO"
  },
  {
    code: "1547",
    value: "G773",
    label: "POGNANA LARIO",
    province: "CO"
  },
  {
    code: "1548",
    value: "G821",
    label: "PONNA",
    province: "CO"
  },
  {
    code: "1549",
    value: "G847",
    label: "PONTE LAMBRO",
    province: "CO"
  },
  {
    code: "1550",
    value: "G889",
    label: "PORLEZZA",
    province: "CO"
  },
  {
    code: "1551",
    value: "H074",
    label: "PROSERPIO",
    province: "CO"
  },
  {
    code: "1552",
    value: "H094",
    label: "PUSIANO",
    province: "CO"
  },
  {
    code: "1554",
    value: "H255",
    label: "REZZAGO",
    province: "CO"
  },
  {
    code: "1555",
    value: "H478",
    label: "RODERO",
    province: "CO"
  },
  {
    code: "1556",
    value: "H521",
    label: "RONAGO",
    province: "CO"
  },
  {
    code: "1557",
    value: "H601",
    label: "ROVELLASCA",
    province: "CO"
  },
  {
    code: "1558",
    value: "H602",
    label: "ROVELLO PORRO",
    province: "CO"
  },
  {
    code: "1559",
    value: "H679",
    label: "SALA COMACINA",
    province: "CO"
  },
  {
    code: "1560",
    value: "H760",
    label: "SAN BARTOLOMEO VAL CAVARGNA",
    province: "CO"
  },
  {
    code: "1562",
    value: "H840",
    label: "SAN FERMO DELLA BATTAGLIA",
    province: "CO"
  },
  {
    code: "1563",
    value: "I051",
    label: "SAN NAZZARO VAL CAVARGNA",
    province: "CO"
  },
  {
    code: "1566",
    value: "I529",
    label: "SCHIGNANO",
    province: "CO"
  },
  {
    code: "1567",
    value: "I611",
    label: "SENNA COMASCO",
    province: "CO"
  },
  {
    code: "1569",
    value: "I856",
    label: "SORICO",
    province: "CO"
  },
  {
    code: "1570",
    value: "I860",
    label: "SORMANO",
    province: "CO"
  },
  {
    code: "1571",
    value: "I943",
    label: "STAZZONA",
    province: "CO"
  },
  {
    code: "1572",
    value: "L071",
    label: "TAVERNERIO",
    province: "CO"
  },
  {
    code: "1573",
    value: "L228",
    label: "TORNO",
    province: "CO"
  },
  {
    code: "1575",
    value: "L413",
    label: "TREZZONE",
    province: "CO"
  },
  {
    code: "1576",
    value: "L470",
    label: "TURATE",
    province: "CO"
  },
  {
    code: "1577",
    value: "L487",
    label: "UGGIATE TREVANO",
    province: "CO"
  },
  {
    code: "1578",
    value: "L547",
    label: "VALBRONA",
    province: "CO"
  },
  {
    code: "1579",
    value: "L640",
    label: "VALMOREA",
    province: "CO"
  },
  {
    code: "1580",
    value: "H259",
    label: "VAL REZZO",
    province: "CO"
  },
  {
    code: "1581",
    value: "C936",
    label: "VALSOLDA",
    province: "CO"
  },
  {
    code: "1582",
    value: "L715",
    label: "VELESO",
    province: "CO"
  },
  {
    code: "1583",
    value: "L737",
    label: "VENIANO",
    province: "CO"
  },
  {
    code: "1584",
    value: "L748",
    label: "VERCANA",
    province: "CO"
  },
  {
    code: "1585",
    value: "L792",
    label: "VERTEMATE CON MINOPRIO",
    province: "CO"
  },
  {
    code: "1586",
    value: "L956",
    label: "VILLA GUARDIA",
    province: "CO"
  },
  {
    code: "1587",
    value: "M156",
    label: "ZELBIO",
    province: "CO"
  },
  {
    code: "1588",
    value: "A135",
    label: "ALBAREDO PER SAN MARCO",
    province: "SO"
  },
  {
    code: "1589",
    value: "A172",
    label: "ALBOSAGGIA",
    province: "SO"
  },
  {
    code: "1590",
    value: "A273",
    label: "ANDALO VALTELLINO",
    province: "SO"
  },
  {
    code: "1591",
    value: "A337",
    label: "APRICA",
    province: "SO"
  },
  {
    code: "1592",
    value: "A382",
    label: "ARDENNO",
    province: "SO"
  },
  {
    code: "1593",
    value: "A777",
    label: "BEMA",
    province: "SO"
  },
  {
    code: "1594",
    value: "A787",
    label: "BERBENNO DI VALTELLINA",
    province: "SO"
  },
  {
    code: "1595",
    value: "A848",
    label: "BIANZONE",
    province: "SO"
  },
  {
    code: "1596",
    value: "B049",
    label: "BORMIO",
    province: "SO"
  },
  {
    code: "1597",
    value: "B255",
    label: "BUGLIO IN MONTE",
    province: "SO"
  },
  {
    code: "1598",
    value: "B366",
    label: "CAIOLO",
    province: "SO"
  },
  {
    code: "1599",
    value: "B530",
    label: "CAMPODOLCINO",
    province: "SO"
  },
  {
    code: "1600",
    value: "B993",
    label: "CASPOGGIO",
    province: "SO"
  },
  {
    code: "1601",
    value: "C186",
    label: "CASTELLO DELL ACQUA",
    province: "SO"
  },
  {
    code: "1602",
    value: "C325",
    label: "CASTIONE ANDEVENNO",
    province: "SO"
  },
  {
    code: "1603",
    value: "C418",
    label: "CEDRASCO",
    province: "SO"
  },
  {
    code: "1604",
    value: "C493",
    label: "CERCINO",
    province: "SO"
  },
  {
    code: "1605",
    value: "C623",
    label: "CHIAVENNA",
    province: "SO"
  },
  {
    code: "1606",
    value: "C628",
    label: "CHIESA IN VALMALENCO",
    province: "SO"
  },
  {
    code: "1607",
    value: "C651",
    label: "CHIURO",
    province: "SO"
  },
  {
    code: "1608",
    value: "C709",
    label: "CINO",
    province: "SO"
  },
  {
    code: "1609",
    value: "C785",
    label: "CIVO",
    province: "SO"
  },
  {
    code: "1610",
    value: "C903",
    label: "COLORINA",
    province: "SO"
  },
  {
    code: "1611",
    value: "D088",
    label: "COSIO VALTELLINO",
    province: "SO"
  },
  {
    code: "1612",
    value: "D258",
    label: "DAZIO",
    province: "SO"
  },
  {
    code: "1613",
    value: "D266",
    label: "DELEBIO",
    province: "SO"
  },
  {
    code: "1614",
    value: "D377",
    label: "DUBINO",
    province: "SO"
  },
  {
    code: "1615",
    value: "D456",
    label: "FAEDO VALTELLINO",
    province: "SO"
  },
  {
    code: "1616",
    value: "D694",
    label: "FORCOLA",
    province: "SO"
  },
  {
    code: "1617",
    value: "D830",
    label: "FUSINE",
    province: "SO"
  },
  {
    code: "1618",
    value: "D990",
    label: "GEROLA ALTA",
    province: "SO"
  },
  {
    code: "1619",
    value: "E090",
    label: "GORDONA",
    province: "SO"
  },
  {
    code: "1620",
    value: "E200",
    label: "GROSIO",
    province: "SO"
  },
  {
    code: "1621",
    value: "E201",
    label: "GROSOTTO",
    province: "SO"
  },
  {
    code: "1622",
    value: "E342",
    label: "MADESIMO",
    province: "SO"
  },
  {
    code: "1623",
    value: "E443",
    label: "LANZADA",
    province: "SO"
  },
  {
    code: "1624",
    value: "E621",
    label: "LIVIGNO",
    province: "SO"
  },
  {
    code: "1625",
    value: "E705",
    label: "LOVERO",
    province: "SO"
  },
  {
    code: "1626",
    value: "E896",
    label: "MANTELLO",
    province: "SO"
  },
  {
    code: "1627",
    value: "F070",
    label: "MAZZO DI VALTELLINA",
    province: "SO"
  },
  {
    code: "1628",
    value: "F115",
    label: "MELLO",
    province: "SO"
  },
  {
    code: "1630",
    value: "F153",
    label: "MESE",
    province: "SO"
  },
  {
    code: "1631",
    value: "F393",
    label: "MONTAGNA IN VALTELLINA",
    province: "SO"
  },
  {
    code: "1632",
    value: "F712",
    label: "MORBEGNO",
    province: "SO"
  },
  {
    code: "1633",
    value: "F956",
    label: "NOVATE MEZZOLA",
    province: "SO"
  },
  {
    code: "1634",
    value: "G410",
    label: "PEDESINA",
    province: "SO"
  },
  {
    code: "1635",
    value: "G572",
    label: "PIANTEDO",
    province: "SO"
  },
  {
    code: "1636",
    value: "G576",
    label: "PIATEDA",
    province: "SO"
  },
  {
    code: "1637",
    value: "G718",
    label: "PIURO",
    province: "SO"
  },
  {
    code: "1638",
    value: "G431",
    label: "POGGIRIDENTI",
    province: "SO"
  },
  {
    code: "1639",
    value: "G829",
    label: "PONTE IN VALTELLINA",
    province: "SO"
  },
  {
    code: "1640",
    value: "G937",
    label: "POSTALESIO",
    province: "SO"
  },
  {
    code: "1641",
    value: "G993",
    label: "PRATA CAMPORTACCIO",
    province: "SO"
  },
  {
    code: "1642",
    value: "H192",
    label: "RASURA",
    province: "SO"
  },
  {
    code: "1643",
    value: "H493",
    label: "ROGOLO",
    province: "SO"
  },
  {
    code: "1644",
    value: "H752",
    label: "SAMOLACO",
    province: "SO"
  },
  {
    code: "1645",
    value: "H868",
    label: "SAN GIACOMO FILIPPO",
    province: "SO"
  },
  {
    code: "1646",
    value: "I636",
    label: "SERNIO",
    province: "SO"
  },
  {
    code: "1647",
    value: "I828",
    label: "SONDALO",
    province: "SO"
  },
  {
    code: "1648",
    value: "I829",
    label: "SONDRIO",
    province: "SO"
  },
  {
    code: "1649",
    value: "I928",
    label: "SPRIANA",
    province: "SO"
  },
  {
    code: "1650",
    value: "L035",
    label: "TALAMONA",
    province: "SO"
  },
  {
    code: "1651",
    value: "L056",
    label: "TARTANO",
    province: "SO"
  },
  {
    code: "1652",
    value: "L084",
    label: "TEGLIO",
    province: "SO"
  },
  {
    code: "1653",
    value: "L175",
    label: "TIRANO",
    province: "SO"
  },
  {
    code: "1654",
    value: "L244",
    label: "TORRE DI SANTA MARIA",
    province: "SO"
  },
  {
    code: "1655",
    value: "L316",
    label: "TOVO DI SANT AGATA",
    province: "SO"
  },
  {
    code: "1656",
    value: "L330",
    label: "TRAONA",
    province: "SO"
  },
  {
    code: "1657",
    value: "L392",
    label: "TRESIVIO",
    province: "SO"
  },
  {
    code: "1658",
    value: "L557",
    label: "VALDIDENTRO",
    province: "SO"
  },
  {
    code: "1659",
    value: "L563",
    label: "VALDISOTTO",
    province: "SO"
  },
  {
    code: "1660",
    value: "L576",
    label: "VALFURVA",
    province: "SO"
  },
  {
    code: "1661",
    value: "L638",
    label: "VAL MASINO",
    province: "SO"
  },
  {
    code: "1662",
    value: "L749",
    label: "VERCEIA",
    province: "SO"
  },
  {
    code: "1663",
    value: "L799",
    label: "VERVIO",
    province: "SO"
  },
  {
    code: "1664",
    value: "L907",
    label: "VILLA DI CHIAVENNA",
    province: "SO"
  },
  {
    code: "1665",
    value: "L908",
    label: "VILLA DI TIRANO",
    province: "SO"
  },
  {
    code: "1666",
    value: "A010",
    label: "ABBIATEGRASSO",
    province: "MI"
  },
  {
    code: "1667",
    value: "A087",
    label: "AGRATE BRIANZA",
    province: "MB"
  },
  {
    code: "1668",
    value: "A096",
    label: "AICURZIO",
    province: "MB"
  },
  {
    code: "1669",
    value: "A127",
    label: "ALBAIRATE",
    province: "MI"
  },
  {
    code: "1670",
    value: "A159",
    label: "ALBIATE",
    province: "MB"
  },
  {
    code: "1671",
    value: "A375",
    label: "ARCONATE",
    province: "MI"
  },
  {
    code: "1672",
    value: "A376",
    label: "ARCORE",
    province: "MB"
  },
  {
    code: "1673",
    value: "A389",
    label: "ARESE",
    province: "MI"
  },
  {
    code: "1674",
    value: "A413",
    label: "ARLUNO",
    province: "MI"
  },
  {
    code: "1675",
    value: "A473",
    label: "ASSAGO",
    province: "MI"
  },
  {
    code: "1676",
    value: "A652",
    label: "BAREGGIO",
    province: "MI"
  },
  {
    code: "1677",
    value: "A668",
    label: "BARLASSINA",
    province: "MB"
  },
  {
    code: "1678",
    value: "A697",
    label: "BASIANO",
    province: "MI"
  },
  {
    code: "1679",
    value: "A699",
    label: "BASIGLIO",
    province: "MI"
  },
  {
    code: "1680",
    value: "A751",
    label: "BELLINZAGO LOMBARDO",
    province: "MI"
  },
  {
    code: "1681",
    value: "A759",
    label: "BELLUSCO",
    province: "MB"
  },
  {
    code: "1682",
    value: "A802",
    label: "BERNAREGGIO",
    province: "MB"
  },
  {
    code: "1683",
    value: "A804",
    label: "BERNATE TICINO",
    province: "MI"
  },
  {
    code: "1684",
    value: "A818",
    label: "BESANA IN BRIANZA",
    province: "MB"
  },
  {
    code: "1685",
    value: "A820",
    label: "BESATE",
    province: "MI"
  },
  {
    code: "1686",
    value: "A849",
    label: "BIASSONO",
    province: "MB"
  },
  {
    code: "1687",
    value: "A872",
    label: "BINASCO",
    province: "MI"
  },
  {
    code: "1688",
    value: "A920",
    label: "BOFFALORA SOPRA TICINO",
    province: "MI"
  },
  {
    code: "1689",
    value: "A940",
    label: "BOLLATE",
    province: "MI"
  },
  {
    code: "1690",
    value: "B105",
    label: "BOVISIO MASCIAGO",
    province: "MB"
  },
  {
    code: "1691",
    value: "B162",
    label: "BRESSO",
    province: "MI"
  },
  {
    code: "1692",
    value: "B187",
    label: "BRIOSCO",
    province: "MB"
  },
  {
    code: "1693",
    value: "B212",
    label: "BRUGHERIO",
    province: "MB"
  },
  {
    code: "1694",
    value: "B235",
    label: "BUBBIANO",
    province: "MI"
  },
  {
    code: "1695",
    value: "B240",
    label: "BUCCINASCO",
    province: "MI"
  },
  {
    code: "1696",
    value: "B272",
    label: "BURAGO DI MOLGORA",
    province: "MB"
  },
  {
    code: "1697",
    value: "B286",
    label: "BUSCATE",
    province: "MI"
  },
  {
    code: "1698",
    value: "B289",
    label: "BUSNAGO",
    province: "MB"
  },
  {
    code: "1699",
    value: "B292",
    label: "BUSSERO",
    province: "MI"
  },
  {
    code: "1700",
    value: "B301",
    label: "BUSTO GAROLFO",
    province: "MI"
  },
  {
    code: "1701",
    value: "B448",
    label: "CALVIGNASCO",
    province: "MI"
  },
  {
    code: "1702",
    value: "B461",
    label: "CAMBIAGO",
    province: "MI"
  },
  {
    code: "1703",
    value: "B501",
    label: "CAMPARADA",
    province: "MB"
  },
  {
    code: "1704",
    value: "B593",
    label: "CANEGRATE",
    province: "MI"
  },
  {
    code: "1705",
    value: "B671",
    label: "CAPONAGO",
    province: "MB"
  },
  {
    code: "1706",
    value: "B729",
    label: "CARATE BRIANZA",
    province: "MB"
  },
  {
    code: "1707",
    value: "B798",
    label: "CARNATE",
    province: "MB"
  },
  {
    code: "1708",
    value: "B820",
    label: "CARPIANO",
    province: "MI"
  },
  {
    code: "1709",
    value: "B850",
    label: "CARUGATE",
    province: "MI"
  },
  {
    code: "1710",
    value: "B938",
    label: "CASARILE",
    province: "MI"
  },
  {
    code: "1711",
    value: "B989",
    label: "CASOREZZO",
    province: "MI"
  },
  {
    code: "1712",
    value: "C003",
    label: "CASSANO D ADDA",
    province: "MI"
  },
  {
    code: "1713",
    value: "C014",
    label: "CASSINA DE PECCHI",
    province: "MI"
  },
  {
    code: "1714",
    value: "C033",
    label: "CASSINETTA DI LUGAGNANO",
    province: "MI"
  },
  {
    code: "1715",
    value: "C052",
    label: "CASTANO PRIMO",
    province: "MI"
  },
  {
    code: "1716",
    value: "C395",
    label: "CAVENAGO DI BRIANZA",
    province: "MB"
  },
  {
    code: "1717",
    value: "C512",
    label: "CERIANO LAGHETTO",
    province: "MB"
  },
  {
    code: "1718",
    value: "C523",
    label: "CERNUSCO SUL NAVIGLIO",
    province: "MI"
  },
  {
    code: "1719",
    value: "C536",
    label: "CERRO AL LAMBRO",
    province: "MI"
  },
  {
    code: "1720",
    value: "C537",
    label: "CERRO MAGGIORE",
    province: "MI"
  },
  {
    code: "1721",
    value: "C565",
    label: "CESANO BOSCONE",
    province: "MI"
  },
  {
    code: "1722",
    value: "C566",
    label: "CESANO MADERNO",
    province: "MB"
  },
  {
    code: "1723",
    value: "C569",
    label: "CESATE",
    province: "MI"
  },
  {
    code: "1724",
    value: "C707",
    label: "CINISELLO BALSAMO",
    province: "MI"
  },
  {
    code: "1725",
    value: "C733",
    label: "CISLIANO",
    province: "MI"
  },
  {
    code: "1726",
    value: "C820",
    label: "COGLIATE",
    province: "MB"
  },
  {
    code: "1727",
    value: "C895",
    label: "COLOGNO MONZESE",
    province: "MI"
  },
  {
    code: "1728",
    value: "C908",
    label: "COLTURANO",
    province: "MI"
  },
  {
    code: "1729",
    value: "C952",
    label: "CONCOREZZO",
    province: "MB"
  },
  {
    code: "1730",
    value: "C986",
    label: "CORBETTA",
    province: "MI"
  },
  {
    code: "1731",
    value: "D013",
    label: "CORMANO",
    province: "MI"
  },
  {
    code: "1732",
    value: "D018",
    label: "CORNAREDO",
    province: "MI"
  },
  {
    code: "1733",
    value: "D019",
    label: "CORNATE D ADDA",
    province: "MB"
  },
  {
    code: "1734",
    value: "D038",
    label: "CORREZZANA",
    province: "MB"
  },
  {
    code: "1735",
    value: "D045",
    label: "CORSICO",
    province: "MI"
  },
  {
    code: "1736",
    value: "D198",
    label: "CUGGIONO",
    province: "MI"
  },
  {
    code: "1737",
    value: "D229",
    label: "CUSAGO",
    province: "MI"
  },
  {
    code: "1738",
    value: "D231",
    label: "CUSANO MILANINO",
    province: "MI"
  },
  {
    code: "1739",
    value: "D244",
    label: "DAIRAGO",
    province: "MI"
  },
  {
    code: "1740",
    value: "D286",
    label: "DESIO",
    province: "MB"
  },
  {
    code: "1741",
    value: "D367",
    label: "DRESANO",
    province: "MI"
  },
  {
    code: "1742",
    value: "D845",
    label: "GAGGIANO",
    province: "MI"
  },
  {
    code: "1743",
    value: "D912",
    label: "GARBAGNATE MILANESE",
    province: "MI"
  },
  {
    code: "1744",
    value: "D995",
    label: "GESSATE",
    province: "MI"
  },
  {
    code: "1745",
    value: "E063",
    label: "GIUSSANO",
    province: "MB"
  },
  {
    code: "1746",
    value: "E094",
    label: "GORGONZOLA",
    province: "MI"
  },
  {
    code: "1747",
    value: "E170",
    label: "GREZZAGO",
    province: "MI"
  },
  {
    code: "1748",
    value: "E258",
    label: "GUDO VISCONTI",
    province: "MI"
  },
  {
    code: "1749",
    value: "E313",
    label: "INVERUNO",
    province: "MI"
  },
  {
    code: "1750",
    value: "E317",
    label: "INZAGO",
    province: "MI"
  },
  {
    code: "1751",
    value: "E395",
    label: "LACCHIARELLA",
    province: "MI"
  },
  {
    code: "1752",
    value: "E415",
    label: "LAINATE",
    province: "MI"
  },
  {
    code: "1753",
    value: "E504",
    label: "LAZZATE",
    province: "MB"
  },
  {
    code: "1754",
    value: "E514",
    label: "LEGNANO",
    province: "MI"
  },
  {
    code: "1755",
    value: "E530",
    label: "LENTATE SUL SEVESO",
    province: "MB"
  },
  {
    code: "1756",
    value: "E550",
    label: "LESMO",
    province: "MB"
  },
  {
    code: "1757",
    value: "E591",
    label: "LIMBIATE",
    province: "MB"
  },
  {
    code: "1758",
    value: "E610",
    label: "LISCATE",
    province: "MI"
  },
  {
    code: "1759",
    value: "E617",
    label: "LISSONE",
    province: "MB"
  },
  {
    code: "1760",
    value: "E639",
    label: "LOCATE DI TRIULZI",
    province: "MI"
  },
  {
    code: "1761",
    value: "E786",
    label: "MACHERIO",
    province: "MB"
  },
  {
    code: "1762",
    value: "E801",
    label: "MAGENTA",
    province: "MI"
  },
  {
    code: "1763",
    value: "E819",
    label: "MAGNAGO",
    province: "MI"
  },
  {
    code: "1764",
    value: "E921",
    label: "MARCALLO CON CASONE",
    province: "MI"
  },
  {
    code: "1765",
    value: "F003",
    label: "MASATE",
    province: "MI"
  },
  {
    code: "1766",
    value: "F078",
    label: "MEDA",
    province: "MB"
  },
  {
    code: "1767",
    value: "F084",
    label: "MEDIGLIA",
    province: "MI"
  },
  {
    code: "1768",
    value: "F100",
    label: "MELEGNANO",
    province: "MI"
  },
  {
    code: "1769",
    value: "F119",
    label: "MELZO",
    province: "MI"
  },
  {
    code: "1770",
    value: "F155",
    label: "MESERO",
    province: "MI"
  },
  {
    code: "1771",
    value: "F165",
    label: "MEZZAGO",
    province: "MB"
  },
  {
    code: "1772",
    value: "F205",
    label: "MILANO",
    province: "MI"
  },
  {
    code: "1773",
    value: "F247",
    label: "MISINTO",
    province: "MB"
  },
  {
    code: "1774",
    value: "F704",
    label: "MONZA",
    province: "MB"
  },
  {
    code: "1775",
    value: "D033",
    label: "MORIMONDO",
    province: "MI"
  },
  {
    code: "1776",
    value: "F783",
    label: "MOTTA VISCONTI",
    province: "MI"
  },
  {
    code: "1777",
    value: "F797",
    label: "MUGGIO",
    province: "MB"
  },
  {
    code: "1778",
    value: "F874",
    label: "NERVIANO",
    province: "MI"
  },
  {
    code: "1779",
    value: "F939",
    label: "NOSATE",
    province: "MI"
  },
  {
    code: "1780",
    value: "F944",
    label: "NOVA MILANESE",
    province: "MB"
  },
  {
    code: "1781",
    value: "F955",
    label: "NOVATE MILANESE",
    province: "MI"
  },
  {
    code: "1782",
    value: "F968",
    label: "NOVIGLIO",
    province: "MI"
  },
  {
    code: "1783",
    value: "G078",
    label: "OPERA",
    province: "MI"
  },
  {
    code: "1784",
    value: "G116",
    label: "ORNAGO",
    province: "MB"
  },
  {
    code: "1785",
    value: "G181",
    label: "OSSONA",
    province: "MI"
  },
  {
    code: "1786",
    value: "G206",
    label: "OZZERO",
    province: "MI"
  },
  {
    code: "1787",
    value: "G220",
    label: "PADERNO DUGNANO",
    province: "MI"
  },
  {
    code: "1788",
    value: "G316",
    label: "PANTIGLIATE",
    province: "MI"
  },
  {
    code: "1789",
    value: "G324",
    label: "PARABIAGO",
    province: "MI"
  },
  {
    code: "1790",
    value: "G385",
    label: "PAULLO",
    province: "MI"
  },
  {
    code: "1791",
    value: "C013",
    label: "PERO",
    province: "MI"
  },
  {
    code: "1792",
    value: "G488",
    label: "PESCHIERA BORROMEO",
    province: "MI"
  },
  {
    code: "1793",
    value: "G502",
    label: "PESSANO CON BORNAGO",
    province: "MI"
  },
  {
    code: "1794",
    value: "G634",
    label: "PIEVE EMANUELE",
    province: "MI"
  },
  {
    code: "1795",
    value: "G686",
    label: "PIOLTELLO",
    province: "MI"
  },
  {
    code: "1796",
    value: "G772",
    label: "POGLIANO MILANESE",
    province: "MI"
  },
  {
    code: "1797",
    value: "G955",
    label: "POZZO D ADDA",
    province: "MI"
  },
  {
    code: "1798",
    value: "G965",
    label: "POZZUOLO MARTESANA",
    province: "MI"
  },
  {
    code: "1799",
    value: "H026",
    label: "PREGNANA MILANESE",
    province: "MI"
  },
  {
    code: "1800",
    value: "H233",
    label: "RENATE",
    province: "MB"
  },
  {
    code: "1801",
    value: "H240",
    label: "RESCALDINA",
    province: "MI"
  },
  {
    code: "1802",
    value: "H264",
    label: "RHO",
    province: "MI"
  },
  {
    code: "1803",
    value: "H371",
    label: "ROBECCHETTO CON INDUNO",
    province: "MI"
  },
  {
    code: "1804",
    value: "H373",
    label: "ROBECCO SUL NAVIGLIO",
    province: "MI"
  },
  {
    code: "1805",
    value: "H470",
    label: "RODANO",
    province: "MI"
  },
  {
    code: "1806",
    value: "H529",
    label: "RONCELLO",
    province: "MB"
  },
  {
    code: "1807",
    value: "H537",
    label: "RONCO BRIANTINO",
    province: "MB"
  },
  {
    code: "1808",
    value: "H560",
    label: "ROSATE",
    province: "MI"
  },
  {
    code: "1809",
    value: "H623",
    label: "ROZZANO",
    province: "MI"
  },
  {
    code: "1810",
    value: "H803",
    label: "SAN COLOMBANO AL LAMBRO",
    province: "MI"
  },
  {
    code: "1811",
    value: "H827",
    label: "SAN DONATO MILANESE",
    province: "MI"
  },
  {
    code: "1812",
    value: "H884",
    label: "SAN GIORGIO SU LEGNANO",
    province: "MI"
  },
  {
    code: "1813",
    value: "H930",
    label: "SAN GIULIANO MILANESE",
    province: "MI"
  },
  {
    code: "1814",
    value: "I361",
    label: "SANTO STEFANO TICINO",
    province: "MI"
  },
  {
    code: "1815",
    value: "I409",
    label: "SAN VITTORE OLONA",
    province: "MI"
  },
  {
    code: "1816",
    value: "I415",
    label: "SAN ZENONE AL LAMBRO",
    province: "MI"
  },
  {
    code: "1817",
    value: "I566",
    label: "SEDRIANO",
    province: "MI"
  },
  {
    code: "1818",
    value: "I577",
    label: "SEGRATE",
    province: "MI"
  },
  {
    code: "1819",
    value: "I602",
    label: "SENAGO",
    province: "MI"
  },
  {
    code: "1820",
    value: "I625",
    label: "SEREGNO",
    province: "MB"
  },
  {
    code: "1821",
    value: "I690",
    label: "SESTO SAN GIOVANNI",
    province: "MI"
  },
  {
    code: "1822",
    value: "I696",
    label: "SETTALA",
    province: "MI"
  },
  {
    code: "1823",
    value: "I700",
    label: "SETTIMO MILANESE",
    province: "MI"
  },
  {
    code: "1824",
    value: "I709",
    label: "SEVESO",
    province: "MB"
  },
  {
    code: "1825",
    value: "I786",
    label: "SOLARO",
    province: "MI"
  },
  {
    code: "1826",
    value: "I878",
    label: "SOVICO",
    province: "MB"
  },
  {
    code: "1827",
    value: "I998",
    label: "SULBIATE",
    province: "MB"
  },
  {
    code: "1828",
    value: "L408",
    label: "TREZZANO ROSA",
    province: "MI"
  },
  {
    code: "1829",
    value: "L409",
    label: "TREZZANO SUL NAVIGLIO",
    province: "MI"
  },
  {
    code: "1830",
    value: "L411",
    label: "TREZZO SULL ADDA",
    province: "MI"
  },
  {
    code: "1831",
    value: "L415",
    label: "TRIBIANO",
    province: "MI"
  },
  {
    code: "1832",
    value: "L434",
    label: "TRIUGGIO",
    province: "MB"
  },
  {
    code: "1833",
    value: "L454",
    label: "TRUCCAZZANO",
    province: "MI"
  },
  {
    code: "1834",
    value: "L471",
    label: "TURBIGO",
    province: "MI"
  },
  {
    code: "1835",
    value: "L511",
    label: "USMATE VELATE",
    province: "MB"
  },
  {
    code: "1836",
    value: "L665",
    label: "VANZAGO",
    province: "MI"
  },
  {
    code: "1837",
    value: "L667",
    label: "VAPRIO D ADDA",
    province: "MI"
  },
  {
    code: "1838",
    value: "L677",
    label: "VAREDO",
    province: "MB"
  },
  {
    code: "1839",
    value: "L704",
    label: "VEDANO AL LAMBRO",
    province: "MB"
  },
  {
    code: "1840",
    value: "L709",
    label: "VEDUGGIO CON COLZANO",
    province: "MB"
  },
  {
    code: "1841",
    value: "L744",
    label: "VERANO BRIANZA",
    province: "MB"
  },
  {
    code: "1843",
    value: "L773",
    label: "VERNATE",
    province: "MI"
  },
  {
    code: "1844",
    value: "L883",
    label: "VIGNATE",
    province: "MI"
  },
  {
    code: "1845",
    value: "M017",
    label: "VILLASANTA",
    province: "MB"
  },
  {
    code: "1846",
    value: "M052",
    label: "VIMERCATE",
    province: "MB"
  },
  {
    code: "1847",
    value: "M053",
    label: "VIMODRONE",
    province: "MI"
  },
  {
    code: "1848",
    value: "M091",
    label: "VITTUONE",
    province: "MI"
  },
  {
    code: "1849",
    value: "M102",
    label: "VIZZOLO PREDABISSI",
    province: "MI"
  },
  {
    code: "1851",
    value: "M176",
    label: "ZIBIDO SAN GIACOMO",
    province: "MI"
  },
  {
    code: "1852",
    value: "L928",
    label: "VILLA CORTESE",
    province: "MI"
  },
  {
    code: "1853",
    value: "L664",
    label: "VANZAGHELLO",
    province: "MI"
  },
  {
    code: "1854",
    value: "A057",
    label: "ADRARA SAN MARTINO",
    province: "BG"
  },
  {
    code: "1855",
    value: "A058",
    label: "ADRARA SAN ROCCO",
    province: "BG"
  },
  {
    code: "1856",
    value: "A129",
    label: "ALBANO SANT ALESSANDRO",
    province: "BG"
  },
  {
    code: "1857",
    value: "A163",
    label: "ALBINO",
    province: "BG"
  },
  {
    code: "1858",
    value: "A214",
    label: "ALME",
    province: "BG"
  },
  {
    code: "1859",
    value: "A216",
    label: "ALMENNO SAN BARTOLOMEO",
    province: "BG"
  },
  {
    code: "1860",
    value: "A217",
    label: "ALMENNO SAN SALVATORE",
    province: "BG"
  },
  {
    code: "1861",
    value: "A246",
    label: "ALZANO LOMBARDO",
    province: "BG"
  },
  {
    code: "1862",
    value: "A259",
    label: "AMBIVERE",
    province: "BG"
  },
  {
    code: "1863",
    value: "A304",
    label: "ANTEGNATE",
    province: "BG"
  },
  {
    code: "1864",
    value: "A365",
    label: "ARCENE",
    province: "BG"
  },
  {
    code: "1865",
    value: "A383",
    label: "ARDESIO",
    province: "BG"
  },
  {
    code: "1866",
    value: "A440",
    label: "ARZAGO D ADDA",
    province: "BG"
  },
  {
    code: "1867",
    value: "A511",
    label: "AVERARA",
    province: "BG"
  },
  {
    code: "1868",
    value: "A517",
    label: "AVIATICO",
    province: "BG"
  },
  {
    code: "1869",
    value: "A528",
    label: "AZZANO SAN PAOLO",
    province: "BG"
  },
  {
    code: "1870",
    value: "A533",
    label: "AZZONE",
    province: "BG"
  },
  {
    code: "1871",
    value: "A557",
    label: "BAGNATICA",
    province: "BG"
  },
  {
    code: "1872",
    value: "A631",
    label: "BARBATA",
    province: "BG"
  },
  {
    code: "1873",
    value: "A664",
    label: "BARIANO",
    province: "BG"
  },
  {
    code: "1874",
    value: "A684",
    label: "BARZANA",
    province: "BG"
  },
  {
    code: "1875",
    value: "A732",
    label: "BEDULITA",
    province: "BG"
  },
  {
    code: "1876",
    value: "A786",
    label: "BERBENNO",
    province: "BG"
  },
  {
    code: "1877",
    value: "A794",
    label: "BERGAMO",
    province: "BG"
  },
  {
    code: "1878",
    value: "A815",
    label: "BERZO SAN FERMO",
    province: "BG"
  },
  {
    code: "1879",
    value: "A846",
    label: "BIANZANO",
    province: "BG"
  },
  {
    code: "1880",
    value: "A903",
    label: "BLELLO",
    province: "BG"
  },
  {
    code: "1881",
    value: "A937",
    label: "BOLGARE",
    province: "BG"
  },
  {
    code: "1882",
    value: "A950",
    label: "BOLTIERE",
    province: "BG"
  },
  {
    code: "1883",
    value: "A963",
    label: "BONATE SOPRA",
    province: "BG"
  },
  {
    code: "1884",
    value: "A962",
    label: "BONATE SOTTO",
    province: "BG"
  },
  {
    code: "1885",
    value: "B010",
    label: "BORGO DI TERZO",
    province: "BG"
  },
  {
    code: "1886",
    value: "B083",
    label: "BOSSICO",
    province: "BG"
  },
  {
    code: "1887",
    value: "B088",
    label: "BOTTANUCO",
    province: "BG"
  },
  {
    code: "1888",
    value: "B112",
    label: "BRACCA",
    province: "BG"
  },
  {
    code: "1889",
    value: "B123",
    label: "BRANZI",
    province: "BG"
  },
  {
    code: "1890",
    value: "B137",
    label: "BREMBATE",
    province: "BG"
  },
  {
    code: "1891",
    value: "B138",
    label: "BREMBATE DI SOPRA",
    province: "BG"
  },
  {
    code: "1893",
    value: "B178",
    label: "BRIGNANO GERA D ADDA",
    province: "BG"
  },
  {
    code: "1894",
    value: "B217",
    label: "BRUMANO",
    province: "BG"
  },
  {
    code: "1895",
    value: "B223",
    label: "BRUSAPORTO",
    province: "BG"
  },
  {
    code: "1896",
    value: "B393",
    label: "CALCINATE",
    province: "BG"
  },
  {
    code: "1897",
    value: "B395",
    label: "CALCIO",
    province: "BG"
  },
  {
    code: "1898",
    value: "B434",
    label: "CALUSCO D ADDA",
    province: "BG"
  },
  {
    code: "1899",
    value: "B442",
    label: "CALVENZANO",
    province: "BG"
  },
  {
    code: "1900",
    value: "B471",
    label: "CAMERATA CORNELLO",
    province: "BG"
  },
  {
    code: "1901",
    value: "B618",
    label: "CANONICA D ADDA",
    province: "BG"
  },
  {
    code: "1902",
    value: "B661",
    label: "CAPIZZONE",
    province: "BG"
  },
  {
    code: "1903",
    value: "B703",
    label: "CAPRIATE SAN GERVASIO",
    province: "BG"
  },
  {
    code: "1904",
    value: "B710",
    label: "CAPRINO BERGAMASCO",
    province: "BG"
  },
  {
    code: "1905",
    value: "B731",
    label: "CARAVAGGIO",
    province: "BG"
  },
  {
    code: "1906",
    value: "B801",
    label: "CAROBBIO DEGLI ANGELI",
    province: "BG"
  },
  {
    code: "1907",
    value: "B803",
    label: "CARONA",
    province: "BG"
  },
  {
    code: "1908",
    value: "B854",
    label: "CARVICO",
    province: "BG"
  },
  {
    code: "1909",
    value: "B947",
    label: "CASAZZA",
    province: "BG"
  },
  {
    code: "1910",
    value: "B971",
    label: "CASIRATE D ADDA",
    province: "BG"
  },
  {
    code: "1911",
    value: "B978",
    label: "CASNIGO",
    province: "BG"
  },
  {
    code: "1912",
    value: "C007",
    label: "CASSIGLIO",
    province: "BG"
  },
  {
    code: "1913",
    value: "C079",
    label: "CASTELLI CALEPIO",
    province: "BG"
  },
  {
    code: "1914",
    value: "C255",
    label: "CASTEL ROZZONE",
    province: "BG"
  },
  {
    code: "1915",
    value: "C324",
    label: "CASTIONE DELLA PRESOLANA",
    province: "BG"
  },
  {
    code: "1916",
    value: "C337",
    label: "CASTRO",
    province: "BG"
  },
  {
    code: "1917",
    value: "C396",
    label: "CAVERNAGO",
    province: "BG"
  },
  {
    code: "1918",
    value: "C410",
    label: "CAZZANO SANT ANDREA",
    province: "BG"
  },
  {
    code: "1919",
    value: "C456",
    label: "CENATE SOPRA",
    province: "BG"
  },
  {
    code: "1920",
    value: "C457",
    label: "CENATE SOTTO",
    province: "BG"
  },
  {
    code: "1921",
    value: "C459",
    label: "CENE",
    province: "BG"
  },
  {
    code: "1922",
    value: "C506",
    label: "CERETE",
    province: "BG"
  },
  {
    code: "1923",
    value: "C635",
    label: "CHIGNOLO D ISOLA",
    province: "BG"
  },
  {
    code: "1924",
    value: "C649",
    label: "CHIUDUNO",
    province: "BG"
  },
  {
    code: "1925",
    value: "C728",
    label: "CISANO BERGAMASCO",
    province: "BG"
  },
  {
    code: "1926",
    value: "C730",
    label: "CISERANO",
    province: "BG"
  },
  {
    code: "1927",
    value: "C759",
    label: "CIVIDATE AL PIANO",
    province: "BG"
  },
  {
    code: "1928",
    value: "C800",
    label: "CLUSONE",
    province: "BG"
  },
  {
    code: "1929",
    value: "C835",
    label: "COLERE",
    province: "BG"
  },
  {
    code: "1930",
    value: "C894",
    label: "COLOGNO AL SERIO",
    province: "BG"
  },
  {
    code: "1931",
    value: "C910",
    label: "COLZATE",
    province: "BG"
  },
  {
    code: "1932",
    value: "C937",
    label: "COMUN NUOVO",
    province: "BG"
  },
  {
    code: "1933",
    value: "D015",
    label: "CORNA IMAGNA",
    province: "BG"
  },
  {
    code: "1934",
    value: "D066",
    label: "CORTENUOVA",
    province: "BG"
  },
  {
    code: "1935",
    value: "D110",
    label: "COSTA DI MEZZATE",
    province: "BG"
  },
  {
    code: "1936",
    value: "D103",
    label: "COSTA VALLE IMAGNA",
    province: "BG"
  },
  {
    code: "1937",
    value: "D117",
    label: "COSTA VOLPINO",
    province: "BG"
  },
  {
    code: "1938",
    value: "D126",
    label: "COVO",
    province: "BG"
  },
  {
    code: "1939",
    value: "D139",
    label: "CREDARO",
    province: "BG"
  },
  {
    code: "1940",
    value: "D221",
    label: "CURNO",
    province: "BG"
  },
  {
    code: "1941",
    value: "D233",
    label: "CUSIO",
    province: "BG"
  },
  {
    code: "1942",
    value: "D245",
    label: "DALMINE",
    province: "BG"
  },
  {
    code: "1943",
    value: "D352",
    label: "DOSSENA",
    province: "BG"
  },
  {
    code: "1944",
    value: "D406",
    label: "ENDINE GAIANO",
    province: "BG"
  },
  {
    code: "1945",
    value: "D411",
    label: "ENTRATICO",
    province: "BG"
  },
  {
    code: "1946",
    value: "D490",
    label: "FARA GERA D ADDA",
    province: "BG"
  },
  {
    code: "1947",
    value: "D491",
    label: "FARA OLIVANA CON SOLA",
    province: "BG"
  },
  {
    code: "1948",
    value: "D588",
    label: "FILAGO",
    province: "BG"
  },
  {
    code: "1949",
    value: "D604",
    label: "FINO DEL MONTE",
    province: "BG"
  },
  {
    code: "1950",
    value: "D606",
    label: "FIORANO AL SERIO",
    province: "BG"
  },
  {
    code: "1951",
    value: "D672",
    label: "FONTANELLA",
    province: "BG"
  },
  {
    code: "1952",
    value: "D684",
    label: "FONTENO",
    province: "BG"
  },
  {
    code: "1953",
    value: "D688",
    label: "FOPPOLO",
    province: "BG"
  },
  {
    code: "1954",
    value: "D697",
    label: "FORESTO SPARSO",
    province: "BG"
  },
  {
    code: "1955",
    value: "D727",
    label: "FORNOVO SAN GIOVANNI",
    province: "BG"
  },
  {
    code: "1956",
    value: "D817",
    label: "FUIPIANO VALLE IMAGNA",
    province: "BG"
  },
  {
    code: "1957",
    value: "D903",
    label: "GANDELLINO",
    province: "BG"
  },
  {
    code: "1958",
    value: "D905",
    label: "GANDINO",
    province: "BG"
  },
  {
    code: "1959",
    value: "D906",
    label: "GANDOSSO",
    province: "BG"
  },
  {
    code: "1960",
    value: "D943",
    label: "GAVERINA TERME",
    province: "BG"
  },
  {
    code: "1961",
    value: "D952",
    label: "GAZZANIGA",
    province: "BG"
  },
  {
    code: "1963",
    value: "E006",
    label: "GHISALBA",
    province: "BG"
  },
  {
    code: "1964",
    value: "E100",
    label: "GORLAGO",
    province: "BG"
  },
  {
    code: "1965",
    value: "E103",
    label: "GORLE",
    province: "BG"
  },
  {
    code: "1966",
    value: "E106",
    label: "GORNO",
    province: "BG"
  },
  {
    code: "1967",
    value: "E148",
    label: "GRASSOBBIO",
    province: "BG"
  },
  {
    code: "1968",
    value: "E189",
    label: "GROMO",
    province: "BG"
  },
  {
    code: "1969",
    value: "E192",
    label: "GRONE",
    province: "BG"
  },
  {
    code: "1970",
    value: "E219",
    label: "GRUMELLO DEL MONTE",
    province: "BG"
  },
  {
    code: "1971",
    value: "E353",
    label: "ISOLA DI FONDRA",
    province: "BG"
  },
  {
    code: "1972",
    value: "E370",
    label: "ISSO",
    province: "BG"
  },
  {
    code: "1973",
    value: "E422",
    label: "LALLIO",
    province: "BG"
  },
  {
    code: "1974",
    value: "E509",
    label: "LEFFE",
    province: "BG"
  },
  {
    code: "1975",
    value: "E524",
    label: "LENNA",
    province: "BG"
  },
  {
    code: "1976",
    value: "E562",
    label: "LEVATE",
    province: "BG"
  },
  {
    code: "1977",
    value: "E640",
    label: "LOCATELLO",
    province: "BG"
  },
  {
    code: "1978",
    value: "E704",
    label: "LOVERE",
    province: "BG"
  },
  {
    code: "1979",
    value: "E751",
    label: "LURANO",
    province: "BG"
  },
  {
    code: "1980",
    value: "E770",
    label: "LUZZANA",
    province: "BG"
  },
  {
    code: "1981",
    value: "E794",
    label: "MADONE",
    province: "BG"
  },
  {
    code: "1982",
    value: "E901",
    label: "MAPELLO",
    province: "BG"
  },
  {
    code: "1983",
    value: "E987",
    label: "MARTINENGO",
    province: "BG"
  },
  {
    code: "1984",
    value: "F186",
    label: "MEZZOLDO",
    province: "BG"
  },
  {
    code: "1985",
    value: "F243",
    label: "MISANO DI GERA D ADDA",
    province: "BG"
  },
  {
    code: "1986",
    value: "F276",
    label: "MOIO DE CALVI",
    province: "BG"
  },
  {
    code: "1987",
    value: "F328",
    label: "MONASTEROLO DEL CASTELLO",
    province: "BG"
  },
  {
    code: "1988",
    value: "F547",
    label: "MONTELLO",
    province: "BG"
  },
  {
    code: "1989",
    value: "F720",
    label: "MORENGO",
    province: "BG"
  },
  {
    code: "1990",
    value: "F738",
    label: "MORNICO AL SERIO",
    province: "BG"
  },
  {
    code: "1991",
    value: "F786",
    label: "MOZZANICA",
    province: "BG"
  },
  {
    code: "1992",
    value: "F791",
    label: "MOZZO",
    province: "BG"
  },
  {
    code: "1993",
    value: "F864",
    label: "NEMBRO",
    province: "BG"
  },
  {
    code: "1994",
    value: "G049",
    label: "OLMO AL BREMBO",
    province: "BG"
  },
  {
    code: "1995",
    value: "G050",
    label: "OLTRE IL COLLE",
    province: "BG"
  },
  {
    code: "1996",
    value: "G054",
    label: "OLTRESSENDA ALTA",
    province: "BG"
  },
  {
    code: "1997",
    value: "G068",
    label: "ONETA",
    province: "BG"
  },
  {
    code: "1998",
    value: "G075",
    label: "ONORE",
    province: "BG"
  },
  {
    code: "1999",
    value: "G108",
    label: "ORIO AL SERIO",
    province: "BG"
  },
  {
    code: "2000",
    value: "G118",
    label: "ORNICA",
    province: "BG"
  },
  {
    code: "2001",
    value: "G159",
    label: "OSIO SOPRA",
    province: "BG"
  },
  {
    code: "2002",
    value: "G160",
    label: "OSIO SOTTO",
    province: "BG"
  },
  {
    code: "2003",
    value: "G233",
    label: "PAGAZZANO",
    province: "BG"
  },
  {
    code: "2004",
    value: "G249",
    label: "PALADINA",
    province: "BG"
  },
  {
    code: "2005",
    value: "G259",
    label: "PALAZZAGO",
    province: "BG"
  },
  {
    code: "2006",
    value: "G295",
    label: "PALOSCO",
    province: "BG"
  },
  {
    code: "2007",
    value: "G346",
    label: "PARRE",
    province: "BG"
  },
  {
    code: "2008",
    value: "G350",
    label: "PARZANICA",
    province: "BG"
  },
  {
    code: "2009",
    value: "G412",
    label: "PEDRENGO",
    province: "BG"
  },
  {
    code: "2010",
    value: "G418",
    label: "PEIA",
    province: "BG"
  },
  {
    code: "2011",
    value: "G564",
    label: "PIANICO",
    province: "BG"
  },
  {
    code: "2012",
    value: "G574",
    label: "PIARIO",
    province: "BG"
  },
  {
    code: "2013",
    value: "G579",
    label: "PIAZZA BREMBANA",
    province: "BG"
  },
  {
    code: "2014",
    value: "G583",
    label: "PIAZZATORRE",
    province: "BG"
  },
  {
    code: "2015",
    value: "G588",
    label: "PIAZZOLO",
    province: "BG"
  },
  {
    code: "2016",
    value: "G774",
    label: "POGNANO",
    province: "BG"
  },
  {
    code: "2017",
    value: "F941",
    label: "PONTE NOSSA",
    province: "BG"
  },
  {
    code: "2018",
    value: "G853",
    label: "PONTERANICA",
    province: "BG"
  },
  {
    code: "2019",
    value: "G856",
    label: "PONTE SAN PIETRO",
    province: "BG"
  },
  {
    code: "2020",
    value: "G864",
    label: "PONTIDA",
    province: "BG"
  },
  {
    code: "2021",
    value: "G867",
    label: "PONTIROLO NUOVO",
    province: "BG"
  },
  {
    code: "2022",
    value: "G968",
    label: "PRADALUNGA",
    province: "BG"
  },
  {
    code: "2023",
    value: "H020",
    label: "PREDORE",
    province: "BG"
  },
  {
    code: "2024",
    value: "H036",
    label: "PREMOLO",
    province: "BG"
  },
  {
    code: "2025",
    value: "H046",
    label: "PRESEZZO",
    province: "BG"
  },
  {
    code: "2026",
    value: "H091",
    label: "PUMENENGO",
    province: "BG"
  },
  {
    code: "2027",
    value: "H176",
    label: "RANICA",
    province: "BG"
  },
  {
    code: "2028",
    value: "H177",
    label: "RANZANICO",
    province: "BG"
  },
  {
    code: "2029",
    value: "H331",
    label: "RIVA DI SOLTO",
    province: "BG"
  },
  {
    code: "2030",
    value: "H492",
    label: "ROGNO",
    province: "BG"
  },
  {
    code: "2031",
    value: "H509",
    label: "ROMANO DI LOMBARDIA",
    province: "BG"
  },
  {
    code: "2032",
    value: "H535",
    label: "RONCOBELLO",
    province: "BG"
  },
  {
    code: "2033",
    value: "H544",
    label: "RONCOLA",
    province: "BG"
  },
  {
    code: "2034",
    value: "H584",
    label: "ROTA D IMAGNA",
    province: "BG"
  },
  {
    code: "2035",
    value: "H615",
    label: "ROVETTA",
    province: "BG"
  },
  {
    code: "2036",
    value: "H910",
    label: "SAN GIOVANNI BIANCO",
    province: "BG"
  },
  {
    code: "2037",
    value: "B310",
    label: "SAN PAOLO D ARGON",
    province: "BG"
  },
  {
    code: "2038",
    value: "I079",
    label: "SAN PELLEGRINO TERME",
    province: "BG"
  },
  {
    code: "2039",
    value: "I168",
    label: "SANTA BRIGIDA",
    province: "BG"
  },
  {
    code: "2040",
    value: "M333",
    label: "SANT OMOBONO TERME",
    province: "BG"
  },
  {
    code: "2041",
    value: "I437",
    label: "SARNICO",
    province: "BG"
  },
  {
    code: "2042",
    value: "I506",
    label: "SCANZOROSCIATE",
    province: "BG"
  },
  {
    code: "2043",
    value: "I530",
    label: "SCHILPARIO",
    province: "BG"
  },
  {
    code: "2044",
    value: "I567",
    label: "SEDRINA",
    province: "BG"
  },
  {
    code: "2045",
    value: "I597",
    label: "SELVINO",
    province: "BG"
  },
  {
    code: "2046",
    value: "I628",
    label: "SERIATE",
    province: "BG"
  },
  {
    code: "2047",
    value: "I629",
    label: "SERINA",
    province: "BG"
  },
  {
    code: "2048",
    value: "I812",
    label: "SOLTO COLLINA",
    province: "BG"
  },
  {
    code: "2049",
    value: "I830",
    label: "SONGAVAZZO",
    province: "BG"
  },
  {
    code: "2050",
    value: "I858",
    label: "SORISOLE",
    province: "BG"
  },
  {
    code: "2051",
    value: "I869",
    label: "SOTTO IL MONTE GIOVANNI XXIII",
    province: "BG"
  },
  {
    code: "2052",
    value: "I873",
    label: "SOVERE",
    province: "BG"
  },
  {
    code: "2053",
    value: "I916",
    label: "SPINONE AL LAGO",
    province: "BG"
  },
  {
    code: "2054",
    value: "I919",
    label: "SPIRANO",
    province: "BG"
  },
  {
    code: "2055",
    value: "I951",
    label: "STEZZANO",
    province: "BG"
  },
  {
    code: "2056",
    value: "I986",
    label: "STROZZA",
    province: "BG"
  },
  {
    code: "2057",
    value: "I997",
    label: "SUISIO",
    province: "BG"
  },
  {
    code: "2058",
    value: "L037",
    label: "TALEGGIO",
    province: "BG"
  },
  {
    code: "2059",
    value: "L073",
    label: "TAVERNOLA BERGAMASCA",
    province: "BG"
  },
  {
    code: "2060",
    value: "L087",
    label: "TELGATE",
    province: "BG"
  },
  {
    code: "2061",
    value: "L118",
    label: "TERNO D ISOLA",
    province: "BG"
  },
  {
    code: "2062",
    value: "L251",
    label: "TORRE BOLDONE",
    province: "BG"
  },
  {
    code: "2063",
    value: "L265",
    label: "TORRE DE ROVERI",
    province: "BG"
  },
  {
    code: "2064",
    value: "L276",
    label: "TORRE PALLAVICINA",
    province: "BG"
  },
  {
    code: "2065",
    value: "L388",
    label: "TRESCORE BALNEARIO",
    province: "BG"
  },
  {
    code: "2066",
    value: "L400",
    label: "TREVIGLIO",
    province: "BG"
  },
  {
    code: "2067",
    value: "L404",
    label: "TREVIOLO",
    province: "BG"
  },
  {
    code: "2068",
    value: "C789",
    label: "UBIALE CLANEZZO",
    province: "BG"
  },
  {
    code: "2069",
    value: "L502",
    label: "URGNANO",
    province: "BG"
  },
  {
    code: "2070",
    value: "L544",
    label: "VALBONDIONE",
    province: "BG"
  },
  {
    code: "2071",
    value: "L545",
    label: "VALBREMBO",
    province: "BG"
  },
  {
    code: "2072",
    value: "L579",
    label: "VALGOGLIO",
    province: "BG"
  },
  {
    code: "2073",
    value: "L623",
    label: "VALLEVE",
    province: "BG"
  },
  {
    code: "2074",
    value: "L642",
    label: "VALNEGRA",
    province: "BG"
  },
  {
    code: "2098",
    value: "A034",
    label: "ACQUAFREDDA",
    province: "BS"
  },
  {
    code: "2099",
    value: "A060",
    label: "ADRO",
    province: "BS"
  },
  {
    code: "2100",
    value: "A082",
    label: "AGNOSINE",
    province: "BS"
  },
  {
    code: "2101",
    value: "A188",
    label: "ALFIANELLO",
    province: "BS"
  },
  {
    code: "2102",
    value: "A288",
    label: "ANFO",
    province: "BS"
  },
  {
    code: "2103",
    value: "A293",
    label: "ANGOLO TERME",
    province: "BS"
  },
  {
    code: "2104",
    value: "A451",
    label: "ARTOGNE",
    province: "BS"
  },
  {
    code: "2105",
    value: "A529",
    label: "AZZANO MELLA",
    province: "BS"
  },
  {
    code: "2106",
    value: "A569",
    label: "BAGNOLO MELLA",
    province: "BS"
  },
  {
    code: "2107",
    value: "A578",
    label: "BAGOLINO",
    province: "BS"
  },
  {
    code: "2108",
    value: "A630",
    label: "BARBARIGA",
    province: "BS"
  },
  {
    code: "2109",
    value: "A661",
    label: "BARGHE",
    province: "BS"
  },
  {
    code: "2110",
    value: "A702",
    label: "BASSANO BRESCIANO",
    province: "BS"
  },
  {
    code: "2111",
    value: "A729",
    label: "BEDIZZOLE",
    province: "BS"
  },
  {
    code: "2112",
    value: "A799",
    label: "BERLINGO",
    province: "BS"
  },
  {
    code: "2113",
    value: "A816",
    label: "BERZO DEMO",
    province: "BS"
  },
  {
    code: "2114",
    value: "A817",
    label: "BERZO INFERIORE",
    province: "BS"
  },
  {
    code: "2115",
    value: "A861",
    label: "BIENNO",
    province: "BS"
  },
  {
    code: "2116",
    value: "A878",
    label: "BIONE",
    province: "BS"
  },
  {
    code: "2117",
    value: "B035",
    label: "BORGO SAN GIACOMO",
    province: "BS"
  },
  {
    code: "2118",
    value: "B040",
    label: "BORGOSATOLLO",
    province: "BS"
  },
  {
    code: "2119",
    value: "B054",
    label: "BORNO",
    province: "BS"
  },
  {
    code: "2120",
    value: "B091",
    label: "BOTTICINO",
    province: "BS"
  },
  {
    code: "2121",
    value: "B100",
    label: "BOVEGNO",
    province: "BS"
  },
  {
    code: "2122",
    value: "B102",
    label: "BOVEZZO",
    province: "BS"
  },
  {
    code: "2123",
    value: "B120",
    label: "BRANDICO",
    province: "BS"
  },
  {
    code: "2124",
    value: "B124",
    label: "BRAONE",
    province: "BS"
  },
  {
    code: "2125",
    value: "B149",
    label: "BRENO",
    province: "BS"
  },
  {
    code: "2126",
    value: "B157",
    label: "BRESCIA",
    province: "BS"
  },
  {
    code: "2127",
    value: "B184",
    label: "BRIONE",
    province: "BS"
  },
  {
    code: "2128",
    value: "B365",
    label: "CAINO",
    province: "BS"
  },
  {
    code: "2129",
    value: "B394",
    label: "CALCINATO",
    province: "BS"
  },
  {
    code: "2130",
    value: "B436",
    label: "CALVAGESE DELLA RIVIERA",
    province: "BS"
  },
  {
    code: "2131",
    value: "B450",
    label: "CALVISANO",
    province: "BS"
  },
  {
    code: "2132",
    value: "B664",
    label: "CAPO DI PONTE",
    province: "BS"
  },
  {
    code: "2133",
    value: "B676",
    label: "CAPOVALLE",
    province: "BS"
  },
  {
    code: "2134",
    value: "B698",
    label: "CAPRIANO DEL COLLE",
    province: "BS"
  },
  {
    code: "2135",
    value: "B711",
    label: "CAPRIOLO",
    province: "BS"
  },
  {
    code: "2136",
    value: "B817",
    label: "CARPENEDOLO",
    province: "BS"
  },
  {
    code: "2137",
    value: "C055",
    label: "CASTEGNATO",
    province: "BS"
  },
  {
    code: "2138",
    value: "C072",
    label: "CASTELCOVATI",
    province: "BS"
  },
  {
    code: "2139",
    value: "C208",
    label: "CASTEL MELLA",
    province: "BS"
  },
  {
    code: "2140",
    value: "C293",
    label: "CASTENEDOLO",
    province: "BS"
  },
  {
    code: "2141",
    value: "C330",
    label: "CASTO",
    province: "BS"
  },
  {
    code: "2142",
    value: "C332",
    label: "CASTREZZATO",
    province: "BS"
  },
  {
    code: "2143",
    value: "C408",
    label: "CAZZAGO SAN MARTINO",
    province: "BS"
  },
  {
    code: "2144",
    value: "C417",
    label: "CEDEGOLO",
    province: "BS"
  },
  {
    code: "2145",
    value: "C439",
    label: "CELLATICA",
    province: "BS"
  },
  {
    code: "2146",
    value: "C549",
    label: "CERVENO",
    province: "BS"
  },
  {
    code: "2147",
    value: "C585",
    label: "CETO",
    province: "BS"
  },
  {
    code: "2148",
    value: "C591",
    label: "CEVO",
    province: "BS"
  },
  {
    code: "2149",
    value: "C618",
    label: "CHIARI",
    province: "BS"
  },
  {
    code: "2150",
    value: "C685",
    label: "CIGOLE",
    province: "BS"
  },
  {
    code: "2151",
    value: "C691",
    label: "CIMBERGO",
    province: "BS"
  },
  {
    code: "2152",
    value: "C760",
    label: "CIVIDATE CAMUNO",
    province: "BS"
  },
  {
    code: "2153",
    value: "C806",
    label: "COCCAGLIO",
    province: "BS"
  },
  {
    code: "2154",
    value: "C850",
    label: "COLLEBEATO",
    province: "BS"
  },
  {
    code: "2155",
    value: "C883",
    label: "COLLIO",
    province: "BS"
  },
  {
    code: "2156",
    value: "C893",
    label: "COLOGNE",
    province: "BS"
  },
  {
    code: "2157",
    value: "C925",
    label: "COMEZZANO CIZZAGO",
    province: "BS"
  },
  {
    code: "2158",
    value: "C948",
    label: "CONCESIO",
    province: "BS"
  },
  {
    code: "2159",
    value: "D058",
    label: "CORTE FRANCA",
    province: "BS"
  },
  {
    code: "2160",
    value: "D064",
    label: "CORTENO GOLGI",
    province: "BS"
  },
  {
    code: "2161",
    value: "D082",
    label: "CORZANO",
    province: "BS"
  },
  {
    code: "2162",
    value: "D251",
    label: "DARFO BOARIO TERME",
    province: "BS"
  },
  {
    code: "2163",
    value: "D270",
    label: "DELLO",
    province: "BS"
  },
  {
    code: "2164",
    value: "D284",
    label: "DESENZANO DEL GARDA",
    province: "BS"
  },
  {
    code: "2165",
    value: "D391",
    label: "EDOLO",
    province: "BS"
  },
  {
    code: "2166",
    value: "D421",
    label: "ERBUSCO",
    province: "BS"
  },
  {
    code: "2167",
    value: "D434",
    label: "ESINE",
    province: "BS"
  },
  {
    code: "2168",
    value: "D576",
    label: "FIESSE",
    province: "BS"
  },
  {
    code: "2169",
    value: "D634",
    label: "FLERO",
    province: "BS"
  },
  {
    code: "2170",
    value: "D891",
    label: "GAMBARA",
    province: "BS"
  },
  {
    code: "2171",
    value: "D917",
    label: "GARDONE RIVIERA",
    province: "BS"
  },
  {
    code: "2172",
    value: "D918",
    label: "GARDONE VAL TROMPIA",
    province: "BS"
  },
  {
    code: "2173",
    value: "D924",
    label: "GARGNANO",
    province: "BS"
  },
  {
    code: "2174",
    value: "D940",
    label: "GAVARDO",
    province: "BS"
  },
  {
    code: "2175",
    value: "D999",
    label: "GHEDI",
    province: "BS"
  },
  {
    code: "2176",
    value: "E010",
    label: "GIANICO",
    province: "BS"
  },
  {
    code: "2177",
    value: "E116",
    label: "GOTTOLENGO",
    province: "BS"
  },
  {
    code: "2178",
    value: "E271",
    label: "GUSSAGO",
    province: "BS"
  },
  {
    code: "2179",
    value: "E280",
    label: "IDRO",
    province: "BS"
  },
  {
    code: "2180",
    value: "E297",
    label: "INCUDINE",
    province: "BS"
  },
  {
    code: "2181",
    value: "E325",
    label: "IRMA",
    province: "BS"
  },
  {
    code: "2182",
    value: "E333",
    label: "ISEO",
    province: "BS"
  },
  {
    code: "2183",
    value: "E364",
    label: "ISORELLA",
    province: "BS"
  },
  {
    code: "2184",
    value: "E497",
    label: "LAVENONE",
    province: "BS"
  },
  {
    code: "2185",
    value: "E526",
    label: "LENO",
    province: "BS"
  },
  {
    code: "2186",
    value: "E596",
    label: "LIMONE SUL GARDA",
    province: "BS"
  },
  {
    code: "2187",
    value: "E652",
    label: "LODRINO",
    province: "BS"
  },
  {
    code: "2188",
    value: "E654",
    label: "LOGRATO",
    province: "BS"
  },
  {
    code: "2189",
    value: "M312",
    label: "LONATO DEL GARDA",
    province: "BS"
  },
  {
    code: "2190",
    value: "E673",
    label: "LONGHENA",
    province: "BS"
  },
  {
    code: "2191",
    value: "E698",
    label: "LOSINE",
    province: "BS"
  },
  {
    code: "2192",
    value: "E706",
    label: "LOZIO",
    province: "BS"
  },
  {
    code: "2193",
    value: "E738",
    label: "LUMEZZANE",
    province: "BS"
  },
  {
    code: "2194",
    value: "E787",
    label: "MACLODIO",
    province: "BS"
  },
  {
    code: "2195",
    value: "E800",
    label: "MAGASA",
    province: "BS"
  },
  {
    code: "2196",
    value: "E841",
    label: "MAIRANO",
    province: "BS"
  },
  {
    code: "2197",
    value: "E851",
    label: "MALEGNO",
    province: "BS"
  },
  {
    code: "2198",
    value: "E865",
    label: "MALONNO",
    province: "BS"
  },
  {
    code: "2199",
    value: "E883",
    label: "MANERBA DEL GARDA",
    province: "BS"
  },
  {
    code: "2200",
    value: "E884",
    label: "MANERBIO",
    province: "BS"
  },
  {
    code: "2201",
    value: "E928",
    label: "MARCHENO",
    province: "BS"
  },
  {
    code: "2202",
    value: "E961",
    label: "MARMENTINO",
    province: "BS"
  },
  {
    code: "2203",
    value: "E967",
    label: "MARONE",
    province: "BS"
  },
  {
    code: "2204",
    value: "F063",
    label: "MAZZANO",
    province: "BS"
  },
  {
    code: "2205",
    value: "F216",
    label: "MILZANO",
    province: "BS"
  },
  {
    code: "2206",
    value: "F373",
    label: "MONIGA DEL GARDA",
    province: "BS"
  },
  {
    code: "2207",
    value: "F375",
    label: "MONNO",
    province: "BS"
  },
  {
    code: "2208",
    value: "F532",
    label: "MONTE ISOLA",
    province: "BS"
  },
  {
    code: "2209",
    value: "F672",
    label: "MONTICELLI BRUSATI",
    province: "BS"
  },
  {
    code: "2210",
    value: "F471",
    label: "MONTICHIARI",
    province: "BS"
  },
  {
    code: "2211",
    value: "F680",
    label: "MONTIRONE",
    province: "BS"
  },
  {
    code: "2212",
    value: "F806",
    label: "MURA",
    province: "BS"
  },
  {
    code: "2213",
    value: "F820",
    label: "MUSCOLINE",
    province: "BS"
  },
  {
    code: "2214",
    value: "F851",
    label: "NAVE",
    province: "BS"
  },
  {
    code: "2215",
    value: "F884",
    label: "NIARDO",
    province: "BS"
  },
  {
    code: "2216",
    value: "F989",
    label: "NUVOLENTO",
    province: "BS"
  },
  {
    code: "2217",
    value: "F990",
    label: "NUVOLERA",
    province: "BS"
  },
  {
    code: "2218",
    value: "G001",
    label: "ODOLO",
    province: "BS"
  },
  {
    code: "2219",
    value: "G006",
    label: "OFFLAGA",
    province: "BS"
  },
  {
    code: "2220",
    value: "G061",
    label: "OME",
    province: "BS"
  },
  {
    code: "2221",
    value: "G074",
    label: "ONO SAN PIETRO",
    province: "BS"
  },
  {
    code: "2222",
    value: "G149",
    label: "ORZINUOVI",
    province: "BS"
  },
  {
    code: "2223",
    value: "G150",
    label: "ORZIVECCHI",
    province: "BS"
  },
  {
    code: "2224",
    value: "G170",
    label: "OSPITALETTO",
    province: "BS"
  },
  {
    code: "2225",
    value: "G179",
    label: "OSSIMO",
    province: "BS"
  },
  {
    code: "2226",
    value: "G213",
    label: "PADENGHE SUL GARDA",
    province: "BS"
  },
  {
    code: "2227",
    value: "G217",
    label: "PADERNO FRANCIACORTA",
    province: "BS"
  },
  {
    code: "2228",
    value: "G247",
    label: "PAISCO LOVENO",
    province: "BS"
  },
  {
    code: "2229",
    value: "G248",
    label: "PAITONE",
    province: "BS"
  },
  {
    code: "2230",
    value: "G264",
    label: "PALAZZOLO SULL OGLIO",
    province: "BS"
  },
  {
    code: "2231",
    value: "G327",
    label: "PARATICO",
    province: "BS"
  },
  {
    code: "2232",
    value: "G354",
    label: "PASPARDO",
    province: "BS"
  },
  {
    code: "2233",
    value: "G361",
    label: "PASSIRANO",
    province: "BS"
  },
  {
    code: "2234",
    value: "G391",
    label: "PAVONE DEL MELLA",
    province: "BS"
  },
  {
    code: "2235",
    value: "G407",
    label: "SAN PAOLO",
    province: "BS"
  },
  {
    code: "2236",
    value: "G474",
    label: "PERTICA ALTA",
    province: "BS"
  },
  {
    code: "2237",
    value: "G475",
    label: "PERTICA BASSA",
    province: "BS"
  },
  {
    code: "2238",
    value: "G529",
    label: "PEZZAZE",
    province: "BS"
  },
  {
    code: "2239",
    value: "G546",
    label: "PIAN CAMUNO",
    province: "BS"
  },
  {
    code: "2240",
    value: "G710",
    label: "PISOGNE",
    province: "BS"
  },
  {
    code: "2241",
    value: "G779",
    label: "POLAVENO",
    province: "BS"
  },
  {
    code: "2242",
    value: "G801",
    label: "POLPENAZZE DEL GARDA",
    province: "BS"
  },
  {
    code: "2243",
    value: "G815",
    label: "POMPIANO",
    province: "BS"
  },
  {
    code: "2244",
    value: "G818",
    label: "PONCARALE",
    province: "BS"
  },
  {
    code: "2245",
    value: "G844",
    label: "PONTE DI LEGNO",
    province: "BS"
  },
  {
    code: "2246",
    value: "G859",
    label: "PONTEVICO",
    province: "BS"
  },
  {
    code: "2247",
    value: "G869",
    label: "PONTOGLIO",
    province: "BS"
  },
  {
    code: "2248",
    value: "G959",
    label: "POZZOLENGO",
    province: "BS"
  },
  {
    code: "2249",
    value: "G977",
    label: "PRALBOINO",
    province: "BS"
  },
  {
    code: "2250",
    value: "H043",
    label: "PRESEGLIE",
    province: "BS"
  },
  {
    code: "2252",
    value: "H055",
    label: "PREVALLE",
    province: "BS"
  },
  {
    code: "2253",
    value: "H078",
    label: "PROVAGLIO D ISEO",
    province: "BS"
  },
  {
    code: "2254",
    value: "H077",
    label: "PROVAGLIO VAL SABBIA",
    province: "BS"
  },
  {
    code: "2255",
    value: "H086",
    label: "PUEGNAGO DEL GARDA",
    province: "BS"
  },
  {
    code: "2256",
    value: "H140",
    label: "QUINZANO D OGLIO",
    province: "BS"
  },
  {
    code: "2257",
    value: "H230",
    label: "REMEDELLO",
    province: "BS"
  },
  {
    code: "2258",
    value: "H256",
    label: "REZZATO",
    province: "BS"
  },
  {
    code: "2259",
    value: "H410",
    label: "ROCCAFRANCA",
    province: "BS"
  },
  {
    code: "2260",
    value: "H477",
    label: "RODENGO SAIANO",
    province: "BS"
  },
  {
    code: "2261",
    value: "H484",
    label: "ROE VOLCIANO",
    province: "BS"
  },
  {
    code: "2262",
    value: "H525",
    label: "RONCADELLE",
    province: "BS"
  },
  {
    code: "2263",
    value: "H598",
    label: "ROVATO",
    province: "BS"
  },
  {
    code: "2264",
    value: "H630",
    label: "RUDIANO",
    province: "BS"
  },
  {
    code: "2265",
    value: "H650",
    label: "SABBIO CHIESE",
    province: "BS"
  },
  {
    code: "2266",
    value: "H699",
    label: "SALE MARASINO",
    province: "BS"
  },
  {
    code: "2267",
    value: "H717",
    label: "SALO",
    province: "BS"
  },
  {
    code: "2268",
    value: "H838",
    label: "SAN FELICE DEL BENACO",
    province: "BS"
  },
  {
    code: "2269",
    value: "H865",
    label: "SAN GERVASIO BRESCIANO",
    province: "BS"
  },
  {
    code: "2270",
    value: "I412",
    label: "SAN ZENO NAVIGLIO",
    province: "BS"
  },
  {
    code: "2271",
    value: "I433",
    label: "SAREZZO",
    province: "BS"
  },
  {
    code: "2272",
    value: "I476",
    label: "SAVIORE DELL ADAMELLO",
    province: "BS"
  },
  {
    code: "2273",
    value: "I588",
    label: "SELLERO",
    province: "BS"
  },
  {
    code: "2274",
    value: "I607",
    label: "SENIGA",
    province: "BS"
  },
  {
    code: "2275",
    value: "I631",
    label: "SERLE",
    province: "BS"
  },
  {
    code: "2276",
    value: "I633",
    label: "SIRMIONE",
    province: "BS"
  },
  {
    code: "2277",
    value: "I782",
    label: "SOIANO DEL LAGO",
    province: "BS"
  },
  {
    code: "2278",
    value: "I831",
    label: "SONICO",
    province: "BS"
  },
  {
    code: "2279",
    value: "L002",
    label: "SULZANO",
    province: "BS"
  },
  {
    code: "2280",
    value: "C698",
    label: "TAVERNOLE SUL MELLA",
    province: "BS"
  },
  {
    code: "2281",
    value: "L094",
    label: "TEMU",
    province: "BS"
  },
  {
    code: "2282",
    value: "L169",
    label: "TIGNALE",
    province: "BS"
  },
  {
    code: "2283",
    value: "L210",
    label: "TORBOLE CASAGLIA",
    province: "BS"
  },
  {
    code: "2284",
    value: "L312",
    label: "TOSCOLANO MADERNO",
    province: "BS"
  },
  {
    code: "2285",
    value: "L339",
    label: "TRAVAGLIATO",
    province: "BS"
  },
  {
    code: "2286",
    value: "L372",
    label: "TREMOSINE SUL GARDA",
    province: "BS"
  },
  {
    code: "2287",
    value: "L380",
    label: "TRENZANO",
    province: "BS"
  },
  {
    code: "2288",
    value: "L406",
    label: "TREVISO BRESCIANO",
    province: "BS"
  },
  {
    code: "2289",
    value: "L494",
    label: "URAGO D OGLIO",
    province: "BS"
  },
  {
    code: "2290",
    value: "L626",
    label: "VALLIO TERME",
    province: "BS"
  },
  {
    code: "2291",
    value: "L468",
    label: "VALVESTINO",
    province: "BS"
  },
  {
    code: "2292",
    value: "L777",
    label: "VEROLANUOVA",
    province: "BS"
  },
  {
    code: "2293",
    value: "L778",
    label: "VEROLAVECCHIA",
    province: "BS"
  },
  {
    code: "2294",
    value: "L812",
    label: "VESTONE",
    province: "BS"
  },
  {
    code: "2295",
    value: "L816",
    label: "VEZZA D OGLIO",
    province: "BS"
  },
  {
    code: "2296",
    value: "L919",
    label: "VILLA CARCINA",
    province: "BS"
  },
  {
    code: "2297",
    value: "L923",
    label: "VILLACHIARA",
    province: "BS"
  },
  {
    code: "2298",
    value: "L995",
    label: "VILLANUOVA SUL CLISI",
    province: "BS"
  },
  {
    code: "2299",
    value: "M065",
    label: "VIONE",
    province: "BS"
  },
  {
    code: "2300",
    value: "M070",
    label: "VISANO",
    province: "BS"
  },
  {
    code: "2301",
    value: "M104",
    label: "VOBARNO",
    province: "BS"
  },
  {
    code: "2302",
    value: "M188",
    label: "ZONE",
    province: "BS"
  },
  {
    code: "2303",
    value: "G549",
    label: "PIANCOGNO",
    province: "BS"
  },
  {
    code: "2304",
    value: "A118",
    label: "ALAGNA",
    province: "PV"
  },
  {
    code: "2305",
    value: "A134",
    label: "ALBAREDO ARNABOLDI",
    province: "PV"
  },
  {
    code: "2306",
    value: "A171",
    label: "ALBONESE",
    province: "PV"
  },
  {
    code: "2307",
    value: "A175",
    label: "ALBUZZANO",
    province: "PV"
  },
  {
    code: "2308",
    value: "A387",
    label: "ARENA PO",
    province: "PV"
  },
  {
    code: "2309",
    value: "A538",
    label: "BADIA PAVESE",
    province: "PV"
  },
  {
    code: "2310",
    value: "A550",
    label: "BAGNARIA",
    province: "PV"
  },
  {
    code: "2311",
    value: "A634",
    label: "BARBIANELLO",
    province: "PV"
  },
  {
    code: "2312",
    value: "A690",
    label: "BASCAPE",
    province: "PV"
  },
  {
    code: "2314",
    value: "A712",
    label: "BASTIDA PANCARANA",
    province: "PV"
  },
  {
    code: "2315",
    value: "A718",
    label: "BATTUDA",
    province: "PV"
  },
  {
    code: "2316",
    value: "A741",
    label: "BELGIOIOSO",
    province: "PV"
  },
  {
    code: "2317",
    value: "A792",
    label: "BEREGUARDO",
    province: "PV"
  },
  {
    code: "2318",
    value: "A989",
    label: "BORGARELLO",
    province: "PV"
  },
  {
    code: "2319",
    value: "B028",
    label: "BORGO PRIOLO",
    province: "PV"
  },
  {
    code: "2320",
    value: "B030",
    label: "BORGORATTO MORMOROLO",
    province: "PV"
  },
  {
    code: "2321",
    value: "B038",
    label: "BORGO SAN SIRO",
    province: "PV"
  },
  {
    code: "2322",
    value: "B051",
    label: "BORNASCO",
    province: "PV"
  },
  {
    code: "2323",
    value: "B082",
    label: "BOSNASCO",
    province: "PV"
  },
  {
    code: "2324",
    value: "B117",
    label: "BRALLO DI PREGOLA",
    province: "PV"
  },
  {
    code: "2325",
    value: "B142",
    label: "BREME",
    province: "PV"
  },
  {
    code: "2326",
    value: "B159",
    label: "BRESSANA BOTTARONE",
    province: "PV"
  },
  {
    code: "2327",
    value: "B201",
    label: "BRONI",
    province: "PV"
  },
  {
    code: "2328",
    value: "B447",
    label: "CALVIGNANO",
    province: "PV"
  },
  {
    code: "2329",
    value: "B567",
    label: "CAMPOSPINOSO",
    province: "PV"
  },
  {
    code: "2330",
    value: "B587",
    label: "CANDIA LOMELLINA",
    province: "PV"
  },
  {
    code: "2332",
    value: "B613",
    label: "CANNETO PAVESE",
    province: "PV"
  },
  {
    code: "2333",
    value: "B741",
    label: "CARBONARA AL TICINO",
    province: "PV"
  },
  {
    code: "2334",
    value: "B929",
    label: "CASANOVA LONATI",
    province: "PV"
  },
  {
    code: "2335",
    value: "B945",
    label: "CASATISMA",
    province: "PV"
  },
  {
    code: "2336",
    value: "B954",
    label: "CASEI GEROLA",
    province: "PV"
  },
  {
    code: "2337",
    value: "B988",
    label: "CASORATE PRIMO",
    province: "PV"
  },
  {
    code: "2338",
    value: "C038",
    label: "CASSOLNOVO",
    province: "PV"
  },
  {
    code: "2339",
    value: "C050",
    label: "CASTANA",
    province: "PV"
  },
  {
    code: "2340",
    value: "C053",
    label: "CASTEGGIO",
    province: "PV"
  },
  {
    code: "2341",
    value: "C157",
    label: "CASTELLETTO DI BRANDUZZO",
    province: "PV"
  },
  {
    code: "2342",
    value: "C184",
    label: "CASTELLO D AGOGNA",
    province: "PV"
  },
  {
    code: "2343",
    value: "C213",
    label: "CASTELNOVETTO",
    province: "PV"
  },
  {
    code: "2344",
    value: "C360",
    label: "CAVA MANARA",
    province: "PV"
  },
  {
    code: "2345",
    value: "C414",
    label: "CECIMA",
    province: "PV"
  },
  {
    code: "2346",
    value: "C484",
    label: "CERANOVA",
    province: "PV"
  },
  {
    code: "2347",
    value: "C508",
    label: "CERETTO LOMELLINA",
    province: "PV"
  },
  {
    code: "2348",
    value: "C509",
    label: "CERGNAGO",
    province: "PV"
  },
  {
    code: "2349",
    value: "C541",
    label: "CERTOSA DI PAVIA",
    province: "PV"
  },
  {
    code: "2350",
    value: "C551",
    label: "CERVESINA",
    province: "PV"
  },
  {
    code: "2351",
    value: "C637",
    label: "CHIGNOLO PO",
    province: "PV"
  },
  {
    code: "2352",
    value: "C684",
    label: "CIGOGNOLA",
    province: "PV"
  },
  {
    code: "2353",
    value: "C686",
    label: "CILAVEGNA",
    province: "PV"
  },
  {
    code: "2354",
    value: "C813",
    label: "CODEVILLA",
    province: "PV"
  },
  {
    code: "2355",
    value: "C958",
    label: "CONFIENZA",
    province: "PV"
  },
  {
    code: "2356",
    value: "C979",
    label: "COPIANO",
    province: "PV"
  },
  {
    code: "2357",
    value: "C982",
    label: "CORANA",
    province: "PV"
  },
  {
    code: "2360",
    value: "D081",
    label: "CORVINO SAN QUIRICO",
    province: "PV"
  },
  {
    code: "2361",
    value: "D109",
    label: "COSTA DE NOBILI",
    province: "PV"
  },
  {
    code: "2362",
    value: "D127",
    label: "COZZO",
    province: "PV"
  },
  {
    code: "2363",
    value: "B824",
    label: "CURA CARPIGNANO",
    province: "PV"
  },
  {
    code: "2364",
    value: "D348",
    label: "DORNO",
    province: "PV"
  },
  {
    code: "2365",
    value: "D552",
    label: "FERRERA ERBOGNONE",
    province: "PV"
  },
  {
    code: "2366",
    value: "D594",
    label: "FILIGHERA",
    province: "PV"
  },
  {
    code: "2367",
    value: "D732",
    label: "FORTUNAGO",
    province: "PV"
  },
  {
    code: "2368",
    value: "D771",
    label: "FRASCAROLO",
    province: "PV"
  },
  {
    code: "2369",
    value: "D873",
    label: "GALLIAVOLA",
    province: "PV"
  },
  {
    code: "2370",
    value: "D892",
    label: "GAMBARANA",
    province: "PV"
  },
  {
    code: "2371",
    value: "D901",
    label: "GAMBOLO",
    province: "PV"
  },
  {
    code: "2372",
    value: "D925",
    label: "GARLASCO",
    province: "PV"
  },
  {
    code: "2374",
    value: "D980",
    label: "GERENZAGO",
    province: "PV"
  },
  {
    code: "2375",
    value: "E062",
    label: "GIUSSAGO",
    province: "PV"
  },
  {
    code: "2376",
    value: "E072",
    label: "GODIASCO SALICE TERME",
    province: "PV"
  },
  {
    code: "2377",
    value: "E081",
    label: "GOLFERENZO",
    province: "PV"
  },
  {
    code: "2378",
    value: "E152",
    label: "GRAVELLONA LOMELLINA",
    province: "PV"
  },
  {
    code: "2379",
    value: "E195",
    label: "GROPELLO CAIROLI",
    province: "PV"
  },
  {
    code: "2380",
    value: "E310",
    label: "INVERNO E MONTELEONE",
    province: "PV"
  },
  {
    code: "2381",
    value: "E437",
    label: "LANDRIANO",
    province: "PV"
  },
  {
    code: "2382",
    value: "E439",
    label: "LANGOSCO",
    province: "PV"
  },
  {
    code: "2383",
    value: "E454",
    label: "LARDIRAGO",
    province: "PV"
  },
  {
    code: "2384",
    value: "E600",
    label: "LINAROLO",
    province: "PV"
  },
  {
    code: "2385",
    value: "E608",
    label: "LIRIO",
    province: "PV"
  },
  {
    code: "2386",
    value: "E662",
    label: "LOMELLO",
    province: "PV"
  },
  {
    code: "2387",
    value: "B387",
    label: "LUNGAVILLA",
    province: "PV"
  },
  {
    code: "2388",
    value: "E804",
    label: "MAGHERNO",
    province: "PV"
  },
  {
    code: "2389",
    value: "E934",
    label: "MARCIGNAGO",
    province: "PV"
  },
  {
    code: "2390",
    value: "E999",
    label: "MARZANO",
    province: "PV"
  },
  {
    code: "2391",
    value: "F080",
    label: "MEDE",
    province: "PV"
  },
  {
    code: "2392",
    value: "F122",
    label: "MENCONICO",
    province: "PV"
  },
  {
    code: "2393",
    value: "F170",
    label: "MEZZANA BIGLI",
    province: "PV"
  },
  {
    code: "2394",
    value: "F171",
    label: "MEZZANA RABATTONE",
    province: "PV"
  },
  {
    code: "2395",
    value: "F175",
    label: "MEZZANINO",
    province: "PV"
  },
  {
    code: "2396",
    value: "F238",
    label: "MIRADOLO TERME",
    province: "PV"
  },
  {
    code: "2397",
    value: "F417",
    label: "MONTALTO PAVESE",
    province: "PV"
  },
  {
    code: "2398",
    value: "F440",
    label: "MONTEBELLO DELLA BATTAGLIA",
    province: "PV"
  },
  {
    code: "2399",
    value: "F449",
    label: "MONTECALVO VERSIGGIA",
    province: "PV"
  },
  {
    code: "2400",
    value: "F638",
    label: "MONTESCANO",
    province: "PV"
  },
  {
    code: "2401",
    value: "F644",
    label: "MONTESEGALE",
    province: "PV"
  },
  {
    code: "2402",
    value: "F670",
    label: "MONTICELLI PAVESE",
    province: "PV"
  },
  {
    code: "2403",
    value: "F701",
    label: "MONTU BECCARIA",
    province: "PV"
  },
  {
    code: "2404",
    value: "F739",
    label: "MORNICO LOSANA",
    province: "PV"
  },
  {
    code: "2405",
    value: "F754",
    label: "MORTARA",
    province: "PV"
  },
  {
    code: "2406",
    value: "F891",
    label: "NICORVO",
    province: "PV"
  },
  {
    code: "2407",
    value: "G021",
    label: "OLEVANO DI LOMELLINA",
    province: "PV"
  },
  {
    code: "2408",
    value: "G032",
    label: "OLIVA GESSI",
    province: "PV"
  },
  {
    code: "2409",
    value: "G194",
    label: "OTTOBIANO",
    province: "PV"
  },
  {
    code: "2410",
    value: "G275",
    label: "PALESTRO",
    province: "PV"
  },
  {
    code: "2411",
    value: "G304",
    label: "PANCARANA",
    province: "PV"
  },
  {
    code: "2412",
    value: "G342",
    label: "PARONA",
    province: "PV"
  },
  {
    code: "2413",
    value: "G388",
    label: "PAVIA",
    province: "PV"
  },
  {
    code: "2414",
    value: "G612",
    label: "PIETRA DE GIORGI",
    province: "PV"
  },
  {
    code: "2415",
    value: "G635",
    label: "PIEVE ALBIGNOLA",
    province: "PV"
  },
  {
    code: "2416",
    value: "G639",
    label: "PIEVE DEL CAIRO",
    province: "PV"
  },
  {
    code: "2417",
    value: "G650",
    label: "PIEVE PORTO MORONE",
    province: "PV"
  },
  {
    code: "2418",
    value: "G671",
    label: "PINAROLO PO",
    province: "PV"
  },
  {
    code: "2419",
    value: "G720",
    label: "PIZZALE",
    province: "PV"
  },
  {
    code: "2420",
    value: "G851",
    label: "PONTE NIZZA",
    province: "PV"
  },
  {
    code: "2421",
    value: "G895",
    label: "PORTALBERA",
    province: "PV"
  },
  {
    code: "2422",
    value: "H204",
    label: "REA",
    province: "PV"
  },
  {
    code: "2423",
    value: "H216",
    label: "REDAVALLE",
    province: "PV"
  },
  {
    code: "2424",
    value: "H246",
    label: "RETORBIDO",
    province: "PV"
  },
  {
    code: "2425",
    value: "H336",
    label: "RIVANAZZANO TERME",
    province: "PV"
  },
  {
    code: "2426",
    value: "H369",
    label: "ROBBIO",
    province: "PV"
  },
  {
    code: "2427",
    value: "H375",
    label: "ROBECCO PAVESE",
    province: "PV"
  },
  {
    code: "2428",
    value: "H396",
    label: "ROCCA DE GIORGI",
    province: "PV"
  },
  {
    code: "2429",
    value: "H450",
    label: "ROCCA SUSELLA",
    province: "PV"
  },
  {
    code: "2430",
    value: "H491",
    label: "ROGNANO",
    province: "PV"
  },
  {
    code: "2431",
    value: "H505",
    label: "ROMAGNESE",
    province: "PV"
  },
  {
    code: "2432",
    value: "H527",
    label: "RONCARO",
    province: "PV"
  },
  {
    code: "2433",
    value: "H559",
    label: "ROSASCO",
    province: "PV"
  },
  {
    code: "2434",
    value: "H614",
    label: "ROVESCALA",
    province: "PV"
  },
  {
    code: "2436",
    value: "H799",
    label: "SAN CIPRIANO PO",
    province: "PV"
  },
  {
    code: "2437",
    value: "H814",
    label: "SAN DAMIANO AL COLLE",
    province: "PV"
  },
  {
    code: "2438",
    value: "H859",
    label: "SAN GENESIO ED UNITI",
    province: "PV"
  },
  {
    code: "2439",
    value: "H885",
    label: "SAN GIORGIO DI LOMELLINA",
    province: "PV"
  },
  {
    code: "2440",
    value: "I014",
    label: "SAN MARTINO SICCOMARIO",
    province: "PV"
  },
  {
    code: "2441",
    value: "I048",
    label: "SANNAZZARO DE BURGONDI",
    province: "PV"
  },
  {
    code: "2442",
    value: "I175",
    label: "SANTA CRISTINA E BISSONE",
    province: "PV"
  },
  {
    code: "2443",
    value: "I203",
    label: "SANTA GIULETTA",
    province: "PV"
  },
  {
    code: "2444",
    value: "I213",
    label: "SANT ALESSIO CON VIALONE",
    province: "PV"
  },
  {
    code: "2445",
    value: "I230",
    label: "SANTA MARGHERITA DI STAFFORA",
    province: "PV"
  },
  {
    code: "2446",
    value: "I237",
    label: "SANTA MARIA DELLA VERSA",
    province: "PV"
  },
  {
    code: "2447",
    value: "I276",
    label: "SANT ANGELO LOMELLINA",
    province: "PV"
  },
  {
    code: "2448",
    value: "I416",
    label: "SAN ZENONE AL PO",
    province: "PV"
  },
  {
    code: "2449",
    value: "I447",
    label: "SARTIRANA LOMELLINA",
    province: "PV"
  },
  {
    code: "2450",
    value: "I487",
    label: "SCALDASOLE",
    province: "PV"
  },
  {
    code: "2451",
    value: "I599",
    label: "SEMIANA",
    province: "PV"
  },
  {
    code: "2452",
    value: "I739",
    label: "SILVANO PIETRA",
    province: "PV"
  },
  {
    code: "2453",
    value: "E265",
    label: "SIZIANO",
    province: "PV"
  },
  {
    code: "2454",
    value: "I825",
    label: "SOMMO",
    province: "PV"
  },
  {
    code: "2455",
    value: "I894",
    label: "SPESSA",
    province: "PV"
  },
  {
    code: "2456",
    value: "I968",
    label: "STRADELLA",
    province: "PV"
  },
  {
    code: "2457",
    value: "B014",
    label: "SUARDI",
    province: "PV"
  },
  {
    code: "2458",
    value: "L237",
    label: "TORRAZZA COSTE",
    province: "PV"
  },
  {
    code: "2459",
    value: "L250",
    label: "TORRE BERETTI E CASTELLARO",
    province: "PV"
  },
  {
    code: "2460",
    value: "L256",
    label: "TORRE D ARESE",
    province: "PV"
  },
  {
    code: "2461",
    value: "L262",
    label: "TORRE DE NEGRI",
    province: "PV"
  },
  {
    code: "2462",
    value: "L269",
    label: "TORRE D ISOLA",
    province: "PV"
  },
  {
    code: "2463",
    value: "L285",
    label: "TORREVECCHIA PIA",
    province: "PV"
  },
  {
    code: "2464",
    value: "L292",
    label: "TORRICELLA VERZATE",
    province: "PV"
  },
  {
    code: "2465",
    value: "I236",
    label: "TRAVACO SICCOMARIO",
    province: "PV"
  },
  {
    code: "2466",
    value: "L440",
    label: "TRIVOLZIO",
    province: "PV"
  },
  {
    code: "2467",
    value: "L449",
    label: "TROMELLO",
    province: "PV"
  },
  {
    code: "2468",
    value: "L453",
    label: "TROVO",
    province: "PV"
  },
  {
    code: "2469",
    value: "L562",
    label: "VAL DI NIZZA",
    province: "PV"
  },
  {
    code: "2470",
    value: "L568",
    label: "VALEGGIO",
    province: "PV"
  },
  {
    code: "2471",
    value: "L593",
    label: "VALLE LOMELLINA",
    province: "PV"
  },
  {
    code: "2472",
    value: "L617",
    label: "VALLE SALIMBENE",
    province: "PV"
  },
  {
    code: "2474",
    value: "L690",
    label: "VARZI",
    province: "PV"
  },
  {
    code: "2475",
    value: "L716",
    label: "VELEZZO LOMELLINA",
    province: "PV"
  },
  {
    code: "2476",
    value: "L720",
    label: "VELLEZZO BELLINI",
    province: "PV"
  },
  {
    code: "2477",
    value: "L784",
    label: "VERRETTO",
    province: "PV"
  },
  {
    code: "2478",
    value: "L788",
    label: "VERRUA PO",
    province: "PV"
  },
  {
    code: "2479",
    value: "L854",
    label: "VIDIGULFO",
    province: "PV"
  },
  {
    code: "2480",
    value: "L872",
    label: "VIGEVANO",
    province: "PV"
  },
  {
    code: "2481",
    value: "L917",
    label: "VILLA BISCOSSI",
    province: "PV"
  },
  {
    code: "2482",
    value: "L983",
    label: "VILLANOVA D ARDENGHI",
    province: "PV"
  },
  {
    code: "2483",
    value: "L994",
    label: "VILLANTERIO",
    province: "PV"
  },
  {
    code: "2484",
    value: "M079",
    label: "VISTARINO",
    province: "PV"
  },
  {
    code: "2485",
    value: "M109",
    label: "VOGHERA",
    province: "PV"
  },
  {
    code: "2486",
    value: "M119",
    label: "VOLPARA",
    province: "PV"
  },
  {
    code: "2487",
    value: "M150",
    label: "ZAVATTARELLO",
    province: "PV"
  },
  {
    code: "2488",
    value: "M152",
    label: "ZECCONE",
    province: "PV"
  },
  {
    code: "2489",
    value: "M161",
    label: "ZEME",
    province: "PV"
  },
  {
    code: "2490",
    value: "M162",
    label: "ZENEVREDO",
    province: "PV"
  },
  {
    code: "2491",
    value: "M166",
    label: "ZERBO",
    province: "PV"
  },
  {
    code: "2492",
    value: "M167",
    label: "ZERBOLO",
    province: "PV"
  },
  {
    code: "2493",
    value: "M180",
    label: "ZINASCO",
    province: "PV"
  },
  {
    code: "2494",
    value: "A039",
    label: "ACQUANEGRA CREMONESE",
    province: "CR"
  },
  {
    code: "2495",
    value: "A076",
    label: "AGNADELLO",
    province: "CR"
  },
  {
    code: "2496",
    value: "A299",
    label: "ANNICCO",
    province: "CR"
  },
  {
    code: "2497",
    value: "A526",
    label: "AZZANELLO",
    province: "CR"
  },
  {
    code: "2498",
    value: "A570",
    label: "BAGNOLO CREMASCO",
    province: "CR"
  },
  {
    code: "2499",
    value: "A972",
    label: "BONEMERSE",
    province: "CR"
  },
  {
    code: "2500",
    value: "A986",
    label: "BORDOLANO",
    province: "CR"
  },
  {
    code: "2502",
    value: "B439",
    label: "CALVATONE",
    province: "CR"
  },
  {
    code: "2503",
    value: "B484",
    label: "CAMISANO",
    province: "CR"
  },
  {
    code: "2504",
    value: "B498",
    label: "CAMPAGNOLA CREMASCA",
    province: "CR"
  },
  {
    code: "2505",
    value: "B650",
    label: "CAPERGNANICA",
    province: "CR"
  },
  {
    code: "2506",
    value: "B679",
    label: "CAPPELLA CANTONE",
    province: "CR"
  },
  {
    code: "2507",
    value: "B680",
    label: "CAPPELLA DE PICENARDI",
    province: "CR"
  },
  {
    code: "2508",
    value: "B686",
    label: "CAPRALBA",
    province: "CR"
  },
  {
    code: "2509",
    value: "B869",
    label: "CASALBUTTANO ED UNITI",
    province: "CR"
  },
  {
    code: "2510",
    value: "B881",
    label: "CASALE CREMASCO VIDOLASCO",
    province: "CR"
  },
  {
    code: "2511",
    value: "B889",
    label: "CASALETTO CEREDANO",
    province: "CR"
  },
  {
    code: "2512",
    value: "B890",
    label: "CASALETTO DI SOPRA",
    province: "CR"
  },
  {
    code: "2513",
    value: "B891",
    label: "CASALETTO VAPRIO",
    province: "CR"
  },
  {
    code: "2514",
    value: "B898",
    label: "CASALMAGGIORE",
    province: "CR"
  },
  {
    code: "2515",
    value: "B900",
    label: "CASALMORANO",
    province: "CR"
  },
  {
    code: "2516",
    value: "C089",
    label: "CASTELDIDONE",
    province: "CR"
  },
  {
    code: "2517",
    value: "C115",
    label: "CASTEL GABBIANO",
    province: "CR"
  },
  {
    code: "2518",
    value: "C153",
    label: "CASTELLEONE",
    province: "CR"
  },
  {
    code: "2519",
    value: "B129",
    label: "CASTELVERDE",
    province: "CR"
  },
  {
    code: "2520",
    value: "C290",
    label: "CASTELVISCONTI",
    province: "CR"
  },
  {
    code: "2521",
    value: "C435",
    label: "CELLA DATI",
    province: "CR"
  },
  {
    code: "2522",
    value: "C634",
    label: "CHIEVE",
    province: "CR"
  },
  {
    code: "2523",
    value: "C678",
    label: "CICOGNOLO",
    province: "CR"
  },
  {
    code: "2524",
    value: "C703",
    label: "CINGIA DE BOTTI",
    province: "CR"
  },
  {
    code: "2525",
    value: "D056",
    label: "CORTE DE CORTESI CON CIGNONE",
    province: "CR"
  },
  {
    code: "2526",
    value: "D057",
    label: "CORTE DE FRATI",
    province: "CR"
  },
  {
    code: "2527",
    value: "D141",
    label: "CREDERA RUBBIANO",
    province: "CR"
  },
  {
    code: "2528",
    value: "D142",
    label: "CREMA",
    province: "CR"
  },
  {
    code: "2529",
    value: "D150",
    label: "CREMONA",
    province: "CR"
  },
  {
    code: "2530",
    value: "D151",
    label: "CREMOSANO",
    province: "CR"
  },
  {
    code: "2531",
    value: "D186",
    label: "CROTTA D ADDA",
    province: "CR"
  },
  {
    code: "2532",
    value: "D203",
    label: "CUMIGNANO SUL NAVIGLIO",
    province: "CR"
  },
  {
    code: "2533",
    value: "D278",
    label: "DEROVERE",
    province: "CR"
  },
  {
    code: "2534",
    value: "D358",
    label: "DOVERA",
    province: "CR"
  },
  {
    code: "2536",
    value: "D574",
    label: "FIESCO",
    province: "CR"
  },
  {
    code: "2537",
    value: "D710",
    label: "FORMIGARA",
    province: "CR"
  },
  {
    code: "2538",
    value: "D834",
    label: "GABBIONETA BINANUOVA",
    province: "CR"
  },
  {
    code: "2539",
    value: "D841",
    label: "GADESCO PIEVE DELMONA",
    province: "CR"
  },
  {
    code: "2540",
    value: "D966",
    label: "GENIVOLTA",
    province: "CR"
  },
  {
    code: "2541",
    value: "D993",
    label: "GERRE DE CAPRIOLI",
    province: "CR"
  },
  {
    code: "2542",
    value: "E082",
    label: "GOMBITO",
    province: "CR"
  },
  {
    code: "2543",
    value: "E193",
    label: "GRONTARDO",
    province: "CR"
  },
  {
    code: "2544",
    value: "E217",
    label: "GRUMELLO CREMONESE ED UNITI",
    province: "CR"
  },
  {
    code: "2545",
    value: "E272",
    label: "GUSSOLA",
    province: "CR"
  },
  {
    code: "2546",
    value: "E356",
    label: "ISOLA DOVARESE",
    province: "CR"
  },
  {
    code: "2547",
    value: "E380",
    label: "IZANO",
    province: "CR"
  },
  {
    code: "2548",
    value: "E793",
    label: "MADIGNANO",
    province: "CR"
  },
  {
    code: "2549",
    value: "E843",
    label: "MALAGNINO",
    province: "CR"
  },
  {
    code: "2550",
    value: "E983",
    label: "MARTIGNANA DI PO",
    province: "CR"
  },
  {
    code: "2551",
    value: "F434",
    label: "MONTE CREMASCO",
    province: "CR"
  },
  {
    code: "2552",
    value: "F681",
    label: "MONTODINE",
    province: "CR"
  },
  {
    code: "2553",
    value: "F761",
    label: "MOSCAZZANO",
    province: "CR"
  },
  {
    code: "2554",
    value: "F771",
    label: "MOTTA BALUFFI",
    province: "CR"
  },
  {
    code: "2555",
    value: "G004",
    label: "OFFANENGO",
    province: "CR"
  },
  {
    code: "2556",
    value: "G047",
    label: "OLMENETA",
    province: "CR"
  },
  {
    code: "2557",
    value: "G185",
    label: "OSTIANO",
    province: "CR"
  },
  {
    code: "2558",
    value: "G222",
    label: "PADERNO PONCHIELLI",
    province: "CR"
  },
  {
    code: "2559",
    value: "G260",
    label: "PALAZZO PIGNANO",
    province: "CR"
  },
  {
    code: "2560",
    value: "G306",
    label: "PANDINO",
    province: "CR"
  },
  {
    code: "2561",
    value: "G469",
    label: "PERSICO DOSIMO",
    province: "CR"
  },
  {
    code: "2562",
    value: "G483",
    label: "PESCAROLO ED UNITI",
    province: "CR"
  },
  {
    code: "2563",
    value: "G504",
    label: "PESSINA CREMONESE",
    province: "CR"
  },
  {
    code: "2565",
    value: "G558",
    label: "PIANENGO",
    province: "CR"
  },
  {
    code: "2566",
    value: "G603",
    label: "PIERANICA",
    province: "CR"
  },
  {
    code: "2567",
    value: "G647",
    label: "PIEVE D OLMI",
    province: "CR"
  },
  {
    code: "2568",
    value: "G651",
    label: "PIEVE SAN GIACOMO",
    province: "CR"
  },
  {
    code: "2569",
    value: "G721",
    label: "PIZZIGHETTONE",
    province: "CR"
  },
  {
    code: "2570",
    value: "B914",
    label: "POZZAGLIO ED UNITI",
    province: "CR"
  },
  {
    code: "2571",
    value: "H130",
    label: "QUINTANO",
    province: "CR"
  },
  {
    code: "2572",
    value: "H276",
    label: "RICENGO",
    province: "CR"
  },
  {
    code: "2573",
    value: "H314",
    label: "RIPALTA ARPINA",
    province: "CR"
  },
  {
    code: "2574",
    value: "H315",
    label: "RIPALTA CREMASCA",
    province: "CR"
  },
  {
    code: "2575",
    value: "H316",
    label: "RIPALTA GUERINA",
    province: "CR"
  },
  {
    code: "2576",
    value: "H341",
    label: "RIVAROLO DEL RE ED UNITI",
    province: "CR"
  },
  {
    code: "2577",
    value: "H357",
    label: "RIVOLTA D ADDA",
    province: "CR"
  },
  {
    code: "2578",
    value: "H372",
    label: "ROBECCO D OGLIO",
    province: "CR"
  },
  {
    code: "2579",
    value: "H508",
    label: "ROMANENGO",
    province: "CR"
  },
  {
    code: "2580",
    value: "H731",
    label: "SALVIROLA",
    province: "CR"
  },
  {
    code: "2581",
    value: "H767",
    label: "SAN BASSANO",
    province: "CR"
  },
  {
    code: "2582",
    value: "H815",
    label: "SAN DANIELE PO",
    province: "CR"
  },
  {
    code: "2583",
    value: "H918",
    label: "SAN GIOVANNI IN CROCE",
    province: "CR"
  },
  {
    code: "2628",
    value: "C930",
    label: "COMMESSAGGIO",
    province: "MN"
  },
  {
    code: "2629",
    value: "D227",
    label: "CURTATONE",
    province: "MN"
  },
  {
    code: "2630",
    value: "D351",
    label: "DOSOLO",
    province: "MN"
  },
  {
    code: "2632",
    value: "D949",
    label: "GAZOLDO DEGLI IPPOLITI",
    province: "MN"
  },
  {
    code: "2633",
    value: "D959",
    label: "GAZZUOLO",
    province: "MN"
  },
  {
    code: "2634",
    value: "E078",
    label: "GOITO",
    province: "MN"
  },
  {
    code: "2635",
    value: "E089",
    label: "GONZAGA",
    province: "MN"
  },
  {
    code: "2636",
    value: "E261",
    label: "GUIDIZZOLO",
    province: "MN"
  },
  {
    code: "2637",
    value: "E818",
    label: "MAGNACAVALLO",
    province: "MN"
  },
  {
    code: "2638",
    value: "E897",
    label: "MANTOVA",
    province: "MN"
  },
  {
    code: "2639",
    value: "E922",
    label: "MARCARIA",
    province: "MN"
  },
  {
    code: "2640",
    value: "E949",
    label: "MARIANA MANTOVANA",
    province: "MN"
  },
  {
    code: "2641",
    value: "E962",
    label: "MARMIROLO",
    province: "MN"
  },
  {
    code: "2642",
    value: "F086",
    label: "MEDOLE",
    province: "MN"
  },
  {
    code: "2643",
    value: "F267",
    label: "MOGLIA",
    province: "MN"
  },
  {
    code: "2644",
    value: "F705",
    label: "MONZAMBANO",
    province: "MN"
  },
  {
    code: "2645",
    value: "B012",
    label: "MOTTEGGIANA",
    province: "MN"
  },
  {
    code: "2646",
    value: "G186",
    label: "OSTIGLIA",
    province: "MN"
  },
  {
    code: "2647",
    value: "G417",
    label: "PEGOGNAGA",
    province: "MN"
  },
  {
    code: "2649",
    value: "G717",
    label: "PIUBEGA",
    province: "MN"
  },
  {
    code: "2650",
    value: "G753",
    label: "POGGIO RUSCO",
    province: "MN"
  },
  {
    code: "2651",
    value: "G816",
    label: "POMPONESCO",
    province: "MN"
  },
  {
    code: "2652",
    value: "G862",
    label: "PONTI SUL MINCIO",
    province: "MN"
  },
  {
    code: "2653",
    value: "G917",
    label: "PORTO MANTOVANO",
    province: "MN"
  },
  {
    code: "2654",
    value: "H129",
    label: "QUINGENTOLE",
    province: "MN"
  },
  {
    code: "2655",
    value: "H143",
    label: "QUISTELLO",
    province: "MN"
  },
  {
    code: "2656",
    value: "H218",
    label: "REDONDESCO",
    province: "MN"
  },
  {
    code: "2658",
    value: "H342",
    label: "RIVAROLO MANTOVANO",
    province: "MN"
  },
  {
    code: "2659",
    value: "H481",
    label: "RODIGO",
    province: "MN"
  },
  {
    code: "2660",
    value: "H541",
    label: "RONCOFERRARO",
    province: "MN"
  },
  {
    code: "2661",
    value: "H604",
    label: "ROVERBELLA",
    province: "MN"
  },
  {
    code: "2662",
    value: "H652",
    label: "SABBIONETA",
    province: "MN"
  },
  {
    code: "2663",
    value: "H771",
    label: "SAN BENEDETTO PO",
    province: "MN"
  },
  {
    code: "2664",
    value: "H870",
    label: "SAN GIACOMO DELLE SEGNATE",
    province: "MN"
  },
  {
    code: "2665",
    value: "H883",
    label: "SAN GIORGIO BIGARELLO",
    province: "MN"
  },
  {
    code: "2666",
    value: "H912",
    label: "SAN GIOVANNI DEL DOSSO",
    province: "MN"
  },
  {
    code: "2667",
    value: "I005",
    label: "SAN MARTINO DALL ARGINE",
    province: "MN"
  },
  {
    code: "2668",
    value: "I532",
    label: "SCHIVENOGLIA",
    province: "MN"
  },
  {
    code: "2670",
    value: "I662",
    label: "SERRAVALLE A PO",
    province: "MN"
  },
  {
    code: "2671",
    value: "I801",
    label: "SOLFERINO",
    province: "MN"
  },
  {
    code: "2672",
    value: "L015",
    label: "SUSTINENTE",
    province: "MN"
  },
  {
    code: "2673",
    value: "L020",
    label: "SUZZARA",
    province: "MN"
  },
  {
    code: "2674",
    value: "L826",
    label: "VIADANA",
    province: "MN"
  },
  {
    code: "2676",
    value: "M044",
    label: "VILLIMPENTA",
    province: "MN"
  },
  {
    code: "2678",
    value: "M125",
    label: "VOLTA MANTOVANA",
    province: "MN"
  },
  {
    code: "2679",
    value: "A005",
    label: "ABBADIA LARIANA",
    province: "LC"
  },
  {
    code: "2680",
    value: "A112",
    label: "AIRUNO",
    province: "LC"
  },
  {
    code: "2681",
    value: "A301",
    label: "ANNONE DI BRIANZA",
    province: "LC"
  },
  {
    code: "2682",
    value: "A594",
    label: "BALLABIO",
    province: "LC"
  },
  {
    code: "2683",
    value: "A683",
    label: "BARZAGO",
    province: "LC"
  },
  {
    code: "2684",
    value: "A686",
    label: "BARZANO",
    province: "LC"
  },
  {
    code: "2685",
    value: "A687",
    label: "BARZIO",
    province: "LC"
  },
  {
    code: "2686",
    value: "A745",
    label: "BELLANO",
    province: "LC"
  },
  {
    code: "2687",
    value: "B081",
    label: "BOSISIO PARINI",
    province: "LC"
  },
  {
    code: "2688",
    value: "B194",
    label: "BRIVIO",
    province: "LC"
  },
  {
    code: "2689",
    value: "B261",
    label: "BULCIAGO",
    province: "LC"
  },
  {
    code: "2690",
    value: "B396",
    label: "CALCO",
    province: "LC"
  },
  {
    code: "2691",
    value: "B423",
    label: "CALOLZIOCORTE",
    province: "LC"
  },
  {
    code: "2692",
    value: "B763",
    label: "CARENNO",
    province: "LC"
  },
  {
    code: "2693",
    value: "B937",
    label: "CASARGO",
    province: "LC"
  },
  {
    code: "2694",
    value: "B943",
    label: "CASATENOVO",
    province: "LC"
  },
  {
    code: "2695",
    value: "B996",
    label: "CASSAGO BRIANZA",
    province: "LC"
  },
  {
    code: "2696",
    value: "C024",
    label: "CASSINA VALSASSINA",
    province: "LC"
  },
  {
    code: "2697",
    value: "C187",
    label: "CASTELLO DI BRIANZA",
    province: "LC"
  },
  {
    code: "2698",
    value: "C521",
    label: "CERNUSCO LOMBARDONE",
    province: "LC"
  },
  {
    code: "2699",
    value: "C563",
    label: "CESANA BRIANZA",
    province: "LC"
  },
  {
    code: "2700",
    value: "C752",
    label: "CIVATE",
    province: "LC"
  },
  {
    code: "2701",
    value: "C839",
    label: "COLICO",
    province: "LC"
  },
  {
    code: "2702",
    value: "C851",
    label: "COLLE BRIANZA",
    province: "LC"
  },
  {
    code: "2703",
    value: "D065",
    label: "CORTENOVA",
    province: "LC"
  },
  {
    code: "2704",
    value: "D112",
    label: "COSTA MASNAGA",
    province: "LC"
  },
  {
    code: "2705",
    value: "D131",
    label: "CRANDOLA VALSASSINA",
    province: "LC"
  },
  {
    code: "2706",
    value: "D143",
    label: "CREMELLA",
    province: "LC"
  },
  {
    code: "2707",
    value: "D145",
    label: "CREMENO",
    province: "LC"
  },
  {
    code: "2708",
    value: "D280",
    label: "DERVIO",
    province: "LC"
  },
  {
    code: "2709",
    value: "D327",
    label: "DOLZAGO",
    province: "LC"
  },
  {
    code: "2710",
    value: "D346",
    label: "DORIO",
    province: "LC"
  },
  {
    code: "2711",
    value: "D398",
    label: "ELLO",
    province: "LC"
  },
  {
    code: "2712",
    value: "D428",
    label: "ERVE",
    province: "LC"
  },
  {
    code: "2713",
    value: "D436",
    label: "ESINO LARIO",
    province: "LC"
  },
  {
    code: "2714",
    value: "D865",
    label: "GALBIATE",
    province: "LC"
  },
  {
    code: "2715",
    value: "D913",
    label: "GARBAGNATE MONASTERO",
    province: "LC"
  },
  {
    code: "2716",
    value: "D926",
    label: "GARLATE",
    province: "LC"
  },
  {
    code: "2717",
    value: "E287",
    label: "IMBERSAGO",
    province: "LC"
  },
  {
    code: "2718",
    value: "E305",
    label: "INTROBIO",
    province: "LC"
  },
  {
    code: "2720",
    value: "E507",
    label: "LECCO",
    province: "LC"
  },
  {
    code: "2721",
    value: "E581",
    label: "LIERNA",
    province: "LC"
  },
  {
    code: "2722",
    value: "E656",
    label: "LOMAGNA",
    province: "LC"
  },
  {
    code: "2723",
    value: "E858",
    label: "MALGRATE",
    province: "LC"
  },
  {
    code: "2724",
    value: "E879",
    label: "MANDELLO DEL LARIO",
    province: "LC"
  },
  {
    code: "2725",
    value: "E947",
    label: "MARGNO",
    province: "LC"
  },
  {
    code: "2726",
    value: "F133",
    label: "MERATE",
    province: "LC"
  },
  {
    code: "2727",
    value: "F248",
    label: "MISSAGLIA",
    province: "LC"
  },
  {
    code: "2728",
    value: "F265",
    label: "MOGGIO",
    province: "LC"
  },
  {
    code: "2729",
    value: "F304",
    label: "MOLTENO",
    province: "LC"
  },
  {
    code: "2730",
    value: "F561",
    label: "MONTE MARENZO",
    province: "LC"
  },
  {
    code: "2731",
    value: "F657",
    label: "MONTEVECCHIA",
    province: "LC"
  },
  {
    code: "2732",
    value: "F674",
    label: "MONTICELLO BRIANZA",
    province: "LC"
  },
  {
    code: "2733",
    value: "F758",
    label: "MORTERONE",
    province: "LC"
  },
  {
    code: "2734",
    value: "F887",
    label: "NIBIONNO",
    province: "LC"
  },
  {
    code: "2735",
    value: "G009",
    label: "OGGIONO",
    province: "LC"
  },
  {
    code: "2736",
    value: "G026",
    label: "OLGIATE MOLGORA",
    province: "LC"
  },
  {
    code: "2737",
    value: "G030",
    label: "OLGINATE",
    province: "LC"
  },
  {
    code: "2738",
    value: "G040",
    label: "OLIVETO LARIO",
    province: "LC"
  },
  {
    code: "2739",
    value: "G161",
    label: "OSNAGO",
    province: "LC"
  },
  {
    code: "2740",
    value: "G218",
    label: "PADERNO D ADDA",
    province: "LC"
  },
  {
    code: "2741",
    value: "G241",
    label: "PAGNONA",
    province: "LC"
  },
  {
    code: "2742",
    value: "G336",
    label: "PARLASCO",
    province: "LC"
  },
  {
    code: "2743",
    value: "G368",
    label: "PASTURO",
    province: "LC"
  },
  {
    code: "2745",
    value: "G456",
    label: "PERLEDO",
    province: "LC"
  },
  {
    code: "2746",
    value: "G485",
    label: "PESCATE",
    province: "LC"
  },
  {
    code: "2747",
    value: "H028",
    label: "PREMANA",
    province: "LC"
  },
  {
    code: "2748",
    value: "H063",
    label: "PRIMALUNA",
    province: "LC"
  },
  {
    code: "2749",
    value: "G223",
    label: "ROBBIATE",
    province: "LC"
  },
  {
    code: "2750",
    value: "H486",
    label: "ROGENO",
    province: "LC"
  },
  {
    code: "2752",
    value: "I243",
    label: "SANTA MARIA HOE",
    province: "LC"
  },
  {
    code: "2753",
    value: "I759",
    label: "SIRONE",
    province: "LC"
  },
  {
    code: "2754",
    value: "I761",
    label: "SIRTORI",
    province: "LC"
  },
  {
    code: "2755",
    value: "I994",
    label: "SUEGLIO",
    province: "LC"
  },
  {
    code: "2756",
    value: "I996",
    label: "SUELLO",
    province: "LC"
  },
  {
    code: "2757",
    value: "L022",
    label: "TACENO",
    province: "LC"
  },
  {
    code: "2758",
    value: "L257",
    label: "TORRE DE BUSI",
    province: "BG"
  },
  {
    code: "2760",
    value: "L581",
    label: "VALGREGHENTINO",
    province: "LC"
  },
  {
    code: "2761",
    value: "L634",
    label: "VALMADRERA",
    province: "LC"
  },
  {
    code: "2762",
    value: "L680",
    label: "VARENNA",
    province: "LC"
  },
  {
    code: "2764",
    value: "L751",
    label: "VERCURAGO",
    province: "LC"
  },
  {
    code: "2768",
    value: "L866",
    label: "VIGANO",
    province: "LC"
  },
  {
    code: "2769",
    value: "A004",
    label: "ABBADIA CERRETO",
    province: "LO"
  },
  {
    code: "2770",
    value: "A811",
    label: "BERTONICO",
    province: "LO"
  },
  {
    code: "2771",
    value: "A919",
    label: "BOFFALORA D ADDA",
    province: "LO"
  },
  {
    code: "2772",
    value: "A995",
    label: "BORGHETTO LODIGIANO",
    province: "LO"
  },
  {
    code: "2773",
    value: "B017",
    label: "BORGO SAN GIOVANNI",
    province: "LO"
  },
  {
    code: "2774",
    value: "B141",
    label: "BREMBIO",
    province: "LO"
  },
  {
    code: "2776",
    value: "B887",
    label: "CASALETTO LODIGIANO",
    province: "LO"
  },
  {
    code: "2777",
    value: "B899",
    label: "CASALMAIOCCO",
    province: "LO"
  },
  {
    code: "2778",
    value: "B910",
    label: "CASALPUSTERLENGO",
    province: "LO"
  },
  {
    code: "2779",
    value: "B961",
    label: "CASELLE LANDI",
    province: "LO"
  },
  {
    code: "2780",
    value: "B958",
    label: "CASELLE LURANI",
    province: "LO"
  },
  {
    code: "2781",
    value: "C228",
    label: "CASTELNUOVO BOCCA D ADDA",
    province: "LO"
  },
  {
    code: "2782",
    value: "C304",
    label: "CASTIGLIONE D ADDA",
    province: "LO"
  },
  {
    code: "2783",
    value: "C329",
    label: "CASTIRAGA VIDARDO",
    province: "LO"
  },
  {
    code: "2785",
    value: "C394",
    label: "CAVENAGO D ADDA",
    province: "LO"
  },
  {
    code: "2786",
    value: "C555",
    label: "CERVIGNANO D ADDA",
    province: "LO"
  },
  {
    code: "2787",
    value: "C816",
    label: "CODOGNO",
    province: "LO"
  },
  {
    code: "2788",
    value: "C917",
    label: "COMAZZO",
    province: "LO"
  },
  {
    code: "2789",
    value: "D021",
    label: "CORNEGLIANO LAUDENSE",
    province: "LO"
  },
  {
    code: "2790",
    value: "D028",
    label: "CORNO GIOVINE",
    province: "LO"
  },
  {
    code: "2791",
    value: "D029",
    label: "CORNOVECCHIO",
    province: "LO"
  },
  {
    code: "2792",
    value: "D068",
    label: "CORTE PALASIO",
    province: "LO"
  },
  {
    code: "2793",
    value: "D159",
    label: "CRESPIATICA",
    province: "LO"
  },
  {
    code: "2794",
    value: "D660",
    label: "FOMBIO",
    province: "LO"
  },
  {
    code: "2795",
    value: "D868",
    label: "GALGAGNANO",
    province: "LO"
  },
  {
    code: "2796",
    value: "E127",
    label: "GRAFFIGNANA",
    province: "LO"
  },
  {
    code: "2797",
    value: "E238",
    label: "GUARDAMIGLIO",
    province: "LO"
  },
  {
    code: "2798",
    value: "E627",
    label: "LIVRAGA",
    province: "LO"
  },
  {
    code: "2799",
    value: "E648",
    label: "LODI",
    province: "LO"
  },
  {
    code: "2800",
    value: "E651",
    label: "LODI VECCHIO",
    province: "LO"
  },
  {
    code: "2801",
    value: "E777",
    label: "MACCASTORNA",
    province: "LO"
  },
  {
    code: "2802",
    value: "E840",
    label: "MAIRAGO",
    province: "LO"
  },
  {
    code: "2803",
    value: "E852",
    label: "MALEO",
    province: "LO"
  },
  {
    code: "2804",
    value: "E994",
    label: "MARUDO",
    province: "LO"
  },
  {
    code: "2805",
    value: "F028",
    label: "MASSALENGO",
    province: "LO"
  },
  {
    code: "2806",
    value: "F102",
    label: "MELETI",
    province: "LO"
  },
  {
    code: "2807",
    value: "F149",
    label: "MERLINO",
    province: "LO"
  },
  {
    code: "2808",
    value: "F423",
    label: "MONTANASO LOMBARDO",
    province: "LO"
  },
  {
    code: "2809",
    value: "F801",
    label: "MULAZZANO",
    province: "LO"
  },
  {
    code: "2810",
    value: "G107",
    label: "ORIO LITTA",
    province: "LO"
  },
  {
    code: "2811",
    value: "G166",
    label: "OSPEDALETTO LODIGIANO",
    province: "LO"
  },
  {
    code: "2812",
    value: "G171",
    label: "OSSAGO LODIGIANO",
    province: "LO"
  },
  {
    code: "2813",
    value: "G096",
    label: "PIEVE FISSIRAGA",
    province: "LO"
  },
  {
    code: "2814",
    value: "H701",
    label: "SALERANO SUL LAMBRO",
    province: "LO"
  },
  {
    code: "2815",
    value: "H844",
    label: "SAN FIORANO",
    province: "LO"
  },
  {
    code: "2816",
    value: "I012",
    label: "SAN MARTINO IN STRADA",
    province: "LO"
  },
  {
    code: "2817",
    value: "I140",
    label: "SAN ROCCO AL PORTO",
    province: "LO"
  },
  {
    code: "2818",
    value: "I274",
    label: "SANT ANGELO LODIGIANO",
    province: "LO"
  },
  {
    code: "2819",
    value: "I362",
    label: "SANTO STEFANO LODIGIANO",
    province: "LO"
  },
  {
    code: "2820",
    value: "I561",
    label: "SECUGNAGO",
    province: "LO"
  },
  {
    code: "2821",
    value: "I612",
    label: "SENNA LODIGIANA",
    province: "LO"
  },
  {
    code: "2822",
    value: "I815",
    label: "SOMAGLIA",
    province: "LO"
  },
  {
    code: "2823",
    value: "I848",
    label: "SORDIO",
    province: "LO"
  },
  {
    code: "2824",
    value: "F260",
    label: "TAVAZZANO CON VILLAVESCO",
    province: "LO"
  },
  {
    code: "2825",
    value: "L125",
    label: "TERRANOVA DEI PASSERINI",
    province: "LO"
  },
  {
    code: "2826",
    value: "L469",
    label: "TURANO LODIGIANO",
    province: "LO"
  },
  {
    code: "2827",
    value: "L572",
    label: "VALERA FRATTA",
    province: "LO"
  },
  {
    code: "2828",
    value: "L977",
    label: "VILLANOVA DEL SILLARO",
    province: "LO"
  },
  {
    code: "2829",
    value: "M158",
    label: "ZELO BUON PERSICO",
    province: "LO"
  },
  {
    code: "2830",
    value: "A179",
    label: "ALDINO",
    province: "BZ"
  },
  {
    code: "2831",
    value: "A286",
    label: "ANDRIANO",
    province: "BZ"
  },
  {
    code: "2832",
    value: "A306",
    label: "ANTERIVO",
    province: "BZ"
  },
  {
    code: "2833",
    value: "A332",
    label: "APPIANO SULLA STRADA DEL VINO",
    province: "BZ"
  },
  {
    code: "2834",
    value: "A507",
    label: "AVELENGO",
    province: "BZ"
  },
  {
    code: "2835",
    value: "A537",
    label: "BADIA",
    province: "BZ"
  },
  {
    code: "2836",
    value: "A635",
    label: "BARBIANO",
    province: "BZ"
  },
  {
    code: "2837",
    value: "A952",
    label: "BOLZANO",
    province: "BZ"
  },
  {
    code: "2838",
    value: "B116",
    label: "BRAIES",
    province: "BZ"
  },
  {
    code: "2839",
    value: "B145",
    label: "BRENNERO",
    province: "BZ"
  },
  {
    code: "2840",
    value: "B160",
    label: "BRESSANONE",
    province: "BZ"
  },
  {
    code: "2841",
    value: "B203",
    label: "BRONZOLO",
    province: "BZ"
  },
  {
    code: "2842",
    value: "B220",
    label: "BRUNICO",
    province: "BZ"
  },
  {
    code: "2843",
    value: "B364",
    label: "CAINES",
    province: "BZ"
  },
  {
    code: "2844",
    value: "B397",
    label: "CALDARO SULLA STRADA DEL VINO",
    province: "BZ"
  },
  {
    code: "2845",
    value: "B529",
    label: "CAMPO DI TRENS",
    province: "BZ"
  },
  {
    code: "2846",
    value: "B570",
    label: "CAMPO TURES",
    province: "BZ"
  },
  {
    code: "2847",
    value: "C062",
    label: "CASTELBELLO CIARDES",
    province: "BZ"
  },
  {
    code: "2848",
    value: "C254",
    label: "CASTELROTTO",
    province: "BZ"
  },
  {
    code: "2849",
    value: "A022",
    label: "CERMES",
    province: "BZ"
  },
  {
    code: "2850",
    value: "C625",
    label: "CHIENES",
    province: "BZ"
  },
  {
    code: "2851",
    value: "C652",
    label: "CHIUSA",
    province: "BZ"
  },
  {
    code: "2852",
    value: "B799",
    label: "CORNEDO ALL ISARCO",
    province: "BZ"
  },
  {
    code: "2853",
    value: "D048",
    label: "CORTACCIA SULLA STRADA DEL VINO",
    province: "BZ"
  },
  {
    code: "2854",
    value: "D075",
    label: "CORTINA SULLA STRADA DEL VINO",
    province: "BZ"
  },
  {
    code: "2855",
    value: "D079",
    label: "CORVARA IN BADIA",
    province: "BZ"
  },
  {
    code: "2856",
    value: "D222",
    label: "CURON VENOSTA",
    province: "BZ"
  },
  {
    code: "2857",
    value: "D311",
    label: "DOBBIACO",
    province: "BZ"
  },
  {
    code: "2858",
    value: "D392",
    label: "EGNA",
    province: "BZ"
  },
  {
    code: "2859",
    value: "D484",
    label: "FALZES",
    province: "BZ"
  },
  {
    code: "2860",
    value: "D571",
    label: "FIE ALLO SCILIAR",
    province: "BZ"
  },
  {
    code: "2861",
    value: "D731",
    label: "FORTEZZA",
    province: "BZ"
  },
  {
    code: "2862",
    value: "D821",
    label: "FUNES",
    province: "BZ"
  },
  {
    code: "2864",
    value: "D923",
    label: "GARGAZZONE",
    province: "BZ"
  },
  {
    code: "2865",
    value: "E069",
    label: "GLORENZA",
    province: "BZ"
  },
  {
    code: "2866",
    value: "E398",
    label: "LACES",
    province: "BZ"
  },
  {
    code: "2867",
    value: "E412",
    label: "LAGUNDO",
    province: "BZ"
  },
  {
    code: "2868",
    value: "E420",
    label: "LAION",
    province: "BZ"
  },
  {
    code: "2869",
    value: "E421",
    label: "LAIVES",
    province: "BZ"
  },
  {
    code: "2870",
    value: "E434",
    label: "LANA",
    province: "BZ"
  },
  {
    code: "2871",
    value: "E457",
    label: "LASA",
    province: "BZ"
  },
  {
    code: "2872",
    value: "E481",
    label: "LAUREGNO",
    province: "BZ"
  },
  {
    code: "2873",
    value: "E764",
    label: "LUSON",
    province: "BZ"
  },
  {
    code: "2874",
    value: "E829",
    label: "MAGRE SULLA STRADA DEL VINO",
    province: "BZ"
  },
  {
    code: "2875",
    value: "E862",
    label: "MALLES VENOSTA",
    province: "BZ"
  },
  {
    code: "2876",
    value: "E938",
    label: "MAREBBE",
    province: "BZ"
  },
  {
    code: "2877",
    value: "E959",
    label: "MARLENGO",
    province: "BZ"
  },
  {
    code: "2878",
    value: "E981",
    label: "MARTELLO",
    province: "BZ"
  },
  {
    code: "2879",
    value: "F118",
    label: "MELTINA",
    province: "BZ"
  },
  {
    code: "2880",
    value: "F132",
    label: "MERANO",
    province: "BZ"
  },
  {
    code: "2881",
    value: "F371",
    label: "MONGUELFO TESIDO",
    province: "BZ"
  },
  {
    code: "2882",
    value: "F392",
    label: "MONTAGNA",
    province: "BZ"
  },
  {
    code: "2883",
    value: "F766",
    label: "MOSO IN PASSIRIA",
    province: "BZ"
  },
  {
    code: "2884",
    value: "F836",
    label: "NALLES",
    province: "BZ"
  },
  {
    code: "2885",
    value: "F849",
    label: "NATURNO",
    province: "BZ"
  },
  {
    code: "2886",
    value: "F856",
    label: "NAZ SCIAVES",
    province: "BZ"
  },
  {
    code: "2887",
    value: "F949",
    label: "NOVA LEVANTE",
    province: "BZ"
  },
  {
    code: "2888",
    value: "F950",
    label: "NOVA PONENTE",
    province: "BZ"
  },
  {
    code: "2889",
    value: "G083",
    label: "ORA",
    province: "BZ"
  },
  {
    code: "2890",
    value: "G140",
    label: "ORTISEI",
    province: "BZ"
  },
  {
    code: "2891",
    value: "G328",
    label: "PARCINES",
    province: "BZ"
  },
  {
    code: "2892",
    value: "G443",
    label: "PERCA",
    province: "BZ"
  },
  {
    code: "2894",
    value: "G830",
    label: "PONTE GARDENA",
    province: "BZ"
  },
  {
    code: "2895",
    value: "G936",
    label: "POSTAL",
    province: "BZ"
  },
  {
    code: "2896",
    value: "H004",
    label: "PRATO ALLO STELVIO",
    province: "BZ"
  },
  {
    code: "2897",
    value: "H019",
    label: "PREDOI",
    province: "BZ"
  },
  {
    code: "2898",
    value: "H081",
    label: "PROVES",
    province: "BZ"
  },
  {
    code: "2899",
    value: "H152",
    label: "RACINES",
    province: "BZ"
  },
  {
    code: "2900",
    value: "H189",
    label: "RASUN ANTERSELVA",
    province: "BZ"
  },
  {
    code: "2901",
    value: "H236",
    label: "RENON",
    province: "BZ"
  },
  {
    code: "2902",
    value: "H284",
    label: "RIFIANO",
    province: "BZ"
  },
  {
    code: "2903",
    value: "H299",
    label: "RIO DI PUSTERIA",
    province: "BZ"
  },
  {
    code: "2904",
    value: "H475",
    label: "RODENGO",
    province: "BZ"
  },
  {
    code: "2905",
    value: "H719",
    label: "SALORNO SULLA STRADA DEL VINO",
    province: "BZ"
  },
  {
    code: "2906",
    value: "H786",
    label: "SAN CANDIDO",
    province: "BZ"
  },
  {
    code: "2907",
    value: "H858",
    label: "SAN GENESIO ATESINO",
    province: "BZ"
  },
  {
    code: "2908",
    value: "H952",
    label: "SAN LEONARDO IN PASSIRIA",
    province: "BZ"
  },
  {
    code: "2909",
    value: "H956",
    label: "SAN LORENZO DI SEBATO",
    province: "BZ"
  },
  {
    code: "2910",
    value: "H988",
    label: "SAN MARTINO IN BADIA",
    province: "BZ"
  },
  {
    code: "2911",
    value: "H989",
    label: "SAN MARTINO IN PASSIRIA",
    province: "BZ"
  },
  {
    code: "2912",
    value: "I065",
    label: "SAN PANCRAZIO",
    province: "BZ"
  },
  {
    code: "2913",
    value: "I173",
    label: "SANTA CRISTINA VAL GARDENA",
    province: "BZ"
  },
  {
    code: "2914",
    value: "I431",
    label: "SARENTINO",
    province: "BZ"
  },
  {
    code: "2915",
    value: "I519",
    label: "SCENA",
    province: "BZ"
  },
  {
    code: "2916",
    value: "I593",
    label: "SELVA DEI MOLINI",
    province: "BZ"
  },
  {
    code: "2917",
    value: "I591",
    label: "SELVA DI VAL GARDENA",
    province: "BZ"
  },
  {
    code: "2918",
    value: "I604",
    label: "SENALES",
    province: "BZ"
  },
  {
    code: "2919",
    value: "I687",
    label: "SESTO",
    province: "BZ"
  },
  {
    code: "2920",
    value: "I729",
    label: "SILANDRO",
    province: "BZ"
  },
  {
    code: "2921",
    value: "I771",
    label: "SLUDERNO",
    province: "BZ"
  },
  {
    code: "2922",
    value: "I948",
    label: "STELVIO",
    province: "BZ"
  },
  {
    code: "2923",
    value: "L106",
    label: "TERENTO",
    province: "BZ"
  },
  {
    code: "2924",
    value: "L108",
    label: "TERLANO",
    province: "BZ"
  },
  {
    code: "2925",
    value: "L111",
    label: "TERMENO SULLA STRADA DEL VINO",
    province: "BZ"
  },
  {
    code: "2926",
    value: "L149",
    label: "TESIMO",
    province: "BZ"
  },
  {
    code: "2927",
    value: "L176",
    label: "TIRES",
    province: "BZ"
  },
  {
    code: "2928",
    value: "L178",
    label: "TIROLO",
    province: "BZ"
  },
  {
    code: "2929",
    value: "L444",
    label: "TRODENA NEL PARCO NATURALE",
    province: "BZ"
  },
  {
    code: "2930",
    value: "L455",
    label: "TUBRE",
    province: "BZ"
  },
  {
    code: "2931",
    value: "L490",
    label: "ULTIMO",
    province: "BZ"
  },
  {
    code: "2932",
    value: "L527",
    label: "VADENA",
    province: "BZ"
  },
  {
    code: "2933",
    value: "L552",
    label: "VALDAORA",
    province: "BZ"
  },
  {
    code: "2934",
    value: "L564",
    label: "VAL DI VIZZE",
    province: "BZ"
  },
  {
    code: "2935",
    value: "L595",
    label: "VALLE AURINA",
    province: "BZ"
  },
  {
    code: "2936",
    value: "L601",
    label: "VALLE DI CASIES",
    province: "BZ"
  },
  {
    code: "2937",
    value: "L660",
    label: "VANDOIES",
    province: "BZ"
  },
  {
    code: "2938",
    value: "L687",
    label: "VARNA",
    province: "BZ"
  },
  {
    code: "2939",
    value: "L745",
    label: "VERANO",
    province: "BZ"
  },
  {
    code: "2940",
    value: "L915",
    label: "VILLABASSA",
    province: "BZ"
  },
  {
    code: "2941",
    value: "L971",
    label: "VILLANDRO",
    province: "BZ"
  },
  {
    code: "2942",
    value: "M067",
    label: "VIPITENO",
    province: "BZ"
  },
  {
    code: "2943",
    value: "L724",
    label: "VELTURNO",
    province: "BZ"
  },
  {
    code: "2944",
    value: "E491",
    label: "LA VALLE",
    province: "BZ"
  },
  {
    code: "2945",
    value: "I603",
    label: "SENALE SAN FELICE",
    province: "BZ"
  },
  {
    code: "2946",
    value: "A116",
    label: "ALA",
    province: "TN"
  },
  {
    code: "2947",
    value: "A158",
    label: "ALBIANO",
    province: "TN"
  },
  {
    code: "2948",
    value: "A178",
    label: "ALDENO",
    province: "TN"
  },
  {
    code: "2950",
    value: "A274",
    label: "ANDALO",
    province: "TN"
  },
  {
    code: "2951",
    value: "A372",
    label: "ARCO",
    province: "TN"
  },
  {
    code: "2952",
    value: "A520",
    label: "AVIO",
    province: "TN"
  },
  {
    code: "2953",
    value: "A694",
    label: "BASELGA DI PINE",
    province: "TN"
  },
  {
    code: "2954",
    value: "A730",
    label: "BEDOLLO",
    province: "TN"
  },
  {
    code: "2956",
    value: "A821",
    label: "BESENELLO",
    province: "TN"
  },
  {
    code: "2958",
    value: "A863",
    label: "BIENO",
    province: "TN"
  },
  {
    code: "2960",
    value: "A902",
    label: "BLEGGIO SUPERIORE",
    province: "TN"
  },
  {
    code: "2961",
    value: "A916",
    label: "BOCENAGO",
    province: "TN"
  },
  {
    code: "2964",
    value: "A968",
    label: "BONDONE",
    province: "TN"
  },
  {
    code: "2965",
    value: "B006",
    label: "BORGO VALSUGANA",
    province: "TN"
  },
  {
    code: "2968",
    value: "B153",
    label: "BRENTONICO",
    province: "TN"
  },
  {
    code: "2969",
    value: "B158",
    label: "BRESIMO",
    province: "TN"
  },
  {
    code: "2972",
    value: "B335",
    label: "CADERZONE TERME",
    province: "TN"
  },
  {
    code: "2975",
    value: "B389",
    label: "CALCERANICA AL LAGO",
    province: "TN"
  },
  {
    code: "2976",
    value: "B400",
    label: "CALDES",
    province: "TN"
  },
  {
    code: "2977",
    value: "B404",
    label: "CALDONAZZO",
    province: "TN"
  },
  {
    code: "2978",
    value: "B419",
    label: "CALLIANO",
    province: "TN"
  },
  {
    code: "2979",
    value: "B514",
    label: "CAMPITELLO DI FASSA",
    province: "TN"
  },
  {
    code: "2980",
    value: "B525",
    label: "CAMPODENNO",
    province: "TN"
  },
  {
    code: "2981",
    value: "B577",
    label: "CANAL SAN BOVO",
    province: "TN"
  },
  {
    code: "2982",
    value: "B579",
    label: "CANAZEI",
    province: "TN"
  },
  {
    code: "2983",
    value: "B697",
    label: "CAPRIANA",
    province: "TN"
  },
  {
    code: "2985",
    value: "B783",
    label: "CARISOLO",
    province: "TN"
  },
  {
    code: "2986",
    value: "B856",
    label: "CARZANO",
    province: "TN"
  },
  {
    code: "2987",
    value: "C183",
    label: "CASTEL CONDINO",
    province: "TN"
  },
  {
    code: "2989",
    value: "C189",
    label: "CASTELLO MOLINA DI FIEMME",
    province: "TN"
  },
  {
    code: "2990",
    value: "C194",
    label: "CASTELLO TESINO",
    province: "TN"
  },
  {
    code: "2991",
    value: "C216",
    label: "CASTELNUOVO",
    province: "TN"
  },
  {
    code: "2992",
    value: "C372",
    label: "CAVALESE",
    province: "TN"
  },
  {
    code: "2993",
    value: "C380",
    label: "CAVARENO",
    province: "TN"
  },
  {
    code: "2994",
    value: "C392",
    label: "CAVEDAGO",
    province: "TN"
  },
  {
    code: "2995",
    value: "C393",
    label: "CAVEDINE",
    province: "TN"
  },
  {
    code: "2996",
    value: "C400",
    label: "CAVIZZANA",
    province: "TN"
  },
  {
    code: "3000",
    value: "C700",
    label: "CIMONE",
    province: "TN"
  },
  {
    code: "3001",
    value: "C712",
    label: "CINTE TESINO",
    province: "TN"
  },
  {
    code: "3002",
    value: "C727",
    label: "CIS",
    province: "TN"
  },
  {
    code: "3003",
    value: "C756",
    label: "CIVEZZANO",
    province: "TN"
  },
  {
    code: "3004",
    value: "C794",
    label: "CLES",
    province: "TN"
  },
  {
    code: "3006",
    value: "C931",
    label: "COMMEZZADURA",
    province: "TN"
  },
  {
    code: "3010",
    value: "D188",
    label: "CROVIANA",
    province: "TN"
  },
  {
    code: "3013",
    value: "D246",
    label: "DAMBEL",
    province: "TN"
  },
  {
    code: "3016",
    value: "D273",
    label: "DENNO",
    province: "TN"
  },
  {
    code: "3020",
    value: "D365",
    label: "DRENA",
    province: "TN"
  },
  {
    code: "3021",
    value: "D371",
    label: "DRO",
    province: "TN"
  },
  {
    code: "3023",
    value: "D468",
    label: "FAI DELLA PAGANELLA",
    province: "TN"
  },
  {
    code: "3025",
    value: "D565",
    label: "FIAVE",
    province: "TN"
  },
  {
    code: "3027",
    value: "D573",
    label: "FIEROZZO",
    province: "TN"
  },
  {
    code: "3029",
    value: "D651",
    label: "FOLGARIA",
    province: "TN"
  },
  {
    code: "3031",
    value: "D714",
    label: "FORNACE",
    province: "TN"
  },
  {
    code: "3032",
    value: "D775",
    label: "FRASSILONGO",
    province: "TN"
  },
  {
    code: "3033",
    value: "D928",
    label: "GARNIGA TERME",
    province: "TN"
  },
  {
    code: "3034",
    value: "E048",
    label: "GIOVO",
    province: "TN"
  },
  {
    code: "3035",
    value: "E065",
    label: "GIUSTINO",
    province: "TN"
  },
  {
    code: "3037",
    value: "E178",
    label: "GRIGNO",
    province: "TN"
  },
  {
    code: "3039",
    value: "E288",
    label: "IMER",
    province: "TN"
  },
  {
    code: "3040",
    value: "E334",
    label: "ISERA",
    province: "TN"
  },
  {
    code: "3044",
    value: "E492",
    label: "LAVARONE",
    province: "TN"
  },
  {
    code: "3045",
    value: "E500",
    label: "LAVIS",
    province: "TN"
  },
  {
    code: "3046",
    value: "E565",
    label: "LEVICO TERME",
    province: "TN"
  },
  {
    code: "3048",
    value: "E624",
    label: "LIVO",
    province: "TN"
  },
  {
    code: "3050",
    value: "E664",
    label: "LONA LASES",
    province: "TN"
  },
  {
    code: "3051",
    value: "E757",
    label: "LUSERNA",
    province: "TN"
  },
  {
    code: "3052",
    value: "E850",
    label: "MALE",
    province: "TN"
  },
  {
    code: "3054",
    value: "F045",
    label: "MASSIMENO",
    province: "TN"
  },
  {
    code: "3055",
    value: "F068",
    label: "MAZZIN",
    province: "TN"
  },
  {
    code: "3056",
    value: "F168",
    label: "MEZZANA",
    province: "TN"
  },
  {
    code: "3057",
    value: "F176",
    label: "MEZZANO",
    province: "TN"
  },
  {
    code: "3058",
    value: "F183",
    label: "MEZZOCORONA",
    province: "TN"
  },
  {
    code: "3059",
    value: "F187",
    label: "MEZZOLOMBARDO",
    province: "TN"
  },
  {
    code: "3060",
    value: "F263",
    label: "MOENA",
    province: "TN"
  },
  {
    code: "3062",
    value: "F307",
    label: "MOLVENO",
    province: "TN"
  },
  {
    code: "3065",
    value: "F728",
    label: "MORI",
    province: "TN"
  },
  {
    code: "3066",
    value: "F835",
    label: "NAGO TORBOLE",
    province: "TN"
  },
  {
    code: "3069",
    value: "F920",
    label: "NOGAREDO",
    province: "TN"
  },
  {
    code: "3070",
    value: "F929",
    label: "NOMI",
    province: "TN"
  },
  {
    code: "3071",
    value: "F947",
    label: "NOVALEDO",
    province: "TN"
  },
  {
    code: "3072",
    value: "G168",
    label: "OSPEDALETTO",
    province: "TN"
  },
  {
    code: "3073",
    value: "G173",
    label: "OSSANA",
    province: "TN"
  },
  {
    code: "3075",
    value: "G296",
    label: "PALU DEL FERSINA",
    province: "TN"
  },
  {
    code: "3076",
    value: "G305",
    label: "PANCHIA",
    province: "TN"
  },
  {
    code: "3077",
    value: "M303",
    label: "RONZO CHIENIS",
    province: "TN"
  },
  {
    code: "3078",
    value: "G419",
    label: "PEIO",
    province: "TN"
  },
  {
    code: "3079",
    value: "G428",
    label: "PELLIZZANO",
    province: "TN"
  },
  {
    code: "3080",
    value: "G429",
    label: "PELUGO",
    province: "TN"
  },
  {
    code: "3081",
    value: "G452",
    label: "PERGINE VALSUGANA",
    province: "TN"
  },
  {
    code: "3084",
    value: "G656",
    label: "PIEVE TESINO",
    province: "TN"
  },
  {
    code: "3085",
    value: "G681",
    label: "PINZOLO",
    province: "TN"
  },
  {
    code: "3086",
    value: "G808",
    label: "POMAROLO",
    province: "TN"
  },
  {
    code: "3089",
    value: "H018",
    label: "PREDAZZO",
    province: "TN"
  },
  {
    code: "3092",
    value: "H146",
    label: "RABBI",
    province: "TN"
  },
  {
    code: "3095",
    value: "H330",
    label: "RIVA DEL GARDA",
    province: "TN"
  },
  {
    code: "3097",
    value: "H517",
    label: "ROMENO",
    province: "TN"
  },
  {
    code: "3098",
    value: "H528",
    label: "RONCEGNO TERME",
    province: "TN"
  },
  {
    code: "3099",
    value: "H532",
    label: "RONCHI VALSUGANA",
    province: "TN"
  },
  {
    code: "3101",
    value: "H552",
    label: "RONZONE",
    province: "TN"
  },
  {
    code: "3102",
    value: "H607",
    label: "ROVERE DELLA LUNA",
    province: "TN"
  },
  {
    code: "3103",
    value: "H612",
    label: "ROVERETO",
    province: "TN"
  },
  {
    code: "3104",
    value: "H634",
    label: "RUFFRE MENDOLA",
    province: "TN"
  },
  {
    code: "3105",
    value: "H639",
    label: "RUMO",
    province: "TN"
  },
  {
    code: "3106",
    value: "H666",
    label: "SAGRON MIS",
    province: "TN"
  },
  {
    code: "3107",
    value: "H754",
    label: "SAMONE",
    province: "TN"
  },
  {
    code: "3109",
    value: "I042",
    label: "SAN MICHELE ALL ADIGE",
    province: "TN"
  },
  {
    code: "3110",
    value: "I354",
    label: "SANT ORSOLA TERME",
    province: "TN"
  },
  {
    code: "3111",
    value: "I411",
    label: "SANZENO",
    province: "TN"
  },
  {
    code: "3112",
    value: "I439",
    label: "SARNONICO",
    province: "TN"
  },
  {
    code: "3113",
    value: "I554",
    label: "SCURELLE",
    province: "TN"
  },
  {
    code: "3114",
    value: "I576",
    label: "SEGONZANO",
    province: "TN"
  },
  {
    code: "3115",
    value: "I714",
    label: "SFRUZ",
    province: "TN"
  },
  {
    code: "3118",
    value: "I839",
    label: "SORAGA DI FASSA",
    province: "TN"
  },
  {
    code: "3119",
    value: "I871",
    label: "SOVER",
    province: "TN"
  },
  {
    code: "3121",
    value: "I899",
    label: "SPIAZZO",
    province: "TN"
  },
  {
    code: "3122",
    value: "I924",
    label: "SPORMAGGIORE",
    province: "TN"
  },
  {
    code: "3123",
    value: "I925",
    label: "SPORMINORE",
    province: "TN"
  },
  {
    code: "3124",
    value: "I949",
    label: "STENICO",
    province: "TN"
  },
  {
    code: "3125",
    value: "I964",
    label: "STORO",
    province: "TN"
  },
  {
    code: "3126",
    value: "I975",
    label: "STREMBO",
    province: "TN"
  },
  {
    code: "3130",
    value: "L089",
    label: "TELVE",
    province: "TN"
  },
  {
    code: "3131",
    value: "L090",
    label: "TELVE DI SOPRA",
    province: "TN"
  },
  {
    code: "3132",
    value: "L096",
    label: "TENNA",
    province: "TN"
  },
  {
    code: "3133",
    value: "L097",
    label: "TENNO",
    province: "TN"
  },
  {
    code: "3135",
    value: "L121",
    label: "TERRAGNOLO",
    province: "TN"
  },
  {
    code: "3137",
    value: "L145",
    label: "TERZOLAS",
    province: "TN"
  },
  {
    code: "3138",
    value: "L147",
    label: "TESERO",
    province: "TN"
  },
  {
    code: "3141",
    value: "L174",
    label: "TIONE DI TRENTO",
    province: "TN"
  },
  {
    code: "3142",
    value: "L200",
    label: "TON",
    province: "TN"
  },
  {
    code: "3144",
    value: "L211",
    label: "TORCEGNO",
    province: "TN"
  },
  {
    code: "3145",
    value: "L322",
    label: "TRAMBILENO",
    province: "TN"
  },
  {
    code: "3147",
    value: "L378",
    label: "TRENTO",
    province: "TN"
  },
  {
    code: "3151",
    value: "L575",
    label: "VALFLORIANA",
    province: "TN"
  },
  {
    code: "3152",
    value: "L588",
    label: "VALLARSA",
    province: "TN"
  },
  {
    code: "3155",
    value: "L769",
    label: "VERMIGLIO",
    province: "TN"
  },
  {
    code: "3158",
    value: "L886",
    label: "VIGNOLA FALESINA",
    province: "TN"
  },
  {
    code: "3163",
    value: "L957",
    label: "VILLA LAGARINA",
    province: "TN"
  },
  {
    code: "3165",
    value: "M113",
    label: "VOLANO",
    province: "TN"
  },
  {
    code: "3167",
    value: "M173",
    label: "ZIANO DI FIEMME",
    province: "TN"
  },
  {
    code: "3169",
    value: "A061",
    label: "AFFI",
    province: "VR"
  },
  {
    code: "3170",
    value: "A137",
    label: "ALBAREDO D ADIGE",
    province: "VR"
  },
  {
    code: "3171",
    value: "A292",
    label: "ANGIARI",
    province: "VR"
  },
  {
    code: "3172",
    value: "A374",
    label: "ARCOLE",
    province: "VR"
  },
  {
    code: "3173",
    value: "A540",
    label: "BADIA CALAVENA",
    province: "VR"
  },
  {
    code: "3174",
    value: "A650",
    label: "BARDOLINO",
    province: "VR"
  },
  {
    code: "3175",
    value: "A737",
    label: "BELFIORE",
    province: "VR"
  },
  {
    code: "3176",
    value: "A837",
    label: "BEVILACQUA",
    province: "VR"
  },
  {
    code: "3177",
    value: "A964",
    label: "BONAVIGO",
    province: "VR"
  },
  {
    code: "3178",
    value: "B070",
    label: "BOSCHI SANT ANNA",
    province: "VR"
  },
  {
    code: "3179",
    value: "B073",
    label: "BOSCO CHIESANUOVA",
    province: "VR"
  },
  {
    code: "3180",
    value: "B107",
    label: "BOVOLONE",
    province: "VR"
  },
  {
    code: "3181",
    value: "B152",
    label: "BRENTINO BELLUNO",
    province: "VR"
  },
  {
    code: "3182",
    value: "B154",
    label: "BRENZONE SUL GARDA",
    province: "VR"
  },
  {
    code: "3183",
    value: "B296",
    label: "BUSSOLENGO",
    province: "VR"
  },
  {
    code: "3184",
    value: "B304",
    label: "BUTTAPIETRA",
    province: "VR"
  },
  {
    code: "3185",
    value: "B402",
    label: "CALDIERO",
    province: "VR"
  },
  {
    code: "3186",
    value: "B709",
    label: "CAPRINO VERONESE",
    province: "VR"
  },
  {
    code: "3187",
    value: "B886",
    label: "CASALEONE",
    province: "VR"
  },
  {
    code: "3188",
    value: "C041",
    label: "CASTAGNARO",
    province: "VR"
  },
  {
    code: "3189",
    value: "C078",
    label: "CASTEL D AZZANO",
    province: "VR"
  },
  {
    code: "3190",
    value: "C225",
    label: "CASTELNUOVO DEL GARDA",
    province: "VR"
  },
  {
    code: "3191",
    value: "C370",
    label: "CAVAION VERONESE",
    province: "VR"
  },
  {
    code: "3192",
    value: "C412",
    label: "CAZZANO DI TRAMIGNA",
    province: "VR"
  },
  {
    code: "3193",
    value: "C498",
    label: "CEREA",
    province: "VR"
  },
  {
    code: "3194",
    value: "C538",
    label: "CERRO VERONESE",
    province: "VR"
  },
  {
    code: "3195",
    value: "C890",
    label: "COLOGNA VENETA",
    province: "VR"
  },
  {
    code: "3196",
    value: "C897",
    label: "COLOGNOLA AI COLLI",
    province: "VR"
  },
  {
    code: "3197",
    value: "C943",
    label: "CONCAMARISE",
    province: "VR"
  },
  {
    code: "3198",
    value: "D118",
    label: "COSTERMANO SUL GARDA",
    province: "VR"
  },
  {
    code: "3199",
    value: "D317",
    label: "DOLCE",
    province: "VR"
  },
  {
    code: "3200",
    value: "D419",
    label: "ERBE",
    province: "VR"
  },
  {
    code: "3201",
    value: "D420",
    label: "ERBEZZO",
    province: "VR"
  },
  {
    code: "3202",
    value: "D549",
    label: "FERRARA DI MONTE BALDO",
    province: "VR"
  },
  {
    code: "3203",
    value: "D818",
    label: "FUMANE",
    province: "VR"
  },
  {
    code: "3204",
    value: "D915",
    label: "GARDA",
    province: "VR"
  },
  {
    code: "3205",
    value: "D957",
    label: "GAZZO VERONESE",
    province: "VR"
  },
  {
    code: "3206",
    value: "E171",
    label: "GREZZANA",
    province: "VR"
  },
  {
    code: "3207",
    value: "E284",
    label: "ILLASI",
    province: "VR"
  },
  {
    code: "3208",
    value: "E349",
    label: "ISOLA DELLA SCALA",
    province: "VR"
  },
  {
    code: "3209",
    value: "E358",
    label: "ISOLA RIZZA",
    province: "VR"
  },
  {
    code: "3210",
    value: "E489",
    label: "LAVAGNO",
    province: "VR"
  },
  {
    code: "3211",
    value: "E502",
    label: "LAZISE",
    province: "VR"
  },
  {
    code: "3212",
    value: "E512",
    label: "LEGNAGO",
    province: "VR"
  },
  {
    code: "3213",
    value: "E848",
    label: "MALCESINE",
    province: "VR"
  },
  {
    code: "3214",
    value: "E911",
    label: "MARANO DI VALPOLICELLA",
    province: "VR"
  },
  {
    code: "3215",
    value: "F172",
    label: "MEZZANE DI SOTTO",
    province: "VR"
  },
  {
    code: "3275",
    value: "A465",
    label: "ASIAGO",
    province: "VI"
  },
  {
    code: "3276",
    value: "A467",
    label: "ASIGLIANO VENETO",
    province: "VI"
  },
  {
    code: "3278",
    value: "A703",
    label: "BASSANO DEL GRAPPA",
    province: "VI"
  },
  {
    code: "3279",
    value: "A954",
    label: "BOLZANO VICENTINO",
    province: "VI"
  },
  {
    code: "3280",
    value: "B132",
    label: "BREGANZE",
    province: "VI"
  },
  {
    code: "3281",
    value: "B143",
    label: "BRENDOLA",
    province: "VI"
  },
  {
    code: "3282",
    value: "B161",
    label: "BRESSANVIDO",
    province: "VI"
  },
  {
    code: "3283",
    value: "B196",
    label: "BROGLIANO",
    province: "VI"
  },
  {
    code: "3284",
    value: "B403",
    label: "CALDOGNO",
    province: "VI"
  },
  {
    code: "3285",
    value: "B433",
    label: "CALTRANO",
    province: "VI"
  },
  {
    code: "3286",
    value: "B441",
    label: "CALVENE",
    province: "VI"
  },
  {
    code: "3287",
    value: "B485",
    label: "CAMISANO VICENTINO",
    province: "VI"
  },
  {
    code: "3288",
    value: "B511",
    label: "CAMPIGLIA DEI BERICI",
    province: "VI"
  },
  {
    code: "3290",
    value: "B835",
    label: "CARRE",
    province: "VI"
  },
  {
    code: "3291",
    value: "B844",
    label: "CARTIGLIANO",
    province: "VI"
  },
  {
    code: "3292",
    value: "C037",
    label: "CASSOLA",
    province: "VI"
  },
  {
    code: "3293",
    value: "C056",
    label: "CASTEGNERO",
    province: "VI"
  },
  {
    code: "3294",
    value: "C119",
    label: "CASTELGOMBERTO",
    province: "VI"
  },
  {
    code: "3295",
    value: "C605",
    label: "CHIAMPO",
    province: "VI"
  },
  {
    code: "3296",
    value: "C650",
    label: "CHIUPPANO",
    province: "VI"
  },
  {
    code: "3298",
    value: "C824",
    label: "COGOLLO DEL CENGIO",
    province: "VI"
  },
  {
    code: "3300",
    value: "D020",
    label: "CORNEDO VICENTINO",
    province: "VI"
  },
  {
    code: "3301",
    value: "D107",
    label: "COSTABISSARA",
    province: "VI"
  },
  {
    code: "3302",
    value: "D136",
    label: "CREAZZO",
    province: "VI"
  },
  {
    code: "3303",
    value: "D156",
    label: "CRESPADORO",
    province: "VI"
  },
  {
    code: "3304",
    value: "D379",
    label: "DUEVILLE",
    province: "VI"
  },
  {
    code: "3305",
    value: "D407",
    label: "ENEGO",
    province: "VI"
  },
  {
    code: "3306",
    value: "D496",
    label: "FARA VICENTINO",
    province: "VI"
  },
  {
    code: "3307",
    value: "D750",
    label: "FOZA",
    province: "VI"
  },
  {
    code: "3308",
    value: "D882",
    label: "GALLIO",
    province: "VI"
  },
  {
    code: "3309",
    value: "D897",
    label: "GAMBELLARA",
    province: "VI"
  },
  {
    code: "3310",
    value: "D902",
    label: "GAMBUGLIANO",
    province: "VI"
  },
  {
    code: "3312",
    value: "E184",
    label: "GRISIGNANO DI ZOCCO",
    province: "VI"
  },
  {
    code: "3313",
    value: "E226",
    label: "GRUMOLO DELLE ABBADESSE",
    province: "VI"
  },
  {
    code: "3314",
    value: "E354",
    label: "ISOLA VICENTINA",
    province: "VI"
  },
  {
    code: "3315",
    value: "E403",
    label: "LAGHI",
    province: "VI"
  },
  {
    code: "3316",
    value: "E465",
    label: "LASTEBASSE",
    province: "VI"
  },
  {
    code: "3317",
    value: "E671",
    label: "LONGARE",
    province: "VI"
  },
  {
    code: "3318",
    value: "E682",
    label: "LONIGO",
    province: "VI"
  },
  {
    code: "3319",
    value: "E731",
    label: "LUGO DI VICENZA",
    province: "VI"
  },
  {
    code: "3321",
    value: "E864",
    label: "MALO",
    province: "VI"
  },
  {
    code: "3322",
    value: "E912",
    label: "MARANO VICENTINO",
    province: "VI"
  },
  {
    code: "3323",
    value: "E970",
    label: "MAROSTICA",
    province: "VI"
  },
  {
    code: "3326",
    value: "F442",
    label: "MONTEBELLO VICENTINO",
    province: "VI"
  },
  {
    code: "3327",
    value: "F464",
    label: "MONTECCHIO MAGGIORE",
    province: "VI"
  },
  {
    code: "3328",
    value: "F465",
    label: "MONTECCHIO PRECALCINO",
    province: "VI"
  },
  {
    code: "3329",
    value: "F486",
    label: "MONTE DI MALO",
    province: "VI"
  },
  {
    code: "3330",
    value: "F514",
    label: "MONTEGALDA",
    province: "VI"
  },
  {
    code: "3331",
    value: "F515",
    label: "MONTEGALDELLA",
    province: "VI"
  },
  {
    code: "3332",
    value: "F662",
    label: "MONTEVIALE",
    province: "VI"
  },
  {
    code: "3333",
    value: "F675",
    label: "MONTICELLO CONTE OTTO",
    province: "VI"
  },
  {
    code: "3334",
    value: "F696",
    label: "MONTORSO VICENTINO",
    province: "VI"
  },
  {
    code: "3336",
    value: "F829",
    label: "MUSSOLENTE",
    province: "VI"
  },
  {
    code: "3337",
    value: "F838",
    label: "NANTO",
    province: "VI"
  },
  {
    code: "3338",
    value: "F922",
    label: "NOGAROLE VICENTINO",
    province: "VI"
  },
  {
    code: "3339",
    value: "F957",
    label: "NOVE",
    province: "VI"
  },
  {
    code: "3340",
    value: "F964",
    label: "NOVENTA VICENTINA",
    province: "VI"
  },
  {
    code: "3341",
    value: "G095",
    label: "ORGIANO",
    province: "VI"
  },
  {
    code: "3342",
    value: "G406",
    label: "PEDEMONTE",
    province: "VI"
  },
  {
    code: "3343",
    value: "G560",
    label: "PIANEZZE",
    province: "VI"
  },
  {
    code: "3344",
    value: "G694",
    label: "PIOVENE ROCCHETTE",
    province: "VI"
  },
  {
    code: "3345",
    value: "G776",
    label: "POJANA MAGGIORE",
    province: "VI"
  },
  {
    code: "3346",
    value: "G931",
    label: "POSINA",
    province: "VI"
  },
  {
    code: "3347",
    value: "G943",
    label: "POVE DEL GRAPPA",
    province: "VI"
  },
  {
    code: "3348",
    value: "G957",
    label: "POZZOLEONE",
    province: "VI"
  },
  {
    code: "3349",
    value: "H134",
    label: "QUINTO VICENTINO",
    province: "VI"
  },
  {
    code: "3350",
    value: "H214",
    label: "RECOARO TERME",
    province: "VI"
  },
  {
    code: "3351",
    value: "H361",
    label: "ROANA",
    province: "VI"
  },
  {
    code: "3352",
    value: "H512",
    label: "ROMANO D EZZELINO",
    province: "VI"
  },
  {
    code: "3353",
    value: "H556",
    label: "ROSA",
    province: "VI"
  },
  {
    code: "3354",
    value: "H580",
    label: "ROSSANO VENETO",
    province: "VI"
  },
  {
    code: "3355",
    value: "H594",
    label: "ROTZO",
    province: "VI"
  },
  {
    code: "3356",
    value: "F810",
    label: "SALCEDO",
    province: "VI"
  },
  {
    code: "3357",
    value: "H829",
    label: "SANDRIGO",
    province: "VI"
  },
  {
    code: "3360",
    value: "I117",
    label: "SAN PIETRO MUSSOLINO",
    province: "VI"
  },
  {
    code: "3361",
    value: "I353",
    label: "SANTORSO",
    province: "VI"
  },
  {
    code: "3362",
    value: "I401",
    label: "SAN VITO DI LEGUZZANO",
    province: "VI"
  },
  {
    code: "3363",
    value: "I425",
    label: "SARCEDO",
    province: "VI"
  },
  {
    code: "3364",
    value: "I430",
    label: "SAREGO",
    province: "VI"
  },
  {
    code: "3365",
    value: "I527",
    label: "SCHIAVON",
    province: "VI"
  },
  {
    code: "3366",
    value: "I531",
    label: "SCHIO",
    province: "VI"
  },
  {
    code: "3367",
    value: "I783",
    label: "SOLAGNA",
    province: "VI"
  },
  {
    code: "3368",
    value: "I867",
    label: "SOSSANO",
    province: "VI"
  },
  {
    code: "3369",
    value: "I879",
    label: "SOVIZZO",
    province: "VI"
  },
  {
    code: "3370",
    value: "L156",
    label: "TEZZE SUL BRENTA",
    province: "VI"
  },
  {
    code: "3371",
    value: "L157",
    label: "THIENE",
    province: "VI"
  },
  {
    code: "3372",
    value: "D717",
    label: "TONEZZA DEL CIMONE",
    province: "VI"
  },
  {
    code: "3373",
    value: "L248",
    label: "TORREBELVICINO",
    province: "VI"
  },
  {
    code: "3374",
    value: "L297",
    label: "TORRI DI QUARTESOLO",
    province: "VI"
  },
  {
    code: "3375",
    value: "L433",
    label: "TRISSINO",
    province: "VI"
  },
  {
    code: "3376",
    value: "L551",
    label: "VALDAGNO",
    province: "VI"
  },
  {
    code: "3377",
    value: "L554",
    label: "VALDASTICO",
    province: "VI"
  },
  {
    code: "3378",
    value: "L624",
    label: "VALLI DEL PASUBIO",
    province: "VI"
  },
  {
    code: "3380",
    value: "L723",
    label: "VELO D ASTICO",
    province: "VI"
  },
  {
    code: "3381",
    value: "L840",
    label: "VICENZA",
    province: "VI"
  },
  {
    code: "3382",
    value: "L952",
    label: "VILLAGA",
    province: "VI"
  },
  {
    code: "3383",
    value: "M032",
    label: "VILLAVERLA",
    province: "VI"
  },
  {
    code: "3384",
    value: "M145",
    label: "ZANE",
    province: "VI"
  },
  {
    code: "3385",
    value: "M170",
    label: "ZERMEGHEDO",
    province: "VI"
  },
  {
    code: "3386",
    value: "M194",
    label: "ZOVENCEDO",
    province: "VI"
  },
  {
    code: "3387",
    value: "M199",
    label: "ZUGLIANO",
    province: "VI"
  },
  {
    code: "3388",
    value: "A083",
    label: "AGORDO",
    province: "BL"
  },
  {
    code: "3389",
    value: "A121",
    label: "ALANO DI PIAVE",
    province: "BL"
  },
  {
    code: "3390",
    value: "A206",
    label: "ALLEGHE",
    province: "BL"
  },
  {
    code: "3391",
    value: "A443",
    label: "ARSIE",
    province: "BL"
  },
  {
    code: "3392",
    value: "A501",
    label: "AURONZO DI CADORE",
    province: "BL"
  },
  {
    code: "3393",
    value: "A757",
    label: "BELLUNO",
    province: "BL"
  },
  {
    code: "3394",
    value: "A982",
    label: "BORCA DI CADORE",
    province: "BL"
  },
  {
    code: "3395",
    value: "B375",
    label: "CALALZO DI CADORE",
    province: "BL"
  },
  {
    code: "3397",
    value: "C458",
    label: "CENCENIGHE AGORDINO",
    province: "BL"
  },
  {
    code: "3398",
    value: "C577",
    label: "CESIOMAGGIORE",
    province: "BL"
  },
  {
    code: "3399",
    value: "C630",
    label: "CHIES D ALPAGO",
    province: "BL"
  },
  {
    code: "3400",
    value: "C672",
    label: "CIBIANA DI CADORE",
    province: "BL"
  },
  {
    code: "3401",
    value: "C872",
    label: "COLLE SANTA LUCIA",
    province: "BL"
  },
  {
    code: "3402",
    value: "C920",
    label: "COMELICO SUPERIORE",
    province: "BL"
  },
  {
    code: "3403",
    value: "A266",
    label: "CORTINA D AMPEZZO",
    province: "BL"
  },
  {
    code: "3404",
    value: "D247",
    label: "DANTA DI CADORE",
    province: "BL"
  },
  {
    code: "3405",
    value: "D330",
    label: "DOMEGGE DI CADORE",
    province: "BL"
  },
  {
    code: "3406",
    value: "D470",
    label: "FALCADE",
    province: "BL"
  },
  {
    code: "3408",
    value: "D530",
    label: "FELTRE",
    province: "BL"
  },
  {
    code: "3409",
    value: "D686",
    label: "FONZASO",
    province: "BL"
  },
  {
    code: "3410",
    value: "B574",
    label: "CANALE D AGORDO",
    province: "BL"
  },
  {
    code: "3412",
    value: "E113",
    label: "GOSALDO",
    province: "BL"
  },
  {
    code: "3413",
    value: "E429",
    label: "LAMON",
    province: "BL"
  },
  {
    code: "3414",
    value: "E490",
    label: "LA VALLE AGORDINA",
    province: "BL"
  },
  {
    code: "3416",
    value: "E588",
    label: "LIMANA",
    province: "BL"
  },
  {
    code: "3417",
    value: "E622",
    label: "LIVINALLONGO DEL COL DI LANA",
    province: "BL"
  },
  {
    code: "3418",
    value: "M342",
    label: "LONGARONE",
    province: "BL"
  },
  {
    code: "3419",
    value: "E687",
    label: "LORENZAGO DI CADORE",
    province: "BL"
  },
  {
    code: "3420",
    value: "E708",
    label: "LOZZO DI CADORE",
    province: "BL"
  },
  {
    code: "3422",
    value: "G169",
    label: "OSPITALE DI CADORE",
    province: "BL"
  },
  {
    code: "3423",
    value: "G404",
    label: "PEDAVENA",
    province: "BL"
  },
  {
    code: "3424",
    value: "G442",
    label: "PERAROLO DI CADORE",
    province: "BL"
  },
  {
    code: "3426",
    value: "G642",
    label: "PIEVE DI CADORE",
    province: "BL"
  },
  {
    code: "3427",
    value: "B662",
    label: "PONTE NELLE ALPI",
    province: "BL"
  },
  {
    code: "3430",
    value: "H327",
    label: "RIVAMONTE AGORDINO",
    province: "BL"
  },
  {
    code: "3431",
    value: "H379",
    label: "ROCCA PIETORE",
    province: "BL"
  },
  {
    code: "3432",
    value: "H938",
    label: "SAN GREGORIO NELLE ALPI",
    province: "BL"
  },
  {
    code: "3433",
    value: "I063",
    label: "SAN NICOLO DI COMELICO",
    province: "BL"
  },
  {
    code: "3434",
    value: "I088",
    label: "SAN PIETRO DI CADORE",
    province: "BL"
  },
  {
    code: "3435",
    value: "I206",
    label: "SANTA GIUSTINA",
    province: "BL"
  },
  {
    code: "3436",
    value: "I347",
    label: "SAN TOMASO AGORDINO",
    province: "BL"
  },
  {
    code: "3437",
    value: "C919",
    label: "SANTO STEFANO DI CADORE",
    province: "BL"
  },
  {
    code: "3438",
    value: "I392",
    label: "SAN VITO DI CADORE",
    province: "BL"
  },
  {
    code: "3439",
    value: "I421",
    label: "SAPPADA",
    province: "UD"
  },
  {
    code: "3440",
    value: "I563",
    label: "SEDICO",
    province: "BL"
  },
  {
    code: "3441",
    value: "I592",
    label: "SELVA DI CADORE",
    province: "BL"
  },
  {
    code: "3442",
    value: "I626",
    label: "SEREN DEL GRAPPA",
    province: "BL"
  },
  {
    code: "3443",
    value: "I866",
    label: "SOSPIROLO",
    province: "BL"
  },
  {
    code: "3444",
    value: "I876",
    label: "SOVERZENE",
    province: "BL"
  },
  {
    code: "3445",
    value: "I673",
    label: "SOVRAMONTE",
    province: "BL"
  },
  {
    code: "3446",
    value: "L030",
    label: "TAIBON AGORDINO",
    province: "BL"
  },
  {
    code: "3447",
    value: "L040",
    label: "TAMBRE",
    province: "BL"
  },
  {
    code: "3449",
    value: "L584",
    label: "VALLADA AGORDINA",
    province: "BL"
  },
  {
    code: "3450",
    value: "L590",
    label: "VALLE DI CADORE",
    province: "BL"
  },
  {
    code: "3452",
    value: "L890",
    label: "VIGO DI CADORE",
    province: "BL"
  },
  {
    code: "3453",
    value: "M108",
    label: "VODO CADORE",
    province: "BL"
  },
  {
    code: "3454",
    value: "M124",
    label: "VOLTAGO AGORDINO",
    province: "BL"
  },
  {
    code: "3456",
    value: "M189",
    label: "ZOPPE DI CADORE",
    province: "BL"
  },
  {
    code: "3457",
    value: "A237",
    label: "ALTIVOLE",
    province: "TV"
  },
  {
    code: "3458",
    value: "A360",
    label: "ARCADE",
    province: "TV"
  },
  {
    code: "3479",
    value: "D030",
    label: "CORNUDA",
    province: "TV"
  },
  {
    code: "3481",
    value: "C670",
    label: "CROCETTA DEL MONTELLO",
    province: "TV"
  },
  {
    code: "3482",
    value: "D505",
    label: "FARRA DI SOLIGO",
    province: "TV"
  },
  {
    code: "3483",
    value: "D654",
    label: "FOLLINA",
    province: "TV"
  },
  {
    code: "3484",
    value: "D674",
    label: "FONTANELLE",
    province: "TV"
  },
  {
    code: "3485",
    value: "D680",
    label: "FONTE",
    province: "TV"
  },
  {
    code: "3486",
    value: "D794",
    label: "FREGONA",
    province: "TV"
  },
  {
    code: "3487",
    value: "D854",
    label: "GAIARINE",
    province: "TV"
  },
  {
    code: "3488",
    value: "E021",
    label: "GIAVERA DEL MONTELLO",
    province: "TV"
  },
  {
    code: "3489",
    value: "E071",
    label: "GODEGA DI SANT URBANO",
    province: "TV"
  },
  {
    code: "3490",
    value: "E092",
    label: "GORGO AL MONTICANO",
    province: "TV"
  },
  {
    code: "3491",
    value: "E373",
    label: "ISTRANA",
    province: "TV"
  },
  {
    code: "3492",
    value: "E692",
    label: "LORIA",
    province: "TV"
  },
  {
    code: "3493",
    value: "E893",
    label: "MANSUE",
    province: "TV"
  },
  {
    code: "3494",
    value: "E940",
    label: "MARENO DI PIAVE",
    province: "TV"
  },
  {
    code: "3495",
    value: "F009",
    label: "MASER",
    province: "TV"
  },
  {
    code: "3496",
    value: "F012",
    label: "MASERADA SUL PIAVE",
    province: "TV"
  },
  {
    code: "3497",
    value: "F088",
    label: "MEDUNA DI LIVENZA",
    province: "TV"
  },
  {
    code: "3498",
    value: "F190",
    label: "MIANE",
    province: "TV"
  },
  {
    code: "3499",
    value: "F269",
    label: "MOGLIANO VENETO",
    province: "TV"
  },
  {
    code: "3500",
    value: "F332",
    label: "MONASTIER DI TREVISO",
    province: "TV"
  },
  {
    code: "3501",
    value: "F360",
    label: "MONFUMO",
    province: "TV"
  },
  {
    code: "3502",
    value: "F443",
    label: "MONTEBELLUNA",
    province: "TV"
  },
  {
    code: "3503",
    value: "F725",
    label: "MORGANO",
    province: "TV"
  },
  {
    code: "3504",
    value: "F729",
    label: "MORIAGO DELLA BATTAGLIA",
    province: "TV"
  },
  {
    code: "3505",
    value: "F770",
    label: "MOTTA DI LIVENZA",
    province: "TV"
  },
  {
    code: "3506",
    value: "F872",
    label: "NERVESA DELLA BATTAGLIA",
    province: "TV"
  },
  {
    code: "3507",
    value: "F999",
    label: "ODERZO",
    province: "TV"
  },
  {
    code: "3508",
    value: "G115",
    label: "ORMELLE",
    province: "TV"
  },
  {
    code: "3509",
    value: "G123",
    label: "ORSAGO",
    province: "TV"
  },
  {
    code: "3511",
    value: "G229",
    label: "PAESE",
    province: "TV"
  },
  {
    code: "3512",
    value: "G408",
    label: "PEDEROBBA",
    province: "TV"
  },
  {
    code: "3513",
    value: "G645",
    label: "PIEVE DI SOLIGO",
    province: "TV"
  },
  {
    code: "3514",
    value: "G846",
    label: "PONTE DI PIAVE",
    province: "TV"
  },
  {
    code: "3515",
    value: "G875",
    label: "PONZANO VENETO",
    province: "TV"
  },
  {
    code: "3516",
    value: "G909",
    label: "PORTOBUFFOLE",
    province: "TV"
  },
  {
    code: "3517",
    value: "G933",
    label: "POSSAGNO",
    province: "TV"
  },
  {
    code: "3518",
    value: "G944",
    label: "POVEGLIANO",
    province: "TV"
  },
  {
    code: "3519",
    value: "H022",
    label: "PREGANZIOL",
    province: "TV"
  },
  {
    code: "3520",
    value: "H131",
    label: "QUINTO DI TREVISO",
    province: "TV"
  },
  {
    code: "3521",
    value: "H220",
    label: "REFRONTOLO",
    province: "TV"
  },
  {
    code: "3522",
    value: "H238",
    label: "RESANA",
    province: "TV"
  },
  {
    code: "3523",
    value: "H253",
    label: "REVINE LAGO",
    province: "TV"
  },
  {
    code: "3524",
    value: "H280",
    label: "RIESE PIO X",
    province: "TV"
  },
  {
    code: "3525",
    value: "H523",
    label: "RONCADE",
    province: "TV"
  },
  {
    code: "3526",
    value: "H706",
    label: "SALGAREDA",
    province: "TV"
  },
  {
    code: "3527",
    value: "H781",
    label: "SAN BIAGIO DI CALLALTA",
    province: "TV"
  },
  {
    code: "3528",
    value: "H843",
    label: "SAN FIOR",
    province: "TV"
  },
  {
    code: "3529",
    value: "I103",
    label: "SAN PIETRO DI FELETTO",
    province: "TV"
  },
  {
    code: "3530",
    value: "I124",
    label: "SAN POLO DI PIAVE",
    province: "TV"
  },
  {
    code: "3531",
    value: "I221",
    label: "SANTA LUCIA DI PIAVE",
    province: "TV"
  },
  {
    code: "3532",
    value: "I382",
    label: "SAN VENDEMIANO",
    province: "TV"
  },
  {
    code: "3533",
    value: "I417",
    label: "SAN ZENONE DEGLI EZZELINI",
    province: "TV"
  },
  {
    code: "3534",
    value: "I435",
    label: "SARMEDE",
    province: "TV"
  },
  {
    code: "3535",
    value: "I578",
    label: "SEGUSINO",
    province: "TV"
  },
  {
    code: "3536",
    value: "I635",
    label: "SERNAGLIA DELLA BATTAGLIA",
    province: "TV"
  },
  {
    code: "3537",
    value: "F116",
    label: "SILEA",
    province: "TV"
  },
  {
    code: "3538",
    value: "I927",
    label: "SPRESIANO",
    province: "TV"
  },
  {
    code: "3539",
    value: "L014",
    label: "SUSEGANA",
    province: "TV"
  },
  {
    code: "3540",
    value: "L058",
    label: "TARZO",
    province: "TV"
  },
  {
    code: "3541",
    value: "L402",
    label: "TREVIGNANO",
    province: "TV"
  },
  {
    code: "3542",
    value: "L407",
    label: "TREVISO",
    province: "TV"
  },
  {
    code: "3543",
    value: "L565",
    label: "VALDOBBIADENE",
    province: "TV"
  },
  {
    code: "3544",
    value: "L700",
    label: "VAZZOLA",
    province: "TV"
  },
  {
    code: "3545",
    value: "L706",
    label: "VEDELAGO",
    province: "TV"
  },
  {
    code: "3546",
    value: "L856",
    label: "VIDOR",
    province: "TV"
  },
  {
    code: "3547",
    value: "M048",
    label: "VILLORBA",
    province: "TV"
  },
  {
    code: "3548",
    value: "M089",
    label: "VITTORIO VENETO",
    province: "TV"
  },
  {
    code: "3549",
    value: "M118",
    label: "VOLPAGO DEL MONTELLO",
    province: "TV"
  },
  {
    code: "3550",
    value: "M163",
    label: "ZENSON DI PIAVE",
    province: "TV"
  },
  {
    code: "3551",
    value: "M171",
    label: "ZERO BRANCO",
    province: "TV"
  },
  {
    code: "3552",
    value: "A302",
    label: "ANNONE VENETO",
    province: "VE"
  },
  {
    code: "3553",
    value: "B493",
    label: "CAMPAGNA LUPIA",
    province: "VE"
  },
  {
    code: "3554",
    value: "B546",
    label: "CAMPOLONGO MAGGIORE",
    province: "VE"
  },
  {
    code: "3555",
    value: "B554",
    label: "CAMPONOGARA",
    province: "VE"
  },
  {
    code: "3556",
    value: "B642",
    label: "CAORLE",
    province: "VE"
  },
  {
    code: "3557",
    value: "C383",
    label: "CAVARZERE",
    province: "VE"
  },
  {
    code: "3558",
    value: "C422",
    label: "CEGGIA",
    province: "VE"
  },
  {
    code: "3559",
    value: "C638",
    label: "CHIOGGIA",
    province: "VE"
  },
  {
    code: "3560",
    value: "C714",
    label: "CINTO CAOMAGGIORE",
    province: "VE"
  },
  {
    code: "3561",
    value: "C938",
    label: "CONA",
    province: "VE"
  },
  {
    code: "3562",
    value: "C950",
    label: "CONCORDIA SAGITTARIA",
    province: "VE"
  },
  {
    code: "3563",
    value: "D325",
    label: "DOLO",
    province: "VE"
  },
  {
    code: "3564",
    value: "D415",
    label: "ERACLEA",
    province: "VE"
  },
  {
    code: "3565",
    value: "D578",
    label: "FIESSO D ARTICO",
    province: "VE"
  },
  {
    code: "3566",
    value: "D740",
    label: "FOSSALTA DI PIAVE",
    province: "VE"
  },
  {
    code: "3567",
    value: "D741",
    label: "FOSSALTA DI PORTOGRUARO",
    province: "VE"
  },
  {
    code: "3568",
    value: "D748",
    label: "FOSSO",
    province: "VE"
  },
  {
    code: "3569",
    value: "E215",
    label: "GRUARO",
    province: "VE"
  },
  {
    code: "3570",
    value: "C388",
    label: "JESOLO",
    province: "VE"
  },
  {
    code: "3571",
    value: "E936",
    label: "MARCON",
    province: "VE"
  },
  {
    code: "3572",
    value: "E980",
    label: "MARTELLAGO",
    province: "VE"
  },
  {
    code: "3573",
    value: "F130",
    label: "MEOLO",
    province: "VE"
  },
  {
    code: "3574",
    value: "F229",
    label: "MIRA",
    province: "VE"
  },
  {
    code: "3575",
    value: "F241",
    label: "MIRANO",
    province: "VE"
  },
  {
    code: "3576",
    value: "F826",
    label: "MUSILE DI PIAVE",
    province: "VE"
  },
  {
    code: "3577",
    value: "F904",
    label: "NOALE",
    province: "VE"
  },
  {
    code: "3578",
    value: "F963",
    label: "NOVENTA DI PIAVE",
    province: "VE"
  },
  {
    code: "3579",
    value: "G565",
    label: "PIANIGA",
    province: "VE"
  },
  {
    code: "3580",
    value: "G914",
    label: "PORTOGRUARO",
    province: "VE"
  },
  {
    code: "3581",
    value: "G981",
    label: "PRAMAGGIORE",
    province: "VE"
  },
  {
    code: "3582",
    value: "H117",
    label: "QUARTO D ALTINO",
    province: "VE"
  },
  {
    code: "3583",
    value: "H735",
    label: "SALZANO",
    province: "VE"
  },
  {
    code: "3584",
    value: "H823",
    label: "SAN DONA DI PIAVE",
    province: "VE"
  },
  {
    code: "3585",
    value: "I040",
    label: "SAN MICHELE AL TAGLIAMENTO",
    province: "VE"
  },
  {
    code: "3586",
    value: "I242",
    label: "SANTA MARIA DI SALA",
    province: "VE"
  },
  {
    code: "3587",
    value: "I373",
    label: "SAN STINO DI LIVENZA",
    province: "VE"
  },
  {
    code: "3588",
    value: "I551",
    label: "SCORZE",
    province: "VE"
  },
  {
    code: "3589",
    value: "I908",
    label: "SPINEA",
    province: "VE"
  },
  {
    code: "3590",
    value: "I965",
    label: "STRA",
    province: "VE"
  },
  {
    code: "3591",
    value: "L085",
    label: "TEGLIO VENETO",
    province: "VE"
  },
  {
    code: "3592",
    value: "L267",
    label: "TORRE DI MOSTO",
    province: "VE"
  },
  {
    code: "3593",
    value: "L736",
    label: "VENEZIA",
    province: "VE"
  },
  {
    code: "3594",
    value: "L899",
    label: "VIGONOVO",
    province: "VE"
  },
  {
    code: "3595",
    value: "A001",
    label: "ABANO TERME",
    province: "PD"
  },
  {
    code: "3596",
    value: "A075",
    label: "AGNA",
    province: "PD"
  },
  {
    code: "3597",
    value: "A161",
    label: "ALBIGNASEGO",
    province: "PD"
  },
  {
    code: "3598",
    value: "A296",
    label: "ANGUILLARA VENETA",
    province: "PD"
  },
  {
    code: "3599",
    value: "A434",
    label: "ARQUA PETRARCA",
    province: "PD"
  },
  {
    code: "3600",
    value: "A438",
    label: "ARRE",
    province: "PD"
  },
  {
    code: "3601",
    value: "A458",
    label: "ARZERGRANDE",
    province: "PD"
  },
  {
    code: "3602",
    value: "A568",
    label: "BAGNOLI DI SOPRA",
    province: "PD"
  },
  {
    code: "3603",
    value: "A613",
    label: "BAONE",
    province: "PD"
  },
  {
    code: "3604",
    value: "A637",
    label: "BARBONA",
    province: "PD"
  },
  {
    code: "3605",
    value: "A714",
    label: "BATTAGLIA TERME",
    province: "PD"
  },
  {
    code: "3606",
    value: "A906",
    label: "BOARA PISANI",
    province: "PD"
  },
  {
    code: "3607",
    value: "B031",
    label: "BORGORICCO",
    province: "PD"
  },
  {
    code: "3608",
    value: "B106",
    label: "BOVOLENTA",
    province: "PD"
  },
  {
    code: "3609",
    value: "B213",
    label: "BRUGINE",
    province: "PD"
  },
  {
    code: "3610",
    value: "B345",
    label: "CADONEGHE",
    province: "PD"
  },
  {
    code: "3611",
    value: "B524",
    label: "CAMPODARSEGO",
    province: "PD"
  },
  {
    code: "3612",
    value: "B531",
    label: "CAMPODORO",
    province: "PD"
  },
  {
    code: "3613",
    value: "B563",
    label: "CAMPOSAMPIERO",
    province: "PD"
  },
  {
    code: "3614",
    value: "B564",
    label: "CAMPO SAN MARTINO",
    province: "PD"
  },
  {
    code: "3615",
    value: "B589",
    label: "CANDIANA",
    province: "PD"
  },
  {
    code: "3616",
    value: "B749",
    label: "CARCERI",
    province: "PD"
  },
  {
    code: "3617",
    value: "B795",
    label: "CARMIGNANO DI BRENTA",
    province: "PD"
  },
  {
    code: "3618",
    value: "B848",
    label: "CARTURA",
    province: "PD"
  },
  {
    code: "3619",
    value: "B877",
    label: "CASALE DI SCODOSIA",
    province: "PD"
  },
  {
    code: "3620",
    value: "B912",
    label: "CASALSERUGO",
    province: "PD"
  },
  {
    code: "3621",
    value: "C057",
    label: "CASTELBALDO",
    province: "PD"
  },
  {
    code: "3622",
    value: "C544",
    label: "CERVARESE SANTA CROCE",
    province: "PD"
  },
  {
    code: "3623",
    value: "C713",
    label: "CINTO EUGANEO",
    province: "PD"
  },
  {
    code: "3624",
    value: "C743",
    label: "CITTADELLA",
    province: "PD"
  },
  {
    code: "3625",
    value: "C812",
    label: "CODEVIGO",
    province: "PD"
  },
  {
    code: "3626",
    value: "C964",
    label: "CONSELVE",
    province: "PD"
  },
  {
    code: "3627",
    value: "D040",
    label: "CORREZZOLA",
    province: "PD"
  },
  {
    code: "3628",
    value: "D226",
    label: "CURTAROLO",
    province: "PD"
  },
  {
    code: "3629",
    value: "D442",
    label: "ESTE",
    province: "PD"
  },
  {
    code: "3630",
    value: "D679",
    label: "FONTANIVA",
    province: "PD"
  },
  {
    code: "3631",
    value: "D879",
    label: "GALLIERA VENETA",
    province: "PD"
  },
  {
    code: "3632",
    value: "D889",
    label: "GALZIGNANO TERME",
    province: "PD"
  },
  {
    code: "3633",
    value: "D956",
    label: "GAZZO",
    province: "PD"
  },
  {
    code: "3634",
    value: "E145",
    label: "GRANTORTO",
    province: "PD"
  },
  {
    code: "3635",
    value: "E146",
    label: "GRANZE",
    province: "PD"
  },
  {
    code: "3636",
    value: "E515",
    label: "LEGNARO",
    province: "PD"
  },
  {
    code: "3637",
    value: "E592",
    label: "LIMENA",
    province: "PD"
  },
  {
    code: "3638",
    value: "E684",
    label: "LOREGGIA",
    province: "PD"
  },
  {
    code: "3639",
    value: "E709",
    label: "LOZZO ATESTINO",
    province: "PD"
  },
  {
    code: "3640",
    value: "F011",
    label: "MASERA DI PADOVA",
    province: "PD"
  },
  {
    code: "3641",
    value: "F013",
    label: "MASI",
    province: "PD"
  },
  {
    code: "3642",
    value: "F033",
    label: "MASSANZAGO",
    province: "PD"
  },
  {
    code: "3644",
    value: "F092",
    label: "MEGLIADINO SAN VITALE",
    province: "PD"
  },
  {
    code: "3645",
    value: "F148",
    label: "MERLARA",
    province: "PD"
  },
  {
    code: "3646",
    value: "F161",
    label: "MESTRINO",
    province: "PD"
  },
  {
    code: "3647",
    value: "F382",
    label: "MONSELICE",
    province: "PD"
  },
  {
    code: "3648",
    value: "F394",
    label: "MONTAGNANA",
    province: "PD"
  },
  {
    code: "3649",
    value: "F529",
    label: "MONTEGROTTO TERME",
    province: "PD"
  },
  {
    code: "3650",
    value: "F962",
    label: "NOVENTA PADOVANA",
    province: "PD"
  },
  {
    code: "3651",
    value: "G167",
    label: "OSPEDALETTO EUGANEO",
    province: "PD"
  },
  {
    code: "3652",
    value: "G224",
    label: "PADOVA",
    province: "PD"
  },
  {
    code: "3653",
    value: "G461",
    label: "PERNUMIA",
    province: "PD"
  },
  {
    code: "3654",
    value: "G534",
    label: "PIACENZA D ADIGE",
    province: "PD"
  },
  {
    code: "3655",
    value: "G587",
    label: "PIAZZOLA SUL BRENTA",
    province: "PD"
  },
  {
    code: "3656",
    value: "G688",
    label: "PIOMBINO DESE",
    province: "PD"
  },
  {
    code: "3657",
    value: "G693",
    label: "PIOVE DI SACCO",
    province: "PD"
  },
  {
    code: "3658",
    value: "G802",
    label: "POLVERARA",
    province: "PD"
  },
  {
    code: "3659",
    value: "G823",
    label: "PONSO",
    province: "PD"
  },
  {
    code: "3660",
    value: "G850",
    label: "PONTELONGO",
    province: "PD"
  },
  {
    code: "3661",
    value: "G855",
    label: "PONTE SAN NICOLO",
    province: "PD"
  },
  {
    code: "3662",
    value: "G963",
    label: "POZZONOVO",
    province: "PD"
  },
  {
    code: "3663",
    value: "H622",
    label: "ROVOLON",
    province: "PD"
  },
  {
    code: "3664",
    value: "H625",
    label: "RUBANO",
    province: "PD"
  },
  {
    code: "3665",
    value: "H655",
    label: "SACCOLONGO",
    province: "PD"
  },
  {
    code: "3667",
    value: "H893",
    label: "SAN GIORGIO DELLE PERTICHE",
    province: "PD"
  },
  {
    code: "3668",
    value: "H897",
    label: "SAN GIORGIO IN BOSCO",
    province: "PD"
  },
  {
    code: "3669",
    value: "I008",
    label: "SAN MARTINO DI LUPARI",
    province: "PD"
  },
  {
    code: "3670",
    value: "I107",
    label: "SAN PIETRO IN GU",
    province: "PD"
  },
  {
    code: "3671",
    value: "I120",
    label: "SAN PIETRO VIMINARIO",
    province: "PD"
  },
  {
    code: "3672",
    value: "I207",
    label: "SANTA GIUSTINA IN COLLE",
    province: "PD"
  },
  {
    code: "3674",
    value: "I275",
    label: "SANT ANGELO DI PIOVE DI SACCO",
    province: "PD"
  },
  {
    code: "3675",
    value: "I319",
    label: "SANT ELENA",
    province: "PD"
  },
  {
    code: "3676",
    value: "I375",
    label: "SANT URBANO",
    province: "PD"
  },
  {
    code: "3677",
    value: "I418",
    label: "SAONARA",
    province: "PD"
  },
  {
    code: "3678",
    value: "I595",
    label: "SELVAZZANO DENTRO",
    province: "PD"
  },
  {
    code: "3679",
    value: "I799",
    label: "SOLESINO",
    province: "PD"
  },
  {
    code: "3680",
    value: "I938",
    label: "STANGHELLA",
    province: "PD"
  },
  {
    code: "3681",
    value: "L100",
    label: "TEOLO",
    province: "PD"
  },
  {
    code: "3682",
    value: "L132",
    label: "TERRASSA PADOVANA",
    province: "PD"
  },
  {
    code: "3683",
    value: "L199",
    label: "TOMBOLO",
    province: "PD"
  },
  {
    code: "3684",
    value: "L270",
    label: "TORREGLIA",
    province: "PD"
  },
  {
    code: "3685",
    value: "L349",
    label: "TREBASELEGHE",
    province: "PD"
  },
  {
    code: "3686",
    value: "L414",
    label: "TRIBANO",
    province: "PD"
  },
  {
    code: "3687",
    value: "L497",
    label: "URBANA",
    province: "PD"
  },
  {
    code: "3688",
    value: "L710",
    label: "VEGGIANO",
    province: "PD"
  },
  {
    code: "3689",
    value: "L805",
    label: "VESCOVANA",
    province: "PD"
  },
  {
    code: "3690",
    value: "L878",
    label: "VIGHIZZOLO D ESTE",
    province: "PD"
  },
  {
    code: "3691",
    value: "L892",
    label: "VIGODARZERE",
    province: "PD"
  },
  {
    code: "3692",
    value: "L900",
    label: "VIGONZA",
    province: "PD"
  },
  {
    code: "3693",
    value: "L934",
    label: "VILLA DEL CONTE",
    province: "PD"
  },
  {
    code: "3694",
    value: "L937",
    label: "VILLA ESTENSE",
    province: "PD"
  },
  {
    code: "3695",
    value: "L947",
    label: "VILLAFRANCA PADOVANA",
    province: "PD"
  },
  {
    code: "3696",
    value: "L979",
    label: "VILLANOVA DI CAMPOSAMPIERO",
    province: "PD"
  },
  {
    code: "3697",
    value: "M103",
    label: "VO",
    province: "PD"
  },
  {
    code: "3698",
    value: "M300",
    label: "DUE CARRARE",
    province: "PD"
  },
  {
    code: "3699",
    value: "A059",
    label: "ADRIA",
    province: "RO"
  },
  {
    code: "3700",
    value: "A400",
    label: "ARIANO NEL POLESINE",
    province: "RO"
  },
  {
    code: "3701",
    value: "A435",
    label: "ARQUA POLESINE",
    province: "RO"
  },
  {
    code: "3702",
    value: "A539",
    label: "BADIA POLESINE",
    province: "RO"
  },
  {
    code: "3703",
    value: "A574",
    label: "BAGNOLO DI PO",
    province: "RO"
  },
  {
    code: "3704",
    value: "A795",
    label: "BERGANTINO",
    province: "RO"
  },
  {
    code: "3705",
    value: "B069",
    label: "BOSARO",
    province: "RO"
  },
  {
    code: "3706",
    value: "B432",
    label: "CALTO",
    province: "RO"
  },
  {
    code: "3707",
    value: "B578",
    label: "CANARO",
    province: "RO"
  },
  {
    code: "3708",
    value: "B582",
    label: "CANDA",
    province: "RO"
  },
  {
    code: "3709",
    value: "C122",
    label: "CASTELGUGLIELMO",
    province: "RO"
  },
  {
    code: "3710",
    value: "C207",
    label: "CASTELMASSA",
    province: "RO"
  },
  {
    code: "3711",
    value: "C215",
    label: "CASTELNOVO BARIANO",
    province: "RO"
  },
  {
    code: "3712",
    value: "C461",
    label: "CENESELLI",
    province: "RO"
  },
  {
    code: "3713",
    value: "C500",
    label: "CEREGNANO",
    province: "RO"
  },
  {
    code: "3714",
    value: "C987",
    label: "CORBOLA",
    province: "RO"
  },
  {
    code: "3715",
    value: "D105",
    label: "COSTA DI ROVIGO",
    province: "RO"
  },
  {
    code: "3716",
    value: "D161",
    label: "CRESPINO",
    province: "RO"
  },
  {
    code: "3717",
    value: "D568",
    label: "FICAROLO",
    province: "RO"
  },
  {
    code: "3718",
    value: "D577",
    label: "FIESSO UMBERTIANO",
    province: "RO"
  },
  {
    code: "3719",
    value: "D776",
    label: "FRASSINELLE POLESINE",
    province: "RO"
  },
  {
    code: "3720",
    value: "D788",
    label: "FRATTA POLESINE",
    province: "RO"
  },
  {
    code: "3721",
    value: "D855",
    label: "GAIBA",
    province: "RO"
  },
  {
    code: "3722",
    value: "D942",
    label: "GAVELLO",
    province: "RO"
  },
  {
    code: "3723",
    value: "E008",
    label: "GIACCIANO CON BARUCHELLA",
    province: "RO"
  },
  {
    code: "3724",
    value: "E240",
    label: "GUARDA VENETA",
    province: "RO"
  },
  {
    code: "3725",
    value: "E522",
    label: "LENDINARA",
    province: "RO"
  },
  {
    code: "3726",
    value: "E689",
    label: "LOREO",
    province: "RO"
  },
  {
    code: "3727",
    value: "E761",
    label: "LUSIA",
    province: "RO"
  },
  {
    code: "3728",
    value: "F095",
    label: "MELARA",
    province: "RO"
  },
  {
    code: "3729",
    value: "F994",
    label: "OCCHIOBELLO",
    province: "RO"
  },
  {
    code: "3730",
    value: "G323",
    label: "PAPOZZE",
    province: "RO"
  },
  {
    code: "3731",
    value: "G525",
    label: "PETTORAZZA GRIMANI",
    province: "RO"
  },
  {
    code: "3732",
    value: "G673",
    label: "PINCARA",
    province: "RO"
  },
  {
    code: "3733",
    value: "G782",
    label: "POLESELLA",
    province: "RO"
  },
  {
    code: "3734",
    value: "G836",
    label: "PONTECCHIO POLESINE",
    province: "RO"
  },
  {
    code: "3735",
    value: "G923",
    label: "PORTO TOLLE",
    province: "RO"
  },
  {
    code: "3736",
    value: "H573",
    label: "ROSOLINA",
    province: "RO"
  },
  {
    code: "3737",
    value: "H620",
    label: "ROVIGO",
    province: "RO"
  },
  {
    code: "3738",
    value: "H689",
    label: "SALARA",
    province: "RO"
  },
  {
    code: "3739",
    value: "H768",
    label: "SAN BELLINO",
    province: "RO"
  },
  {
    code: "3740",
    value: "H996",
    label: "SAN MARTINO DI VENEZZE",
    province: "RO"
  },
  {
    code: "3741",
    value: "I953",
    label: "STIENTA",
    province: "RO"
  },
  {
    code: "3742",
    value: "L026",
    label: "TAGLIO DI PO",
    province: "RO"
  },
  {
    code: "3743",
    value: "L359",
    label: "TRECENTA",
    province: "RO"
  },
  {
    code: "3744",
    value: "L939",
    label: "VILLADOSE",
    province: "RO"
  },
  {
    code: "3745",
    value: "L967",
    label: "VILLAMARZANA",
    province: "RO"
  },
  {
    code: "3746",
    value: "L985",
    label: "VILLANOVA DEL GHEBBO",
    province: "RO"
  },
  {
    code: "3747",
    value: "L988",
    label: "VILLANOVA MARCHESANA",
    province: "RO"
  },
  {
    code: "3748",
    value: "G926",
    label: "PORTO VIRO",
    province: "RO"
  },
  {
    code: "3749",
    value: "A103",
    label: "AIELLO DEL FRIULI",
    province: "UD"
  },
  {
    code: "3750",
    value: "A254",
    label: "AMARO",
    province: "UD"
  },
  {
    code: "3751",
    value: "A267",
    label: "AMPEZZO",
    province: "UD"
  },
  {
    code: "3752",
    value: "A346",
    label: "AQUILEIA",
    province: "UD"
  },
  {
    code: "3753",
    value: "A447",
    label: "ARTA TERME",
    province: "UD"
  },
  {
    code: "3754",
    value: "A448",
    label: "ARTEGNA",
    province: "UD"
  },
  {
    code: "3755",
    value: "A491",
    label: "ATTIMIS",
    province: "UD"
  },
  {
    code: "3756",
    value: "A553",
    label: "BAGNARIA ARSA",
    province: "UD"
  },
  {
    code: "3757",
    value: "A700",
    label: "BASILIANO",
    province: "UD"
  },
  {
    code: "3758",
    value: "A810",
    label: "BERTIOLO",
    province: "UD"
  },
  {
    code: "3759",
    value: "A855",
    label: "BICINICCO",
    province: "UD"
  },
  {
    code: "3760",
    value: "A983",
    label: "BORDANO",
    province: "UD"
  },
  {
    code: "3761",
    value: "B259",
    label: "BUJA",
    province: "UD"
  },
  {
    code: "3762",
    value: "B309",
    label: "BUTTRIO",
    province: "UD"
  },
  {
    code: "3763",
    value: "B483",
    label: "CAMINO AL TAGLIAMENTO",
    province: "UD"
  },
  {
    code: "3764",
    value: "B536",
    label: "CAMPOFORMIDO",
    province: "UD"
  },
  {
    code: "3766",
    value: "B788",
    label: "CARLINO",
    province: "UD"
  },
  {
    code: "3767",
    value: "B994",
    label: "CASSACCO",
    province: "UD"
  },
  {
    code: "3768",
    value: "C327",
    label: "CASTIONS DI STRADA",
    province: "UD"
  },
  {
    code: "3769",
    value: "C389",
    label: "CAVAZZO CARNICO",
    province: "UD"
  },
  {
    code: "3770",
    value: "C494",
    label: "CERCIVENTO",
    province: "UD"
  },
  {
    code: "3771",
    value: "C556",
    label: "CERVIGNANO DEL FRIULI",
    province: "UD"
  },
  {
    code: "3772",
    value: "C641",
    label: "CHIOPRIS VISCONE",
    province: "UD"
  },
  {
    code: "3773",
    value: "C656",
    label: "CHIUSAFORTE",
    province: "UD"
  },
  {
    code: "3774",
    value: "C758",
    label: "CIVIDALE DEL FRIULI",
    province: "UD"
  },
  {
    code: "3775",
    value: "C817",
    label: "CODROIPO",
    province: "UD"
  },
  {
    code: "3776",
    value: "C885",
    label: "COLLOREDO DI MONTE ALBANO",
    province: "UD"
  },
  {
    code: "3777",
    value: "C918",
    label: "COMEGLIANS",
    province: "UD"
  },
  {
    code: "3778",
    value: "D027",
    label: "CORNO DI ROSAZZO",
    province: "UD"
  },
  {
    code: "3779",
    value: "D085",
    label: "COSEANO",
    province: "UD"
  },
  {
    code: "3780",
    value: "D300",
    label: "DIGNANO",
    province: "UD"
  },
  {
    code: "3781",
    value: "D316",
    label: "DOGNA",
    province: "UD"
  },
  {
    code: "3782",
    value: "D366",
    label: "DRENCHIA",
    province: "UD"
  },
  {
    code: "3783",
    value: "D408",
    label: "ENEMONZO",
    province: "UD"
  },
  {
    code: "3784",
    value: "D455",
    label: "FAEDIS",
    province: "UD"
  },
  {
    code: "3785",
    value: "D461",
    label: "FAGAGNA",
    province: "UD"
  },
  {
    code: "3787",
    value: "D630",
    label: "FLAIBANO",
    province: "UD"
  },
  {
    code: "3788",
    value: "D718",
    label: "FORNI AVOLTRI",
    province: "UD"
  },
  {
    code: "3789",
    value: "D719",
    label: "FORNI DI SOPRA",
    province: "UD"
  },
  {
    code: "3790",
    value: "D720",
    label: "FORNI DI SOTTO",
    province: "UD"
  },
  {
    code: "3791",
    value: "D962",
    label: "GEMONA DEL FRIULI",
    province: "UD"
  },
  {
    code: "3792",
    value: "E083",
    label: "GONARS",
    province: "UD"
  },
  {
    code: "3793",
    value: "E179",
    label: "GRIMACCO",
    province: "UD"
  },
  {
    code: "3459",
    value: "A471",
    label: "ASOLO",
    province: "TV"
  },
  {
    code: "3460",
    value: "B061",
    label: "BORSO DEL GRAPPA",
    province: "TV"
  },
  {
    code: "3461",
    value: "B128",
    label: "BREDA DI PIAVE",
    province: "TV"
  },
  {
    code: "3462",
    value: "B349",
    label: "CAERANO DI SAN MARCO",
    province: "TV"
  },
  {
    code: "3463",
    value: "B678",
    label: "CAPPELLA MAGGIORE",
    province: "TV"
  },
  {
    code: "3464",
    value: "B744",
    label: "CARBONERA",
    province: "TV"
  },
  {
    code: "3465",
    value: "B879",
    label: "CASALE SUL SILE",
    province: "TV"
  },
  {
    code: "3466",
    value: "B965",
    label: "CASIER",
    province: "TV"
  },
  {
    code: "3467",
    value: "C073",
    label: "CASTELCUCCO",
    province: "TV"
  },
  {
    code: "3468",
    value: "C111",
    label: "CASTELFRANCO VENETO",
    province: "TV"
  },
  {
    code: "3469",
    value: "C190",
    label: "CASTELLO DI GODEGO",
    province: "TV"
  },
  {
    code: "3470",
    value: "C384",
    label: "CAVASO DEL TOMBA",
    province: "TV"
  },
  {
    code: "3471",
    value: "C580",
    label: "CESSALTO",
    province: "TV"
  },
  {
    code: "3472",
    value: "C614",
    label: "CHIARANO",
    province: "TV"
  },
  {
    code: "3473",
    value: "C689",
    label: "CIMADOLMO",
    province: "TV"
  },
  {
    code: "3474",
    value: "C735",
    label: "CISON DI VALMARINO",
    province: "TV"
  },
  {
    code: "3475",
    value: "C815",
    label: "CODOGNE",
    province: "TV"
  },
  {
    code: "3476",
    value: "C848",
    label: "COLLE UMBERTO",
    province: "TV"
  },
  {
    code: "3477",
    value: "C957",
    label: "CONEGLIANO",
    province: "TV"
  },
  {
    code: "3478",
    value: "C992",
    label: "CORDIGNANO",
    province: "TV"
  },
  {
    code: "3794",
    value: "E473",
    label: "LATISANA",
    province: "UD"
  },
  {
    code: "3795",
    value: "E476",
    label: "LAUCO",
    province: "UD"
  },
  {
    code: "3796",
    value: "E553",
    label: "LESTIZZA",
    province: "UD"
  },
  {
    code: "3797",
    value: "E584",
    label: "LIGNANO SABBIADORO",
    province: "UD"
  },
  {
    code: "3799",
    value: "E760",
    label: "LUSEVERA",
    province: "UD"
  },
  {
    code: "3800",
    value: "E820",
    label: "MAGNANO IN RIVIERA",
    province: "UD"
  },
  {
    code: "3801",
    value: "E833",
    label: "MAJANO",
    province: "UD"
  },
  {
    code: "3802",
    value: "E847",
    label: "MALBORGHETTO VALBRUNA",
    province: "UD"
  },
  {
    code: "3803",
    value: "E899",
    label: "MANZANO",
    province: "UD"
  },
  {
    code: "3804",
    value: "E910",
    label: "MARANO LAGUNARE",
    province: "UD"
  },
  {
    code: "3805",
    value: "E982",
    label: "MARTIGNACCO",
    province: "UD"
  },
  {
    code: "3806",
    value: "F144",
    label: "MERETO DI TOMBA",
    province: "UD"
  },
  {
    code: "3807",
    value: "F266",
    label: "MOGGIO UDINESE",
    province: "UD"
  },
  {
    code: "3808",
    value: "F275",
    label: "MOIMACCO",
    province: "UD"
  },
  {
    code: "3809",
    value: "F574",
    label: "MONTENARS",
    province: "UD"
  },
  {
    code: "3810",
    value: "F756",
    label: "MORTEGLIANO",
    province: "UD"
  },
  {
    code: "3811",
    value: "F760",
    label: "MORUZZO",
    province: "UD"
  },
  {
    code: "3812",
    value: "F832",
    label: "MUZZANA DEL TURGNANO",
    province: "UD"
  },
  {
    code: "3813",
    value: "F898",
    label: "NIMIS",
    province: "UD"
  },
  {
    code: "3814",
    value: "G163",
    label: "OSOPPO",
    province: "UD"
  },
  {
    code: "3815",
    value: "G198",
    label: "OVARO",
    province: "UD"
  },
  {
    code: "3816",
    value: "G238",
    label: "PAGNACCO",
    province: "UD"
  },
  {
    code: "3817",
    value: "G268",
    label: "PALAZZOLO DELLO STELLA",
    province: "UD"
  },
  {
    code: "3818",
    value: "G284",
    label: "PALMANOVA",
    province: "UD"
  },
  {
    code: "3819",
    value: "G300",
    label: "PALUZZA",
    province: "UD"
  },
  {
    code: "3820",
    value: "G352",
    label: "PASIAN DI PRATO",
    province: "UD"
  },
  {
    code: "3821",
    value: "G381",
    label: "PAULARO",
    province: "UD"
  },
  {
    code: "3822",
    value: "G389",
    label: "PAVIA DI UDINE",
    province: "UD"
  },
  {
    code: "3823",
    value: "G743",
    label: "POCENIA",
    province: "UD"
  },
  {
    code: "3824",
    value: "G831",
    label: "PONTEBBA",
    province: "UD"
  },
  {
    code: "3825",
    value: "G891",
    label: "PORPETTO",
    province: "UD"
  },
  {
    code: "3826",
    value: "G949",
    label: "POVOLETTO",
    province: "UD"
  },
  {
    code: "3827",
    value: "G966",
    label: "POZZUOLO DEL FRIULI",
    province: "UD"
  },
  {
    code: "3828",
    value: "G969",
    label: "PRADAMANO",
    province: "UD"
  },
  {
    code: "3829",
    value: "H002",
    label: "PRATO CARNICO",
    province: "UD"
  },
  {
    code: "3830",
    value: "H014",
    label: "PRECENICCO",
    province: "UD"
  },
  {
    code: "3831",
    value: "H029",
    label: "PREMARIACCO",
    province: "UD"
  },
  {
    code: "3832",
    value: "H038",
    label: "PREONE",
    province: "UD"
  },
  {
    code: "3833",
    value: "H040",
    label: "PREPOTTO",
    province: "UD"
  },
  {
    code: "3834",
    value: "H089",
    label: "PULFERO",
    province: "UD"
  },
  {
    code: "3835",
    value: "H161",
    label: "RAGOGNA",
    province: "UD"
  },
  {
    code: "3836",
    value: "H196",
    label: "RAVASCLETTO",
    province: "UD"
  },
  {
    code: "3837",
    value: "H200",
    label: "RAVEO",
    province: "UD"
  },
  {
    code: "3838",
    value: "H206",
    label: "REANA DEL ROJALE",
    province: "UD"
  },
  {
    code: "3839",
    value: "H229",
    label: "REMANZACCO",
    province: "UD"
  },
  {
    code: "3840",
    value: "H242",
    label: "RESIA",
    province: "UD"
  },
  {
    code: "3841",
    value: "H244",
    label: "RESIUTTA",
    province: "UD"
  },
  {
    code: "3842",
    value: "H289",
    label: "RIGOLATO",
    province: "UD"
  },
  {
    code: "3843",
    value: "H347",
    label: "RIVE D ARCANO",
    province: "UD"
  },
  {
    code: "3845",
    value: "H533",
    label: "RONCHIS",
    province: "UD"
  },
  {
    code: "3846",
    value: "H629",
    label: "RUDA",
    province: "UD"
  },
  {
    code: "3847",
    value: "H816",
    label: "SAN DANIELE DEL FRIULI",
    province: "UD"
  },
  {
    code: "3848",
    value: "H895",
    label: "SAN GIORGIO DI NOGARO",
    province: "UD"
  },
  {
    code: "3849",
    value: "H906",
    label: "SAN GIOVANNI AL NATISONE",
    province: "UD"
  },
  {
    code: "3850",
    value: "H951",
    label: "SAN LEONARDO",
    province: "UD"
  },
  {
    code: "3851",
    value: "I092",
    label: "SAN PIETRO AL NATISONE",
    province: "UD"
  },
  {
    code: "3852",
    value: "I248",
    label: "SANTA MARIA LA LONGA",
    province: "UD"
  },
  {
    code: "3853",
    value: "I404",
    label: "SAN VITO AL TORRE",
    province: "UD"
  },
  {
    code: "3854",
    value: "I405",
    label: "SAN VITO DI FAGAGNA",
    province: "UD"
  },
  {
    code: "3855",
    value: "I464",
    label: "SAURIS",
    province: "UD"
  },
  {
    code: "3856",
    value: "I478",
    label: "SAVOGNA",
    province: "UD"
  },
  {
    code: "3857",
    value: "I562",
    label: "SEDEGLIANO",
    province: "UD"
  },
  {
    code: "3858",
    value: "I777",
    label: "SOCCHIEVE",
    province: "UD"
  },
  {
    code: "3859",
    value: "I974",
    label: "STREGNA",
    province: "UD"
  },
  {
    code: "3860",
    value: "L018",
    label: "SUTRIO",
    province: "UD"
  },
  {
    code: "3861",
    value: "G736",
    label: "TAIPANA",
    province: "UD"
  },
  {
    code: "3862",
    value: "L039",
    label: "TALMASSONS",
    province: "UD"
  },
  {
    code: "3864",
    value: "L050",
    label: "TARCENTO",
    province: "UD"
  },
  {
    code: "3865",
    value: "L057",
    label: "TARVISIO",
    province: "UD"
  },
  {
    code: "3866",
    value: "L065",
    label: "TAVAGNACCO",
    province: "UD"
  },
  {
    code: "3868",
    value: "L144",
    label: "TERZO DI AQUILEIA",
    province: "UD"
  },
  {
    code: "3869",
    value: "L195",
    label: "TOLMEZZO",
    province: "UD"
  },
  {
    code: "3870",
    value: "L246",
    label: "TORREANO",
    province: "UD"
  },
  {
    code: "3871",
    value: "L309",
    label: "TORVISCOSA",
    province: "UD"
  },
  {
    code: "3872",
    value: "L335",
    label: "TRASAGHIS",
    province: "UD"
  },
  {
    code: "3874",
    value: "L382",
    label: "TREPPO GRANDE",
    province: "UD"
  },
  {
    code: "3875",
    value: "L421",
    label: "TRICESIMO",
    province: "UD"
  },
  {
    code: "3876",
    value: "L438",
    label: "TRIVIGNANO UDINESE",
    province: "UD"
  },
  {
    code: "3877",
    value: "L483",
    label: "UDINE",
    province: "UD"
  },
  {
    code: "3878",
    value: "L686",
    label: "VARMO",
    province: "UD"
  },
  {
    code: "3879",
    value: "L743",
    label: "VENZONE",
    province: "UD"
  },
  {
    code: "3880",
    value: "L801",
    label: "VERZEGNIS",
    province: "UD"
  },
  {
    code: "3881",
    value: "L909",
    label: "VILLA SANTINA",
    province: "UD"
  },
  {
    code: "3883",
    value: "M073",
    label: "VISCO",
    province: "UD"
  },
  {
    code: "3884",
    value: "M200",
    label: "ZUGLIO",
    province: "UD"
  },
  {
    code: "3885",
    value: "D700",
    label: "FORGARIA NEL FRIULI",
    province: "UD"
  },
  {
    code: "3886",
    value: "B712",
    label: "CAPRIVA DEL FRIULI",
    province: "GO"
  },
  {
    code: "3887",
    value: "D014",
    label: "CORMONS",
    province: "GO"
  },
  {
    code: "3888",
    value: "D312",
    label: "DOBERDO DEL LAGO",
    province: "GO"
  },
  {
    code: "3889",
    value: "D321",
    label: "DOLEGNA DEL COLLIO",
    province: "GO"
  },
  {
    code: "3890",
    value: "D504",
    label: "FARRA D ISONZO",
    province: "GO"
  },
  {
    code: "3891",
    value: "D645",
    label: "FOGLIANO REDIPUGLIA",
    province: "GO"
  },
  {
    code: "3892",
    value: "E098",
    label: "GORIZIA",
    province: "GO"
  },
  {
    code: "3893",
    value: "E124",
    label: "GRADISCA D ISONZO",
    province: "GO"
  },
  {
    code: "3894",
    value: "E125",
    label: "GRADO",
    province: "GO"
  },
  {
    code: "3895",
    value: "E952",
    label: "MARIANO DEL FRIULI",
    province: "GO"
  },
  {
    code: "3896",
    value: "F081",
    label: "MEDEA",
    province: "GO"
  },
  {
    code: "3897",
    value: "F356",
    label: "MONFALCONE",
    province: "GO"
  },
  {
    code: "3898",
    value: "F710",
    label: "MORARO",
    province: "GO"
  },
  {
    code: "3899",
    value: "F767",
    label: "MOSSA",
    province: "GO"
  },
  {
    code: "3900",
    value: "H514",
    label: "ROMANS D ISONZO",
    province: "GO"
  },
  {
    code: "3901",
    value: "H531",
    label: "RONCHI DEI LEGIONARI",
    province: "GO"
  },
  {
    code: "3902",
    value: "H665",
    label: "SAGRADO",
    province: "GO"
  },
  {
    code: "3903",
    value: "H787",
    label: "SAN CANZIAN D ISONZO",
    province: "GO"
  },
  {
    code: "3904",
    value: "H845",
    label: "SAN FLORIANO DEL COLLIO",
    province: "GO"
  },
  {
    code: "3905",
    value: "H964",
    label: "SAN LORENZO ISONTINO",
    province: "GO"
  },
  {
    code: "3906",
    value: "I082",
    label: "SAN PIER D ISONZO",
    province: "GO"
  },
  {
    code: "3907",
    value: "I479",
    label: "SAVOGNA D ISONZO",
    province: "GO"
  },
  {
    code: "3908",
    value: "I939",
    label: "STARANZANO",
    province: "GO"
  },
  {
    code: "3909",
    value: "L474",
    label: "TURRIACO",
    province: "GO"
  },
  {
    code: "3910",
    value: "M043",
    label: "VILLESSE",
    province: "GO"
  },
  {
    code: "3911",
    value: "D383",
    label: "DUINO AURISINA",
    province: "TS"
  },
  {
    code: "3912",
    value: "F378",
    label: "MONRUPINO",
    province: "TS"
  },
  {
    code: "3913",
    value: "F795",
    label: "MUGGIA",
    province: "TS"
  },
  {
    code: "3914",
    value: "D324",
    label: "SAN DORLIGO DELLA VALLE DOLINA",
    province: "TS"
  },
  {
    code: "3915",
    value: "I715",
    label: "SGONICO",
    province: "TS"
  },
  {
    code: "3916",
    value: "L424",
    label: "TRIESTE",
    province: "TS"
  },
  {
    code: "3917",
    value: "A283",
    label: "ANDREIS",
    province: "PN"
  },
  {
    code: "3918",
    value: "A354",
    label: "ARBA",
    province: "PN"
  },
  {
    code: "3920",
    value: "A516",
    label: "AVIANO",
    province: "PN"
  },
  {
    code: "3921",
    value: "A530",
    label: "AZZANO DECIMO",
    province: "PN"
  },
  {
    code: "3922",
    value: "A640",
    label: "BARCIS",
    province: "PN"
  },
  {
    code: "3923",
    value: "B215",
    label: "BRUGNERA",
    province: "PN"
  },
  {
    code: "3924",
    value: "B247",
    label: "BUDOIA",
    province: "PN"
  },
  {
    code: "3925",
    value: "B598",
    label: "CANEVA",
    province: "PN"
  },
  {
    code: "3926",
    value: "B940",
    label: "CASARSA DELLA DELIZIA",
    province: "PN"
  },
  {
    code: "3927",
    value: "C217",
    label: "CASTELNOVO DEL FRIULI",
    province: "PN"
  },
  {
    code: "3928",
    value: "C385",
    label: "CAVASSO NUOVO",
    province: "PN"
  },
  {
    code: "3929",
    value: "C640",
    label: "CHIONS",
    province: "PN"
  },
  {
    code: "3930",
    value: "C699",
    label: "CIMOLAIS",
    province: "PN"
  },
  {
    code: "3931",
    value: "C790",
    label: "CLAUT",
    province: "PN"
  },
  {
    code: "3932",
    value: "C791",
    label: "CLAUZETTO",
    province: "PN"
  },
  {
    code: "3933",
    value: "C991",
    label: "CORDENONS",
    province: "PN"
  },
  {
    code: "3934",
    value: "C993",
    label: "CORDOVADO",
    province: "PN"
  },
  {
    code: "3935",
    value: "D426",
    label: "ERTO E CASSO",
    province: "PN"
  },
  {
    code: "3936",
    value: "D487",
    label: "FANNA",
    province: "PN"
  },
  {
    code: "3937",
    value: "D621",
    label: "FIUME VENETO",
    province: "PN"
  },
  {
    code: "3938",
    value: "D670",
    label: "FONTANAFREDDA",
    province: "PN"
  },
  {
    code: "3939",
    value: "D804",
    label: "FRISANCO",
    province: "PN"
  },
  {
    code: "3940",
    value: "E889",
    label: "MANIAGO",
    province: "PN"
  },
  {
    code: "3941",
    value: "F089",
    label: "MEDUNO",
    province: "PN"
  },
  {
    code: "3942",
    value: "F596",
    label: "MONTEREALE VALCELLINA",
    province: "PN"
  },
  {
    code: "3943",
    value: "F750",
    label: "MORSANO AL TAGLIAMENTO",
    province: "PN"
  },
  {
    code: "3944",
    value: "G353",
    label: "PASIANO DI PORDENONE",
    province: "PN"
  },
  {
    code: "3945",
    value: "G680",
    label: "PINZANO AL TAGLIAMENTO",
    province: "PN"
  },
  {
    code: "4747",
    value: "G139",
    label: "ORTIGNANO RAGGIOLO",
    province: "AR"
  },
  {
    code: "4750",
    value: "G653",
    label: "PIEVE SANTO STEFANO",
    province: "AR"
  },
  {
    code: "4751",
    value: "G879",
    label: "POPPI",
    province: "AR"
  },
  {
    code: "4753",
    value: "H901",
    label: "SAN GIOVANNI VALDARNO",
    province: "AR"
  },
  {
    code: "4754",
    value: "I155",
    label: "SANSEPOLCRO",
    province: "AR"
  },
  {
    code: "4755",
    value: "I681",
    label: "SESTINO",
    province: "AR"
  },
  {
    code: "4757",
    value: "I991",
    label: "SUBBIANO",
    province: "AR"
  },
  {
    code: "4758",
    value: "L038",
    label: "TALLA",
    province: "AR"
  },
  {
    code: "4759",
    value: "L123",
    label: "TERRANUOVA BRACCIOLINI",
    province: "AR"
  },
  {
    code: "4760",
    value: "A006",
    label: "ABBADIA SAN SALVATORE",
    province: "SI"
  },
  {
    code: "4761",
    value: "A461",
    label: "ASCIANO",
    province: "SI"
  },
  {
    code: "4762",
    value: "B269",
    label: "BUONCONVENTO",
    province: "SI"
  },
  {
    code: "4763",
    value: "B984",
    label: "CASOLE D ELSA",
    province: "SI"
  },
  {
    code: "4764",
    value: "C172",
    label: "CASTELLINA IN CHIANTI",
    province: "SI"
  },
  {
    code: "4765",
    value: "C227",
    label: "CASTELNUOVO BERARDENGA",
    province: "SI"
  },
  {
    code: "4766",
    value: "C313",
    label: "CASTIGLIONE D ORCIA",
    province: "SI"
  },
  {
    code: "4767",
    value: "C587",
    label: "CETONA",
    province: "SI"
  },
  {
    code: "4768",
    value: "C608",
    label: "CHIANCIANO TERME",
    province: "SI"
  },
  {
    code: "4769",
    value: "C661",
    label: "CHIUSDINO",
    province: "SI"
  },
  {
    code: "4770",
    value: "C662",
    label: "CHIUSI",
    province: "SI"
  },
  {
    code: "4771",
    value: "C847",
    label: "COLLE DI VAL D ELSA",
    province: "SI"
  },
  {
    code: "4772",
    value: "D858",
    label: "GAIOLE IN CHIANTI",
    province: "SI"
  },
  {
    code: "4773",
    value: "M378",
    label: "MONTALCINO",
    province: "SI"
  },
  {
    code: "4774",
    value: "F592",
    label: "MONTEPULCIANO",
    province: "SI"
  },
  {
    code: "4775",
    value: "F598",
    label: "MONTERIGGIONI",
    province: "SI"
  },
  {
    code: "4776",
    value: "F605",
    label: "MONTERONI D ARBIA",
    province: "SI"
  },
  {
    code: "4777",
    value: "F676",
    label: "MONTICIANO",
    province: "SI"
  },
  {
    code: "4778",
    value: "F815",
    label: "MURLO",
    province: "SI"
  },
  {
    code: "4779",
    value: "G547",
    label: "PIANCASTAGNAIO",
    province: "SI"
  },
  {
    code: "4780",
    value: "G602",
    label: "PIENZA",
    province: "SI"
  },
  {
    code: "4781",
    value: "G752",
    label: "POGGIBONSI",
    province: "SI"
  },
  {
    code: "4782",
    value: "H153",
    label: "RADDA IN CHIANTI",
    province: "SI"
  },
  {
    code: "4783",
    value: "H156",
    label: "RADICOFANI",
    province: "SI"
  },
  {
    code: "4784",
    value: "H157",
    label: "RADICONDOLI",
    province: "SI"
  },
  {
    code: "4785",
    value: "H185",
    label: "RAPOLANO TERME",
    province: "SI"
  },
  {
    code: "4786",
    value: "H790",
    label: "SAN CASCIANO DEI BAGNI",
    province: "SI"
  },
  {
    code: "4787",
    value: "H875",
    label: "SAN GIMIGNANO",
    province: "SI"
  },
  {
    code: "4789",
    value: "I135",
    label: "SAN QUIRICO D ORCIA",
    province: "SI"
  },
  {
    code: "4790",
    value: "I445",
    label: "SARTEANO",
    province: "SI"
  },
  {
    code: "4791",
    value: "I726",
    label: "SIENA",
    province: "SI"
  },
  {
    code: "4792",
    value: "A468",
    label: "SINALUNGA",
    province: "SI"
  },
  {
    code: "4793",
    value: "I877",
    label: "SOVICILLE",
    province: "SI"
  },
  {
    code: "4794",
    value: "L303",
    label: "TORRITA DI SIENA",
    province: "SI"
  },
  {
    code: "4795",
    value: "L384",
    label: "TREQUANDA",
    province: "SI"
  },
  {
    code: "4796",
    value: "A369",
    label: "ARCIDOSSO",
    province: "GR"
  },
  {
    code: "4797",
    value: "B497",
    label: "CAMPAGNATICO",
    province: "GR"
  },
  {
    code: "4798",
    value: "B646",
    label: "CAPALBIO",
    province: "GR"
  },
  {
    code: "4799",
    value: "C085",
    label: "CASTEL DEL PIANO",
    province: "GR"
  },
  {
    code: "4800",
    value: "C147",
    label: "CASTELL AZZARA",
    province: "GR"
  },
  {
    code: "4801",
    value: "C310",
    label: "CASTIGLIONE DELLA PESCAIA",
    province: "GR"
  },
  {
    code: "4802",
    value: "C705",
    label: "CINIGIANO",
    province: "GR"
  },
  {
    code: "4803",
    value: "C782",
    label: "CIVITELLA PAGANICO",
    province: "GR"
  },
  {
    code: "4804",
    value: "D656",
    label: "FOLLONICA",
    province: "GR"
  },
  {
    code: "4805",
    value: "D948",
    label: "GAVORRANO",
    province: "GR"
  },
  {
    code: "4806",
    value: "E202",
    label: "GROSSETO",
    province: "GR"
  },
  {
    code: "4807",
    value: "E348",
    label: "ISOLA DEL GIGLIO",
    province: "GR"
  },
  {
    code: "4808",
    value: "E810",
    label: "MAGLIANO IN TOSCANA",
    province: "GR"
  },
  {
    code: "4809",
    value: "E875",
    label: "MANCIANO",
    province: "GR"
  },
  {
    code: "4810",
    value: "F032",
    label: "MASSA MARITTIMA",
    province: "GR"
  },
  {
    code: "4811",
    value: "F437",
    label: "MONTE ARGENTARIO",
    province: "GR"
  },
  {
    code: "4812",
    value: "F677",
    label: "MONTIERI",
    province: "GR"
  },
  {
    code: "4813",
    value: "G088",
    label: "ORBETELLO",
    province: "GR"
  },
  {
    code: "4814",
    value: "G716",
    label: "PITIGLIANO",
    province: "GR"
  },
  {
    code: "4815",
    value: "H417",
    label: "ROCCALBEGNA",
    province: "GR"
  },
  {
    code: "4816",
    value: "H449",
    label: "ROCCASTRADA",
    province: "GR"
  },
  {
    code: "4817",
    value: "I187",
    label: "SANTA FIORA",
    province: "GR"
  },
  {
    code: "4818",
    value: "I504",
    label: "SCANSANO",
    province: "GR"
  },
  {
    code: "4819",
    value: "I510",
    label: "SCARLINO",
    province: "GR"
  },
  {
    code: "4820",
    value: "I571",
    label: "SEGGIANO",
    province: "GR"
  },
  {
    code: "4821",
    value: "I841",
    label: "SORANO",
    province: "GR"
  },
  {
    code: "4822",
    value: "F612",
    label: "MONTEROTONDO MARITTIMO",
    province: "GR"
  },
  {
    code: "4823",
    value: "I601",
    label: "SEMPRONIANO",
    province: "GR"
  },
  {
    code: "4824",
    value: "B626",
    label: "CANTAGALLO",
    province: "PO"
  },
  {
    code: "4825",
    value: "B794",
    label: "CARMIGNANO",
    province: "PO"
  },
  {
    code: "4826",
    value: "F572",
    label: "MONTEMURLO",
    province: "PO"
  },
  {
    code: "4827",
    value: "G754",
    label: "POGGIO A CAIANO",
    province: "PO"
  },
  {
    code: "4828",
    value: "G999",
    label: "PRATO",
    province: "PO"
  },
  {
    code: "4829",
    value: "L537",
    label: "VAIANO",
    province: "PO"
  },
  {
    code: "4830",
    value: "L775",
    label: "VERNIO",
    province: "PO"
  },
  {
    code: "4831",
    value: "A475",
    label: "ASSISI",
    province: "PG"
  },
  {
    code: "4832",
    value: "A710",
    label: "BASTIA UMBRA",
    province: "PG"
  },
  {
    code: "4833",
    value: "A832",
    label: "BETTONA",
    province: "PG"
  },
  {
    code: "4834",
    value: "A835",
    label: "BEVAGNA",
    province: "PG"
  },
  {
    code: "4835",
    value: "B504",
    label: "CAMPELLO SUL CLITUNNO",
    province: "PG"
  },
  {
    code: "4836",
    value: "B609",
    label: "CANNARA",
    province: "PG"
  },
  {
    code: "4837",
    value: "B948",
    label: "CASCIA",
    province: "PG"
  },
  {
    code: "4838",
    value: "C252",
    label: "CASTEL RITALDI",
    province: "PG"
  },
  {
    code: "4839",
    value: "C309",
    label: "CASTIGLIONE DEL LAGO",
    province: "PG"
  },
  {
    code: "4840",
    value: "C527",
    label: "CERRETO DI SPOLETO",
    province: "PG"
  },
  {
    code: "4841",
    value: "C742",
    label: "CITERNA",
    province: "PG"
  },
  {
    code: "4842",
    value: "C744",
    label: "CITTA DELLA PIEVE",
    province: "PG"
  },
  {
    code: "4843",
    value: "C745",
    label: "CITTA DI CASTELLO",
    province: "PG"
  },
  {
    code: "4844",
    value: "C845",
    label: "COLLAZZONE",
    province: "PG"
  },
  {
    code: "4845",
    value: "C990",
    label: "CORCIANO",
    province: "PG"
  },
  {
    code: "4846",
    value: "D108",
    label: "COSTACCIARO",
    province: "PG"
  },
  {
    code: "4847",
    value: "D279",
    label: "DERUTA",
    province: "PG"
  },
  {
    code: "4848",
    value: "D653",
    label: "FOLIGNO",
    province: "PG"
  },
  {
    code: "4849",
    value: "D745",
    label: "FOSSATO DI VICO",
    province: "PG"
  },
  {
    code: "4850",
    value: "D787",
    label: "FRATTA TODINA",
    province: "PG"
  },
  {
    code: "4851",
    value: "E012",
    label: "GIANO DELL UMBRIA",
    province: "PG"
  },
  {
    code: "4852",
    value: "E229",
    label: "GUALDO CATTANEO",
    province: "PG"
  },
  {
    code: "4853",
    value: "E230",
    label: "GUALDO TADINO",
    province: "PG"
  },
  {
    code: "4854",
    value: "E256",
    label: "GUBBIO",
    province: "PG"
  },
  {
    code: "4855",
    value: "E613",
    label: "LISCIANO NICCONE",
    province: "PG"
  },
  {
    code: "4856",
    value: "E805",
    label: "MAGIONE",
    province: "PG"
  },
  {
    code: "4857",
    value: "E975",
    label: "MARSCIANO",
    province: "PG"
  },
  {
    code: "4858",
    value: "F024",
    label: "MASSA MARTANA",
    province: "PG"
  },
  {
    code: "4859",
    value: "F456",
    label: "MONTE CASTELLO DI VIBIO",
    province: "PG"
  },
  {
    code: "4860",
    value: "F492",
    label: "MONTEFALCO",
    province: "PG"
  },
  {
    code: "4861",
    value: "F540",
    label: "MONTELEONE DI SPOLETO",
    province: "PG"
  },
  {
    code: "4862",
    value: "F629",
    label: "MONTE SANTA MARIA TIBERINA",
    province: "PG"
  },
  {
    code: "4863",
    value: "F685",
    label: "MONTONE",
    province: "PG"
  },
  {
    code: "4864",
    value: "F911",
    label: "NOCERA UMBRA",
    province: "PG"
  },
  {
    code: "4865",
    value: "F935",
    label: "NORCIA",
    province: "PG"
  },
  {
    code: "4866",
    value: "G212",
    label: "PACIANO",
    province: "PG"
  },
  {
    code: "4867",
    value: "G308",
    label: "PANICALE",
    province: "PG"
  },
  {
    code: "4868",
    value: "G359",
    label: "PASSIGNANO SUL TRASIMENO",
    province: "PG"
  },
  {
    code: "4869",
    value: "G478",
    label: "PERUGIA",
    province: "PG"
  },
  {
    code: "4870",
    value: "G601",
    label: "PIEGARO",
    province: "PG"
  },
  {
    code: "4871",
    value: "G618",
    label: "PIETRALUNGA",
    province: "PG"
  },
  {
    code: "4872",
    value: "G758",
    label: "POGGIODOMO",
    province: "PG"
  },
  {
    code: "4873",
    value: "H015",
    label: "PRECI",
    province: "PG"
  },
  {
    code: "4874",
    value: "H935",
    label: "SAN GIUSTINO",
    province: "PG"
  },
  {
    code: "4875",
    value: "I263",
    label: "SANT ANATOLIA DI NARCO",
    province: "PG"
  },
  {
    code: "4876",
    value: "I522",
    label: "SCHEGGIA E PASCELUPO",
    province: "PG"
  },
  {
    code: "4877",
    value: "I523",
    label: "SCHEGGINO",
    province: "PG"
  },
  {
    code: "4878",
    value: "I585",
    label: "SELLANO",
    province: "PG"
  },
  {
    code: "4879",
    value: "I727",
    label: "SIGILLO",
    province: "PG"
  },
  {
    code: "4880",
    value: "I888",
    label: "SPELLO",
    province: "PG"
  },
  {
    code: "4881",
    value: "I921",
    label: "SPOLETO",
    province: "PG"
  },
  {
    code: "4882",
    value: "L188",
    label: "TODI",
    province: "PG"
  },
  {
    code: "4883",
    value: "L216",
    label: "TORGIANO",
    province: "PG"
  },
  {
    code: "4884",
    value: "L397",
    label: "TREVI",
    province: "PG"
  },
  {
    code: "4885",
    value: "L466",
    label: "TUORO SUL TRASIMENO",
    province: "PG"
  },
  {
    code: "4886",
    value: "D786",
    label: "UMBERTIDE",
    province: "PG"
  },
  {
    code: "4887",
    value: "L573",
    label: "VALFABBRICA",
    province: "PG"
  },
  {
    code: "4888",
    value: "L627",
    label: "VALLO DI NERA",
    province: "PG"
  },
  {
    code: "4889",
    value: "L653",
    label: "VALTOPINA",
    province: "PG"
  },
  {
    code: "4890",
    value: "A045",
    label: "ACQUASPARTA",
    province: "TR"
  },
  {
    code: "4891",
    value: "A207",
    label: "ALLERONA",
    province: "TR"
  },
  {
    code: "4892",
    value: "A242",
    label: "ALVIANO",
    province: "TR"
  },
  {
    code: "4893",
    value: "A262",
    label: "AMELIA",
    province: "TR"
  },
  {
    code: "4894",
    value: "A439",
    label: "ARRONE",
    province: "TR"
  },
  {
    code: "4895",
    value: "A490",
    label: "ATTIGLIANO",
    province: "TR"
  },
  {
    code: "4896",
    value: "A691",
    label: "BASCHI",
    province: "TR"
  },
  {
    code: "4897",
    value: "B446",
    label: "CALVI DELL UMBRIA",
    province: "TR"
  },
  {
    code: "4898",
    value: "C117",
    label: "CASTEL GIORGIO",
    province: "TR"
  },
  {
    code: "4899",
    value: "C289",
    label: "CASTEL VISCARDO",
    province: "TR"
  },
  {
    code: "4900",
    value: "D454",
    label: "FABRO",
    province: "TR"
  },
  {
    code: "4901",
    value: "D538",
    label: "FERENTILLO",
    province: "TR"
  },
  {
    code: "4902",
    value: "D570",
    label: "FICULLE",
    province: "TR"
  },
  {
    code: "4903",
    value: "E045",
    label: "GIOVE",
    province: "TR"
  },
  {
    code: "4904",
    value: "E241",
    label: "GUARDEA",
    province: "TR"
  },
  {
    code: "4905",
    value: "E729",
    label: "LUGNANO IN TEVERINA",
    province: "TR"
  },
  {
    code: "4906",
    value: "F457",
    label: "MONTECASTRILLI",
    province: "TR"
  },
  {
    code: "4907",
    value: "F462",
    label: "MONTECCHIO",
    province: "TR"
  },
  {
    code: "4908",
    value: "F510",
    label: "MONTEFRANCO",
    province: "TR"
  },
  {
    code: "4909",
    value: "F513",
    label: "MONTEGABBIONE",
    province: "TR"
  },
  {
    code: "4910",
    value: "F543",
    label: "MONTELEONE D ORVIETO",
    province: "TR"
  },
  {
    code: "4911",
    value: "F844",
    label: "NARNI",
    province: "TR"
  },
  {
    code: "4912",
    value: "G148",
    label: "ORVIETO",
    province: "TR"
  },
  {
    code: "4913",
    value: "G189",
    label: "OTRICOLI",
    province: "TR"
  },
  {
    code: "4914",
    value: "G344",
    label: "PARRANO",
    province: "TR"
  },
  {
    code: "4915",
    value: "G432",
    label: "PENNA IN TEVERINA",
    province: "TR"
  },
  {
    code: "4916",
    value: "G790",
    label: "POLINO",
    province: "TR"
  },
  {
    code: "4917",
    value: "G881",
    label: "PORANO",
    province: "TR"
  },
  {
    code: "4918",
    value: "H857",
    label: "SAN GEMINI",
    province: "TR"
  },
  {
    code: "4919",
    value: "I381",
    label: "SAN VENANZO",
    province: "TR"
  },
  {
    code: "4920",
    value: "I981",
    label: "STRONCONE",
    province: "TR"
  },
  {
    code: "4921",
    value: "L117",
    label: "TERNI",
    province: "TR"
  },
  {
    code: "4922",
    value: "M258",
    label: "AVIGLIANO UMBRO",
    province: "TR"
  },
  {
    code: "4923",
    value: "A035",
    label: "ACQUALAGNA",
    province: "PU"
  },
  {
    code: "4924",
    value: "A327",
    label: "APECCHIO",
    province: "PU"
  },
  {
    code: "4927",
    value: "A740",
    label: "BELFORTE ALL ISAURO",
    province: "PU"
  },
  {
    code: "4928",
    value: "B026",
    label: "BORGO PACE",
    province: "PU"
  },
  {
    code: "4929",
    value: "B352",
    label: "CAGLI",
    province: "PU"
  },
  {
    code: "4930",
    value: "B636",
    label: "CANTIANO",
    province: "PU"
  },
  {
    code: "4931",
    value: "B816",
    label: "CARPEGNA",
    province: "PU"
  },
  {
    code: "4932",
    value: "B846",
    label: "CARTOCETO",
    province: "PU"
  },
  {
    code: "4933",
    value: "C080",
    label: "CASTELDELCI",
    province: "RN"
  },
  {
    code: "4935",
    value: "D488",
    label: "FANO",
    province: "PU"
  },
  {
    code: "4936",
    value: "D541",
    label: "FERMIGNANO",
    province: "PU"
  },
  {
    code: "4937",
    value: "D749",
    label: "FOSSOMBRONE",
    province: "PU"
  },
  {
    code: "4938",
    value: "D791",
    label: "FRATTE ROSA",
    province: "PU"
  },
  {
    code: "4939",
    value: "D807",
    label: "FRONTINO",
    province: "PU"
  },
  {
    code: "3946",
    value: "G780",
    label: "POLCENIGO",
    province: "PN"
  },
  {
    code: "3947",
    value: "G886",
    label: "PORCIA",
    province: "PN"
  },
  {
    code: "3948",
    value: "G888",
    label: "PORDENONE",
    province: "PN"
  },
  {
    code: "3949",
    value: "G994",
    label: "PRATA DI PORDENONE",
    province: "PN"
  },
  {
    code: "3950",
    value: "H010",
    label: "PRAVISDOMINI",
    province: "PN"
  },
  {
    code: "3951",
    value: "H609",
    label: "ROVEREDO IN PIANO",
    province: "PN"
  },
  {
    code: "3952",
    value: "H657",
    label: "SACILE",
    province: "PN"
  },
  {
    code: "3953",
    value: "H891",
    label: "SAN GIORGIO DELLA RICHINVELDA",
    province: "PN"
  },
  {
    code: "3954",
    value: "H999",
    label: "SAN MARTINO AL TAGLIAMENTO",
    province: "PN"
  },
  {
    code: "3955",
    value: "I136",
    label: "SAN QUIRINO",
    province: "PN"
  },
  {
    code: "3956",
    value: "I403",
    label: "SAN VITO AL TAGLIAMENTO",
    province: "PN"
  },
  {
    code: "3957",
    value: "I621",
    label: "SEQUALS",
    province: "PN"
  },
  {
    code: "3958",
    value: "I686",
    label: "SESTO AL REGHENA",
    province: "PN"
  },
  {
    code: "3959",
    value: "I904",
    label: "SPILIMBERGO",
    province: "PN"
  },
  {
    code: "3960",
    value: "L324",
    label: "TRAMONTI DI SOPRA",
    province: "PN"
  },
  {
    code: "3961",
    value: "L325",
    label: "TRAMONTI DI SOTTO",
    province: "PN"
  },
  {
    code: "3962",
    value: "L347",
    label: "TRAVESIO",
    province: "PN"
  },
  {
    code: "3964",
    value: "M085",
    label: "VITO D ASIO",
    province: "PN"
  },
  {
    code: "3965",
    value: "M096",
    label: "VIVARO",
    province: "PN"
  },
  {
    code: "3966",
    value: "M190",
    label: "ZOPPOLA",
    province: "PN"
  },
  {
    code: "3967",
    value: "M265",
    label: "VAJONT",
    province: "PN"
  },
  {
    code: "3968",
    value: "A111",
    label: "AIROLE",
    province: "IM"
  },
  {
    code: "3969",
    value: "A338",
    label: "APRICALE",
    province: "IM"
  },
  {
    code: "3970",
    value: "A344",
    label: "AQUILA D ARROSCIA",
    province: "IM"
  },
  {
    code: "3971",
    value: "A418",
    label: "ARMO",
    province: "IM"
  },
  {
    code: "3972",
    value: "A499",
    label: "AURIGO",
    province: "IM"
  },
  {
    code: "3973",
    value: "A536",
    label: "BADALUCCO",
    province: "IM"
  },
  {
    code: "3974",
    value: "A581",
    label: "BAJARDO",
    province: "IM"
  },
  {
    code: "3975",
    value: "A984",
    label: "BORDIGHERA",
    province: "IM"
  },
  {
    code: "3976",
    value: "A993",
    label: "BORGHETTO D ARROSCIA",
    province: "IM"
  },
  {
    code: "3977",
    value: "B020",
    label: "BORGOMARO",
    province: "IM"
  },
  {
    code: "3978",
    value: "B559",
    label: "CAMPOROSSO",
    province: "IM"
  },
  {
    code: "3979",
    value: "B734",
    label: "CARAVONICA",
    province: "IM"
  },
  {
    code: "3981",
    value: "C143",
    label: "CASTELLARO",
    province: "IM"
  },
  {
    code: "3982",
    value: "C110",
    label: "CASTEL VITTORIO",
    province: "IM"
  },
  {
    code: "3983",
    value: "C511",
    label: "CERIANA",
    province: "IM"
  },
  {
    code: "3984",
    value: "C559",
    label: "CERVO",
    province: "IM"
  },
  {
    code: "3985",
    value: "C578",
    label: "CESIO",
    province: "IM"
  },
  {
    code: "3986",
    value: "C657",
    label: "CHIUSANICO",
    province: "IM"
  },
  {
    code: "3987",
    value: "C660",
    label: "CHIUSAVECCHIA",
    province: "IM"
  },
  {
    code: "3988",
    value: "C718",
    label: "CIPRESSA",
    province: "IM"
  },
  {
    code: "3989",
    value: "C755",
    label: "CIVEZZA",
    province: "IM"
  },
  {
    code: "3990",
    value: "D087",
    label: "COSIO DI ARROSCIA",
    province: "IM"
  },
  {
    code: "3991",
    value: "D114",
    label: "COSTARAINERA",
    province: "IM"
  },
  {
    code: "3992",
    value: "D293",
    label: "DIANO ARENTINO",
    province: "IM"
  },
  {
    code: "3993",
    value: "D296",
    label: "DIANO CASTELLO",
    province: "IM"
  },
  {
    code: "3994",
    value: "D297",
    label: "DIANO MARINA",
    province: "IM"
  },
  {
    code: "3995",
    value: "D298",
    label: "DIANO SAN PIETRO",
    province: "IM"
  },
  {
    code: "3996",
    value: "D318",
    label: "DOLCEACQUA",
    province: "IM"
  },
  {
    code: "3997",
    value: "D319",
    label: "DOLCEDO",
    province: "IM"
  },
  {
    code: "3998",
    value: "E290",
    label: "IMPERIA",
    province: "IM"
  },
  {
    code: "3999",
    value: "E346",
    label: "ISOLABONA",
    province: "IM"
  },
  {
    code: "4000",
    value: "E719",
    label: "LUCINASCO",
    province: "IM"
  },
  {
    code: "4001",
    value: "F123",
    label: "MENDATICA",
    province: "IM"
  },
  {
    code: "4002",
    value: "F290",
    label: "MOLINI DI TRIORA",
    province: "IM"
  },
  {
    code: "4004",
    value: "F528",
    label: "MONTEGROSSO PIAN LATTE",
    province: "IM"
  },
  {
    code: "4005",
    value: "G041",
    label: "OLIVETTA SAN MICHELE",
    province: "IM"
  },
  {
    code: "4006",
    value: "G164",
    label: "OSPEDALETTI",
    province: "IM"
  },
  {
    code: "4007",
    value: "G454",
    label: "PERINALDO",
    province: "IM"
  },
  {
    code: "4008",
    value: "G607",
    label: "PIETRABRUNA",
    province: "IM"
  },
  {
    code: "4009",
    value: "G632",
    label: "PIEVE DI TECO",
    province: "IM"
  },
  {
    code: "4010",
    value: "G660",
    label: "PIGNA",
    province: "IM"
  },
  {
    code: "4011",
    value: "G814",
    label: "POMPEIANA",
    province: "IM"
  },
  {
    code: "4012",
    value: "G840",
    label: "PONTEDASSIO",
    province: "IM"
  },
  {
    code: "4013",
    value: "G890",
    label: "PORNASSIO",
    province: "IM"
  },
  {
    code: "4014",
    value: "H027",
    label: "PRELA",
    province: "IM"
  },
  {
    code: "4015",
    value: "H180",
    label: "RANZO",
    province: "IM"
  },
  {
    code: "4016",
    value: "H257",
    label: "REZZO",
    province: "IM"
  },
  {
    code: "4017",
    value: "H328",
    label: "RIVA LIGURE",
    province: "IM"
  },
  {
    code: "4018",
    value: "H460",
    label: "ROCCHETTA NERVINA",
    province: "IM"
  },
  {
    code: "4019",
    value: "H763",
    label: "SAN BARTOLOMEO AL MARE",
    province: "IM"
  },
  {
    code: "4020",
    value: "H780",
    label: "SAN BIAGIO DELLA CIMA",
    province: "IM"
  },
  {
    code: "4021",
    value: "H957",
    label: "SAN LORENZO AL MARE",
    province: "IM"
  },
  {
    code: "4022",
    value: "I138",
    label: "SANREMO",
    province: "IM"
  },
  {
    code: "4023",
    value: "I365",
    label: "SANTO STEFANO AL MARE",
    province: "IM"
  },
  {
    code: "4024",
    value: "I556",
    label: "SEBORGA",
    province: "IM"
  },
  {
    code: "4025",
    value: "I796",
    label: "SOLDANO",
    province: "IM"
  },
  {
    code: "4026",
    value: "L024",
    label: "TAGGIA",
    province: "IM"
  },
  {
    code: "4027",
    value: "L146",
    label: "TERZORIO",
    province: "IM"
  },
  {
    code: "4028",
    value: "L430",
    label: "TRIORA",
    province: "IM"
  },
  {
    code: "4029",
    value: "L596",
    label: "VALLEBONA",
    province: "IM"
  },
  {
    code: "4030",
    value: "L599",
    label: "VALLECROSIA",
    province: "IM"
  },
  {
    code: "4031",
    value: "L693",
    label: "VASIA",
    province: "IM"
  },
  {
    code: "4032",
    value: "L741",
    label: "VENTIMIGLIA",
    province: "IM"
  },
  {
    code: "4033",
    value: "L809",
    label: "VESSALICO",
    province: "IM"
  },
  {
    code: "4034",
    value: "L943",
    label: "VILLA FARALDI",
    province: "IM"
  },
  {
    code: "4035",
    value: "A122",
    label: "ALASSIO",
    province: "SV"
  },
  {
    code: "4036",
    value: "A145",
    label: "ALBENGA",
    province: "SV"
  },
  {
    code: "4037",
    value: "A165",
    label: "ALBISSOLA MARINA",
    province: "SV"
  },
  {
    code: "4038",
    value: "A166",
    label: "ALBISOLA SUPERIORE",
    province: "SV"
  },
  {
    code: "4039",
    value: "A226",
    label: "ALTARE",
    province: "SV"
  },
  {
    code: "4040",
    value: "A278",
    label: "ANDORA",
    province: "SV"
  },
  {
    code: "4041",
    value: "A422",
    label: "ARNASCO",
    province: "SV"
  },
  {
    code: "4042",
    value: "A593",
    label: "BALESTRINO",
    province: "SV"
  },
  {
    code: "4043",
    value: "A647",
    label: "BARDINETO",
    province: "SV"
  },
  {
    code: "4044",
    value: "A796",
    label: "BERGEGGI",
    province: "SV"
  },
  {
    code: "4045",
    value: "A931",
    label: "BOISSANO",
    province: "SV"
  },
  {
    code: "4046",
    value: "A999",
    label: "BORGHETTO SANTO SPIRITO",
    province: "SV"
  },
  {
    code: "4047",
    value: "B005",
    label: "BORGIO VEREZZI",
    province: "SV"
  },
  {
    code: "4048",
    value: "B048",
    label: "BORMIDA",
    province: "SV"
  },
  {
    code: "4049",
    value: "B369",
    label: "CAIRO MONTENOTTE",
    province: "SV"
  },
  {
    code: "4050",
    value: "B409",
    label: "CALICE LIGURE",
    province: "SV"
  },
  {
    code: "4051",
    value: "B416",
    label: "CALIZZANO",
    province: "SV"
  },
  {
    code: "4052",
    value: "B748",
    label: "CARCARE",
    province: "SV"
  },
  {
    code: "4053",
    value: "B927",
    label: "CASANOVA LERRONE",
    province: "SV"
  },
  {
    code: "4054",
    value: "C063",
    label: "CASTELBIANCO",
    province: "SV"
  },
  {
    code: "4055",
    value: "C276",
    label: "CASTELVECCHIO DI ROCCA BARBENA",
    province: "SV"
  },
  {
    code: "4056",
    value: "C443",
    label: "CELLE LIGURE",
    province: "SV"
  },
  {
    code: "4057",
    value: "C463",
    label: "CENGIO",
    province: "SV"
  },
  {
    code: "4058",
    value: "C510",
    label: "CERIALE",
    province: "SV"
  },
  {
    code: "4059",
    value: "C729",
    label: "CISANO SUL NEVA",
    province: "SV"
  },
  {
    code: "4060",
    value: "D095",
    label: "COSSERIA",
    province: "SV"
  },
  {
    code: "4061",
    value: "D264",
    label: "DEGO",
    province: "SV"
  },
  {
    code: "4062",
    value: "D424",
    label: "ERLI",
    province: "SV"
  },
  {
    code: "4063",
    value: "D600",
    label: "FINALE LIGURE",
    province: "SV"
  },
  {
    code: "4064",
    value: "D927",
    label: "GARLENDA",
    province: "SV"
  },
  {
    code: "4065",
    value: "E064",
    label: "GIUSTENICE",
    province: "SV"
  },
  {
    code: "4066",
    value: "E066",
    label: "GIUSVALLA",
    province: "SV"
  },
  {
    code: "4067",
    value: "E414",
    label: "LAIGUEGLIA",
    province: "SV"
  },
  {
    code: "4068",
    value: "E632",
    label: "LOANO",
    province: "SV"
  },
  {
    code: "4069",
    value: "E816",
    label: "MAGLIOLO",
    province: "SV"
  },
  {
    code: "4070",
    value: "E860",
    label: "MALLARE",
    province: "SV"
  },
  {
    code: "4071",
    value: "F046",
    label: "MASSIMINO",
    province: "SV"
  },
  {
    code: "4072",
    value: "F213",
    label: "MILLESIMO",
    province: "SV"
  },
  {
    code: "4073",
    value: "F226",
    label: "MIOGLIA",
    province: "SV"
  },
  {
    code: "4074",
    value: "F813",
    label: "MURIALDO",
    province: "SV"
  },
  {
    code: "4075",
    value: "F847",
    label: "NASINO",
    province: "SV"
  },
  {
    code: "4076",
    value: "F926",
    label: "NOLI",
    province: "SV"
  },
  {
    code: "4077",
    value: "G076",
    label: "ONZO",
    province: "SV"
  },
  {
    code: "4078",
    value: "D522",
    label: "ORCO FEGLINO",
    province: "SV"
  },
  {
    code: "4079",
    value: "G144",
    label: "ORTOVERO",
    province: "SV"
  },
  {
    code: "4080",
    value: "G155",
    label: "OSIGLIA",
    province: "SV"
  },
  {
    code: "4081",
    value: "G281",
    label: "PALLARE",
    province: "SV"
  },
  {
    code: "4082",
    value: "G542",
    label: "PIANA CRIXIA",
    province: "SV"
  },
  {
    code: "4083",
    value: "G605",
    label: "PIETRA LIGURE",
    province: "SV"
  },
  {
    code: "4084",
    value: "G741",
    label: "PLODIO",
    province: "SV"
  },
  {
    code: "4085",
    value: "G866",
    label: "PONTINVREA",
    province: "SV"
  },
  {
    code: "4086",
    value: "H126",
    label: "QUILIANO",
    province: "SV"
  },
  {
    code: "4087",
    value: "H266",
    label: "RIALTO",
    province: "SV"
  },
  {
    code: "4088",
    value: "H452",
    label: "ROCCAVIGNALE",
    province: "SV"
  },
  {
    code: "4089",
    value: "I453",
    label: "SASSELLO",
    province: "SV"
  },
  {
    code: "4090",
    value: "I480",
    label: "SAVONA",
    province: "SV"
  },
  {
    code: "4091",
    value: "I926",
    label: "SPOTORNO",
    province: "SV"
  },
  {
    code: "4092",
    value: "I946",
    label: "STELLA",
    province: "SV"
  },
  {
    code: "4093",
    value: "I947",
    label: "STELLANELLO",
    province: "SV"
  },
  {
    code: "4094",
    value: "L152",
    label: "TESTICO",
    province: "SV"
  },
  {
    code: "4095",
    value: "L190",
    label: "TOIRANO",
    province: "SV"
  },
  {
    code: "4096",
    value: "L315",
    label: "TOVO SAN GIACOMO",
    province: "SV"
  },
  {
    code: "4097",
    value: "L499",
    label: "URBE",
    province: "SV"
  },
  {
    code: "4098",
    value: "L528",
    label: "VADO LIGURE",
    province: "SV"
  },
  {
    code: "4099",
    value: "L675",
    label: "VARAZZE",
    province: "SV"
  },
  {
    code: "4100",
    value: "L730",
    label: "VENDONE",
    province: "SV"
  },
  {
    code: "4101",
    value: "L823",
    label: "VEZZI PORTIO",
    province: "SV"
  },
  {
    code: "4102",
    value: "L975",
    label: "VILLANOVA D ALBENGA",
    province: "SV"
  },
  {
    code: "4103",
    value: "M197",
    label: "ZUCCARELLO",
    province: "SV"
  },
  {
    code: "4104",
    value: "A388",
    label: "ARENZANO",
    province: "GE"
  },
  {
    code: "4105",
    value: "A506",
    label: "AVEGNO",
    province: "GE"
  },
  {
    code: "4106",
    value: "A658",
    label: "BARGAGLI",
    province: "GE"
  },
  {
    code: "4107",
    value: "A922",
    label: "BOGLIASCO",
    province: "GE"
  },
  {
    code: "4108",
    value: "B067",
    label: "BORZONASCA",
    province: "GE"
  },
  {
    code: "4109",
    value: "B282",
    label: "BUSALLA",
    province: "GE"
  },
  {
    code: "4110",
    value: "B490",
    label: "CAMOGLI",
    province: "GE"
  },
  {
    code: "4111",
    value: "B538",
    label: "CAMPO LIGURE",
    province: "GE"
  },
  {
    code: "4112",
    value: "B551",
    label: "CAMPOMORONE",
    province: "GE"
  },
  {
    code: "4113",
    value: "B726",
    label: "CARASCO",
    province: "GE"
  },
  {
    code: "4114",
    value: "B939",
    label: "CASARZA LIGURE",
    province: "GE"
  },
  {
    code: "4115",
    value: "B956",
    label: "CASELLA",
    province: "GE"
  },
  {
    code: "4116",
    value: "C302",
    label: "CASTIGLIONE CHIAVARESE",
    province: "GE"
  },
  {
    code: "4117",
    value: "C481",
    label: "CERANESI",
    province: "GE"
  },
  {
    code: "4118",
    value: "C621",
    label: "CHIAVARI",
    province: "GE"
  },
  {
    code: "4119",
    value: "C673",
    label: "CICAGNA",
    province: "GE"
  },
  {
    code: "4120",
    value: "C823",
    label: "COGOLETO",
    province: "GE"
  },
  {
    code: "4121",
    value: "C826",
    label: "COGORNO",
    province: "GE"
  },
  {
    code: "4122",
    value: "C995",
    label: "COREGLIA LIGURE",
    province: "GE"
  },
  {
    code: "4123",
    value: "D175",
    label: "CROCEFIESCHI",
    province: "GE"
  },
  {
    code: "4124",
    value: "D255",
    label: "DAVAGNA",
    province: "GE"
  },
  {
    code: "4125",
    value: "D509",
    label: "FASCIA",
    province: "GE"
  },
  {
    code: "4126",
    value: "D512",
    label: "FAVALE DI MALVARO",
    province: "GE"
  },
  {
    code: "4127",
    value: "D677",
    label: "FONTANIGORDA",
    province: "GE"
  },
  {
    code: "4128",
    value: "D969",
    label: "GENOVA",
    province: "GE"
  },
  {
    code: "4129",
    value: "E109",
    label: "GORRETO",
    province: "GE"
  },
  {
    code: "4130",
    value: "E341",
    label: "ISOLA DEL CANTONE",
    province: "GE"
  },
  {
    code: "4131",
    value: "E488",
    label: "LAVAGNA",
    province: "GE"
  },
  {
    code: "4132",
    value: "E519",
    label: "LEIVI",
    province: "GE"
  },
  {
    code: "4133",
    value: "E695",
    label: "LORSICA",
    province: "GE"
  },
  {
    code: "4134",
    value: "E737",
    label: "LUMARZO",
    province: "GE"
  },
  {
    code: "4135",
    value: "F020",
    label: "MASONE",
    province: "GE"
  },
  {
    code: "4136",
    value: "F098",
    label: "MELE",
    province: "GE"
  },
  {
    code: "4137",
    value: "F173",
    label: "MEZZANEGO",
    province: "GE"
  },
  {
    code: "4138",
    value: "F202",
    label: "MIGNANEGO",
    province: "GE"
  },
  {
    code: "4139",
    value: "F256",
    label: "MOCONESI",
    province: "GE"
  },
  {
    code: "4140",
    value: "F354",
    label: "MONEGLIA",
    province: "GE"
  },
  {
    code: "4141",
    value: "F445",
    label: "MONTEBRUNO",
    province: "GE"
  },
  {
    code: "4142",
    value: "F682",
    label: "MONTOGGIO",
    province: "GE"
  },
  {
    code: "4143",
    value: "F858",
    label: "NE",
    province: "GE"
  },
  {
    code: "4144",
    value: "F862",
    label: "NEIRONE",
    province: "GE"
  },
  {
    code: "4145",
    value: "G093",
    label: "ORERO",
    province: "GE"
  },
  {
    code: "4146",
    value: "G646",
    label: "PIEVE LIGURE",
    province: "GE"
  },
  {
    code: "4147",
    value: "G913",
    label: "PORTOFINO",
    province: "GE"
  },
  {
    code: "4148",
    value: "H073",
    label: "PROPATA",
    province: "GE"
  },
  {
    code: "4149",
    value: "H183",
    label: "RAPALLO",
    province: "GE"
  },
  {
    code: "4150",
    value: "H212",
    label: "RECCO",
    province: "GE"
  },
  {
    code: "4151",
    value: "H258",
    label: "REZZOAGLIO",
    province: "GE"
  },
  {
    code: "4152",
    value: "H536",
    label: "RONCO SCRIVIA",
    province: "GE"
  },
  {
    code: "4153",
    value: "H546",
    label: "RONDANINA",
    province: "GE"
  },
  {
    code: "4154",
    value: "H581",
    label: "ROSSIGLIONE",
    province: "GE"
  },
  {
    code: "4155",
    value: "H599",
    label: "ROVEGNO",
    province: "GE"
  },
  {
    code: "4156",
    value: "H802",
    label: "SAN COLOMBANO CERTENOLI",
    province: "GE"
  },
  {
    code: "4157",
    value: "I225",
    label: "SANTA MARGHERITA LIGURE",
    province: "GE"
  },
  {
    code: "4158",
    value: "I346",
    label: "SANT OLCESE",
    province: "GE"
  },
  {
    code: "4159",
    value: "I368",
    label: "SANTO STEFANO D AVETO",
    province: "GE"
  },
  {
    code: "4160",
    value: "I475",
    label: "SAVIGNONE",
    province: "GE"
  },
  {
    code: "4161",
    value: "I640",
    label: "SERRA RICCO",
    province: "GE"
  },
  {
    code: "4162",
    value: "I693",
    label: "SESTRI LEVANTE",
    province: "GE"
  },
  {
    code: "4163",
    value: "I852",
    label: "SORI",
    province: "GE"
  },
  {
    code: "4164",
    value: "L167",
    label: "TIGLIETO",
    province: "GE"
  },
  {
    code: "4165",
    value: "L298",
    label: "TORRIGLIA",
    province: "GE"
  },
  {
    code: "4166",
    value: "L416",
    label: "TRIBOGNA",
    province: "GE"
  },
  {
    code: "4167",
    value: "L507",
    label: "USCIO",
    province: "GE"
  },
  {
    code: "4168",
    value: "L546",
    label: "VALBREVENNA",
    province: "GE"
  },
  {
    code: "4169",
    value: "M105",
    label: "VOBBIA",
    province: "GE"
  },
  {
    code: "4170",
    value: "M182",
    label: "ZOAGLI",
    province: "GE"
  },
  {
    code: "4171",
    value: "A261",
    label: "AMEGLIA",
    province: "SP"
  },
  {
    code: "4172",
    value: "A373",
    label: "ARCOLA",
    province: "SP"
  },
  {
    code: "4173",
    value: "A836",
    label: "BEVERINO",
    province: "SP"
  },
  {
    code: "4174",
    value: "A932",
    label: "BOLANO",
    province: "SP"
  },
  {
    code: "4175",
    value: "A961",
    label: "BONASSOLA",
    province: "SP"
  },
  {
    code: "4176",
    value: "A992",
    label: "BORGHETTO DI VARA",
    province: "SP"
  },
  {
    code: "4177",
    value: "B214",
    label: "BRUGNATO",
    province: "SP"
  },
  {
    code: "4178",
    value: "B410",
    label: "CALICE AL CORNOVIGLIO",
    province: "SP"
  },
  {
    code: "4179",
    value: "B838",
    label: "CARRO",
    province: "SP"
  },
  {
    code: "4180",
    value: "B839",
    label: "CARRODANO",
    province: "SP"
  },
  {
    code: "4181",
    value: "C240",
    label: "CASTELNUOVO MAGRA",
    province: "SP"
  },
  {
    code: "4182",
    value: "D265",
    label: "DEIVA MARINA",
    province: "SP"
  },
  {
    code: "4183",
    value: "D655",
    label: "FOLLO",
    province: "SP"
  },
  {
    code: "4184",
    value: "D758",
    label: "FRAMURA",
    province: "SP"
  },
  {
    code: "4185",
    value: "E463",
    label: "LA SPEZIA",
    province: "SP"
  },
  {
    code: "4186",
    value: "E542",
    label: "LERICI",
    province: "SP"
  },
  {
    code: "4187",
    value: "E560",
    label: "LEVANTO",
    province: "SP"
  },
  {
    code: "4188",
    value: "E842",
    label: "MAISSANA",
    province: "SP"
  },
  {
    code: "4189",
    value: "F609",
    label: "MONTEROSSO AL MARE",
    province: "SP"
  },
  {
    code: "4190",
    value: "G143",
    label: "LUNI",
    province: "SP"
  },
  {
    code: "4191",
    value: "G664",
    label: "PIGNONE",
    province: "SP"
  },
  {
    code: "4192",
    value: "G925",
    label: "PORTOVENERE",
    province: "SP"
  },
  {
    code: "4193",
    value: "H275",
    label: "RICCO DEL GOLFO DI SPEZIA",
    province: "SP"
  },
  {
    code: "4194",
    value: "H304",
    label: "RIOMAGGIORE",
    province: "SP"
  },
  {
    code: "4195",
    value: "H461",
    label: "ROCCHETTA DI VARA",
    province: "SP"
  },
  {
    code: "4196",
    value: "I363",
    label: "SANTO STEFANO DI MAGRA",
    province: "SP"
  },
  {
    code: "4197",
    value: "I449",
    label: "SARZANA",
    province: "SP"
  },
  {
    code: "4198",
    value: "E070",
    label: "SESTA GODANO",
    province: "SP"
  },
  {
    code: "4199",
    value: "L681",
    label: "VARESE LIGURE",
    province: "SP"
  },
  {
    code: "4200",
    value: "L774",
    label: "VERNAZZA",
    province: "SP"
  },
  {
    code: "4201",
    value: "L819",
    label: "VEZZANO LIGURE",
    province: "SP"
  },
  {
    code: "4202",
    value: "M177",
    label: "ZIGNAGO",
    province: "SP"
  },
  {
    code: "4203",
    value: "A067",
    label: "AGAZZANO",
    province: "PC"
  },
  {
    code: "4204",
    value: "A223",
    label: "ALSENO",
    province: "PC"
  },
  {
    code: "4205",
    value: "A823",
    label: "BESENZONE",
    province: "PC"
  },
  {
    code: "4206",
    value: "A831",
    label: "BETTOLA",
    province: "PC"
  },
  {
    code: "4207",
    value: "A909",
    label: "BOBBIO",
    province: "PC"
  },
  {
    code: "4208",
    value: "B025",
    label: "BORGONOVO VAL TIDONE",
    province: "PC"
  },
  {
    code: "4209",
    value: "B332",
    label: "CADEO",
    province: "PC"
  },
  {
    code: "4210",
    value: "B405",
    label: "CALENDASCO",
    province: "PC"
  },
  {
    code: "4212",
    value: "B643",
    label: "CAORSO",
    province: "PC"
  },
  {
    code: "4213",
    value: "B812",
    label: "CARPANETO PIACENTINO",
    province: "PC"
  },
  {
    code: "4214",
    value: "C145",
    label: "CASTELL ARQUATO",
    province: "PC"
  },
  {
    code: "4215",
    value: "C261",
    label: "CASTEL SAN GIOVANNI",
    province: "PC"
  },
  {
    code: "4216",
    value: "C288",
    label: "CASTELVETRO PIACENTINO",
    province: "PC"
  },
  {
    code: "4217",
    value: "C513",
    label: "CERIGNALE",
    province: "PC"
  },
  {
    code: "4218",
    value: "C838",
    label: "COLI",
    province: "PC"
  },
  {
    code: "4219",
    value: "D054",
    label: "CORTE BRUGNATELLA",
    province: "PC"
  },
  {
    code: "4220",
    value: "D061",
    label: "CORTEMAGGIORE",
    province: "PC"
  },
  {
    code: "4221",
    value: "D502",
    label: "FARINI",
    province: "PC"
  },
  {
    code: "4222",
    value: "D555",
    label: "FERRIERE",
    province: "PC"
  },
  {
    code: "4223",
    value: "D611",
    label: "FIORENZUOLA D ARDA",
    province: "PC"
  },
  {
    code: "4224",
    value: "D958",
    label: "GAZZOLA",
    province: "PC"
  },
  {
    code: "4225",
    value: "E114",
    label: "GOSSOLENGO",
    province: "PC"
  },
  {
    code: "4226",
    value: "E132",
    label: "GRAGNANO TREBBIENSE",
    province: "PC"
  },
  {
    code: "4227",
    value: "E196",
    label: "GROPPARELLO",
    province: "PC"
  },
  {
    code: "4228",
    value: "E726",
    label: "LUGAGNANO VAL D ARDA",
    province: "PC"
  },
  {
    code: "4229",
    value: "F671",
    label: "MONTICELLI D ONGINA",
    province: "PC"
  },
  {
    code: "4230",
    value: "F724",
    label: "MORFASSO",
    province: "PC"
  },
  {
    code: "4232",
    value: "G195",
    label: "OTTONE",
    province: "PC"
  },
  {
    code: "4234",
    value: "G535",
    label: "PIACENZA",
    province: "PC"
  },
  {
    code: "4235",
    value: "G557",
    label: "PIANELLO VAL TIDONE",
    province: "PC"
  },
  {
    code: "4236",
    value: "G696",
    label: "PIOZZANO",
    province: "PC"
  },
  {
    code: "4237",
    value: "G747",
    label: "PODENZANO",
    province: "PC"
  },
  {
    code: "4238",
    value: "G842",
    label: "PONTE DELL OLIO",
    province: "PC"
  },
  {
    code: "4239",
    value: "G852",
    label: "PONTENURE",
    province: "PC"
  },
  {
    code: "4240",
    value: "H350",
    label: "RIVERGARO",
    province: "PC"
  },
  {
    code: "4241",
    value: "H593",
    label: "ROTTOFRENO",
    province: "PC"
  },
  {
    code: "4242",
    value: "H887",
    label: "SAN GIORGIO PIACENTINO",
    province: "PC"
  },
  {
    code: "4243",
    value: "G788",
    label: "SAN PIETRO IN CERRO",
    province: "PC"
  },
  {
    code: "4244",
    value: "I434",
    label: "SARMATO",
    province: "PC"
  },
  {
    code: "4245",
    value: "L348",
    label: "TRAVO",
    province: "PC"
  },
  {
    code: "4246",
    value: "L772",
    label: "VERNASCA",
    province: "PC"
  },
  {
    code: "4247",
    value: "L897",
    label: "VIGOLZONE",
    province: "PC"
  },
  {
    code: "4248",
    value: "L980",
    label: "VILLANOVA SULL ARDA",
    province: "PC"
  },
  {
    code: "4249",
    value: "M165",
    label: "ZERBA",
    province: "PC"
  },
  {
    code: "4250",
    value: "L848",
    label: "ZIANO PIACENTINO",
    province: "PC"
  },
  {
    code: "4251",
    value: "A138",
    label: "ALBARETO",
    province: "PR"
  },
  {
    code: "4252",
    value: "A646",
    label: "BARDI",
    province: "PR"
  },
  {
    code: "4253",
    value: "A731",
    label: "BEDONIA",
    province: "PR"
  },
  {
    code: "4254",
    value: "A788",
    label: "BERCETO",
    province: "PR"
  },
  {
    code: "4255",
    value: "A987",
    label: "BORE",
    province: "PR"
  },
  {
    code: "4256",
    value: "B042",
    label: "BORGO VAL DI TARO",
    province: "PR"
  },
  {
    code: "4257",
    value: "B293",
    label: "BUSSETO",
    province: "PR"
  },
  {
    code: "4258",
    value: "B408",
    label: "CALESTANO",
    province: "PR"
  },
  {
    code: "4259",
    value: "C852",
    label: "COLLECCHIO",
    province: "PR"
  },
  {
    code: "4260",
    value: "C904",
    label: "COLORNO",
    province: "PR"
  },
  {
    code: "4261",
    value: "C934",
    label: "COMPIANO",
    province: "PR"
  },
  {
    code: "4262",
    value: "D026",
    label: "CORNIGLIO",
    province: "PR"
  },
  {
    code: "4263",
    value: "D526",
    label: "FELINO",
    province: "PR"
  },
  {
    code: "4264",
    value: "B034",
    label: "FIDENZA",
    province: "PR"
  },
  {
    code: "4265",
    value: "D673",
    label: "FONTANELLATO",
    province: "PR"
  },
  {
    code: "4266",
    value: "D685",
    label: "FONTEVIVO",
    province: "PR"
  },
  {
    code: "4267",
    value: "D728",
    label: "FORNOVO DI TARO",
    province: "PR"
  },
  {
    code: "4268",
    value: "E438",
    label: "LANGHIRANO",
    province: "PR"
  },
  {
    code: "4269",
    value: "E547",
    label: "LESIGNANO DE BAGNI",
    province: "PR"
  },
  {
    code: "4270",
    value: "F082",
    label: "MEDESANO",
    province: "PR"
  },
  {
    code: "4272",
    value: "F340",
    label: "MONCHIO DELLE CORTI",
    province: "PR"
  },
  {
    code: "4273",
    value: "F473",
    label: "MONTECHIARUGOLO",
    province: "PR"
  },
  {
    code: "4274",
    value: "F882",
    label: "NEVIANO DEGLI ARDUINI",
    province: "PR"
  },
  {
    code: "4275",
    value: "F914",
    label: "NOCETO",
    province: "PR"
  },
  {
    code: "4276",
    value: "G255",
    label: "PALANZANO",
    province: "PR"
  },
  {
    code: "4277",
    value: "G337",
    label: "PARMA",
    province: "PR"
  },
  {
    code: "4278",
    value: "G424",
    label: "PELLEGRINO PARMENSE",
    province: "PR"
  },
  {
    code: "4280",
    value: "H384",
    label: "ROCCABIANCA",
    province: "PR"
  },
  {
    code: "4281",
    value: "H682",
    label: "SALA BAGANZA",
    province: "PR"
  },
  {
    code: "4282",
    value: "H720",
    label: "SALSOMAGGIORE TERME",
    province: "PR"
  },
  {
    code: "4283",
    value: "I153",
    label: "SAN SECONDO PARMENSE",
    province: "PR"
  },
  {
    code: "4285",
    value: "I803",
    label: "SOLIGNANO",
    province: "PR"
  },
  {
    code: "4286",
    value: "I840",
    label: "SORAGNA",
    province: "PR"
  },
  {
    code: "4288",
    value: "E548",
    label: "TERENZO",
    province: "PR"
  },
  {
    code: "4289",
    value: "L183",
    label: "TIZZANO VAL PARMA",
    province: "PR"
  },
  {
    code: "4290",
    value: "L229",
    label: "TORNOLO",
    province: "PR"
  },
  {
    code: "4291",
    value: "L299",
    label: "TORRILE",
    province: "PR"
  },
  {
    code: "4292",
    value: "L346",
    label: "TRAVERSETOLO",
    province: "PR"
  },
  {
    code: "4294",
    value: "L641",
    label: "VALMOZZOLA",
    province: "PR"
  },
  {
    code: "4295",
    value: "L672",
    label: "VARANO DE MELEGARI",
    province: "PR"
  },
  {
    code: "4296",
    value: "L689",
    label: "VARSI",
    province: "PR"
  },
  {
    code: "4298",
    value: "A162",
    label: "ALBINEA",
    province: "RE"
  },
  {
    code: "4299",
    value: "A573",
    label: "BAGNOLO IN PIANO",
    province: "RE"
  },
  {
    code: "4300",
    value: "A586",
    label: "BAISO",
    province: "RE"
  },
  {
    code: "4301",
    value: "A850",
    label: "BIBBIANO",
    province: "RE"
  },
  {
    code: "4302",
    value: "A988",
    label: "BORETTO",
    province: "RE"
  },
  {
    code: "4303",
    value: "B156",
    label: "BRESCELLO",
    province: "RE"
  },
  {
    code: "4305",
    value: "B328",
    label: "CADELBOSCO DI SOPRA",
    province: "RE"
  },
  {
    code: "4306",
    value: "B499",
    label: "CAMPAGNOLA EMILIA",
    province: "RE"
  },
  {
    code: "4307",
    value: "B502",
    label: "CAMPEGINE",
    province: "RE"
  },
  {
    code: "4308",
    value: "B825",
    label: "CARPINETI",
    province: "RE"
  },
  {
    code: "4309",
    value: "B893",
    label: "CASALGRANDE",
    province: "RE"
  },
  {
    code: "4310",
    value: "B967",
    label: "CASINA",
    province: "RE"
  },
  {
    code: "4311",
    value: "C141",
    label: "CASTELLARANO",
    province: "RE"
  },
  {
    code: "4312",
    value: "C218",
    label: "CASTELNOVO DI SOTTO",
    province: "RE"
  },
  {
    code: "4313",
    value: "C219",
    label: "CASTELNOVO NE MONTI",
    province: "RE"
  },
  {
    code: "4314",
    value: "C405",
    label: "CAVRIAGO",
    province: "RE"
  },
  {
    code: "4315",
    value: "C669",
    label: "CANOSSA",
    province: "RE"
  },
  {
    code: "4317",
    value: "D037",
    label: "CORREGGIO",
    province: "RE"
  },
  {
    code: "4318",
    value: "D450",
    label: "FABBRICO",
    province: "RE"
  },
  {
    code: "4319",
    value: "D934",
    label: "GATTATICO",
    province: "RE"
  },
  {
    code: "4320",
    value: "E232",
    label: "GUALTIERI",
    province: "RE"
  },
  {
    code: "4321",
    value: "E253",
    label: "GUASTALLA",
    province: "RE"
  },
  {
    code: "4323",
    value: "E772",
    label: "LUZZARA",
    province: "RE"
  },
  {
    code: "4324",
    value: "F463",
    label: "MONTECCHIO EMILIA",
    province: "RE"
  },
  {
    code: "4325",
    value: "F960",
    label: "NOVELLARA",
    province: "RE"
  },
  {
    code: "4326",
    value: "G947",
    label: "POVIGLIO",
    province: "RE"
  },
  {
    code: "4327",
    value: "H122",
    label: "QUATTRO CASTELLA",
    province: "RE"
  },
  {
    code: "4329",
    value: "H225",
    label: "REGGIOLO",
    province: "RE"
  },
  {
    code: "4330",
    value: "H223",
    label: "REGGIO EMILIA",
    province: "RE"
  },
  {
    code: "4331",
    value: "H298",
    label: "RIO SALICETO",
    province: "RE"
  },
  {
    code: "4332",
    value: "H500",
    label: "ROLO",
    province: "RE"
  },
  {
    code: "4333",
    value: "H628",
    label: "RUBIERA",
    province: "RE"
  },
  {
    code: "4334",
    value: "I011",
    label: "SAN MARTINO IN RIO",
    province: "RE"
  },
  {
    code: "4335",
    value: "I123",
    label: "SAN POLO D ENZA",
    province: "RE"
  },
  {
    code: "4336",
    value: "I342",
    label: "SANT ILARIO D ENZA",
    province: "RE"
  },
  {
    code: "4337",
    value: "I496",
    label: "SCANDIANO",
    province: "RE"
  },
  {
    code: "4338",
    value: "L184",
    label: "TOANO",
    province: "RE"
  },
  {
    code: "4339",
    value: "L815",
    label: "VETTO",
    province: "RE"
  },
  {
    code: "4340",
    value: "L820",
    label: "VEZZANO SUL CROSTOLO",
    province: "RE"
  },
  {
    code: "4341",
    value: "L831",
    label: "VIANO",
    province: "RE"
  },
  {
    code: "4342",
    value: "L969",
    label: "VILLA MINOZZO",
    province: "RE"
  },
  {
    code: "4343",
    value: "A713",
    label: "BASTIGLIA",
    province: "MO"
  },
  {
    code: "4344",
    value: "A959",
    label: "BOMPORTO",
    province: "MO"
  },
  {
    code: "4345",
    value: "B539",
    label: "CAMPOGALLIANO",
    province: "MO"
  },
  {
    code: "4346",
    value: "B566",
    label: "CAMPOSANTO",
    province: "MO"
  },
  {
    code: "4347",
    value: "B819",
    label: "CARPI",
    province: "MO"
  },
  {
    code: "4348",
    value: "C107",
    label: "CASTELFRANCO EMILIA",
    province: "MO"
  },
  {
    code: "4349",
    value: "C242",
    label: "CASTELNUOVO RANGONE",
    province: "MO"
  },
  {
    code: "4350",
    value: "C287",
    label: "CASTELVETRO DI MODENA",
    province: "MO"
  },
  {
    code: "4351",
    value: "C398",
    label: "CAVEZZO",
    province: "MO"
  },
  {
    code: "4352",
    value: "C951",
    label: "CONCORDIA SULLA SECCHIA",
    province: "MO"
  },
  {
    code: "4353",
    value: "D486",
    label: "FANANO",
    province: "MO"
  },
  {
    code: "4354",
    value: "D599",
    label: "FINALE EMILIA",
    province: "MO"
  },
  {
    code: "4355",
    value: "D607",
    label: "FIORANO MODENESE",
    province: "MO"
  },
  {
    code: "4356",
    value: "D617",
    label: "FIUMALBO",
    province: "MO"
  },
  {
    code: "4357",
    value: "D711",
    label: "FORMIGINE",
    province: "MO"
  },
  {
    code: "4358",
    value: "D783",
    label: "FRASSINORO",
    province: "MO"
  },
  {
    code: "4359",
    value: "E264",
    label: "GUIGLIA",
    province: "MO"
  },
  {
    code: "4360",
    value: "E426",
    label: "LAMA MOCOGNO",
    province: "MO"
  },
  {
    code: "4361",
    value: "E904",
    label: "MARANELLO",
    province: "MO"
  },
  {
    code: "4362",
    value: "E905",
    label: "MARANO SUL PANARO",
    province: "MO"
  },
  {
    code: "4363",
    value: "F087",
    label: "MEDOLLA",
    province: "MO"
  },
  {
    code: "4364",
    value: "F240",
    label: "MIRANDOLA",
    province: "MO"
  },
  {
    code: "4365",
    value: "F257",
    label: "MODENA",
    province: "MO"
  },
  {
    code: "4366",
    value: "F484",
    label: "MONTECRETO",
    province: "MO"
  },
  {
    code: "4367",
    value: "F503",
    label: "MONTEFIORINO",
    province: "MO"
  },
  {
    code: "4368",
    value: "F642",
    label: "MONTESE",
    province: "MO"
  },
  {
    code: "4369",
    value: "F930",
    label: "NONANTOLA",
    province: "MO"
  },
  {
    code: "4370",
    value: "F966",
    label: "NOVI DI MODENA",
    province: "MO"
  },
  {
    code: "4371",
    value: "G250",
    label: "PALAGANO",
    province: "MO"
  },
  {
    code: "4372",
    value: "G393",
    label: "PAVULLO NEL FRIGNANO",
    province: "MO"
  },
  {
    code: "4373",
    value: "G649",
    label: "PIEVEPELAGO",
    province: "MO"
  },
  {
    code: "4374",
    value: "G789",
    label: "POLINAGO",
    province: "MO"
  },
  {
    code: "4375",
    value: "H061",
    label: "PRIGNANO SULLA SECCHIA",
    province: "MO"
  },
  {
    code: "4376",
    value: "H195",
    label: "RAVARINO",
    province: "MO"
  },
  {
    code: "4377",
    value: "H303",
    label: "RIOLUNATO",
    province: "MO"
  },
  {
    code: "4378",
    value: "H794",
    label: "SAN CESARIO SUL PANARO",
    province: "MO"
  },
  {
    code: "4379",
    value: "H835",
    label: "SAN FELICE SUL PANARO",
    province: "MO"
  },
  {
    code: "4380",
    value: "I128",
    label: "SAN POSSIDONIO",
    province: "MO"
  },
  {
    code: "4381",
    value: "I133",
    label: "SAN PROSPERO",
    province: "MO"
  },
  {
    code: "4382",
    value: "I462",
    label: "SASSUOLO",
    province: "MO"
  },
  {
    code: "4383",
    value: "I473",
    label: "SAVIGNANO SUL PANARO",
    province: "MO"
  },
  {
    code: "4384",
    value: "F357",
    label: "SERRAMAZZONI",
    province: "MO"
  },
  {
    code: "4385",
    value: "I689",
    label: "SESTOLA",
    province: "MO"
  },
  {
    code: "4386",
    value: "I802",
    label: "SOLIERA",
    province: "MO"
  },
  {
    code: "4387",
    value: "I903",
    label: "SPILAMBERTO",
    province: "MO"
  },
  {
    code: "4388",
    value: "L885",
    label: "VIGNOLA",
    province: "MO"
  },
  {
    code: "4389",
    value: "M183",
    label: "ZOCCA",
    province: "MO"
  },
  {
    code: "4390",
    value: "A324",
    label: "ANZOLA DELL EMILIA",
    province: "BO"
  },
  {
    code: "4391",
    value: "A392",
    label: "ARGELATO",
    province: "BO"
  },
  {
    code: "4392",
    value: "A665",
    label: "BARICELLA",
    province: "BO"
  },
  {
    code: "4394",
    value: "A785",
    label: "BENTIVOGLIO",
    province: "BO"
  },
  {
    code: "4395",
    value: "A944",
    label: "BOLOGNA",
    province: "BO"
  },
  {
    code: "4396",
    value: "B044",
    label: "BORGO TOSSIGNANO",
    province: "BO"
  },
  {
    code: "4397",
    value: "B249",
    label: "BUDRIO",
    province: "BO"
  },
  {
    code: "4398",
    value: "B399",
    label: "CALDERARA DI RENO",
    province: "BO"
  },
  {
    code: "4399",
    value: "B572",
    label: "CAMUGNANO",
    province: "BO"
  },
  {
    code: "4400",
    value: "B880",
    label: "CASALECCHIO DI RENO",
    province: "BO"
  },
  {
    code: "4401",
    value: "B892",
    label: "CASALFIUMANESE",
    province: "BO"
  },
  {
    code: "4402",
    value: "C075",
    label: "CASTEL D AIANO",
    province: "BO"
  },
  {
    code: "4403",
    value: "C086",
    label: "CASTEL DEL RIO",
    province: "BO"
  },
  {
    code: "4404",
    value: "B969",
    label: "CASTEL DI CASIO",
    province: "BO"
  },
  {
    code: "4405",
    value: "C121",
    label: "CASTEL GUELFO DI BOLOGNA",
    province: "BO"
  },
  {
    code: "4406",
    value: "C185",
    label: "CASTELLO D ARGILE",
    province: "BO"
  },
  {
    code: "4408",
    value: "C204",
    label: "CASTEL MAGGIORE",
    province: "BO"
  },
  {
    code: "4409",
    value: "C265",
    label: "CASTEL SAN PIETRO TERME",
    province: "BO"
  },
  {
    code: "4410",
    value: "C292",
    label: "CASTENASO",
    province: "BO"
  },
  {
    code: "4411",
    value: "C296",
    label: "CASTIGLIONE DEI PEPOLI",
    province: "BO"
  },
  {
    code: "4413",
    value: "D166",
    label: "CREVALCORE",
    province: "BO"
  },
  {
    code: "4414",
    value: "D360",
    label: "DOZZA",
    province: "BO"
  },
  {
    code: "4415",
    value: "D668",
    label: "FONTANELICE",
    province: "BO"
  },
  {
    code: "4416",
    value: "D847",
    label: "GAGGIO MONTANO",
    province: "BO"
  },
  {
    code: "4417",
    value: "D878",
    label: "GALLIERA",
    province: "BO"
  },
  {
    code: "4419",
    value: "E136",
    label: "GRANAROLO DELL EMILIA",
    province: "BO"
  },
  {
    code: "4420",
    value: "E187",
    label: "GRIZZANA MORANDI",
    province: "BO"
  },
  {
    code: "4421",
    value: "E289",
    label: "IMOLA",
    province: "BO"
  },
  {
    code: "4422",
    value: "A771",
    label: "LIZZANO IN BELVEDERE",
    province: "BO"
  },
  {
    code: "4423",
    value: "E655",
    label: "LOIANO",
    province: "BO"
  },
  {
    code: "4424",
    value: "E844",
    label: "MALALBERGO",
    province: "BO"
  },
  {
    code: "4425",
    value: "B689",
    label: "MARZABOTTO",
    province: "BO"
  },
  {
    code: "4426",
    value: "F083",
    label: "MEDICINA",
    province: "BO"
  },
  {
    code: "4427",
    value: "F219",
    label: "MINERBIO",
    province: "BO"
  },
  {
    code: "4428",
    value: "F288",
    label: "MOLINELLA",
    province: "BO"
  },
  {
    code: "4429",
    value: "F363",
    label: "MONGHIDORO",
    province: "BO"
  },
  {
    code: "4430",
    value: "F597",
    label: "MONTERENZIO",
    province: "BO"
  },
  {
    code: "4431",
    value: "F627",
    label: "MONTE SAN PIETRO",
    province: "BO"
  },
  {
    code: "4433",
    value: "F706",
    label: "MONZUNO",
    province: "BO"
  },
  {
    code: "4434",
    value: "F718",
    label: "MORDANO",
    province: "BO"
  },
  {
    code: "4435",
    value: "G205",
    label: "OZZANO DELL EMILIA",
    province: "BO"
  },
  {
    code: "4436",
    value: "G570",
    label: "PIANORO",
    province: "BO"
  },
  {
    code: "4437",
    value: "G643",
    label: "PIEVE DI CENTO",
    province: "BO"
  },
  {
    code: "4439",
    value: "H678",
    label: "SALA BOLOGNESE",
    province: "BO"
  },
  {
    code: "4440",
    value: "G566",
    label: "SAN BENEDETTO VAL DI SAMBRO",
    province: "BO"
  },
  {
    code: "4441",
    value: "H896",
    label: "SAN GIORGIO DI PIANO",
    province: "BO"
  },
  {
    code: "4442",
    value: "G467",
    label: "SAN GIOVANNI IN PERSICETO",
    province: "BO"
  },
  {
    code: "4443",
    value: "H945",
    label: "SAN LAZZARO DI SAVENA",
    province: "BO"
  },
  {
    code: "4444",
    value: "I110",
    label: "SAN PIETRO IN CASALE",
    province: "BO"
  },
  {
    code: "4445",
    value: "I191",
    label: "SANT AGATA BOLOGNESE",
    province: "BO"
  },
  {
    code: "4446",
    value: "G972",
    label: "SASSO MARCONI",
    province: "BO"
  },
  {
    code: "4448",
    value: "L762",
    label: "VERGATO",
    province: "BO"
  },
  {
    code: "4449",
    value: "M185",
    label: "ZOLA PREDOSA",
    province: "BO"
  },
  {
    code: "4450",
    value: "A393",
    label: "ARGENTA",
    province: "FE"
  },
  {
    code: "4452",
    value: "A965",
    label: "BONDENO",
    province: "FE"
  },
  {
    code: "4453",
    value: "C469",
    label: "CENTO",
    province: "FE"
  },
  {
    code: "4454",
    value: "C814",
    label: "CODIGORO",
    province: "FE"
  },
  {
    code: "4455",
    value: "C912",
    label: "COMACCHIO",
    province: "FE"
  },
  {
    code: "4456",
    value: "C980",
    label: "COPPARO",
    province: "FE"
  },
  {
    code: "4457",
    value: "D548",
    label: "FERRARA",
    province: "FE"
  },
  {
    code: "4459",
    value: "E320",
    label: "JOLANDA DI SAVOIA",
    province: "FE"
  },
  {
    code: "4460",
    value: "E410",
    label: "LAGOSANTO",
    province: "FE"
  },
  {
    code: "4461",
    value: "F016",
    label: "MASI TORELLO",
    province: "FE"
  },
  {
    code: "4463",
    value: "F156",
    label: "MESOLA",
    province: "FE"
  },
  {
    code: "4466",
    value: "G184",
    label: "OSTELLATO",
    province: "FE"
  },
  {
    code: "4467",
    value: "G768",
    label: "POGGIO RENATICO",
    province: "FE"
  },
  {
    code: "4468",
    value: "G916",
    label: "PORTOMAGGIORE",
    province: "FE"
  },
  {
    code: "4471",
    value: "L868",
    label: "VIGARANO MAINARDA",
    province: "FE"
  },
  {
    code: "4472",
    value: "M110",
    label: "VOGHIERA",
    province: "FE"
  },
  {
    code: "4474",
    value: "E107",
    label: "GORO",
    province: "FE"
  },
  {
    code: "4476",
    value: "A191",
    label: "ALFONSINE",
    province: "RA"
  },
  {
    code: "4477",
    value: "A547",
    label: "BAGNACAVALLO",
    province: "RA"
  },
  {
    code: "4478",
    value: "A551",
    label: "BAGNARA DI ROMAGNA",
    province: "RA"
  },
  {
    code: "4479",
    value: "B188",
    label: "BRISIGHELLA",
    province: "RA"
  },
  {
    code: "4480",
    value: "B982",
    label: "CASOLA VALSENIO",
    province: "RA"
  },
  {
    code: "4481",
    value: "C065",
    label: "CASTEL BOLOGNESE",
    province: "RA"
  },
  {
    code: "4482",
    value: "C553",
    label: "CERVIA",
    province: "RA"
  },
  {
    code: "4483",
    value: "C963",
    label: "CONSELICE",
    province: "RA"
  },
  {
    code: "4484",
    value: "D121",
    label: "COTIGNOLA",
    province: "RA"
  },
  {
    code: "4485",
    value: "D458",
    label: "FAENZA",
    province: "RA"
  },
  {
    code: "4486",
    value: "D829",
    label: "FUSIGNANO",
    province: "RA"
  },
  {
    code: "4487",
    value: "E730",
    label: "LUGO",
    province: "RA"
  },
  {
    code: "4488",
    value: "F029",
    label: "MASSA LOMBARDA",
    province: "RA"
  },
  {
    code: "4489",
    value: "H199",
    label: "RAVENNA",
    province: "RA"
  },
  {
    code: "4490",
    value: "H302",
    label: "RIOLO TERME",
    province: "RA"
  },
  {
    code: "4491",
    value: "H642",
    label: "RUSSI",
    province: "RA"
  },
  {
    code: "4492",
    value: "I196",
    label: "SANT AGATA SUL SANTERNO",
    province: "RA"
  },
  {
    code: "4493",
    value: "I787",
    label: "SOLAROLO",
    province: "RA"
  },
  {
    code: "4494",
    value: "A565",
    label: "BAGNO DI ROMAGNA",
    province: "FC"
  },
  {
    code: "4495",
    value: "A809",
    label: "BERTINORO",
    province: "FC"
  },
  {
    code: "4496",
    value: "B001",
    label: "BORGHI",
    province: "FC"
  },
  {
    code: "4497",
    value: "C339",
    label: "CASTROCARO TERME E TERRA DEL SOLE",
    province: "FC"
  },
  {
    code: "4498",
    value: "C573",
    label: "CESENA",
    province: "FC"
  },
  {
    code: "4499",
    value: "C574",
    label: "CESENATICO",
    province: "FC"
  },
  {
    code: "4500",
    value: "C777",
    label: "CIVITELLA DI ROMAGNA",
    province: "FC"
  },
  {
    code: "4501",
    value: "D357",
    label: "DOVADOLA",
    province: "FC"
  },
  {
    code: "4502",
    value: "D704",
    label: "FORLI",
    province: "FC"
  },
  {
    code: "4503",
    value: "D705",
    label: "FORLIMPOPOLI",
    province: "FC"
  },
  {
    code: "4504",
    value: "D867",
    label: "GALEATA",
    province: "FC"
  },
  {
    code: "4505",
    value: "D899",
    label: "GAMBETTOLA",
    province: "FC"
  },
  {
    code: "4506",
    value: "D935",
    label: "GATTEO",
    province: "FC"
  },
  {
    code: "4507",
    value: "E675",
    label: "LONGIANO",
    province: "FC"
  },
  {
    code: "4508",
    value: "F097",
    label: "MELDOLA",
    province: "FC"
  },
  {
    code: "4509",
    value: "F139",
    label: "MERCATO SARACENO",
    province: "FC"
  },
  {
    code: "4510",
    value: "F259",
    label: "MODIGLIANA",
    province: "FC"
  },
  {
    code: "4511",
    value: "F668",
    label: "MONTIANO",
    province: "FC"
  },
  {
    code: "4512",
    value: "G904",
    label: "PORTICO E SAN BENEDETTO",
    province: "FC"
  },
  {
    code: "4513",
    value: "H017",
    label: "PREDAPPIO",
    province: "FC"
  },
  {
    code: "4514",
    value: "H034",
    label: "PREMILCUORE",
    province: "FC"
  },
  {
    code: "4515",
    value: "H437",
    label: "ROCCA SAN CASCIANO",
    province: "FC"
  },
  {
    code: "4516",
    value: "H542",
    label: "RONCOFREDDO",
    province: "FC"
  },
  {
    code: "4517",
    value: "I027",
    label: "SAN MAURO PASCOLI",
    province: "FC"
  },
  {
    code: "4518",
    value: "I310",
    label: "SANTA SOFIA",
    province: "FC"
  },
  {
    code: "4519",
    value: "I444",
    label: "SARSINA",
    province: "FC"
  },
  {
    code: "4520",
    value: "I472",
    label: "SAVIGNANO SUL RUBICONE",
    province: "FC"
  },
  {
    code: "4521",
    value: "I779",
    label: "SOGLIANO AL RUBICONE",
    province: "FC"
  },
  {
    code: "4522",
    value: "L361",
    label: "TREDOZIO",
    province: "FC"
  },
  {
    code: "4523",
    value: "L764",
    label: "VERGHERETO",
    province: "FC"
  },
  {
    code: "4524",
    value: "A747",
    label: "BELLARIA IGEA MARINA",
    province: "RN"
  },
  {
    code: "4525",
    value: "C357",
    label: "CATTOLICA",
    province: "RN"
  },
  {
    code: "4526",
    value: "D004",
    label: "CORIANO",
    province: "RN"
  },
  {
    code: "4527",
    value: "D961",
    label: "GEMMANO",
    province: "RN"
  },
  {
    code: "4528",
    value: "F244",
    label: "MISANO ADRIATICO",
    province: "RN"
  },
  {
    code: "4529",
    value: "F346",
    label: "MONDAINO",
    province: "RN"
  },
  {
    code: "4531",
    value: "F502",
    label: "MONTEFIORE CONCA",
    province: "RN"
  },
  {
    code: "4532",
    value: "F523",
    label: "MONTEGRIDOLFO",
    province: "RN"
  },
  {
    code: "4534",
    value: "F715",
    label: "MORCIANO DI ROMAGNA",
    province: "RN"
  },
  {
    code: "4536",
    value: "H274",
    label: "RICCIONE",
    province: "RN"
  },
  {
    code: "4537",
    value: "H294",
    label: "RIMINI",
    province: "RN"
  },
  {
    code: "4538",
    value: "H724",
    label: "SALUDECIO",
    province: "RN"
  },
  {
    code: "4539",
    value: "H801",
    label: "SAN CLEMENTE",
    province: "RN"
  },
  {
    code: "4540",
    value: "H921",
    label: "SAN GIOVANNI IN MARIGNANO",
    province: "RN"
  },
  {
    code: "4541",
    value: "I304",
    label: "SANTARCANGELO DI ROMAGNA",
    province: "RN"
  },
  {
    code: "4543",
    value: "L797",
    label: "VERUCCHIO",
    province: "RN"
  },
  {
    code: "4544",
    value: "A496",
    label: "AULLA",
    province: "MS"
  },
  {
    code: "4545",
    value: "A576",
    label: "BAGNONE",
    province: "MS"
  },
  {
    code: "4546",
    value: "B832",
    label: "CARRARA",
    province: "MS"
  },
  {
    code: "4547",
    value: "B979",
    label: "CASOLA IN LUNIGIANA",
    province: "MS"
  },
  {
    code: "4548",
    value: "C914",
    label: "COMANO",
    province: "MS"
  },
  {
    code: "4549",
    value: "D590",
    label: "FILATTIERA",
    province: "MS"
  },
  {
    code: "4550",
    value: "D629",
    label: "FIVIZZANO",
    province: "MS"
  },
  {
    code: "4551",
    value: "D735",
    label: "FOSDINOVO",
    province: "MS"
  },
  {
    code: "4552",
    value: "E574",
    label: "LICCIANA NARDI",
    province: "MS"
  },
  {
    code: "4553",
    value: "F023",
    label: "MASSA",
    province: "MS"
  },
  {
    code: "4554",
    value: "F679",
    label: "MONTIGNOSO",
    province: "MS"
  },
  {
    code: "4555",
    value: "F802",
    label: "MULAZZO",
    province: "MS"
  },
  {
    code: "4556",
    value: "G746",
    label: "PODENZANA",
    province: "MS"
  },
  {
    code: "4557",
    value: "G870",
    label: "PONTREMOLI",
    province: "MS"
  },
  {
    code: "4558",
    value: "L386",
    label: "TRESANA",
    province: "MS"
  },
  {
    code: "4559",
    value: "L946",
    label: "VILLAFRANCA IN LUNIGIANA",
    province: "MS"
  },
  {
    code: "4560",
    value: "M169",
    label: "ZERI",
    province: "MS"
  },
  {
    code: "4561",
    value: "A241",
    label: "ALTOPASCIO",
    province: "LU"
  },
  {
    code: "4562",
    value: "A560",
    label: "BAGNI DI LUCCA",
    province: "LU"
  },
  {
    code: "4563",
    value: "A657",
    label: "BARGA",
    province: "LU"
  },
  {
    code: "4564",
    value: "B007",
    label: "BORGO A MOZZANO",
    province: "LU"
  },
  {
    code: "4565",
    value: "B455",
    label: "CAMAIORE",
    province: "LU"
  },
  {
    code: "4566",
    value: "B557",
    label: "CAMPORGIANO",
    province: "LU"
  },
  {
    code: "4567",
    value: "B648",
    label: "CAPANNORI",
    province: "LU"
  },
  {
    code: "4568",
    value: "B760",
    label: "CAREGGINE",
    province: "LU"
  },
  {
    code: "4569",
    value: "C236",
    label: "CASTELNUOVO DI GARFAGNANA",
    province: "LU"
  },
  {
    code: "4570",
    value: "C303",
    label: "CASTIGLIONE DI GARFAGNANA",
    province: "LU"
  },
  {
    code: "4571",
    value: "C996",
    label: "COREGLIA ANTELMINELLI",
    province: "LU"
  },
  {
    code: "4573",
    value: "D730",
    label: "FORTE DEI MARMI",
    province: "LU"
  },
  {
    code: "4574",
    value: "D734",
    label: "FOSCIANDORA",
    province: "LU"
  },
  {
    code: "4575",
    value: "D874",
    label: "GALLICANO",
    province: "LU"
  },
  {
    code: "4577",
    value: "E715",
    label: "LUCCA",
    province: "LU"
  },
  {
    code: "4578",
    value: "F035",
    label: "MASSAROSA",
    province: "LU"
  },
  {
    code: "4579",
    value: "F225",
    label: "MINUCCIANO",
    province: "LU"
  },
  {
    code: "4580",
    value: "F283",
    label: "MOLAZZANA",
    province: "LU"
  },
  {
    code: "4581",
    value: "F452",
    label: "MONTECARLO",
    province: "LU"
  },
  {
    code: "4582",
    value: "G480",
    label: "PESCAGLIA",
    province: "LU"
  },
  {
    code: "4583",
    value: "G582",
    label: "PIAZZA AL SERCHIO",
    province: "LU"
  },
  {
    code: "4584",
    value: "G628",
    label: "PIETRASANTA",
    province: "LU"
  },
  {
    code: "4585",
    value: "G648",
    label: "PIEVE FOSCIANA",
    province: "LU"
  },
  {
    code: "4586",
    value: "G882",
    label: "PORCARI",
    province: "LU"
  },
  {
    code: "4587",
    value: "I142",
    label: "SAN ROMANO IN GARFAGNANA",
    province: "LU"
  },
  {
    code: "4588",
    value: "I622",
    label: "SERAVEZZA",
    province: "LU"
  },
  {
    code: "4590",
    value: "I942",
    label: "STAZZEMA",
    province: "LU"
  },
  {
    code: "4591",
    value: "L533",
    label: "VAGLI SOTTO",
    province: "LU"
  },
  {
    code: "4593",
    value: "L833",
    label: "VIAREGGIO",
    province: "LU"
  },
  {
    code: "4594",
    value: "L913",
    label: "VILLA BASILICA",
    province: "LU"
  },
  {
    code: "4595",
    value: "L926",
    label: "VILLA COLLEMANDINA",
    province: "LU"
  },
  {
    code: "4597",
    value: "A071",
    label: "AGLIANA",
    province: "PT"
  },
  {
    code: "4598",
    value: "B251",
    label: "BUGGIANO",
    province: "PT"
  },
  {
    code: "4600",
    value: "E432",
    label: "LAMPORECCHIO",
    province: "PT"
  },
  {
    code: "4601",
    value: "E451",
    label: "LARCIANO",
    province: "PT"
  },
  {
    code: "4602",
    value: "E960",
    label: "MARLIANA",
    province: "PT"
  },
  {
    code: "4603",
    value: "F025",
    label: "MASSA E COZZILE",
    province: "PT"
  },
  {
    code: "4604",
    value: "F384",
    label: "MONSUMMANO TERME",
    province: "PT"
  },
  {
    code: "4605",
    value: "F410",
    label: "MONTALE",
    province: "PT"
  },
  {
    code: "4606",
    value: "A561",
    label: "MONTECATINI TERME",
    province: "PT"
  },
  {
    code: "4607",
    value: "G491",
    label: "PESCIA",
    province: "PT"
  },
  {
    code: "4608",
    value: "G636",
    label: "PIEVE A NIEVOLE",
    province: "PT"
  },
  {
    code: "4609",
    value: "G713",
    label: "PISTOIA",
    province: "PT"
  },
  {
    code: "4611",
    value: "G833",
    label: "PONTE BUGGIANESE",
    province: "PT"
  },
  {
    code: "4612",
    value: "H109",
    label: "QUARRATA",
    province: "PT"
  },
  {
    code: "4613",
    value: "H744",
    label: "SAMBUCA PISTOIESE",
    province: "PT"
  },
  {
    code: "4615",
    value: "I660",
    label: "SERRAVALLE PISTOIESE",
    province: "PT"
  },
  {
    code: "4616",
    value: "L522",
    label: "UZZANO",
    province: "PT"
  },
  {
    code: "4617",
    value: "C631",
    label: "CHIESINA UZZANESE",
    province: "PT"
  },
  {
    code: "4618",
    value: "A564",
    label: "BAGNO A RIPOLI",
    province: "FI"
  },
  {
    code: "4619",
    value: "A632",
    label: "BARBERINO DI MUGELLO",
    province: "FI"
  },
  {
    code: "4621",
    value: "B036",
    label: "BORGO SAN LORENZO",
    province: "FI"
  },
  {
    code: "4622",
    value: "B406",
    label: "CALENZANO",
    province: "FI"
  },
  {
    code: "4623",
    value: "B507",
    label: "CAMPI BISENZIO",
    province: "FI"
  },
  {
    code: "4624",
    value: "B684",
    label: "CAPRAIA E LIMITE",
    province: "FI"
  },
  {
    code: "4625",
    value: "C101",
    label: "CASTELFIORENTINO",
    province: "FI"
  },
  {
    code: "4626",
    value: "C529",
    label: "CERRETO GUIDI",
    province: "FI"
  },
  {
    code: "4627",
    value: "C540",
    label: "CERTALDO",
    province: "FI"
  },
  {
    code: "4628",
    value: "D299",
    label: "DICOMANO",
    province: "FI"
  },
  {
    code: "4629",
    value: "D403",
    label: "EMPOLI",
    province: "FI"
  },
  {
    code: "4630",
    value: "D575",
    label: "FIESOLE",
    province: "FI"
  },
  {
    code: "4632",
    value: "D612",
    label: "FIRENZE",
    province: "FI"
  },
  {
    code: "4633",
    value: "D613",
    label: "FIRENZUOLA",
    province: "FI"
  },
  {
    code: "4634",
    value: "D815",
    label: "FUCECCHIO",
    province: "FI"
  },
  {
    code: "4635",
    value: "D895",
    label: "GAMBASSI TERME",
    province: "FI"
  },
  {
    code: "4636",
    value: "E169",
    label: "GREVE IN CHIANTI",
    province: "FI"
  },
  {
    code: "4637",
    value: "E291",
    label: "IMPRUNETA",
    province: "FI"
  },
  {
    code: "4639",
    value: "E466",
    label: "LASTRA A SIGNA",
    province: "FI"
  },
  {
    code: "4640",
    value: "E668",
    label: "LONDA",
    province: "FI"
  },
  {
    code: "4641",
    value: "E971",
    label: "MARRADI",
    province: "FI"
  },
  {
    code: "4642",
    value: "F398",
    label: "MONTAIONE",
    province: "FI"
  },
  {
    code: "4643",
    value: "F551",
    label: "MONTELUPO FIORENTINO",
    province: "FI"
  },
  {
    code: "4644",
    value: "F648",
    label: "MONTESPERTOLI",
    province: "FI"
  },
  {
    code: "4645",
    value: "G270",
    label: "PALAZZUOLO SUL SENIO",
    province: "FI"
  },
  {
    code: "4646",
    value: "G420",
    label: "PELAGO",
    province: "FI"
  },
  {
    code: "4647",
    value: "G825",
    label: "PONTASSIEVE",
    province: "FI"
  },
  {
    code: "4648",
    value: "H222",
    label: "REGGELLO",
    province: "FI"
  },
  {
    code: "4649",
    value: "H286",
    label: "RIGNANO SULL ARNO",
    province: "FI"
  },
  {
    code: "4650",
    value: "H635",
    label: "RUFINA",
    province: "FI"
  },
  {
    code: "4651",
    value: "H791",
    label: "SAN CASCIANO IN VAL DI PESA",
    province: "FI"
  },
  {
    code: "4652",
    value: "H937",
    label: "SAN GODENZO",
    province: "FI"
  },
  {
    code: "4654",
    value: "B962",
    label: "SCANDICCI",
    province: "FI"
  },
  {
    code: "4656",
    value: "I684",
    label: "SESTO FIORENTINO",
    province: "FI"
  },
  {
    code: "4657",
    value: "I728",
    label: "SIGNA",
    province: "FI"
  },
  {
    code: "4659",
    value: "L529",
    label: "VAGLIA",
    province: "FI"
  },
  {
    code: "4660",
    value: "L838",
    label: "VICCHIO",
    province: "FI"
  },
  {
    code: "4661",
    value: "M059",
    label: "VINCI",
    province: "FI"
  },
  {
    code: "4662",
    value: "A852",
    label: "BIBBONA",
    province: "LI"
  },
  {
    code: "4663",
    value: "B509",
    label: "CAMPIGLIA MARITTIMA",
    province: "LI"
  },
  {
    code: "4664",
    value: "B553",
    label: "CAMPO NELL ELBA",
    province: "LI"
  },
  {
    code: "4665",
    value: "B669",
    label: "CAPOLIVERI",
    province: "LI"
  },
  {
    code: "4666",
    value: "B685",
    label: "CAPRAIA ISOLA",
    province: "LI"
  },
  {
    code: "4667",
    value: "C044",
    label: "CASTAGNETO CARDUCCI",
    province: "LI"
  },
  {
    code: "4668",
    value: "C415",
    label: "CECINA",
    province: "LI"
  },
  {
    code: "4669",
    value: "C869",
    label: "COLLESALVETTI",
    province: "LI"
  },
  {
    code: "4670",
    value: "E625",
    label: "LIVORNO",
    province: "LI"
  },
  {
    code: "4671",
    value: "E930",
    label: "MARCIANA",
    province: "LI"
  },
  {
    code: "4672",
    value: "E931",
    label: "MARCIANA MARINA",
    province: "LI"
  },
  {
    code: "4673",
    value: "G687",
    label: "PIOMBINO",
    province: "LI"
  },
  {
    code: "4674",
    value: "E680",
    label: "PORTO AZZURRO",
    province: "LI"
  },
  {
    code: "4675",
    value: "G912",
    label: "PORTOFERRAIO",
    province: "LI"
  },
  {
    code: "4678",
    value: "H570",
    label: "ROSIGNANO MARITTIMO",
    province: "LI"
  },
  {
    code: "4679",
    value: "I390",
    label: "SAN VINCENZO",
    province: "LI"
  },
  {
    code: "4680",
    value: "I454",
    label: "SASSETTA",
    province: "LI"
  },
  {
    code: "4681",
    value: "L019",
    label: "SUVERETO",
    province: "LI"
  },
  {
    code: "4682",
    value: "A864",
    label: "BIENTINA",
    province: "PI"
  },
  {
    code: "4683",
    value: "B303",
    label: "BUTI",
    province: "PI"
  },
  {
    code: "4684",
    value: "B390",
    label: "CALCI",
    province: "PI"
  },
  {
    code: "4685",
    value: "B392",
    label: "CALCINAIA",
    province: "PI"
  },
  {
    code: "4686",
    value: "B647",
    label: "CAPANNOLI",
    province: "PI"
  },
  {
    code: "4687",
    value: "B878",
    label: "CASALE MARITTIMO",
    province: "PI"
  },
  {
    code: "4689",
    value: "B950",
    label: "CASCINA",
    province: "PI"
  },
  {
    code: "4690",
    value: "C113",
    label: "CASTELFRANCO DI SOTTO",
    province: "PI"
  },
  {
    code: "4691",
    value: "C174",
    label: "CASTELLINA MARITTIMA",
    province: "PI"
  },
  {
    code: "4692",
    value: "C244",
    label: "CASTELNUOVO DI VAL DI CECINA",
    province: "PI"
  },
  {
    code: "4693",
    value: "C609",
    label: "CHIANNI",
    province: "PI"
  },
  {
    code: "4695",
    value: "D510",
    label: "FAUGLIA",
    province: "PI"
  },
  {
    code: "4696",
    value: "E250",
    label: "GUARDISTALLO",
    province: "PI"
  },
  {
    code: "4697",
    value: "E413",
    label: "LAJATICO",
    province: "PI"
  },
  {
    code: "4700",
    value: "F458",
    label: "MONTECATINI VAL DI CECINA",
    province: "PI"
  },
  {
    code: "4701",
    value: "F640",
    label: "MONTESCUDAIO",
    province: "PI"
  },
  {
    code: "4702",
    value: "F661",
    label: "MONTEVERDI MARITTIMO",
    province: "PI"
  },
  {
    code: "4703",
    value: "F686",
    label: "MONTOPOLI IN VAL D ARNO",
    province: "PI"
  },
  {
    code: "4704",
    value: "G090",
    label: "ORCIANO PISANO",
    province: "PI"
  },
  {
    code: "4705",
    value: "G254",
    label: "PALAIA",
    province: "PI"
  },
  {
    code: "4706",
    value: "G395",
    label: "PECCIOLI",
    province: "PI"
  },
  {
    code: "4707",
    value: "G702",
    label: "PISA",
    province: "PI"
  },
  {
    code: "4708",
    value: "G804",
    label: "POMARANCE",
    province: "PI"
  },
  {
    code: "4709",
    value: "G822",
    label: "PONSACCO",
    province: "PI"
  },
  {
    code: "4710",
    value: "G843",
    label: "PONTEDERA",
    province: "PI"
  },
  {
    code: "4711",
    value: "H319",
    label: "RIPARBELLA",
    province: "PI"
  },
  {
    code: "4712",
    value: "A562",
    label: "SAN GIULIANO TERME",
    province: "PI"
  },
  {
    code: "4713",
    value: "I046",
    label: "SAN MINIATO",
    province: "PI"
  },
  {
    code: "4714",
    value: "I177",
    label: "SANTA CROCE SULL ARNO",
    province: "PI"
  },
  {
    code: "4715",
    value: "I217",
    label: "SANTA LUCE",
    province: "PI"
  },
  {
    code: "4716",
    value: "I232",
    label: "SANTA MARIA A MONTE",
    province: "PI"
  },
  {
    code: "4717",
    value: "L138",
    label: "TERRICCIOLA",
    province: "PI"
  },
  {
    code: "4718",
    value: "L702",
    label: "VECCHIANO",
    province: "PI"
  },
  {
    code: "4719",
    value: "L850",
    label: "VICOPISANO",
    province: "PI"
  },
  {
    code: "4720",
    value: "M126",
    label: "VOLTERRA",
    province: "PI"
  },
  {
    code: "4721",
    value: "A291",
    label: "ANGHIARI",
    province: "AR"
  },
  {
    code: "4722",
    value: "A390",
    label: "AREZZO",
    province: "AR"
  },
  {
    code: "4723",
    value: "A541",
    label: "BADIA TEDALDA",
    province: "AR"
  },
  {
    code: "4724",
    value: "A851",
    label: "BIBBIENA",
    province: "AR"
  },
  {
    code: "4725",
    value: "B243",
    label: "BUCINE",
    province: "AR"
  },
  {
    code: "4726",
    value: "B670",
    label: "CAPOLONA",
    province: "AR"
  },
  {
    code: "4727",
    value: "B693",
    label: "CAPRESE MICHELANGELO",
    province: "AR"
  },
  {
    code: "4728",
    value: "C102",
    label: "CASTEL FOCOGNANO",
    province: "AR"
  },
  {
    code: "4730",
    value: "C263",
    label: "CASTEL SAN NICCOLO",
    province: "AR"
  },
  {
    code: "4731",
    value: "C318",
    label: "CASTIGLION FIBOCCHI",
    province: "AR"
  },
  {
    code: "4732",
    value: "C319",
    label: "CASTIGLION FIORENTINO",
    province: "AR"
  },
  {
    code: "4733",
    value: "C407",
    label: "CAVRIGLIA",
    province: "AR"
  },
  {
    code: "4734",
    value: "C648",
    label: "CHITIGNANO",
    province: "AR"
  },
  {
    code: "4735",
    value: "C663",
    label: "CHIUSI DELLA VERNA",
    province: "AR"
  },
  {
    code: "4736",
    value: "C774",
    label: "CIVITELLA IN VAL DI CHIANA",
    province: "AR"
  },
  {
    code: "4737",
    value: "D077",
    label: "CORTONA",
    province: "AR"
  },
  {
    code: "4738",
    value: "D649",
    label: "FOIANO DELLA CHIANA",
    province: "AR"
  },
  {
    code: "4740",
    value: "E693",
    label: "LORO CIUFFENNA",
    province: "AR"
  },
  {
    code: "4741",
    value: "E718",
    label: "LUCIGNANO",
    province: "AR"
  },
  {
    code: "4742",
    value: "E933",
    label: "MARCIANO DELLA CHIANA",
    province: "AR"
  },
  {
    code: "4743",
    value: "F565",
    label: "MONTEMIGNAIO",
    province: "AR"
  },
  {
    code: "4744",
    value: "F594",
    label: "MONTERCHI",
    province: "AR"
  },
  {
    code: "4745",
    value: "F628",
    label: "MONTE SAN SAVINO",
    province: "AR"
  },
  {
    code: "4746",
    value: "F656",
    label: "MONTEVARCHI",
    province: "AR"
  },
  {
    code: "4940",
    value: "D808",
    label: "FRONTONE",
    province: "PU"
  },
  {
    code: "4941",
    value: "D836",
    label: "GABICCE MARE",
    province: "PU"
  },
  {
    code: "4942",
    value: "E122",
    label: "GRADARA",
    province: "PU"
  },
  {
    code: "4943",
    value: "E351",
    label: "ISOLA DEL PIANO",
    province: "PU"
  },
  {
    code: "4944",
    value: "E743",
    label: "LUNANO",
    province: "PU"
  },
  {
    code: "4945",
    value: "E785",
    label: "MACERATA FELTRIA",
    province: "PU"
  },
  {
    code: "4946",
    value: "E838",
    label: "MAIOLO",
    province: "RN"
  },
  {
    code: "4947",
    value: "F135",
    label: "MERCATELLO SUL METAURO",
    province: "PU"
  },
  {
    code: "4948",
    value: "F136",
    label: "MERCATINO CONCA",
    province: "PU"
  },
  {
    code: "4949",
    value: "F310",
    label: "MOMBAROCCIO",
    province: "PU"
  },
  {
    code: "4950",
    value: "F347",
    label: "MONDAVIO",
    province: "PU"
  },
  {
    code: "4951",
    value: "F348",
    label: "MONDOLFO",
    province: "PU"
  },
  {
    code: "4952",
    value: "F450",
    label: "MONTECALVO IN FOGLIA",
    province: "PU"
  },
  {
    code: "4953",
    value: "F467",
    label: "MONTE CERIGNONE",
    province: "PU"
  },
  {
    code: "4955",
    value: "F478",
    label: "MONTECOPIOLO",
    province: "RN"
  },
  {
    code: "4956",
    value: "F497",
    label: "MONTEFELCINO",
    province: "PU"
  },
  {
    code: "4957",
    value: "F524",
    label: "MONTE GRIMANO TERME",
    province: "PU"
  },
  {
    code: "4958",
    value: "F533",
    label: "MONTELABBATE",
    province: "PU"
  },
  {
    code: "4960",
    value: "F589",
    label: "MONTE PORZIO",
    province: "PU"
  },
  {
    code: "4961",
    value: "F137",
    label: "NOVAFELTRIA",
    province: "RN"
  },
  {
    code: "4963",
    value: "G416",
    label: "PEGLIO",
    province: "PU"
  },
  {
    code: "4964",
    value: "G433",
    label: "PENNABILLI",
    province: "RN"
  },
  {
    code: "4965",
    value: "G453",
    label: "PERGOLA",
    province: "PU"
  },
  {
    code: "4966",
    value: "G479",
    label: "PESARO",
    province: "PU"
  },
  {
    code: "4967",
    value: "G514",
    label: "PETRIANO",
    province: "PU"
  },
  {
    code: "4969",
    value: "G551",
    label: "PIANDIMELETO",
    province: "PU"
  },
  {
    code: "4970",
    value: "G627",
    label: "PIETRARUBBIA",
    province: "PU"
  },
  {
    code: "4971",
    value: "G682",
    label: "PIOBBICO",
    province: "PU"
  },
  {
    code: "4973",
    value: "H809",
    label: "SAN COSTANZO",
    province: "PU"
  },
  {
    code: "4975",
    value: "H949",
    label: "SAN LEO",
    province: "RN"
  },
  {
    code: "4976",
    value: "H958",
    label: "SAN LORENZO IN CAMPO",
    province: "PU"
  },
  {
    code: "4977",
    value: "I201",
    label: "SANT AGATA FELTRIA",
    province: "RN"
  },
  {
    code: "4979",
    value: "I287",
    label: "SANT ANGELO IN VADO",
    province: "PU"
  },
  {
    code: "4980",
    value: "I344",
    label: "SANT IPPOLITO",
    province: "PU"
  },
  {
    code: "4982",
    value: "I460",
    label: "SASSOFELTRIO",
    province: "RN"
  },
  {
    code: "4983",
    value: "I654",
    label: "SERRA SANT ABBONDIO",
    province: "PU"
  },
  {
    code: "4985",
    value: "L034",
    label: "TALAMELLO",
    province: "RN"
  },
  {
    code: "4986",
    value: "L078",
    label: "TAVOLETO",
    province: "PU"
  },
  {
    code: "4987",
    value: "L081",
    label: "TAVULLIA",
    province: "PU"
  },
  {
    code: "4988",
    value: "L498",
    label: "URBANIA",
    province: "PU"
  },
  {
    code: "4989",
    value: "L500",
    label: "URBINO",
    province: "PU"
  },
  {
    code: "4990",
    value: "A092",
    label: "AGUGLIANO",
    province: "AN"
  },
  {
    code: "4991",
    value: "A271",
    label: "ANCONA",
    province: "AN"
  },
  {
    code: "4992",
    value: "A366",
    label: "ARCEVIA",
    province: "AN"
  },
  {
    code: "4993",
    value: "A626",
    label: "BARBARA",
    province: "AN"
  },
  {
    code: "4994",
    value: "A769",
    label: "BELVEDERE OSTRENSE",
    province: "AN"
  },
  {
    code: "4995",
    value: "B468",
    label: "CAMERANO",
    province: "AN"
  },
  {
    code: "4996",
    value: "B470",
    label: "CAMERATA PICENA",
    province: "AN"
  },
  {
    code: "4997",
    value: "C060",
    label: "CASTELBELLINO",
    province: "AN"
  },
  {
    code: "4999",
    value: "C100",
    label: "CASTELFIDARDO",
    province: "AN"
  },
  {
    code: "5000",
    value: "C152",
    label: "CASTELLEONE DI SUASA",
    province: "AN"
  },
  {
    code: "5001",
    value: "C248",
    label: "CASTELPLANIO",
    province: "AN"
  },
  {
    code: "5002",
    value: "C524",
    label: "CERRETO D ESI",
    province: "AN"
  },
  {
    code: "5003",
    value: "C615",
    label: "CHIARAVALLE",
    province: "AN"
  },
  {
    code: "5004",
    value: "D007",
    label: "CORINALDO",
    province: "AN"
  },
  {
    code: "5005",
    value: "D211",
    label: "CUPRAMONTANA",
    province: "AN"
  },
  {
    code: "5006",
    value: "D451",
    label: "FABRIANO",
    province: "AN"
  },
  {
    code: "5007",
    value: "D472",
    label: "FALCONARA MARITTIMA",
    province: "AN"
  },
  {
    code: "5008",
    value: "D597",
    label: "FILOTTRANO",
    province: "AN"
  },
  {
    code: "5009",
    value: "D965",
    label: "GENGA",
    province: "AN"
  },
  {
    code: "5010",
    value: "E388",
    label: "JESI",
    province: "AN"
  },
  {
    code: "5011",
    value: "E690",
    label: "LORETO",
    province: "AN"
  },
  {
    code: "5012",
    value: "E837",
    label: "MAIOLATI SPONTINI",
    province: "AN"
  },
  {
    code: "5013",
    value: "F145",
    label: "MERGO",
    province: "AN"
  },
  {
    code: "5014",
    value: "F381",
    label: "MONSANO",
    province: "AN"
  },
  {
    code: "5015",
    value: "F453",
    label: "MONTECAROTTO",
    province: "AN"
  },
  {
    code: "5016",
    value: "F560",
    label: "MONTEMARCIANO",
    province: "AN"
  },
  {
    code: "5018",
    value: "F600",
    label: "MONTE ROBERTO",
    province: "AN"
  },
  {
    code: "5019",
    value: "F634",
    label: "MONTE SAN VITO",
    province: "AN"
  },
  {
    code: "5020",
    value: "F745",
    label: "MORRO D ALBA",
    province: "AN"
  },
  {
    code: "5021",
    value: "F978",
    label: "NUMANA",
    province: "AN"
  },
  {
    code: "5022",
    value: "G003",
    label: "OFFAGNA",
    province: "AN"
  },
  {
    code: "5023",
    value: "G157",
    label: "OSIMO",
    province: "AN"
  },
  {
    code: "5024",
    value: "F401",
    label: "OSTRA",
    province: "AN"
  },
  {
    code: "5025",
    value: "F581",
    label: "OSTRA VETERE",
    province: "AN"
  },
  {
    code: "5026",
    value: "G771",
    label: "POGGIO SAN MARCELLO",
    province: "AN"
  },
  {
    code: "5027",
    value: "G803",
    label: "POLVERIGI",
    province: "AN"
  },
  {
    code: "5029",
    value: "H575",
    label: "ROSORA",
    province: "AN"
  },
  {
    code: "5030",
    value: "H979",
    label: "SAN MARCELLO",
    province: "AN"
  },
  {
    code: "5031",
    value: "I071",
    label: "SAN PAOLO DI JESI",
    province: "AN"
  },
  {
    code: "5032",
    value: "I251",
    label: "SANTA MARIA NUOVA",
    province: "AN"
  },
  {
    code: "5033",
    value: "I461",
    label: "SASSOFERRATO",
    province: "AN"
  },
  {
    code: "5034",
    value: "I608",
    label: "SENIGALLIA",
    province: "AN"
  },
  {
    code: "6771",
    value: "I549",
    label: "SCORRANO",
    province: "LE"
  },
  {
    code: "6772",
    value: "I559",
    label: "SECLI",
    province: "LE"
  },
  {
    code: "6773",
    value: "I780",
    label: "SOGLIANO CAVOUR",
    province: "LE"
  },
  {
    code: "6774",
    value: "I800",
    label: "SOLETO",
    province: "LE"
  },
  {
    code: "6775",
    value: "I887",
    label: "SPECCHIA",
    province: "LE"
  },
  {
    code: "6776",
    value: "I923",
    label: "SPONGANO",
    province: "LE"
  },
  {
    code: "6777",
    value: "I930",
    label: "SQUINZANO",
    province: "LE"
  },
  {
    code: "6778",
    value: "I950",
    label: "STERNATIA",
    province: "LE"
  },
  {
    code: "6779",
    value: "L008",
    label: "SUPERSANO",
    province: "LE"
  },
  {
    code: "6780",
    value: "L010",
    label: "SURANO",
    province: "LE"
  },
  {
    code: "6781",
    value: "L011",
    label: "SURBO",
    province: "LE"
  },
  {
    code: "6782",
    value: "L064",
    label: "TAURISANO",
    province: "LE"
  },
  {
    code: "6783",
    value: "L074",
    label: "TAVIANO",
    province: "LE"
  },
  {
    code: "6784",
    value: "L166",
    label: "TIGGIANO",
    province: "LE"
  },
  {
    code: "6785",
    value: "L383",
    label: "TREPUZZI",
    province: "LE"
  },
  {
    code: "6786",
    value: "L419",
    label: "TRICASE",
    province: "LE"
  },
  {
    code: "6787",
    value: "L462",
    label: "TUGLIE",
    province: "LE"
  },
  {
    code: "6788",
    value: "L484",
    label: "UGENTO",
    province: "LE"
  },
  {
    code: "6789",
    value: "L485",
    label: "UGGIANO LA CHIESA",
    province: "LE"
  },
  {
    code: "6790",
    value: "L711",
    label: "VEGLIE",
    province: "LE"
  },
  {
    code: "6791",
    value: "L776",
    label: "VERNOLE",
    province: "LE"
  },
  {
    code: "6792",
    value: "M187",
    label: "ZOLLINO",
    province: "LE"
  },
  {
    code: "6793",
    value: "M264",
    label: "SAN CASSIANO",
    province: "LE"
  },
  {
    code: "5035",
    value: "I643",
    label: "SERRA DE CONTI",
    province: "AN"
  },
  {
    code: "5036",
    value: "I653",
    label: "SERRA SAN QUIRICO",
    province: "AN"
  },
  {
    code: "5037",
    value: "I758",
    label: "SIROLO",
    province: "AN"
  },
  {
    code: "5038",
    value: "I932",
    label: "STAFFOLO",
    province: "AN"
  },
  {
    code: "5040",
    value: "A329",
    label: "APIRO",
    province: "MC"
  },
  {
    code: "5041",
    value: "A334",
    label: "APPIGNANO",
    province: "MC"
  },
  {
    code: "5042",
    value: "A739",
    label: "BELFORTE DEL CHIENTI",
    province: "MC"
  },
  {
    code: "5043",
    value: "A947",
    label: "BOLOGNOLA",
    province: "MC"
  },
  {
    code: "5044",
    value: "B398",
    label: "CALDAROLA",
    province: "MC"
  },
  {
    code: "5045",
    value: "B474",
    label: "CAMERINO",
    province: "MC"
  },
  {
    code: "5046",
    value: "B562",
    label: "CAMPOROTONDO DI FIASTRONE",
    province: "MC"
  },
  {
    code: "5047",
    value: "C251",
    label: "CASTELRAIMONDO",
    province: "MC"
  },
  {
    code: "5048",
    value: "C267",
    label: "CASTELSANTANGELO SUL NERA",
    province: "MC"
  },
  {
    code: "5049",
    value: "C582",
    label: "CESSAPALOMBO",
    province: "MC"
  },
  {
    code: "5050",
    value: "C704",
    label: "CINGOLI",
    province: "MC"
  },
  {
    code: "5051",
    value: "C770",
    label: "CIVITANOVA MARCHE",
    province: "MC"
  },
  {
    code: "5052",
    value: "C886",
    label: "COLMURANO",
    province: "MC"
  },
  {
    code: "5053",
    value: "D042",
    label: "CORRIDONIA",
    province: "MC"
  },
  {
    code: "5054",
    value: "D429",
    label: "ESANATOGLIA",
    province: "MC"
  },
  {
    code: "5055",
    value: "D564",
    label: "FIASTRA",
    province: "MC"
  },
  {
    code: "5057",
    value: "D628",
    label: "FIUMINATA",
    province: "MC"
  },
  {
    code: "5058",
    value: "D853",
    label: "GAGLIOLE",
    province: "MC"
  },
  {
    code: "5059",
    value: "E228",
    label: "GUALDO",
    province: "MC"
  },
  {
    code: "5060",
    value: "E694",
    label: "LORO PICENO",
    province: "MC"
  },
  {
    code: "5061",
    value: "E783",
    label: "MACERATA",
    province: "MC"
  },
  {
    code: "5062",
    value: "F051",
    label: "MATELICA",
    province: "MC"
  },
  {
    code: "5063",
    value: "F268",
    label: "MOGLIANO",
    province: "MC"
  },
  {
    code: "5064",
    value: "F454",
    label: "MONTECASSIANO",
    province: "MC"
  },
  {
    code: "5065",
    value: "F460",
    label: "MONTE CAVALLO",
    province: "MC"
  },
  {
    code: "5066",
    value: "F482",
    label: "MONTECOSARO",
    province: "MC"
  },
  {
    code: "5067",
    value: "F496",
    label: "MONTEFANO",
    province: "MC"
  },
  {
    code: "5068",
    value: "F552",
    label: "MONTELUPONE",
    province: "MC"
  },
  {
    code: "5069",
    value: "F621",
    label: "MONTE SAN GIUSTO",
    province: "MC"
  },
  {
    code: "5070",
    value: "F622",
    label: "MONTE SAN MARTINO",
    province: "MC"
  },
  {
    code: "5071",
    value: "F749",
    label: "MORROVALLE",
    province: "MC"
  },
  {
    code: "5072",
    value: "F793",
    label: "MUCCIA",
    province: "MC"
  },
  {
    code: "5073",
    value: "G436",
    label: "PENNA SAN GIOVANNI",
    province: "MC"
  },
  {
    code: "5074",
    value: "G515",
    label: "PETRIOLO",
    province: "MC"
  },
  {
    code: "5076",
    value: "G657",
    label: "PIEVE TORINA",
    province: "MC"
  },
  {
    code: "5077",
    value: "G690",
    label: "PIORACO",
    province: "MC"
  },
  {
    code: "5078",
    value: "D566",
    label: "POGGIO SAN VICINO",
    province: "MC"
  },
  {
    code: "5079",
    value: "F567",
    label: "POLLENZA",
    province: "MC"
  },
  {
    code: "5080",
    value: "G919",
    label: "PORTO RECANATI",
    province: "MC"
  },
  {
    code: "5081",
    value: "F632",
    label: "POTENZA PICENA",
    province: "MC"
  },
  {
    code: "5082",
    value: "H211",
    label: "RECANATI",
    province: "MC"
  },
  {
    code: "5083",
    value: "H323",
    label: "RIPE SAN GINESIO",
    province: "MC"
  },
  {
    code: "5084",
    value: "H876",
    label: "SAN GINESIO",
    province: "MC"
  },
  {
    code: "5085",
    value: "I156",
    label: "SAN SEVERINO MARCHE",
    province: "MC"
  },
  {
    code: "5086",
    value: "I286",
    label: "SANT ANGELO IN PONTANO",
    province: "MC"
  },
  {
    code: "5087",
    value: "I436",
    label: "SARNANO",
    province: "MC"
  },
  {
    code: "5088",
    value: "I569",
    label: "SEFRO",
    province: "MC"
  },
  {
    code: "5089",
    value: "I651",
    label: "SERRAPETRONA",
    province: "MC"
  },
  {
    code: "5090",
    value: "I661",
    label: "SERRAVALLE DI CHIENTI",
    province: "MC"
  },
  {
    code: "5091",
    value: "L191",
    label: "TOLENTINO",
    province: "MC"
  },
  {
    code: "5092",
    value: "L366",
    label: "TREIA",
    province: "MC"
  },
  {
    code: "5093",
    value: "L501",
    label: "URBISAGLIA",
    province: "MC"
  },
  {
    code: "5094",
    value: "L517",
    label: "USSITA",
    province: "MC"
  },
  {
    code: "5095",
    value: "M078",
    label: "VISSO",
    province: "MC"
  },
  {
    code: "5096",
    value: "A044",
    label: "ACQUASANTA TERME",
    province: "AP"
  },
  {
    code: "5097",
    value: "A047",
    label: "ACQUAVIVA PICENA",
    province: "AP"
  },
  {
    code: "5098",
    value: "A233",
    label: "ALTIDONA",
    province: "FM"
  },
  {
    code: "5099",
    value: "A252",
    label: "AMANDOLA",
    province: "FM"
  },
  {
    code: "5100",
    value: "A335",
    label: "APPIGNANO DEL TRONTO",
    province: "AP"
  },
  {
    code: "5101",
    value: "A437",
    label: "ARQUATA DEL TRONTO",
    province: "AP"
  },
  {
    code: "5102",
    value: "A462",
    label: "ASCOLI PICENO",
    province: "AP"
  },
  {
    code: "5103",
    value: "A760",
    label: "BELMONTE PICENO",
    province: "FM"
  },
  {
    code: "5104",
    value: "B534",
    label: "CAMPOFILONE",
    province: "FM"
  },
  {
    code: "5105",
    value: "B727",
    label: "CARASSAI",
    province: "AP"
  },
  {
    code: "5106",
    value: "C093",
    label: "CASTEL DI LAMA",
    province: "AP"
  },
  {
    code: "5107",
    value: "C321",
    label: "CASTIGNANO",
    province: "AP"
  },
  {
    code: "5108",
    value: "C331",
    label: "CASTORANO",
    province: "AP"
  },
  {
    code: "5109",
    value: "C877",
    label: "COLLI DEL TRONTO",
    province: "AP"
  },
  {
    code: "5110",
    value: "C935",
    label: "COMUNANZA",
    province: "AP"
  },
  {
    code: "5111",
    value: "D096",
    label: "COSSIGNANO",
    province: "AP"
  },
  {
    code: "5112",
    value: "D210",
    label: "CUPRA MARITTIMA",
    province: "AP"
  },
  {
    code: "5113",
    value: "D477",
    label: "FALERONE",
    province: "FM"
  },
  {
    code: "5114",
    value: "D542",
    label: "FERMO",
    province: "FM"
  },
  {
    code: "5115",
    value: "D652",
    label: "FOLIGNANO",
    province: "AP"
  },
  {
    code: "5116",
    value: "D691",
    label: "FORCE",
    province: "AP"
  },
  {
    code: "5117",
    value: "D760",
    label: "FRANCAVILLA D ETE",
    province: "FM"
  },
  {
    code: "5118",
    value: "E207",
    label: "GROTTAMMARE",
    province: "AP"
  },
  {
    code: "5119",
    value: "E208",
    label: "GROTTAZZOLINA",
    province: "FM"
  },
  {
    code: "5120",
    value: "E447",
    label: "LAPEDONA",
    province: "FM"
  },
  {
    code: "5121",
    value: "E807",
    label: "MAGLIANO DI TENNA",
    province: "FM"
  },
  {
    code: "5122",
    value: "E868",
    label: "MALTIGNANO",
    province: "AP"
  },
  {
    code: "5123",
    value: "F021",
    label: "MASSA FERMANA",
    province: "FM"
  },
  {
    code: "5124",
    value: "F044",
    label: "MASSIGNANO",
    province: "AP"
  },
  {
    code: "5125",
    value: "F379",
    label: "MONSAMPIETRO MORICO",
    province: "FM"
  },
  {
    code: "5126",
    value: "F380",
    label: "MONSAMPOLO DEL TRONTO",
    province: "AP"
  },
  {
    code: "5127",
    value: "F415",
    label: "MONTALTO DELLE MARCHE",
    province: "AP"
  },
  {
    code: "5128",
    value: "F428",
    label: "MONTAPPONE",
    province: "FM"
  },
  {
    code: "5129",
    value: "F487",
    label: "MONTEDINOVE",
    province: "AP"
  },
  {
    code: "5130",
    value: "F493",
    label: "MONTEFALCONE APPENNINO",
    province: "FM"
  },
  {
    code: "5131",
    value: "F501",
    label: "MONTEFIORE DELL ASO",
    province: "AP"
  },
  {
    code: "5132",
    value: "F509",
    label: "MONTEFORTINO",
    province: "FM"
  },
  {
    code: "5133",
    value: "F516",
    label: "MONTEGALLO",
    province: "AP"
  },
  {
    code: "5134",
    value: "F517",
    label: "MONTE GIBERTO",
    province: "FM"
  },
  {
    code: "5135",
    value: "F520",
    label: "MONTEGIORGIO",
    province: "FM"
  },
  {
    code: "5136",
    value: "F522",
    label: "MONTEGRANARO",
    province: "FM"
  },
  {
    code: "5137",
    value: "F536",
    label: "MONTELEONE DI FERMO",
    province: "FM"
  },
  {
    code: "5138",
    value: "F549",
    label: "MONTELPARO",
    province: "FM"
  },
  {
    code: "5139",
    value: "F570",
    label: "MONTEMONACO",
    province: "AP"
  },
  {
    code: "5140",
    value: "F591",
    label: "MONTEPRANDONE",
    province: "AP"
  },
  {
    code: "5141",
    value: "F599",
    label: "MONTE RINALDO",
    province: "FM"
  },
  {
    code: "5142",
    value: "F614",
    label: "MONTERUBBIANO",
    province: "FM"
  },
  {
    code: "5143",
    value: "F626",
    label: "MONTE SAN PIETRANGELI",
    province: "FM"
  },
  {
    code: "5144",
    value: "F653",
    label: "MONTE URANO",
    province: "FM"
  },
  {
    code: "5145",
    value: "F664",
    label: "MONTE VIDON COMBATTE",
    province: "FM"
  },
  {
    code: "5146",
    value: "F665",
    label: "MONTE VIDON CORRADO",
    province: "FM"
  },
  {
    code: "5147",
    value: "F697",
    label: "MONTOTTONE",
    province: "FM"
  },
  {
    code: "5148",
    value: "F722",
    label: "MORESCO",
    province: "FM"
  },
  {
    code: "5149",
    value: "G005",
    label: "OFFIDA",
    province: "AP"
  },
  {
    code: "5150",
    value: "G137",
    label: "ORTEZZANO",
    province: "FM"
  },
  {
    code: "5151",
    value: "G289",
    label: "PALMIANO",
    province: "AP"
  },
  {
    code: "5152",
    value: "G403",
    label: "PEDASO",
    province: "FM"
  },
  {
    code: "5153",
    value: "G516",
    label: "PETRITOLI",
    province: "FM"
  },
  {
    code: "5154",
    value: "G873",
    label: "PONZANO DI FERMO",
    province: "FM"
  },
  {
    code: "5155",
    value: "G920",
    label: "PORTO SAN GIORGIO",
    province: "FM"
  },
  {
    code: "5156",
    value: "G921",
    label: "PORTO SANT ELPIDIO",
    province: "FM"
  },
  {
    code: "5157",
    value: "H182",
    label: "RAPAGNANO",
    province: "FM"
  },
  {
    code: "5158",
    value: "H321",
    label: "RIPATRANSONE",
    province: "AP"
  },
  {
    code: "5159",
    value: "H390",
    label: "ROCCAFLUVIONE",
    province: "AP"
  },
  {
    code: "5160",
    value: "H588",
    label: "ROTELLA",
    province: "AP"
  },
  {
    code: "5161",
    value: "H769",
    label: "SAN BENEDETTO DEL TRONTO",
    province: "AP"
  },
  {
    code: "5162",
    value: "I315",
    label: "SANTA VITTORIA IN MATENANO",
    province: "FM"
  },
  {
    code: "5163",
    value: "I324",
    label: "SANT ELPIDIO A MARE",
    province: "FM"
  },
  {
    code: "5164",
    value: "C070",
    label: "SERVIGLIANO",
    province: "FM"
  },
  {
    code: "5165",
    value: "I774",
    label: "SMERILLO",
    province: "FM"
  },
  {
    code: "5166",
    value: "I912",
    label: "SPINETOLI",
    province: "AP"
  },
  {
    code: "5167",
    value: "L279",
    label: "TORRE SAN PATRIZIO",
    province: "FM"
  },
  {
    code: "5168",
    value: "L728",
    label: "VENAROTTA",
    province: "AP"
  },
  {
    code: "5169",
    value: "A040",
    label: "ACQUAPENDENTE",
    province: "VT"
  },
  {
    code: "5170",
    value: "A412",
    label: "ARLENA DI CASTRO",
    province: "VT"
  },
  {
    code: "5171",
    value: "A577",
    label: "BAGNOREGIO",
    province: "VT"
  },
  {
    code: "5172",
    value: "A628",
    label: "BARBARANO ROMANO",
    province: "VT"
  },
  {
    code: "5173",
    value: "A704",
    label: "BASSANO ROMANO",
    province: "VT"
  },
  {
    code: "5174",
    value: "A706",
    label: "BASSANO IN TEVERINA",
    province: "VT"
  },
  {
    code: "5175",
    value: "A857",
    label: "BLERA",
    province: "VT"
  },
  {
    code: "5176",
    value: "A949",
    label: "BOLSENA",
    province: "VT"
  },
  {
    code: "5177",
    value: "A955",
    label: "BOMARZO",
    province: "VT"
  },
  {
    code: "5178",
    value: "B388",
    label: "CALCATA",
    province: "VT"
  },
  {
    code: "5179",
    value: "B597",
    label: "CANEPINA",
    province: "VT"
  },
  {
    code: "5180",
    value: "B604",
    label: "CANINO",
    province: "VT"
  },
  {
    code: "5181",
    value: "B663",
    label: "CAPODIMONTE",
    province: "VT"
  },
  {
    code: "5182",
    value: "B688",
    label: "CAPRANICA",
    province: "VT"
  },
  {
    code: "5183",
    value: "B691",
    label: "CAPRAROLA",
    province: "VT"
  },
  {
    code: "5184",
    value: "B735",
    label: "CARBOGNANO",
    province: "VT"
  },
  {
    code: "5185",
    value: "C269",
    label: "CASTEL SANT ELIA",
    province: "VT"
  },
  {
    code: "5186",
    value: "C315",
    label: "CASTIGLIONE IN TEVERINA",
    province: "VT"
  },
  {
    code: "5187",
    value: "C446",
    label: "CELLENO",
    province: "VT"
  },
  {
    code: "5188",
    value: "C447",
    label: "CELLERE",
    province: "VT"
  },
  {
    code: "5189",
    value: "C765",
    label: "CIVITA CASTELLANA",
    province: "VT"
  },
  {
    code: "5190",
    value: "C780",
    label: "CIVITELLA D AGLIANO",
    province: "VT"
  },
  {
    code: "5191",
    value: "C988",
    label: "CORCHIANO",
    province: "VT"
  },
  {
    code: "5192",
    value: "D452",
    label: "FABRICA DI ROMA",
    province: "VT"
  },
  {
    code: "5193",
    value: "D475",
    label: "FALERIA",
    province: "VT"
  },
  {
    code: "5194",
    value: "D503",
    label: "FARNESE",
    province: "VT"
  },
  {
    code: "5195",
    value: "D870",
    label: "GALLESE",
    province: "VT"
  },
  {
    code: "5196",
    value: "E126",
    label: "GRADOLI",
    province: "VT"
  },
  {
    code: "5197",
    value: "E128",
    label: "GRAFFIGNANO",
    province: "VT"
  },
  {
    code: "6794",
    value: "M261",
    label: "CASTRO",
    province: "LE"
  },
  {
    code: "6795",
    value: "M263",
    label: "PORTO CESAREO",
    province: "LE"
  },
  {
    code: "6796",
    value: "A013",
    label: "ABRIOLA",
    province: "PZ"
  },
  {
    code: "6797",
    value: "A020",
    label: "ACERENZA",
    province: "PZ"
  },
  {
    code: "6798",
    value: "A131",
    label: "ALBANO DI LUCANIA",
    province: "PZ"
  },
  {
    code: "6799",
    value: "A321",
    label: "ANZI",
    province: "PZ"
  },
  {
    code: "6800",
    value: "A415",
    label: "ARMENTO",
    province: "PZ"
  },
  {
    code: "6801",
    value: "A482",
    label: "ATELLA",
    province: "PZ"
  },
  {
    code: "6802",
    value: "A519",
    label: "AVIGLIANO",
    province: "PZ"
  },
  {
    code: "6803",
    value: "A604",
    label: "BALVANO",
    province: "PZ"
  },
  {
    code: "6804",
    value: "A612",
    label: "BANZI",
    province: "PZ"
  },
  {
    code: "6805",
    value: "A615",
    label: "BARAGIANO",
    province: "PZ"
  },
  {
    code: "6806",
    value: "A666",
    label: "BARILE",
    province: "PZ"
  },
  {
    code: "6807",
    value: "A743",
    label: "BELLA",
    province: "PZ"
  },
  {
    code: "6808",
    value: "B173",
    label: "BRIENZA",
    province: "PZ"
  },
  {
    code: "6809",
    value: "B181",
    label: "BRINDISI MONTAGNA",
    province: "PZ"
  },
  {
    code: "6810",
    value: "B440",
    label: "CALVELLO",
    province: "PZ"
  },
  {
    code: "6811",
    value: "B443",
    label: "CALVERA",
    province: "PZ"
  },
  {
    code: "6812",
    value: "B549",
    label: "CAMPOMAGGIORE",
    province: "PZ"
  },
  {
    code: "6813",
    value: "B580",
    label: "CANCELLARA",
    province: "PZ"
  },
  {
    code: "6814",
    value: "B743",
    label: "CARBONE",
    province: "PZ"
  },
  {
    code: "6815",
    value: "B906",
    label: "SAN PAOLO ALBANESE",
    province: "PZ"
  },
  {
    code: "6816",
    value: "C120",
    label: "CASTELGRANDE",
    province: "PZ"
  },
  {
    code: "6817",
    value: "C199",
    label: "CASTELLUCCIO INFERIORE",
    province: "PZ"
  },
  {
    code: "6818",
    value: "C201",
    label: "CASTELLUCCIO SUPERIORE",
    province: "PZ"
  },
  {
    code: "6819",
    value: "C209",
    label: "CASTELMEZZANO",
    province: "PZ"
  },
  {
    code: "6820",
    value: "C271",
    label: "CASTELSARACENO",
    province: "PZ"
  },
  {
    code: "6821",
    value: "C345",
    label: "CASTRONUOVO DI SANT ANDREA",
    province: "PZ"
  },
  {
    code: "6822",
    value: "C539",
    label: "CERSOSIMO",
    province: "PZ"
  },
  {
    code: "6823",
    value: "C619",
    label: "CHIAROMONTE",
    province: "PZ"
  },
  {
    code: "6824",
    value: "D010",
    label: "CORLETO PERTICARA",
    province: "PZ"
  },
  {
    code: "6825",
    value: "D414",
    label: "EPISCOPIA",
    province: "PZ"
  },
  {
    code: "6826",
    value: "D497",
    label: "FARDELLA",
    province: "PZ"
  },
  {
    code: "6827",
    value: "D593",
    label: "FILIANO",
    province: "PZ"
  },
  {
    code: "6828",
    value: "D696",
    label: "FORENZA",
    province: "PZ"
  },
  {
    code: "6829",
    value: "D766",
    label: "FRANCAVILLA IN SINNI",
    province: "PZ"
  },
  {
    code: "6830",
    value: "D876",
    label: "GALLICCHIO",
    province: "PZ"
  },
  {
    code: "6831",
    value: "D971",
    label: "GENZANO DI LUCANIA",
    province: "PZ"
  },
  {
    code: "6832",
    value: "E221",
    label: "GRUMENTO NOVA",
    province: "PZ"
  },
  {
    code: "6833",
    value: "E246",
    label: "GUARDIA PERTICARA",
    province: "PZ"
  },
  {
    code: "6834",
    value: "E409",
    label: "LAGONEGRO",
    province: "PZ"
  },
  {
    code: "6835",
    value: "E474",
    label: "LATRONICO",
    province: "PZ"
  },
  {
    code: "6836",
    value: "E482",
    label: "LAURENZANA",
    province: "PZ"
  },
  {
    code: "6837",
    value: "E483",
    label: "LAURIA",
    province: "PZ"
  },
  {
    code: "6838",
    value: "E493",
    label: "LAVELLO",
    province: "PZ"
  },
  {
    code: "6839",
    value: "E919",
    label: "MARATEA",
    province: "PZ"
  },
  {
    code: "6840",
    value: "E976",
    label: "MARSICO NUOVO",
    province: "PZ"
  },
  {
    code: "6841",
    value: "E977",
    label: "MARSICOVETERE",
    province: "PZ"
  },
  {
    code: "6842",
    value: "F006",
    label: "MASCHITO",
    province: "PZ"
  },
  {
    code: "6843",
    value: "F104",
    label: "MELFI",
    province: "PZ"
  },
  {
    code: "6844",
    value: "F249",
    label: "MISSANELLO",
    province: "PZ"
  },
  {
    code: "6845",
    value: "F295",
    label: "MOLITERNO",
    province: "PZ"
  },
  {
    code: "6846",
    value: "F568",
    label: "MONTEMILONE",
    province: "PZ"
  },
  {
    code: "6847",
    value: "F573",
    label: "MONTEMURRO",
    province: "PZ"
  },
  {
    code: "6848",
    value: "F817",
    label: "MURO LUCANO",
    province: "PZ"
  },
  {
    code: "6849",
    value: "F866",
    label: "NEMOLI",
    province: "PZ"
  },
  {
    code: "6850",
    value: "F917",
    label: "NOEPOLI",
    province: "PZ"
  },
  {
    code: "6851",
    value: "G081",
    label: "OPPIDO LUCANO",
    province: "PZ"
  },
  {
    code: "6852",
    value: "G261",
    label: "PALAZZO SAN GERVASIO",
    province: "PZ"
  },
  {
    code: "6853",
    value: "G496",
    label: "PESCOPAGANO",
    province: "PZ"
  },
  {
    code: "6854",
    value: "G590",
    label: "PICERNO",
    province: "PZ"
  },
  {
    code: "6855",
    value: "G616",
    label: "PIETRAGALLA",
    province: "PZ"
  },
  {
    code: "6856",
    value: "G623",
    label: "PIETRAPERTOSA",
    province: "PZ"
  },
  {
    code: "6857",
    value: "G663",
    label: "PIGNOLA",
    province: "PZ"
  },
  {
    code: "6858",
    value: "G942",
    label: "POTENZA",
    province: "PZ"
  },
  {
    code: "6859",
    value: "H186",
    label: "RAPOLLA",
    province: "PZ"
  },
  {
    code: "6860",
    value: "H187",
    label: "RAPONE",
    province: "PZ"
  },
  {
    code: "6861",
    value: "H307",
    label: "RIONERO IN VULTURE",
    province: "PZ"
  },
  {
    code: "6862",
    value: "H312",
    label: "RIPACANDIDA",
    province: "PZ"
  },
  {
    code: "6863",
    value: "H348",
    label: "RIVELLO",
    province: "PZ"
  },
  {
    code: "6864",
    value: "H426",
    label: "ROCCANOVA",
    province: "PZ"
  },
  {
    code: "6865",
    value: "H590",
    label: "ROTONDA",
    province: "PZ"
  },
  {
    code: "6866",
    value: "H641",
    label: "RUOTI",
    province: "PZ"
  },
  {
    code: "6867",
    value: "H646",
    label: "RUVO DEL MONTE",
    province: "PZ"
  },
  {
    code: "6868",
    value: "H795",
    label: "SAN CHIRICO NUOVO",
    province: "PZ"
  },
  {
    code: "6869",
    value: "H796",
    label: "SAN CHIRICO RAPARO",
    province: "PZ"
  },
  {
    code: "6870",
    value: "H808",
    label: "SAN COSTANTINO ALBANESE",
    province: "PZ"
  },
  {
    code: "6871",
    value: "H831",
    label: "SAN FELE",
    province: "PZ"
  },
  {
    code: "6872",
    value: "H994",
    label: "SAN MARTINO D AGRI",
    province: "PZ"
  },
  {
    code: "6873",
    value: "I157",
    label: "SAN SEVERINO LUCANO",
    province: "PZ"
  },
  {
    code: "6874",
    value: "I288",
    label: "SANT ANGELO LE FRATTE",
    province: "PZ"
  },
  {
    code: "6875",
    value: "I305",
    label: "SANT ARCANGELO",
    province: "PZ"
  },
  {
    code: "6876",
    value: "I426",
    label: "SARCONI",
    province: "PZ"
  },
  {
    code: "5198",
    value: "E210",
    label: "GROTTE DI CASTRO",
    province: "VT"
  },
  {
    code: "5199",
    value: "E330",
    label: "ISCHIA DI CASTRO",
    province: "VT"
  },
  {
    code: "5200",
    value: "E467",
    label: "LATERA",
    province: "VT"
  },
  {
    code: "5201",
    value: "E713",
    label: "LUBRIANO",
    province: "VT"
  },
  {
    code: "5202",
    value: "E978",
    label: "MARTA",
    province: "VT"
  },
  {
    code: "5203",
    value: "F419",
    label: "MONTALTO DI CASTRO",
    province: "VT"
  },
  {
    code: "5204",
    value: "F499",
    label: "MONTEFIASCONE",
    province: "VT"
  },
  {
    code: "5205",
    value: "F603",
    label: "MONTE ROMANO",
    province: "VT"
  },
  {
    code: "5206",
    value: "F606",
    label: "MONTEROSI",
    province: "VT"
  },
  {
    code: "5207",
    value: "F868",
    label: "NEPI",
    province: "VT"
  },
  {
    code: "5208",
    value: "G065",
    label: "ONANO",
    province: "VT"
  },
  {
    code: "5209",
    value: "G111",
    label: "ORIOLO ROMANO",
    province: "VT"
  },
  {
    code: "5210",
    value: "G135",
    label: "ORTE",
    province: "VT"
  },
  {
    code: "5211",
    value: "G571",
    label: "PIANSANO",
    province: "VT"
  },
  {
    code: "5212",
    value: "H071",
    label: "PROCENO",
    province: "VT"
  },
  {
    code: "5213",
    value: "H534",
    label: "RONCIGLIONE",
    province: "VT"
  },
  {
    code: "5214",
    value: "H913",
    label: "VILLA SAN GIOVANNI IN TUSCIA",
    province: "VT"
  },
  {
    code: "5215",
    value: "H969",
    label: "SAN LORENZO NUOVO",
    province: "VT"
  },
  {
    code: "5216",
    value: "I855",
    label: "SORIANO NEL CIMINO",
    province: "VT"
  },
  {
    code: "5217",
    value: "L017",
    label: "SUTRI",
    province: "VT"
  },
  {
    code: "5218",
    value: "D024",
    label: "TARQUINIA",
    province: "VT"
  },
  {
    code: "5219",
    value: "L150",
    label: "TESSENNANO",
    province: "VT"
  },
  {
    code: "5220",
    value: "L310",
    label: "TUSCANIA",
    province: "VT"
  },
  {
    code: "5221",
    value: "L569",
    label: "VALENTANO",
    province: "VT"
  },
  {
    code: "5222",
    value: "L612",
    label: "VALLERANO",
    province: "VT"
  },
  {
    code: "5223",
    value: "A701",
    label: "VASANELLO",
    province: "VT"
  },
  {
    code: "5224",
    value: "L713",
    label: "VEJANO",
    province: "VT"
  },
  {
    code: "5225",
    value: "L814",
    label: "VETRALLA",
    province: "VT"
  },
  {
    code: "5226",
    value: "L882",
    label: "VIGNANELLO",
    province: "VT"
  },
  {
    code: "5227",
    value: "M082",
    label: "VITERBO",
    province: "VT"
  },
  {
    code: "5228",
    value: "M086",
    label: "VITORCHIANO",
    province: "VT"
  },
  {
    code: "5229",
    value: "A019",
    label: "ACCUMOLI",
    province: "RI"
  },
  {
    code: "5230",
    value: "A258",
    label: "AMATRICE",
    province: "RI"
  },
  {
    code: "5231",
    value: "A315",
    label: "ANTRODOCO",
    province: "RI"
  },
  {
    code: "5232",
    value: "A464",
    label: "ASCREA",
    province: "RI"
  },
  {
    code: "5233",
    value: "A765",
    label: "BELMONTE IN SABINA",
    province: "RI"
  },
  {
    code: "5234",
    value: "A981",
    label: "BORBONA",
    province: "RI"
  },
  {
    code: "5235",
    value: "B008",
    label: "BORGOROSE",
    province: "RI"
  },
  {
    code: "5236",
    value: "A996",
    label: "BORGO VELINO",
    province: "RI"
  },
  {
    code: "5237",
    value: "B627",
    label: "CANTALICE",
    province: "RI"
  },
  {
    code: "5238",
    value: "B631",
    label: "CANTALUPO IN SABINA",
    province: "RI"
  },
  {
    code: "5239",
    value: "B934",
    label: "CASAPROTA",
    province: "RI"
  },
  {
    code: "5240",
    value: "A472",
    label: "CASPERIA",
    province: "RI"
  },
  {
    code: "5241",
    value: "C098",
    label: "CASTEL DI TORA",
    province: "RI"
  },
  {
    code: "5242",
    value: "C224",
    label: "CASTELNUOVO DI FARFA",
    province: "RI"
  },
  {
    code: "5243",
    value: "C268",
    label: "CASTEL SANT ANGELO",
    province: "RI"
  },
  {
    code: "5244",
    value: "C746",
    label: "CITTADUCALE",
    province: "RI"
  },
  {
    code: "5245",
    value: "C749",
    label: "CITTAREALE",
    province: "RI"
  },
  {
    code: "5246",
    value: "C841",
    label: "COLLALTO SABINO",
    province: "RI"
  },
  {
    code: "5247",
    value: "C857",
    label: "COLLE DI TORA",
    province: "RI"
  },
  {
    code: "5248",
    value: "C859",
    label: "COLLEGIOVE",
    province: "RI"
  },
  {
    code: "5249",
    value: "C876",
    label: "COLLEVECCHIO",
    province: "RI"
  },
  {
    code: "5250",
    value: "C880",
    label: "COLLI SUL VELINO",
    province: "RI"
  },
  {
    code: "5251",
    value: "C946",
    label: "CONCERVIANO",
    province: "RI"
  },
  {
    code: "5252",
    value: "C959",
    label: "CONFIGNI",
    province: "RI"
  },
  {
    code: "5253",
    value: "C969",
    label: "CONTIGLIANO",
    province: "RI"
  },
  {
    code: "5254",
    value: "D124",
    label: "COTTANELLO",
    province: "RI"
  },
  {
    code: "5255",
    value: "D493",
    label: "FARA IN SABINA",
    province: "RI"
  },
  {
    code: "5256",
    value: "D560",
    label: "FIAMIGNANO",
    province: "RI"
  },
  {
    code: "5257",
    value: "D689",
    label: "FORANO",
    province: "RI"
  },
  {
    code: "5258",
    value: "D785",
    label: "FRASSO SABINO",
    province: "RI"
  },
  {
    code: "5259",
    value: "E160",
    label: "GRECCIO",
    province: "RI"
  },
  {
    code: "5260",
    value: "E393",
    label: "LABRO",
    province: "RI"
  },
  {
    code: "5261",
    value: "E535",
    label: "LEONESSA",
    province: "RI"
  },
  {
    code: "5262",
    value: "E681",
    label: "LONGONE SABINO",
    province: "RI"
  },
  {
    code: "5263",
    value: "E812",
    label: "MAGLIANO SABINA",
    province: "RI"
  },
  {
    code: "5264",
    value: "E927",
    label: "MARCETELLI",
    province: "RI"
  },
  {
    code: "5265",
    value: "F193",
    label: "MICIGLIANO",
    province: "RI"
  },
  {
    code: "5266",
    value: "F319",
    label: "MOMPEO",
    province: "RI"
  },
  {
    code: "5267",
    value: "F430",
    label: "MONTASOLA",
    province: "RI"
  },
  {
    code: "5268",
    value: "F446",
    label: "MONTEBUONO",
    province: "RI"
  },
  {
    code: "5269",
    value: "F541",
    label: "MONTELEONE SABINO",
    province: "RI"
  },
  {
    code: "5270",
    value: "F579",
    label: "MONTENERO SABINO",
    province: "RI"
  },
  {
    code: "5271",
    value: "F619",
    label: "MONTE SAN GIOVANNI IN SABINA",
    province: "RI"
  },
  {
    code: "5272",
    value: "F687",
    label: "MONTOPOLI DI SABINA",
    province: "RI"
  },
  {
    code: "5273",
    value: "F746",
    label: "MORRO REATINO",
    province: "RI"
  },
  {
    code: "5274",
    value: "F876",
    label: "NESPOLO",
    province: "RI"
  },
  {
    code: "5275",
    value: "B595",
    label: "ORVINIO",
    province: "RI"
  },
  {
    code: "5276",
    value: "G232",
    label: "PAGANICO SABINO",
    province: "RI"
  },
  {
    code: "5277",
    value: "G498",
    label: "PESCOROCCHIANO",
    province: "RI"
  },
  {
    code: "5278",
    value: "G513",
    label: "PETRELLA SALTO",
    province: "RI"
  },
  {
    code: "5279",
    value: "G756",
    label: "POGGIO BUSTONE",
    province: "RI"
  },
  {
    code: "5280",
    value: "G757",
    label: "POGGIO CATINO",
    province: "RI"
  },
  {
    code: "5281",
    value: "G763",
    label: "POGGIO MIRTETO",
    province: "RI"
  },
  {
    code: "5282",
    value: "G764",
    label: "POGGIO MOIANO",
    province: "RI"
  },
  {
    code: "5283",
    value: "G765",
    label: "POGGIO NATIVO",
    province: "RI"
  },
  {
    code: "5284",
    value: "G770",
    label: "POGGIO SAN LORENZO",
    province: "RI"
  },
  {
    code: "5285",
    value: "G934",
    label: "POSTA",
    province: "RI"
  },
  {
    code: "5286",
    value: "G951",
    label: "POZZAGLIA SABINA",
    province: "RI"
  },
  {
    code: "5287",
    value: "H282",
    label: "RIETI",
    province: "RI"
  },
  {
    code: "5288",
    value: "H354",
    label: "RIVODUTRI",
    province: "RI"
  },
  {
    code: "5289",
    value: "H427",
    label: "ROCCANTICA",
    province: "RI"
  },
  {
    code: "5290",
    value: "H446",
    label: "ROCCA SINIBALDA",
    province: "RI"
  },
  {
    code: "5291",
    value: "H713",
    label: "SALISANO",
    province: "RI"
  },
  {
    code: "5292",
    value: "I499",
    label: "SCANDRIGLIA",
    province: "RI"
  },
  {
    code: "5293",
    value: "I581",
    label: "SELCI",
    province: "RI"
  },
  {
    code: "5294",
    value: "I959",
    label: "STIMIGLIANO",
    province: "RI"
  },
  {
    code: "5295",
    value: "L046",
    label: "TARANO",
    province: "RI"
  },
  {
    code: "5296",
    value: "L189",
    label: "TOFFIA",
    province: "RI"
  },
  {
    code: "5297",
    value: "L293",
    label: "TORRICELLA IN SABINA",
    province: "RI"
  },
  {
    code: "5298",
    value: "L286",
    label: "TORRI IN SABINA",
    province: "RI"
  },
  {
    code: "5299",
    value: "G507",
    label: "TURANIA",
    province: "RI"
  },
  {
    code: "5300",
    value: "L525",
    label: "VACONE",
    province: "RI"
  },
  {
    code: "5301",
    value: "L676",
    label: "VARCO SABINO",
    province: "RI"
  },
  {
    code: "5302",
    value: "A062",
    label: "AFFILE",
    province: "RM"
  },
  {
    code: "5303",
    value: "A084",
    label: "AGOSTA",
    province: "RM"
  },
  {
    code: "5304",
    value: "A132",
    label: "ALBANO LAZIALE",
    province: "RM"
  },
  {
    code: "5305",
    value: "A210",
    label: "ALLUMIERE",
    province: "RM"
  },
  {
    code: "5306",
    value: "A297",
    label: "ANGUILLARA SABAZIA",
    province: "RM"
  },
  {
    code: "5307",
    value: "A309",
    label: "ANTICOLI CORRADO",
    province: "RM"
  },
  {
    code: "5308",
    value: "A323",
    label: "ANZIO",
    province: "RM"
  },
  {
    code: "5309",
    value: "A370",
    label: "ARCINAZZO ROMANO",
    province: "RM"
  },
  {
    code: "5310",
    value: "A401",
    label: "ARICCIA",
    province: "RM"
  },
  {
    code: "5311",
    value: "A446",
    label: "ARSOLI",
    province: "RM"
  },
  {
    code: "5312",
    value: "A449",
    label: "ARTENA",
    province: "RM"
  },
  {
    code: "5313",
    value: "A749",
    label: "BELLEGRA",
    province: "RM"
  },
  {
    code: "5314",
    value: "B114",
    label: "BRACCIANO",
    province: "RM"
  },
  {
    code: "5315",
    value: "B472",
    label: "CAMERATA NUOVA",
    province: "RM"
  },
  {
    code: "5316",
    value: "B496",
    label: "CAMPAGNANO DI ROMA",
    province: "RM"
  },
  {
    code: "5317",
    value: "B576",
    label: "CANALE MONTERANO",
    province: "RM"
  },
  {
    code: "5318",
    value: "B635",
    label: "CANTERANO",
    province: "RM"
  },
  {
    code: "5319",
    value: "B649",
    label: "CAPENA",
    province: "RM"
  },
  {
    code: "5320",
    value: "B687",
    label: "CAPRANICA PRENESTINA",
    province: "RM"
  },
  {
    code: "5321",
    value: "B828",
    label: "CARPINETO ROMANO",
    province: "RM"
  },
  {
    code: "5322",
    value: "B932",
    label: "CASAPE",
    province: "RM"
  },
  {
    code: "5323",
    value: "C116",
    label: "CASTEL GANDOLFO",
    province: "RM"
  },
  {
    code: "5324",
    value: "C203",
    label: "CASTEL MADAMA",
    province: "RM"
  },
  {
    code: "5325",
    value: "C237",
    label: "CASTELNUOVO DI PORTO",
    province: "RM"
  },
  {
    code: "5326",
    value: "C266",
    label: "CASTEL SAN PIETRO ROMANO",
    province: "RM"
  },
  {
    code: "5327",
    value: "C390",
    label: "CAVE",
    province: "RM"
  },
  {
    code: "5328",
    value: "C518",
    label: "CERRETO LAZIALE",
    province: "RM"
  },
  {
    code: "5329",
    value: "C543",
    label: "CERVARA DI ROMA",
    province: "RM"
  },
  {
    code: "5330",
    value: "C552",
    label: "CERVETERI",
    province: "RM"
  },
  {
    code: "5331",
    value: "C677",
    label: "CICILIANO",
    province: "RM"
  },
  {
    code: "5332",
    value: "C702",
    label: "CINETO ROMANO",
    province: "RM"
  },
  {
    code: "5333",
    value: "C773",
    label: "CIVITAVECCHIA",
    province: "RM"
  },
  {
    code: "5334",
    value: "C784",
    label: "CIVITELLA SAN PAOLO",
    province: "RM"
  },
  {
    code: "5335",
    value: "C858",
    label: "COLLEFERRO",
    province: "RM"
  },
  {
    code: "5336",
    value: "C900",
    label: "COLONNA",
    province: "RM"
  },
  {
    code: "5337",
    value: "D561",
    label: "FIANO ROMANO",
    province: "RM"
  },
  {
    code: "5338",
    value: "D586",
    label: "FILACCIANO",
    province: "RM"
  },
  {
    code: "5339",
    value: "D707",
    label: "FORMELLO",
    province: "RM"
  },
  {
    code: "5340",
    value: "D773",
    label: "FRASCATI",
    province: "RM"
  },
  {
    code: "5341",
    value: "D875",
    label: "GALLICANO NEL LAZIO",
    province: "RM"
  },
  {
    code: "5342",
    value: "D945",
    label: "GAVIGNANO",
    province: "RM"
  },
  {
    code: "5343",
    value: "D964",
    label: "GENAZZANO",
    province: "RM"
  },
  {
    code: "5344",
    value: "D972",
    label: "GENZANO DI ROMA",
    province: "RM"
  },
  {
    code: "5345",
    value: "D978",
    label: "GERANO",
    province: "RM"
  },
  {
    code: "5346",
    value: "E091",
    label: "GORGA",
    province: "RM"
  },
  {
    code: "5347",
    value: "E204",
    label: "GROTTAFERRATA",
    province: "RM"
  },
  {
    code: "5348",
    value: "E263",
    label: "GUIDONIA MONTECELIO",
    province: "RM"
  },
  {
    code: "5349",
    value: "E382",
    label: "JENNE",
    province: "RM"
  },
  {
    code: "5350",
    value: "E392",
    label: "LABICO",
    province: "RM"
  },
  {
    code: "5351",
    value: "C767",
    label: "LANUVIO",
    province: "RM"
  },
  {
    code: "5352",
    value: "E576",
    label: "LICENZA",
    province: "RM"
  },
  {
    code: "5353",
    value: "E813",
    label: "MAGLIANO ROMANO",
    province: "RM"
  },
  {
    code: "5354",
    value: "B632",
    label: "MANDELA",
    province: "RM"
  },
  {
    code: "5355",
    value: "E900",
    label: "MANZIANA",
    province: "RM"
  },
  {
    code: "5356",
    value: "E908",
    label: "MARANO EQUO",
    province: "RM"
  },
  {
    code: "5357",
    value: "E924",
    label: "MARCELLINA",
    province: "RM"
  },
  {
    code: "5358",
    value: "E958",
    label: "MARINO",
    province: "RM"
  },
  {
    code: "5359",
    value: "F064",
    label: "MAZZANO ROMANO",
    province: "RM"
  },
  {
    code: "5360",
    value: "F127",
    label: "MENTANA",
    province: "RM"
  },
  {
    code: "5361",
    value: "F477",
    label: "MONTE COMPATRI",
    province: "RM"
  },
  {
    code: "5362",
    value: "F504",
    label: "MONTEFLAVIO",
    province: "RM"
  },
  {
    code: "5363",
    value: "F534",
    label: "MONTELANICO",
    province: "RM"
  },
  {
    code: "5364",
    value: "F545",
    label: "MONTELIBRETTI",
    province: "RM"
  },
  {
    code: "5365",
    value: "F590",
    label: "MONTE PORZIO CATONE",
    province: "RM"
  },
  {
    code: "5366",
    value: "F611",
    label: "MONTEROTONDO",
    province: "RM"
  },
  {
    code: "5367",
    value: "F692",
    label: "MONTORIO ROMANO",
    province: "RM"
  },
  {
    code: "5368",
    value: "F730",
    label: "MORICONE",
    province: "RM"
  },
  {
    code: "5369",
    value: "F734",
    label: "MORLUPO",
    province: "RM"
  },
  {
    code: "5370",
    value: "F857",
    label: "NAZZANO",
    province: "RM"
  },
  {
    code: "5371",
    value: "F865",
    label: "NEMI",
    province: "RM"
  },
  {
    code: "5372",
    value: "F871",
    label: "NEROLA",
    province: "RM"
  },
  {
    code: "5373",
    value: "F880",
    label: "NETTUNO",
    province: "RM"
  },
  {
    code: "5374",
    value: "G022",
    label: "OLEVANO ROMANO",
    province: "RM"
  },
  {
    code: "5375",
    value: "G274",
    label: "PALESTRINA",
    province: "RM"
  },
  {
    code: "5376",
    value: "G293",
    label: "PALOMBARA SABINA",
    province: "RM"
  },
  {
    code: "5377",
    value: "G444",
    label: "PERCILE",
    province: "RM"
  },
  {
    code: "5378",
    value: "G704",
    label: "PISONIANO",
    province: "RM"
  },
  {
    code: "5379",
    value: "G784",
    label: "POLI",
    province: "RM"
  },
  {
    code: "5380",
    value: "G811",
    label: "POMEZIA",
    province: "RM"
  },
  {
    code: "5381",
    value: "G874",
    label: "PONZANO ROMANO",
    province: "RM"
  },
  {
    code: "5382",
    value: "H267",
    label: "RIANO",
    province: "RM"
  },
  {
    code: "5383",
    value: "H288",
    label: "RIGNANO FLAMINIO",
    province: "RM"
  },
  {
    code: "5384",
    value: "H300",
    label: "RIOFREDDO",
    province: "RM"
  },
  {
    code: "5385",
    value: "H387",
    label: "ROCCA CANTERANO",
    province: "RM"
  },
  {
    code: "5386",
    value: "H401",
    label: "ROCCA DI CAVE",
    province: "RM"
  },
  {
    code: "5387",
    value: "H404",
    label: "ROCCA DI PAPA",
    province: "RM"
  },
  {
    code: "5388",
    value: "H411",
    label: "ROCCAGIOVINE",
    province: "RM"
  },
  {
    code: "5389",
    value: "H432",
    label: "ROCCA PRIORA",
    province: "RM"
  },
  {
    code: "5390",
    value: "H441",
    label: "ROCCA SANTO STEFANO",
    province: "RM"
  },
  {
    code: "5391",
    value: "H494",
    label: "ROIATE",
    province: "RM"
  },
  {
    code: "5392",
    value: "H501",
    label: "ROMA",
    province: "RM"
  },
  {
    code: "5393",
    value: "H618",
    label: "ROVIANO",
    province: "RM"
  },
  {
    code: "5394",
    value: "H658",
    label: "SACROFANO",
    province: "RM"
  },
  {
    code: "5395",
    value: "H745",
    label: "SAMBUCI",
    province: "RM"
  },
  {
    code: "5396",
    value: "H942",
    label: "SAN GREGORIO DA SASSOLA",
    province: "RM"
  },
  {
    code: "5397",
    value: "I125",
    label: "SAN POLO DEI CAVALIERI",
    province: "RM"
  },
  {
    code: "5398",
    value: "I255",
    label: "SANTA MARINELLA",
    province: "RM"
  },
  {
    code: "5399",
    value: "I284",
    label: "SANT ANGELO ROMANO",
    province: "RM"
  },
  {
    code: "5400",
    value: "I352",
    label: "SANT ORESTE",
    province: "RM"
  },
  {
    code: "5401",
    value: "I400",
    label: "SAN VITO ROMANO",
    province: "RM"
  },
  {
    code: "5402",
    value: "I424",
    label: "SARACINESCO",
    province: "RM"
  },
  {
    code: "5403",
    value: "I573",
    label: "SEGNI",
    province: "RM"
  },
  {
    code: "5404",
    value: "I992",
    label: "SUBIACO",
    province: "RM"
  },
  {
    code: "5405",
    value: "L182",
    label: "TIVOLI",
    province: "RM"
  },
  {
    code: "5406",
    value: "L192",
    label: "TOLFA",
    province: "RM"
  },
  {
    code: "5407",
    value: "L302",
    label: "TORRITA TIBERINA",
    province: "RM"
  },
  {
    code: "5408",
    value: "L401",
    label: "TREVIGNANO ROMANO",
    province: "RM"
  },
  {
    code: "5409",
    value: "L611",
    label: "VALLEPIETRA",
    province: "RM"
  },
  {
    code: "5410",
    value: "L625",
    label: "VALLINFREDA",
    province: "RM"
  },
  {
    code: "5411",
    value: "L639",
    label: "VALMONTONE",
    province: "RM"
  },
  {
    code: "5412",
    value: "L719",
    label: "VELLETRI",
    province: "RM"
  },
  {
    code: "5413",
    value: "L851",
    label: "VICOVARO",
    province: "RM"
  },
  {
    code: "5414",
    value: "M095",
    label: "VIVARO ROMANO",
    province: "RM"
  },
  {
    code: "5415",
    value: "M141",
    label: "ZAGAROLO",
    province: "RM"
  },
  {
    code: "5416",
    value: "M207",
    label: "LARIANO",
    province: "RM"
  },
  {
    code: "5417",
    value: "M212",
    label: "LADISPOLI",
    province: "RM"
  },
  {
    code: "5418",
    value: "M213",
    label: "ARDEA",
    province: "RM"
  },
  {
    code: "5419",
    value: "M272",
    label: "CIAMPINO",
    province: "RM"
  },
  {
    code: "5420",
    value: "M295",
    label: "SAN CESAREO",
    province: "RM"
  },
  {
    code: "5421",
    value: "M297",
    label: "FIUMICINO",
    province: "RM"
  },
  {
    code: "5422",
    value: "A341",
    label: "APRILIA",
    province: "LT"
  },
  {
    code: "5423",
    value: "A707",
    label: "BASSIANO",
    province: "LT"
  },
  {
    code: "5424",
    value: "B527",
    label: "CAMPODIMELE",
    province: "LT"
  },
  {
    code: "5425",
    value: "C104",
    label: "CASTELFORTE",
    province: "LT"
  },
  {
    code: "5426",
    value: "C740",
    label: "CISTERNA DI LATINA",
    province: "LT"
  },
  {
    code: "5427",
    value: "D003",
    label: "CORI",
    province: "LT"
  },
  {
    code: "5428",
    value: "D662",
    label: "FONDI",
    province: "LT"
  },
  {
    code: "5429",
    value: "D708",
    label: "FORMIA",
    province: "LT"
  },
  {
    code: "5430",
    value: "D843",
    label: "GAETA",
    province: "LT"
  },
  {
    code: "5431",
    value: "E375",
    label: "ITRI",
    province: "LT"
  },
  {
    code: "5432",
    value: "E472",
    label: "LATINA",
    province: "LT"
  },
  {
    code: "5433",
    value: "E527",
    label: "LENOLA",
    province: "LT"
  },
  {
    code: "5434",
    value: "E798",
    label: "MAENZA",
    province: "LT"
  },
  {
    code: "5435",
    value: "F224",
    label: "MINTURNO",
    province: "LT"
  },
  {
    code: "5436",
    value: "F616",
    label: "MONTE SAN BIAGIO",
    province: "LT"
  },
  {
    code: "5437",
    value: "F937",
    label: "NORMA",
    province: "LT"
  },
  {
    code: "5438",
    value: "G865",
    label: "PONTINIA",
    province: "LT"
  },
  {
    code: "5439",
    value: "G871",
    label: "PONZA",
    province: "LT"
  },
  {
    code: "5440",
    value: "G698",
    label: "PRIVERNO",
    province: "LT"
  },
  {
    code: "5441",
    value: "H076",
    label: "PROSSEDI",
    province: "LT"
  },
  {
    code: "5442",
    value: "H413",
    label: "ROCCAGORGA",
    province: "LT"
  },
  {
    code: "5443",
    value: "H421",
    label: "ROCCA MASSIMA",
    province: "LT"
  },
  {
    code: "5444",
    value: "H444",
    label: "ROCCASECCA DEI VOLSCI",
    province: "LT"
  },
  {
    code: "5445",
    value: "H647",
    label: "SABAUDIA",
    province: "LT"
  },
  {
    code: "5446",
    value: "H836",
    label: "SAN FELICE CIRCEO",
    province: "LT"
  },
  {
    code: "5447",
    value: "I339",
    label: "SANTI COSMA E DAMIANO",
    province: "LT"
  },
  {
    code: "5448",
    value: "I634",
    label: "SERMONETA",
    province: "LT"
  },
  {
    code: "5449",
    value: "I712",
    label: "SEZZE",
    province: "LT"
  },
  {
    code: "5450",
    value: "I832",
    label: "SONNINO",
    province: "LT"
  },
  {
    code: "5451",
    value: "I892",
    label: "SPERLONGA",
    province: "LT"
  },
  {
    code: "5452",
    value: "I902",
    label: "SPIGNO SATURNIA",
    province: "LT"
  },
  {
    code: "5453",
    value: "L120",
    label: "TERRACINA",
    province: "LT"
  },
  {
    code: "5454",
    value: "L742",
    label: "VENTOTENE",
    province: "LT"
  },
  {
    code: "5455",
    value: "A032",
    label: "ACQUAFONDATA",
    province: "FR"
  },
  {
    code: "5456",
    value: "A054",
    label: "ACUTO",
    province: "FR"
  },
  {
    code: "5457",
    value: "A123",
    label: "ALATRI",
    province: "FR"
  },
  {
    code: "5458",
    value: "A244",
    label: "ALVITO",
    province: "FR"
  },
  {
    code: "5459",
    value: "A256",
    label: "AMASENO",
    province: "FR"
  },
  {
    code: "5460",
    value: "A269",
    label: "ANAGNI",
    province: "FR"
  },
  {
    code: "5461",
    value: "A348",
    label: "AQUINO",
    province: "FR"
  },
  {
    code: "5462",
    value: "A363",
    label: "ARCE",
    province: "FR"
  },
  {
    code: "5463",
    value: "A421",
    label: "ARNARA",
    province: "FR"
  },
  {
    code: "5464",
    value: "A433",
    label: "ARPINO",
    province: "FR"
  },
  {
    code: "5465",
    value: "A486",
    label: "ATINA",
    province: "FR"
  },
  {
    code: "5466",
    value: "A502",
    label: "AUSONIA",
    province: "FR"
  },
  {
    code: "5467",
    value: "A763",
    label: "BELMONTE CASTELLO",
    province: "FR"
  },
  {
    code: "5468",
    value: "A720",
    label: "BOVILLE ERNICA",
    province: "FR"
  },
  {
    code: "5469",
    value: "B195",
    label: "BROCCOSTELLA",
    province: "FR"
  },
  {
    code: "5470",
    value: "B543",
    label: "CAMPOLI APPENNINO",
    province: "FR"
  },
  {
    code: "5471",
    value: "B862",
    label: "CASALATTICO",
    province: "FR"
  },
  {
    code: "5472",
    value: "B919",
    label: "CASALVIERI",
    province: "FR"
  },
  {
    code: "5473",
    value: "C034",
    label: "CASSINO",
    province: "FR"
  },
  {
    code: "5474",
    value: "C177",
    label: "CASTELLIRI",
    province: "FR"
  },
  {
    code: "5475",
    value: "C223",
    label: "CASTELNUOVO PARANO",
    province: "FR"
  },
  {
    code: "5476",
    value: "C340",
    label: "CASTROCIELO",
    province: "FR"
  },
  {
    code: "5477",
    value: "C338",
    label: "CASTRO DEI VOLSCI",
    province: "FR"
  },
  {
    code: "5478",
    value: "C413",
    label: "CECCANO",
    province: "FR"
  },
  {
    code: "5479",
    value: "C479",
    label: "CEPRANO",
    province: "FR"
  },
  {
    code: "5480",
    value: "C545",
    label: "CERVARO",
    province: "FR"
  },
  {
    code: "5481",
    value: "C836",
    label: "COLFELICE",
    province: "FR"
  },
  {
    code: "5482",
    value: "C864",
    label: "COLLEPARDO",
    province: "FR"
  },
  {
    code: "5483",
    value: "C870",
    label: "COLLE SAN MAGNO",
    province: "FR"
  },
  {
    code: "5484",
    value: "C998",
    label: "CORENO AUSONIO",
    province: "FR"
  },
  {
    code: "5485",
    value: "D440",
    label: "ESPERIA",
    province: "FR"
  },
  {
    code: "5486",
    value: "D483",
    label: "FALVATERRA",
    province: "FR"
  },
  {
    code: "5487",
    value: "D539",
    label: "FERENTINO",
    province: "FR"
  },
  {
    code: "5488",
    value: "D591",
    label: "FILETTINO",
    province: "FR"
  },
  {
    code: "5489",
    value: "A310",
    label: "FIUGGI",
    province: "FR"
  },
  {
    code: "5490",
    value: "D667",
    label: "FONTANA LIRI",
    province: "FR"
  },
  {
    code: "5491",
    value: "D682",
    label: "FONTECHIARI",
    province: "FR"
  },
  {
    code: "5492",
    value: "D810",
    label: "FROSINONE",
    province: "FR"
  },
  {
    code: "5493",
    value: "D819",
    label: "FUMONE",
    province: "FR"
  },
  {
    code: "5494",
    value: "D881",
    label: "GALLINARO",
    province: "FR"
  },
  {
    code: "5495",
    value: "E057",
    label: "GIULIANO DI ROMA",
    province: "FR"
  },
  {
    code: "5496",
    value: "E236",
    label: "GUARCINO",
    province: "FR"
  },
  {
    code: "5497",
    value: "E340",
    label: "ISOLA DEL LIRI",
    province: "FR"
  },
  {
    code: "5498",
    value: "F620",
    label: "MONTE SAN GIOVANNI CAMPANO",
    province: "FR"
  },
  {
    code: "5499",
    value: "F740",
    label: "MOROLO",
    province: "FR"
  },
  {
    code: "5500",
    value: "G276",
    label: "PALIANO",
    province: "FR"
  },
  {
    code: "5501",
    value: "G362",
    label: "PASTENA",
    province: "FR"
  },
  {
    code: "5502",
    value: "G374",
    label: "PATRICA",
    province: "FR"
  },
  {
    code: "5503",
    value: "G500",
    label: "PESCOSOLIDO",
    province: "FR"
  },
  {
    code: "5504",
    value: "G591",
    label: "PICINISCO",
    province: "FR"
  },
  {
    code: "5505",
    value: "G592",
    label: "PICO",
    province: "FR"
  },
  {
    code: "5506",
    value: "G598",
    label: "PIEDIMONTE SAN GERMANO",
    province: "FR"
  },
  {
    code: "5507",
    value: "G659",
    label: "PIGLIO",
    province: "FR"
  },
  {
    code: "5508",
    value: "G662",
    label: "PIGNATARO INTERAMNA",
    province: "FR"
  },
  {
    code: "5509",
    value: "G749",
    label: "POFI",
    province: "FR"
  },
  {
    code: "5510",
    value: "G838",
    label: "PONTECORVO",
    province: "FR"
  },
  {
    code: "5511",
    value: "G935",
    label: "POSTA FIBRENO",
    province: "FR"
  },
  {
    code: "5512",
    value: "H324",
    label: "RIPI",
    province: "FR"
  },
  {
    code: "5513",
    value: "H393",
    label: "ROCCA D ARCE",
    province: "FR"
  },
  {
    code: "5514",
    value: "H443",
    label: "ROCCASECCA",
    province: "FR"
  },
  {
    code: "5515",
    value: "H779",
    label: "SAN BIAGIO SARACINISCO",
    province: "FR"
  },
  {
    code: "5516",
    value: "H824",
    label: "SAN DONATO VAL DI COMINO",
    province: "FR"
  },
  {
    code: "5517",
    value: "H880",
    label: "SAN GIORGIO A LIRI",
    province: "FR"
  },
  {
    code: "5518",
    value: "H917",
    label: "SAN GIOVANNI INCARICO",
    province: "FR"
  },
  {
    code: "5519",
    value: "I256",
    label: "SANT AMBROGIO SUL GARIGLIANO",
    province: "FR"
  },
  {
    code: "5520",
    value: "I265",
    label: "SANT ANDREA DEL GARIGLIANO",
    province: "FR"
  },
  {
    code: "5521",
    value: "I302",
    label: "SANT APOLLINARE",
    province: "FR"
  },
  {
    code: "5522",
    value: "I321",
    label: "SANT ELIA FIUMERAPIDO",
    province: "FR"
  },
  {
    code: "5523",
    value: "I351",
    label: "SANTOPADRE",
    province: "FR"
  },
  {
    code: "5524",
    value: "I408",
    label: "SAN VITTORE DEL LAZIO",
    province: "FR"
  },
  {
    code: "5525",
    value: "I669",
    label: "SERRONE",
    province: "FR"
  },
  {
    code: "5526",
    value: "I697",
    label: "SETTEFRATI",
    province: "FR"
  },
  {
    code: "5527",
    value: "I716",
    label: "SGURGOLA",
    province: "FR"
  },
  {
    code: "5528",
    value: "I838",
    label: "SORA",
    province: "FR"
  },
  {
    code: "5529",
    value: "I973",
    label: "STRANGOLAGALLI",
    province: "FR"
  },
  {
    code: "5530",
    value: "L009",
    label: "SUPINO",
    province: "FR"
  },
  {
    code: "5531",
    value: "L105",
    label: "TERELLE",
    province: "FR"
  },
  {
    code: "5532",
    value: "L243",
    label: "TORRE CAJETANI",
    province: "FR"
  },
  {
    code: "5533",
    value: "L290",
    label: "TORRICE",
    province: "FR"
  },
  {
    code: "5534",
    value: "L398",
    label: "TREVI NEL LAZIO",
    province: "FR"
  },
  {
    code: "5535",
    value: "L437",
    label: "TRIVIGLIANO",
    province: "FR"
  },
  {
    code: "5536",
    value: "L598",
    label: "VALLECORSA",
    province: "FR"
  },
  {
    code: "5537",
    value: "L605",
    label: "VALLEMAIO",
    province: "FR"
  },
  {
    code: "5538",
    value: "L614",
    label: "VALLEROTONDA",
    province: "FR"
  },
  {
    code: "5539",
    value: "L780",
    label: "VEROLI",
    province: "FR"
  },
  {
    code: "5540",
    value: "L836",
    label: "VICALVI",
    province: "FR"
  },
  {
    code: "5541",
    value: "L843",
    label: "VICO NEL LAZIO",
    province: "FR"
  },
  {
    code: "5542",
    value: "A081",
    label: "VILLA LATINA",
    province: "FR"
  },
  {
    code: "5543",
    value: "L905",
    label: "VILLA SANTA LUCIA",
    province: "FR"
  },
  {
    code: "5544",
    value: "I364",
    label: "VILLA SANTO STEFANO",
    province: "FR"
  },
  {
    code: "5545",
    value: "M083",
    label: "VITICUSO",
    province: "FR"
  },
  {
    code: "5546",
    value: "A018",
    label: "ACCIANO",
    province: "AQ"
  },
  {
    code: "5547",
    value: "A100",
    label: "AIELLI",
    province: "AQ"
  },
  {
    code: "5548",
    value: "A187",
    label: "ALFEDENA",
    province: "AQ"
  },
  {
    code: "5549",
    value: "A318",
    label: "ANVERSA DEGLI ABRUZZI",
    province: "AQ"
  },
  {
    code: "5550",
    value: "A481",
    label: "ATELETA",
    province: "AQ"
  },
  {
    code: "5551",
    value: "A515",
    label: "AVEZZANO",
    province: "AQ"
  },
  {
    code: "5552",
    value: "A603",
    label: "BALSORANO",
    province: "AQ"
  },
  {
    code: "5553",
    value: "A656",
    label: "BARETE",
    province: "AQ"
  },
  {
    code: "5554",
    value: "A667",
    label: "BARISCIANO",
    province: "AQ"
  },
  {
    code: "5555",
    value: "A678",
    label: "BARREA",
    province: "AQ"
  },
  {
    code: "5556",
    value: "A884",
    label: "BISEGNA",
    province: "AQ"
  },
  {
    code: "5557",
    value: "B256",
    label: "BUGNARA",
    province: "AQ"
  },
  {
    code: "5558",
    value: "B358",
    label: "CAGNANO AMITERNO",
    province: "AQ"
  },
  {
    code: "5559",
    value: "B382",
    label: "CALASCIO",
    province: "AQ"
  },
  {
    code: "5560",
    value: "B526",
    label: "CAMPO DI GIOVE",
    province: "AQ"
  },
  {
    code: "5561",
    value: "B569",
    label: "CAMPOTOSTO",
    province: "AQ"
  },
  {
    code: "5562",
    value: "B606",
    label: "CANISTRO",
    province: "AQ"
  },
  {
    code: "5563",
    value: "B624",
    label: "CANSANO",
    province: "AQ"
  },
  {
    code: "5564",
    value: "B651",
    label: "CAPESTRANO",
    province: "AQ"
  },
  {
    code: "5565",
    value: "B656",
    label: "CAPISTRELLO",
    province: "AQ"
  },
  {
    code: "5566",
    value: "B658",
    label: "CAPITIGNANO",
    province: "AQ"
  },
  {
    code: "5567",
    value: "B672",
    label: "CAPORCIANO",
    province: "AQ"
  },
  {
    code: "5568",
    value: "B677",
    label: "CAPPADOCIA",
    province: "AQ"
  },
  {
    code: "5569",
    value: "B725",
    label: "CARAPELLE CALVISIO",
    province: "AQ"
  },
  {
    code: "5570",
    value: "B842",
    label: "CARSOLI",
    province: "AQ"
  },
  {
    code: "5571",
    value: "C083",
    label: "CASTEL DEL MONTE",
    province: "AQ"
  },
  {
    code: "5572",
    value: "C090",
    label: "CASTEL DI IERI",
    province: "AQ"
  },
  {
    code: "5573",
    value: "C096",
    label: "CASTEL DI SANGRO",
    province: "AQ"
  },
  {
    code: "5574",
    value: "C126",
    label: "CASTELLAFIUME",
    province: "AQ"
  },
  {
    code: "5575",
    value: "C278",
    label: "CASTELVECCHIO CALVISIO",
    province: "AQ"
  },
  {
    code: "5576",
    value: "C279",
    label: "CASTELVECCHIO SUBEQUO",
    province: "AQ"
  },
  {
    code: "5577",
    value: "C426",
    label: "CELANO",
    province: "AQ"
  },
  {
    code: "5578",
    value: "C492",
    label: "CERCHIO",
    province: "AQ"
  },
  {
    code: "5579",
    value: "C766",
    label: "CIVITA D ANTINO",
    province: "AQ"
  },
  {
    code: "5580",
    value: "C778",
    label: "CIVITELLA ALFEDENA",
    province: "AQ"
  },
  {
    code: "5581",
    value: "C783",
    label: "CIVITELLA ROVETO",
    province: "AQ"
  },
  {
    code: "5582",
    value: "C811",
    label: "COCULLO",
    province: "AQ"
  },
  {
    code: "5583",
    value: "C844",
    label: "COLLARMELE",
    province: "AQ"
  },
  {
    code: "5584",
    value: "C862",
    label: "COLLELONGO",
    province: "AQ"
  },
  {
    code: "5585",
    value: "C866",
    label: "COLLEPIETRO",
    province: "AQ"
  },
  {
    code: "5586",
    value: "C999",
    label: "CORFINIO",
    province: "AQ"
  },
  {
    code: "5587",
    value: "D465",
    label: "FAGNANO ALTO",
    province: "AQ"
  },
  {
    code: "5588",
    value: "D681",
    label: "FONTECCHIO",
    province: "AQ"
  },
  {
    code: "5589",
    value: "D736",
    label: "FOSSA",
    province: "AQ"
  },
  {
    code: "5590",
    value: "D850",
    label: "GAGLIANO ATERNO",
    province: "AQ"
  },
  {
    code: "5591",
    value: "E040",
    label: "GIOIA DEI MARSI",
    province: "AQ"
  },
  {
    code: "5592",
    value: "E096",
    label: "GORIANO SICOLI",
    province: "AQ"
  },
  {
    code: "5593",
    value: "E307",
    label: "INTRODACQUA",
    province: "AQ"
  },
  {
    code: "5594",
    value: "A345",
    label: "L AQUILA",
    province: "AQ"
  },
  {
    code: "5595",
    value: "E505",
    label: "LECCE NEI MARSI",
    province: "AQ"
  },
  {
    code: "5596",
    value: "E723",
    label: "LUCO DEI MARSI",
    province: "AQ"
  },
  {
    code: "5597",
    value: "E724",
    label: "LUCOLI",
    province: "AQ"
  },
  {
    code: "5598",
    value: "E811",
    label: "MAGLIANO DE MARSI",
    province: "AQ"
  },
  {
    code: "5599",
    value: "F022",
    label: "MASSA D ALBE",
    province: "AQ"
  },
  {
    code: "5600",
    value: "M255",
    label: "MOLINA ATERNO",
    province: "AQ"
  },
  {
    code: "5601",
    value: "F595",
    label: "MONTEREALE",
    province: "AQ"
  },
  {
    code: "5602",
    value: "F732",
    label: "MORINO",
    province: "AQ"
  },
  {
    code: "5603",
    value: "F852",
    label: "NAVELLI",
    province: "AQ"
  },
  {
    code: "5604",
    value: "F996",
    label: "OCRE",
    province: "AQ"
  },
  {
    code: "5605",
    value: "G002",
    label: "OFENA",
    province: "AQ"
  },
  {
    code: "5606",
    value: "G079",
    label: "OPI",
    province: "AQ"
  },
  {
    code: "5607",
    value: "G102",
    label: "ORICOLA",
    province: "AQ"
  },
  {
    code: "5608",
    value: "G142",
    label: "ORTONA DEI MARSI",
    province: "AQ"
  },
  {
    code: "5609",
    value: "G145",
    label: "ORTUCCHIO",
    province: "AQ"
  },
  {
    code: "5610",
    value: "G200",
    label: "OVINDOLI",
    province: "AQ"
  },
  {
    code: "5611",
    value: "G210",
    label: "PACENTRO",
    province: "AQ"
  },
  {
    code: "5612",
    value: "G449",
    label: "PERETO",
    province: "AQ"
  },
  {
    code: "5613",
    value: "G484",
    label: "PESCASSEROLI",
    province: "AQ"
  },
  {
    code: "5614",
    value: "G492",
    label: "PESCINA",
    province: "AQ"
  },
  {
    code: "5615",
    value: "G493",
    label: "PESCOCOSTANZO",
    province: "AQ"
  },
  {
    code: "5616",
    value: "G524",
    label: "PETTORANO SUL GIZIO",
    province: "AQ"
  },
  {
    code: "5617",
    value: "G726",
    label: "PIZZOLI",
    province: "AQ"
  },
  {
    code: "5618",
    value: "G766",
    label: "POGGIO PICENZE",
    province: "AQ"
  },
  {
    code: "5619",
    value: "G992",
    label: "PRATA D ANSIDONIA",
    province: "AQ"
  },
  {
    code: "5620",
    value: "H007",
    label: "PRATOLA PELIGNA",
    province: "AQ"
  },
  {
    code: "5621",
    value: "H056",
    label: "PREZZA",
    province: "AQ"
  },
  {
    code: "5622",
    value: "H166",
    label: "RAIANO",
    province: "AQ"
  },
  {
    code: "5623",
    value: "H353",
    label: "RIVISONDOLI",
    province: "AQ"
  },
  {
    code: "5624",
    value: "H389",
    label: "ROCCACASALE",
    province: "AQ"
  },
  {
    code: "5625",
    value: "H399",
    label: "ROCCA DI BOTTE",
    province: "AQ"
  },
  {
    code: "5626",
    value: "H400",
    label: "ROCCA DI CAMBIO",
    province: "AQ"
  },
  {
    code: "5627",
    value: "H402",
    label: "ROCCA DI MEZZO",
    province: "AQ"
  },
  {
    code: "5628",
    value: "H429",
    label: "ROCCA PIA",
    province: "AQ"
  },
  {
    code: "5629",
    value: "H434",
    label: "ROCCARASO",
    province: "AQ"
  },
  {
    code: "5630",
    value: "H772",
    label: "SAN BENEDETTO DEI MARSI",
    province: "AQ"
  },
  {
    code: "5631",
    value: "H773",
    label: "SAN BENEDETTO IN PERILLIS",
    province: "AQ"
  },
  {
    code: "5632",
    value: "H819",
    label: "SAN DEMETRIO NE VESTINI",
    province: "AQ"
  },
  {
    code: "5633",
    value: "I121",
    label: "SAN PIO DELLE CAMERE",
    province: "AQ"
  },
  {
    code: "5634",
    value: "I326",
    label: "SANTE MARIE",
    province: "AQ"
  },
  {
    code: "5635",
    value: "I336",
    label: "SANT EUSANIO FORCONESE",
    province: "AQ"
  },
  {
    code: "5636",
    value: "I360",
    label: "SANTO STEFANO DI SESSANIO",
    province: "AQ"
  },
  {
    code: "5637",
    value: "I389",
    label: "SAN VINCENZO VALLE ROVETO",
    province: "AQ"
  },
  {
    code: "5638",
    value: "I501",
    label: "SCANNO",
    province: "AQ"
  },
  {
    code: "5639",
    value: "I543",
    label: "SCONTRONE",
    province: "AQ"
  },
  {
    code: "5640",
    value: "I546",
    label: "SCOPPITO",
    province: "AQ"
  },
  {
    code: "5641",
    value: "I553",
    label: "SCURCOLA MARSICANA",
    province: "AQ"
  },
  {
    code: "5642",
    value: "I558",
    label: "SECINARO",
    province: "AQ"
  },
  {
    code: "5643",
    value: "I804",
    label: "SULMONA",
    province: "AQ"
  },
  {
    code: "5644",
    value: "L025",
    label: "TAGLIACOZZO",
    province: "AQ"
  },
  {
    code: "5645",
    value: "L173",
    label: "TIONE DEGLI ABRUZZI",
    province: "AQ"
  },
  {
    code: "5646",
    value: "L227",
    label: "TORNIMPARTE",
    province: "AQ"
  },
  {
    code: "5647",
    value: "L334",
    label: "TRASACCO",
    province: "AQ"
  },
  {
    code: "5648",
    value: "L958",
    label: "VILLALAGO",
    province: "AQ"
  },
  {
    code: "5649",
    value: "M021",
    label: "VILLA SANTA LUCIA DEGLI ABRUZZI",
    province: "AQ"
  },
  {
    code: "5650",
    value: "M023",
    label: "VILLA SANT ANGELO",
    province: "AQ"
  },
  {
    code: "5651",
    value: "M031",
    label: "VILLAVALLELONGA",
    province: "AQ"
  },
  {
    code: "5652",
    value: "M041",
    label: "VILLETTA BARREA",
    province: "AQ"
  },
  {
    code: "5653",
    value: "M090",
    label: "VITTORITO",
    province: "AQ"
  },
  {
    code: "5654",
    value: "A125",
    label: "ALBA ADRIATICA",
    province: "TE"
  },
  {
    code: "5655",
    value: "A270",
    label: "ANCARANO",
    province: "TE"
  },
  {
    code: "5656",
    value: "A445",
    label: "ARSITA",
    province: "TE"
  },
  {
    code: "5657",
    value: "A488",
    label: "ATRI",
    province: "TE"
  },
  {
    code: "5658",
    value: "A692",
    label: "BASCIANO",
    province: "TE"
  },
  {
    code: "5659",
    value: "A746",
    label: "BELLANTE",
    province: "TE"
  },
  {
    code: "5660",
    value: "A885",
    label: "BISENTI",
    province: "TE"
  },
  {
    code: "5661",
    value: "B515",
    label: "CAMPLI",
    province: "TE"
  },
  {
    code: "5662",
    value: "B640",
    label: "CANZANO",
    province: "TE"
  },
  {
    code: "5663",
    value: "C040",
    label: "CASTEL CASTAGNA",
    province: "TE"
  },
  {
    code: "5664",
    value: "C128",
    label: "CASTELLALTO",
    province: "TE"
  },
  {
    code: "5665",
    value: "C169",
    label: "CASTELLI",
    province: "TE"
  },
  {
    code: "5666",
    value: "C316",
    label: "CASTIGLIONE MESSER RAIMONDO",
    province: "TE"
  },
  {
    code: "5667",
    value: "C322",
    label: "CASTILENTI",
    province: "TE"
  },
  {
    code: "5668",
    value: "C449",
    label: "CELLINO ATTANASIO",
    province: "TE"
  },
  {
    code: "5669",
    value: "C517",
    label: "CERMIGNANO",
    province: "TE"
  },
  {
    code: "5670",
    value: "C781",
    label: "CIVITELLA DEL TRONTO",
    province: "TE"
  },
  {
    code: "5671",
    value: "C311",
    label: "COLLEDARA",
    province: "TE"
  },
  {
    code: "5672",
    value: "C901",
    label: "COLONNELLA",
    province: "TE"
  },
  {
    code: "5673",
    value: "C972",
    label: "CONTROGUERRA",
    province: "TE"
  },
  {
    code: "5674",
    value: "D043",
    label: "CORROPOLI",
    province: "TE"
  },
  {
    code: "5675",
    value: "D076",
    label: "CORTINO",
    province: "TE"
  },
  {
    code: "5676",
    value: "D179",
    label: "CROGNALETO",
    province: "TE"
  },
  {
    code: "5677",
    value: "D489",
    label: "FANO ADRIANO",
    province: "TE"
  },
  {
    code: "5678",
    value: "E058",
    label: "GIULIANOVA",
    province: "TE"
  },
  {
    code: "5679",
    value: "E343",
    label: "ISOLA DEL GRAN SASSO D ITALIA",
    province: "TE"
  },
  {
    code: "5680",
    value: "F500",
    label: "MONTEFINO",
    province: "TE"
  },
  {
    code: "5681",
    value: "F690",
    label: "MONTORIO AL VOMANO",
    province: "TE"
  },
  {
    code: "5682",
    value: "F747",
    label: "MORRO D ORO",
    province: "TE"
  },
  {
    code: "5683",
    value: "F764",
    label: "MOSCIANO SANT ANGELO",
    province: "TE"
  },
  {
    code: "5684",
    value: "F870",
    label: "NERETO",
    province: "TE"
  },
  {
    code: "5685",
    value: "F942",
    label: "NOTARESCO",
    province: "TE"
  },
  {
    code: "5686",
    value: "G437",
    label: "PENNA SANT ANDREA",
    province: "TE"
  },
  {
    code: "5687",
    value: "G608",
    label: "PIETRACAMELA",
    province: "TE"
  },
  {
    code: "5688",
    value: "F831",
    label: "PINETO",
    province: "TE"
  },
  {
    code: "5689",
    value: "H440",
    label: "ROCCA SANTA MARIA",
    province: "TE"
  },
  {
    code: "5690",
    value: "F585",
    label: "ROSETO DEGLI ABRUZZI",
    province: "TE"
  },
  {
    code: "5691",
    value: "I318",
    label: "SANT EGIDIO ALLA VIBRATA",
    province: "TE"
  },
  {
    code: "5692",
    value: "I348",
    label: "SANT OMERO",
    province: "TE"
  },
  {
    code: "5693",
    value: "I741",
    label: "SILVI",
    province: "TE"
  },
  {
    code: "5694",
    value: "L103",
    label: "TERAMO",
    province: "TE"
  },
  {
    code: "5695",
    value: "L207",
    label: "TORANO NUOVO",
    province: "TE"
  },
  {
    code: "5696",
    value: "L295",
    label: "TORRICELLA SICURA",
    province: "TE"
  },
  {
    code: "5697",
    value: "L307",
    label: "TORTORETO",
    province: "TE"
  },
  {
    code: "5698",
    value: "L314",
    label: "TOSSICIA",
    province: "TE"
  },
  {
    code: "5699",
    value: "L597",
    label: "VALLE CASTELLANA",
    province: "TE"
  },
  {
    code: "5700",
    value: "E989",
    label: "MARTINSICURO",
    province: "TE"
  },
  {
    code: "5701",
    value: "A008",
    label: "ABBATEGGIO",
    province: "PE"
  },
  {
    code: "5702",
    value: "A120",
    label: "ALANNO",
    province: "PE"
  },
  {
    code: "5703",
    value: "A945",
    label: "BOLOGNANO",
    province: "PE"
  },
  {
    code: "5704",
    value: "B193",
    label: "BRITTOLI",
    province: "PE"
  },
  {
    code: "5705",
    value: "B294",
    label: "BUSSI SUL TIRINO",
    province: "PE"
  },
  {
    code: "5706",
    value: "B681",
    label: "CAPPELLE SUL TAVO",
    province: "PE"
  },
  {
    code: "5707",
    value: "B722",
    label: "CARAMANICO TERME",
    province: "PE"
  },
  {
    code: "5708",
    value: "B827",
    label: "CARPINETO DELLA NORA",
    province: "PE"
  },
  {
    code: "5709",
    value: "C308",
    label: "CASTIGLIONE A CASAURIA",
    province: "PE"
  },
  {
    code: "5710",
    value: "C354",
    label: "CATIGNANO",
    province: "PE"
  },
  {
    code: "5711",
    value: "C474",
    label: "CEPAGATTI",
    province: "PE"
  },
  {
    code: "5712",
    value: "C750",
    label: "CITTA SANT ANGELO",
    province: "PE"
  },
  {
    code: "5713",
    value: "C771",
    label: "CIVITAQUANA",
    province: "PE"
  },
  {
    code: "5714",
    value: "C779",
    label: "CIVITELLA CASANOVA",
    province: "PE"
  },
  {
    code: "5715",
    value: "C853",
    label: "COLLECORVINO",
    province: "PE"
  },
  {
    code: "5716",
    value: "D078",
    label: "CORVARA",
    province: "PE"
  },
  {
    code: "5717",
    value: "D201",
    label: "CUGNOLI",
    province: "PE"
  },
  {
    code: "5718",
    value: "D394",
    label: "ELICE",
    province: "PE"
  },
  {
    code: "5719",
    value: "D501",
    label: "FARINDOLA",
    province: "PE"
  },
  {
    code: "5720",
    value: "E558",
    label: "LETTOMANOPPELLO",
    province: "PE"
  },
  {
    code: "5721",
    value: "E691",
    label: "LORETO APRUTINO",
    province: "PE"
  },
  {
    code: "5722",
    value: "E892",
    label: "MANOPPELLO",
    province: "PE"
  },
  {
    code: "5723",
    value: "F441",
    label: "MONTEBELLO DI BERTONA",
    province: "PE"
  },
  {
    code: "5724",
    value: "F646",
    label: "MONTESILVANO",
    province: "PE"
  },
  {
    code: "5725",
    value: "F765",
    label: "MOSCUFO",
    province: "PE"
  },
  {
    code: "5726",
    value: "F908",
    label: "NOCCIANO",
    province: "PE"
  },
  {
    code: "5727",
    value: "G438",
    label: "PENNE",
    province: "PE"
  },
  {
    code: "5728",
    value: "G482",
    label: "PESCARA",
    province: "PE"
  },
  {
    code: "5729",
    value: "G499",
    label: "PESCOSANSONESCO",
    province: "PE"
  },
  {
    code: "5730",
    value: "G555",
    label: "PIANELLA",
    province: "PE"
  },
  {
    code: "5731",
    value: "G589",
    label: "PICCIANO",
    province: "PE"
  },
  {
    code: "5732",
    value: "G621",
    label: "PIETRANICO",
    province: "PE"
  },
  {
    code: "5733",
    value: "G878",
    label: "POPOLI",
    province: "PE"
  },
  {
    code: "5734",
    value: "H425",
    label: "ROCCAMORICE",
    province: "PE"
  },
  {
    code: "5735",
    value: "H562",
    label: "ROSCIANO",
    province: "PE"
  },
  {
    code: "5736",
    value: "H715",
    label: "SALLE",
    province: "PE"
  },
  {
    code: "5737",
    value: "I332",
    label: "SANT EUFEMIA A MAIELLA",
    province: "PE"
  },
  {
    code: "5738",
    value: "I376",
    label: "SAN VALENTINO IN ABRUZZO CITERIORE",
    province: "PE"
  },
  {
    code: "5739",
    value: "I482",
    label: "SCAFA",
    province: "PE"
  },
  {
    code: "5740",
    value: "I649",
    label: "SERRAMONACESCA",
    province: "PE"
  },
  {
    code: "5741",
    value: "I922",
    label: "SPOLTORE",
    province: "PE"
  },
  {
    code: "5742",
    value: "L186",
    label: "TOCCO DA CASAURIA",
    province: "PE"
  },
  {
    code: "5743",
    value: "L263",
    label: "TORRE DE PASSERI",
    province: "PE"
  },
  {
    code: "5744",
    value: "L475",
    label: "TURRIVALIGNANI",
    province: "PE"
  },
  {
    code: "5745",
    value: "L846",
    label: "VICOLI",
    province: "PE"
  },
  {
    code: "5746",
    value: "L922",
    label: "VILLA CELIERA",
    province: "PE"
  },
  {
    code: "5747",
    value: "A235",
    label: "ALTINO",
    province: "CH"
  },
  {
    code: "5748",
    value: "A367",
    label: "ARCHI",
    province: "CH"
  },
  {
    code: "5749",
    value: "A398",
    label: "ARI",
    province: "CH"
  },
  {
    code: "5750",
    value: "A402",
    label: "ARIELLI",
    province: "CH"
  },
  {
    code: "5751",
    value: "A485",
    label: "ATESSA",
    province: "CH"
  },
  {
    code: "5752",
    value: "A956",
    label: "BOMBA",
    province: "CH"
  },
  {
    code: "5753",
    value: "B057",
    label: "BORRELLO",
    province: "CH"
  },
  {
    code: "5754",
    value: "B238",
    label: "BUCCHIANICO",
    province: "CH"
  },
  {
    code: "5755",
    value: "B268",
    label: "MONTEBELLO SUL SANGRO",
    province: "CH"
  },
  {
    code: "5756",
    value: "B620",
    label: "CANOSA SANNITA",
    province: "CH"
  },
  {
    code: "5757",
    value: "B826",
    label: "CARPINETO SINELLO",
    province: "CH"
  },
  {
    code: "5758",
    value: "B853",
    label: "CARUNCHIO",
    province: "CH"
  },
  {
    code: "5759",
    value: "B859",
    label: "CASACANDITELLA",
    province: "CH"
  },
  {
    code: "5760",
    value: "B861",
    label: "CASALANGUIDA",
    province: "CH"
  },
  {
    code: "5761",
    value: "B865",
    label: "CASALBORDINO",
    province: "CH"
  },
  {
    code: "5762",
    value: "B896",
    label: "CASALINCONTRADA",
    province: "CH"
  },
  {
    code: "5763",
    value: "B985",
    label: "CASOLI",
    province: "CH"
  },
  {
    code: "5764",
    value: "C114",
    label: "CASTEL FRENTANO",
    province: "CH"
  },
  {
    code: "5765",
    value: "C123",
    label: "CASTELGUIDONE",
    province: "CH"
  },
  {
    code: "5766",
    value: "C298",
    label: "CASTIGLIONE MESSER MARINO",
    province: "CH"
  },
  {
    code: "5767",
    value: "C428",
    label: "CELENZA SUL TRIGNO",
    province: "CH"
  },
  {
    code: "5768",
    value: "C632",
    label: "CHIETI",
    province: "CH"
  },
  {
    code: "5769",
    value: "C768",
    label: "CIVITALUPARELLA",
    province: "CH"
  },
  {
    code: "5770",
    value: "C776",
    label: "CIVITELLA MESSER RAIMONDO",
    province: "CH"
  },
  {
    code: "5771",
    value: "C855",
    label: "COLLEDIMACINE",
    province: "CH"
  },
  {
    code: "5772",
    value: "C856",
    label: "COLLEDIMEZZO",
    province: "CH"
  },
  {
    code: "5773",
    value: "D137",
    label: "CRECCHIO",
    province: "CH"
  },
  {
    code: "5774",
    value: "D209",
    label: "CUPELLO",
    province: "CH"
  },
  {
    code: "5775",
    value: "D315",
    label: "DOGLIOLA",
    province: "CH"
  },
  {
    code: "5776",
    value: "D494",
    label: "FARA FILIORUM PETRI",
    province: "CH"
  },
  {
    code: "5777",
    value: "D495",
    label: "FARA SAN MARTINO",
    province: "CH"
  },
  {
    code: "5778",
    value: "D592",
    label: "FILETTO",
    province: "CH"
  },
  {
    code: "5779",
    value: "D738",
    label: "FOSSACESIA",
    province: "CH"
  },
  {
    code: "5780",
    value: "D757",
    label: "FRAINE",
    province: "CH"
  },
  {
    code: "5781",
    value: "D763",
    label: "FRANCAVILLA AL MARE",
    province: "CH"
  },
  {
    code: "5782",
    value: "D796",
    label: "FRESAGRANDINARIA",
    province: "CH"
  },
  {
    code: "5783",
    value: "D803",
    label: "FRISA",
    province: "CH"
  },
  {
    code: "5784",
    value: "D823",
    label: "FURCI",
    province: "CH"
  },
  {
    code: "5785",
    value: "D898",
    label: "GAMBERALE",
    province: "CH"
  },
  {
    code: "5786",
    value: "D996",
    label: "GESSOPALENA",
    province: "CH"
  },
  {
    code: "5787",
    value: "E052",
    label: "GISSI",
    province: "CH"
  },
  {
    code: "5788",
    value: "E056",
    label: "GIULIANO TEATINO",
    province: "CH"
  },
  {
    code: "5789",
    value: "E243",
    label: "GUARDIAGRELE",
    province: "CH"
  },
  {
    code: "5790",
    value: "E266",
    label: "GUILMI",
    province: "CH"
  },
  {
    code: "5791",
    value: "E424",
    label: "LAMA DEI PELIGNI",
    province: "CH"
  },
  {
    code: "5792",
    value: "E435",
    label: "LANCIANO",
    province: "CH"
  },
  {
    code: "5793",
    value: "E531",
    label: "LENTELLA",
    province: "CH"
  },
  {
    code: "5794",
    value: "E559",
    label: "LETTOPALENA",
    province: "CH"
  },
  {
    code: "5795",
    value: "E611",
    label: "LISCIA",
    province: "CH"
  },
  {
    code: "5796",
    value: "F196",
    label: "MIGLIANICO",
    province: "CH"
  },
  {
    code: "5797",
    value: "F433",
    label: "MONTAZZOLI",
    province: "CH"
  },
  {
    code: "5798",
    value: "F498",
    label: "MONTEFERRANTE",
    province: "CH"
  },
  {
    code: "5799",
    value: "F535",
    label: "MONTELAPIANO",
    province: "CH"
  },
  {
    code: "5800",
    value: "F578",
    label: "MONTENERODOMO",
    province: "CH"
  },
  {
    code: "5801",
    value: "F582",
    label: "MONTEODORISIO",
    province: "CH"
  },
  {
    code: "5802",
    value: "F785",
    label: "MOZZAGROGNA",
    province: "CH"
  },
  {
    code: "5803",
    value: "G128",
    label: "ORSOGNA",
    province: "CH"
  },
  {
    code: "5804",
    value: "G141",
    label: "ORTONA",
    province: "CH"
  },
  {
    code: "5805",
    value: "G237",
    label: "PAGLIETA",
    province: "CH"
  },
  {
    code: "5806",
    value: "G271",
    label: "PALENA",
    province: "CH"
  },
  {
    code: "5807",
    value: "G290",
    label: "PALMOLI",
    province: "CH"
  },
  {
    code: "5808",
    value: "G294",
    label: "PALOMBARO",
    province: "CH"
  },
  {
    code: "5809",
    value: "G434",
    label: "PENNADOMO",
    province: "CH"
  },
  {
    code: "5810",
    value: "G435",
    label: "PENNAPIEDIMONTE",
    province: "CH"
  },
  {
    code: "5811",
    value: "G441",
    label: "PERANO",
    province: "CH"
  },
  {
    code: "5812",
    value: "G724",
    label: "PIZZOFERRATO",
    province: "CH"
  },
  {
    code: "5813",
    value: "G760",
    label: "POGGIOFIORITO",
    province: "CH"
  },
  {
    code: "5814",
    value: "G799",
    label: "POLLUTRI",
    province: "CH"
  },
  {
    code: "5815",
    value: "H052",
    label: "PRETORO",
    province: "CH"
  },
  {
    code: "5816",
    value: "H098",
    label: "QUADRI",
    province: "CH"
  },
  {
    code: "5817",
    value: "H184",
    label: "RAPINO",
    province: "CH"
  },
  {
    code: "5818",
    value: "H320",
    label: "RIPA TEATINA",
    province: "CH"
  },
  {
    code: "5819",
    value: "H424",
    label: "ROCCAMONTEPIANO",
    province: "CH"
  },
  {
    code: "5820",
    value: "H439",
    label: "ROCCA SAN GIOVANNI",
    province: "CH"
  },
  {
    code: "5821",
    value: "H442",
    label: "ROCCASCALEGNA",
    province: "CH"
  },
  {
    code: "5822",
    value: "H448",
    label: "ROCCASPINALVETI",
    province: "CH"
  },
  {
    code: "5823",
    value: "H495",
    label: "ROIO DEL SANGRO",
    province: "CH"
  },
  {
    code: "5824",
    value: "H566",
    label: "ROSELLO",
    province: "CH"
  },
  {
    code: "5825",
    value: "H784",
    label: "SAN BUONO",
    province: "CH"
  },
  {
    code: "5826",
    value: "H923",
    label: "SAN GIOVANNI LIPIONI",
    province: "CH"
  },
  {
    code: "5827",
    value: "D690",
    label: "SAN GIOVANNI TEATINO",
    province: "CH"
  },
  {
    code: "5828",
    value: "H991",
    label: "SAN MARTINO SULLA MARRUCINA",
    province: "CH"
  },
  {
    code: "5829",
    value: "I148",
    label: "SAN SALVO",
    province: "CH"
  },
  {
    code: "5830",
    value: "I244",
    label: "SANTA MARIA IMBARO",
    province: "CH"
  },
  {
    code: "5831",
    value: "I335",
    label: "SANT EUSANIO DEL SANGRO",
    province: "CH"
  },
  {
    code: "5832",
    value: "I394",
    label: "SAN VITO CHIETINO",
    province: "CH"
  },
  {
    code: "5833",
    value: "I520",
    label: "SCERNI",
    province: "CH"
  },
  {
    code: "5834",
    value: "I526",
    label: "SCHIAVI DI ABRUZZO",
    province: "CH"
  },
  {
    code: "5835",
    value: "L047",
    label: "TARANTA PELIGNA",
    province: "CH"
  },
  {
    code: "5836",
    value: "L194",
    label: "TOLLO",
    province: "CH"
  },
  {
    code: "5837",
    value: "L218",
    label: "TORINO DI SANGRO",
    province: "CH"
  },
  {
    code: "5838",
    value: "L224",
    label: "TORNARECCIO",
    province: "CH"
  },
  {
    code: "5839",
    value: "L253",
    label: "TORREBRUNA",
    province: "CH"
  },
  {
    code: "5840",
    value: "L284",
    label: "TORREVECCHIA TEATINA",
    province: "CH"
  },
  {
    code: "5841",
    value: "L291",
    label: "TORRICELLA PELIGNA",
    province: "CH"
  },
  {
    code: "5842",
    value: "L363",
    label: "TREGLIO",
    province: "CH"
  },
  {
    code: "5843",
    value: "L459",
    label: "TUFILLO",
    province: "CH"
  },
  {
    code: "5844",
    value: "L526",
    label: "VACRI",
    province: "CH"
  },
  {
    code: "5845",
    value: "E372",
    label: "VASTO",
    province: "CH"
  },
  {
    code: "5846",
    value: "L961",
    label: "VILLALFONSINA",
    province: "CH"
  },
  {
    code: "5847",
    value: "L964",
    label: "VILLAMAGNA",
    province: "CH"
  },
  {
    code: "5848",
    value: "M022",
    label: "VILLA SANTA MARIA",
    province: "CH"
  },
  {
    code: "5849",
    value: "G613",
    label: "PIETRAFERRAZZANA",
    province: "CH"
  },
  {
    code: "5850",
    value: "D480",
    label: "FALLO",
    province: "CH"
  },
  {
    code: "5851",
    value: "A050",
    label: "ACQUAVIVA COLLECROCE",
    province: "CB"
  },
  {
    code: "5852",
    value: "A616",
    label: "BARANELLO",
    province: "CB"
  },
  {
    code: "5853",
    value: "A930",
    label: "BOJANO",
    province: "CB"
  },
  {
    code: "5854",
    value: "A971",
    label: "BONEFRO",
    province: "CB"
  },
  {
    code: "5855",
    value: "B295",
    label: "BUSSO",
    province: "CB"
  },
  {
    code: "5856",
    value: "B519",
    label: "CAMPOBASSO",
    province: "CB"
  },
  {
    code: "5857",
    value: "B522",
    label: "CAMPOCHIARO",
    province: "CB"
  },
  {
    code: "5858",
    value: "B528",
    label: "CAMPODIPIETRA",
    province: "CB"
  },
  {
    code: "5859",
    value: "B544",
    label: "CAMPOLIETO",
    province: "CB"
  },
  {
    code: "5860",
    value: "B550",
    label: "CAMPOMARINO",
    province: "CB"
  },
  {
    code: "5861",
    value: "B858",
    label: "CASACALENDA",
    province: "CB"
  },
  {
    code: "5862",
    value: "B871",
    label: "CASALCIPRANO",
    province: "CB"
  },
  {
    code: "5863",
    value: "C066",
    label: "CASTELBOTTACCIO",
    province: "CB"
  },
  {
    code: "5864",
    value: "C175",
    label: "CASTELLINO DEL BIFERNO",
    province: "CB"
  },
  {
    code: "5865",
    value: "C197",
    label: "CASTELMAURO",
    province: "CB"
  },
  {
    code: "5866",
    value: "C346",
    label: "CASTROPIGNANO",
    province: "CB"
  },
  {
    code: "5867",
    value: "C486",
    label: "CERCEMAGGIORE",
    province: "CB"
  },
  {
    code: "5868",
    value: "C488",
    label: "CERCEPICCOLA",
    province: "CB"
  },
  {
    code: "5869",
    value: "C764",
    label: "CIVITACAMPOMARANO",
    province: "CB"
  },
  {
    code: "5870",
    value: "C854",
    label: "COLLE D ANCHISE",
    province: "CB"
  },
  {
    code: "5871",
    value: "C875",
    label: "COLLETORTO",
    province: "CB"
  },
  {
    code: "5872",
    value: "C772",
    label: "DURONIA",
    province: "CB"
  },
  {
    code: "5873",
    value: "D550",
    label: "FERRAZZANO",
    province: "CB"
  },
  {
    code: "5874",
    value: "D737",
    label: "FOSSALTO",
    province: "CB"
  },
  {
    code: "5875",
    value: "D896",
    label: "GAMBATESA",
    province: "CB"
  },
  {
    code: "5876",
    value: "E030",
    label: "GILDONE",
    province: "CB"
  },
  {
    code: "5877",
    value: "E244",
    label: "GUARDIALFIERA",
    province: "CB"
  },
  {
    code: "5878",
    value: "E248",
    label: "GUARDIAREGIA",
    province: "CB"
  },
  {
    code: "5879",
    value: "E259",
    label: "GUGLIONESI",
    province: "CB"
  },
  {
    code: "5880",
    value: "E381",
    label: "JELSI",
    province: "CB"
  },
  {
    code: "5881",
    value: "E456",
    label: "LARINO",
    province: "CB"
  },
  {
    code: "5882",
    value: "E599",
    label: "LIMOSANO",
    province: "CB"
  },
  {
    code: "5883",
    value: "E722",
    label: "LUCITO",
    province: "CB"
  },
  {
    code: "5884",
    value: "E748",
    label: "LUPARA",
    province: "CB"
  },
  {
    code: "5885",
    value: "E780",
    label: "MACCHIA VALFORTORE",
    province: "CB"
  },
  {
    code: "5886",
    value: "E799",
    label: "MAFALDA",
    province: "CB"
  },
  {
    code: "5887",
    value: "F055",
    label: "MATRICE",
    province: "CB"
  },
  {
    code: "5888",
    value: "F233",
    label: "MIRABELLO SANNITICO",
    province: "CB"
  },
  {
    code: "5889",
    value: "F294",
    label: "MOLISE",
    province: "CB"
  },
  {
    code: "5890",
    value: "F322",
    label: "MONACILIONI",
    province: "CB"
  },
  {
    code: "5891",
    value: "F391",
    label: "MONTAGANO",
    province: "CB"
  },
  {
    code: "5892",
    value: "F475",
    label: "MONTECILFONE",
    province: "CB"
  },
  {
    code: "5893",
    value: "F495",
    label: "MONTEFALCONE NEL SANNIO",
    province: "CB"
  },
  {
    code: "5894",
    value: "F548",
    label: "MONTELONGO",
    province: "CB"
  },
  {
    code: "5895",
    value: "F569",
    label: "MONTEMITRO",
    province: "CB"
  },
  {
    code: "5896",
    value: "F576",
    label: "MONTENERO DI BISACCIA",
    province: "CB"
  },
  {
    code: "5897",
    value: "F689",
    label: "MONTORIO NEI FRENTANI",
    province: "CB"
  },
  {
    code: "5898",
    value: "F748",
    label: "MORRONE DEL SANNIO",
    province: "CB"
  },
  {
    code: "5899",
    value: "G086",
    label: "ORATINO",
    province: "CB"
  },
  {
    code: "5900",
    value: "G257",
    label: "PALATA",
    province: "CB"
  },
  {
    code: "5901",
    value: "G506",
    label: "PETACCIATO",
    province: "CB"
  },
  {
    code: "5902",
    value: "G512",
    label: "PETRELLA TIFERNINA",
    province: "CB"
  },
  {
    code: "5903",
    value: "G609",
    label: "PIETRACATELLA",
    province: "CB"
  },
  {
    code: "5904",
    value: "G610",
    label: "PIETRACUPA",
    province: "CB"
  },
  {
    code: "5905",
    value: "G910",
    label: "PORTOCANNONE",
    province: "CB"
  },
  {
    code: "5906",
    value: "H083",
    label: "PROVVIDENTI",
    province: "CB"
  },
  {
    code: "5907",
    value: "H273",
    label: "RICCIA",
    province: "CB"
  },
  {
    code: "5908",
    value: "H311",
    label: "RIPABOTTONI",
    province: "CB"
  },
  {
    code: "5909",
    value: "H313",
    label: "RIPALIMOSANI",
    province: "CB"
  },
  {
    code: "5910",
    value: "H454",
    label: "ROCCAVIVARA",
    province: "CB"
  },
  {
    code: "5911",
    value: "H589",
    label: "ROTELLO",
    province: "CB"
  },
  {
    code: "5912",
    value: "H693",
    label: "SALCITO",
    province: "CB"
  },
  {
    code: "5913",
    value: "H782",
    label: "SAN BIASE",
    province: "CB"
  },
  {
    code: "5914",
    value: "H833",
    label: "SAN FELICE DEL MOLISE",
    province: "CB"
  },
  {
    code: "5915",
    value: "H867",
    label: "SAN GIACOMO DEGLI SCHIAVONI",
    province: "CB"
  },
  {
    code: "5916",
    value: "H920",
    label: "SAN GIOVANNI IN GALDO",
    province: "CB"
  },
  {
    code: "5917",
    value: "H928",
    label: "SAN GIULIANO DEL SANNIO",
    province: "CB"
  },
  {
    code: "5918",
    value: "H929",
    label: "SAN GIULIANO DI PUGLIA",
    province: "CB"
  },
  {
    code: "5919",
    value: "H990",
    label: "SAN MARTINO IN PENSILIS",
    province: "CB"
  },
  {
    code: "5920",
    value: "I023",
    label: "SAN MASSIMO",
    province: "CB"
  },
  {
    code: "5921",
    value: "I122",
    label: "SAN POLO MATESE",
    province: "CB"
  },
  {
    code: "5922",
    value: "I181",
    label: "SANTA CROCE DI MAGLIANO",
    province: "CB"
  },
  {
    code: "5923",
    value: "I289",
    label: "SANT ANGELO LIMOSANO",
    province: "CB"
  },
  {
    code: "5924",
    value: "I320",
    label: "SANT ELIA A PIANISI",
    province: "CB"
  },
  {
    code: "5925",
    value: "I618",
    label: "SEPINO",
    province: "CB"
  },
  {
    code: "5926",
    value: "I910",
    label: "SPINETE",
    province: "CB"
  },
  {
    code: "5927",
    value: "L069",
    label: "TAVENNA",
    province: "CB"
  },
  {
    code: "5928",
    value: "L113",
    label: "TERMOLI",
    province: "CB"
  },
  {
    code: "5929",
    value: "L215",
    label: "TORELLA DEL SANNIO",
    province: "CB"
  },
  {
    code: "5930",
    value: "L230",
    label: "TORO",
    province: "CB"
  },
  {
    code: "5931",
    value: "L435",
    label: "TRIVENTO",
    province: "CB"
  },
  {
    code: "5932",
    value: "L458",
    label: "TUFARA",
    province: "CB"
  },
  {
    code: "5933",
    value: "L505",
    label: "URURI",
    province: "CB"
  },
  {
    code: "5934",
    value: "M057",
    label: "VINCHIATURO",
    province: "CB"
  },
  {
    code: "5935",
    value: "A051",
    label: "ACQUAVIVA D ISERNIA",
    province: "IS"
  },
  {
    code: "5936",
    value: "A080",
    label: "AGNONE",
    province: "IS"
  },
  {
    code: "5937",
    value: "A567",
    label: "BAGNOLI DEL TRIGNO",
    province: "IS"
  },
  {
    code: "5938",
    value: "A761",
    label: "BELMONTE DEL SANNIO",
    province: "IS"
  },
  {
    code: "5939",
    value: "B630",
    label: "CANTALUPO NEL SANNIO",
    province: "IS"
  },
  {
    code: "5940",
    value: "B682",
    label: "CAPRACOTTA",
    province: "IS"
  },
  {
    code: "5941",
    value: "B810",
    label: "CAROVILLI",
    province: "IS"
  },
  {
    code: "5942",
    value: "B830",
    label: "CARPINONE",
    province: "IS"
  },
  {
    code: "5943",
    value: "C082",
    label: "CASTEL DEL GIUDICE",
    province: "IS"
  },
  {
    code: "5944",
    value: "C246",
    label: "CASTELPETROSO",
    province: "IS"
  },
  {
    code: "5945",
    value: "C247",
    label: "CASTELPIZZUTO",
    province: "IS"
  },
  {
    code: "5946",
    value: "C270",
    label: "CASTEL SAN VINCENZO",
    province: "IS"
  },
  {
    code: "5947",
    value: "C200",
    label: "CASTELVERRINO",
    province: "IS"
  },
  {
    code: "5948",
    value: "C534",
    label: "CERRO AL VOLTURNO",
    province: "IS"
  },
  {
    code: "5949",
    value: "C620",
    label: "CHIAUCI",
    province: "IS"
  },
  {
    code: "5950",
    value: "C769",
    label: "CIVITANOVA DEL SANNIO",
    province: "IS"
  },
  {
    code: "5951",
    value: "C878",
    label: "COLLI A VOLTURNO",
    province: "IS"
  },
  {
    code: "5952",
    value: "C941",
    label: "CONCA CASALE",
    province: "IS"
  },
  {
    code: "5953",
    value: "D595",
    label: "FILIGNANO",
    province: "IS"
  },
  {
    code: "5954",
    value: "D703",
    label: "FORLI DEL SANNIO",
    province: "IS"
  },
  {
    code: "5955",
    value: "D715",
    label: "FORNELLI",
    province: "IS"
  },
  {
    code: "5956",
    value: "D811",
    label: "FROSOLONE",
    province: "IS"
  },
  {
    code: "5957",
    value: "E335",
    label: "ISERNIA",
    province: "IS"
  },
  {
    code: "5958",
    value: "E669",
    label: "LONGANO",
    province: "IS"
  },
  {
    code: "5959",
    value: "E778",
    label: "MACCHIA D ISERNIA",
    province: "IS"
  },
  {
    code: "5960",
    value: "E779",
    label: "MACCHIAGODENA",
    province: "IS"
  },
  {
    code: "5961",
    value: "F239",
    label: "MIRANDA",
    province: "IS"
  },
  {
    code: "5962",
    value: "F429",
    label: "MONTAQUILA",
    province: "IS"
  },
  {
    code: "5963",
    value: "F580",
    label: "MONTENERO VAL COCCHIARA",
    province: "IS"
  },
  {
    code: "5964",
    value: "F601",
    label: "MONTERODUNI",
    province: "IS"
  },
  {
    code: "5965",
    value: "G486",
    label: "PESCHE",
    province: "IS"
  },
  {
    code: "5966",
    value: "G495",
    label: "PESCOLANCIANO",
    province: "IS"
  },
  {
    code: "5967",
    value: "G497",
    label: "PESCOPENNATARO",
    province: "IS"
  },
  {
    code: "5968",
    value: "G523",
    label: "PETTORANELLO DEL MOLISE",
    province: "IS"
  },
  {
    code: "5969",
    value: "G606",
    label: "PIETRABBONDANTE",
    province: "IS"
  },
  {
    code: "5970",
    value: "G727",
    label: "PIZZONE",
    province: "IS"
  },
  {
    code: "5971",
    value: "B317",
    label: "POGGIO SANNITA",
    province: "IS"
  },
  {
    code: "5972",
    value: "G954",
    label: "POZZILLI",
    province: "IS"
  },
  {
    code: "5973",
    value: "H308",
    label: "RIONERO SANNITICO",
    province: "IS"
  },
  {
    code: "5974",
    value: "H420",
    label: "ROCCAMANDOLFI",
    province: "IS"
  },
  {
    code: "5975",
    value: "H445",
    label: "ROCCASICURA",
    province: "IS"
  },
  {
    code: "5976",
    value: "H458",
    label: "ROCCHETTA A VOLTURNO",
    province: "IS"
  },
  {
    code: "5977",
    value: "I096",
    label: "SAN PIETRO AVELLANA",
    province: "IS"
  },
  {
    code: "5978",
    value: "I189",
    label: "SANT AGAPITO",
    province: "IS"
  },
  {
    code: "5979",
    value: "I238",
    label: "SANTA MARIA DEL MOLISE",
    province: "IS"
  },
  {
    code: "5980",
    value: "I282",
    label: "SANT ANGELO DEL PESCO",
    province: "IS"
  },
  {
    code: "5981",
    value: "B466",
    label: "SANT ELENA SANNITA",
    province: "IS"
  },
  {
    code: "5982",
    value: "I507",
    label: "SCAPOLI",
    province: "IS"
  },
  {
    code: "5983",
    value: "I679",
    label: "SESSANO DEL MOLISE",
    province: "IS"
  },
  {
    code: "5984",
    value: "I682",
    label: "SESTO CAMPANO",
    province: "IS"
  },
  {
    code: "5985",
    value: "L696",
    label: "VASTOGIRARDI",
    province: "IS"
  },
  {
    code: "5986",
    value: "L725",
    label: "VENAFRO",
    province: "IS"
  },
  {
    code: "5987",
    value: "A106",
    label: "AILANO",
    province: "CE"
  },
  {
    code: "5988",
    value: "A200",
    label: "ALIFE",
    province: "CE"
  },
  {
    code: "5989",
    value: "A243",
    label: "ALVIGNANO",
    province: "CE"
  },
  {
    code: "5990",
    value: "A403",
    label: "ARIENZO",
    province: "CE"
  },
  {
    code: "5991",
    value: "A512",
    label: "AVERSA",
    province: "CE"
  },
  {
    code: "5992",
    value: "A579",
    label: "BAIA E LATINA",
    province: "CE"
  },
  {
    code: "5993",
    value: "A755",
    label: "BELLONA",
    province: "CE"
  },
  {
    code: "5994",
    value: "B361",
    label: "CAIANELLO",
    province: "CE"
  },
  {
    code: "5995",
    value: "B362",
    label: "CAIAZZO",
    province: "CE"
  },
  {
    code: "5996",
    value: "B445",
    label: "CALVI RISORTA",
    province: "CE"
  },
  {
    code: "5997",
    value: "B477",
    label: "CAMIGLIANO",
    province: "CE"
  },
  {
    code: "5998",
    value: "B581",
    label: "CANCELLO ED ARNONE",
    province: "CE"
  },
  {
    code: "5999",
    value: "B667",
    label: "CAPODRISE",
    province: "CE"
  },
  {
    code: "6000",
    value: "B704",
    label: "CAPRIATI A VOLTURNO",
    province: "CE"
  },
  {
    code: "6001",
    value: "B715",
    label: "CAPUA",
    province: "CE"
  },
  {
    code: "6002",
    value: "B779",
    label: "CARINARO",
    province: "CE"
  },
  {
    code: "6003",
    value: "B781",
    label: "CARINOLA",
    province: "CE"
  },
  {
    code: "6004",
    value: "B860",
    label: "CASAGIOVE",
    province: "CE"
  },
  {
    code: "6005",
    value: "B872",
    label: "CASAL DI PRINCIPE",
    province: "CE"
  },
  {
    code: "6006",
    value: "B916",
    label: "CASALUCE",
    province: "CE"
  },
  {
    code: "6007",
    value: "B935",
    label: "CASAPULLA",
    province: "CE"
  },
  {
    code: "6008",
    value: "B963",
    label: "CASERTA",
    province: "CE"
  },
  {
    code: "6009",
    value: "B494",
    label: "CASTEL CAMPAGNANO",
    province: "CE"
  },
  {
    code: "6010",
    value: "C097",
    label: "CASTEL DI SASSO",
    province: "CE"
  },
  {
    code: "6011",
    value: "C178",
    label: "CASTELLO DEL MATESE",
    province: "CE"
  },
  {
    code: "6012",
    value: "C211",
    label: "CASTEL MORRONE",
    province: "CE"
  },
  {
    code: "6013",
    value: "C291",
    label: "CASTEL VOLTURNO",
    province: "CE"
  },
  {
    code: "6014",
    value: "C558",
    label: "CERVINO",
    province: "CE"
  },
  {
    code: "6015",
    value: "C561",
    label: "CESA",
    province: "CE"
  },
  {
    code: "6016",
    value: "C716",
    label: "CIORLANO",
    province: "CE"
  },
  {
    code: "6017",
    value: "C939",
    label: "CONCA DELLA CAMPANIA",
    province: "CE"
  },
  {
    code: "6018",
    value: "D228",
    label: "CURTI",
    province: "CE"
  },
  {
    code: "6019",
    value: "D361",
    label: "DRAGONI",
    province: "CE"
  },
  {
    code: "6020",
    value: "D683",
    label: "FONTEGRECA",
    province: "CE"
  },
  {
    code: "6021",
    value: "D709",
    label: "FORMICOLA",
    province: "CE"
  },
  {
    code: "6022",
    value: "D769",
    label: "FRANCOLISE",
    province: "CE"
  },
  {
    code: "6023",
    value: "D799",
    label: "FRIGNANO",
    province: "CE"
  },
  {
    code: "6024",
    value: "D884",
    label: "GALLO MATESE",
    province: "CE"
  },
  {
    code: "6025",
    value: "D886",
    label: "GALLUCCIO",
    province: "CE"
  },
  {
    code: "6026",
    value: "E011",
    label: "GIANO VETUSTO",
    province: "CE"
  },
  {
    code: "6027",
    value: "E039",
    label: "GIOIA SANNITICA",
    province: "CE"
  },
  {
    code: "6028",
    value: "E158",
    label: "GRAZZANISE",
    province: "CE"
  },
  {
    code: "6029",
    value: "E173",
    label: "GRICIGNANO DI AVERSA",
    province: "CE"
  },
  {
    code: "6030",
    value: "E554",
    label: "LETINO",
    province: "CE"
  },
  {
    code: "6031",
    value: "E570",
    label: "LIBERI",
    province: "CE"
  },
  {
    code: "6032",
    value: "E754",
    label: "LUSCIANO",
    province: "CE"
  },
  {
    code: "6033",
    value: "E784",
    label: "MACERATA CAMPANIA",
    province: "CE"
  },
  {
    code: "6034",
    value: "E791",
    label: "MADDALONI",
    province: "CE"
  },
  {
    code: "6035",
    value: "E932",
    label: "MARCIANISE",
    province: "CE"
  },
  {
    code: "6036",
    value: "E998",
    label: "MARZANO APPIO",
    province: "CE"
  },
  {
    code: "6037",
    value: "F203",
    label: "MIGNANO MONTE LUNGO",
    province: "CE"
  },
  {
    code: "6038",
    value: "F352",
    label: "MONDRAGONE",
    province: "CE"
  },
  {
    code: "6039",
    value: "G130",
    label: "ORTA DI ATELLA",
    province: "CE"
  },
  {
    code: "6040",
    value: "G333",
    label: "PARETE",
    province: "CE"
  },
  {
    code: "6041",
    value: "G364",
    label: "PASTORANO",
    province: "CE"
  },
  {
    code: "6042",
    value: "G541",
    label: "PIANA DI MONTE VERNA",
    province: "CE"
  },
  {
    code: "6043",
    value: "G596",
    label: "PIEDIMONTE MATESE",
    province: "CE"
  },
  {
    code: "6044",
    value: "G620",
    label: "PIETRAMELARA",
    province: "CE"
  },
  {
    code: "6045",
    value: "G630",
    label: "PIETRAVAIRANO",
    province: "CE"
  },
  {
    code: "6046",
    value: "G661",
    label: "PIGNATARO MAGGIORE",
    province: "CE"
  },
  {
    code: "6047",
    value: "G849",
    label: "PONTELATONE",
    province: "CE"
  },
  {
    code: "6048",
    value: "G903",
    label: "PORTICO DI CASERTA",
    province: "CE"
  },
  {
    code: "6049",
    value: "G991",
    label: "PRATA SANNITA",
    province: "CE"
  },
  {
    code: "6050",
    value: "G995",
    label: "PRATELLA",
    province: "CE"
  },
  {
    code: "6051",
    value: "H045",
    label: "PRESENZANO",
    province: "CE"
  },
  {
    code: "6052",
    value: "H202",
    label: "RAVISCANINA",
    province: "CE"
  },
  {
    code: "6053",
    value: "H210",
    label: "RECALE",
    province: "CE"
  },
  {
    code: "6054",
    value: "H268",
    label: "RIARDO",
    province: "CE"
  },
  {
    code: "6055",
    value: "H398",
    label: "ROCCA D EVANDRO",
    province: "CE"
  },
  {
    code: "6056",
    value: "H423",
    label: "ROCCAMONFINA",
    province: "CE"
  },
  {
    code: "6057",
    value: "H436",
    label: "ROCCAROMANA",
    province: "CE"
  },
  {
    code: "6058",
    value: "H459",
    label: "ROCCHETTA E CROCE",
    province: "CE"
  },
  {
    code: "6059",
    value: "H165",
    label: "RUVIANO",
    province: "CE"
  },
  {
    code: "6060",
    value: "H798",
    label: "SAN CIPRIANO D AVERSA",
    province: "CE"
  },
  {
    code: "6061",
    value: "H834",
    label: "SAN FELICE A CANCELLO",
    province: "CE"
  },
  {
    code: "6062",
    value: "H939",
    label: "SAN GREGORIO MATESE",
    province: "CE"
  },
  {
    code: "6063",
    value: "H978",
    label: "SAN MARCELLINO",
    province: "CE"
  },
  {
    code: "6064",
    value: "I056",
    label: "SAN NICOLA LA STRADA",
    province: "CE"
  },
  {
    code: "6065",
    value: "I113",
    label: "SAN PIETRO INFINE",
    province: "CE"
  },
  {
    code: "6066",
    value: "I130",
    label: "SAN POTITO SANNITICO",
    province: "CE"
  },
  {
    code: "6067",
    value: "I131",
    label: "SAN PRISCO",
    province: "CE"
  },
  {
    code: "6068",
    value: "I233",
    label: "SANTA MARIA A VICO",
    province: "CE"
  },
  {
    code: "6069",
    value: "I234",
    label: "SANTA MARIA CAPUA VETERE",
    province: "CE"
  },
  {
    code: "6070",
    value: "I247",
    label: "SANTA MARIA LA FOSSA",
    province: "CE"
  },
  {
    code: "6071",
    value: "I261",
    label: "SAN TAMMARO",
    province: "CE"
  },
  {
    code: "6072",
    value: "I273",
    label: "SANT ANGELO D ALIFE",
    province: "CE"
  },
  {
    code: "6073",
    value: "I306",
    label: "SANT ARPINO",
    province: "CE"
  },
  {
    code: "6074",
    value: "I676",
    label: "SESSA AURUNCA",
    province: "CE"
  },
  {
    code: "6075",
    value: "I885",
    label: "SPARANISE",
    province: "CE"
  },
  {
    code: "6076",
    value: "I993",
    label: "SUCCIVO",
    province: "CE"
  },
  {
    code: "6077",
    value: "L083",
    label: "TEANO",
    province: "CE"
  },
  {
    code: "6078",
    value: "L155",
    label: "TEVEROLA",
    province: "CE"
  },
  {
    code: "6079",
    value: "L205",
    label: "TORA E PICCILLI",
    province: "CE"
  },
  {
    code: "6080",
    value: "L379",
    label: "TRENTOLA DUCENTA",
    province: "CE"
  },
  {
    code: "6081",
    value: "L540",
    label: "VAIRANO PATENORA",
    province: "CE"
  },
  {
    code: "6082",
    value: "L594",
    label: "VALLE AGRICOLA",
    province: "CE"
  },
  {
    code: "6083",
    value: "L591",
    label: "VALLE DI MADDALONI",
    province: "CE"
  },
  {
    code: "6084",
    value: "D801",
    label: "VILLA DI BRIANO",
    province: "CE"
  },
  {
    code: "6085",
    value: "L844",
    label: "VILLA LITERNO",
    province: "CE"
  },
  {
    code: "6086",
    value: "M092",
    label: "VITULAZIO",
    province: "CE"
  },
  {
    code: "6087",
    value: "D471",
    label: "FALCIANO DEL MASSICO",
    province: "CE"
  },
  {
    code: "6088",
    value: "M262",
    label: "CELLOLE",
    province: "CE"
  },
  {
    code: "6089",
    value: "M260",
    label: "CASAPESENNA",
    province: "CE"
  },
  {
    code: "6090",
    value: "F043",
    label: "SAN MARCO EVANGELISTA",
    province: "CE"
  },
  {
    code: "6091",
    value: "A110",
    label: "AIROLA",
    province: "BN"
  },
  {
    code: "6092",
    value: "A265",
    label: "AMOROSI",
    province: "BN"
  },
  {
    code: "6093",
    value: "A328",
    label: "APICE",
    province: "BN"
  },
  {
    code: "6094",
    value: "A330",
    label: "APOLLOSA",
    province: "BN"
  },
  {
    code: "6095",
    value: "A431",
    label: "ARPAIA",
    province: "BN"
  },
  {
    code: "6096",
    value: "A432",
    label: "ARPAISE",
    province: "BN"
  },
  {
    code: "6097",
    value: "A696",
    label: "BASELICE",
    province: "BN"
  },
  {
    code: "6098",
    value: "A783",
    label: "BENEVENTO",
    province: "BN"
  },
  {
    code: "6099",
    value: "A970",
    label: "BONEA",
    province: "BN"
  },
  {
    code: "6100",
    value: "B239",
    label: "BUCCIANO",
    province: "BN"
  },
  {
    code: "6101",
    value: "B267",
    label: "BUONALBERGO",
    province: "BN"
  },
  {
    code: "6102",
    value: "B444",
    label: "CALVI",
    province: "BN"
  },
  {
    code: "6103",
    value: "B541",
    label: "CAMPOLATTARO",
    province: "BN"
  },
  {
    code: "6104",
    value: "B542",
    label: "CAMPOLI DEL MONTE TABURNO",
    province: "BN"
  },
  {
    code: "6105",
    value: "B873",
    label: "CASALDUNI",
    province: "BN"
  },
  {
    code: "6106",
    value: "C106",
    label: "CASTELFRANCO IN MISCANO",
    province: "BN"
  },
  {
    code: "6107",
    value: "C245",
    label: "CASTELPAGANO",
    province: "BN"
  },
  {
    code: "6108",
    value: "C250",
    label: "CASTELPOTO",
    province: "BN"
  },
  {
    code: "6109",
    value: "C280",
    label: "CASTELVENERE",
    province: "BN"
  },
  {
    code: "6110",
    value: "C284",
    label: "CASTELVETERE IN VAL FORTORE",
    province: "BN"
  },
  {
    code: "6111",
    value: "C359",
    label: "CAUTANO",
    province: "BN"
  },
  {
    code: "6112",
    value: "C476",
    label: "CEPPALONI",
    province: "BN"
  },
  {
    code: "6113",
    value: "C525",
    label: "CERRETO SANNITA",
    province: "BN"
  },
  {
    code: "6114",
    value: "C719",
    label: "CIRCELLO",
    province: "BN"
  },
  {
    code: "6115",
    value: "C846",
    label: "COLLE SANNITA",
    province: "BN"
  },
  {
    code: "6116",
    value: "D230",
    label: "CUSANO MUTRI",
    province: "BN"
  },
  {
    code: "6117",
    value: "D380",
    label: "DUGENTA",
    province: "BN"
  },
  {
    code: "6118",
    value: "D386",
    label: "DURAZZANO",
    province: "BN"
  },
  {
    code: "6119",
    value: "D469",
    label: "FAICCHIO",
    province: "BN"
  },
  {
    code: "6120",
    value: "D644",
    label: "FOGLIANISE",
    province: "BN"
  },
  {
    code: "6121",
    value: "D650",
    label: "FOIANO DI VAL FORTORE",
    province: "BN"
  },
  {
    code: "6122",
    value: "D693",
    label: "FORCHIA",
    province: "BN"
  },
  {
    code: "6123",
    value: "D755",
    label: "FRAGNETO L ABATE",
    province: "BN"
  },
  {
    code: "6124",
    value: "D756",
    label: "FRAGNETO MONFORTE",
    province: "BN"
  },
  {
    code: "6125",
    value: "D784",
    label: "FRASSO TELESINO",
    province: "BN"
  },
  {
    code: "6126",
    value: "E034",
    label: "GINESTRA DEGLI SCHIAVONI",
    province: "BN"
  },
  {
    code: "6127",
    value: "E249",
    label: "GUARDIA SANFRAMONDI",
    province: "BN"
  },
  {
    code: "6128",
    value: "E589",
    label: "LIMATOLA",
    province: "BN"
  },
  {
    code: "6129",
    value: "F113",
    label: "MELIZZANO",
    province: "BN"
  },
  {
    code: "6130",
    value: "F274",
    label: "MOIANO",
    province: "BN"
  },
  {
    code: "6131",
    value: "F287",
    label: "MOLINARA",
    province: "BN"
  },
  {
    code: "6132",
    value: "F494",
    label: "MONTEFALCONE DI VAL FORTORE",
    province: "BN"
  },
  {
    code: "6133",
    value: "F636",
    label: "MONTESARCHIO",
    province: "BN"
  },
  {
    code: "6134",
    value: "F717",
    label: "MORCONE",
    province: "BN"
  },
  {
    code: "6135",
    value: "G227",
    label: "PADULI",
    province: "BN"
  },
  {
    code: "6136",
    value: "G243",
    label: "PAGO VEIANO",
    province: "BN"
  },
  {
    code: "6137",
    value: "G311",
    label: "PANNARANO",
    province: "BN"
  },
  {
    code: "6138",
    value: "G318",
    label: "PAOLISI",
    province: "BN"
  },
  {
    code: "6139",
    value: "G386",
    label: "PAUPISI",
    province: "BN"
  },
  {
    code: "6140",
    value: "G494",
    label: "PESCO SANNITA",
    province: "BN"
  },
  {
    code: "6141",
    value: "G626",
    label: "PIETRAROJA",
    province: "BN"
  },
  {
    code: "6142",
    value: "G631",
    label: "PIETRELCINA",
    province: "BN"
  },
  {
    code: "6143",
    value: "G827",
    label: "PONTE",
    province: "BN"
  },
  {
    code: "6144",
    value: "G848",
    label: "PONTELANDOLFO",
    province: "BN"
  },
  {
    code: "6145",
    value: "H087",
    label: "PUGLIANELLO",
    province: "BN"
  },
  {
    code: "6146",
    value: "H227",
    label: "REINO",
    province: "BN"
  },
  {
    code: "6147",
    value: "H764",
    label: "SAN BARTOLOMEO IN GALDO",
    province: "BN"
  },
  {
    code: "6148",
    value: "H894",
    label: "SAN GIORGIO DEL SANNIO",
    province: "BN"
  },
  {
    code: "6149",
    value: "H898",
    label: "SAN GIORGIO LA MOLARA",
    province: "BN"
  },
  {
    code: "6150",
    value: "H953",
    label: "SAN LEUCIO DEL SANNIO",
    province: "BN"
  },
  {
    code: "6151",
    value: "H955",
    label: "SAN LORENZELLO",
    province: "BN"
  },
  {
    code: "6152",
    value: "H967",
    label: "SAN LORENZO MAGGIORE",
    province: "BN"
  },
  {
    code: "6153",
    value: "H973",
    label: "SAN LUPO",
    province: "BN"
  },
  {
    code: "6154",
    value: "H984",
    label: "SAN MARCO DEI CAVOTI",
    province: "BN"
  },
  {
    code: "6155",
    value: "I002",
    label: "SAN MARTINO SANNITA",
    province: "BN"
  },
  {
    code: "6156",
    value: "I049",
    label: "SAN NAZZARO",
    province: "BN"
  },
  {
    code: "6157",
    value: "I062",
    label: "SAN NICOLA MANFREDI",
    province: "BN"
  },
  {
    code: "6158",
    value: "I145",
    label: "SAN SALVATORE TELESINO",
    province: "BN"
  },
  {
    code: "6159",
    value: "I179",
    label: "SANTA CROCE DEL SANNIO",
    province: "BN"
  },
  {
    code: "6160",
    value: "I197",
    label: "SANT AGATA DE GOTI",
    province: "BN"
  },
  {
    code: "6161",
    value: "I277",
    label: "SANT ANGELO A CUPOLO",
    province: "BN"
  },
  {
    code: "6162",
    value: "I455",
    label: "SASSINORO",
    province: "BN"
  },
  {
    code: "6163",
    value: "I809",
    label: "SOLOPACA",
    province: "BN"
  },
  {
    code: "6164",
    value: "L086",
    label: "TELESE TERME",
    province: "BN"
  },
  {
    code: "6165",
    value: "L185",
    label: "TOCCO CAUDIO",
    province: "BN"
  },
  {
    code: "6166",
    value: "L254",
    label: "TORRECUSO",
    province: "BN"
  },
  {
    code: "6167",
    value: "M093",
    label: "VITULANO",
    province: "BN"
  },
  {
    code: "6168",
    value: "F557",
    label: "SANT ARCANGELO TRIMONTE",
    province: "BN"
  },
  {
    code: "6169",
    value: "A024",
    label: "ACERRA",
    province: "NA"
  },
  {
    code: "6170",
    value: "A064",
    label: "AFRAGOLA",
    province: "NA"
  },
  {
    code: "6171",
    value: "A068",
    label: "AGEROLA",
    province: "NA"
  },
  {
    code: "6172",
    value: "A268",
    label: "ANACAPRI",
    province: "NA"
  },
  {
    code: "6173",
    value: "A455",
    label: "ARZANO",
    province: "NA"
  },
  {
    code: "6174",
    value: "A535",
    label: "BACOLI",
    province: "NA"
  },
  {
    code: "6175",
    value: "A617",
    label: "BARANO D ISCHIA",
    province: "NA"
  },
  {
    code: "6176",
    value: "B076",
    label: "BOSCOREALE",
    province: "NA"
  },
  {
    code: "6177",
    value: "B077",
    label: "BOSCOTRECASE",
    province: "NA"
  },
  {
    code: "6178",
    value: "B227",
    label: "BRUSCIANO",
    province: "NA"
  },
  {
    code: "6179",
    value: "B371",
    label: "CAIVANO",
    province: "NA"
  },
  {
    code: "6180",
    value: "B452",
    label: "CALVIZZANO",
    province: "NA"
  },
  {
    code: "6181",
    value: "B565",
    label: "CAMPOSANO",
    province: "NA"
  },
  {
    code: "6182",
    value: "B696",
    label: "CAPRI",
    province: "NA"
  },
  {
    code: "6183",
    value: "B740",
    label: "CARBONARA DI NOLA",
    province: "NA"
  },
  {
    code: "6184",
    value: "B759",
    label: "CARDITO",
    province: "NA"
  },
  {
    code: "6185",
    value: "B905",
    label: "CASALNUOVO DI NAPOLI",
    province: "NA"
  },
  {
    code: "6186",
    value: "B922",
    label: "CASAMARCIANO",
    province: "NA"
  },
  {
    code: "6187",
    value: "B924",
    label: "CASAMICCIOLA TERME",
    province: "NA"
  },
  {
    code: "6188",
    value: "B925",
    label: "CASANDRINO",
    province: "NA"
  },
  {
    code: "6189",
    value: "B946",
    label: "CASAVATORE",
    province: "NA"
  },
  {
    code: "6190",
    value: "B980",
    label: "CASOLA DI NAPOLI",
    province: "NA"
  },
  {
    code: "6191",
    value: "B990",
    label: "CASORIA",
    province: "NA"
  },
  {
    code: "6192",
    value: "C129",
    label: "CASTELLAMMARE DI STABIA",
    province: "NA"
  },
  {
    code: "6193",
    value: "C188",
    label: "CASTELLO DI CISTERNA",
    province: "NA"
  },
  {
    code: "6194",
    value: "C495",
    label: "CERCOLA",
    province: "NA"
  },
  {
    code: "6195",
    value: "C675",
    label: "CICCIANO",
    province: "NA"
  },
  {
    code: "6196",
    value: "C697",
    label: "CIMITILE",
    province: "NA"
  },
  {
    code: "6197",
    value: "C929",
    label: "COMIZIANO",
    province: "NA"
  },
  {
    code: "6198",
    value: "D170",
    label: "CRISPANO",
    province: "NA"
  },
  {
    code: "6199",
    value: "D702",
    label: "FORIO",
    province: "NA"
  },
  {
    code: "6200",
    value: "D789",
    label: "FRATTAMAGGIORE",
    province: "NA"
  },
  {
    code: "6201",
    value: "D790",
    label: "FRATTAMINORE",
    province: "NA"
  },
  {
    code: "6202",
    value: "E054",
    label: "GIUGLIANO IN CAMPANIA",
    province: "NA"
  },
  {
    code: "6203",
    value: "E131",
    label: "GRAGNANO",
    province: "NA"
  },
  {
    code: "6204",
    value: "E224",
    label: "GRUMO NEVANO",
    province: "NA"
  },
  {
    code: "6205",
    value: "E329",
    label: "ISCHIA",
    province: "NA"
  },
  {
    code: "6206",
    value: "E396",
    label: "LACCO AMENO",
    province: "NA"
  },
  {
    code: "6207",
    value: "E557",
    label: "LETTERE",
    province: "NA"
  },
  {
    code: "6208",
    value: "E620",
    label: "LIVERI",
    province: "NA"
  },
  {
    code: "6209",
    value: "E906",
    label: "MARANO DI NAPOLI",
    province: "NA"
  },
  {
    code: "6210",
    value: "E954",
    label: "MARIGLIANELLA",
    province: "NA"
  },
  {
    code: "6211",
    value: "E955",
    label: "MARIGLIANO",
    province: "NA"
  },
  {
    code: "6212",
    value: "F030",
    label: "MASSA LUBRENSE",
    province: "NA"
  },
  {
    code: "6213",
    value: "F111",
    label: "MELITO DI NAPOLI",
    province: "NA"
  },
  {
    code: "6214",
    value: "F162",
    label: "META",
    province: "NA"
  },
  {
    code: "6215",
    value: "F488",
    label: "MONTE DI PROCIDA",
    province: "NA"
  },
  {
    code: "6216",
    value: "F799",
    label: "MUGNANO DI NAPOLI",
    province: "NA"
  },
  {
    code: "6217",
    value: "F839",
    label: "NAPOLI",
    province: "NA"
  },
  {
    code: "6218",
    value: "F924",
    label: "NOLA",
    province: "NA"
  },
  {
    code: "6219",
    value: "G190",
    label: "OTTAVIANO",
    province: "NA"
  },
  {
    code: "6220",
    value: "G283",
    label: "PALMA CAMPANIA",
    province: "NA"
  },
  {
    code: "6221",
    value: "G568",
    label: "PIANO DI SORRENTO",
    province: "NA"
  },
  {
    code: "6222",
    value: "G670",
    label: "PIMONTE",
    province: "NA"
  },
  {
    code: "6223",
    value: "G762",
    label: "POGGIOMARINO",
    province: "NA"
  },
  {
    code: "6224",
    value: "G795",
    label: "POLLENA TROCCHIA",
    province: "NA"
  },
  {
    code: "6225",
    value: "G812",
    label: "POMIGLIANO D ARCO",
    province: "NA"
  },
  {
    code: "6226",
    value: "G813",
    label: "POMPEI",
    province: "NA"
  },
  {
    code: "6227",
    value: "G902",
    label: "PORTICI",
    province: "NA"
  },
  {
    code: "6228",
    value: "G964",
    label: "POZZUOLI",
    province: "NA"
  },
  {
    code: "6229",
    value: "H072",
    label: "PROCIDA",
    province: "NA"
  },
  {
    code: "6230",
    value: "H101",
    label: "QUALIANO",
    province: "NA"
  },
  {
    code: "6231",
    value: "H114",
    label: "QUARTO",
    province: "NA"
  },
  {
    code: "6232",
    value: "H243",
    label: "ERCOLANO",
    province: "NA"
  },
  {
    code: "6233",
    value: "H433",
    label: "ROCCARAINOLA",
    province: "NA"
  },
  {
    code: "6234",
    value: "H860",
    label: "SAN GENNARO VESUVIANO",
    province: "NA"
  },
  {
    code: "6235",
    value: "H892",
    label: "SAN GIORGIO A CREMANO",
    province: "NA"
  },
  {
    code: "6236",
    value: "H931",
    label: "SAN GIUSEPPE VESUVIANO",
    province: "NA"
  },
  {
    code: "6237",
    value: "I073",
    label: "SAN PAOLO BEL SITO",
    province: "NA"
  },
  {
    code: "6238",
    value: "I151",
    label: "SAN SEBASTIANO AL VESUVIO",
    province: "NA"
  },
  {
    code: "6239",
    value: "I208",
    label: "SANT AGNELLO",
    province: "NA"
  },
  {
    code: "6240",
    value: "I262",
    label: "SANT ANASTASIA",
    province: "NA"
  },
  {
    code: "6241",
    value: "I293",
    label: "SANT ANTIMO",
    province: "NA"
  },
  {
    code: "6242",
    value: "I300",
    label: "SANT ANTONIO ABATE",
    province: "NA"
  },
  {
    code: "6243",
    value: "I391",
    label: "SAN VITALIANO",
    province: "NA"
  },
  {
    code: "6244",
    value: "I469",
    label: "SAVIANO",
    province: "NA"
  },
  {
    code: "6245",
    value: "I540",
    label: "SCISCIANO",
    province: "NA"
  },
  {
    code: "6246",
    value: "I652",
    label: "SERRARA FONTANA",
    province: "NA"
  },
  {
    code: "6247",
    value: "I820",
    label: "SOMMA VESUVIANA",
    province: "NA"
  },
  {
    code: "6248",
    value: "I862",
    label: "SORRENTO",
    province: "NA"
  },
  {
    code: "6249",
    value: "I978",
    label: "STRIANO",
    province: "NA"
  },
  {
    code: "6250",
    value: "L142",
    label: "TERZIGNO",
    province: "NA"
  },
  {
    code: "6251",
    value: "L245",
    label: "TORRE ANNUNZIATA",
    province: "NA"
  },
  {
    code: "6252",
    value: "L259",
    label: "TORRE DEL GRECO",
    province: "NA"
  },
  {
    code: "6253",
    value: "L460",
    label: "TUFINO",
    province: "NA"
  },
  {
    code: "6254",
    value: "L845",
    label: "VICO EQUENSE",
    province: "NA"
  },
  {
    code: "6255",
    value: "G309",
    label: "VILLARICCA",
    province: "NA"
  },
  {
    code: "6256",
    value: "M072",
    label: "VISCIANO",
    province: "NA"
  },
  {
    code: "6257",
    value: "M115",
    label: "VOLLA",
    province: "NA"
  },
  {
    code: "6258",
    value: "M273",
    label: "SANTA MARIA LA CARITA",
    province: "NA"
  },
  {
    code: "6259",
    value: "M280",
    label: "TRECASE",
    province: "NA"
  },
  {
    code: "6260",
    value: "M289",
    label: "MASSA DI SOMMA",
    province: "NA"
  },
  {
    code: "6261",
    value: "A101",
    label: "AIELLO DEL SABATO",
    province: "AV"
  },
  {
    code: "6262",
    value: "A228",
    label: "ALTAVILLA IRPINA",
    province: "AV"
  },
  {
    code: "6263",
    value: "A284",
    label: "ANDRETTA",
    province: "AV"
  },
  {
    code: "6264",
    value: "A347",
    label: "AQUILONIA",
    province: "AV"
  },
  {
    code: "6265",
    value: "A399",
    label: "ARIANO IRPINO",
    province: "AV"
  },
  {
    code: "6266",
    value: "A489",
    label: "ATRIPALDA",
    province: "AV"
  },
  {
    code: "6267",
    value: "A508",
    label: "AVELLA",
    province: "AV"
  },
  {
    code: "6268",
    value: "A509",
    label: "AVELLINO",
    province: "AV"
  },
  {
    code: "6269",
    value: "A566",
    label: "BAGNOLI IRPINO",
    province: "AV"
  },
  {
    code: "6270",
    value: "A580",
    label: "BAIANO",
    province: "AV"
  },
  {
    code: "6271",
    value: "A881",
    label: "BISACCIA",
    province: "AV"
  },
  {
    code: "6272",
    value: "A975",
    label: "BONITO",
    province: "AV"
  },
  {
    code: "6273",
    value: "B367",
    label: "CAIRANO",
    province: "AV"
  },
  {
    code: "6274",
    value: "B374",
    label: "CALABRITTO",
    province: "AV"
  },
  {
    code: "6275",
    value: "B415",
    label: "CALITRI",
    province: "AV"
  },
  {
    code: "6276",
    value: "B590",
    label: "CANDIDA",
    province: "AV"
  },
  {
    code: "6277",
    value: "B674",
    label: "CAPOSELE",
    province: "AV"
  },
  {
    code: "6278",
    value: "B706",
    label: "CAPRIGLIA IRPINA",
    province: "AV"
  },
  {
    code: "6279",
    value: "B776",
    label: "CARIFE",
    province: "AV"
  },
  {
    code: "6280",
    value: "B866",
    label: "CASALBORE",
    province: "AV"
  },
  {
    code: "6281",
    value: "B997",
    label: "CASSANO IRPINO",
    province: "AV"
  },
  {
    code: "6282",
    value: "C058",
    label: "CASTEL BARONIA",
    province: "AV"
  },
  {
    code: "6283",
    value: "C105",
    label: "CASTELFRANCI",
    province: "AV"
  },
  {
    code: "6284",
    value: "C283",
    label: "CASTELVETERE SUL CALORE",
    province: "AV"
  },
  {
    code: "6285",
    value: "C557",
    label: "CERVINARA",
    province: "AV"
  },
  {
    code: "6286",
    value: "C576",
    label: "CESINALI",
    province: "AV"
  },
  {
    code: "6287",
    value: "C606",
    label: "CHIANCHE",
    province: "AV"
  },
  {
    code: "6288",
    value: "C659",
    label: "CHIUSANO DI SAN DOMENICO",
    province: "AV"
  },
  {
    code: "6289",
    value: "C971",
    label: "CONTRADA",
    province: "AV"
  },
  {
    code: "6290",
    value: "C976",
    label: "CONZA DELLA CAMPANIA",
    province: "AV"
  },
  {
    code: "6291",
    value: "D331",
    label: "DOMICELLA",
    province: "AV"
  },
  {
    code: "6292",
    value: "D638",
    label: "FLUMERI",
    province: "AV"
  },
  {
    code: "6293",
    value: "D671",
    label: "FONTANAROSA",
    province: "AV"
  },
  {
    code: "6294",
    value: "D701",
    label: "FORINO",
    province: "AV"
  },
  {
    code: "6295",
    value: "D798",
    label: "FRIGENTO",
    province: "AV"
  },
  {
    code: "6296",
    value: "D998",
    label: "GESUALDO",
    province: "AV"
  },
  {
    code: "6297",
    value: "E161",
    label: "GRECI",
    province: "AV"
  },
  {
    code: "6298",
    value: "E206",
    label: "GROTTAMINARDA",
    province: "AV"
  },
  {
    code: "6299",
    value: "E214",
    label: "GROTTOLELLA",
    province: "AV"
  },
  {
    code: "6300",
    value: "E245",
    label: "GUARDIA LOMBARDI",
    province: "AV"
  },
  {
    code: "6301",
    value: "E397",
    label: "LACEDONIA",
    province: "AV"
  },
  {
    code: "6302",
    value: "E448",
    label: "LAPIO",
    province: "AV"
  },
  {
    code: "6303",
    value: "E487",
    label: "LAURO",
    province: "AV"
  },
  {
    code: "6304",
    value: "E605",
    label: "LIONI",
    province: "AV"
  },
  {
    code: "6305",
    value: "E746",
    label: "LUOGOSANO",
    province: "AV"
  },
  {
    code: "6306",
    value: "E891",
    label: "MANOCALZATI",
    province: "AV"
  },
  {
    code: "6307",
    value: "E997",
    label: "MARZANO DI NOLA",
    province: "AV"
  },
  {
    code: "6308",
    value: "F110",
    label: "MELITO IRPINO",
    province: "AV"
  },
  {
    code: "6309",
    value: "F141",
    label: "MERCOGLIANO",
    province: "AV"
  },
  {
    code: "6310",
    value: "F230",
    label: "MIRABELLA ECLANO",
    province: "AV"
  },
  {
    code: "6311",
    value: "F397",
    label: "MONTAGUTO",
    province: "AV"
  },
  {
    code: "6312",
    value: "F448",
    label: "MONTECALVO IRPINO",
    province: "AV"
  },
  {
    code: "6313",
    value: "F491",
    label: "MONTEFALCIONE",
    province: "AV"
  },
  {
    code: "6314",
    value: "F506",
    label: "MONTEFORTE IRPINO",
    province: "AV"
  },
  {
    code: "6315",
    value: "F511",
    label: "MONTEFREDANE",
    province: "AV"
  },
  {
    code: "6316",
    value: "F512",
    label: "MONTEFUSCO",
    province: "AV"
  },
  {
    code: "6317",
    value: "F546",
    label: "MONTELLA",
    province: "AV"
  },
  {
    code: "6318",
    value: "F559",
    label: "MONTEMARANO",
    province: "AV"
  },
  {
    code: "6319",
    value: "F566",
    label: "MONTEMILETTO",
    province: "AV"
  },
  {
    code: "6320",
    value: "F660",
    label: "MONTEVERDE",
    province: "AV"
  },
  {
    code: "6323",
    value: "F744",
    label: "MORRA DE SANCTIS",
    province: "AV"
  },
  {
    code: "6324",
    value: "F762",
    label: "MOSCHIANO",
    province: "AV"
  },
  {
    code: "6325",
    value: "F798",
    label: "MUGNANO DEL CARDINALE",
    province: "AV"
  },
  {
    code: "6326",
    value: "F988",
    label: "NUSCO",
    province: "AV"
  },
  {
    code: "6327",
    value: "G165",
    label: "OSPEDALETTO D ALPINOLO",
    province: "AV"
  },
  {
    code: "6328",
    value: "G242",
    label: "PAGO DEL VALLO DI LAURO",
    province: "AV"
  },
  {
    code: "6329",
    value: "G340",
    label: "PAROLISE",
    province: "AV"
  },
  {
    code: "6330",
    value: "G370",
    label: "PATERNOPOLI",
    province: "AV"
  },
  {
    code: "6331",
    value: "G519",
    label: "PETRURO IRPINO",
    province: "AV"
  },
  {
    code: "6332",
    value: "G611",
    label: "PIETRADEFUSI",
    province: "AV"
  },
  {
    code: "6333",
    value: "G629",
    label: "PIETRASTORNINA",
    province: "AV"
  },
  {
    code: "6334",
    value: "G990",
    label: "PRATA DI PRINCIPATO ULTRA",
    province: "AV"
  },
  {
    code: "6335",
    value: "H006",
    label: "PRATOLA SERRA",
    province: "AV"
  },
  {
    code: "6336",
    value: "H097",
    label: "QUADRELLE",
    province: "AV"
  },
  {
    code: "6337",
    value: "H128",
    label: "QUINDICI",
    province: "AV"
  },
  {
    code: "6338",
    value: "H382",
    label: "ROCCABASCERANA",
    province: "AV"
  },
  {
    code: "6339",
    value: "H438",
    label: "ROCCA SAN FELICE",
    province: "AV"
  },
  {
    code: "6340",
    value: "H592",
    label: "ROTONDI",
    province: "AV"
  },
  {
    code: "6341",
    value: "H733",
    label: "SALZA IRPINA",
    province: "AV"
  },
  {
    code: "6342",
    value: "H975",
    label: "SAN MANGO SUL CALORE",
    province: "AV"
  },
  {
    code: "6343",
    value: "I016",
    label: "SAN MARTINO VALLE CAUDINA",
    province: "AV"
  },
  {
    code: "6344",
    value: "I034",
    label: "SAN MICHELE DI SERINO",
    province: "AV"
  },
  {
    code: "6345",
    value: "I061",
    label: "SAN NICOLA BARONIA",
    province: "AV"
  },
  {
    code: "6346",
    value: "I129",
    label: "SAN POTITO ULTRA",
    province: "AV"
  },
  {
    code: "6347",
    value: "I163",
    label: "SAN SOSSIO BARONIA",
    province: "AV"
  },
  {
    code: "6348",
    value: "I219",
    label: "SANTA LUCIA DI SERINO",
    province: "AV"
  },
  {
    code: "6349",
    value: "I264",
    label: "SANT ANDREA DI CONZA",
    province: "AV"
  },
  {
    code: "6350",
    value: "I279",
    label: "SANT ANGELO ALL ESCA",
    province: "AV"
  },
  {
    code: "6351",
    value: "I280",
    label: "SANT ANGELO A SCALA",
    province: "AV"
  },
  {
    code: "6352",
    value: "I281",
    label: "SANT ANGELO DEI LOMBARDI",
    province: "AV"
  },
  {
    code: "6353",
    value: "I301",
    label: "SANTA PAOLINA",
    province: "AV"
  },
  {
    code: "6354",
    value: "I357",
    label: "SANTO STEFANO DEL SOLE",
    province: "AV"
  },
  {
    code: "6355",
    value: "I471",
    label: "SAVIGNANO IRPINO",
    province: "AV"
  },
  {
    code: "6356",
    value: "I493",
    label: "SCAMPITELLA",
    province: "AV"
  },
  {
    code: "6357",
    value: "I606",
    label: "SENERCHIA",
    province: "AV"
  },
  {
    code: "6358",
    value: "I630",
    label: "SERINO",
    province: "AV"
  },
  {
    code: "6359",
    value: "I756",
    label: "SIRIGNANO",
    province: "AV"
  },
  {
    code: "6360",
    value: "I805",
    label: "SOLOFRA",
    province: "AV"
  },
  {
    code: "6361",
    value: "I843",
    label: "SORBO SERPICO",
    province: "AV"
  },
  {
    code: "6362",
    value: "I893",
    label: "SPERONE",
    province: "AV"
  },
  {
    code: "6363",
    value: "I990",
    label: "STURNO",
    province: "AV"
  },
  {
    code: "6364",
    value: "L004",
    label: "SUMMONTE",
    province: "AV"
  },
  {
    code: "6365",
    value: "L061",
    label: "TAURANO",
    province: "AV"
  },
  {
    code: "6366",
    value: "L062",
    label: "TAURASI",
    province: "AV"
  },
  {
    code: "6367",
    value: "L102",
    label: "TEORA",
    province: "AV"
  },
  {
    code: "6368",
    value: "L214",
    label: "TORELLA DEI LOMBARDI",
    province: "AV"
  },
  {
    code: "6369",
    value: "L272",
    label: "TORRE LE NOCELLE",
    province: "AV"
  },
  {
    code: "6370",
    value: "L301",
    label: "TORRIONI",
    province: "AV"
  },
  {
    code: "6371",
    value: "L399",
    label: "TREVICO",
    province: "AV"
  },
  {
    code: "6372",
    value: "L461",
    label: "TUFO",
    province: "AV"
  },
  {
    code: "6373",
    value: "L589",
    label: "VALLATA",
    province: "AV"
  },
  {
    code: "6374",
    value: "L616",
    label: "VALLESACCARDA",
    province: "AV"
  },
  {
    code: "6375",
    value: "L739",
    label: "VENTICANO",
    province: "AV"
  },
  {
    code: "6376",
    value: "L965",
    label: "VILLAMAINA",
    province: "AV"
  },
  {
    code: "6377",
    value: "L973",
    label: "VILLANOVA DEL BATTISTA",
    province: "AV"
  },
  {
    code: "6378",
    value: "M130",
    label: "VOLTURARA IRPINA",
    province: "AV"
  },
  {
    code: "6379",
    value: "M203",
    label: "ZUNGOLI",
    province: "AV"
  },
  {
    code: "6380",
    value: "A023",
    label: "ACERNO",
    province: "SA"
  },
  {
    code: "6381",
    value: "A091",
    label: "AGROPOLI",
    province: "SA"
  },
  {
    code: "6382",
    value: "A128",
    label: "ALBANELLA",
    province: "SA"
  },
  {
    code: "6383",
    value: "A186",
    label: "ALFANO",
    province: "SA"
  },
  {
    code: "6384",
    value: "A230",
    label: "ALTAVILLA SILENTINA",
    province: "SA"
  },
  {
    code: "6385",
    value: "A251",
    label: "AMALFI",
    province: "SA"
  },
  {
    code: "6386",
    value: "A294",
    label: "ANGRI",
    province: "SA"
  },
  {
    code: "6387",
    value: "A343",
    label: "AQUARA",
    province: "SA"
  },
  {
    code: "6388",
    value: "A460",
    label: "ASCEA",
    province: "SA"
  },
  {
    code: "6389",
    value: "A484",
    label: "ATENA LUCANA",
    province: "SA"
  },
  {
    code: "6390",
    value: "A487",
    label: "ATRANI",
    province: "SA"
  },
  {
    code: "6391",
    value: "A495",
    label: "AULETTA",
    province: "SA"
  },
  {
    code: "6392",
    value: "A674",
    label: "BARONISSI",
    province: "SA"
  },
  {
    code: "6393",
    value: "A717",
    label: "BATTIPAGLIA",
    province: "SA"
  },
  {
    code: "6394",
    value: "A756",
    label: "BELLOSGUARDO",
    province: "SA"
  },
  {
    code: "6395",
    value: "B115",
    label: "BRACIGLIANO",
    province: "SA"
  },
  {
    code: "6396",
    value: "B242",
    label: "BUCCINO",
    province: "SA"
  },
  {
    code: "6397",
    value: "B266",
    label: "BUONABITACOLO",
    province: "SA"
  },
  {
    code: "6398",
    value: "B351",
    label: "CAGGIANO",
    province: "SA"
  },
  {
    code: "6399",
    value: "B437",
    label: "CALVANICO",
    province: "SA"
  },
  {
    code: "6400",
    value: "B476",
    label: "CAMEROTA",
    province: "SA"
  },
  {
    code: "6401",
    value: "B492",
    label: "CAMPAGNA",
    province: "SA"
  },
  {
    code: "6402",
    value: "B555",
    label: "CAMPORA",
    province: "SA"
  },
  {
    code: "6403",
    value: "B608",
    label: "CANNALONGA",
    province: "SA"
  },
  {
    code: "6404",
    value: "B644",
    label: "CAPACCIO PAESTUM",
    province: "SA"
  },
  {
    code: "6405",
    value: "B868",
    label: "CASALBUONO",
    province: "SA"
  },
  {
    code: "6406",
    value: "B888",
    label: "CASALETTO SPARTANO",
    province: "SA"
  },
  {
    code: "6407",
    value: "B895",
    label: "CASAL VELINO",
    province: "SA"
  },
  {
    code: "6408",
    value: "B959",
    label: "CASELLE IN PITTARI",
    province: "SA"
  },
  {
    code: "6409",
    value: "C069",
    label: "CASTELCIVITA",
    province: "SA"
  },
  {
    code: "6410",
    value: "C125",
    label: "CASTELLABATE",
    province: "SA"
  },
  {
    code: "6411",
    value: "C231",
    label: "CASTELNUOVO CILENTO",
    province: "SA"
  },
  {
    code: "6412",
    value: "C235",
    label: "CASTELNUOVO DI CONZA",
    province: "SA"
  },
  {
    code: "6413",
    value: "C259",
    label: "CASTEL SAN GIORGIO",
    province: "SA"
  },
  {
    code: "6414",
    value: "C262",
    label: "CASTEL SAN LORENZO",
    province: "SA"
  },
  {
    code: "6415",
    value: "C306",
    label: "CASTIGLIONE DEL GENOVESI",
    province: "SA"
  },
  {
    code: "6416",
    value: "C361",
    label: "CAVA DE TIRRENI",
    province: "SA"
  },
  {
    code: "6417",
    value: "C444",
    label: "CELLE DI BULGHERIA",
    province: "SA"
  },
  {
    code: "6418",
    value: "C470",
    label: "CENTOLA",
    province: "SA"
  },
  {
    code: "6419",
    value: "C485",
    label: "CERASO",
    province: "SA"
  },
  {
    code: "6420",
    value: "C584",
    label: "CETARA",
    province: "SA"
  },
  {
    code: "6421",
    value: "C676",
    label: "CICERALE",
    province: "SA"
  },
  {
    code: "6422",
    value: "C879",
    label: "COLLIANO",
    province: "SA"
  },
  {
    code: "6423",
    value: "C940",
    label: "CONCA DEI MARINI",
    province: "SA"
  },
  {
    code: "6424",
    value: "C973",
    label: "CONTRONE",
    province: "SA"
  },
  {
    code: "6425",
    value: "C974",
    label: "CONTURSI TERME",
    province: "SA"
  },
  {
    code: "6426",
    value: "C984",
    label: "CORBARA",
    province: "SA"
  },
  {
    code: "6427",
    value: "D011",
    label: "CORLETO MONFORTE",
    province: "SA"
  },
  {
    code: "6428",
    value: "D195",
    label: "CUCCARO VETERE",
    province: "SA"
  },
  {
    code: "6429",
    value: "D390",
    label: "EBOLI",
    province: "SA"
  },
  {
    code: "6430",
    value: "D527",
    label: "FELITTO",
    province: "SA"
  },
  {
    code: "6431",
    value: "D615",
    label: "FISCIANO",
    province: "SA"
  },
  {
    code: "6432",
    value: "D826",
    label: "FURORE",
    province: "SA"
  },
  {
    code: "6433",
    value: "D832",
    label: "FUTANI",
    province: "SA"
  },
  {
    code: "6434",
    value: "E026",
    label: "GIFFONI SEI CASALI",
    province: "SA"
  },
  {
    code: "6435",
    value: "E027",
    label: "GIFFONI VALLE PIANA",
    province: "SA"
  },
  {
    code: "6436",
    value: "E037",
    label: "GIOI",
    province: "SA"
  },
  {
    code: "6437",
    value: "E060",
    label: "GIUNGANO",
    province: "SA"
  },
  {
    code: "6438",
    value: "E365",
    label: "ISPANI",
    province: "SA"
  },
  {
    code: "6439",
    value: "E480",
    label: "LAUREANA CILENTO",
    province: "SA"
  },
  {
    code: "6440",
    value: "E485",
    label: "LAURINO",
    province: "SA"
  },
  {
    code: "6441",
    value: "E486",
    label: "LAURITO",
    province: "SA"
  },
  {
    code: "6442",
    value: "E498",
    label: "LAVIANO",
    province: "SA"
  },
  {
    code: "6443",
    value: "E767",
    label: "LUSTRA",
    province: "SA"
  },
  {
    code: "6444",
    value: "E814",
    label: "MAGLIANO VETERE",
    province: "SA"
  },
  {
    code: "6445",
    value: "E839",
    label: "MAIORI",
    province: "SA"
  },
  {
    code: "6446",
    value: "F138",
    label: "MERCATO SAN SEVERINO",
    province: "SA"
  },
  {
    code: "6447",
    value: "F223",
    label: "MINORI",
    province: "SA"
  },
  {
    code: "6448",
    value: "F278",
    label: "MOIO DELLA CIVITELLA",
    province: "SA"
  },
  {
    code: "6449",
    value: "F426",
    label: "MONTANO ANTILIA",
    province: "SA"
  },
  {
    code: "6450",
    value: "F479",
    label: "MONTECORICE",
    province: "SA"
  },
  {
    code: "6451",
    value: "F480",
    label: "MONTECORVINO PUGLIANO",
    province: "SA"
  },
  {
    code: "6452",
    value: "F481",
    label: "MONTECORVINO ROVELLA",
    province: "SA"
  },
  {
    code: "6453",
    value: "F507",
    label: "MONTEFORTE CILENTO",
    province: "SA"
  },
  {
    code: "6454",
    value: "F618",
    label: "MONTE SAN GIACOMO",
    province: "SA"
  },
  {
    code: "6455",
    value: "F625",
    label: "MONTESANO SULLA MARCELLANA",
    province: "SA"
  },
  {
    code: "6456",
    value: "F731",
    label: "MORIGERATI",
    province: "SA"
  },
  {
    code: "6457",
    value: "F912",
    label: "NOCERA INFERIORE",
    province: "SA"
  },
  {
    code: "6458",
    value: "F913",
    label: "NOCERA SUPERIORE",
    province: "SA"
  },
  {
    code: "6459",
    value: "F967",
    label: "NOVI VELIA",
    province: "SA"
  },
  {
    code: "6460",
    value: "G011",
    label: "OGLIASTRO CILENTO",
    province: "SA"
  },
  {
    code: "6461",
    value: "G023",
    label: "OLEVANO SUL TUSCIANO",
    province: "SA"
  },
  {
    code: "6462",
    value: "G039",
    label: "OLIVETO CITRA",
    province: "SA"
  },
  {
    code: "6463",
    value: "G063",
    label: "OMIGNANO",
    province: "SA"
  },
  {
    code: "6464",
    value: "G121",
    label: "ORRIA",
    province: "SA"
  },
  {
    code: "6465",
    value: "G192",
    label: "OTTATI",
    province: "SA"
  },
  {
    code: "6466",
    value: "G226",
    label: "PADULA",
    province: "SA"
  },
  {
    code: "6467",
    value: "G230",
    label: "PAGANI",
    province: "SA"
  },
  {
    code: "6468",
    value: "G292",
    label: "PALOMONTE",
    province: "SA"
  },
  {
    code: "6469",
    value: "G426",
    label: "PELLEZZANO",
    province: "SA"
  },
  {
    code: "6470",
    value: "G447",
    label: "PERDIFUMO",
    province: "SA"
  },
  {
    code: "6471",
    value: "G455",
    label: "PERITO",
    province: "SA"
  },
  {
    code: "6472",
    value: "G476",
    label: "PERTOSA",
    province: "SA"
  },
  {
    code: "6473",
    value: "G509",
    label: "PETINA",
    province: "SA"
  },
  {
    code: "6474",
    value: "G538",
    label: "PIAGGINE",
    province: "SA"
  },
  {
    code: "6475",
    value: "G707",
    label: "PISCIOTTA",
    province: "SA"
  },
  {
    code: "6476",
    value: "G793",
    label: "POLLA",
    province: "SA"
  },
  {
    code: "6477",
    value: "G796",
    label: "POLLICA",
    province: "SA"
  },
  {
    code: "6478",
    value: "G834",
    label: "PONTECAGNANO FAIANO",
    province: "SA"
  },
  {
    code: "6479",
    value: "G932",
    label: "POSITANO",
    province: "SA"
  },
  {
    code: "6480",
    value: "G939",
    label: "POSTIGLIONE",
    province: "SA"
  },
  {
    code: "6481",
    value: "G976",
    label: "PRAIANO",
    province: "SA"
  },
  {
    code: "6482",
    value: "H062",
    label: "PRIGNANO CILENTO",
    province: "SA"
  },
  {
    code: "6483",
    value: "H198",
    label: "RAVELLO",
    province: "SA"
  },
  {
    code: "6484",
    value: "H277",
    label: "RICIGLIANO",
    province: "SA"
  },
  {
    code: "6485",
    value: "H394",
    label: "ROCCADASPIDE",
    province: "SA"
  },
  {
    code: "6486",
    value: "H412",
    label: "ROCCAGLORIOSA",
    province: "SA"
  },
  {
    code: "6487",
    value: "H431",
    label: "ROCCAPIEMONTE",
    province: "SA"
  },
  {
    code: "6488",
    value: "H485",
    label: "ROFRANO",
    province: "SA"
  },
  {
    code: "6489",
    value: "H503",
    label: "ROMAGNANO AL MONTE",
    province: "SA"
  },
  {
    code: "6490",
    value: "H564",
    label: "ROSCIGNO",
    province: "SA"
  },
  {
    code: "6491",
    value: "H644",
    label: "RUTINO",
    province: "SA"
  },
  {
    code: "6492",
    value: "H654",
    label: "SACCO",
    province: "SA"
  },
  {
    code: "6493",
    value: "H683",
    label: "SALA CONSILINA",
    province: "SA"
  },
  {
    code: "6494",
    value: "H686",
    label: "SALENTO",
    province: "SA"
  },
  {
    code: "6495",
    value: "H703",
    label: "SALERNO",
    province: "SA"
  },
  {
    code: "6496",
    value: "H732",
    label: "SALVITELLE",
    province: "SA"
  },
  {
    code: "6497",
    value: "H800",
    label: "SAN CIPRIANO PICENTINO",
    province: "SA"
  },
  {
    code: "6498",
    value: "H907",
    label: "SAN GIOVANNI A PIRO",
    province: "SA"
  },
  {
    code: "6499",
    value: "H943",
    label: "SAN GREGORIO MAGNO",
    province: "SA"
  },
  {
    code: "6500",
    value: "H977",
    label: "SAN MANGO PIEMONTE",
    province: "SA"
  },
  {
    code: "6501",
    value: "I019",
    label: "SAN MARZANO SUL SARNO",
    province: "SA"
  },
  {
    code: "6502",
    value: "I031",
    label: "SAN MAURO CILENTO",
    province: "SA"
  },
  {
    code: "6503",
    value: "I032",
    label: "SAN MAURO LA BRUCA",
    province: "SA"
  },
  {
    code: "6504",
    value: "I089",
    label: "SAN PIETRO AL TANAGRO",
    province: "SA"
  },
  {
    code: "6505",
    value: "I143",
    label: "SAN RUFO",
    province: "SA"
  },
  {
    code: "6506",
    value: "I253",
    label: "SANTA MARINA",
    province: "SA"
  },
  {
    code: "6507",
    value: "I278",
    label: "SANT ANGELO A FASANELLA",
    province: "SA"
  },
  {
    code: "6508",
    value: "I307",
    label: "SANT ARSENIO",
    province: "SA"
  },
  {
    code: "6509",
    value: "I317",
    label: "SANT EGIDIO DEL MONTE ALBINO",
    province: "SA"
  },
  {
    code: "6510",
    value: "I260",
    label: "SANTOMENNA",
    province: "SA"
  },
  {
    code: "6511",
    value: "I377",
    label: "SAN VALENTINO TORIO",
    province: "SA"
  },
  {
    code: "6512",
    value: "I410",
    label: "SANZA",
    province: "SA"
  },
  {
    code: "6513",
    value: "I422",
    label: "SAPRI",
    province: "SA"
  },
  {
    code: "6514",
    value: "I438",
    label: "SARNO",
    province: "SA"
  },
  {
    code: "6515",
    value: "I451",
    label: "SASSANO",
    province: "SA"
  },
  {
    code: "6516",
    value: "I483",
    label: "SCAFATI",
    province: "SA"
  },
  {
    code: "6517",
    value: "I486",
    label: "SCALA",
    province: "SA"
  },
  {
    code: "6518",
    value: "I648",
    label: "SERRAMEZZANA",
    province: "SA"
  },
  {
    code: "6519",
    value: "I666",
    label: "SERRE",
    province: "SA"
  },
  {
    code: "6520",
    value: "I677",
    label: "SESSA CILENTO",
    province: "SA"
  },
  {
    code: "6521",
    value: "I720",
    label: "SIANO",
    province: "SA"
  },
  {
    code: "6522",
    value: "M253",
    label: "SICIGNANO DEGLI ALBURNI",
    province: "SA"
  },
  {
    code: "6523",
    value: "G887",
    label: "STELLA CILENTO",
    province: "SA"
  },
  {
    code: "6524",
    value: "I960",
    label: "STIO",
    province: "SA"
  },
  {
    code: "6525",
    value: "D292",
    label: "TEGGIANO",
    province: "SA"
  },
  {
    code: "6526",
    value: "L212",
    label: "TORCHIARA",
    province: "SA"
  },
  {
    code: "6527",
    value: "L233",
    label: "TORRACA",
    province: "SA"
  },
  {
    code: "6528",
    value: "L274",
    label: "TORRE ORSAIA",
    province: "SA"
  },
  {
    code: "6529",
    value: "L306",
    label: "TORTORELLA",
    province: "SA"
  },
  {
    code: "6530",
    value: "L323",
    label: "TRAMONTI",
    province: "SA"
  },
  {
    code: "6531",
    value: "L377",
    label: "TRENTINARA",
    province: "SA"
  },
  {
    code: "6532",
    value: "G540",
    label: "VALLE DELL ANGELO",
    province: "SA"
  },
  {
    code: "6533",
    value: "L628",
    label: "VALLO DELLA LUCANIA",
    province: "SA"
  },
  {
    code: "6534",
    value: "L656",
    label: "VALVA",
    province: "SA"
  },
  {
    code: "6535",
    value: "L835",
    label: "VIBONATI",
    province: "SA"
  },
  {
    code: "6536",
    value: "L860",
    label: "VIETRI SUL MARE",
    province: "SA"
  },
  {
    code: "6537",
    value: "M294",
    label: "BELLIZZI",
    province: "SA"
  },
  {
    code: "6538",
    value: "A015",
    label: "ACCADIA",
    province: "FG"
  },
  {
    code: "6539",
    value: "A150",
    label: "ALBERONA",
    province: "FG"
  },
  {
    code: "6540",
    value: "A320",
    label: "ANZANO DI PUGLIA",
    province: "FG"
  },
  {
    code: "6541",
    value: "A339",
    label: "APRICENA",
    province: "FG"
  },
  {
    code: "6542",
    value: "A463",
    label: "ASCOLI SATRIANO",
    province: "FG"
  },
  {
    code: "6543",
    value: "A854",
    label: "BICCARI",
    province: "FG"
  },
  {
    code: "6544",
    value: "B104",
    label: "BOVINO",
    province: "FG"
  },
  {
    code: "6545",
    value: "B357",
    label: "CAGNANO VARANO",
    province: "FG"
  },
  {
    code: "6546",
    value: "B584",
    label: "CANDELA",
    province: "FG"
  },
  {
    code: "6547",
    value: "B724",
    label: "CARAPELLE",
    province: "FG"
  },
  {
    code: "6548",
    value: "B784",
    label: "CARLANTINO",
    province: "FG"
  },
  {
    code: "6549",
    value: "B829",
    label: "CARPINO",
    province: "FG"
  },
  {
    code: "6550",
    value: "B904",
    label: "CASALNUOVO MONTEROTARO",
    province: "FG"
  },
  {
    code: "6551",
    value: "B917",
    label: "CASALVECCHIO DI PUGLIA",
    province: "FG"
  },
  {
    code: "6552",
    value: "C198",
    label: "CASTELLUCCIO DEI SAURI",
    province: "FG"
  },
  {
    code: "6553",
    value: "C202",
    label: "CASTELLUCCIO VALMAGGIORE",
    province: "FG"
  },
  {
    code: "6554",
    value: "C222",
    label: "CASTELNUOVO DELLA DAUNIA",
    province: "FG"
  },
  {
    code: "6555",
    value: "C429",
    label: "CELENZA VALFORTORE",
    province: "FG"
  },
  {
    code: "6556",
    value: "C442",
    label: "CELLE DI SAN VITO",
    province: "FG"
  },
  {
    code: "6557",
    value: "C514",
    label: "CERIGNOLA",
    province: "FG"
  },
  {
    code: "6558",
    value: "C633",
    label: "CHIEUTI",
    province: "FG"
  },
  {
    code: "6559",
    value: "D269",
    label: "DELICETO",
    province: "FG"
  },
  {
    code: "6560",
    value: "D459",
    label: "FAETO",
    province: "FG"
  },
  {
    code: "6561",
    value: "D643",
    label: "FOGGIA",
    province: "FG"
  },
  {
    code: "6562",
    value: "E332",
    label: "ISCHITELLA",
    province: "FG"
  },
  {
    code: "6563",
    value: "E363",
    label: "ISOLE TREMITI",
    province: "FG"
  },
  {
    code: "6564",
    value: "E549",
    label: "LESINA",
    province: "FG"
  },
  {
    code: "6565",
    value: "E716",
    label: "LUCERA",
    province: "FG"
  },
  {
    code: "6566",
    value: "E885",
    label: "MANFREDONIA",
    province: "FG"
  },
  {
    code: "6567",
    value: "E946",
    label: "MARGHERITA DI SAVOIA",
    province: "BT"
  },
  {
    code: "6568",
    value: "F059",
    label: "MATTINATA",
    province: "FG"
  },
  {
    code: "6569",
    value: "F538",
    label: "MONTELEONE DI PUGLIA",
    province: "FG"
  },
  {
    code: "6570",
    value: "F631",
    label: "MONTE SANT ANGELO",
    province: "FG"
  },
  {
    code: "6571",
    value: "F777",
    label: "MOTTA MONTECORVINO",
    province: "FG"
  },
  {
    code: "6572",
    value: "G125",
    label: "ORSARA DI PUGLIA",
    province: "FG"
  },
  {
    code: "6573",
    value: "G131",
    label: "ORTA NOVA",
    province: "FG"
  },
  {
    code: "6574",
    value: "G312",
    label: "PANNI",
    province: "FG"
  },
  {
    code: "6575",
    value: "G487",
    label: "PESCHICI",
    province: "FG"
  },
  {
    code: "6576",
    value: "G604",
    label: "PIETRAMONTECORVINO",
    province: "FG"
  },
  {
    code: "6577",
    value: "G761",
    label: "POGGIO IMPERIALE",
    province: "FG"
  },
  {
    code: "6578",
    value: "H287",
    label: "RIGNANO GARGANICO",
    province: "FG"
  },
  {
    code: "6579",
    value: "H467",
    label: "ROCCHETTA SANT ANTONIO",
    province: "FG"
  },
  {
    code: "6580",
    value: "H480",
    label: "RODI GARGANICO",
    province: "FG"
  },
  {
    code: "6581",
    value: "H568",
    label: "ROSETO VALFORTORE",
    province: "FG"
  },
  {
    code: "6582",
    value: "H839",
    label: "SAN FERDINANDO DI PUGLIA",
    province: "BT"
  },
  {
    code: "6583",
    value: "H926",
    label: "SAN GIOVANNI ROTONDO",
    province: "FG"
  },
  {
    code: "6584",
    value: "H985",
    label: "SAN MARCO IN LAMIS",
    province: "FG"
  },
  {
    code: "6585",
    value: "H986",
    label: "SAN MARCO LA CATOLA",
    province: "FG"
  },
  {
    code: "6586",
    value: "I054",
    label: "SAN NICANDRO GARGANICO",
    province: "FG"
  },
  {
    code: "6587",
    value: "I072",
    label: "SAN PAOLO DI CIVITATE",
    province: "FG"
  },
  {
    code: "6588",
    value: "I158",
    label: "SAN SEVERO",
    province: "FG"
  },
  {
    code: "6589",
    value: "I193",
    label: "SANT AGATA DI PUGLIA",
    province: "FG"
  },
  {
    code: "6590",
    value: "I641",
    label: "SERRACAPRIOLA",
    province: "FG"
  },
  {
    code: "6591",
    value: "I962",
    label: "STORNARA",
    province: "FG"
  },
  {
    code: "6592",
    value: "I963",
    label: "STORNARELLA",
    province: "FG"
  },
  {
    code: "6593",
    value: "L273",
    label: "TORREMAGGIORE",
    province: "FG"
  },
  {
    code: "6594",
    value: "B915",
    label: "TRINITAPOLI",
    province: "BT"
  },
  {
    code: "6595",
    value: "L447",
    label: "TROIA",
    province: "FG"
  },
  {
    code: "6596",
    value: "L842",
    label: "VICO DEL GARGANO",
    province: "FG"
  },
  {
    code: "6597",
    value: "L858",
    label: "VIESTE",
    province: "FG"
  },
  {
    code: "6598",
    value: "M131",
    label: "VOLTURARA APPULA",
    province: "FG"
  },
  {
    code: "6599",
    value: "M132",
    label: "VOLTURINO",
    province: "FG"
  },
  {
    code: "6600",
    value: "M266",
    label: "ORDONA",
    province: "FG"
  },
  {
    code: "6601",
    value: "M267",
    label: "ZAPPONETA",
    province: "FG"
  },
  {
    code: "6602",
    value: "A048",
    label: "ACQUAVIVA DELLE FONTI",
    province: "BA"
  },
  {
    code: "6603",
    value: "A055",
    label: "ADELFIA",
    province: "BA"
  },
  {
    code: "6604",
    value: "A149",
    label: "ALBEROBELLO",
    province: "BA"
  },
  {
    code: "6605",
    value: "A225",
    label: "ALTAMURA",
    province: "BA"
  },
  {
    code: "6606",
    value: "A285",
    label: "ANDRIA",
    province: "BT"
  },
  {
    code: "6607",
    value: "A662",
    label: "BARI",
    province: "BA"
  },
  {
    code: "6608",
    value: "A669",
    label: "BARLETTA",
    province: "BT"
  },
  {
    code: "6609",
    value: "A874",
    label: "BINETTO",
    province: "BA"
  },
  {
    code: "6610",
    value: "A883",
    label: "BISCEGLIE",
    province: "BT"
  },
  {
    code: "6611",
    value: "A892",
    label: "BITETTO",
    province: "BA"
  },
  {
    code: "6612",
    value: "A893",
    label: "BITONTO",
    province: "BA"
  },
  {
    code: "6613",
    value: "A894",
    label: "BITRITTO",
    province: "BA"
  },
  {
    code: "6614",
    value: "B619",
    label: "CANOSA DI PUGLIA",
    province: "BT"
  },
  {
    code: "6615",
    value: "B716",
    label: "CAPURSO",
    province: "BA"
  },
  {
    code: "6616",
    value: "B923",
    label: "CASAMASSIMA",
    province: "BA"
  },
  {
    code: "6617",
    value: "B998",
    label: "CASSANO DELLE MURGE",
    province: "BA"
  },
  {
    code: "6618",
    value: "C134",
    label: "CASTELLANA GROTTE",
    province: "BA"
  },
  {
    code: "6619",
    value: "C436",
    label: "CELLAMARE",
    province: "BA"
  },
  {
    code: "6620",
    value: "C975",
    label: "CONVERSANO",
    province: "BA"
  },
  {
    code: "6621",
    value: "C983",
    label: "CORATO",
    province: "BA"
  },
  {
    code: "6622",
    value: "E038",
    label: "GIOIA DEL COLLE",
    province: "BA"
  },
  {
    code: "6623",
    value: "E047",
    label: "GIOVINAZZO",
    province: "BA"
  },
  {
    code: "6624",
    value: "E155",
    label: "GRAVINA IN PUGLIA",
    province: "BA"
  },
  {
    code: "6625",
    value: "E223",
    label: "GRUMO APPULA",
    province: "BA"
  },
  {
    code: "6626",
    value: "E645",
    label: "LOCOROTONDO",
    province: "BA"
  },
  {
    code: "6627",
    value: "F220",
    label: "MINERVINO MURGE",
    province: "BT"
  },
  {
    code: "6628",
    value: "F262",
    label: "MODUGNO",
    province: "BA"
  },
  {
    code: "6629",
    value: "F280",
    label: "MOLA DI BARI",
    province: "BA"
  },
  {
    code: "6630",
    value: "F284",
    label: "MOLFETTA",
    province: "BA"
  },
  {
    code: "6631",
    value: "F376",
    label: "MONOPOLI",
    province: "BA"
  },
  {
    code: "6632",
    value: "F915",
    label: "NOCI",
    province: "BA"
  },
  {
    code: "6633",
    value: "F923",
    label: "NOICATTARO",
    province: "BA"
  },
  {
    code: "6634",
    value: "G291",
    label: "PALO DEL COLLE",
    province: "BA"
  },
  {
    code: "6635",
    value: "G769",
    label: "POGGIORSINI",
    province: "BA"
  },
  {
    code: "6636",
    value: "G787",
    label: "POLIGNANO A MARE",
    province: "BA"
  },
  {
    code: "6637",
    value: "H096",
    label: "PUTIGNANO",
    province: "BA"
  },
  {
    code: "6638",
    value: "H643",
    label: "RUTIGLIANO",
    province: "BA"
  },
  {
    code: "6639",
    value: "H645",
    label: "RUVO DI PUGLIA",
    province: "BA"
  },
  {
    code: "6640",
    value: "H749",
    label: "SAMMICHELE DI BARI",
    province: "BA"
  },
  {
    code: "6641",
    value: "I053",
    label: "SANNICANDRO DI BARI",
    province: "BA"
  },
  {
    code: "6642",
    value: "I330",
    label: "SANTERAMO IN COLLE",
    province: "BA"
  },
  {
    code: "6643",
    value: "I907",
    label: "SPINAZZOLA",
    province: "BT"
  },
  {
    code: "6644",
    value: "L109",
    label: "TERLIZZI",
    province: "BA"
  },
  {
    code: "6645",
    value: "L220",
    label: "TORITTO",
    province: "BA"
  },
  {
    code: "6646",
    value: "L328",
    label: "TRANI",
    province: "BT"
  },
  {
    code: "6647",
    value: "L425",
    label: "TRIGGIANO",
    province: "BA"
  },
  {
    code: "6648",
    value: "L472",
    label: "TURI",
    province: "BA"
  },
  {
    code: "6649",
    value: "L571",
    label: "VALENZANO",
    province: "BA"
  },
  {
    code: "6650",
    value: "A514",
    label: "AVETRANA",
    province: "TA"
  },
  {
    code: "6651",
    value: "B808",
    label: "CAROSINO",
    province: "TA"
  },
  {
    code: "6652",
    value: "C136",
    label: "CASTELLANETA",
    province: "TA"
  },
  {
    code: "6653",
    value: "D171",
    label: "CRISPIANO",
    province: "TA"
  },
  {
    code: "6654",
    value: "D463",
    label: "FAGGIANO",
    province: "TA"
  },
  {
    code: "6655",
    value: "D754",
    label: "FRAGAGNANO",
    province: "TA"
  },
  {
    code: "6656",
    value: "E036",
    label: "GINOSA",
    province: "TA"
  },
  {
    code: "6657",
    value: "E205",
    label: "GROTTAGLIE",
    province: "TA"
  },
  {
    code: "6658",
    value: "E469",
    label: "LATERZA",
    province: "TA"
  },
  {
    code: "6659",
    value: "E537",
    label: "LEPORANO",
    province: "TA"
  },
  {
    code: "6660",
    value: "E630",
    label: "LIZZANO",
    province: "TA"
  },
  {
    code: "6661",
    value: "E882",
    label: "MANDURIA",
    province: "TA"
  },
  {
    code: "6662",
    value: "E986",
    label: "MARTINA FRANCA",
    province: "TA"
  },
  {
    code: "6663",
    value: "E995",
    label: "MARUGGIO",
    province: "TA"
  },
  {
    code: "6664",
    value: "F027",
    label: "MASSAFRA",
    province: "TA"
  },
  {
    code: "6665",
    value: "F531",
    label: "MONTEIASI",
    province: "TA"
  },
  {
    code: "6666",
    value: "F563",
    label: "MONTEMESOLA",
    province: "TA"
  },
  {
    code: "6667",
    value: "F587",
    label: "MONTEPARANO",
    province: "TA"
  },
  {
    code: "6668",
    value: "F784",
    label: "MOTTOLA",
    province: "TA"
  },
  {
    code: "6669",
    value: "G251",
    label: "PALAGIANELLO",
    province: "TA"
  },
  {
    code: "6670",
    value: "G252",
    label: "PALAGIANO",
    province: "TA"
  },
  {
    code: "6671",
    value: "H090",
    label: "PULSANO",
    province: "TA"
  },
  {
    code: "6672",
    value: "H409",
    label: "ROCCAFORZATA",
    province: "TA"
  },
  {
    code: "6673",
    value: "H882",
    label: "SAN GIORGIO IONICO",
    province: "TA"
  },
  {
    code: "6674",
    value: "I018",
    label: "SAN MARZANO DI SAN GIUSEPPE",
    province: "TA"
  },
  {
    code: "6675",
    value: "I467",
    label: "SAVA",
    province: "TA"
  },
  {
    code: "6676",
    value: "L049",
    label: "TARANTO",
    province: "TA"
  },
  {
    code: "6677",
    value: "L294",
    label: "TORRICELLA",
    province: "TA"
  },
  {
    code: "6678",
    value: "M298",
    label: "STATTE",
    province: "TA"
  },
  {
    code: "6679",
    value: "B180",
    label: "BRINDISI",
    province: "BR"
  },
  {
    code: "6680",
    value: "B809",
    label: "CAROVIGNO",
    province: "BR"
  },
  {
    code: "6681",
    value: "C424",
    label: "CEGLIE MESSAPICA",
    province: "BR"
  },
  {
    code: "6682",
    value: "C448",
    label: "CELLINO SAN MARCO",
    province: "BR"
  },
  {
    code: "6683",
    value: "C741",
    label: "CISTERNINO",
    province: "BR"
  },
  {
    code: "6684",
    value: "D422",
    label: "ERCHIE",
    province: "BR"
  },
  {
    code: "6685",
    value: "D508",
    label: "FASANO",
    province: "BR"
  },
  {
    code: "6686",
    value: "D761",
    label: "FRANCAVILLA FONTANA",
    province: "BR"
  },
  {
    code: "6687",
    value: "E471",
    label: "LATIANO",
    province: "BR"
  },
  {
    code: "6688",
    value: "F152",
    label: "MESAGNE",
    province: "BR"
  },
  {
    code: "6689",
    value: "G098",
    label: "ORIA",
    province: "BR"
  },
  {
    code: "6690",
    value: "G187",
    label: "OSTUNI",
    province: "BR"
  },
  {
    code: "6691",
    value: "H822",
    label: "SAN DONACI",
    province: "BR"
  },
  {
    code: "6692",
    value: "I045",
    label: "SAN MICHELE SALENTINO",
    province: "BR"
  },
  {
    code: "6693",
    value: "I066",
    label: "SAN PANCRAZIO SALENTINO",
    province: "BR"
  },
  {
    code: "6694",
    value: "I119",
    label: "SAN PIETRO VERNOTICO",
    province: "BR"
  },
  {
    code: "6695",
    value: "I396",
    label: "SAN VITO DEI NORMANNI",
    province: "BR"
  },
  {
    code: "6696",
    value: "L213",
    label: "TORCHIAROLO",
    province: "BR"
  },
  {
    code: "6697",
    value: "L280",
    label: "TORRE SANTA SUSANNA",
    province: "BR"
  },
  {
    code: "6698",
    value: "L920",
    label: "VILLA CASTELLI",
    province: "BR"
  },
  {
    code: "6700",
    value: "A184",
    label: "ALESSANO",
    province: "LE"
  },
  {
    code: "6701",
    value: "A185",
    label: "ALEZIO",
    province: "LE"
  },
  {
    code: "6702",
    value: "A208",
    label: "ALLISTE",
    province: "LE"
  },
  {
    code: "6703",
    value: "A281",
    label: "ANDRANO",
    province: "LE"
  },
  {
    code: "6704",
    value: "A350",
    label: "ARADEO",
    province: "LE"
  },
  {
    code: "6705",
    value: "A425",
    label: "ARNESANO",
    province: "LE"
  },
  {
    code: "6706",
    value: "A572",
    label: "BAGNOLO DEL SALENTO",
    province: "LE"
  },
  {
    code: "6707",
    value: "B086",
    label: "BOTRUGNO",
    province: "LE"
  },
  {
    code: "6708",
    value: "B413",
    label: "CALIMERA",
    province: "LE"
  },
  {
    code: "6709",
    value: "B506",
    label: "CAMPI SALENTINA",
    province: "LE"
  },
  {
    code: "6710",
    value: "B616",
    label: "CANNOLE",
    province: "LE"
  },
  {
    code: "6711",
    value: "B690",
    label: "CAPRARICA DI LECCE",
    province: "LE"
  },
  {
    code: "6712",
    value: "B792",
    label: "CARMIANO",
    province: "LE"
  },
  {
    code: "6713",
    value: "B822",
    label: "CARPIGNANO SALENTINO",
    province: "LE"
  },
  {
    code: "6714",
    value: "B936",
    label: "CASARANO",
    province: "LE"
  },
  {
    code: "6715",
    value: "C334",
    label: "CASTRI DI LECCE",
    province: "LE"
  },
  {
    code: "6716",
    value: "C335",
    label: "CASTRIGNANO DE GRECI",
    province: "LE"
  },
  {
    code: "6717",
    value: "C336",
    label: "CASTRIGNANO DEL CAPO",
    province: "LE"
  },
  {
    code: "6718",
    value: "C377",
    label: "CAVALLINO",
    province: "LE"
  },
  {
    code: "6719",
    value: "C865",
    label: "COLLEPASSO",
    province: "LE"
  },
  {
    code: "6720",
    value: "C978",
    label: "COPERTINO",
    province: "LE"
  },
  {
    code: "6721",
    value: "D006",
    label: "CORIGLIANO D OTRANTO",
    province: "LE"
  },
  {
    code: "6722",
    value: "D044",
    label: "CORSANO",
    province: "LE"
  },
  {
    code: "6723",
    value: "D223",
    label: "CURSI",
    province: "LE"
  },
  {
    code: "6724",
    value: "D237",
    label: "CUTROFIANO",
    province: "LE"
  },
  {
    code: "6725",
    value: "D305",
    label: "DISO",
    province: "LE"
  },
  {
    code: "6726",
    value: "D851",
    label: "GAGLIANO DEL CAPO",
    province: "LE"
  },
  {
    code: "6727",
    value: "D862",
    label: "GALATINA",
    province: "LE"
  },
  {
    code: "6728",
    value: "D863",
    label: "GALATONE",
    province: "LE"
  },
  {
    code: "6729",
    value: "D883",
    label: "GALLIPOLI",
    province: "LE"
  },
  {
    code: "6730",
    value: "E053",
    label: "GIUGGIANELLO",
    province: "LE"
  },
  {
    code: "6731",
    value: "E061",
    label: "GIURDIGNANO",
    province: "LE"
  },
  {
    code: "6732",
    value: "E227",
    label: "GUAGNANO",
    province: "LE"
  },
  {
    code: "6733",
    value: "E506",
    label: "LECCE",
    province: "LE"
  },
  {
    code: "6734",
    value: "E538",
    label: "LEQUILE",
    province: "LE"
  },
  {
    code: "6735",
    value: "E563",
    label: "LEVERANO",
    province: "LE"
  },
  {
    code: "6736",
    value: "E629",
    label: "LIZZANELLO",
    province: "LE"
  },
  {
    code: "6737",
    value: "E815",
    label: "MAGLIE",
    province: "LE"
  },
  {
    code: "6738",
    value: "E979",
    label: "MARTANO",
    province: "LE"
  },
  {
    code: "6739",
    value: "E984",
    label: "MARTIGNANO",
    province: "LE"
  },
  {
    code: "6740",
    value: "F054",
    label: "MATINO",
    province: "LE"
  },
  {
    code: "6741",
    value: "F101",
    label: "MELENDUGNO",
    province: "LE"
  },
  {
    code: "6742",
    value: "F109",
    label: "MELISSANO",
    province: "LE"
  },
  {
    code: "6743",
    value: "F117",
    label: "MELPIGNANO",
    province: "LE"
  },
  {
    code: "6744",
    value: "F194",
    label: "MIGGIANO",
    province: "LE"
  },
  {
    code: "6745",
    value: "F221",
    label: "MINERVINO DI LECCE",
    province: "LE"
  },
  {
    code: "6746",
    value: "F604",
    label: "MONTERONI DI LECCE",
    province: "LE"
  },
  {
    code: "6747",
    value: "F623",
    label: "MONTESANO SALENTINO",
    province: "LE"
  },
  {
    code: "6748",
    value: "F716",
    label: "MORCIANO DI LEUCA",
    province: "LE"
  },
  {
    code: "6749",
    value: "F816",
    label: "MURO LECCESE",
    province: "LE"
  },
  {
    code: "6750",
    value: "F842",
    label: "NARDO",
    province: "LE"
  },
  {
    code: "6751",
    value: "F881",
    label: "NEVIANO",
    province: "LE"
  },
  {
    code: "6752",
    value: "F916",
    label: "NOCIGLIA",
    province: "LE"
  },
  {
    code: "6753",
    value: "F970",
    label: "NOVOLI",
    province: "LE"
  },
  {
    code: "6754",
    value: "G136",
    label: "ORTELLE",
    province: "LE"
  },
  {
    code: "6755",
    value: "G188",
    label: "OTRANTO",
    province: "LE"
  },
  {
    code: "6756",
    value: "G285",
    label: "PALMARIGGI",
    province: "LE"
  },
  {
    code: "6757",
    value: "G325",
    label: "PARABITA",
    province: "LE"
  },
  {
    code: "6758",
    value: "G378",
    label: "PATU",
    province: "LE"
  },
  {
    code: "6759",
    value: "G751",
    label: "POGGIARDO",
    province: "LE"
  },
  {
    code: "6761",
    value: "H147",
    label: "RACALE",
    province: "LE"
  },
  {
    code: "6762",
    value: "H632",
    label: "RUFFANO",
    province: "LE"
  },
  {
    code: "6763",
    value: "H708",
    label: "SALICE SALENTINO",
    province: "LE"
  },
  {
    code: "6764",
    value: "H729",
    label: "SALVE",
    province: "LE"
  },
  {
    code: "6765",
    value: "H757",
    label: "SANARICA",
    province: "LE"
  },
  {
    code: "6766",
    value: "H793",
    label: "SAN CESARIO DI LECCE",
    province: "LE"
  },
  {
    code: "6767",
    value: "H826",
    label: "SAN DONATO DI LECCE",
    province: "LE"
  },
  {
    code: "6768",
    value: "I059",
    label: "SANNICOLA",
    province: "LE"
  },
  {
    code: "6769",
    value: "I115",
    label: "SAN PIETRO IN LAMA",
    province: "LE"
  },
  {
    code: "6770",
    value: "I172",
    label: "SANTA CESAREA TERME",
    province: "LE"
  },
  {
    code: "6877",
    value: "I457",
    label: "SASSO DI CASTALDA",
    province: "PZ"
  },
  {
    code: "6878",
    value: "G614",
    label: "SATRIANO DI LUCANIA",
    province: "PZ"
  },
  {
    code: "6879",
    value: "H730",
    label: "SAVOIA DI LUCANIA",
    province: "PZ"
  },
  {
    code: "6880",
    value: "I610",
    label: "SENISE",
    province: "PZ"
  },
  {
    code: "6881",
    value: "I917",
    label: "SPINOSO",
    province: "PZ"
  },
  {
    code: "6882",
    value: "L082",
    label: "TEANA",
    province: "PZ"
  },
  {
    code: "6883",
    value: "L126",
    label: "TERRANOVA DI POLLINO",
    province: "PZ"
  },
  {
    code: "6884",
    value: "L181",
    label: "TITO",
    province: "PZ"
  },
  {
    code: "6885",
    value: "L197",
    label: "TOLVE",
    province: "PZ"
  },
  {
    code: "6886",
    value: "L326",
    label: "TRAMUTOLA",
    province: "PZ"
  },
  {
    code: "6887",
    value: "L357",
    label: "TRECCHINA",
    province: "PZ"
  },
  {
    code: "6888",
    value: "L439",
    label: "TRIVIGNO",
    province: "PZ"
  },
  {
    code: "6889",
    value: "L532",
    label: "VAGLIO BASILICATA",
    province: "PZ"
  },
  {
    code: "6890",
    value: "L738",
    label: "VENOSA",
    province: "PZ"
  },
  {
    code: "6891",
    value: "L859",
    label: "VIETRI DI POTENZA",
    province: "PZ"
  },
  {
    code: "6892",
    value: "L873",
    label: "VIGGIANELLO",
    province: "PZ"
  },
  {
    code: "6893",
    value: "L874",
    label: "VIGGIANO",
    province: "PZ"
  },
  {
    code: "6894",
    value: "E033",
    label: "GINESTRA",
    province: "PZ"
  },
  {
    code: "6895",
    value: "M269",
    label: "PATERNO",
    province: "PZ"
  },
  {
    code: "6896",
    value: "A017",
    label: "ACCETTURA",
    province: "MT"
  },
  {
    code: "6897",
    value: "A196",
    label: "ALIANO",
    province: "MT"
  },
  {
    code: "6898",
    value: "A801",
    label: "BERNALDA",
    province: "MT"
  },
  {
    code: "6899",
    value: "B391",
    label: "CALCIANO",
    province: "MT"
  },
  {
    code: "6900",
    value: "C723",
    label: "CIRIGLIANO",
    province: "MT"
  },
  {
    code: "6901",
    value: "C888",
    label: "COLOBRARO",
    province: "MT"
  },
  {
    code: "6902",
    value: "D128",
    label: "CRACO",
    province: "MT"
  },
  {
    code: "6903",
    value: "D547",
    label: "FERRANDINA",
    province: "MT"
  },
  {
    code: "6904",
    value: "D909",
    label: "GARAGUSO",
    province: "MT"
  },
  {
    code: "6905",
    value: "E093",
    label: "GORGOGLIONE",
    province: "MT"
  },
  {
    code: "6906",
    value: "E147",
    label: "GRASSANO",
    province: "MT"
  },
  {
    code: "6907",
    value: "E213",
    label: "GROTTOLE",
    province: "MT"
  },
  {
    code: "6908",
    value: "E326",
    label: "IRSINA",
    province: "MT"
  },
  {
    code: "6909",
    value: "F052",
    label: "MATERA",
    province: "MT"
  },
  {
    code: "6910",
    value: "F201",
    label: "MIGLIONICO",
    province: "MT"
  },
  {
    code: "6911",
    value: "F399",
    label: "MONTALBANO JONICO",
    province: "MT"
  },
  {
    code: "6912",
    value: "F637",
    label: "MONTESCAGLIOSO",
    province: "MT"
  },
  {
    code: "6913",
    value: "A942",
    label: "NOVA SIRI",
    province: "MT"
  },
  {
    code: "6914",
    value: "G037",
    label: "OLIVETO LUCANO",
    province: "MT"
  },
  {
    code: "6915",
    value: "G712",
    label: "PISTICCI",
    province: "MT"
  },
  {
    code: "6916",
    value: "G786",
    label: "POLICORO",
    province: "MT"
  },
  {
    code: "6917",
    value: "G806",
    label: "POMARICO",
    province: "MT"
  },
  {
    code: "6918",
    value: "H591",
    label: "ROTONDELLA",
    province: "MT"
  },
  {
    code: "6919",
    value: "H687",
    label: "SALANDRA",
    province: "MT"
  },
  {
    code: "6920",
    value: "H888",
    label: "SAN GIORGIO LUCANO",
    province: "MT"
  },
  {
    code: "6921",
    value: "I029",
    label: "SAN MAURO FORTE",
    province: "MT"
  },
  {
    code: "6922",
    value: "I954",
    label: "STIGLIANO",
    province: "MT"
  },
  {
    code: "6923",
    value: "L418",
    label: "TRICARICO",
    province: "MT"
  },
  {
    code: "6924",
    value: "L477",
    label: "TURSI",
    province: "MT"
  },
  {
    code: "6925",
    value: "D513",
    label: "VALSINNI",
    province: "MT"
  },
  {
    code: "6926",
    value: "M256",
    label: "SCANZANO JONICO",
    province: "MT"
  },
  {
    code: "6927",
    value: "A033",
    label: "ACQUAFORMOSA",
    province: "CS"
  },
  {
    code: "6928",
    value: "A041",
    label: "ACQUAPPESA",
    province: "CS"
  },
  {
    code: "6929",
    value: "A053",
    label: "ACRI",
    province: "CS"
  },
  {
    code: "6930",
    value: "A102",
    label: "AIELLO CALABRO",
    province: "CS"
  },
  {
    code: "6931",
    value: "A105",
    label: "AIETA",
    province: "CS"
  },
  {
    code: "6932",
    value: "A160",
    label: "ALBIDONA",
    province: "CS"
  },
  {
    code: "6933",
    value: "A183",
    label: "ALESSANDRIA DEL CARRETTO",
    province: "CS"
  },
  {
    code: "6934",
    value: "A234",
    label: "ALTILIA",
    province: "CS"
  },
  {
    code: "6935",
    value: "A240",
    label: "ALTOMONTE",
    province: "CS"
  },
  {
    code: "6936",
    value: "A253",
    label: "AMANTEA",
    province: "CS"
  },
  {
    code: "6937",
    value: "A263",
    label: "AMENDOLARA",
    province: "CS"
  },
  {
    code: "6938",
    value: "A340",
    label: "APRIGLIANO",
    province: "CS"
  },
  {
    code: "6939",
    value: "A762",
    label: "BELMONTE CALABRO",
    province: "CS"
  },
  {
    code: "6940",
    value: "A768",
    label: "BELSITO",
    province: "CS"
  },
  {
    code: "6941",
    value: "A773",
    label: "BELVEDERE MARITTIMO",
    province: "CS"
  },
  {
    code: "6942",
    value: "A842",
    label: "BIANCHI",
    province: "CS"
  },
  {
    code: "6943",
    value: "A887",
    label: "BISIGNANO",
    province: "CS"
  },
  {
    code: "6944",
    value: "A912",
    label: "BOCCHIGLIERO",
    province: "CS"
  },
  {
    code: "6945",
    value: "A973",
    label: "BONIFATI",
    province: "CS"
  },
  {
    code: "6946",
    value: "B270",
    label: "BUONVICINO",
    province: "CS"
  },
  {
    code: "6947",
    value: "B424",
    label: "CALOPEZZATI",
    province: "CS"
  },
  {
    code: "6948",
    value: "B426",
    label: "CALOVETO",
    province: "CS"
  },
  {
    code: "6949",
    value: "B500",
    label: "CAMPANA",
    province: "CS"
  },
  {
    code: "6950",
    value: "B607",
    label: "CANNA",
    province: "CS"
  },
  {
    code: "6951",
    value: "B774",
    label: "CARIATI",
    province: "CS"
  },
  {
    code: "6952",
    value: "B802",
    label: "CAROLEI",
    province: "CS"
  },
  {
    code: "6953",
    value: "B813",
    label: "CARPANZANO",
    province: "CS"
  },
  {
    code: "6955",
    value: "C002",
    label: "CASSANO ALLO IONIO",
    province: "CS"
  },
  {
    code: "6956",
    value: "C301",
    label: "CASTIGLIONE COSENTINO",
    province: "CS"
  },
  {
    code: "6957",
    value: "C108",
    label: "CASTROLIBERO",
    province: "CS"
  },
  {
    code: "6958",
    value: "C348",
    label: "CASTROREGIO",
    province: "CS"
  },
  {
    code: "6959",
    value: "C349",
    label: "CASTROVILLARI",
    province: "CS"
  },
  {
    code: "6960",
    value: "C430",
    label: "CELICO",
    province: "CS"
  },
  {
    code: "6961",
    value: "C437",
    label: "CELLARA",
    province: "CS"
  },
  {
    code: "6962",
    value: "C489",
    label: "CERCHIARA DI CALABRIA",
    province: "CS"
  },
  {
    code: "6963",
    value: "C515",
    label: "CERISANO",
    province: "CS"
  },
  {
    code: "6964",
    value: "C554",
    label: "CERVICATI",
    province: "CS"
  },
  {
    code: "6965",
    value: "C560",
    label: "CERZETO",
    province: "CS"
  },
  {
    code: "6966",
    value: "C588",
    label: "CETRARO",
    province: "CS"
  },
  {
    code: "6967",
    value: "C763",
    label: "CIVITA",
    province: "CS"
  },
  {
    code: "6968",
    value: "C795",
    label: "CLETO",
    province: "CS"
  },
  {
    code: "6969",
    value: "C905",
    label: "COLOSIMI",
    province: "CS"
  },
  {
    code: "6971",
    value: "D086",
    label: "COSENZA",
    province: "CS"
  },
  {
    code: "6972",
    value: "D180",
    label: "CROPALATI",
    province: "CS"
  },
  {
    code: "6973",
    value: "D184",
    label: "CROSIA",
    province: "CS"
  },
  {
    code: "6974",
    value: "D289",
    label: "DIAMANTE",
    province: "CS"
  },
  {
    code: "6975",
    value: "D304",
    label: "DIPIGNANO",
    province: "CS"
  },
  {
    code: "6976",
    value: "D328",
    label: "DOMANICO",
    province: "CS"
  },
  {
    code: "6977",
    value: "D464",
    label: "FAGNANO CASTELLO",
    province: "CS"
  },
  {
    code: "6978",
    value: "D473",
    label: "FALCONARA ALBANESE",
    province: "CS"
  },
  {
    code: "6979",
    value: "D582",
    label: "FIGLINE VEGLIATURO",
    province: "CS"
  },
  {
    code: "6980",
    value: "D614",
    label: "FIRMO",
    province: "CS"
  },
  {
    code: "6981",
    value: "D624",
    label: "FIUMEFREDDO BRUZIO",
    province: "CS"
  },
  {
    code: "6982",
    value: "D764",
    label: "FRANCAVILLA MARITTIMA",
    province: "CS"
  },
  {
    code: "6983",
    value: "D774",
    label: "FRASCINETO",
    province: "CS"
  },
  {
    code: "6984",
    value: "D828",
    label: "FUSCALDO",
    province: "CS"
  },
  {
    code: "6985",
    value: "E180",
    label: "GRIMALDI",
    province: "CS"
  },
  {
    code: "6986",
    value: "E185",
    label: "GRISOLIA",
    province: "CS"
  },
  {
    code: "6987",
    value: "E242",
    label: "GUARDIA PIEMONTESE",
    province: "CS"
  },
  {
    code: "6988",
    value: "E407",
    label: "LAGO",
    province: "CS"
  },
  {
    code: "6989",
    value: "E417",
    label: "LAINO BORGO",
    province: "CS"
  },
  {
    code: "6990",
    value: "E419",
    label: "LAINO CASTELLO",
    province: "CS"
  },
  {
    code: "6991",
    value: "E450",
    label: "LAPPANO",
    province: "CS"
  },
  {
    code: "6992",
    value: "E475",
    label: "LATTARICO",
    province: "CS"
  },
  {
    code: "6993",
    value: "E677",
    label: "LONGOBARDI",
    province: "CS"
  },
  {
    code: "6994",
    value: "E678",
    label: "LONGOBUCCO",
    province: "CS"
  },
  {
    code: "6995",
    value: "E745",
    label: "LUNGRO",
    province: "CS"
  },
  {
    code: "6996",
    value: "E773",
    label: "LUZZI",
    province: "CS"
  },
  {
    code: "6997",
    value: "E835",
    label: "MAIERA",
    province: "CS"
  },
  {
    code: "6998",
    value: "E859",
    label: "MALITO",
    province: "CS"
  },
  {
    code: "6999",
    value: "E872",
    label: "MALVITO",
    province: "CS"
  },
  {
    code: "7000",
    value: "E878",
    label: "MANDATORICCIO",
    province: "CS"
  },
  {
    code: "7001",
    value: "E888",
    label: "MANGONE",
    province: "CS"
  },
  {
    code: "7002",
    value: "E914",
    label: "MARANO MARCHESATO",
    province: "CS"
  },
  {
    code: "7003",
    value: "E915",
    label: "MARANO PRINCIPATO",
    province: "CS"
  },
  {
    code: "7004",
    value: "F001",
    label: "MARZI",
    province: "CS"
  },
  {
    code: "7005",
    value: "F125",
    label: "MENDICINO",
    province: "CS"
  },
  {
    code: "7006",
    value: "F370",
    label: "MONGRASSANO",
    province: "CS"
  },
  {
    code: "7007",
    value: "F416",
    label: "MONTALTO UFFUGO",
    province: "CS"
  },
  {
    code: "7008",
    value: "F519",
    label: "MONTEGIORDANO",
    province: "CS"
  },
  {
    code: "7009",
    value: "F708",
    label: "MORANO CALABRO",
    province: "CS"
  },
  {
    code: "7010",
    value: "F735",
    label: "MORMANNO",
    province: "CS"
  },
  {
    code: "7011",
    value: "F775",
    label: "MOTTAFOLLONE",
    province: "CS"
  },
  {
    code: "7012",
    value: "F907",
    label: "NOCARA",
    province: "CS"
  },
  {
    code: "7013",
    value: "G110",
    label: "ORIOLO",
    province: "CS"
  },
  {
    code: "7014",
    value: "G129",
    label: "ORSOMARSO",
    province: "CS"
  },
  {
    code: "7015",
    value: "G298",
    label: "PALUDI",
    province: "CS"
  },
  {
    code: "7016",
    value: "G307",
    label: "PANETTIERI",
    province: "CS"
  },
  {
    code: "7017",
    value: "G317",
    label: "PAOLA",
    province: "CS"
  },
  {
    code: "7018",
    value: "G320",
    label: "PAPASIDERO",
    province: "CS"
  },
  {
    code: "7019",
    value: "G331",
    label: "PARENTI",
    province: "CS"
  },
  {
    code: "7020",
    value: "G372",
    label: "PATERNO CALABRO",
    province: "CS"
  },
  {
    code: "7022",
    value: "G411",
    label: "PEDIVIGLIANO",
    province: "CS"
  },
  {
    code: "7023",
    value: "G553",
    label: "PIANE CRATI",
    province: "CS"
  },
  {
    code: "7024",
    value: "G615",
    label: "PIETRAFITTA",
    province: "CS"
  },
  {
    code: "7025",
    value: "G622",
    label: "PIETRAPAOLA",
    province: "CS"
  },
  {
    code: "7026",
    value: "G733",
    label: "PLATACI",
    province: "CS"
  },
  {
    code: "7027",
    value: "G975",
    label: "PRAIA A MARE",
    province: "CS"
  },
  {
    code: "7028",
    value: "H235",
    label: "RENDE",
    province: "CS"
  },
  {
    code: "7029",
    value: "H416",
    label: "ROCCA IMPERIALE",
    province: "CS"
  },
  {
    code: "7030",
    value: "H488",
    label: "ROGGIANO GRAVINA",
    province: "CS"
  },
  {
    code: "7031",
    value: "H490",
    label: "ROGLIANO",
    province: "CS"
  },
  {
    code: "7032",
    value: "H565",
    label: "ROSE",
    province: "CS"
  },
  {
    code: "7033",
    value: "H572",
    label: "ROSETO CAPO SPULICO",
    province: "CS"
  },
  {
    code: "7035",
    value: "H585",
    label: "ROTA GRECA",
    province: "CS"
  },
  {
    code: "7036",
    value: "H621",
    label: "ROVITO",
    province: "CS"
  },
  {
    code: "7037",
    value: "H765",
    label: "SAN BASILE",
    province: "CS"
  },
  {
    code: "7038",
    value: "H774",
    label: "SAN BENEDETTO ULLANO",
    province: "CS"
  },
  {
    code: "7039",
    value: "H806",
    label: "SAN COSMO ALBANESE",
    province: "CS"
  },
  {
    code: "7040",
    value: "H818",
    label: "SAN DEMETRIO CORONE",
    province: "CS"
  },
  {
    code: "7041",
    value: "H825",
    label: "SAN DONATO DI NINEA",
    province: "CS"
  },
  {
    code: "7042",
    value: "H841",
    label: "SAN FILI",
    province: "CS"
  },
  {
    code: "7043",
    value: "H877",
    label: "SANGINETO",
    province: "CS"
  },
  {
    code: "7044",
    value: "H881",
    label: "SAN GIORGIO ALBANESE",
    province: "CS"
  },
  {
    code: "7045",
    value: "H919",
    label: "SAN GIOVANNI IN FIORE",
    province: "CS"
  },
  {
    code: "7046",
    value: "H961",
    label: "SAN LORENZO BELLIZZI",
    province: "CS"
  },
  {
    code: "7047",
    value: "H962",
    label: "SAN LORENZO DEL VALLO",
    province: "CS"
  },
  {
    code: "7048",
    value: "H971",
    label: "SAN LUCIDO",
    province: "CS"
  },
  {
    code: "7049",
    value: "H981",
    label: "SAN MARCO ARGENTANO",
    province: "CS"
  },
  {
    code: "7050",
    value: "H992",
    label: "SAN MARTINO DI FINITA",
    province: "CS"
  },
  {
    code: "7051",
    value: "I060",
    label: "SAN NICOLA ARCELLA",
    province: "CS"
  },
  {
    code: "7052",
    value: "I108",
    label: "SAN PIETRO IN AMANTEA",
    province: "CS"
  },
  {
    code: "7053",
    value: "I114",
    label: "SAN PIETRO IN GUARANO",
    province: "CS"
  },
  {
    code: "7054",
    value: "I165",
    label: "SAN SOSTI",
    province: "CS"
  },
  {
    code: "7055",
    value: "I171",
    label: "SANTA CATERINA ALBANESE",
    province: "CS"
  },
  {
    code: "7056",
    value: "I183",
    label: "SANTA DOMENICA TALAO",
    province: "CS"
  },
  {
    code: "7057",
    value: "I192",
    label: "SANT AGATA DI ESARO",
    province: "CS"
  },
  {
    code: "7058",
    value: "C717",
    label: "SANTA MARIA DEL CEDRO",
    province: "CS"
  },
  {
    code: "7059",
    value: "I309",
    label: "SANTA SOFIA D EPIRO",
    province: "CS"
  },
  {
    code: "7060",
    value: "I359",
    label: "SANTO STEFANO DI ROGLIANO",
    province: "CS"
  },
  {
    code: "7061",
    value: "I388",
    label: "SAN VINCENZO LA COSTA",
    province: "CS"
  },
  {
    code: "7062",
    value: "I423",
    label: "SARACENA",
    province: "CS"
  },
  {
    code: "7063",
    value: "I485",
    label: "SCALA COELI",
    province: "CS"
  },
  {
    code: "7064",
    value: "I489",
    label: "SCALEA",
    province: "CS"
  },
  {
    code: "7065",
    value: "D290",
    label: "SCIGLIANO",
    province: "CS"
  },
  {
    code: "7066",
    value: "I642",
    label: "SERRA D AIELLO",
    province: "CS"
  },
  {
    code: "7068",
    value: "I895",
    label: "SPEZZANO ALBANESE",
    province: "CS"
  },
  {
    code: "7069",
    value: "I896",
    label: "SPEZZANO DELLA SILA",
    province: "CS"
  },
  {
    code: "7071",
    value: "L055",
    label: "TARSIA",
    province: "CS"
  },
  {
    code: "7072",
    value: "L124",
    label: "TERRANOVA DA SIBARI",
    province: "CS"
  },
  {
    code: "7073",
    value: "L134",
    label: "TERRAVECCHIA",
    province: "CS"
  },
  {
    code: "7074",
    value: "L206",
    label: "TORANO CASTELLO",
    province: "CS"
  },
  {
    code: "7075",
    value: "L305",
    label: "TORTORA",
    province: "CS"
  },
  {
    code: "7076",
    value: "L353",
    label: "TREBISACCE",
    province: "CS"
  },
  {
    code: "7078",
    value: "L524",
    label: "VACCARIZZO ALBANESE",
    province: "CS"
  },
  {
    code: "7079",
    value: "L747",
    label: "VERBICARO",
    province: "CS"
  },
  {
    code: "7080",
    value: "B903",
    label: "VILLAPIANA",
    province: "CS"
  },
  {
    code: "7081",
    value: "M202",
    label: "ZUMPANO",
    province: "CS"
  },
  {
    code: "7082",
    value: "A155",
    label: "ALBI",
    province: "CZ"
  },
  {
    code: "7083",
    value: "A255",
    label: "AMARONI",
    province: "CZ"
  },
  {
    code: "7084",
    value: "A257",
    label: "AMATO",
    province: "CZ"
  },
  {
    code: "7085",
    value: "A272",
    label: "ANDALI",
    province: "CZ"
  },
  {
    code: "7086",
    value: "A397",
    label: "ARGUSTO",
    province: "CZ"
  },
  {
    code: "7087",
    value: "A542",
    label: "BADOLATO",
    province: "CZ"
  },
  {
    code: "7088",
    value: "A736",
    label: "BELCASTRO",
    province: "CZ"
  },
  {
    code: "7089",
    value: "B002",
    label: "BORGIA",
    province: "CZ"
  },
  {
    code: "7090",
    value: "B085",
    label: "BOTRICELLO",
    province: "CZ"
  },
  {
    code: "7091",
    value: "B717",
    label: "CARAFFA DI CATANZARO",
    province: "CZ"
  },
  {
    code: "7092",
    value: "B758",
    label: "CARDINALE",
    province: "CZ"
  },
  {
    code: "7093",
    value: "B790",
    label: "CARLOPOLI",
    province: "CZ"
  },
  {
    code: "7094",
    value: "C352",
    label: "CATANZARO",
    province: "CZ"
  },
  {
    code: "7095",
    value: "C453",
    label: "CENADI",
    province: "CZ"
  },
  {
    code: "7096",
    value: "C472",
    label: "CENTRACHE",
    province: "CZ"
  },
  {
    code: "7097",
    value: "C542",
    label: "CERVA",
    province: "CZ"
  },
  {
    code: "7098",
    value: "C616",
    label: "CHIARAVALLE CENTRALE",
    province: "CZ"
  },
  {
    code: "7099",
    value: "C674",
    label: "CICALA",
    province: "CZ"
  },
  {
    code: "7100",
    value: "C960",
    label: "CONFLENTI",
    province: "CZ"
  },
  {
    code: "7101",
    value: "D049",
    label: "CORTALE",
    province: "CZ"
  },
  {
    code: "7102",
    value: "D181",
    label: "CROPANI",
    province: "CZ"
  },
  {
    code: "7103",
    value: "D218",
    label: "CURINGA",
    province: "CZ"
  },
  {
    code: "7104",
    value: "D257",
    label: "DAVOLI",
    province: "CZ"
  },
  {
    code: "7105",
    value: "D261",
    label: "DECOLLATURA",
    province: "CZ"
  },
  {
    code: "7106",
    value: "D476",
    label: "FALERNA",
    province: "CZ"
  },
  {
    code: "7107",
    value: "D544",
    label: "FEROLETO ANTICO",
    province: "CZ"
  },
  {
    code: "7108",
    value: "D744",
    label: "FOSSATO SERRALTA",
    province: "CZ"
  },
  {
    code: "7109",
    value: "D852",
    label: "GAGLIATO",
    province: "CZ"
  },
  {
    code: "7110",
    value: "D932",
    label: "GASPERINA",
    province: "CZ"
  },
  {
    code: "7111",
    value: "E031",
    label: "GIMIGLIANO",
    province: "CZ"
  },
  {
    code: "7112",
    value: "E050",
    label: "GIRIFALCO",
    province: "CZ"
  },
  {
    code: "7113",
    value: "E068",
    label: "GIZZERIA",
    province: "CZ"
  },
  {
    code: "7114",
    value: "E239",
    label: "GUARDAVALLE",
    province: "CZ"
  },
  {
    code: "7115",
    value: "E328",
    label: "ISCA SULLO IONIO",
    province: "CZ"
  },
  {
    code: "7116",
    value: "E274",
    label: "JACURSO",
    province: "CZ"
  },
  {
    code: "7117",
    value: "E806",
    label: "MAGISANO",
    province: "CZ"
  },
  {
    code: "7118",
    value: "E834",
    label: "MAIDA",
    province: "CZ"
  },
  {
    code: "7119",
    value: "E923",
    label: "MARCEDUSA",
    province: "CZ"
  },
  {
    code: "7120",
    value: "E925",
    label: "MARCELLINARA",
    province: "CZ"
  },
  {
    code: "7121",
    value: "E990",
    label: "MARTIRANO",
    province: "CZ"
  },
  {
    code: "7122",
    value: "E991",
    label: "MARTIRANO LOMBARDO",
    province: "CZ"
  },
  {
    code: "7123",
    value: "F200",
    label: "MIGLIERINA",
    province: "CZ"
  },
  {
    code: "7124",
    value: "F432",
    label: "MONTAURO",
    province: "CZ"
  },
  {
    code: "7125",
    value: "F586",
    label: "MONTEPAONE",
    province: "CZ"
  },
  {
    code: "7126",
    value: "F780",
    label: "MOTTA SANTA LUCIA",
    province: "CZ"
  },
  {
    code: "7127",
    value: "F910",
    label: "NOCERA TERINESE",
    province: "CZ"
  },
  {
    code: "7128",
    value: "G034",
    label: "OLIVADI",
    province: "CZ"
  },
  {
    code: "7129",
    value: "G272",
    label: "PALERMITI",
    province: "CZ"
  },
  {
    code: "7130",
    value: "G439",
    label: "PENTONE",
    province: "CZ"
  },
  {
    code: "7131",
    value: "G517",
    label: "PETRIZZI",
    province: "CZ"
  },
  {
    code: "7132",
    value: "G518",
    label: "PETRONA",
    province: "CZ"
  },
  {
    code: "7133",
    value: "D546",
    label: "PIANOPOLI",
    province: "CZ"
  },
  {
    code: "7134",
    value: "G734",
    label: "PLATANIA",
    province: "CZ"
  },
  {
    code: "7135",
    value: "H846",
    label: "SAN FLORO",
    province: "CZ"
  },
  {
    code: "7136",
    value: "H976",
    label: "SAN MANGO D AQUINO",
    province: "CZ"
  },
  {
    code: "7137",
    value: "I093",
    label: "SAN PIETRO A MAIDA",
    province: "CZ"
  },
  {
    code: "7138",
    value: "I095",
    label: "SAN PIETRO APOSTOLO",
    province: "CZ"
  },
  {
    code: "7139",
    value: "I164",
    label: "SAN SOSTENE",
    province: "CZ"
  },
  {
    code: "7140",
    value: "I170",
    label: "SANTA CATERINA DELLO IONIO",
    province: "CZ"
  },
  {
    code: "7141",
    value: "I266",
    label: "SANT ANDREA APOSTOLO DELLO IONIO",
    province: "CZ"
  },
  {
    code: "7142",
    value: "I393",
    label: "SAN VITO SULLO IONIO",
    province: "CZ"
  },
  {
    code: "7143",
    value: "I463",
    label: "SATRIANO",
    province: "CZ"
  },
  {
    code: "7144",
    value: "I589",
    label: "SELLIA",
    province: "CZ"
  },
  {
    code: "7145",
    value: "I590",
    label: "SELLIA MARINA",
    province: "CZ"
  },
  {
    code: "7146",
    value: "I655",
    label: "SERRASTRETTA",
    province: "CZ"
  },
  {
    code: "7147",
    value: "I671",
    label: "SERSALE",
    province: "CZ"
  },
  {
    code: "7148",
    value: "I704",
    label: "SETTINGIANO",
    province: "CZ"
  },
  {
    code: "7149",
    value: "I745",
    label: "SIMERI CRICHI",
    province: "CZ"
  },
  {
    code: "7150",
    value: "I844",
    label: "SORBO SAN BASILE",
    province: "CZ"
  },
  {
    code: "7151",
    value: "I872",
    label: "SOVERATO",
    province: "CZ"
  },
  {
    code: "7152",
    value: "I874",
    label: "SOVERIA MANNELLI",
    province: "CZ"
  },
  {
    code: "7153",
    value: "I875",
    label: "SOVERIA SIMERI",
    province: "CZ"
  },
  {
    code: "7154",
    value: "I929",
    label: "SQUILLACE",
    province: "CZ"
  },
  {
    code: "7155",
    value: "I937",
    label: "STALETTI",
    province: "CZ"
  },
  {
    code: "7156",
    value: "L070",
    label: "TAVERNA",
    province: "CZ"
  },
  {
    code: "7157",
    value: "L177",
    label: "TIRIOLO",
    province: "CZ"
  },
  {
    code: "7158",
    value: "L240",
    label: "TORRE DI RUGGIERO",
    province: "CZ"
  },
  {
    code: "7159",
    value: "I322",
    label: "VALLEFIORITA",
    province: "CZ"
  },
  {
    code: "7160",
    value: "M140",
    label: "ZAGARISE",
    province: "CZ"
  },
  {
    code: "7161",
    value: "M208",
    label: "LAMEZIA TERME",
    province: "CZ"
  },
  {
    code: "7162",
    value: "A065",
    label: "AFRICO",
    province: "RC"
  },
  {
    code: "7163",
    value: "A077",
    label: "AGNANA CALABRA",
    province: "RC"
  },
  {
    code: "7164",
    value: "A303",
    label: "ANOIA",
    province: "RC"
  },
  {
    code: "7165",
    value: "A314",
    label: "ANTONIMINA",
    province: "RC"
  },
  {
    code: "7166",
    value: "A385",
    label: "ARDORE",
    province: "RC"
  },
  {
    code: "7167",
    value: "A544",
    label: "BAGALADI",
    province: "RC"
  },
  {
    code: "7168",
    value: "A552",
    label: "BAGNARA CALABRA",
    province: "RC"
  },
  {
    code: "7169",
    value: "A780",
    label: "BENESTARE",
    province: "RC"
  },
  {
    code: "7170",
    value: "A843",
    label: "BIANCO",
    province: "RC"
  },
  {
    code: "7171",
    value: "A897",
    label: "BIVONGI",
    province: "RC"
  },
  {
    code: "7172",
    value: "B097",
    label: "BOVA",
    province: "RC"
  },
  {
    code: "7173",
    value: "B098",
    label: "BOVALINO",
    province: "RC"
  },
  {
    code: "7174",
    value: "B099",
    label: "BOVA MARINA",
    province: "RC"
  },
  {
    code: "7175",
    value: "B118",
    label: "BRANCALEONE",
    province: "RC"
  },
  {
    code: "7176",
    value: "B234",
    label: "BRUZZANO ZEFFIRIO",
    province: "RC"
  },
  {
    code: "7177",
    value: "B379",
    label: "CALANNA",
    province: "RC"
  },
  {
    code: "7178",
    value: "B481",
    label: "CAMINI",
    province: "RC"
  },
  {
    code: "7179",
    value: "B516",
    label: "CAMPO CALABRO",
    province: "RC"
  },
  {
    code: "7180",
    value: "B591",
    label: "CANDIDONI",
    province: "RC"
  },
  {
    code: "7181",
    value: "B617",
    label: "CANOLO",
    province: "RC"
  },
  {
    code: "7182",
    value: "B718",
    label: "CARAFFA DEL BIANCO",
    province: "RC"
  },
  {
    code: "7183",
    value: "B756",
    label: "CARDETO",
    province: "RC"
  },
  {
    code: "7184",
    value: "B766",
    label: "CARERI",
    province: "RC"
  },
  {
    code: "7185",
    value: "B966",
    label: "CASIGNANA",
    province: "RC"
  },
  {
    code: "7186",
    value: "C285",
    label: "CAULONIA",
    province: "RC"
  },
  {
    code: "7187",
    value: "C695",
    label: "CIMINA",
    province: "RC"
  },
  {
    code: "7188",
    value: "C710",
    label: "CINQUEFRONDI",
    province: "RC"
  },
  {
    code: "7189",
    value: "C747",
    label: "CITTANOVA",
    province: "RC"
  },
  {
    code: "7190",
    value: "C954",
    label: "CONDOFURI",
    province: "RC"
  },
  {
    code: "7191",
    value: "D089",
    label: "COSOLETO",
    province: "RC"
  },
  {
    code: "7192",
    value: "D268",
    label: "DELIANUOVA",
    province: "RC"
  },
  {
    code: "7193",
    value: "D545",
    label: "FEROLETO DELLA CHIESA",
    province: "RC"
  },
  {
    code: "7194",
    value: "D557",
    label: "FERRUZZANO",
    province: "RC"
  },
  {
    code: "7195",
    value: "D619",
    label: "FIUMARA",
    province: "RC"
  },
  {
    code: "7196",
    value: "D864",
    label: "GALATRO",
    province: "RC"
  },
  {
    code: "7197",
    value: "D975",
    label: "GERACE",
    province: "RC"
  },
  {
    code: "7198",
    value: "E025",
    label: "GIFFONE",
    province: "RC"
  },
  {
    code: "7199",
    value: "E041",
    label: "GIOIA TAURO",
    province: "RC"
  },
  {
    code: "7200",
    value: "E044",
    label: "GIOIOSA IONICA",
    province: "RC"
  },
  {
    code: "7201",
    value: "E212",
    label: "GROTTERIA",
    province: "RC"
  },
  {
    code: "7202",
    value: "E402",
    label: "LAGANADI",
    province: "RC"
  },
  {
    code: "7203",
    value: "E479",
    label: "LAUREANA DI BORRELLO",
    province: "RC"
  },
  {
    code: "7204",
    value: "D976",
    label: "LOCRI",
    province: "RC"
  },
  {
    code: "7205",
    value: "E873",
    label: "MAMMOLA",
    province: "RC"
  },
  {
    code: "7206",
    value: "E956",
    label: "MARINA DI GIOIOSA IONICA",
    province: "RC"
  },
  {
    code: "7207",
    value: "E968",
    label: "MAROPATI",
    province: "RC"
  },
  {
    code: "7208",
    value: "E993",
    label: "MARTONE",
    province: "RC"
  },
  {
    code: "7209",
    value: "F105",
    label: "MELICUCCA",
    province: "RC"
  },
  {
    code: "7210",
    value: "F106",
    label: "MELICUCCO",
    province: "RC"
  },
  {
    code: "7211",
    value: "F112",
    label: "MELITO DI PORTO SALVO",
    province: "RC"
  },
  {
    code: "7212",
    value: "F301",
    label: "MOLOCHIO",
    province: "RC"
  },
  {
    code: "7213",
    value: "F324",
    label: "MONASTERACE",
    province: "RC"
  },
  {
    code: "7214",
    value: "D746",
    label: "MONTEBELLO JONICO",
    province: "RC"
  },
  {
    code: "7215",
    value: "F779",
    label: "MOTTA SAN GIOVANNI",
    province: "RC"
  },
  {
    code: "7216",
    value: "G082",
    label: "OPPIDO MAMERTINA",
    province: "RC"
  },
  {
    code: "7217",
    value: "G277",
    label: "PALIZZI",
    province: "RC"
  },
  {
    code: "7218",
    value: "G288",
    label: "PALMI",
    province: "RC"
  },
  {
    code: "7219",
    value: "G394",
    label: "PAZZANO",
    province: "RC"
  },
  {
    code: "7220",
    value: "G729",
    label: "PLACANICA",
    province: "RC"
  },
  {
    code: "7221",
    value: "G735",
    label: "PLATI",
    province: "RC"
  },
  {
    code: "7222",
    value: "G791",
    label: "POLISTENA",
    province: "RC"
  },
  {
    code: "7223",
    value: "G905",
    label: "PORTIGLIOLA",
    province: "RC"
  },
  {
    code: "7224",
    value: "H224",
    label: "REGGIO CALABRIA",
    province: "RC"
  },
  {
    code: "7225",
    value: "H265",
    label: "RIACE",
    province: "RC"
  },
  {
    code: "7226",
    value: "H359",
    label: "RIZZICONI",
    province: "RC"
  },
  {
    code: "7227",
    value: "H408",
    label: "ROCCAFORTE DEL GRECO",
    province: "RC"
  },
  {
    code: "7228",
    value: "H456",
    label: "ROCCELLA IONICA",
    province: "RC"
  },
  {
    code: "7229",
    value: "H489",
    label: "ROGHUDI",
    province: "RC"
  },
  {
    code: "7230",
    value: "H558",
    label: "ROSARNO",
    province: "RC"
  },
  {
    code: "7231",
    value: "H013",
    label: "SAMO",
    province: "RC"
  },
  {
    code: "7232",
    value: "H889",
    label: "SAN GIORGIO MORGETO",
    province: "RC"
  },
  {
    code: "7233",
    value: "H903",
    label: "SAN GIOVANNI DI GERACE",
    province: "RC"
  },
  {
    code: "7234",
    value: "H959",
    label: "SAN LORENZO",
    province: "RC"
  },
  {
    code: "7235",
    value: "H970",
    label: "SAN LUCA",
    province: "RC"
  },
  {
    code: "7236",
    value: "I102",
    label: "SAN PIETRO DI CARIDA",
    province: "RC"
  },
  {
    code: "7237",
    value: "I132",
    label: "SAN PROCOPIO",
    province: "RC"
  },
  {
    code: "7238",
    value: "I139",
    label: "SAN ROBERTO",
    province: "RC"
  },
  {
    code: "7239",
    value: "I176",
    label: "SANTA CRISTINA D ASPROMONTE",
    province: "RC"
  },
  {
    code: "7240",
    value: "I198",
    label: "SANT AGATA DEL BIANCO",
    province: "RC"
  },
  {
    code: "7241",
    value: "I214",
    label: "SANT ALESSIO IN ASPROMONTE",
    province: "RC"
  },
  {
    code: "7242",
    value: "I333",
    label: "SANT EUFEMIA D ASPROMONTE",
    province: "RC"
  },
  {
    code: "7243",
    value: "I341",
    label: "SANT ILARIO DELLO IONIO",
    province: "RC"
  },
  {
    code: "7244",
    value: "I371",
    label: "SANTO STEFANO IN ASPROMONTE",
    province: "RC"
  },
  {
    code: "7245",
    value: "I536",
    label: "SCIDO",
    province: "RC"
  },
  {
    code: "7246",
    value: "I537",
    label: "SCILLA",
    province: "RC"
  },
  {
    code: "7247",
    value: "I600",
    label: "SEMINARA",
    province: "RC"
  },
  {
    code: "7248",
    value: "I656",
    label: "SERRATA",
    province: "RC"
  },
  {
    code: "7249",
    value: "I725",
    label: "SIDERNO",
    province: "RC"
  },
  {
    code: "7250",
    value: "I753",
    label: "SINOPOLI",
    province: "RC"
  },
  {
    code: "7251",
    value: "I936",
    label: "STAITI",
    province: "RC"
  },
  {
    code: "7252",
    value: "I955",
    label: "STIGNANO",
    province: "RC"
  },
  {
    code: "7253",
    value: "I956",
    label: "STILO",
    province: "RC"
  },
  {
    code: "7254",
    value: "L063",
    label: "TAURIANOVA",
    province: "RC"
  },
  {
    code: "7255",
    value: "L127",
    label: "TERRANOVA SAPPO MINULIO",
    province: "RC"
  },
  {
    code: "7256",
    value: "L673",
    label: "VARAPODIO",
    province: "RC"
  },
  {
    code: "7257",
    value: "M018",
    label: "VILLA SAN GIOVANNI",
    province: "RC"
  },
  {
    code: "7258",
    value: "M277",
    label: "SAN FERDINANDO",
    province: "RC"
  },
  {
    code: "7259",
    value: "A772",
    label: "BELVEDERE DI SPINELLO",
    province: "KR"
  },
  {
    code: "7260",
    value: "B319",
    label: "CACCURI",
    province: "KR"
  },
  {
    code: "7261",
    value: "B771",
    label: "CARFIZZI",
    province: "KR"
  },
  {
    code: "7262",
    value: "B857",
    label: "CASABONA",
    province: "KR"
  },
  {
    code: "7263",
    value: "B968",
    label: "CASTELSILANO",
    province: "KR"
  },
  {
    code: "7264",
    value: "C501",
    label: "CERENZIA",
    province: "KR"
  },
  {
    code: "7265",
    value: "C725",
    label: "CIRO",
    province: "KR"
  },
  {
    code: "7266",
    value: "C726",
    label: "CIRO MARINA",
    province: "KR"
  },
  {
    code: "7267",
    value: "D123",
    label: "COTRONEI",
    province: "KR"
  },
  {
    code: "7268",
    value: "D122",
    label: "CROTONE",
    province: "KR"
  },
  {
    code: "7269",
    value: "D189",
    label: "CRUCOLI",
    province: "KR"
  },
  {
    code: "7270",
    value: "D236",
    label: "CUTRO",
    province: "KR"
  },
  {
    code: "7271",
    value: "E339",
    label: "ISOLA DI CAPO RIZZUTO",
    province: "KR"
  },
  {
    code: "7272",
    value: "F108",
    label: "MELISSA",
    province: "KR"
  },
  {
    code: "7273",
    value: "F157",
    label: "MESORACA",
    province: "KR"
  },
  {
    code: "7274",
    value: "G278",
    label: "PALLAGORIO",
    province: "KR"
  },
  {
    code: "7275",
    value: "G508",
    label: "PETILIA POLICASTRO",
    province: "KR"
  },
  {
    code: "7276",
    value: "H383",
    label: "ROCCABERNARDA",
    province: "KR"
  },
  {
    code: "7277",
    value: "H403",
    label: "ROCCA DI NETO",
    province: "KR"
  },
  {
    code: "7278",
    value: "I026",
    label: "SAN MAURO MARCHESATO",
    province: "KR"
  },
  {
    code: "7279",
    value: "I057",
    label: "SAN NICOLA DELL ALTO",
    province: "KR"
  },
  {
    code: "7280",
    value: "I308",
    label: "SANTA SEVERINA",
    province: "KR"
  },
  {
    code: "7281",
    value: "I468",
    label: "SAVELLI",
    province: "KR"
  },
  {
    code: "7282",
    value: "I494",
    label: "SCANDALE",
    province: "KR"
  },
  {
    code: "7283",
    value: "I982",
    label: "STRONGOLI",
    province: "KR"
  },
  {
    code: "7284",
    value: "L492",
    label: "UMBRIATICO",
    province: "KR"
  },
  {
    code: "7285",
    value: "L802",
    label: "VERZINO",
    province: "KR"
  },
  {
    code: "7286",
    value: "A043",
    label: "ACQUARO",
    province: "VV"
  },
  {
    code: "7287",
    value: "A386",
    label: "ARENA",
    province: "VV"
  },
  {
    code: "7288",
    value: "B169",
    label: "BRIATICO",
    province: "VV"
  },
  {
    code: "7289",
    value: "B197",
    label: "BROGNATURO",
    province: "VV"
  },
  {
    code: "7290",
    value: "B655",
    label: "CAPISTRANO",
    province: "VV"
  },
  {
    code: "7291",
    value: "C581",
    label: "CESSANITI",
    province: "VV"
  },
  {
    code: "7292",
    value: "D253",
    label: "DASA",
    province: "VV"
  },
  {
    code: "7293",
    value: "D303",
    label: "DINAMI",
    province: "VV"
  },
  {
    code: "7294",
    value: "D364",
    label: "DRAPIA",
    province: "VV"
  },
  {
    code: "7295",
    value: "D453",
    label: "FABRIZIA",
    province: "VV"
  },
  {
    code: "7296",
    value: "D587",
    label: "FILADELFIA",
    province: "VV"
  },
  {
    code: "7297",
    value: "D589",
    label: "FILANDARI",
    province: "VV"
  },
  {
    code: "7298",
    value: "D596",
    label: "FILOGASO",
    province: "VV"
  },
  {
    code: "7299",
    value: "D762",
    label: "FRANCAVILLA ANGITOLA",
    province: "VV"
  },
  {
    code: "7300",
    value: "D767",
    label: "FRANCICA",
    province: "VV"
  },
  {
    code: "7301",
    value: "D988",
    label: "GEROCARNE",
    province: "VV"
  },
  {
    code: "7302",
    value: "E321",
    label: "IONADI",
    province: "VV"
  },
  {
    code: "7303",
    value: "E389",
    label: "JOPPOLO",
    province: "VV"
  },
  {
    code: "7304",
    value: "E590",
    label: "LIMBADI",
    province: "VV"
  },
  {
    code: "7305",
    value: "E836",
    label: "MAIERATO",
    province: "VV"
  },
  {
    code: "7306",
    value: "F207",
    label: "MILETO",
    province: "VV"
  },
  {
    code: "7307",
    value: "F364",
    label: "MONGIANA",
    province: "VV"
  },
  {
    code: "7308",
    value: "F607",
    label: "MONTEROSSO CALABRO",
    province: "VV"
  },
  {
    code: "7309",
    value: "F843",
    label: "NARDODIPACE",
    province: "VV"
  },
  {
    code: "7310",
    value: "F893",
    label: "NICOTERA",
    province: "VV"
  },
  {
    code: "7311",
    value: "G335",
    label: "PARGHELIA",
    province: "VV"
  },
  {
    code: "7312",
    value: "G722",
    label: "PIZZO",
    province: "VV"
  },
  {
    code: "7313",
    value: "G728",
    label: "PIZZONI",
    province: "VV"
  },
  {
    code: "7314",
    value: "G785",
    label: "POLIA",
    province: "VV"
  },
  {
    code: "7315",
    value: "H271",
    label: "RICADI",
    province: "VV"
  },
  {
    code: "7316",
    value: "H516",
    label: "ROMBIOLO",
    province: "VV"
  },
  {
    code: "7317",
    value: "H785",
    label: "SAN CALOGERO",
    province: "VV"
  },
  {
    code: "7318",
    value: "H807",
    label: "SAN COSTANTINO CALABRO",
    province: "VV"
  },
  {
    code: "7319",
    value: "H941",
    label: "SAN GREGORIO D IPPONA",
    province: "VV"
  },
  {
    code: "7320",
    value: "I058",
    label: "SAN NICOLA DA CRISSA",
    province: "VV"
  },
  {
    code: "7321",
    value: "I350",
    label: "SANT ONOFRIO",
    province: "VV"
  },
  {
    code: "7322",
    value: "I639",
    label: "SERRA SAN BRUNO",
    province: "VV"
  },
  {
    code: "7323",
    value: "I744",
    label: "SIMBARIO",
    province: "VV"
  },
  {
    code: "7324",
    value: "I853",
    label: "SORIANELLO",
    province: "VV"
  },
  {
    code: "7325",
    value: "I854",
    label: "SORIANO CALABRO",
    province: "VV"
  },
  {
    code: "7326",
    value: "I884",
    label: "SPADOLA",
    province: "VV"
  },
  {
    code: "7327",
    value: "I905",
    label: "SPILINGA",
    province: "VV"
  },
  {
    code: "7328",
    value: "I945",
    label: "STEFANACONI",
    province: "VV"
  },
  {
    code: "7329",
    value: "L452",
    label: "TROPEA",
    province: "VV"
  },
  {
    code: "7330",
    value: "L607",
    label: "VALLELONGA",
    province: "VV"
  },
  {
    code: "7331",
    value: "L699",
    label: "VAZZANO",
    province: "VV"
  },
  {
    code: "7332",
    value: "F537",
    label: "VIBO VALENTIA",
    province: "VV"
  },
  {
    code: "7333",
    value: "M138",
    label: "ZACCANOPOLI",
    province: "VV"
  },
  {
    code: "7334",
    value: "M143",
    label: "ZAMBRONE",
    province: "VV"
  },
  {
    code: "7335",
    value: "M204",
    label: "ZUNGRI",
    province: "VV"
  },
  {
    code: "7336",
    value: "A176",
    label: "ALCAMO",
    province: "TP"
  },
  {
    code: "7337",
    value: "B288",
    label: "BUSETO PALIZZOLO",
    province: "TP"
  },
  {
    code: "7338",
    value: "B385",
    label: "CALATAFIMI SEGESTA",
    province: "TP"
  },
  {
    code: "7339",
    value: "B521",
    label: "CAMPOBELLO DI MAZARA",
    province: "TP"
  },
  {
    code: "7340",
    value: "C130",
    label: "CASTELLAMMARE DEL GOLFO",
    province: "TP"
  },
  {
    code: "7341",
    value: "C286",
    label: "CASTELVETRANO",
    province: "TP"
  },
  {
    code: "7342",
    value: "D234",
    label: "CUSTONACI",
    province: "TP"
  },
  {
    code: "7343",
    value: "D423",
    label: "ERICE",
    province: "TP"
  },
  {
    code: "7344",
    value: "D518",
    label: "FAVIGNANA",
    province: "TP"
  },
  {
    code: "7345",
    value: "E023",
    label: "GIBELLINA",
    province: "TP"
  },
  {
    code: "7346",
    value: "E974",
    label: "MARSALA",
    province: "TP"
  },
  {
    code: "7347",
    value: "F061",
    label: "MAZARA DEL VALLO",
    province: "TP"
  },
  {
    code: "7348",
    value: "G208",
    label: "PACECO",
    province: "TP"
  },
  {
    code: "7349",
    value: "G315",
    label: "PANTELLERIA",
    province: "TP"
  },
  {
    code: "7350",
    value: "G347",
    label: "PARTANNA",
    province: "TP"
  },
  {
    code: "7351",
    value: "G767",
    label: "POGGIOREALE",
    province: "TP"
  },
  {
    code: "7352",
    value: "H688",
    label: "SALAPARUTA",
    province: "TP"
  },
  {
    code: "7353",
    value: "H700",
    label: "SALEMI",
    province: "TP"
  },
  {
    code: "7354",
    value: "I291",
    label: "SANTA NINFA",
    province: "TP"
  },
  {
    code: "7355",
    value: "I407",
    label: "SAN VITO LO CAPO",
    province: "TP"
  },
  {
    code: "7356",
    value: "L331",
    label: "TRAPANI",
    province: "TP"
  },
  {
    code: "7357",
    value: "G319",
    label: "VALDERICE",
    province: "TP"
  },
  {
    code: "7358",
    value: "M081",
    label: "VITA",
    province: "TP"
  },
  {
    code: "7359",
    value: "M281",
    label: "PETROSINO",
    province: "TP"
  },
  {
    code: "7360",
    value: "A195",
    label: "ALIA",
    province: "PA"
  },
  {
    code: "7361",
    value: "A202",
    label: "ALIMENA",
    province: "PA"
  },
  {
    code: "7362",
    value: "A203",
    label: "ALIMINUSA",
    province: "PA"
  },
  {
    code: "7363",
    value: "A229",
    label: "ALTAVILLA MILICIA",
    province: "PA"
  },
  {
    code: "7364",
    value: "A239",
    label: "ALTOFONTE",
    province: "PA"
  },
  {
    code: "7365",
    value: "A546",
    label: "BAGHERIA",
    province: "PA"
  },
  {
    code: "7366",
    value: "A592",
    label: "BALESTRATE",
    province: "PA"
  },
  {
    code: "7367",
    value: "A719",
    label: "BAUCINA",
    province: "PA"
  },
  {
    code: "7368",
    value: "A764",
    label: "BELMONTE MEZZAGNO",
    province: "PA"
  },
  {
    code: "7369",
    value: "A882",
    label: "BISACQUINO",
    province: "PA"
  },
  {
    code: "7370",
    value: "A946",
    label: "BOLOGNETTA",
    province: "PA"
  },
  {
    code: "7371",
    value: "A958",
    label: "BOMPIETRO",
    province: "PA"
  },
  {
    code: "7372",
    value: "A991",
    label: "BORGETTO",
    province: "PA"
  },
  {
    code: "7373",
    value: "B315",
    label: "CACCAMO",
    province: "PA"
  },
  {
    code: "7374",
    value: "B430",
    label: "CALTAVUTURO",
    province: "PA"
  },
  {
    code: "7375",
    value: "B533",
    label: "CAMPOFELICE DI FITALIA",
    province: "PA"
  },
  {
    code: "7376",
    value: "B532",
    label: "CAMPOFELICE DI ROCCELLA",
    province: "PA"
  },
  {
    code: "7377",
    value: "B535",
    label: "CAMPOFIORITO",
    province: "PA"
  },
  {
    code: "7378",
    value: "B556",
    label: "CAMPOREALE",
    province: "PA"
  },
  {
    code: "7379",
    value: "B645",
    label: "CAPACI",
    province: "PA"
  },
  {
    code: "7380",
    value: "B780",
    label: "CARINI",
    province: "PA"
  },
  {
    code: "7381",
    value: "C067",
    label: "CASTELBUONO",
    province: "PA"
  },
  {
    code: "7382",
    value: "C074",
    label: "CASTELDACCIA",
    province: "PA"
  },
  {
    code: "7383",
    value: "C135",
    label: "CASTELLANA SICULA",
    province: "PA"
  },
  {
    code: "7384",
    value: "C344",
    label: "CASTRONOVO DI SICILIA",
    province: "PA"
  },
  {
    code: "7385",
    value: "C420",
    label: "CEFALA DIANA",
    province: "PA"
  },
  {
    code: "7386",
    value: "C421",
    label: "CEFALU",
    province: "PA"
  },
  {
    code: "7387",
    value: "C496",
    label: "CERDA",
    province: "PA"
  },
  {
    code: "7388",
    value: "C654",
    label: "CHIUSA SCLAFANI",
    province: "PA"
  },
  {
    code: "7389",
    value: "C696",
    label: "CIMINNA",
    province: "PA"
  },
  {
    code: "7390",
    value: "C708",
    label: "CINISI",
    province: "PA"
  },
  {
    code: "7391",
    value: "C871",
    label: "COLLESANO",
    province: "PA"
  },
  {
    code: "7392",
    value: "C968",
    label: "CONTESSA ENTELLINA",
    province: "PA"
  },
  {
    code: "7393",
    value: "D009",
    label: "CORLEONE",
    province: "PA"
  },
  {
    code: "7394",
    value: "D567",
    label: "FICARAZZI",
    province: "PA"
  },
  {
    code: "7395",
    value: "D907",
    label: "GANGI",
    province: "PA"
  },
  {
    code: "7396",
    value: "D977",
    label: "GERACI SICULO",
    province: "PA"
  },
  {
    code: "7397",
    value: "E013",
    label: "GIARDINELLO",
    province: "PA"
  },
  {
    code: "7398",
    value: "E055",
    label: "GIULIANA",
    province: "PA"
  },
  {
    code: "7399",
    value: "E074",
    label: "GODRANO",
    province: "PA"
  },
  {
    code: "7400",
    value: "E149",
    label: "GRATTERI",
    province: "PA"
  },
  {
    code: "7401",
    value: "E337",
    label: "ISNELLO",
    province: "PA"
  },
  {
    code: "7402",
    value: "E350",
    label: "ISOLA DELLE FEMMINE",
    province: "PA"
  },
  {
    code: "7403",
    value: "E459",
    label: "LASCARI",
    province: "PA"
  },
  {
    code: "7404",
    value: "E541",
    label: "LERCARA FRIDDI",
    province: "PA"
  },
  {
    code: "7405",
    value: "E957",
    label: "MARINEO",
    province: "PA"
  },
  {
    code: "7406",
    value: "F184",
    label: "MEZZOJUSO",
    province: "PA"
  },
  {
    code: "7407",
    value: "F246",
    label: "MISILMERI",
    province: "PA"
  },
  {
    code: "7408",
    value: "F377",
    label: "MONREALE",
    province: "PA"
  },
  {
    code: "7409",
    value: "F544",
    label: "MONTELEPRE",
    province: "PA"
  },
  {
    code: "7410",
    value: "F553",
    label: "MONTEMAGGIORE BELSITO",
    province: "PA"
  },
  {
    code: "7411",
    value: "G263",
    label: "PALAZZO ADRIANO",
    province: "PA"
  },
  {
    code: "7412",
    value: "G273",
    label: "PALERMO",
    province: "PA"
  },
  {
    code: "7413",
    value: "G348",
    label: "PARTINICO",
    province: "PA"
  },
  {
    code: "7414",
    value: "G510",
    label: "PETRALIA SOPRANA",
    province: "PA"
  },
  {
    code: "7415",
    value: "G511",
    label: "PETRALIA SOTTANA",
    province: "PA"
  },
  {
    code: "7416",
    value: "G543",
    label: "PIANA DEGLI ALBANESI",
    province: "PA"
  },
  {
    code: "7417",
    value: "G792",
    label: "POLIZZI GENEROSA",
    province: "PA"
  },
  {
    code: "7418",
    value: "G797",
    label: "POLLINA",
    province: "PA"
  },
  {
    code: "7419",
    value: "H070",
    label: "PRIZZI",
    province: "PA"
  },
  {
    code: "7420",
    value: "H422",
    label: "ROCCAMENA",
    province: "PA"
  },
  {
    code: "7421",
    value: "H428",
    label: "ROCCAPALUMBA",
    province: "PA"
  },
  {
    code: "7422",
    value: "H797",
    label: "SAN CIPIRELLO",
    province: "PA"
  },
  {
    code: "7423",
    value: "H933",
    label: "SAN GIUSEPPE JATO",
    province: "PA"
  },
  {
    code: "7424",
    value: "I028",
    label: "SAN MAURO CASTELVERDE",
    province: "PA"
  },
  {
    code: "7425",
    value: "I174",
    label: "SANTA CRISTINA GELA",
    province: "PA"
  },
  {
    code: "7426",
    value: "I188",
    label: "SANTA FLAVIA",
    province: "PA"
  },
  {
    code: "7427",
    value: "I534",
    label: "SCIARA",
    province: "PA"
  },
  {
    code: "7428",
    value: "I541",
    label: "SCLAFANI BAGNI",
    province: "PA"
  },
  {
    code: "7429",
    value: "L112",
    label: "TERMINI IMERESE",
    province: "PA"
  },
  {
    code: "7430",
    value: "L131",
    label: "TERRASINI",
    province: "PA"
  },
  {
    code: "7431",
    value: "L282",
    label: "TORRETTA",
    province: "PA"
  },
  {
    code: "7432",
    value: "L317",
    label: "TRABIA",
    province: "PA"
  },
  {
    code: "7433",
    value: "L332",
    label: "TRAPPETO",
    province: "PA"
  },
  {
    code: "7434",
    value: "L519",
    label: "USTICA",
    province: "PA"
  },
  {
    code: "7435",
    value: "L603",
    label: "VALLEDOLMO",
    province: "PA"
  },
  {
    code: "7436",
    value: "L740",
    label: "VENTIMIGLIA DI SICILIA",
    province: "PA"
  },
  {
    code: "7437",
    value: "L837",
    label: "VICARI",
    province: "PA"
  },
  {
    code: "7438",
    value: "L916",
    label: "VILLABATE",
    province: "PA"
  },
  {
    code: "7439",
    value: "L951",
    label: "VILLAFRATI",
    province: "PA"
  },
  {
    code: "7440",
    value: "I538",
    label: "SCILLATO",
    province: "PA"
  },
  {
    code: "7441",
    value: "M268",
    label: "BLUFI",
    province: "PA"
  },
  {
    code: "7442",
    value: "A177",
    label: "ALCARA LI FUSI",
    province: "ME"
  },
  {
    code: "7443",
    value: "A194",
    label: "ALI",
    province: "ME"
  },
  {
    code: "7444",
    value: "A201",
    label: "ALI TERME",
    province: "ME"
  },
  {
    code: "7445",
    value: "A313",
    label: "ANTILLO",
    province: "ME"
  },
  {
    code: "7446",
    value: "A638",
    label: "BARCELLONA POZZO DI GOTTO",
    province: "ME"
  },
  {
    code: "7447",
    value: "A698",
    label: "BASICO",
    province: "ME"
  },
  {
    code: "7448",
    value: "B198",
    label: "BROLO",
    province: "ME"
  },
  {
    code: "7449",
    value: "B660",
    label: "CAPIZZI",
    province: "ME"
  },
  {
    code: "7450",
    value: "B666",
    label: "CAPO D ORLANDO",
    province: "ME"
  },
  {
    code: "7451",
    value: "B695",
    label: "CAPRI LEONE",
    province: "ME"
  },
  {
    code: "7452",
    value: "B804",
    label: "CARONIA",
    province: "ME"
  },
  {
    code: "7453",
    value: "B918",
    label: "CASALVECCHIO SICULO",
    province: "ME"
  },
  {
    code: "7454",
    value: "C094",
    label: "CASTEL DI LUCIO",
    province: "ME"
  },
  {
    code: "7455",
    value: "C051",
    label: "CASTELL UMBERTO",
    province: "ME"
  },
  {
    code: "7456",
    value: "C210",
    label: "CASTELMOLA",
    province: "ME"
  },
  {
    code: "7457",
    value: "C347",
    label: "CASTROREALE",
    province: "ME"
  },
  {
    code: "7458",
    value: "C568",
    label: "CESARO",
    province: "ME"
  },
  {
    code: "7459",
    value: "C956",
    label: "CONDRO",
    province: "ME"
  },
  {
    code: "7460",
    value: "D474",
    label: "FALCONE",
    province: "ME"
  },
  {
    code: "7461",
    value: "D569",
    label: "FICARRA",
    province: "ME"
  },
  {
    code: "7462",
    value: "D622",
    label: "FIUMEDINISI",
    province: "ME"
  },
  {
    code: "7463",
    value: "D635",
    label: "FLORESTA",
    province: "ME"
  },
  {
    code: "7464",
    value: "D661",
    label: "FONDACHELLI FANTINA",
    province: "ME"
  },
  {
    code: "7465",
    value: "D733",
    label: "FORZA D AGRO",
    province: "ME"
  },
  {
    code: "7466",
    value: "D765",
    label: "FRANCAVILLA DI SICILIA",
    province: "ME"
  },
  {
    code: "7467",
    value: "D793",
    label: "FRAZZANO",
    province: "ME"
  },
  {
    code: "7468",
    value: "D824",
    label: "FURCI SICULO",
    province: "ME"
  },
  {
    code: "7469",
    value: "D825",
    label: "FURNARI",
    province: "ME"
  },
  {
    code: "7470",
    value: "D844",
    label: "GAGGI",
    province: "ME"
  },
  {
    code: "7471",
    value: "D861",
    label: "GALATI MAMERTINO",
    province: "ME"
  },
  {
    code: "7472",
    value: "D885",
    label: "GALLODORO",
    province: "ME"
  },
  {
    code: "7473",
    value: "E014",
    label: "GIARDINI NAXOS",
    province: "ME"
  },
  {
    code: "7474",
    value: "E043",
    label: "GIOIOSA MAREA",
    province: "ME"
  },
  {
    code: "7475",
    value: "E142",
    label: "GRANITI",
    province: "ME"
  },
  {
    code: "7476",
    value: "E233",
    label: "GUALTIERI SICAMINO",
    province: "ME"
  },
  {
    code: "7477",
    value: "E374",
    label: "ITALA",
    province: "ME"
  },
  {
    code: "7478",
    value: "E523",
    label: "LENI",
    province: "ME"
  },
  {
    code: "7479",
    value: "E555",
    label: "LETOJANNI",
    province: "ME"
  },
  {
    code: "7480",
    value: "E571",
    label: "LIBRIZZI",
    province: "ME"
  },
  {
    code: "7481",
    value: "E594",
    label: "LIMINA",
    province: "ME"
  },
  {
    code: "7482",
    value: "E606",
    label: "LIPARI",
    province: "ME"
  },
  {
    code: "7483",
    value: "E674",
    label: "LONGI",
    province: "ME"
  },
  {
    code: "7484",
    value: "E855",
    label: "MALFA",
    province: "ME"
  },
  {
    code: "7485",
    value: "E869",
    label: "MALVAGNA",
    province: "ME"
  },
  {
    code: "7486",
    value: "E876",
    label: "MANDANICI",
    province: "ME"
  },
  {
    code: "7487",
    value: "F066",
    label: "MAZZARRA SANT ANDREA",
    province: "ME"
  },
  {
    code: "7488",
    value: "F147",
    label: "MERI",
    province: "ME"
  },
  {
    code: "7489",
    value: "F158",
    label: "MESSINA",
    province: "ME"
  },
  {
    code: "7490",
    value: "F206",
    label: "MILAZZO",
    province: "ME"
  },
  {
    code: "7491",
    value: "F210",
    label: "MILITELLO ROSMARINO",
    province: "ME"
  },
  {
    code: "7492",
    value: "F242",
    label: "MIRTO",
    province: "ME"
  },
  {
    code: "7493",
    value: "F251",
    label: "MISTRETTA",
    province: "ME"
  },
  {
    code: "7494",
    value: "F277",
    label: "MOIO ALCANTARA",
    province: "ME"
  },
  {
    code: "7495",
    value: "F359",
    label: "MONFORTE SAN GIORGIO",
    province: "ME"
  },
  {
    code: "7496",
    value: "F368",
    label: "MONGIUFFI MELIA",
    province: "ME"
  },
  {
    code: "7497",
    value: "F395",
    label: "MONTAGNAREALE",
    province: "ME"
  },
  {
    code: "7498",
    value: "F400",
    label: "MONTALBANO ELICONA",
    province: "ME"
  },
  {
    code: "7499",
    value: "F772",
    label: "MOTTA CAMASTRA",
    province: "ME"
  },
  {
    code: "7500",
    value: "F773",
    label: "MOTTA D AFFERMO",
    province: "ME"
  },
  {
    code: "7501",
    value: "F848",
    label: "NASO",
    province: "ME"
  },
  {
    code: "7502",
    value: "F901",
    label: "NIZZA DI SICILIA",
    province: "ME"
  },
  {
    code: "7503",
    value: "F951",
    label: "NOVARA DI SICILIA",
    province: "ME"
  },
  {
    code: "7504",
    value: "G036",
    label: "OLIVERI",
    province: "ME"
  },
  {
    code: "7505",
    value: "G209",
    label: "PACE DEL MELA",
    province: "ME"
  },
  {
    code: "7506",
    value: "G234",
    label: "PAGLIARA",
    province: "ME"
  },
  {
    code: "7507",
    value: "G377",
    label: "PATTI",
    province: "ME"
  },
  {
    code: "7508",
    value: "G522",
    label: "PETTINEO",
    province: "ME"
  },
  {
    code: "7509",
    value: "G699",
    label: "PIRAINO",
    province: "ME"
  },
  {
    code: "7510",
    value: "H151",
    label: "RACCUJA",
    province: "ME"
  },
  {
    code: "7511",
    value: "H228",
    label: "REITANO",
    province: "ME"
  },
  {
    code: "7512",
    value: "H405",
    label: "ROCCAFIORITA",
    province: "ME"
  },
  {
    code: "7513",
    value: "H418",
    label: "ROCCALUMERA",
    province: "ME"
  },
  {
    code: "7514",
    value: "H380",
    label: "ROCCAVALDINA",
    province: "ME"
  },
  {
    code: "7515",
    value: "H455",
    label: "ROCCELLA VALDEMONE",
    province: "ME"
  },
  {
    code: "7516",
    value: "H479",
    label: "RODI MILICI",
    province: "ME"
  },
  {
    code: "7517",
    value: "H519",
    label: "ROMETTA",
    province: "ME"
  },
  {
    code: "7518",
    value: "H842",
    label: "SAN FILIPPO DEL MELA",
    province: "ME"
  },
  {
    code: "7519",
    value: "H850",
    label: "SAN FRATELLO",
    province: "ME"
  },
  {
    code: "7520",
    value: "H982",
    label: "SAN MARCO D ALUNZIO",
    province: "ME"
  },
  {
    code: "7521",
    value: "I084",
    label: "SAN PIER NICETO",
    province: "ME"
  },
  {
    code: "7522",
    value: "I086",
    label: "SAN PIERO PATTI",
    province: "ME"
  },
  {
    code: "7523",
    value: "I147",
    label: "SAN SALVATORE DI FITALIA",
    province: "ME"
  },
  {
    code: "7524",
    value: "I184",
    label: "SANTA DOMENICA VITTORIA",
    province: "ME"
  },
  {
    code: "7525",
    value: "I199",
    label: "SANT AGATA DI MILITELLO",
    province: "ME"
  },
  {
    code: "7526",
    value: "I215",
    label: "SANT ALESSIO SICULO",
    province: "ME"
  },
  {
    code: "7527",
    value: "I220",
    label: "SANTA LUCIA DEL MELA",
    province: "ME"
  },
  {
    code: "7528",
    value: "I254",
    label: "SANTA MARINA SALINA",
    province: "ME"
  },
  {
    code: "7529",
    value: "I283",
    label: "SANT ANGELO DI BROLO",
    province: "ME"
  },
  {
    code: "7530",
    value: "I311",
    label: "SANTA TERESA DI RIVA",
    province: "ME"
  },
  {
    code: "7531",
    value: "I328",
    label: "SAN TEODORO",
    province: "ME"
  },
  {
    code: "7532",
    value: "I370",
    label: "SANTO STEFANO DI CAMASTRA",
    province: "ME"
  },
  {
    code: "7533",
    value: "I420",
    label: "SAPONARA",
    province: "ME"
  },
  {
    code: "7534",
    value: "I477",
    label: "SAVOCA",
    province: "ME"
  },
  {
    code: "7535",
    value: "I492",
    label: "SCALETTA ZANCLEA",
    province: "ME"
  },
  {
    code: "7536",
    value: "I747",
    label: "SINAGRA",
    province: "ME"
  },
  {
    code: "7537",
    value: "I881",
    label: "SPADAFORA",
    province: "ME"
  },
  {
    code: "7538",
    value: "L042",
    label: "TAORMINA",
    province: "ME"
  },
  {
    code: "7539",
    value: "L271",
    label: "TORREGROTTA",
    province: "ME"
  },
  {
    code: "7540",
    value: "L308",
    label: "TORTORICI",
    province: "ME"
  },
  {
    code: "7541",
    value: "L431",
    label: "TRIPI",
    province: "ME"
  },
  {
    code: "7542",
    value: "L478",
    label: "TUSA",
    province: "ME"
  },
  {
    code: "7543",
    value: "L482",
    label: "UCRIA",
    province: "ME"
  },
  {
    code: "7544",
    value: "L561",
    label: "VALDINA",
    province: "ME"
  },
  {
    code: "7545",
    value: "L735",
    label: "VENETICO",
    province: "ME"
  },
  {
    code: "7546",
    value: "L950",
    label: "VILLAFRANCA TIRRENA",
    province: "ME"
  },
  {
    code: "7547",
    value: "M210",
    label: "TERME VIGLIATORE",
    province: "ME"
  },
  {
    code: "7548",
    value: "M211",
    label: "ACQUEDOLCI",
    province: "ME"
  },
  {
    code: "7549",
    value: "M286",
    label: "TORRENOVA",
    province: "ME"
  },
  {
    code: "7550",
    value: "A089",
    label: "AGRIGENTO",
    province: "AG"
  },
  {
    code: "7551",
    value: "A181",
    label: "ALESSANDRIA DELLA ROCCA",
    province: "AG"
  },
  {
    code: "7552",
    value: "A351",
    label: "ARAGONA",
    province: "AG"
  },
  {
    code: "7553",
    value: "A896",
    label: "BIVONA",
    province: "AG"
  },
  {
    code: "7554",
    value: "B275",
    label: "BURGIO",
    province: "AG"
  },
  {
    code: "7555",
    value: "B377",
    label: "CALAMONACI",
    province: "AG"
  },
  {
    code: "7556",
    value: "B427",
    label: "CALTABELLOTTA",
    province: "AG"
  },
  {
    code: "7557",
    value: "B460",
    label: "CAMASTRA",
    province: "AG"
  },
  {
    code: "7558",
    value: "B486",
    label: "CAMMARATA",
    province: "AG"
  },
  {
    code: "7559",
    value: "B520",
    label: "CAMPOBELLO DI LICATA",
    province: "AG"
  },
  {
    code: "7560",
    value: "B602",
    label: "CANICATTI",
    province: "AG"
  },
  {
    code: "7561",
    value: "C275",
    label: "CASTELTERMINI",
    province: "AG"
  },
  {
    code: "7562",
    value: "C341",
    label: "CASTROFILIPPO",
    province: "AG"
  },
  {
    code: "7563",
    value: "C356",
    label: "CATTOLICA ERACLEA",
    province: "AG"
  },
  {
    code: "7564",
    value: "C668",
    label: "CIANCIANA",
    province: "AG"
  },
  {
    code: "7565",
    value: "C928",
    label: "COMITINI",
    province: "AG"
  },
  {
    code: "7566",
    value: "D514",
    label: "FAVARA",
    province: "AG"
  },
  {
    code: "7567",
    value: "E209",
    label: "GROTTE",
    province: "AG"
  },
  {
    code: "7568",
    value: "E390",
    label: "JOPPOLO GIANCAXIO",
    province: "AG"
  },
  {
    code: "7569",
    value: "E431",
    label: "LAMPEDUSA E LINOSA",
    province: "AG"
  },
  {
    code: "7570",
    value: "E573",
    label: "LICATA",
    province: "AG"
  },
  {
    code: "7571",
    value: "E714",
    label: "LUCCA SICULA",
    province: "AG"
  },
  {
    code: "7572",
    value: "F126",
    label: "MENFI",
    province: "AG"
  },
  {
    code: "7573",
    value: "F414",
    label: "MONTALLEGRO",
    province: "AG"
  },
  {
    code: "7574",
    value: "F655",
    label: "MONTEVAGO",
    province: "AG"
  },
  {
    code: "7575",
    value: "F845",
    label: "NARO",
    province: "AG"
  },
  {
    code: "7576",
    value: "G282",
    label: "PALMA DI MONTECHIARO",
    province: "AG"
  },
  {
    code: "7577",
    value: "F299",
    label: "PORTO EMPEDOCLE",
    province: "AG"
  },
  {
    code: "7578",
    value: "H148",
    label: "RACALMUTO",
    province: "AG"
  },
  {
    code: "7579",
    value: "H159",
    label: "RAFFADALI",
    province: "AG"
  },
  {
    code: "7580",
    value: "H194",
    label: "RAVANUSA",
    province: "AG"
  },
  {
    code: "7581",
    value: "H205",
    label: "REALMONTE",
    province: "AG"
  },
  {
    code: "7582",
    value: "H269",
    label: "RIBERA",
    province: "AG"
  },
  {
    code: "7583",
    value: "H743",
    label: "SAMBUCA DI SICILIA",
    province: "AG"
  },
  {
    code: "7584",
    value: "H778",
    label: "SAN BIAGIO PLATANI",
    province: "AG"
  },
  {
    code: "7585",
    value: "H914",
    label: "SAN GIOVANNI GEMINI",
    province: "AG"
  },
  {
    code: "7586",
    value: "I185",
    label: "SANTA ELISABETTA",
    province: "AG"
  },
  {
    code: "7587",
    value: "I224",
    label: "SANTA MARGHERITA DI BELICE",
    province: "AG"
  },
  {
    code: "7588",
    value: "I290",
    label: "SANT ANGELO MUXARO",
    province: "AG"
  },
  {
    code: "7589",
    value: "I356",
    label: "SANTO STEFANO QUISQUINA",
    province: "AG"
  },
  {
    code: "7590",
    value: "I533",
    label: "SCIACCA",
    province: "AG"
  },
  {
    code: "7591",
    value: "I723",
    label: "SICULIANA",
    province: "AG"
  },
  {
    code: "7592",
    value: "L944",
    label: "VILLAFRANCA SICULA",
    province: "AG"
  },
  {
    code: "7593",
    value: "A049",
    label: "ACQUAVIVA PLATANI",
    province: "CL"
  },
  {
    code: "7594",
    value: "A957",
    label: "BOMPENSIERE",
    province: "CL"
  },
  {
    code: "7595",
    value: "B302",
    label: "BUTERA",
    province: "CL"
  },
  {
    code: "7596",
    value: "B429",
    label: "CALTANISSETTA",
    province: "CL"
  },
  {
    code: "7597",
    value: "B537",
    label: "CAMPOFRANCO",
    province: "CL"
  },
  {
    code: "7598",
    value: "D267",
    label: "DELIA",
    province: "CL"
  },
  {
    code: "7599",
    value: "D960",
    label: "GELA",
    province: "CL"
  },
  {
    code: "7600",
    value: "E953",
    label: "MARIANOPOLI",
    province: "CL"
  },
  {
    code: "7601",
    value: "F065",
    label: "MAZZARINO",
    province: "CL"
  },
  {
    code: "7602",
    value: "E618",
    label: "MILENA",
    province: "CL"
  },
  {
    code: "7603",
    value: "F489",
    label: "MONTEDORO",
    province: "CL"
  },
  {
    code: "7604",
    value: "F830",
    label: "MUSSOMELI",
    province: "CL"
  },
  {
    code: "7605",
    value: "F899",
    label: "NISCEMI",
    province: "CL"
  },
  {
    code: "7606",
    value: "H245",
    label: "RESUTTANO",
    province: "CL"
  },
  {
    code: "7607",
    value: "H281",
    label: "RIESI",
    province: "CL"
  },
  {
    code: "7608",
    value: "H792",
    label: "SAN CATALDO",
    province: "CL"
  },
  {
    code: "7609",
    value: "I169",
    label: "SANTA CATERINA VILLARMOSA",
    province: "CL"
  },
  {
    code: "7610",
    value: "I644",
    label: "SERRADIFALCO",
    province: "CL"
  },
  {
    code: "7611",
    value: "I824",
    label: "SOMMATINO",
    province: "CL"
  },
  {
    code: "7612",
    value: "L016",
    label: "SUTERA",
    province: "CL"
  },
  {
    code: "7613",
    value: "L609",
    label: "VALLELUNGA PRATAMENO",
    province: "CL"
  },
  {
    code: "7614",
    value: "L959",
    label: "VILLALBA",
    province: "CL"
  },
  {
    code: "7615",
    value: "A070",
    label: "AGIRA",
    province: "EN"
  },
  {
    code: "7616",
    value: "A098",
    label: "AIDONE",
    province: "EN"
  },
  {
    code: "7617",
    value: "A478",
    label: "ASSORO",
    province: "EN"
  },
  {
    code: "7618",
    value: "A676",
    label: "BARRAFRANCA",
    province: "EN"
  },
  {
    code: "7619",
    value: "B381",
    label: "CALASCIBETTA",
    province: "EN"
  },
  {
    code: "7620",
    value: "C353",
    label: "CATENANUOVA",
    province: "EN"
  },
  {
    code: "7621",
    value: "C471",
    label: "CENTURIPE",
    province: "EN"
  },
  {
    code: "7622",
    value: "C480",
    label: "CERAMI",
    province: "EN"
  },
  {
    code: "7623",
    value: "C342",
    label: "ENNA",
    province: "EN"
  },
  {
    code: "7624",
    value: "D849",
    label: "GAGLIANO CASTELFERRATO",
    province: "EN"
  },
  {
    code: "7625",
    value: "E536",
    label: "LEONFORTE",
    province: "EN"
  },
  {
    code: "7626",
    value: "F892",
    label: "NICOSIA",
    province: "EN"
  },
  {
    code: "7627",
    value: "F900",
    label: "NISSORIA",
    province: "EN"
  },
  {
    code: "7628",
    value: "G580",
    label: "PIAZZA ARMERINA",
    province: "EN"
  },
  {
    code: "7629",
    value: "G624",
    label: "PIETRAPERZIA",
    province: "EN"
  },
  {
    code: "7630",
    value: "H221",
    label: "REGALBUTO",
    province: "EN"
  },
  {
    code: "7631",
    value: "I891",
    label: "SPERLINGA",
    province: "EN"
  },
  {
    code: "7632",
    value: "L448",
    label: "TROINA",
    province: "EN"
  },
  {
    code: "7633",
    value: "L583",
    label: "VALGUARNERA CAROPEPE",
    province: "EN"
  },
  {
    code: "7634",
    value: "M011",
    label: "VILLAROSA",
    province: "EN"
  },
  {
    code: "7635",
    value: "A025",
    label: "ACI BONACCORSI",
    province: "CT"
  },
  {
    code: "7636",
    value: "A026",
    label: "ACI CASTELLO",
    province: "CT"
  },
  {
    code: "7637",
    value: "A027",
    label: "ACI CATENA",
    province: "CT"
  },
  {
    code: "7638",
    value: "A028",
    label: "ACIREALE",
    province: "CT"
  },
  {
    code: "7639",
    value: "A029",
    label: "ACI SANT ANTONIO",
    province: "CT"
  },
  {
    code: "7640",
    value: "A056",
    label: "ADRANO",
    province: "CT"
  },
  {
    code: "7641",
    value: "A766",
    label: "BELPASSO",
    province: "CT"
  },
  {
    code: "7642",
    value: "A841",
    label: "BIANCAVILLA",
    province: "CT"
  },
  {
    code: "7643",
    value: "B202",
    label: "BRONTE",
    province: "CT"
  },
  {
    code: "7644",
    value: "B384",
    label: "CALATABIANO",
    province: "CT"
  },
  {
    code: "7645",
    value: "B428",
    label: "CALTAGIRONE",
    province: "CT"
  },
  {
    code: "7646",
    value: "B561",
    label: "CAMPOROTONDO ETNEO",
    province: "CT"
  },
  {
    code: "7647",
    value: "C091",
    label: "CASTEL DI IUDICA",
    province: "CT"
  },
  {
    code: "7648",
    value: "C297",
    label: "CASTIGLIONE DI SICILIA",
    province: "CT"
  },
  {
    code: "7649",
    value: "C351",
    label: "CATANIA",
    province: "CT"
  },
  {
    code: "7650",
    value: "D623",
    label: "FIUMEFREDDO DI SICILIA",
    province: "CT"
  },
  {
    code: "7651",
    value: "E017",
    label: "GIARRE",
    province: "CT"
  },
  {
    code: "7652",
    value: "E133",
    label: "GRAMMICHELE",
    province: "CT"
  },
  {
    code: "7653",
    value: "E156",
    label: "GRAVINA DI CATANIA",
    province: "CT"
  },
  {
    code: "7654",
    value: "E578",
    label: "LICODIA EUBEA",
    province: "CT"
  },
  {
    code: "7655",
    value: "E602",
    label: "LINGUAGLOSSA",
    province: "CT"
  },
  {
    code: "7656",
    value: "E854",
    label: "MALETTO",
    province: "CT"
  },
  {
    code: "7657",
    value: "F004",
    label: "MASCALI",
    province: "CT"
  },
  {
    code: "7658",
    value: "F005",
    label: "MASCALUCIA",
    province: "CT"
  },
  {
    code: "7659",
    value: "F209",
    label: "MILITELLO IN VAL DI CATANIA",
    province: "CT"
  },
  {
    code: "7660",
    value: "F214",
    label: "MILO",
    province: "CT"
  },
  {
    code: "7661",
    value: "F217",
    label: "MINEO",
    province: "CT"
  },
  {
    code: "7662",
    value: "F231",
    label: "MIRABELLA IMBACCARI",
    province: "CT"
  },
  {
    code: "7663",
    value: "F250",
    label: "MISTERBIANCO",
    province: "CT"
  },
  {
    code: "7664",
    value: "F781",
    label: "MOTTA SANT ANASTASIA",
    province: "CT"
  },
  {
    code: "7665",
    value: "F890",
    label: "NICOLOSI",
    province: "CT"
  },
  {
    code: "7666",
    value: "G253",
    label: "PALAGONIA",
    province: "CT"
  },
  {
    code: "7667",
    value: "G371",
    label: "PATERNO",
    province: "CT"
  },
  {
    code: "7668",
    value: "G402",
    label: "PEDARA",
    province: "CT"
  },
  {
    code: "7669",
    value: "G597",
    label: "PIEDIMONTE ETNEO",
    province: "CT"
  },
  {
    code: "7670",
    value: "H154",
    label: "RADDUSA",
    province: "CT"
  },
  {
    code: "7671",
    value: "H168",
    label: "RAMACCA",
    province: "CT"
  },
  {
    code: "7672",
    value: "H175",
    label: "RANDAZZO",
    province: "CT"
  },
  {
    code: "7673",
    value: "H325",
    label: "RIPOSTO",
    province: "CT"
  },
  {
    code: "7674",
    value: "H805",
    label: "SAN CONO",
    province: "CT"
  },
  {
    code: "7675",
    value: "H922",
    label: "SAN GIOVANNI LA PUNTA",
    province: "CT"
  },
  {
    code: "7676",
    value: "H940",
    label: "SAN GREGORIO DI CATANIA",
    province: "CT"
  },
  {
    code: "7677",
    value: "I035",
    label: "SAN MICHELE DI GANZARIA",
    province: "CT"
  },
  {
    code: "7678",
    value: "I098",
    label: "SAN PIETRO CLARENZA",
    province: "CT"
  },
  {
    code: "7679",
    value: "I202",
    label: "SANT AGATA LI BATTIATI",
    province: "CT"
  },
  {
    code: "7680",
    value: "I216",
    label: "SANT ALFIO",
    province: "CT"
  },
  {
    code: "7681",
    value: "I240",
    label: "SANTA MARIA DI LICODIA",
    province: "CT"
  },
  {
    code: "7682",
    value: "I314",
    label: "SANTA VENERINA",
    province: "CT"
  },
  {
    code: "7683",
    value: "I548",
    label: "SCORDIA",
    province: "CT"
  },
  {
    code: "7684",
    value: "L355",
    label: "TRECASTAGNI",
    province: "CT"
  },
  {
    code: "7685",
    value: "L369",
    label: "TREMESTIERI ETNEO",
    province: "CT"
  },
  {
    code: "7686",
    value: "L658",
    label: "VALVERDE",
    province: "CT"
  },
  {
    code: "7687",
    value: "L828",
    label: "VIAGRANDE",
    province: "CT"
  },
  {
    code: "7688",
    value: "M100",
    label: "VIZZINI",
    province: "CT"
  },
  {
    code: "7689",
    value: "M139",
    label: "ZAFFERANA ETNEA",
    province: "CT"
  },
  {
    code: "7690",
    value: "M271",
    label: "MAZZARRONE",
    province: "CT"
  },
  {
    code: "7691",
    value: "M283",
    label: "MANIACE",
    province: "CT"
  },
  {
    code: "7692",
    value: "M287",
    label: "RAGALNA",
    province: "CT"
  },
  {
    code: "7693",
    value: "A014",
    label: "ACATE",
    province: "RG"
  },
  {
    code: "7694",
    value: "C612",
    label: "CHIARAMONTE GULFI",
    province: "RG"
  },
  {
    code: "7695",
    value: "C927",
    label: "COMISO",
    province: "RG"
  },
  {
    code: "7696",
    value: "E016",
    label: "GIARRATANA",
    province: "RG"
  },
  {
    code: "7697",
    value: "E366",
    label: "ISPICA",
    province: "RG"
  },
  {
    code: "7698",
    value: "F258",
    label: "MODICA",
    province: "RG"
  },
  {
    code: "7699",
    value: "F610",
    label: "MONTEROSSO ALMO",
    province: "RG"
  },
  {
    code: "7700",
    value: "G953",
    label: "POZZALLO",
    province: "RG"
  },
  {
    code: "7701",
    value: "H163",
    label: "RAGUSA",
    province: "RG"
  },
  {
    code: "7702",
    value: "I178",
    label: "SANTA CROCE CAMERINA",
    province: "RG"
  },
  {
    code: "7703",
    value: "I535",
    label: "SCICLI",
    province: "RG"
  },
  {
    code: "7704",
    value: "M088",
    label: "VITTORIA",
    province: "RG"
  },
  {
    code: "7705",
    value: "A494",
    label: "AUGUSTA",
    province: "SR"
  },
  {
    code: "7706",
    value: "A522",
    label: "AVOLA",
    province: "SR"
  },
  {
    code: "7707",
    value: "B237",
    label: "BUCCHERI",
    province: "SR"
  },
  {
    code: "7708",
    value: "B287",
    label: "BUSCEMI",
    province: "SR"
  },
  {
    code: "7709",
    value: "B603",
    label: "CANICATTINI BAGNI",
    province: "SR"
  },
  {
    code: "7710",
    value: "B787",
    label: "CARLENTINI",
    province: "SR"
  },
  {
    code: "7711",
    value: "C006",
    label: "CASSARO",
    province: "SR"
  },
  {
    code: "7712",
    value: "D540",
    label: "FERLA",
    province: "SR"
  },
  {
    code: "7713",
    value: "D636",
    label: "FLORIDIA",
    province: "SR"
  },
  {
    code: "7714",
    value: "D768",
    label: "FRANCOFONTE",
    province: "SR"
  },
  {
    code: "7715",
    value: "E532",
    label: "LENTINI",
    province: "SR"
  },
  {
    code: "7716",
    value: "F107",
    label: "MELILLI",
    province: "SR"
  },
  {
    code: "7717",
    value: "F943",
    label: "NOTO",
    province: "SR"
  },
  {
    code: "7718",
    value: "G211",
    label: "PACHINO",
    province: "SR"
  },
  {
    code: "7719",
    value: "G267",
    label: "PALAZZOLO ACREIDE",
    province: "SR"
  },
  {
    code: "7720",
    value: "H574",
    label: "ROSOLINI",
    province: "SR"
  },
  {
    code: "7721",
    value: "I754",
    label: "SIRACUSA",
    province: "SR"
  },
  {
    code: "7722",
    value: "I785",
    label: "SOLARINO",
    province: "SR"
  },
  {
    code: "7723",
    value: "I864",
    label: "SORTINO",
    province: "SR"
  },
  {
    code: "7724",
    value: "M257",
    label: "PORTOPALO DI CAPO PASSERO",
    province: "SR"
  },
  {
    code: "7725",
    value: "M279",
    label: "PRIOLO GARGALLO",
    province: "SR"
  },
  {
    code: "7726",
    value: "A069",
    label: "AGGIUS",
    province: "SS"
  },
  {
    code: "7727",
    value: "A115",
    label: "ALA DEI SARDI",
    province: "SS"
  },
  {
    code: "7728",
    value: "A192",
    label: "ALGHERO",
    province: "SS"
  },
  {
    code: "7729",
    value: "A287",
    label: "ANELA",
    province: "SS"
  },
  {
    code: "7730",
    value: "A379",
    label: "ARDARA",
    province: "SS"
  },
  {
    code: "7731",
    value: "A453",
    label: "ARZACHENA",
    province: "SS"
  },
  {
    code: "7732",
    value: "A606",
    label: "BANARI",
    province: "SS"
  },
  {
    code: "7733",
    value: "A781",
    label: "BENETUTTI",
    province: "SS"
  },
  {
    code: "7734",
    value: "A789",
    label: "BERCHIDDA",
    province: "SS"
  },
  {
    code: "7735",
    value: "A827",
    label: "BESSUDE",
    province: "SS"
  },
  {
    code: "7736",
    value: "A976",
    label: "BONNANARO",
    province: "SS"
  },
  {
    code: "7737",
    value: "A977",
    label: "BONO",
    province: "SS"
  },
  {
    code: "7738",
    value: "A978",
    label: "BONORVA",
    province: "SS"
  },
  {
    code: "7739",
    value: "B063",
    label: "BORTIGIADAS",
    province: "SS"
  },
  {
    code: "7740",
    value: "B064",
    label: "BORUTTA",
    province: "SS"
  },
  {
    code: "7741",
    value: "B094",
    label: "BOTTIDDA",
    province: "SS"
  },
  {
    code: "7742",
    value: "B246",
    label: "BUDDUSO",
    province: "SS"
  },
  {
    code: "7743",
    value: "B264",
    label: "BULTEI",
    province: "SS"
  },
  {
    code: "7744",
    value: "B265",
    label: "BULZI",
    province: "SS"
  },
  {
    code: "7745",
    value: "B276",
    label: "BURGOS",
    province: "SS"
  },
  {
    code: "7746",
    value: "B378",
    label: "CALANGIANUS",
    province: "SS"
  },
  {
    code: "7747",
    value: "B772",
    label: "CARGEGHE",
    province: "SS"
  },
  {
    code: "7748",
    value: "C272",
    label: "CASTELSARDO",
    province: "SS"
  },
  {
    code: "7749",
    value: "C600",
    label: "CHEREMULE",
    province: "SS"
  },
  {
    code: "7750",
    value: "C613",
    label: "CHIARAMONTI",
    province: "SS"
  },
  {
    code: "7751",
    value: "C818",
    label: "CODRONGIANOS",
    province: "SS"
  },
  {
    code: "7752",
    value: "D100",
    label: "COSSOINE",
    province: "SS"
  },
  {
    code: "7753",
    value: "D441",
    label: "ESPORLATU",
    province: "SS"
  },
  {
    code: "7754",
    value: "D637",
    label: "FLORINAS",
    province: "SS"
  },
  {
    code: "7755",
    value: "E019",
    label: "GIAVE",
    province: "SS"
  },
  {
    code: "7756",
    value: "E285",
    label: "ILLORAI",
    province: "SS"
  },
  {
    code: "7757",
    value: "E376",
    label: "ITTIREDDU",
    province: "SS"
  },
  {
    code: "7758",
    value: "E377",
    label: "ITTIRI",
    province: "SS"
  },
  {
    code: "7759",
    value: "E401",
    label: "LAERRU",
    province: "SS"
  },
  {
    code: "7760",
    value: "E425",
    label: "LA MADDALENA",
    province: "SS"
  },
  {
    code: "7761",
    value: "E747",
    label: "LUOGOSANTO",
    province: "SS"
  },
  {
    code: "7762",
    value: "E752",
    label: "LURAS",
    province: "SS"
  },
  {
    code: "7763",
    value: "E902",
    label: "MARA",
    province: "SS"
  },
  {
    code: "7764",
    value: "E992",
    label: "MARTIS",
    province: "SS"
  },
  {
    code: "7765",
    value: "F542",
    label: "MONTELEONE ROCCA DORIA",
    province: "SS"
  },
  {
    code: "7766",
    value: "F667",
    label: "MONTI",
    province: "SS"
  },
  {
    code: "7767",
    value: "F721",
    label: "MORES",
    province: "SS"
  },
  {
    code: "7768",
    value: "F818",
    label: "MUROS",
    province: "SS"
  },
  {
    code: "7769",
    value: "F975",
    label: "NUGHEDU SAN NICOLO",
    province: "SS"
  },
  {
    code: "7770",
    value: "F976",
    label: "NULE",
    province: "SS"
  },
  {
    code: "7771",
    value: "F977",
    label: "NULVI",
    province: "SS"
  },
  {
    code: "7772",
    value: "G015",
    label: "OLBIA",
    province: "SS"
  },
  {
    code: "7773",
    value: "G046",
    label: "OLMEDO",
    province: "SS"
  },
  {
    code: "7774",
    value: "G153",
    label: "OSCHIRI",
    province: "SS"
  },
  {
    code: "7775",
    value: "G156",
    label: "OSILO",
    province: "SS"
  },
  {
    code: "7776",
    value: "G178",
    label: "OSSI",
    province: "SS"
  },
  {
    code: "7777",
    value: "G203",
    label: "OZIERI",
    province: "SS"
  },
  {
    code: "7778",
    value: "G225",
    label: "PADRIA",
    province: "SS"
  },
  {
    code: "7779",
    value: "G258",
    label: "PALAU",
    province: "SS"
  },
  {
    code: "7780",
    value: "G376",
    label: "PATTADA",
    province: "SS"
  },
  {
    code: "7781",
    value: "G450",
    label: "PERFUGAS",
    province: "SS"
  },
  {
    code: "7782",
    value: "G740",
    label: "PLOAGHE",
    province: "SS"
  },
  {
    code: "7783",
    value: "G924",
    label: "PORTO TORRES",
    province: "SS"
  },
  {
    code: "7784",
    value: "G962",
    label: "POZZOMAGGIORE",
    province: "SS"
  },
  {
    code: "7785",
    value: "H095",
    label: "PUTIFIGARI",
    province: "SS"
  },
  {
    code: "7786",
    value: "H507",
    label: "ROMANA",
    province: "SS"
  },
  {
    code: "7787",
    value: "H848",
    label: "AGLIENTU",
    province: "SS"
  },
  {
    code: "7788",
    value: "I312",
    label: "SANTA TERESA GALLURA",
    province: "SS"
  },
  {
    code: "7789",
    value: "I452",
    label: "SASSARI",
    province: "SS"
  },
  {
    code: "7790",
    value: "I565",
    label: "SEDINI",
    province: "SS"
  },
  {
    code: "7791",
    value: "I598",
    label: "SEMESTENE",
    province: "SS"
  },
  {
    code: "7792",
    value: "I614",
    label: "SENNORI",
    province: "SS"
  },
  {
    code: "7793",
    value: "I732",
    label: "SILIGO",
    province: "SS"
  },
  {
    code: "7794",
    value: "I863",
    label: "SORSO",
    province: "SS"
  },
  {
    code: "7795",
    value: "L093",
    label: "TEMPIO PAUSANIA",
    province: "SS"
  },
  {
    code: "7796",
    value: "L158",
    label: "THIESI",
    province: "SS"
  },
  {
    code: "7797",
    value: "L180",
    label: "TISSI",
    province: "SS"
  },
  {
    code: "7798",
    value: "L235",
    label: "TORRALBA",
    province: "SS"
  },
  {
    code: "7799",
    value: "L428",
    label: "TRINITA D AGULTU E VIGNOLA",
    province: "SS"
  },
  {
    code: "7800",
    value: "L464",
    label: "TULA",
    province: "SS"
  },
  {
    code: "7801",
    value: "L503",
    label: "URI",
    province: "SS"
  },
  {
    code: "7802",
    value: "L509",
    label: "USINI",
    province: "SS"
  },
  {
    code: "7803",
    value: "L989",
    label: "VILLANOVA MONTELEONE",
    province: "SS"
  },
  {
    code: "7804",
    value: "L604",
    label: "VALLEDORIA",
    province: "SS"
  },
  {
    code: "7805",
    value: "L088",
    label: "TELTI",
    province: "SS"
  },
  {
    code: "7806",
    value: "M214",
    label: "BADESI",
    province: "SS"
  },
  {
    code: "7807",
    value: "M259",
    label: "VIDDALBA",
    province: "SS"
  },
  {
    code: "7808",
    value: "M274",
    label: "GOLFO ARANCI",
    province: "SS"
  },
  {
    code: "7809",
    value: "M275",
    label: "LOIRI PORTO SAN PAOLO",
    province: "SS"
  },
  {
    code: "7810",
    value: "M276",
    label: "SANT ANTONIO DI GALLURA",
    province: "SS"
  },
  {
    code: "7811",
    value: "M282",
    label: "TERGU",
    province: "SS"
  },
  {
    code: "7812",
    value: "M284",
    label: "SANTA MARIA COGHINAS",
    province: "SS"
  },
  {
    code: "7813",
    value: "M292",
    label: "ERULA",
    province: "SS"
  },
  {
    code: "7814",
    value: "M290",
    label: "STINTINO",
    province: "SS"
  },
  {
    code: "7815",
    value: "M301",
    label: "PADRU",
    province: "SS"
  },
  {
    code: "7816",
    value: "A407",
    label: "ARITZO",
    province: "NU"
  },
  {
    code: "7817",
    value: "A454",
    label: "ARZANA",
    province: "NU"
  },
  {
    code: "7818",
    value: "A492",
    label: "ATZARA",
    province: "NU"
  },
  {
    code: "7819",
    value: "A503",
    label: "AUSTIS",
    province: "NU"
  },
  {
    code: "7820",
    value: "A663",
    label: "BARI SARDO",
    province: "NU"
  },
  {
    code: "7821",
    value: "A722",
    label: "BAUNEI",
    province: "NU"
  },
  {
    code: "7822",
    value: "A776",
    label: "BELVI",
    province: "NU"
  },
  {
    code: "7823",
    value: "A880",
    label: "BIRORI",
    province: "NU"
  },
  {
    code: "7824",
    value: "A895",
    label: "BITTI",
    province: "NU"
  },
  {
    code: "7825",
    value: "A948",
    label: "BOLOTANA",
    province: "NU"
  },
  {
    code: "7826",
    value: "B056",
    label: "BORORE",
    province: "NU"
  },
  {
    code: "7827",
    value: "B062",
    label: "BORTIGALI",
    province: "NU"
  },
  {
    code: "7828",
    value: "B068",
    label: "BOSA",
    province: "OR"
  },
  {
    code: "7829",
    value: "B248",
    label: "BUDONI",
    province: "SS"
  },
  {
    code: "7830",
    value: "D287",
    label: "DESULO",
    province: "NU"
  },
  {
    code: "7831",
    value: "D345",
    label: "DORGALI",
    province: "NU"
  },
  {
    code: "7832",
    value: "D376",
    label: "DUALCHI",
    province: "NU"
  },
  {
    code: "7833",
    value: "D395",
    label: "ELINI",
    province: "NU"
  },
  {
    code: "7834",
    value: "D430",
    label: "ESCALAPLANO",
    province: "SU"
  },
  {
    code: "7835",
    value: "D431",
    label: "ESCOLCA",
    province: "SU"
  },
  {
    code: "7836",
    value: "D443",
    label: "ESTERZILI",
    province: "SU"
  },
  {
    code: "7837",
    value: "D640",
    label: "FLUSSIO",
    province: "OR"
  },
  {
    code: "7838",
    value: "D665",
    label: "FONNI",
    province: "NU"
  },
  {
    code: "7839",
    value: "D842",
    label: "GADONI",
    province: "NU"
  },
  {
    code: "7840",
    value: "D859",
    label: "GAIRO",
    province: "NU"
  },
  {
    code: "7841",
    value: "D888",
    label: "GALTELLI",
    province: "NU"
  },
  {
    code: "7842",
    value: "D947",
    label: "GAVOI",
    province: "NU"
  },
  {
    code: "7843",
    value: "D968",
    label: "GENONI",
    province: "SU"
  },
  {
    code: "7844",
    value: "D982",
    label: "GERGEI",
    province: "SU"
  },
  {
    code: "7845",
    value: "E049",
    label: "GIRASOLE",
    province: "NU"
  },
  {
    code: "7846",
    value: "E283",
    label: "ILBONO",
    province: "NU"
  },
  {
    code: "7847",
    value: "E323",
    label: "IRGOLI",
    province: "NU"
  },
  {
    code: "7848",
    value: "E336",
    label: "ISILI",
    province: "SU"
  },
  {
    code: "7849",
    value: "E387",
    label: "JERZU",
    province: "NU"
  },
  {
    code: "7850",
    value: "E400",
    label: "LACONI",
    province: "OR"
  },
  {
    code: "7851",
    value: "E441",
    label: "LANUSEI",
    province: "NU"
  },
  {
    code: "7852",
    value: "E517",
    label: "LEI",
    province: "NU"
  },
  {
    code: "7853",
    value: "E644",
    label: "LOCERI",
    province: "NU"
  },
  {
    code: "7854",
    value: "E646",
    label: "LOCULI",
    province: "NU"
  },
  {
    code: "7855",
    value: "E647",
    label: "LODE",
    province: "NU"
  },
  {
    code: "7856",
    value: "E700",
    label: "LOTZORAI",
    province: "NU"
  },
  {
    code: "7857",
    value: "E736",
    label: "LULA",
    province: "NU"
  },
  {
    code: "7858",
    value: "E788",
    label: "MACOMER",
    province: "NU"
  },
  {
    code: "7859",
    value: "E825",
    label: "MAGOMADAS",
    province: "OR"
  },
  {
    code: "7860",
    value: "E874",
    label: "MAMOIADA",
    province: "NU"
  },
  {
    code: "7861",
    value: "F073",
    label: "MEANA SARDO",
    province: "NU"
  },
  {
    code: "7862",
    value: "F261",
    label: "MODOLO",
    province: "OR"
  },
  {
    code: "7863",
    value: "F698",
    label: "MONTRESTA",
    province: "OR"
  },
  {
    code: "7864",
    value: "F933",
    label: "NORAGUGUME",
    province: "NU"
  },
  {
    code: "7865",
    value: "F979",
    label: "NUORO",
    province: "NU"
  },
  {
    code: "7866",
    value: "F981",
    label: "NURAGUS",
    province: "SU"
  },
  {
    code: "7867",
    value: "F982",
    label: "NURALLAO",
    province: "SU"
  },
  {
    code: "7868",
    value: "F986",
    label: "NURRI",
    province: "SU"
  },
  {
    code: "7869",
    value: "G031",
    label: "OLIENA",
    province: "NU"
  },
  {
    code: "7870",
    value: "G044",
    label: "OLLOLAI",
    province: "NU"
  },
  {
    code: "7871",
    value: "G058",
    label: "OLZAI",
    province: "NU"
  },
  {
    code: "7872",
    value: "G064",
    label: "ONANI",
    province: "NU"
  },
  {
    code: "7873",
    value: "G070",
    label: "ONIFAI",
    province: "NU"
  },
  {
    code: "7874",
    value: "G071",
    label: "ONIFERI",
    province: "NU"
  },
  {
    code: "7875",
    value: "G084",
    label: "ORANI",
    province: "NU"
  },
  {
    code: "7876",
    value: "G097",
    label: "ORGOSOLO",
    province: "NU"
  },
  {
    code: "7877",
    value: "G119",
    label: "OROSEI",
    province: "NU"
  },
  {
    code: "7878",
    value: "G120",
    label: "OROTELLI",
    province: "NU"
  },
  {
    code: "7879",
    value: "G122",
    label: "ORROLI",
    province: "SU"
  },
  {
    code: "7880",
    value: "G146",
    label: "ORTUERI",
    province: "NU"
  },
  {
    code: "7881",
    value: "G147",
    label: "ORUNE",
    province: "NU"
  },
  {
    code: "7882",
    value: "G154",
    label: "OSIDDA",
    province: "NU"
  },
  {
    code: "7883",
    value: "G158",
    label: "OSINI",
    province: "NU"
  },
  {
    code: "7884",
    value: "G191",
    label: "OTTANA",
    province: "NU"
  },
  {
    code: "7885",
    value: "G201",
    label: "OVODDA",
    province: "NU"
  },
  {
    code: "7886",
    value: "G445",
    label: "PERDASDEFOGU",
    province: "NU"
  },
  {
    code: "7887",
    value: "G929",
    label: "POSADA",
    province: "NU"
  },
  {
    code: "7888",
    value: "H659",
    label: "SADALI",
    province: "SU"
  },
  {
    code: "7889",
    value: "H661",
    label: "SAGAMA",
    province: "OR"
  },
  {
    code: "7890",
    value: "I329",
    label: "SAN TEODORO",
    province: "SS"
  },
  {
    code: "7891",
    value: "I448",
    label: "SARULE",
    province: "NU"
  },
  {
    code: "7892",
    value: "I668",
    label: "SERRI",
    province: "SU"
  },
  {
    code: "7893",
    value: "I706",
    label: "SEUI",
    province: "SU"
  },
  {
    code: "7894",
    value: "I707",
    label: "SEULO",
    province: "SU"
  },
  {
    code: "7895",
    value: "I730",
    label: "SILANUS",
    province: "NU"
  },
  {
    code: "7896",
    value: "I748",
    label: "SINDIA",
    province: "NU"
  },
  {
    code: "7897",
    value: "I751",
    label: "SINISCOLA",
    province: "NU"
  },
  {
    code: "7898",
    value: "I851",
    label: "SORGONO",
    province: "NU"
  },
  {
    code: "7899",
    value: "L006",
    label: "SUNI",
    province: "OR"
  },
  {
    code: "7900",
    value: "L036",
    label: "TALANA",
    province: "NU"
  },
  {
    code: "7901",
    value: "L140",
    label: "TERTENIA",
    province: "NU"
  },
  {
    code: "7902",
    value: "L153",
    label: "TETI",
    province: "NU"
  },
  {
    code: "7903",
    value: "L160",
    label: "TIANA",
    province: "NU"
  },
  {
    code: "7904",
    value: "L172",
    label: "TINNURA",
    province: "OR"
  },
  {
    code: "7905",
    value: "L202",
    label: "TONARA",
    province: "NU"
  },
  {
    code: "7906",
    value: "L231",
    label: "TORPE",
    province: "NU"
  },
  {
    code: "7907",
    value: "A355",
    label: "TORTOLI",
    province: "NU"
  },
  {
    code: "7908",
    value: "L423",
    label: "TRIEI",
    province: "NU"
  },
  {
    code: "7909",
    value: "L489",
    label: "ULASSAI",
    province: "NU"
  },
  {
    code: "7910",
    value: "L506",
    label: "URZULEI",
    province: "NU"
  },
  {
    code: "7911",
    value: "L514",
    label: "USSASSAI",
    province: "NU"
  },
  {
    code: "7912",
    value: "L953",
    label: "VILLAGRANDE STRISAILI",
    province: "NU"
  },
  {
    code: "7913",
    value: "L992",
    label: "VILLANOVA TULO",
    province: "SU"
  },
  {
    code: "7914",
    value: "M285",
    label: "CARDEDU",
    province: "NU"
  },
  {
    code: "7915",
    value: "E649",
    label: "LODINE",
    province: "NU"
  },
  {
    code: "7916",
    value: "A359",
    label: "ARBUS",
    province: "SU"
  },
  {
    code: "7917",
    value: "A419",
    label: "ARMUNGIA",
    province: "SU"
  },
  {
    code: "7918",
    value: "A474",
    label: "ASSEMINI",
    province: "CA"
  },
  {
    code: "7919",
    value: "A597",
    label: "BALLAO",
    province: "SU"
  },
  {
    code: "7920",
    value: "A677",
    label: "BARRALI",
    province: "SU"
  },
  {
    code: "7921",
    value: "A681",
    label: "BARUMINI",
    province: "SU"
  },
  {
    code: "7922",
    value: "B250",
    label: "BUGGERRU",
    province: "SU"
  },
  {
    code: "7923",
    value: "B274",
    label: "BURCEI",
    province: "SU"
  },
  {
    code: "7924",
    value: "B354",
    label: "CAGLIARI",
    province: "CA"
  },
  {
    code: "7925",
    value: "B383",
    label: "CALASETTA",
    province: "SU"
  },
  {
    code: "7926",
    value: "B675",
    label: "CAPOTERRA",
    province: "CA"
  },
  {
    code: "7927",
    value: "B745",
    label: "CARBONIA",
    province: "SU"
  },
  {
    code: "7928",
    value: "B789",
    label: "CARLOFORTE",
    province: "SU"
  },
  {
    code: "7929",
    value: "C882",
    label: "COLLINAS",
    province: "SU"
  },
  {
    code: "7930",
    value: "D259",
    label: "DECIMOMANNU",
    province: "CA"
  },
  {
    code: "7931",
    value: "D260",
    label: "DECIMOPUTZU",
    province: "SU"
  },
  {
    code: "7932",
    value: "D323",
    label: "DOLIANOVA",
    province: "SU"
  },
  {
    code: "7933",
    value: "D333",
    label: "DOMUS DE MARIA",
    province: "SU"
  },
  {
    code: "7934",
    value: "D334",
    label: "DOMUSNOVAS",
    province: "SU"
  },
  {
    code: "7935",
    value: "D344",
    label: "DONORI",
    province: "SU"
  },
  {
    code: "7936",
    value: "D639",
    label: "FLUMINIMAGGIORE",
    province: "SU"
  },
  {
    code: "7937",
    value: "D827",
    label: "FURTEI",
    province: "SU"
  },
  {
    code: "7938",
    value: "D970",
    label: "GENURI",
    province: "SU"
  },
  {
    code: "7939",
    value: "D994",
    label: "GESICO",
    province: "SU"
  },
  {
    code: "7940",
    value: "D997",
    label: "GESTURI",
    province: "SU"
  },
  {
    code: "7941",
    value: "E022",
    label: "GIBA",
    province: "SU"
  },
  {
    code: "7942",
    value: "E084",
    label: "GONI",
    province: "SU"
  },
  {
    code: "7943",
    value: "E086",
    label: "GONNESA",
    province: "SU"
  },
  {
    code: "7944",
    value: "E085",
    label: "GONNOSFANADIGA",
    province: "SU"
  },
  {
    code: "7945",
    value: "E234",
    label: "GUAMAGGIORE",
    province: "SU"
  },
  {
    code: "7946",
    value: "E252",
    label: "GUASILA",
    province: "SU"
  },
  {
    code: "7947",
    value: "E270",
    label: "GUSPINI",
    province: "SU"
  },
  {
    code: "7948",
    value: "E281",
    label: "IGLESIAS",
    province: "SU"
  },
  {
    code: "7949",
    value: "E464",
    label: "LAS PLASSAS",
    province: "SU"
  },
  {
    code: "7950",
    value: "E742",
    label: "LUNAMATRONA",
    province: "SU"
  },
  {
    code: "7951",
    value: "E877",
    label: "MANDAS",
    province: "SU"
  },
  {
    code: "7952",
    value: "E903",
    label: "MARACALAGONIS",
    province: "CA"
  },
  {
    code: "7953",
    value: "F333",
    label: "MONASTIR",
    province: "SU"
  },
  {
    code: "7954",
    value: "F808",
    label: "MURAVERA",
    province: "SU"
  },
  {
    code: "7955",
    value: "F822",
    label: "MUSEI",
    province: "SU"
  },
  {
    code: "7956",
    value: "F841",
    label: "NARCAO",
    province: "SU"
  },
  {
    code: "7957",
    value: "F983",
    label: "NURAMINIS",
    province: "SU"
  },
  {
    code: "7958",
    value: "F991",
    label: "NUXIS",
    province: "SU"
  },
  {
    code: "7959",
    value: "G133",
    label: "ORTACESUS",
    province: "SU"
  },
  {
    code: "7960",
    value: "G207",
    label: "PABILLONIS",
    province: "SU"
  },
  {
    code: "7961",
    value: "G382",
    label: "PAULI ARBAREI",
    province: "SU"
  },
  {
    code: "7962",
    value: "G446",
    label: "PERDAXIUS",
    province: "SU"
  },
  {
    code: "7963",
    value: "G669",
    label: "PIMENTEL",
    province: "SU"
  },
  {
    code: "7964",
    value: "G922",
    label: "PORTOSCUSO",
    province: "SU"
  },
  {
    code: "7965",
    value: "H088",
    label: "PULA",
    province: "CA"
  },
  {
    code: "7966",
    value: "H118",
    label: "QUARTU SANT ELENA",
    province: "CA"
  },
  {
    code: "7967",
    value: "H738",
    label: "SAMASSI",
    province: "SU"
  },
  {
    code: "7968",
    value: "H739",
    label: "SAMATZAI",
    province: "SU"
  },
  {
    code: "7969",
    value: "H766",
    label: "SAN BASILIO",
    province: "SU"
  },
  {
    code: "7970",
    value: "H856",
    label: "SAN GAVINO MONREALE",
    province: "SU"
  },
  {
    code: "7971",
    value: "G287",
    label: "SAN GIOVANNI SUERGIU",
    province: "SU"
  },
  {
    code: "7972",
    value: "H974",
    label: "SANLURI",
    province: "SU"
  },
  {
    code: "7973",
    value: "G383",
    label: "SAN NICOLO GERREI",
    province: "SU"
  },
  {
    code: "7974",
    value: "I166",
    label: "SAN SPERATE",
    province: "SU"
  },
  {
    code: "7975",
    value: "I182",
    label: "SANTADI",
    province: "SU"
  },
  {
    code: "7976",
    value: "I271",
    label: "SANT ANDREA FRIUS",
    province: "SU"
  },
  {
    code: "7977",
    value: "M209",
    label: "SANT ANNA ARRESI",
    province: "SU"
  },
  {
    code: "7978",
    value: "I294",
    label: "SANT ANTIOCO",
    province: "SU"
  },
  {
    code: "7979",
    value: "I402",
    label: "SAN VITO",
    province: "SU"
  },
  {
    code: "7980",
    value: "I428",
    label: "SARDARA",
    province: "SU"
  },
  {
    code: "7981",
    value: "I443",
    label: "SARROCH",
    province: "CA"
  },
  {
    code: "7982",
    value: "I570",
    label: "SEGARIU",
    province: "SU"
  },
  {
    code: "7983",
    value: "I580",
    label: "SELARGIUS",
    province: "CA"
  },
  {
    code: "7984",
    value: "I582",
    label: "SELEGAS",
    province: "SU"
  },
  {
    code: "7985",
    value: "I615",
    label: "SENORBI",
    province: "SU"
  },
  {
    code: "7986",
    value: "I624",
    label: "SERDIANA",
    province: "SU"
  },
  {
    code: "7987",
    value: "I647",
    label: "SERRAMANNA",
    province: "SU"
  },
  {
    code: "7988",
    value: "I667",
    label: "SERRENTI",
    province: "SU"
  },
  {
    code: "7989",
    value: "I695",
    label: "SESTU",
    province: "CA"
  },
  {
    code: "7990",
    value: "I699",
    label: "SETTIMO SAN PIETRO",
    province: "CA"
  },
  {
    code: "7991",
    value: "I705",
    label: "SETZU",
    province: "SU"
  },
  {
    code: "7992",
    value: "I724",
    label: "SIDDI",
    province: "SU"
  },
  {
    code: "7993",
    value: "I734",
    label: "SILIQUA",
    province: "SU"
  },
  {
    code: "7994",
    value: "I735",
    label: "SILIUS",
    province: "SU"
  },
  {
    code: "7995",
    value: "I752",
    label: "SINNAI",
    province: "CA"
  },
  {
    code: "7996",
    value: "I765",
    label: "SIURGUS DONIGALA",
    province: "SU"
  },
  {
    code: "7997",
    value: "I797",
    label: "SOLEMINIS",
    province: "SU"
  },
  {
    code: "7998",
    value: "I995",
    label: "SUELLI",
    province: "SU"
  },
  {
    code: "7999",
    value: "L154",
    label: "TEULADA",
    province: "SU"
  },
  {
    code: "8000",
    value: "L337",
    label: "TRATALIAS",
    province: "SU"
  },
  {
    code: "8001",
    value: "L463",
    label: "TUILI",
    province: "SU"
  },
  {
    code: "8002",
    value: "L473",
    label: "TURRI",
    province: "SU"
  },
  {
    code: "8003",
    value: "L512",
    label: "USSANA",
    province: "SU"
  },
  {
    code: "8004",
    value: "L513",
    label: "USSARAMANNA",
    province: "SU"
  },
  {
    code: "8005",
    value: "L521",
    label: "UTA",
    province: "CA"
  },
  {
    code: "8006",
    value: "L613",
    label: "VALLERMOSA",
    province: "SU"
  },
  {
    code: "8007",
    value: "L924",
    label: "VILLACIDRO",
    province: "SU"
  },
  {
    code: "8008",
    value: "L966",
    label: "VILLAMAR",
    province: "SU"
  },
  {
    code: "8009",
    value: "L968",
    label: "VILLAMASSARGIA",
    province: "SU"
  },
  {
    code: "8010",
    value: "L986",
    label: "VILLANOVAFORRU",
    province: "SU"
  },
  {
    code: "8011",
    value: "L987",
    label: "VILLANOVAFRANCA",
    province: "SU"
  },
  {
    code: "8012",
    value: "L998",
    label: "VILLAPUTZU",
    province: "SU"
  },
  {
    code: "8013",
    value: "M016",
    label: "VILLASALTO",
    province: "SU"
  },
  {
    code: "8014",
    value: "I118",
    label: "VILLA SAN PIETRO",
    province: "CA"
  },
  {
    code: "8015",
    value: "B738",
    label: "VILLASIMIUS",
    province: "SU"
  },
  {
    code: "8016",
    value: "M025",
    label: "VILLASOR",
    province: "SU"
  },
  {
    code: "8017",
    value: "M026",
    label: "VILLASPECIOSA",
    province: "SU"
  },
  {
    code: "8018",
    value: "M270",
    label: "MASAINAS",
    province: "SU"
  },
  {
    code: "8019",
    value: "M278",
    label: "VILLAPERUCCIO",
    province: "SU"
  },
  {
    code: "8020",
    value: "H119",
    label: "QUARTUCCIU",
    province: "CA"
  },
  {
    code: "8021",
    value: "M288",
    label: "CASTIADAS",
    province: "SU"
  },
  {
    code: "8022",
    value: "M291",
    label: "PISCINAS",
    province: "SU"
  },
  {
    code: "8023",
    value: "D399",
    label: "ELMAS",
    province: "CA"
  },
  {
    code: "8024",
    value: "F383",
    label: "MONSERRATO",
    province: "CA"
  },
  {
    code: "8025",
    value: "A007",
    label: "ABBASANTA",
    province: "OR"
  },
  {
    code: "8026",
    value: "A097",
    label: "AIDOMAGGIORE",
    province: "OR"
  },
  {
    code: "8027",
    value: "A126",
    label: "ALBAGIARA",
    province: "OR"
  },
  {
    code: "8028",
    value: "A180",
    label: "ALES",
    province: "OR"
  },
  {
    code: "8029",
    value: "A204",
    label: "ALLAI",
    province: "OR"
  },
  {
    code: "8030",
    value: "A357",
    label: "ARBOREA",
    province: "OR"
  },
  {
    code: "8031",
    value: "A380",
    label: "ARDAULI",
    province: "OR"
  },
  {
    code: "8032",
    value: "A477",
    label: "ASSOLO",
    province: "OR"
  },
  {
    code: "8033",
    value: "A480",
    label: "ASUNI",
    province: "OR"
  },
  {
    code: "8034",
    value: "A614",
    label: "BARADILI",
    province: "OR"
  },
  {
    code: "8035",
    value: "A621",
    label: "BARATILI SAN PIETRO",
    province: "OR"
  },
  {
    code: "8036",
    value: "A655",
    label: "BARESSA",
    province: "OR"
  },
  {
    code: "8037",
    value: "A721",
    label: "BAULADU",
    province: "OR"
  },
  {
    code: "8038",
    value: "A856",
    label: "BIDONI",
    province: "OR"
  },
  {
    code: "8039",
    value: "A960",
    label: "BONARCADO",
    province: "OR"
  },
  {
    code: "8040",
    value: "B055",
    label: "BORONEDDU",
    province: "OR"
  },
  {
    code: "8041",
    value: "B281",
    label: "BUSACHI",
    province: "OR"
  },
  {
    code: "8042",
    value: "B314",
    label: "CABRAS",
    province: "OR"
  },
  {
    code: "8043",
    value: "D200",
    label: "CUGLIERI",
    province: "OR"
  },
  {
    code: "8044",
    value: "D695",
    label: "FORDONGIANUS",
    province: "OR"
  },
  {
    code: "8045",
    value: "E004",
    label: "GHILARZA",
    province: "OR"
  },
  {
    code: "8046",
    value: "E087",
    label: "GONNOSCODINA",
    province: "OR"
  },
  {
    code: "8047",
    value: "D585",
    label: "GONNOSNO",
    province: "OR"
  },
  {
    code: "8048",
    value: "E088",
    label: "GONNOSTRAMATZA",
    province: "OR"
  },
  {
    code: "8049",
    value: "E972",
    label: "MARRUBIU",
    province: "OR"
  },
  {
    code: "8050",
    value: "F050",
    label: "MASULLAS",
    province: "OR"
  },
  {
    code: "8051",
    value: "F208",
    label: "MILIS",
    province: "OR"
  },
  {
    code: "8052",
    value: "F270",
    label: "MOGORELLA",
    province: "OR"
  },
  {
    code: "8053",
    value: "F272",
    label: "MOGORO",
    province: "OR"
  },
  {
    code: "8054",
    value: "F727",
    label: "MORGONGIORI",
    province: "OR"
  },
  {
    code: "8055",
    value: "F840",
    label: "NARBOLIA",
    province: "OR"
  },
  {
    code: "8056",
    value: "F867",
    label: "NEONELI",
    province: "OR"
  },
  {
    code: "8057",
    value: "F934",
    label: "NORBELLO",
    province: "OR"
  },
  {
    code: "8058",
    value: "F974",
    label: "NUGHEDU SANTA VITTORIA",
    province: "OR"
  },
  {
    code: "8059",
    value: "F980",
    label: "NURACHI",
    province: "OR"
  },
  {
    code: "8060",
    value: "F985",
    label: "NURECI",
    province: "OR"
  },
  {
    code: "8061",
    value: "G043",
    label: "OLLASTRA",
    province: "OR"
  },
  {
    code: "8062",
    value: "G113",
    label: "ORISTANO",
    province: "OR"
  },
  {
    code: "8063",
    value: "G286",
    label: "PALMAS ARBOREA",
    province: "OR"
  },
  {
    code: "8064",
    value: "G379",
    label: "PAU",
    province: "OR"
  },
  {
    code: "8065",
    value: "G384",
    label: "PAULILATINO",
    province: "OR"
  },
  {
    code: "8066",
    value: "G817",
    label: "POMPU",
    province: "OR"
  },
  {
    code: "8067",
    value: "H301",
    label: "RIOLA SARDO",
    province: "OR"
  },
  {
    code: "8068",
    value: "F271",
    label: "RUINAS",
    province: "OR"
  },
  {
    code: "8069",
    value: "H756",
    label: "SAMUGHEO",
    province: "OR"
  },
  {
    code: "8070",
    value: "A368",
    label: "SAN NICOLO D ARCIDANO",
    province: "OR"
  },
  {
    code: "8071",
    value: "I205",
    label: "SANTA GIUSTA",
    province: "OR"
  },
  {
    code: "8072",
    value: "I298",
    label: "VILLA SANT ANTONIO",
    province: "OR"
  },
  {
    code: "8073",
    value: "I374",
    label: "SANTU LUSSURGIU",
    province: "OR"
  },
  {
    code: "8074",
    value: "I384",
    label: "SAN VERO MILIS",
    province: "OR"
  },
  {
    code: "8075",
    value: "I503",
    label: "SCANO DI MONTIFERRO",
    province: "OR"
  },
  {
    code: "8076",
    value: "I564",
    label: "SEDILO",
    province: "OR"
  },
  {
    code: "8077",
    value: "I605",
    label: "SENEGHE",
    province: "OR"
  },
  {
    code: "8078",
    value: "I609",
    label: "SENIS",
    province: "OR"
  },
  {
    code: "8079",
    value: "I613",
    label: "SENNARIOLO",
    province: "OR"
  },
  {
    code: "8080",
    value: "I717",
    label: "SIAMAGGIORE",
    province: "OR"
  },
  {
    code: "8081",
    value: "I718",
    label: "SIAMANNA",
    province: "OR"
  },
  {
    code: "8082",
    value: "I742",
    label: "SIMALA",
    province: "OR"
  },
  {
    code: "8083",
    value: "I743",
    label: "SIMAXIS",
    province: "OR"
  },
  {
    code: "8084",
    value: "I749",
    label: "SINI",
    province: "OR"
  },
  {
    code: "8085",
    value: "I757",
    label: "SIRIS",
    province: "OR"
  },
  {
    code: "8086",
    value: "I791",
    label: "SOLARUSSA",
    province: "OR"
  },
  {
    code: "8087",
    value: "I861",
    label: "SORRADILE",
    province: "OR"
  },
  {
    code: "8088",
    value: "L023",
    label: "TADASUNI",
    province: "OR"
  },
  {
    code: "8089",
    value: "L122",
    label: "TERRALBA",
    province: "OR"
  },
  {
    code: "8090",
    value: "L321",
    label: "TRAMATZA",
    province: "OR"
  },
  {
    code: "8091",
    value: "L393",
    label: "TRESNURAGHES",
    province: "OR"
  },
  {
    code: "8092",
    value: "L488",
    label: "ULA TIRSO",
    province: "OR"
  },
  {
    code: "8093",
    value: "L496",
    label: "URAS",
    province: "OR"
  },
  {
    code: "8094",
    value: "L508",
    label: "USELLUS",
    province: "OR"
  },
  {
    code: "8095",
    value: "L991",
    label: "VILLANOVA TRUSCHEDU",
    province: "OR"
  },
  {
    code: "8096",
    value: "M030",
    label: "VILLAURBANA",
    province: "OR"
  },
  {
    code: "8097",
    value: "A609",
    label: "VILLA VERDE",
    province: "OR"
  },
  {
    code: "8098",
    value: "M153",
    label: "ZEDDIANI",
    province: "OR"
  },
  {
    code: "8099",
    value: "M168",
    label: "ZERFALIU",
    province: "OR"
  },
  {
    code: "8100",
    value: "I721",
    label: "SIAPICCIA",
    province: "OR"
  },
  {
    code: "8101",
    value: "D214",
    label: "CURCURIS",
    province: "OR"
  },
  {
    code: "8102",
    value: "I778",
    label: "SODDI",
    province: "OR"
  },
  {
    code: "8103",
    value: "M302",
    label: "MONTIGLIO MONFERRATO",
    province: "AT"
  },
  {
    code: "8105",
    value: "M308",
    label: "CAVALLINO TREPORTI",
    province: "VE"
  },
  {
    code: "9384",
    value: "E491",
    label: "WENGEN",
    province: "BZ"
  },
  {
    code: "9385",
    value: "I603",
    label: "UNSERE LIEBE FRAU IM WALDE ST FELIX",
    province: "BZ"
  },
  {
    code: "9386",
    value: "A179",
    label: "ALDEIN",
    province: "BZ"
  },
  {
    code: "9387",
    value: "A286",
    label: "ANDRIAN",
    province: "BZ"
  },
  {
    code: "9388",
    value: "A306",
    label: "ALTREI",
    province: "BZ"
  },
  {
    code: "9389",
    value: "A332",
    label: "EPPAN AN DER WEINSTRASSE",
    province: "BZ"
  },
  {
    code: "9390",
    value: "A507",
    label: "HAFLING",
    province: "BZ"
  },
  {
    code: "9391",
    value: "A537",
    label: "ABTEI",
    province: "BZ"
  },
  {
    code: "9392",
    value: "A635",
    label: "BARBIAN",
    province: "BZ"
  },
  {
    code: "9393",
    value: "A952",
    label: "BOZEN",
    province: "BZ"
  },
  {
    code: "9394",
    value: "B116",
    label: "PRAGS",
    province: "BZ"
  },
  {
    code: "9395",
    value: "B145",
    label: "BRENNER",
    province: "BZ"
  },
  {
    code: "9396",
    value: "B160",
    label: "BRIXEN",
    province: "BZ"
  },
  {
    code: "9397",
    value: "B203",
    label: "BRANZOLL",
    province: "BZ"
  },
  {
    code: "9398",
    value: "B220",
    label: "BRUNECK",
    province: "BZ"
  },
  {
    code: "9399",
    value: "B364",
    label: "KUENS",
    province: "BZ"
  },
  {
    code: "9400",
    value: "B397",
    label: "KALTERN AN DER WEINSTRASSE",
    province: "BZ"
  },
  {
    code: "9401",
    value: "B529",
    label: "FREIENFELD",
    province: "BZ"
  },
  {
    code: "9402",
    value: "B570",
    label: "SAND IN TAUFERS",
    province: "BZ"
  },
  {
    code: "9403",
    value: "C062",
    label: "KASTELBELL TSCHARS",
    province: "BZ"
  },
  {
    code: "9404",
    value: "C254",
    label: "KASTELRUTH",
    province: "BZ"
  },
  {
    code: "9405",
    value: "A022",
    label: "TSCHERMS",
    province: "BZ"
  },
  {
    code: "9406",
    value: "C625",
    label: "KIENS",
    province: "BZ"
  },
  {
    code: "9407",
    value: "C652",
    label: "KLAUSEN",
    province: "BZ"
  },
  {
    code: "9408",
    value: "B799",
    label: "KARNEID",
    province: "BZ"
  },
  {
    code: "9409",
    value: "D048",
    label: "KURTATSCH AN DER WEINSTRASSE",
    province: "BZ"
  },
  {
    code: "9410",
    value: "D075",
    label: "KURTINIG AN DER WEINSTRASSE",
    province: "BZ"
  },
  {
    code: "9412",
    value: "D222",
    label: "GRAUN IM VINSCHGAU",
    province: "BZ"
  },
  {
    code: "9413",
    value: "D311",
    label: "TOBLACH",
    province: "BZ"
  },
  {
    code: "9414",
    value: "D392",
    label: "NEUMARKT",
    province: "BZ"
  },
  {
    code: "9415",
    value: "D484",
    label: "PFALZEN",
    province: "BZ"
  },
  {
    code: "9416",
    value: "D571",
    label: "VOELS AM SCHLERN",
    province: "BZ"
  },
  {
    code: "9417",
    value: "D731",
    label: "FRANZENSFESTE",
    province: "BZ"
  },
  {
    code: "9418",
    value: "D821",
    label: "VILLNOESS",
    province: "BZ"
  },
  {
    code: "9419",
    value: "D860",
    label: "GAIS",
    province: "BZ"
  },
  {
    code: "9420",
    value: "D923",
    label: "GARGAZON",
    province: "BZ"
  },
  {
    code: "9421",
    value: "E069",
    label: "GLURNS",
    province: "BZ"
  },
  {
    code: "9422",
    value: "E398",
    label: "LATSCH",
    province: "BZ"
  },
  {
    code: "9423",
    value: "E412",
    label: "ALGUND",
    province: "BZ"
  },
  {
    code: "9424",
    value: "E420",
    label: "LAJEN",
    province: "BZ"
  },
  {
    code: "9425",
    value: "E421",
    label: "LEIFERS",
    province: "BZ"
  },
  {
    code: "9427",
    value: "E457",
    label: "LAAS",
    province: "BZ"
  },
  {
    code: "9428",
    value: "E481",
    label: "LAUREIN",
    province: "BZ"
  },
  {
    code: "9429",
    value: "E764",
    label: "LUESEN",
    province: "BZ"
  },
  {
    code: "9430",
    value: "E829",
    label: "MARGREID AN DER WEINSTRASSE",
    province: "BZ"
  },
  {
    code: "9431",
    value: "E862",
    label: "MALS",
    province: "BZ"
  },
  {
    code: "9432",
    value: "E938",
    label: "ENNEBERG",
    province: "BZ"
  },
  {
    code: "9433",
    value: "E959",
    label: "MARLING",
    province: "BZ"
  },
  {
    code: "9434",
    value: "E981",
    label: "MARTELL",
    province: "BZ"
  },
  {
    code: "9435",
    value: "F118",
    label: "MOELTEN",
    province: "BZ"
  },
  {
    code: "9436",
    value: "F132",
    label: "MERAN",
    province: "BZ"
  },
  {
    code: "9438",
    value: "F392",
    label: "MONTAN",
    province: "BZ"
  },
  {
    code: "9439",
    value: "F766",
    label: "MOOS IN PASSEIER",
    province: "BZ"
  },
  {
    code: "9440",
    value: "F836",
    label: "NALS",
    province: "BZ"
  },
  {
    code: "9441",
    value: "F849",
    label: "NATURNS",
    province: "BZ"
  },
  {
    code: "9442",
    value: "F856",
    label: "NATZ SCHABS",
    province: "BZ"
  },
  {
    code: "9443",
    value: "F949",
    label: "WELSCHNOFEN",
    province: "BZ"
  },
  {
    code: "9444",
    value: "F950",
    label: "DEUTSCHNOFEN",
    province: "BZ"
  },
  {
    code: "9445",
    value: "G083",
    label: "AUER",
    province: "BZ"
  },
  {
    code: "9446",
    value: "G140",
    label: "ST ULRICH",
    province: "BZ"
  },
  {
    code: "9447",
    value: "G328",
    label: "PARTSCHINS",
    province: "BZ"
  },
  {
    code: "9448",
    value: "G443",
    label: "PERCHA",
    province: "BZ"
  },
  {
    code: "9449",
    value: "G299",
    label: "PLAUS",
    province: "BZ"
  },
  {
    code: "9450",
    value: "G830",
    label: "WAIDBRUCK",
    province: "BZ"
  },
  {
    code: "9451",
    value: "G936",
    label: "BURGSTALL",
    province: "BZ"
  },
  {
    code: "9452",
    value: "H004",
    label: "PRAD AM STILFSERJOCH",
    province: "BZ"
  },
  {
    code: "9453",
    value: "H019",
    label: "PRETTAU",
    province: "BZ"
  },
  {
    code: "9454",
    value: "H081",
    label: "PROVEIS",
    province: "BZ"
  },
  {
    code: "9455",
    value: "H152",
    label: "RATSCHINGS",
    province: "BZ"
  },
  {
    code: "9456",
    value: "H189",
    label: "RASEN ANTHOLZ",
    province: "BZ"
  },
  {
    code: "9457",
    value: "H236",
    label: "RITTEN",
    province: "BZ"
  },
  {
    code: "9458",
    value: "H284",
    label: "RIFFIAN",
    province: "BZ"
  },
  {
    code: "9459",
    value: "H299",
    label: "MUEHLBACH",
    province: "BZ"
  },
  {
    code: "9460",
    value: "H475",
    label: "RODENECK",
    province: "BZ"
  },
  {
    code: "9461",
    value: "H719",
    label: "SALURN",
    province: "BZ"
  },
  {
    code: "9462",
    value: "H786",
    label: "INNICHEN",
    province: "BZ"
  },
  {
    code: "9463",
    value: "H858",
    label: "JENESIEN",
    province: "BZ"
  },
  {
    code: "9464",
    value: "H952",
    label: "ST LEONHARD IN PASSEIER",
    province: "BZ"
  },
  {
    code: "9465",
    value: "H956",
    label: "ST LORENZEN",
    province: "BZ"
  },
  {
    code: "9466",
    value: "H988",
    label: "ST MARTIN IN THURN",
    province: "BZ"
  },
  {
    code: "9467",
    value: "H989",
    label: "ST MARTIN IN PASSEIER",
    province: "BZ"
  },
  {
    code: "9468",
    value: "I065",
    label: "ST PANKRAZ",
    province: "BZ"
  },
  {
    code: "9469",
    value: "I173",
    label: "ST CHRISTINA IN GROEDEN",
    province: "BZ"
  },
  {
    code: "9470",
    value: "I431",
    label: "SARNTAL",
    province: "BZ"
  },
  {
    code: "9471",
    value: "I519",
    label: "SCHENNA",
    province: "BZ"
  },
  {
    code: "9472",
    value: "I593",
    label: "MUEHLWALD",
    province: "BZ"
  },
  {
    code: "9473",
    value: "I591",
    label: "WOLKENSTEIN IN GROEDEN",
    province: "BZ"
  },
  {
    code: "9474",
    value: "I604",
    label: "SCHNALS",
    province: "BZ"
  },
  {
    code: "9475",
    value: "I687",
    label: "SEXTEN",
    province: "BZ"
  },
  {
    code: "9476",
    value: "I729",
    label: "SCHLANDERS",
    province: "BZ"
  },
  {
    code: "9477",
    value: "I771",
    label: "SCHLUDERNS",
    province: "BZ"
  },
  {
    code: "9478",
    value: "I948",
    label: "STILFS",
    province: "BZ"
  },
  {
    code: "9479",
    value: "L106",
    label: "TERENTEN",
    province: "BZ"
  },
  {
    code: "9480",
    value: "L108",
    label: "TERLAN",
    province: "BZ"
  },
  {
    code: "9481",
    value: "L111",
    label: "TRAMIN AN DER WEINSTRASSE",
    province: "BZ"
  },
  {
    code: "9482",
    value: "L149",
    label: "TISENS",
    province: "BZ"
  },
  {
    code: "9483",
    value: "L176",
    label: "TIERS",
    province: "BZ"
  },
  {
    code: "9484",
    value: "L178",
    label: "TIROL",
    province: "BZ"
  },
  {
    code: "9486",
    value: "L455",
    label: "TAUFERS IM MUENSTERTAL",
    province: "BZ"
  },
  {
    code: "9487",
    value: "L490",
    label: "ULTEN",
    province: "BZ"
  },
  {
    code: "9488",
    value: "L527",
    label: "PFATTEN",
    province: "BZ"
  },
  {
    code: "9489",
    value: "L552",
    label: "OLANG",
    province: "BZ"
  },
  {
    code: "9490",
    value: "L564",
    label: "PFITSCH",
    province: "BZ"
  },
  {
    code: "9491",
    value: "L595",
    label: "AHRNTAL",
    province: "BZ"
  },
  {
    code: "9492",
    value: "L601",
    label: "GSIES",
    province: "BZ"
  },
  {
    code: "9493",
    value: "L660",
    label: "VINTL",
    province: "BZ"
  },
  {
    code: "9494",
    value: "L687",
    label: "VAHRN",
    province: "BZ"
  },
  {
    code: "9495",
    value: "L745",
    label: "VOERAN",
    province: "BZ"
  },
  {
    code: "9496",
    value: "L915",
    label: "NIEDERDORF",
    province: "BZ"
  },
  {
    code: "9497",
    value: "L971",
    label: "VILLANDERS",
    province: "BZ"
  },
  {
    code: "9498",
    value: "M067",
    label: "STERZING",
    province: "BZ"
  },
  {
    code: "9499",
    value: "L724",
    label: "FELDTHURNS",
    province: "BZ"
  },
  {
    code: "9500",
    value: "M309",
    label: "FONTE NUOVA",
    province: "RM"
  },
  {
    code: "9503",
    value: "I162",
    label: "SAN SIRO",
    province: "CO"
  },
  {
    code: "9508",
    value: "A618",
    label: "BARANZATE",
    province: "MI"
  },
  {
    code: "9520",
    value: "A332",
    label: "APPIANO STRADA VINO",
    province: "BZ"
  },
  {
    code: "9569",
    value: "D079",
    label: "CORVARA",
    province: "BZ"
  },
  {
    code: "9644",
    value: "H988",
    label: "SAN MARTINO BADIA",
    province: "BZ"
  },
  {
    code: "9697",
    value: "M311",
    label: "CAMPOLONGO TAPOGLIANO",
    province: "UD"
  },
  {
    code: "9698",
    value: "M313",
    label: "LEDRO",
    province: "TN"
  },
  {
    code: "9699",
    value: "M314",
    label: "COMANO TERME",
    province: "TN"
  },
  {
    code: "9804",
    value: "M315",
    label: "GRAVEDONA ED UNITI",
    province: "CO"
  },
  {
    code: "9807",
    value: "M340",
    label: "BORGO VIRGILIO",
    province: "MN"
  },
  {
    code: "9808",
    value: "M327",
    label: "CASCIANA TERME LARI",
    province: "PI"
  },
  {
    code: "9809",
    value: "M322",
    label: "CASTELFRANCO PIANDISCO",
    province: "AR"
  },
  {
    code: "9810",
    value: "M336",
    label: "COLVERDE",
    province: "CO"
  },
  {
    code: "9811",
    value: "M338",
    label: "CORNALE E BASTIDA",
    province: "PV"
  },
  {
    code: "9812",
    value: "M328",
    label: "CRESPINA LORENZANA",
    province: "PI"
  },
  {
    code: "9813",
    value: "M319",
    label: "FABBRICHE DI VERGEMOLI",
    province: "LU"
  },
  {
    code: "9814",
    value: "M321",
    label: "FIGLINE E INCISA VALDARNO",
    province: "FI"
  },
  {
    code: "9815",
    value: "M323",
    label: "FISCAGLIA",
    province: "FE"
  },
  {
    code: "9816",
    value: "M339",
    label: "MACCAGNO CON PINO E VEDDASCA",
    province: "VA"
  },
  {
    code: "9817",
    value: "M330",
    label: "MONTORO",
    province: "AV"
  },
  {
    code: "9818",
    value: "M324",
    label: "POGGIO TORRIANA",
    province: "RN"
  },
  {
    code: "9819",
    value: "M329",
    label: "PRATOVECCHIO STIA",
    province: "AR"
  },
  {
    code: "9820",
    value: "M332",
    label: "QUERO VAS",
    province: "BL"
  },
  {
    code: "9821",
    value: "M317",
    label: "RIVIGNANO TEOR",
    province: "UD"
  },
  {
    code: "9822",
    value: "M326",
    label: "SCARPERIA E SAN PIERO",
    province: "FI"
  },
  {
    code: "9823",
    value: "M325",
    label: "SISSA TRECASALI",
    province: "PR"
  },
  {
    code: "9824",
    value: "M341",
    label: "TREMEZZINA",
    province: "CO"
  },
  {
    code: "9825",
    value: "M318",
    label: "TRECASTELLI",
    province: "AN"
  },
  {
    code: "9826",
    value: "M334",
    label: "VAL BREMBILLA",
    province: "BG"
  },
  {
    code: "9827",
    value: "M331",
    label: "VALLEFOGLIA",
    province: "PU"
  },
  {
    code: "9828",
    value: "M320",
    label: "VALSAMOGGIA",
    province: "BO"
  },
  {
    code: "9829",
    value: "M337",
    label: "VERDERIO",
    province: "LC"
  },
  {
    code: "9832",
    value: "F371",
    label: "WELSBERG TAISTEN",
    province: "BZ"
  },
  {
    code: "9833",
    value: "L444",
    label: "TRUDEN IM NATURPARK",
    province: "BZ"
  },
  {
    code: "9835",
    value: "M343",
    label: "VALDAONE",
    province: "TN"
  },
  {
    code: "9836",
    value: "M345",
    label: "SAN LORENZO DORSINO",
    province: "TN"
  },
  {
    code: "9837",
    value: "M344",
    label: "PREDAIA",
    province: "TN"
  },
  {
    code: "9838",
    value: "M346",
    label: "VALVASONE ARZENE",
    province: "PN"
  },
  {
    code: "9839",
    value: "M347",
    label: "SILLANO GIUNCUGNANO",
    province: "LU"
  },
  {
    code: "9840",
    value: "M348",
    label: "LA VALLETTA BRIANZA",
    province: "LC"
  },
  {
    code: "9842",
    value: "M354",
    label: "CASTEL IVANO",
    province: "TN"
  },
  {
    code: "9843",
    value: "M351",
    label: "AMBLAR DON",
    province: "TN"
  },
  {
    code: "9844",
    value: "M356",
    label: "CONTA",
    province: "TN"
  },
  {
    code: "9845",
    value: "M367",
    label: "POLESINE ZIBELLO",
    province: "PR"
  },
  {
    code: "9846",
    value: "M352",
    label: "BORGO CHIESE",
    province: "TN"
  },
  {
    code: "9847",
    value: "M353",
    label: "BORGO LARES",
    province: "TN"
  },
  {
    code: "9848",
    value: "M364",
    label: "VENTASSO",
    province: "RE"
  },
  {
    code: "9849",
    value: "M355",
    label: "CEMBRA LISIGNAGO",
    province: "TN"
  },
  {
    code: "9850",
    value: "M362",
    label: "VALLELAGHI",
    province: "TN"
  },
  {
    code: "9851",
    value: "M360",
    label: "SELLA GIUDICARIE",
    province: "TN"
  },
  {
    code: "9852",
    value: "M363",
    label: "VILLE D ANAUNIA",
    province: "TN"
  },
  {
    code: "9853",
    value: "M349",
    label: "ALTAVALLE",
    province: "TN"
  },
  {
    code: "9854",
    value: "M361",
    label: "TRE VILLE",
    province: "TN"
  },
  {
    code: "9855",
    value: "M366",
    label: "DIMARO FOLGARIDA",
    province: "TN"
  },
  {
    code: "9856",
    value: "M365",
    label: "PIEVE DI BONO PREZZO",
    province: "TN"
  },
  {
    code: "9857",
    value: "M372",
    label: "CORTEOLONA E GENZONE",
    province: "PV"
  },
  {
    code: "9858",
    value: "M350",
    label: "ALTOPIANO DELLA VIGOLANA",
    province: "TN"
  },
  {
    code: "9859",
    value: "M357",
    label: "MADRUZZO",
    province: "TN"
  },
  {
    code: "9860",
    value: "M368",
    label: "MONTESCUDO MONTE COLOMBO",
    province: "RN"
  },
  {
    code: "9861",
    value: "M358",
    label: "PORTE DI RENDENA",
    province: "TN"
  },
  {
    code: "9862",
    value: "M359",
    label: "PRIMIERO SAN MARTINO DI CASTROZZA",
    province: "TN"
  },
  {
    code: "9863",
    value: "M370",
    label: "BORGOMEZZAVALLE",
    province: "VB"
  },
  {
    code: "9864",
    value: "M369",
    label: "ALTO RENO TERME",
    province: "BO"
  },
  {
    code: "9866",
    value: "M374",
    label: "VAL DI ZOLDO",
    province: "BL"
  },
  {
    code: "9867",
    value: "M375",
    label: "ALPAGO",
    province: "BL"
  },
  {
    code: "9868",
    value: "M383",
    label: "ALTA VALLE INTELVI",
    province: "CO"
  },
  {
    code: "9869",
    value: "M380",
    label: "COLLI AL METAURO",
    province: "PU"
  },
  {
    code: "9870",
    value: "M379",
    label: "TERRE ROVERESCHE",
    province: "PU"
  },
  {
    code: "9871",
    value: "M376",
    label: "ABETONE CUTIGLIANO",
    province: "PT"
  },
  {
    code: "9872",
    value: "M381",
    label: "TERRE DEL RENO",
    province: "FE"
  },
  {
    code: "9873",
    value: "M377",
    label: "SAN MARCELLO PITEGLIO",
    province: "PT"
  },
  {
    code: "9874",
    value: "M382",
    label: "VALFORNACE",
    province: "MC"
  },
  {
    code: "9883",
    value: "M389",
    label: "ALTO SERMENZA",
    province: "VC"
  },
  {
    code: "9884",
    value: "M385",
    label: "CASALI DEL MANCO",
    province: "CS"
  },
  {
    code: "9885",
    value: "M394",
    label: "CENTRO VALLE INTELVI",
    province: "CO"
  },
  {
    code: "9886",
    value: "M392",
    label: "LATERINA PERGINE VALDARNO",
    province: "AR"
  },
  {
    code: "9887",
    value: "M398",
    label: "CELLIO CON BREIA",
    province: "VC"
  },
  {
    code: "9888",
    value: "I632",
    label: "SERMIDE E FELONICA",
    province: "MN"
  },
  {
    code: "9889",
    value: "M393",
    label: "CASTELGERUNDO",
    province: "LO"
  },
  {
    code: "9890",
    value: "M384",
    label: "VAL LIONA",
    province: "VI"
  },
  {
    code: "9891",
    value: "M397",
    label: "ALLUVIONI PIOVERA",
    province: "AL"
  },
  {
    code: "9892",
    value: "M387",
    label: "MONTALTO CARPASIO",
    province: "IM"
  },
  {
    code: "9893",
    value: "M395",
    label: "VALVARRONE",
    province: "LC"
  },
  {
    code: "9894",
    value: "M390",
    label: "SAN GIOVANNI DI FASSA",
    province: "TN"
  },
  {
    code: "9895",
    value: "M316",
    label: "MAPPANO",
    province: "TO"
  },
  {
    code: "9896",
    value: "M396",
    label: "BORGO MANTOVANO",
    province: "MN"
  },
  {
    code: "9897",
    value: "M391",
    label: "RIO",
    province: "LI"
  },
  {
    code: "9898",
    value: "M386",
    label: "ALTA VAL TIDONE",
    province: "PC"
  },
  {
    code: "9900",
    value: "M402",
    label: "BORGO VENETO",
    province: "PD"
  },
  {
    code: "9901",
    value: "M400",
    label: "FIUMICELLO VILLA VICENTINA",
    province: "UD"
  },
  {
    code: "9902",
    value: "M399",
    label: "TREPPO LIGOSULLO",
    province: "UD"
  },
  {
    code: "9903",
    value: "M403",
    label: "CORIGLIANO ROSSANO",
    province: "CS"
  },
  {
    code: "9904",
    value: "M401",
    label: "BARBARANO MOSSANO",
    province: "VI"
  },
  {
    code: "9911",
    value: "M417",
    label: "VALDILANA",
    province: "BI"
  },
  {
    code: "9912",
    value: "M424",
    label: "VERMEZZO CON ZELO",
    province: "MI"
  },
  {
    code: "9913",
    value: "M414",
    label: "QUAREGNA CERRETO",
    province: "BI"
  },
  {
    code: "9914",
    value: "M415",
    label: "VALCHIUSA",
    province: "TO"
  },
  {
    code: "9915",
    value: "M426",
    label: "COLCERESA",
    province: "VI"
  },
  {
    code: "9916",
    value: "M407",
    label: "TERRE D ADIGE",
    province: "TN"
  },
  {
    code: "9917",
    value: "M421",
    label: "BORGO VALBELLUNA",
    province: "BL"
  },
  {
    code: "9918",
    value: "M423",
    label: "VALBRENTA",
    province: "VI"
  },
  {
    code: "9919",
    value: "M419",
    label: "COLLI VERDI",
    province: "PV"
  },
  {
    code: "9920",
    value: "M404",
    label: "VALLE CANNOBINA",
    province: "VB"
  },
  {
    code: "9921",
    value: "M405",
    label: "VAL DI CHY",
    province: "TO"
  },
  {
    code: "9922",
    value: "M409",
    label: "TRESIGNANA",
    province: "FE"
  },
  {
    code: "9923",
    value: "M410",
    label: "RIVA DEL PO",
    province: "FE"
  },
  {
    code: "9924",
    value: "M408",
    label: "BARBERINO TAVARNELLE",
    province: "FI"
  },
  {
    code: "9925",
    value: "M412",
    label: "SOLBIATE CON CAGNO",
    province: "CO"
  },
  {
    code: "9926",
    value: "M420",
    label: "LU E CUCCARO MONFERRATO",
    province: "AL"
  },
  {
    code: "9927",
    value: "M406",
    label: "BORGOCARBONARA",
    province: "MN"
  },
  {
    code: "9928",
    value: "M425",
    label: "CADREZZATE CON OSMATE",
    province: "VA"
  },
  {
    code: "9929",
    value: "M422",
    label: "PIEVE DEL GRAPPA",
    province: "TV"
  },
  {
    code: "9930",
    value: "M411",
    label: "SORBOLO MEZZANI",
    province: "PR"
  },
  {
    code: "9931",
    value: "M418",
    label: "PIADENA DRIZZONA",
    province: "CR"
  },
  {
    code: "9932",
    value: "M413",
    label: "SASSOCORVARO AUDITORE",
    province: "PU"
  },
  {
    code: "9933",
    value: "M416",
    label: "GATTICO VERUNO",
    province: "NO"
  },
  {
    code: "9934",
    value: "M427",
    label: "LUSIANA CONCO",
    province: "VI"
  },
  {
    code: "9964",
    value: "M428",
    label: "PRESICCE ACQUARICA",
    province: "LE"
  },
  {
    code: "9966",
    value: "M430",
    label: "NOVELLA",
    province: "TN"
  },
  {
    code: "9967",
    value: "M429",
    label: "BORGO D ANAUNIA",
    province: "TN"
  },
  {
    code: "9968",
    value: "M431",
    label: "VILLE DI FIEMME",
    province: "TN"
  },
  {
    code: "9972",
    value: "H719",
    label: "SALORNO",
    province: "BZ"
  },
  {
    code: "9974",
    value: "H719",
    label: "SALURN AN DER WEINSTR",
    province: "BZ"
  },
  {
    code: "9975",
    value: "H719",
    label: "SALURN AN DER WEINSTRASSE",
    province: "BZ"
  },
  {
    code: "1000956149",
    value: "M432",
    label: "MISILISCEMI",
    province: "TP"
  }
];

const citiesCode = cities.map((city) => city.code);
const citiesName = cities.reduce((acc, city) => {
  if (!acc.includes(city.label)) {
    acc.push(city.label);
  }
  return acc;
}, []);

module.exports = {
  cities,
  citiesCode,
  citiesName
};

const formatDataForReportData = (
  data,
  filters = {},
  errorsList,
  modifiedList
) => {
  const { filteredBySource, search, sourceOfData, chipSelected } = filters;
  const sourceOfDataExists = sourceOfData && sourceOfData !== "allSources";
  let filteredData = [];
  const sourcesOfData = [];
  let sectionsList = [];

  // First we flatten the entirety of subfields
  const recursiveFlat = (arr) =>
    arr
      // For now we don't want unsourced and null fields
      .filter((f) => f !== null && f.source)
      .reduce((acc, field) => {
        const { fields, ...f } = field;

        acc.push(f);
        if (!sourcesOfData.includes(f.source)) sourcesOfData.push(f.source);
        if (fields) acc = [...acc, ...recursiveFlat(fields)];

        return acc;
      }, []);

  const transformedData = data
    .reduce((acc, field) => {
      const { fields, ...f } = field;
      //TODO: Pending modify when we know value for warnings
      f.status =
        errorsList?.find((item) => item?.campo?.nome === f.name) !== undefined
          ? 2
          : 0;
      f.modified =
        modifiedList?.find((item) => item?.campo?.nome === f.name) !== undefined
          ? true
          : false;
      if (f.name) {
        f.name = f.name.replace("_", "");
      }
      if (fields) f.fields = recursiveFlat(fields);

      return [...acc, f];
    }, [])
    .filter((f) => {
      let result = true;

      const searchText = search?.replace(
        /^([a-zA-Z]+)(.*)$/,
        (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase()
      );
      if (search) result = f.name.includes(searchText);
      if (chipSelected) {
        if (chipSelected === "modifiedData")
          result = result && f.modified === true;
        if (chipSelected === "warnings") result = result && f.status === 1;
        if (chipSelected === "withErrors") result = result && f.status === 2;
      }
      // If there is at least one subfield with the source, we want to keep the parent field
      if (sourceOfDataExists) {
        result =
          result && f.fields?.some((item) => item.source === sourceOfData);
      }

      return result;
    });

  // Then depending on the filteredBySource flag we either group by source or by section
  if (filteredBySource) {
    filteredData = transformedData.reduce((acc, field) => {
      field.fields?.forEach((f) => {
        if (!acc[f.source]) acc[f.source] = [];

        if (
          field.value !== "" &&
          field.value !== 0 &&
          field.value !== undefined &&
          field.value !== null
        ) {
          f.destination = field.name;
          acc[f.source].push(f);
        }
      });

      return acc;
    }, {});
    sectionsList = sourceOfDataExists ? [sourceOfData] : sourcesOfData;
  } else {
    filteredData = transformedData.reduce((acc, field) => {
      if (!acc[field.section]) acc[field.section] = [];

      if (
        field.value !== "" &&
        field.value !== 0 &&
        field.value !== undefined &&
        field.value !== null
      ) {
        acc[field.section].push(field);
        field.sources =
          field.fields?.reduce((subAcc, f) => {
            if (!subAcc[f.source]) subAcc[f.source] = [];

            subAcc[f.source].push(f);

            return subAcc;
          }, {}) ?? {};
        delete field.fields;
      }

      return acc;
    }, {});
    sectionsList = Object.keys(filteredData);
  }

  return { filteredData, sourcesOfData, sectionsList };
};

module.exports = {
  formatDataForReportData
};

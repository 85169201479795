import { createElement } from "react";

import { useTranslation } from "@onefront/react-sdk";

import Stack from "@vapor/react-material/Stack";
import Button from "@vapor/react-material/Button";
import Divider from "@vapor/react-material/Divider";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@vapor/react-material/Dialog";
import IconButton from "@vapor/react-material/IconButton";
import DialogTitle from "@vapor/react-material/DialogTitle";
import DialogActions from "@vapor/react-material/DialogActions";

import { useSidebarMenu } from "../providers/MenuProvider";
import { useCompanySettingsContext } from "../providers/CompanySettingsProvider";

export const CompanySettings = () => {
  const { t } = useTranslation();
  const { selectedItem } = useSidebarMenu();
  const { cancel, isDialogOpen, handleOpenDialog } =
    useCompanySettingsContext();

  return (
    <Stack direction="column" alignItems="flex-start" gap="32px">
      <Dialog
        scroll="paper"
        fullWidth
        maxWidth="sm"
        open={isDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {t("confirmDiscardChanges")}
          <IconButton
            color="primary"
            title={t("Close")}
            onClick={handleOpenDialog}
          >
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </DialogTitle>
        <Divider variant="fullWidth" />
        <DialogActions>
          <Button variant="outlined" onClick={handleOpenDialog}>
            {t("close")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleOpenDialog();
              cancel();
            }}
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      {selectedItem && createElement(selectedItem.component)}
    </Stack>
  );
};

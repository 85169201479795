import { forwardRef } from "react";

import { useTranslation } from "@onefront/react-sdk";

import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";

/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"; */

import { formatAmount, formatDate } from "../../../../utils";
import TaxReportPageAccordion from "../TaxReportPageAccordion";

const TaxReportPageVatRefunds = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { year, checksData } = props;

  return (
    <TaxReportPageAccordion
      ref={ref}
      title={t("ivaYearlyRefund")}
      subtitle={t("lastChangeDateTemplate", {
        date: formatDate(checksData?.lastUpdate),
        interpolation: { escapeValue: false }
      })}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="bodyLarge">{t("refundTemporary")}</Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="bodyLarge700">
            {formatAmount(checksData?.VL_20, 2, 2) || " 0,00"}
          </Typography>
          {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Typography variant="bodyLarge">
          {t("VATCredit") + " " + (year - 1) + " " + t("offsetF24")}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="bodyLarge700">
            {formatAmount(checksData?.VL_22, 2, 2) || " 0,00"}
          </Typography>
          {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
        </Stack>
      </Stack>
    </TaxReportPageAccordion>
  );
});

export default TaxReportPageVatRefunds;

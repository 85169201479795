import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Select from "@vapor/react-material/Select";
import MenuItem from "@vapor/react-material/MenuItem";
import { useState } from "react";
import { useTranslation } from "@onefront/react-sdk";
import ChartDateBased from "./ChartDateBased";
import Chips from "./Chips";
import Legend from "./Legend";

const Overview = ({ kpiDueDates, kpiStatuses }) => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState(12);
  const [selectedChip, setSelectedChip] = useState(null);

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Select
          sx={{
            width: "285px",
            marginLeft: "10px !important",
            height: "40px"
          }}
          value={period}
          size="small"
          onChange={handlePeriodChange}
        >
          <MenuItem value={12}>{t("twelveMonths")}</MenuItem>
          <MenuItem value={6}>{t("sixMonths")}</MenuItem>
          <MenuItem value={3}>{t("threeMonths")}</MenuItem>
          <MenuItem value={1}>{t("oneMonth")}</MenuItem>
        </Select>
        <Stack direction="row" spacing={1} alignItems="center">
          <Legend />
          <Typography variant="bodyLarge700" color="#008FD6">
            {t("legend")}
          </Typography>
        </Stack>
      </Stack>
      <Stack pt={3} direction="row" spacing={2}>
        <Chips setSelectedChip={setSelectedChip} />
      </Stack>
      <Stack width="100%">
        <ChartDateBased
          kpiDueDates={kpiDueDates}
          period={period}
          selectedChip={selectedChip}
          kpiStatuses={kpiStatuses}
        />
      </Stack>
    </Box>
  );
};

export default Overview;

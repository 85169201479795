import { useTranslation } from "@onefront/react-sdk";

import { useTheme } from "@vapor/react-material/styles";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle
} from "@fortawesome/pro-solid-svg-icons";

import { useFiscaliSaasIframe } from "../../../../services/fiscali-saas-iframe/FiscaliSaasIframeProvider";

export const ReportDataFieldStatus = ({ row }) => {
  const theme = useTheme();
  const { t } = useTranslation("foa");
  const { errorsList } = useFiscaliSaasIframe();
  const status = (row) => {
    const fiscaliSaasName = row.name.slice(0, 2) + "_" + row.name.slice(2);
    return errorsList?.find((item) => item?.campo?.nome === fiscaliSaasName)
      ? 2
      : 0;
  };
  const typeStatus = {
    0: {
      icon: faCheckCircle,
      color: theme.palette.success.dark,
      label: t("filled")
    },
    1: {
      icon: faExclamationTriangle,
      color: theme.palette.warning.light,
      label: t("withAlerts")
    },
    2: {
      icon: faExclamationCircle,
      color: theme.palette.error.dark,
      label: t("withErrors")
    }
  };

  if (!typeStatus[status(row)]) return null;
  return (
    <>
      <FontAwesomeIcon
        key="status"
        icon={typeStatus[status(row)].icon}
        color={typeStatus[status(row)].color || theme.palette.success.dark}
        size="xl"
        style={{ marginRight: "12px" }}
      />
      <Typography
        variant="body"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {typeStatus[status(row)]?.label}
      </Typography>
    </>
  );
};

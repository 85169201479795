import {
  InterFrameCommService,
  SendDataService
} from "@onefront/interframe-comm";

const CHUNK_MSG_TYPE_NO_ENCODE = "0";
const CHUNK_MESSAGE_FORMAT_EMPTY = "";

export class FiscaliCommService {
  constructor() {
    this._interframeCommService = new InterFrameCommService(
      {
        excludeStartMessages: true,
        target: "*",
        version: "1.0",
        bufferSize: 200000
      },
      new SendDataService()
    );
  }

  sendDataToIframe = (data) =>
    this._interframeCommService?.sendSyncData(
      JSON.stringify(data),
      CHUNK_MSG_TYPE_NO_ENCODE,
      CHUNK_MESSAGE_FORMAT_EMPTY,
      0,
      () => {},
      () => {}
    );

  launchCommand = (command, params) => {
    const body = [
      {
        Command: command,
        ...{
          Params: params,
          ServiceState: 0,
          ServiceError: ""
        }
      }
    ];

    this.sendDataToIframe(body);
  };

  hideLeftBar = () => this.launchCommand("HideLeftBar", { Value: true });
  hideBottomBar = () => this.launchCommand("HideBottomBar", { Value: true });
  getBlob = () => this.launchCommand("GetBlob", "");
  getDataOut = () => this.launchCommand("GetDataOut", "");
  getSheetList = () => this.launchCommand("GetSheetList");
  getErrors = () => this.launchCommand("GetErrori");
  getModified = () => this.launchCommand("GetVariati");
  gotoQuadro = (field) =>
    this.launchCommand("GotoTree", {
      Chiave: field.replace(/\\t/g, "\t")
    });
  gotoCampo = (field) => {
    const value = {
      DatiElemento: {
        nome: field,
        allegato: 0,
        rigo: -1,
        tipo: 7,
        descr: ""
      }
    };
    this.launchCommand("GotoCampo", value);
  };

  cancelAndExit = () => this.launchCommand("CancelDichAndHide");

  openUrl = (webUrl, dichType) => {
    const body = [
      {
        Command: "OpenUrl",
        Fun: "OpenUrl",
        Param: webUrl,
        DichType: dichType,
        ServiceState: 0,
        ServiceError: ""
      }
    ];

    this.sendDataToIframe(body);
  };

  startHandshake = (iframeWindow, subscribeHandleFunction) => {
    this._interframeCommService.startHandshake(iframeWindow);

    this._interframeCommService.slaveMessage$.subscribe((message) =>
      subscribeHandleFunction(message)
    );
  };
}

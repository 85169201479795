import { Companies } from "./components/Companies";

const BASE_PATH = "/companies";

export const companies = () => [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: BASE_PATH,
      element: <Companies />
    }
  }
];

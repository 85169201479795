import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "@onefront/react-sdk";

import List from "@vapor/react-material/List";
import ListItemButton from "@vapor/react-material/ListItemButton";
import ListItemText from "@vapor/react-material/ListItemText";
import Collapse from "@vapor/react-material/Collapse";
import { useTheme } from "@vapor/react-material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight
} from "@fortawesome/pro-solid-svg-icons";

import { useWorkspaceData } from "../providers/WorkspaceDataProvider";
import { useSidebarMenu } from "../providers/MenuProvider";

const MenuItem = ({ item }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { selectedItem, setSelectedItem } = useSidebarMenu();
  const [isOpen, setIsOpen] = useState(false);

  const hasChildren = item.children && item.children.length > 0;
  const isSelected = item.key === selectedItem?.key;

  const handleClick = () => {
    if (hasChildren) setIsOpen(!isOpen);
    else {
      setSelectedItem(item);
    }
  };

  useEffect(() => {
    if (
      item.children &&
      item.children.some((child) => child.key === selectedItem?.key) &&
      !isOpen
    )
      setIsOpen(true);
  }, [selectedItem]);

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          display: "flex",
          gap: "8px",
          alignItems: "center",
          background: isSelected
            ? theme.palette.primary.azureishWhite
            : theme.palette.primary.whiteSmoke,
          "&:hover": {
            background: `${
              isSelected
                ? theme.palette.primary.azureishWhite
                : theme.palette.primary.whiteSmoke
            } !important`
          }
        }}
      >
        {hasChildren && (
          <div
            style={{
              width: "24px",
              height: "24px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <FontAwesomeIcon
              icon={isOpen ? faChevronDown : faChevronRight}
              style={{ padding: "4px" }}
              size="sm"
              color={theme.palette.primary.textTitleColor}
            />
          </div>
        )}
        <ListItemText
          sx={{
            ">.MuiListItemText-primary": {
              fontSize: "16px !important",
              fontFamily: "Cairo !important",
              fontStyle: "normal !important",
              fontWeight: "500 !important"
            }
          }}
        >
          {t(item.translationKey)}
        </ListItemText>
      </ListItemButton>
      {hasChildren > 0 && (
        <Collapse in={isOpen} sx={{ paddingLeft: "32px" }}>
          {item.children.map((subItem) => (
            <MenuItem key={subItem.key} item={subItem} />
          ))}
        </Collapse>
      )}
    </>
  );
};

export const SidebarMenu = () => {
  const theme = useTheme();
  const { taxPayerType } = useWorkspaceData();
  const { menuItemsByTaxPayerType, setSelectedItem } = useSidebarMenu();

  const menuItems = useMemo(() => {
    const items = [];
    if (["PF", "SC", "SP", "IVA"].includes(taxPayerType))
      items.push(...menuItemsByTaxPayerType[taxPayerType]);

    return items;
  }, [taxPayerType]);

  useEffect(() => {
    if (menuItems && menuItems.length)
      setSelectedItem(menuItems[0].children[0]);
  }, [menuItems]);

  return (
    <List sx={{ background: theme.palette.primary.whiteSmoke }}>
      {menuItems.map((item) => (
        <MenuItem key={item.key} item={item} />
      ))}
    </List>
  );
};

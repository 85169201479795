import { useEffect, useRef } from "react";

import { mapDataOutToJson } from "@reviso/fiscali-saas";

import { useTaxReportData } from "../providers/taxReportDataProvider";
import { useFiscaliSaasIframe } from "../../../services/fiscali-saas-iframe/FiscaliSaasIframeProvider";

export const FiscaliSaasIframeWrapper = ({ children }) => {
  const {
    taxReportData,
    setTaxReportData,
    setTaxReportVisibleContent,
    possibleContents,
    taxReportVisibleContent
  } = useTaxReportData();
  const {
    setContainerRef,
    currentDataOut,
    currentSheetList,
    callReset,
    resetFiscaliTaxReport,
    saveFiscaliTaxReport
  } = useFiscaliSaasIframe();
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) setContainerRef(containerRef);

    return () => setContainerRef(null);
  }, []);

  useEffect(() => {
    if (containerRef) {
      taxReportVisibleContent === possibleContents.model
        ? setContainerRef(containerRef)
        : setContainerRef(null);
    }
  }, [taxReportVisibleContent]);

  useEffect(() => {
    (async () => {
      if (currentDataOut || currentSheetList) {
        const newTaxReportData = structuredClone(taxReportData);
        if (currentDataOut[taxReportData.document.fiscaliSaas.DichType]) {
          const newDataOut = mapDataOutToJson(
            currentDataOut[taxReportData.document.fiscaliSaas.DichType]
          );
          newTaxReportData.document.data.fiscaliSaas.dataOut = newDataOut;
          if (currentSheetList) {
            newTaxReportData.document.data.fiscaliSaas.sheetList =
              currentSheetList;
          }
          setTaxReportData(newTaxReportData);
        }
      }
    })();
  }, [currentDataOut]);

  useEffect(() => {
    (async () => {
      if (callReset) {
        setTaxReportVisibleContent(possibleContents.result);
        const fiscaliRes = await resetFiscaliTaxReport(taxReportData);
        if (taxReportData.document.states.taxReport !== "closed") {
          const saveRes = await saveFiscaliTaxReport(fiscaliRes, taxReportData);

          if (saveRes) setTaxReportData(saveRes);
        }
      }
    })();
  }, [callReset]);

  return (
    <>
      {taxReportVisibleContent === possibleContents.model && (
        <div
          id="iframe-container"
          ref={containerRef}
          style={{ height: "100%" }}
        />
      )}
      {children}
    </>
  );
};

import { useTranslation } from "@onefront/react-sdk";

import Typography from "@vapor/react-extended/ExtendedTypography";
import IconButton from "@vapor/react-material/IconButton";
import Stack from "@vapor/react-material/Stack";
import TextField from "@vapor/react-material/TextField";
import DatePicker from "@vapor/react-x-date-pickers/DatePicker";
import { LocalizationProvider } from "@vapor/react-x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@vapor/react-x-date-pickers/AdapterDateFns";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { faEye, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@vapor/react-material/styles";

const titleByStatus = {
  pending: "commitmentLetterStatusPending",
  templateDownloaded: "commitmentLetterStatusTemplateDownloaded",
  commitmentLetterUploaded: "commitmentLetterStatusUploaded"
};

export const TaxReportPageSignaturePanel = ({
  commitmentLetter,
  status,
  deleteCommitmentLetter,
  showCommitmentLetter,
  changeCommitmentDate
}) => {
  const theme = useTheme();
  const { t } = useTranslation("foa");

  return (
    <Stack
      direction="column"
      sx={{ padding: "16px 48px" }}
      justifyContent="center"
      alignItems="flex-start"
      gap="16px"
      alignSelf="stretch"
    >
      <Stack direction="row" gap="4px" alignSelf="stretch" alignItems="center">
        {status === "commitmentLetterUploaded" && (
          <FontAwesomeIcon
            style={{
              fontSize: "24px",
              color: theme.palette.primary.successed
            }}
            icon={faCircleCheck}
          />
        )}
        <Typography variant="titleMedium">
          {t(titleByStatus[status || "pending"])}
        </Typography>
      </Stack>
      <Stack direction="row" gap="3px" alignSelf="stretch" alignItems="center">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            // esm/toDate warning: Starting with v2.0.0-beta.1 date-fns doesn't accept strings as date arguments. Please use `parseISO` to parse strings
            value={new Date(commitmentLetter?.commitmentDate) || ""}
            label={t("commitmentDate")}
            renderInput={(inputProps) => (
              <TextField
                sx={{ width: "150px" }}
                {...inputProps}
                variant="outlined"
              />
            )}
            onChange={changeCommitmentDate}
          />
        </LocalizationProvider>
      </Stack>
      {status === "commitmentLetterUploaded" && (
        <Stack direction="row" alignItems="center" style={{ width: "100%" }}>
          <Stack direction="column" style={{ flex: "1" }}>
            <Typography variant="body">
              {commitmentLetter?.file?.fileName}
            </Typography>
            <Typography
              variant="bodySmall"
              style={{ color: theme.palette.primary.cadet }}
            >
              {new Date(commitmentLetter?.file?.timestamp).toLocaleString()}
            </Typography>
          </Stack>
          <IconButton color="primary" onClick={showCommitmentLetter}>
            <FontAwesomeIcon icon={faEye} />
          </IconButton>
          <IconButton color="primary" onClick={deleteCommitmentLetter}>
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

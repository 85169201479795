import { Link } from "react-router-dom";

import List from "@vapor/react-material/List";
import ListItem from "@vapor/react-material/ListItem";
import ListItemIcon from "@vapor/react-material/ListItemIcon";
import ListItemText from "@vapor/react-material/ListItemText";
import Box from "@vapor/react-material/Box";
import { faHomeLg, faBars, faGear } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@vapor/react-material/styles";
import { useTranslation } from "@onefront/react-sdk";

export const NavContent = ({ basePath }) => {
  const { t } = useTranslation("foa");
  const theme = useTheme();

  return (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        <ListItem button component={Link} to={basePath}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faBars}
              color={theme.palette.background.default}
            />
          </ListItemIcon>
          <ListItemText primary={t("Home")} />
        </ListItem>
        <ListItem button component={Link} to={"/companies"}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faHomeLg}
              color={theme.palette.background.default}
            />
          </ListItemIcon>
          <ListItemText primary={t("Companies")} />
        </ListItem>
        <ListItem button component={Link} to={"/tax-reports"}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faHomeLg}
              color={theme.palette.background.default}
            />
          </ListItemIcon>
          <ListItemText primary={t("TaxReports")} />
        </ListItem>
        <ListItem button component={Link} to={"/actions"}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faGear}
              color={theme.palette.background.default}
            />
          </ListItemIcon>
          <ListItemText primary={t("Actions")} />
        </ListItem>
      </List>
    </Box>
  );
};

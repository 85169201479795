import { useGet, useSnackbar, useTranslation } from "@onefront/react-sdk";
import { useEffect, useState } from "react";

/**
 * Custom React hook to fetch and manage intermediary data.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.intermediaryId - The ID of the intermediary.
 * @param {string} params.tenantId - The ID of the tenant.
 * @returns {Object} The intermediary data and a loading state.
 */
export const useIntermediary = ({ intermediaryId, tenantId }) => {
  const { t } = useTranslation("foa");

  const { enqueueSnackbar } = useSnackbar();
  const [intermediary, setIntermediary] = useState(null);
  const [fullIntermediary, setFullIntermediary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [intermediaryCallStarted, setIntermediaryCallStarted] = useState(false);

  // Fetch the intermediary data
  const {
    data,
    fetch,
    loading: intermediaryLoading
  } = useGet(
    `rnd::saas:tax-orchestrator:oneplatformConnectorApi://intermediary/${tenantId}/intermediary/${intermediaryId}`,
    { lazy: true }
  );

  // Fetch the security environments for the intermediary
  const getSecurityEnvironments = useGet(
    `rnd::saas:tax-orchestrator:oneplatformConnectorApi://intermediary/${tenantId}/intermediary/${intermediaryId}/security-environment`,
    { lazy: true }
  );

  useEffect(() => {
    if (intermediaryId !== null) {
      fetch();
      setIntermediaryCallStarted(true);
    } else {
      setLoading(false);
      enqueueSnackbar(t("intermediaryNotAssigned"), {
        variant: "warning",
        autoHideDuration: 3000
      });
    }
  }, []);

  // When the intermediary data is fetched, update the state and fetch the security environments
  useEffect(() => {
    if (!intermediaryCallStarted) return;
    if (intermediaryLoading) return;
    if (data) {
      setIntermediary(data);
      getSecurityEnvironments.fetch();
    } else {
      setLoading(false);
      //TODO: Add Alert to show that intermediary is not assigned for the selected studio
      enqueueSnackbar(t("intermediaryNotAssigned"), {
        variant: "warning",
        autoHideDuration: 3000
      });
    }
  }, [data, intermediaryCallStarted]);

  // When the security environments are fetched, update the full intermediary data and set loading to false
  useEffect(() => {
    if (getSecurityEnvironments.data) {
      setFullIntermediary({
        ...intermediary,
        securityEnvironments: getSecurityEnvironments.data
      });

      setLoading(false);
    } else {
      setFullIntermediary({});
      setLoading(false);
    }
  }, [getSecurityEnvironments.data]);

  return { fullIntermediary, loading };
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "@vapor/react-material/Alert";
import Snackbar from "@vapor/react-material/Snackbar";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";

const Notification = ({ text, close, isOpen }) => {
  return (
    <>
      <Snackbar
        onClose={close}
        open={isOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top"
        }}
      >
        <Alert variant="outlined" severity="success">
          {text}
          <FontAwesomeIcon
            onClick={close}
            icon={faTimesCircle}
            size="lg"
            style={{ marginRight: "12px", cursor: "pointer", width: "40px" }}
          />
        </Alert>
      </Snackbar>
    </>
  );
};

export default Notification;

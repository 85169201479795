export const mapBusiness = (workspaceBusiness, workspaceData) =>
  workspaceBusiness
    ?.sort((a, b) => (a.type === "MAIN" && b.type === "SECONDARY" ? -1 : 1))
    .map((business) => ({
      classifier: workspaceData?.classifier || "",
      declarantId: workspaceData?.itemId || "",
      companyNameOrSurname:
        workspaceData?.classifier === "PERSON"
          ? workspaceData?.personalData?.surname
          : workspaceData?.personalData?.businessName || "",
      name:
        workspaceData?.classifier === "PERSON"
          ? workspaceData?.personalData?.name
          : "",
      taxCode: workspaceData?.personalData?.taxIdentifier || "",
      vatCode: workspaceData?.personalData?.vatIdentifier || "",
      module: business?.module || "",
      atecoCode: business?.atecoCode || "",
      atecoCodeDescription: business?.atecoCodeDescription || "",
      modelType: business?.modelType || "",
      mainActivityVatCode: workspaceData?.personalData?.vatIdentifier || "",
      vatConfiguration: {
        specialVatRegime: business?.vatConfiguration?.specialVatRegime || "",
        vatPeriodicity: business?.vatConfiguration?.vatPeriodicity || ""
      },
      transformationDate: business?.transformationDate?.split("T")[0] || "",
      startDate: business?.startDate?.split("T")[0] || "",
      endDate: business?.endDate?.split("T")[0] || "",
      periodType: business?.vatConfiguration?.vatPeriodicity || ""
    }));

const optionsDateDefault = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric"
};
const optionsHourDefault = {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
};

export const orderDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = ("0" + (d.getMonth() + 1)).slice(-2);
  const day = ("0" + d.getDate()).slice(-2);
  return day + "/" + month + "/" + year;
};

export const orderDateHour = (date, optionsHourOverride = null) => {
  const dateObject = new Date(date);

  const optionsHour = optionsHourOverride ?? optionsHourDefault;

  const dateFormat = dateObject.toLocaleDateString(
    navigator.language,
    optionsDateDefault
  );
  const hourFormat = dateObject.toLocaleTimeString(
    navigator.language,
    optionsHour
  );

  return { date: dateFormat, hour: hourFormat };
};

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString(
    navigator.language,
    optionsDateDefault
  );
};

export const formatDateTime = (date) => {
  return new Date(date).toLocaleString(navigator.language, {
    ...optionsDateDefault,
    ...optionsHourDefault
  });
};

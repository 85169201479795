import { getColorForTaxReport } from "../../../../utils/get-color-tax-report";

//TODO we have to use this function to check if the data is versamenti or not
function isVersamenti(kpi) {
  return false;
}
export const generateKpiDueDatesData = (kpiDueDates, kpiStatuses) => {
  return kpiDueDates.map((item) => {
    const date = new Date(item.deadline);
    date.setHours(23, 59, 59, 999);

    const data = {
      date: date,
      color: getColorForTaxReport(item.tax_report),
      tax_report: item.tax_report,
      year: item.year
    };

    // versamenti data is not ready yet from telematici
    if (isVersamenti(item)) {
      return { ...data, payments: item.total };
    }

    if (item.period === "") {
      return {
        ...data,
        annualDeclarations: item.total
      };
    }

    if (item.period.startsWith("Q")) {
      return {
        ...data,
        periodicDeclarations: item.total
      };
    }

    const kpiStatusesData = kpiStatuses.find(
      (kpi) => kpi.tax_report === item.tax_report
    );

    return {
      date: date,
      payments: item.total,
      periodicDeclarations: item.total,
      annualDeclarations: item.total,
      color: getColorForTaxReport(item.tax_report),
      tax_report: item.tax_report,
      year: item.year,
      status: kpiStatusesData ? kpiStatusesData.data[item.tax_report] : {}
    };
  });
};

import { useTranslation } from "@onefront/react-sdk";

import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"; */
import Accordion from "@vapor/react-extended/Accordion";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import { useTheme } from "@vapor/react-material/styles";

import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";

export const TaxReportPageLipeSummary = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const theme = useTheme();
  return (
    <Accordion
      defaultExpanded={true}
      sx={{
        width: "100%",
        padding: "32px 0px 0px 24px",
        boxShadow: "none"
      }}
    >
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
        aria-controls="panel1a-content"
      >
        <Typography variant="titleSmall">{t("modelSummary")}</Typography>
        <Typography
          sx={{
            textAlign: "right",
            color: theme.palette.primary.textSubduedColor
          }}
          variant="body"
        >
          {t("checkAndWarningOnQuadri")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" sx={{ gap: "32px" }}>
          {data &&
            data.map((item, index) => (
              <Stack direction="column" key={index} sx={{ gap: "16px" }}>
                <Typography variant="bodyLarge700">{item.months}</Typography>
                {item.items.map((row, index) => (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    key={`row-${index}`}
                  >
                    <Typography variant="bodyLarge">{row.text}</Typography>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography variant="bodyLarge700">
                        {row.amount}
                      </Typography>
                      {/* <FontAwesomeIcon size="sm" icon={faArrowRight} /> */}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

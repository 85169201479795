import declarationTypeOptions from "../../../components/declarationTypes.json";

import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-extended/ExtendedTypography";

const declarationOptionsMap = declarationTypeOptions
  .flatMap((x) => x.options)
  .reduce((objMap, option) => {
    objMap[option.value] = option.label;

    return objMap;
  }, {});

export const TaxReportCell = ({ row }) => {
  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="Body">
        {declarationOptionsMap[row.tax_report]}
      </Typography>
    </Stack>
  );
};

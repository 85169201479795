import React, { useLayoutEffect } from "react";

import Stack from "@vapor/react-material/Stack";

//Chart
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useGetConfig, useTranslation } from "@onefront/react-sdk";

//import { useKpiListContext } from "../../providers/kpi-list-wrapper";

const ChartPie = ({ taxReport, kpiList, year }) => {
  const licenseKey = useGetConfig("am5Chart.license");
  const { t } = useTranslation("foa");

  useLayoutEffect(() => {
    const kpi = kpiList.filter((a) => a.year === year);

    const dataKpi = kpi?.[0]?.data?.[taxReport] || [];

    // Create root and chart
    const root = am5.Root.new("chartdiv-pie");

    // Add amCharts5 license
    am5.addLicense(licenseKey);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalHorizontal
      })
    );

    // Define data
    let data = [
      {
        label: t("sent"),
        value: dataKpi.sent?.total,
        sliceSettings: {
          fill: "#0090D1",
          stroke: "#0090D1"
        }
      },
      {
        label: t("closed"),
        value: dataKpi.closed?.total,
        sliceSettings: {
          fill: "#0EC43F",
          stroke: "#0EC43F"
        }
      },
      {
        label: t("ongoing"),
        value: dataKpi.ongoing?.total,
        sliceSettings: {
          fill: "#75F598",
          stroke: "#75F598"
        }
      },
      {
        label: t("todo"),
        value: dataKpi.todo?.total,
        sliceSettings: {
          fill: "#FED06C",
          stroke: "#FED06C"
        }
      },
      {
        label: t("withAlerts"),
        value: dataKpi.withAlerts?.total,
        sliceSettings: {
          fill: "#FDBF3A",
          stroke: "#FDBF3A"
        }
      },
      {
        label: t("withErrors"),
        value: dataKpi.withErrors?.total,
        sliceSettings: {
          fill: "#E05757",
          stroke: "#E05757"
        }
      },
      {
        label: t("rejected"),
        value: dataKpi.rejected?.total,
        FontFace: "Roboto",
        sliceSettings: {
          fill: "#B12021",
          stroke: "#B12021"
        }
      }
    ];

    // Create series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "value",
        categoryField: "label",
        legendValueText: "{valuePercentTotal.formatNumber('#.p')}"
      })
    );

    series.slices.template.setAll({
      templateField: "sliceSettings"
    });

    series.data.setAll(data);

    // Disabling labels and ticks
    series.labels.template.set("forceHidden", true);
    series.ticks.template.set("forceHidden", true);
    series.slices.template.set("tooltipText", "");

    // Add legend
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerY: am5.percent(50),
        y: am5.percent(50),
        layout: root.verticalLayout
      })
    );

    // set value labels align to right
    legend.valueLabels.template.setAll({ textAlign: "right" });
    // set width and max width of labels
    legend.labels.template.setAll({
      width: 100,
      fontSize: 12
    });
    legend.valueLabels.template.setAll({
      fontSize: 14,
      fill: "#909DA7"
    });

    legend.data.setAll(series.dataItems);
    series.appear(1000, 100);
    chart.appear(1000, 100);
    return () => {
      root.dispose();
    };
  }, [taxReport, kpiList]);

  return (
    <Stack sx={{ width: "700px", height: "300px" }} id="chartdiv-pie"></Stack>
  );
};

export default ChartPie;

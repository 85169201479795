import { Actions } from "./components/Actions";

const BASE_PATH = "/actions";

export const actions = () => [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: BASE_PATH,
      element: <Actions />
    }
  }
];

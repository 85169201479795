import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "@onefront/react-sdk";
import { useTheme } from "@vapor/react-material/styles";
import Box from "@vapor/react-material/Box";
import Typography from "@vapor/react-extended/ExtendedTypography";

import { useTaxReportData } from "../../providers/taxReportDataProvider";
import { useFiscaliSaasIframe } from "../../../../services/fiscali-saas-iframe/FiscaliSaasIframeProvider";
import { useReportPageData } from "../../providers/reportPageDataProvider";

export const ReportDataTableSectionHeader = ({ source }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { setTaxReportVisibleContent, setHeaderMinimized } = useTaxReportData();
  const { gotoQuadro } = useFiscaliSaasIframe();
  const { filters, dataToShow } = useReportPageData();

  const dataGridSection = {
    fontSize: "20px !important",
    color: theme.palette.primary.textTitleColor,
    fontFamily: "Cairo !important",
    overflow: "hidden !important"
  };

  const getSourceQuadro = (source) => {
    return dataToShow.newRows.find((element) =>
      element.name.startsWith(source)
    );
  };
  const gotoSection = (source) => {
    setHeaderMinimized(false);
    setTaxReportVisibleContent("model");
    gotoQuadro(getSourceQuadro(source).treeKey);
  };

  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <Typography sx={dataGridSection} style={{ marginRight: "12px" }}>
        {filters?.filteredBySource ? t(source) : `${t("Quadro")} ${source}`}
      </Typography>
      {!filters?.filteredBySource && (
        <FontAwesomeIcon
          onClick={() => gotoSection(source)}
          icon={faArrowRight}
          color={theme.palette.primary.primary60Color}
          style={{
            cursor: "pointer",
            size: "16px",
            height: 20,
            width: 14
          }}
        />
      )}
    </Box>
  );
};

import { Fragment } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { Divider } from "@vapor/react-material/Divider";
import Stack from "@vapor/react-material/Stack";
import Button from "@vapor/react-material/Button";

import { useBrowserWindowSize } from "../../../../hooks/useBrowserWindowSize";
import { useTaxReportData } from "../../providers/taxReportDataProvider";

export const TaxReportPageChecksStack = ({ checksSections }) => {
  const { height } = useBrowserWindowSize();
  const { t } = useTranslation();
  const { setTaxReportVisibleContent, possibleContents, setHeaderMinimized } =
    useTaxReportData();

  const listenToScroll = () => {
    const winScroll = document.getElementById(
      "checks-stack-scrollable"
    ).scrollTop;
    winScroll > 0 ? setHeaderMinimized(true) : setHeaderMinimized(false);
  };

  return (
    <Stack
      id="checks-stack-scrollable"
      direction="column"
      onScroll={listenToScroll}
      sx={{
        paddingTop: "32px",
        alignItems: "flex-start",
        flexGrow: "1",
        maxHeight: `${height - 341}px`,
        overflowY: "auto"
      }}
    >
      {checksSections.map((link, index) => (
        <Fragment key={index}>
          <link.component ref={link.ref} {...link.props} />
          <Divider light />
        </Fragment>
      ))}
      <Stack
        direction="column"
        sx={{
          marginTop: "27px",
          alignItems: "flex-end",
          width: "100%"
        }}
      >
        <Button
          onClick={() => setTaxReportVisibleContent(possibleContents.result)}
        >
          {t("goToResult")}
        </Button>
      </Stack>
    </Stack>
  );
};

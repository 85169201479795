import { useState, useEffect } from "react";

const getBrowserWindowSize = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

export const useBrowserWindowSize = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getBrowserWindowSize()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getBrowserWindowSize());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

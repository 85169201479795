import { useQuery } from "@onefront/react-sdk";

const GET_USER_CONFIG = `query GetUserConfig{
    user_config_public_entries {
      config
    }}`;

export const useGetUserConfig = ({ tenantId }) =>
  useQuery(
    GET_USER_CONFIG,
    {},
    {
      headers: {
        "tenant-id": tenantId
      }
    }
  );

import Typography from "@vapor/react-extended/ExtendedTypography";
import LinearProgress from "@vapor/react-material/LinearProgress";
import Stack from "@vapor/react-material/Stack";
import React, { useEffect, useState } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { useKpiListContext } from "../../providers/kpi-list-wrapper";

import PropTypes from "prop-types";

const ChartBar = ({ year }) => {
  const { t } = useTranslation();
  const { kpiList } = useKpiListContext();

  const [kpi, setKpi] = useState([]);

  useEffect(() => {
    setKpi(kpiList.filter((kpi) => kpi.year === year));
  }, [kpiList, year]);

  const taxReport = [
    {
      tax: "IVN",
      color: "#EC9798"
    },
    {
      tax: "IRAP",
      color: "#8087E2"
    },
    {
      tax: "WPF",
      color: "#8827A6"
    },
    {
      tax: "WSP",
      color: "#E06681"
    },
    {
      tax: "WSC",
      color: "#32979D"
    }
  ];

  return (
    <Stack spacing={3}>
      {taxReport.map((tax) => (
        <Stack direction="column" key={tax.tax}>
          <Stack direction="row" justifyContent="space-between">
            <Typography>{t(tax.tax)}</Typography>
            <Typography>
              {kpi?.[0]?.data?.[tax.tax]?.sent?.total || 0} {t("of")}{" "}
              {kpi?.[0]?.data?.[tax.tax]?.total.total || 0}
            </Typography>
          </Stack>
          <LinearProgress
            sx={{
              "& .MuiLinearProgress-bar": {
                background: tax.color
              },
              height: "4px",
              borderRadius: "2px",
              backgroundColor: "#C9D0D4"
            }}
            variant="determinate"
            value={
              isNaN(kpi?.[0]?.data?.[tax.tax]?.sent) ||
              isNaN(kpi?.[0]?.data?.[tax.tax]?.total) ||
              kpi?.[0]?.data?.[tax.tax]?.total === 0 ||
              kpi?.[0]?.data?.[tax.tax]?.sent === 0
                ? 0
                : (kpi?.[0]?.data?.[tax.tax]?.sent /
                    kpi?.[0]?.data?.[tax.tax]?.total) *
                  100
            }
          />
        </Stack>
      ))}
    </Stack>
  );
};

ChartBar.propTypes = {
  year: PropTypes.number
};

export default ChartBar;

import Box from "@vapor/react-material/Box";
import Skeleton from "@vapor/react-material/Skeleton";
import Stack from "@vapor/react-material/Stack";

const DetailsSidebarSkeleton = () => {
  return (
    <Stack direction={"column"}>
      <Box width="100%">
        <Skeleton variant="text" width="23%" height={30} animation={"wave"} />
      </Box>

      <Box width="100%">
        <Skeleton width="80%" height={67} animation={"wave"} />
      </Box>
      <Box width="100%">
        <Stack direction="column">
          <Skeleton width="70%" animation={"wave"} />
          <Skeleton width="70%" animation={"wave"} />
        </Stack>
      </Box>
    </Stack>
  );
};

export default DetailsSidebarSkeleton;

export const taxReportNameMapping = {
  "LIPE I": "LP I",
  "LIPE II": "LP II",
  "LIPE III": "LP III",
  "LIPE IV": "LP IV",
  IVN: "IVA",
  "IVATR I": "ITR I",
  "IVATR II": "ITR II",
  "IVATR III": "ITR III",
  INTRA: "INT",
  INTENTO: "DI",
  IVA74: "74B",
  IMU: "IMU",
  SANITARIE: "Sp.S",
  FUNEBRI: "Sp.F",
  770: "770",
  UNICA: "CU",
  UNICOMODELLO:
    "COMUNICAZIONI PER I REGIMI DI TONNAGE TAX, CONSOLIDATO, TRASPARENZA E PER L’OPZIONE IRAP(unico modello)",
  CUPE: "CUP",
  WPF: "PF",
  WSP: "SP",
  WSC: "SC",
  WNC: "NC",
  CNM: "ENC",
  IRAP: "IRAP",
  GTI: "IS"
};

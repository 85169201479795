import { createElement } from "react";
import { constants } from "@fiscal-orchestrator/fior-utils";
import { WSP_V1 } from "./WSP_V1";
import { DEFAULT_PAGE } from "../DEFAULT_PAGE";

const modelPerYearMapping = {
  2023: WSP_V1,
  2024: WSP_V1
};

const tabs = {
  contents: { result: "result", model: "model" },
  firstContent: "result"
};

export const WSP = {
  match: (taxReport) => {
    return taxReport === constants.taxReportTypes.redditiPersonSociety;
  },
  render: ({ year }) => {
    const componentToRender = modelPerYearMapping[year] || DEFAULT_PAGE;

    return createElement(componentToRender, { tabs: tabs });
  }
};

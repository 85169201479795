import { useTranslation } from "@onefront/react-sdk";

import { useTheme } from "@vapor/react-material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import Stack from "@vapor/react-material/Stack";

export const TaxReportPageQuadriBoxErrors = ({ error, warnings }) => {
  const { t } = useTranslation("foa");
  const theme = useTheme();

  return (
    <>
      <Stack spacing={2} direction="row" sx={{ flex: "1" }}>
        <Box
          sx={{
            border: "solid 1px #004666",
            padding: "8px 16px 16px",
            width: "210px",
            height: "88px"
          }}
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ display: "flex", alignItems: "baseline" }}
          >
            <Stack
              direction="row"
              spacing="4px"
              sx={{ display: "flex", alignItems: "baseline" }}
            >
              <FontAwesomeIcon
                icon={faExclamationCircle}
                color={theme.palette.error.dark}
                size="xs"
              />
              <Typography color={theme.palette.primary.yankeesBlue}>
                {t("blockingErrors")}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography variant="titleLarge">{error}</Typography>
          </Stack>
        </Box>

        <Stack>
          <Box
            sx={{
              border: "solid 1px #004666",
              padding: "8px 16px 16px",
              width: "210px",
              height: "88px"
            }}
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ display: "flex", alignItems: "baseline" }}
            >
              <Stack
                direction="row"
                spacing="4px"
                sx={{ display: "flex", alignItems: "baseline" }}
              >
                <FontAwesomeIcon
                  key="warning"
                  icon={faTriangleExclamation}
                  color={theme.palette.warning.light}
                  size="xs"
                />
                <Typography
                  variant="bodyLarge"
                  color={theme.palette.primary.yankeesBlue}
                >
                  {t("warnings")}
                </Typography>
              </Stack>
            </Stack>
            <Stack>
              <Typography variant="titleLarge">{warnings}</Typography>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

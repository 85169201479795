import { useTranslation } from "@onefront/react-sdk";

import Stack from "@vapor/react-material/Stack";
import List from "@vapor/react-material/List";
import ListItemButton from "@vapor/react-material/ListItemButton";
import Typography from "@vapor/react-extended/ExtendedTypography";

export const TaxReportPageAccordionMenu = ({ links }) => {
  const { t } = useTranslation();

  const handleLinkClick = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
      block: "nearest"
    });
  };

  return (
    <Stack
      direction="column"
      sx={{
        alignItems: "flex-start",
        gap: "5px",
        flexShrink: "0"
      }}
      style={{ marginRight: "78px" }}
    >
      <Typography variant="titleSmall" color="primary.main">
        {t("checks")}
      </Typography>
      <List>
        {links &&
          links.length &&
          links.map((link) => (
            <ListItemButton key={link.text} sx={{ padding: "10px 16px" }}>
              <Typography
                variant="body"
                color="primary.textTitleColor"
                onClick={() => handleLinkClick(link.ref)}
              >
                {link.text}
              </Typography>
            </ListItemButton>
          ))}
      </List>
    </Stack>
  );
};

import React, { useState, useEffect, useMemo } from "react";
import { LicenseInfo } from "@mui/x-license-pro";

import { useTranslation, useGetConfig } from "@onefront/react-sdk";

import { GridToolbarContainer } from "@mui/x-data-grid";
import DataGridPro from "@vapor/react-x-data-grid/DataGridPro";
import Stack from "@vapor/react-material/Stack";
/* import Button from "@vapor/react-material/Button"; */
import Typography from "@vapor/react-extended/ExtendedTypography";
import Accordion from "@vapor/react-extended/Accordion";
import AccordionSummary from "@vapor/react-material/AccordionSummary";
import AccordionDetails from "@vapor/react-material/AccordionDetails";
import { useTheme } from "@vapor/react-material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import {
  faExclamationCircle,
  faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";

import { TaxReportPageQuadriStatus } from "./Quadri/TaxReportPageQuadriStatus";
import { TaxReportPageQuadriGroupColumnRender } from "./Quadri/TaxReportPageQuadriGroupColumnRender";
import { TaxReportPageQuadriBoxErrors } from "./Quadri/TaxReportPageQuadriBoxErrors";
import { convertSheetListStringToTableRows } from "../../helpers/convert-sheet-list-string-to-table-rows";
import { useFiscaliSaasIframe } from "../../../../services/fiscali-saas-iframe/FiscaliSaasIframeProvider";
import { useTaxReportData } from "../../providers/taxReportDataProvider";

export const TaxReportPageQuadri = ({ sheetList }) => {
  const { t } = useTranslation("foa");
  const theme = useTheme();
  const { setTaxReportVisibleContent, setHeaderMinimized } = useTaxReportData();
  const { numberOfErrors, gotoQuadro } = useFiscaliSaasIframe();
  const licenseKey = useGetConfig("dataGrid.licenseKey");
  LicenseInfo.setLicenseKey(licenseKey);

  const [warningQuadri, setWarningQuadri] = useState(0);
  const [rows, setRows] = useState([]);
  const dataToShow = useMemo(
    () => convertSheetListStringToTableRows(sheetList),
    [sheetList]
  );

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const hour = dateString.substring(8, 10);
    const minutes = dateString.substring(10, 12);
    const seconds = dateString.substring(12, 14);
    const date = new Date(year, +month + 1, day, hour, minutes, seconds);

    return date.toLocaleString();
  };

  const gotoSection = (item) => {
    setHeaderMinimized(false);
    setTaxReportVisibleContent("model");
    gotoQuadro(item.row.treeKey);
  };

  useEffect(() => {
    if (dataToShow) {
      setRows(dataToShow.newRows);
    }
  }, [dataToShow]);

  const columns = [
    {
      field: "status",
      headerName: t("Status"),
      flex: 1,
      maxWidth: 200,
      renderCell: TaxReportPageQuadriStatus
    },
    {
      field: "userAutoCalc",
      headerName: t("user"),
      flex: 1,
      maxWidth: 200
    },
    { field: "lastYearStatus", headerName: t("previousYear"), maxWidth: 150 },
    {
      field: "dataCalc",
      headerName: t("lastChange"),
      filterable: false,
      width: 200,
      valueFormatter: (params) => formatDate(params.value)
    },
    {
      field: "actions",
      type: "actions",
      width: 150,
      renderCell: (params) => {
        return (
          <FontAwesomeIcon
            onClick={() => gotoSection(params)}
            icon={faArrowRight}
            color={theme.palette.primary.primary60Color}
            size="xl"
            style={{ cursor: "pointer" }}
          />
        );
      }
    }
  ];

  return (
    <Accordion
      defaultExpanded={true}
      sx={{
        width: "100%",
        padding: "32px 0px 0px 24px",
        boxShadow: "none"
      }}
    >
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faAngleDown} />}>
        <Typography variant="titleSmall">Quadri</Typography>
        {(numberOfErrors > 0 || warningQuadri > 0) && (
          <Stack direction="row" alignContent="center" alignItems="center">
            <FontAwesomeIcon
              icon={
                numberOfErrors ? faExclamationCircle : faTriangleExclamation
              }
              color={
                numberOfErrors
                  ? theme.palette.error.dark
                  : theme.palette.warning.light
              }
              size="xl"
              style={{ marginRight: "12px" }}
            />
            <Typography variant="body">
              {t(
                numberOfErrors
                  ? "declarationWithErrors"
                  : "declarationWithWarnings"
              )}
            </Typography>
          </Stack>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          justifyContent="end"
          alignItems="flex-end"
          alignSelf="stretch"
          flexDirection="row"
        >
          {(numberOfErrors >= 0 || warningQuadri > 0) && (
            <TaxReportPageQuadriBoxErrors
              error={numberOfErrors}
              warnings={warningQuadri}
            />
          )}
        </Stack>
        <Stack sx={{ marginBlock: "32px" }}>
          <DataGridPro
            treeData
            rows={rows}
            columns={columns}
            getTreeDataPath={(row) => row.path}
            getRowId={(row) => row.name}
            autoHeight
            defaultGroupingExpansionDepth={3}
            disableColumnMenu
            hideFooter
            disableSelectionOnClick={true}
            disableColumnSelector={true}
            disableColumnResize={true}
            groupingColDef={{
              headerName: "Quadro",
              flex: 1,
              renderCell: TaxReportPageQuadriGroupColumnRender
            }}
            slots={{
              toolbar: () => (
                <GridToolbarContainer
                  sx={{
                    "&.MuiDataGrid-toolbarContainer": {
                      justifyContent: "space-between"
                    }
                  }}
                ></GridToolbarContainer>
              )
            }}
            onRowClick={gotoSection}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

import { forwardRef } from "react";
import { useHostPage } from "@onefront/react-sdk";

export const EmbedIFrame = forwardRef(
  ({ src, scope = "*", events = {}, params = {}, style, ...props }, ref) => {
    const host = useHostPage(src, () => ({
      scope,
      params,
      events
    }));

    return (
      <iframe
        {...props}
        style={style}
        src={host.url}
        ref={ref}
        id="fiscali-saas-iframe"
        title="FiscaliSaasIFrame"
      />
    );
  }
);

import { useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { onChangeHandlerField } from "../../../handlers";
import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import { ExtendedToggle } from "../../ExtendedToggle";

export const TenantSCSpecialRegimes = () => {
  const { t } = useTranslation();
  const {
    sotCompanySettings,
    companySettings,
    setCompanySettings,
    isEditMode,
    setIsEditMode,
    loading,
    setAnyChange
  } = useCompanySettingsContext();

  const onChangeHandler = (e) => {
    setAnyChange(true);
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  return (
    <CompanySettingsGrid>
      <ExtendedToggle
        name="f24ModelPublicEntities"
        value={companySettings.f24ModelPublicEntities}
        onChange={onChangeHandler}
        label={t("f24ModelPublicEntities")}
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
    </CompanySettingsGrid>
  );
};

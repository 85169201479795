import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSnackbar } from "@onefront/react-sdk";
import { useTranslation } from "@onefront/react-sdk";

import { useTheme } from "@vapor/react-material/styles";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";
import Divider from "@vapor/react-material/Divider";
import IconButton from "@vapor/react-material/IconButton";
import Popover from "@vapor/react-material/Popover";
import List from "@vapor/react-material/List";
import ListItemButton from "@vapor/react-material/ListItemButton";
import ListItemText from "@vapor/react-material/ListItemText";
import Skeleton from "@vapor/react-material/Skeleton";

import {
  faEllipsisVertical,
  faArrowLeft,
  faExternalLink,
  faAngleRight,
  faAngleLeft
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTaxReportData } from "../providers/taxReportDataProvider";
import { orderDateHour } from "../../../utils";
import { useGetDocument } from "../../../hooks/useGetDocument";
import { generatePdf } from "../../../utils";
import TaxPayerAvatar from "../../components/TaxPayerAvatar";
import { useFiscaliSaasIframe } from "../../../services/fiscali-saas-iframe/FiscaliSaasIframeProvider";
import { TaxReportChangesNotSavedDialog } from "./TaxReportChangesNotSavedDialog";

export const TaxReportPageHeader = ({ workspaceUrl, setShowDeleteModal }) => {
  const theme = useTheme();
  const { t } = useTranslation("foa");
  const navigate = useNavigate();
  const {
    taxReportData,
    setTaxReportData,
    setTaxReportVisibleContent,
    possibleContents,
    headerMinimized,
    setHeaderMinimized
  } = useTaxReportData();
  const {
    data: dataPdf,
    fetch: fetchPdf,
    error: errorPdf
  } = useGetDocument({
    pdfId: taxReportData?.document?.output?.files?.pdf
  });
  const {
    callWebKill,
    storeTaxReportChanges,
    scrollTop: iframeScrollTop
  } = useFiscaliSaasIframe();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [taxReportLastUpdateText, setTaxReportLastUpdateText] = useState("");
  const [isNotSavedChangesDialogOpen, setIsNotSavedChangesDialogOpen] =
    useState(false);

  const open = Boolean(anchorEl);
  const sxAvatar = {
    backgroundColor: "primary.background",
    color: "primary.textTitleColor",
    border: "1px solid black",
    marginRight: "8px"
  };

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const onCloseSaveChangesDialog = () => setIsNotSavedChangesDialogOpen(false);

  const onSaveChanges = async () => {
    setIsNotSavedChangesDialogOpen(false);
    const newTaxReportData = await storeTaxReportChanges(taxReportData);
    if (newTaxReportData) setTaxReportData(newTaxReportData);
    setTaxReportVisibleContent(possibleContents.checks);
    setHeaderMinimized(false);
    navigate("/tax-reports");
  };

  const onExitWithoutSaving = async () => {
    setIsNotSavedChangesDialogOpen(false);
    await callWebKill(taxReportData);
    setTaxReportVisibleContent(possibleContents.checks);
    setHeaderMinimized(false);
    navigate("/tax-reports");
  };

  useEffect(() => {
    if (taxReportData) {
      const lastUpdateDate = new Date(taxReportData.updated_at);
      const actualDay = new Date().getDay();
      const dt = orderDateHour(lastUpdateDate, {
        hour: "2-digit",
        minute: "2-digit"
      });

      if (lastUpdateDate.getDay() === actualDay) {
        setTaxReportLastUpdateText(`${t("todayAt")}: ${dt.hour}`);
      }
      if (lastUpdateDate.getDay() === actualDay - 1) {
        setTaxReportLastUpdateText(`${t("yesterdayAt")}: ${dt.hour}`);
      }
      if (lastUpdateDate.getDay() < actualDay - 1) {
        setTaxReportLastUpdateText(`${dt.date}, ${dt.hour}`);
      }
    } else {
      setTaxReportLastUpdateText("");
    }
  }, [taxReportData]);

  // Open the pdf in a new tab
  useEffect(() => {
    if (dataPdf?.content) {
      try {
        window.open(generatePdf(dataPdf.content), "_blank");
      } catch (error) {
        console.log({ error });
      }
    }
  }, [dataPdf]);

  useEffect(() => {
    if (errorPdf) {
      enqueueSnackbar(errorPdf, {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  }, [errorPdf]);

  useEffect(() => {
    setHeaderMinimized(iframeScrollTop > 0);
  }, [iframeScrollTop]);

  return (
    <Stack sx={{ height: "auto" }}>
      <TaxReportChangesNotSavedDialog
        open={isNotSavedChangesDialogOpen}
        onCloseDialog={onCloseSaveChangesDialog}
        onClickOk={onSaveChanges}
        onClickExit={onExitWithoutSaving}
      />
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            padding: "8px 0"
          }}
        >
          <IconButton
            color="primary"
            size="small"
            onClick={async () => {
              setIsNotSavedChangesDialogOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
          {taxReportData ? (
            <TaxPayerAvatar
              taxPayerType={taxReportData?.document?.taxpayerType}
              sx={
                headerMinimized
                  ? {
                      ...sxAvatar,
                      height: "33px",
                      width: "33px"
                    }
                  : {
                      ...sxAvatar,
                      height: "48px",
                      width: "48px"
                    }
              }
            />
          ) : (
            <Skeleton
              variant="circular"
              width="48px"
              height="48px"
              sx={{ marginRight: "8px" }}
              animation="wave"
            />
          )}
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            flex="1 0 0"
          >
            <Stack
              direction="row"
              alignSelf="stretch"
              alignItems="center"
              gap="8px"
            >
              {taxReportData ? (
                <Typography
                  variant={headerMinimized ? "titleSmall" : "titleMedium"}
                >
                  {taxReportData?.document?.input?.workspaces?.companyData
                    ?.personalData?.businessName ||
                    taxReportData?.document?.input?.workspaces?.companyData
                      ?.personalData?.name}
                </Typography>
              ) : (
                <Skeleton variant="text" width="15%" animation="wave" />
              )}
              <IconButton
                size="small"
                onClick={() => {
                  window.location.href =
                    workspaceUrl +
                    "/details/" +
                    taxReportData?.document?.company?.codAzienda +
                    "/registry";
                }}
              >
                <FontAwesomeIcon
                  sx={{ height: "20px", width: "20px" }}
                  icon={faExternalLink}
                  title={t("OpenWorkspaces")}
                />
              </IconButton>
            </Stack>
            {!headerMinimized && (
              <Stack
                direction="row"
                alignSelf="stretch"
                alignItems="center"
                gap="24px"
              >
                {taxReportData ? (
                  <>
                    <Typography variant="bodySmall500">
                      {`${t("taxCodeAbr")} ${
                        taxReportData?.document?.input?.workspaces?.companyData
                          ?.personalData?.taxIdentifier || ""
                      }`}
                    </Typography>

                    <Typography variant="bodySmall500">
                      {`${t("partitaIVAAbr")} ${
                        taxReportData?.document?.input?.workspaces?.companyData
                          ?.personalData?.vatIdentifier || ""
                      }`}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Skeleton variant="text" width="10%" animation="wave" />
                    <Skeleton variant="text" width="10%" animation="wave" />
                  </>
                )}
              </Stack>
            )}
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack
              direction="row"
              alignItems="center"
              paddingRight="0px"
              gap="24px"
            >
              {taxReportData ? (
                <Stack direction="row">
                  <Typography
                    width="101px"
                    height="15px"
                    color={theme.palette.primary.yankeesBlue}
                    variant="body"
                    sx={{ textWrap: "nowrap" }}
                  >
                    {t("lastChange")}:
                  </Typography>
                  <Typography
                    sx={{ marginLeft: "3px" }}
                    width="101px"
                    height="15px"
                    color={theme.palette.primary.yankeesBlue}
                    variant="body700"
                  >
                    {taxReportLastUpdateText}
                  </Typography>
                </Stack>
              ) : (
                <Skeleton variant="text" width="150px" animation="wave" />
              )}
              <Stack
                direction="row"
                style={{
                  padding: "2px 8px 2px 7px",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <IconButton size="small" disabled={true}>
                  <FontAwesomeIcon
                    style={{ fontSize: "24px" }}
                    icon={faAngleLeft}
                    color={theme.palette.primary.textHintColor}
                  />
                </IconButton>
                <IconButton size="small" disabled={true}>
                  <FontAwesomeIcon
                    style={{ fontSize: "24px", lineHeight: "20px" }}
                    icon={faAngleRight}
                    color={theme.palette.primary.textHintColor}
                  />
                </IconButton>
                {taxReportData ? (
                  <Typography
                    id="year"
                    sx={{ fontSize: headerMinimized ? "16px" : "24px" }}
                    variant={headerMinimized ? "LeadMedium" : "BodyLarge"}
                    color={theme.palette.primary.interactiveDefault}
                  >
                    {taxReportData?.document?.year}
                  </Typography>
                ) : (
                  <Skeleton variant="text" width="54px" animation="wave" />
                )}
              </Stack>
              <IconButton size="small" onClick={handleClick}>
                <FontAwesomeIcon
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "20px"
                  }}
                  icon={faEllipsisVertical}
                  color={theme.palette.primary.interactiveDefault}
                />
              </IconButton>
              <Popover
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
              >
                <List
                  sx={{
                    width: "210px",
                    padding: "1px 1px",
                    alignItems: "center"
                  }}
                >
                  <ListItemButton sx={{ padding: "10px 16px" }}>
                    <ListItemText primary={t("Export")} />
                  </ListItemButton>
                  <ListItemButton
                    disabled={!taxReportData?.document?.output?.files?.pdf}
                    onClick={fetchPdf}
                  >
                    <ListItemText primary={t("pdfPreview")} />
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText
                      primary={t("companySettings")}
                      disabled={!taxReportData?.taxpayer_id}
                      onClick={() => {
                        navigate(`/company/${taxReportData.taxpayer_id}`);
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    disabled={
                      taxReportData?.document?.states?.taxReport === "sent"
                    }
                  >
                    <ListItemText
                      primary={t("Delete")}
                      onClick={() => {
                        setShowDeleteModal(true);
                      }}
                    />
                  </ListItemButton>
                </List>
              </Popover>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
      </Stack>
    </Stack>
  );
};

import Stack from "@vapor/react-material/Stack";
import Box from "@vapor/react-material/Box";
import Popover from "@vapor/react-material/Popover";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
  faCircle,
  faSquare,
  faDiamond
} from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "@onefront/react-sdk";
import { useState } from "react";

const Legend = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const DEPOSITS_ONLY = "depositsOnly";
  const PERIODIC_DECLARATIONS = "periodicDeclarations";
  const ANNUAL_DECLARATIONS = "annualDeclarations";

  const chips = [DEPOSITS_ONLY, PERIODIC_DECLARATIONS, ANNUAL_DECLARATIONS];

  const taxReportColor = [
    { name: `${t("IVA")}`, color: "#FC4E3D" },
    { name: `${t("lipe1to4")}`, color: "#E2AB37" },
    { name: `${t("ivaTR")}`, color: "#FF387A" },
    { name: `${t("DI")}`, color: "#A4F9BB" },
    { name: `${t("74BIS")}`, color: "#0E6C80" },
    { name: `${t("INT")}`, color: "#7745EC" },
    { name: `${t("SF")}`, color: "#C53090" },
    { name: `${t("Sp.S")}`, color: "#FFD1E0" },
    { name: `${t("CU")}`, color: "#5170FF" },
    { name: `${t("770")}`, color: "#CC05FF" },
    { name: `${t("RED")}`, color: "#349D84" },
    { name: `${t("IMU")}`, color: "#85D9FF" },
    { name: `${t("CUP")}`, color: "#A3D47D" },
    { name: `${t("IS")}`, color: "#E288C2" },
    {
      name: `${t("unicoModelloLegend")}`,
      color: "#943101"
    }
  ];

  const chipsIcons = [faDiamond, faSquare, faCircle];

  return (
    <>
      <Typography color="#008FD6" fontSize="22px" onClick={handleClick}>
        <FontAwesomeIcon
          fontSize="22px"
          icon={faCircleInfo}
          onClick={handleOpen}
        />
      </Typography>
      <Popover
        id="legend-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Stack width="340px">
          <Box
            style={{
              backgroundColor: "white",
              padding: "8px 12px 8px 8px"
            }}
          >
            <Stack>
              <Typography
                variant="bodyLarge700"
                color="primary.main"
                sx={{ pb: "3px" }}
              >
                {t("legend")}
              </Typography>{" "}
              <Typography
                variant="bodySmall"
                color="primary.main"
                sx={{ height: "120px" }}
                marginBottom="15px"
              >
                {t("legendDescription")}
              </Typography>
            </Stack>{" "}
            <Stack sx={{ pb: "15px", gap: "6px" }}>
              <Typography
                variant="subtitle"
                color="primary.main"
                sx={{ pb: "3px" }}
              >
                {t("simboli")}
              </Typography>
              {chips.map((chip, index) => (
                <Typography
                  key={chip}
                  color="primary.main"
                  sx={{
                    gap: "5px",
                    display: "flex",
                    justifyContent: "row",
                    alignItems: "center"
                  }}
                >
                  <FontAwesomeIcon
                    style={{ width: "20px", height: "20px" }}
                    icon={chipsIcons[index]}
                    color="#808080"
                  />
                  <Typography
                    color="primary.main"
                    variant="bodySmall"
                    sx={{ marginLeft: "5px" }}
                  >
                    {t(chip)}
                  </Typography>
                </Typography>
              ))}
            </Stack>
            <Stack>
              <Typography
                marginBottom="5px"
                variant="subtitle"
                color="primary.main"
              >
                {t("colori")}
              </Typography>
              {taxReportColor.map((item) => (
                <Stack
                  key={item.name}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px"
                  }}
                >
                  <Typography>
                    <FontAwesomeIcon icon={faSquare} color={item.color} />
                  </Typography>
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color="primary.main"
                    variant="bodySmall"
                  >
                    {item.name}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Popover>
    </>
  );
};

export default Legend;

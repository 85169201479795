const regions = [
  {
    value: "1",
    label: "PIEMONTE"
  },
  {
    value: "2",
    label: "VALLE D AOSTA"
  },
  {
    value: "3",
    label: "LOMBARDIA"
  },
  {
    value: "4",
    label: "TRENTINO ALTO ADIGE"
  },
  {
    value: "5",
    label: "VENETO"
  },
  {
    value: "6",
    label: "FRIULI VENEZIA GIULIA"
  },
  {
    value: "7",
    label: "LIGURIA"
  },
  {
    value: "8",
    label: "EMILIA ROMAGNA"
  },
  {
    value: "9",
    label: "TOSCANA"
  },
  {
    value: "10",
    label: "UMBRIA"
  },
  {
    value: "11",
    label: "MARCHE"
  },
  {
    value: "12",
    label: "LAZIO"
  },
  {
    value: "13",
    label: "ABRUZZO"
  },
  {
    value: "14",
    label: "MOLISE"
  },
  {
    value: "15",
    label: "CAMPANIA"
  },
  {
    value: "16",
    label: "PUGLIA"
  },
  {
    value: "17",
    label: "BASILICATA"
  },
  {
    value: "18",
    label: "CALABRIA"
  },
  {
    value: "19",
    label: "SICILIA"
  },
  {
    value: "20",
    label: "SARDEGNA"
  }
];

const regionsCode = regions.map((x) => x.value);
const regionsName = regions.map((x) => x.label);

module.exports = {
  regions,
  regionsCode,
  regionsName
};

import { useTranslation } from "@onefront/react-sdk";

import { useTheme } from "@vapor/react-material/styles";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle
} from "@fortawesome/pro-solid-svg-icons";

export const TaxReportPageQuadriStatus = ({ row }) => {
  const theme = useTheme();
  const { t } = useTranslation("foa");

  const status = (row) => {
    return row.status;
  };

  const typeStatus = {
    3: {
      icon: faCheckCircle,
      color: theme.palette.success.dark,
      label: t("filled")
    },
    4: {
      icon: faExclamationTriangle,
      color: theme.palette.warning.light,
      label: t("withAlerts")
    },
    5: {
      icon: faExclamationCircle,
      color: theme.palette.error.dark,
      label: t("withErrors")
    }
  };

  if (!typeStatus[status(row)]) return null;
  return (
    <>
      <FontAwesomeIcon
        key="status"
        icon={typeStatus[status(row)].icon}
        color={typeStatus[status(row)].color || theme.palette.success.dark}
        size="xl"
        style={{ marginRight: "12px" }}
      />
      <Typography
        variant="body"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {typeStatus[status(row)]?.label}
      </Typography>
    </>
  );
};

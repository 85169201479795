import { constants } from "@fiscal-orchestrator/fior-utils";

export default [
  { value: constants.taxReportStatuses.TO_DO, label: 'Es "Da Gestire"' },
  { value: constants.taxReportStatuses.ON_GOING, label: 'Es "In lavorazione"' },
  { value: constants.taxReportStatuses.WITH_ERRORS, label: 'Es "Con errori"' },
  {
    value: constants.taxReportStatuses.WITH_ALERTS,
    label: 'Es "Con avvertimenti"'
  },
  { value: constants.taxReportStatuses.CLOSED, label: 'Es "Chiusa"' },
  { value: constants.taxReportStatuses.GENERATED, label: 'Es "Generata"' },
  { value: constants.taxReportStatuses.SENT, label: 'Es "Inviata"' },
  { value: constants.taxReportStatuses.REJECTED, label: 'Es "Scartata"' },
  { value: constants.taxReportStatuses.ARCHIVED, label: 'Es "Archiviata"' }
];

import { useEffect } from "react";

import { useTranslation } from "@onefront/react-sdk";

import { ExtendedSelect } from "@onefront/workspace-forms/components/General/ExtendedSelect/ExtendedSelect.js";
import { ExtendedMenuItem } from "@onefront/workspace-forms/components/General/ExtendedMenuItem/ExtendedMenuItem.js";

import { CompanySettingsGrid } from "../../CompanySettingsGrid";
import { useCompanySettingsContext } from "../../../providers/CompanySettingsProvider";
import { ExtendedToggle } from "../../ExtendedToggle";
import { onChangeHandlerField } from "../../../handlers";

export const TenantPFGeneralSettings = () => {
  const { t } = useTranslation();
  const {
    sotCompanySettings,
    companySettings,
    setCompanySettings,
    isEditMode,
    setIsEditMode,
    loading,
    setAnyChange
  } = useCompanySettingsContext();

  const onChangeHandler = (e) => {
    onChangeHandlerField({
      target: e.target,
      context: companySettings,
      setContext: setCompanySettings
    });
  };

  useEffect(() => {
    if (sotCompanySettings && Object.keys(sotCompanySettings).length !== 0)
      setCompanySettings(sotCompanySettings);
    setIsEditMode(false);
    setAnyChange(false);
  }, []);

  return (
    <CompanySettingsGrid>
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.exemptSeizedAssets}
        label={t("exemptSeizedAssets")}
        name="exemptSeizedAssets"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedSelect
        label={t("imuDeduction")}
        name="imuDeduction"
        value={companySettings.imuDeduction}
        onChange={onChangeHandler}
        editing={isEditMode}
        loading={loading}
      >
        <ExtendedMenuItem
          label={t("otherDeductions1")}
          value="otherDeductions1"
        >
          {t("otherDeductions1")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("otherDeductions2")}
          value="otherDeductions2"
        >
          {t("otherDeductions2")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("otherDeductions3")}
          value="otherDeductions3"
        >
          {t("otherDeductions3")}
        </ExtendedMenuItem>
        <ExtendedMenuItem
          label={t("otherDeductions4")}
          value="otherDeductions4"
        >
          {t("otherDeductions4")}
        </ExtendedMenuItem>
      </ExtendedSelect>
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.paymentPresentationOrdinaryDeadlines}
        label={t("paymentPresentationOrdinaryDeadlines")}
        name="paymentPresentationOrdinaryDeadlines"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
      <ExtendedToggle
        onChange={onChangeHandler}
        value={companySettings.businessFrameworkOnly}
        label={t("businessFrameworkOnly")}
        name="businessFrameworkOnly"
        editing={isEditMode}
        noValueLabel={t("notSet")}
        loading={loading}
        positiveLabel={t("checkboxPositive")}
        negativeLabel={t("checkboxNegative")}
      />
    </CompanySettingsGrid>
  );
};

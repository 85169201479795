import { useTranslation, useProxy, useGetConfig } from "@onefront/react-sdk";

import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import { useEffect } from "react";

import BlockInfo from "./components/BlockInfo";
import DeclarationStatus from "./components/Graphics/DeclarationStatus";
import SendingStatus from "./components/Graphics/SendingStatus";

import TaxReportListWidget from "./components/TaxReportListWidget/TaxReportListWidget";
import GoToWidget from "./components/GoToWidget";

import Overview from "./components/Overview-Widget/Overview";

import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetKpiStatuses } from "./hooks/useGetKpiStatuses";
import { useGetWidgetGoTo } from "./hooks/useGetWidgetGoTo";

import { useKpiListContext } from "./providers/kpi-list-wrapper";
import { useWidgetGoToContext } from "./providers/widget-go-to-wrapper";
import { useUserConfigContext } from "./providers/user-config-wrapper";
import { useKpiDueDatesContext } from "./providers/kpi-due-dates-wrapper";

import { constants } from "@fiscal-orchestrator/fior-utils";
import { useGetKpiDueDates } from "./hooks/useGetKpiDueDates";

export const Dashboard = () => {
  const { tenant } = useCurrentTenant(true);
  const { kpiList, setKpiList } = useKpiListContext();
  const { widgetGoToList, setWidgetGoToList } = useWidgetGoToContext();
  const { kpiDueDates, setKpiDueDates } = useKpiDueDatesContext();
  const { userConfig } = useUserConfigContext();

  const { createJumpUrl } = useProxy();
  const appId = useGetConfig("one.login.delegatedToken.appId");

  const { taxReportGroups } = constants;

  const { data, fetch } = useGetKpiStatuses({
    tenantId: tenant?.itemId
  });

  const { data: kpiDueDatesData, fetch: fetchKpiDueDates } = useGetKpiDueDates({
    tenantId: tenant?.itemId
  });

  const taxReportsGoTo = userConfig?.widgetGoTo?.taxReports?.reduce(
    (acc, index) => {
      taxReportGroups[index].map((group) => acc.push(group));
      return acc;
    },
    []
  );

  const { data: dataWidgetGoTo, fetch: fechWidgetGoTo } = useGetWidgetGoTo({
    limit: userConfig?.widgetGoTo?.limit,
    taxReports: taxReportsGoTo,
    tenantId: tenant?.itemId
  });

  useEffect(() => {
    if (userConfig?.widgetGoTo?.taxReports) {
      const taxReportsGoTo = userConfig?.widgetGoTo?.taxReports?.reduce(
        (acc, index) => {
          taxReportGroups[index].map((group) => acc.push(group));
          return acc;
        },
        []
      );

      if (taxReportsGoTo?.length > 0)
        fechWidgetGoTo({
          limit: userConfig?.widgetGoTo?.limit,
          tax_reports: taxReportsGoTo
        });
    }
  }, [userConfig]);

  useEffect(() => {
    if (tenant) {
      fetch();
    }
  }, [tenant]);

  useEffect(() => {
    if (data) setKpiList(data?.result);
  }, [data]);

  useEffect(() => {
    if (dataWidgetGoTo)
      setWidgetGoToList(dataWidgetGoTo?.tax_compliance_public_entries);
  }, [dataWidgetGoTo]);

  useEffect(() => {
    if (tenant) {
      fetchKpiDueDates();
    }
  }, [tenant]);

  useEffect(() => {
    if (kpiDueDatesData) {
      setKpiDueDates(kpiDueDatesData?.tax_reports_public_kpi_due_dates);
    }
  }, [kpiDueDatesData]);

  const { t } = useTranslation("foa");

  return (
    <Box
      sx={{
        overflowY: "scroll",
        flex: "1",
        height: "calc(100vh - 51px)",
        minHeight: "calc(100vh - 51px)"
      }}
    >
      <Stack alignItems="center" pt={3}>
        <Button
          onClick={() => {
            window.location.href = createJumpUrl(
              `loginApp://?mode=redirect&appId=${appId}`
            );
          }}
        >
          Go to other app
        </Button>
      </Stack>
      <Stack sx={{ margin: "16px" }}>
        <Stack
          justifyContent="flex-start"
          direction="row"
          spacing={2}
          sx={{ margin: "4px 4px 16px" }}
        >
          <BlockInfo title={t("overview")} Breakpoint={1080}>
            <Overview kpiDueDates={kpiDueDates} kpiStatuses={kpiList} />
          </BlockInfo>
          <BlockInfo title={t("goTo")} Breakpoint={330} padding={0}>
            <GoToWidget widgetGoToList={widgetGoToList} />
          </BlockInfo>
        </Stack>
        <Stack
          justifyContent="flex-start"
          direction="row"
          spacing={2}
          sx={{ margin: "4px 4px 16px" }}
        >
          <BlockInfo
            title={t("TaxReportsList")}
            Breakpoint={1080}
            foot={t("gotoFullList")}
          >
            <TaxReportListWidget />
          </BlockInfo>
        </Stack>
        <Stack>
          <Stack
            justifyContent="flex-start"
            direction="row"
            spacing={2}
            sx={{ marginLeft: "4px" }}
          >
            <BlockInfo title={t("SendingStatus")} Breakpoint={524}>
              <SendingStatus />
            </BlockInfo>

            <BlockInfo title={t("DeclarationStatus")} Breakpoint={540}>
              <DeclarationStatus kpiList={kpiList} />
            </BlockInfo>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

import { useTranslation } from "@onefront/react-sdk";

import Typography from "@vapor/react-extended/ExtendedTypography";
import { useTheme } from "@vapor/react-material/styles";
import VaporTag from "@vapor/react-custom/VaporTag";
import Stack from "@vapor/react-material/Stack";
import Tabs from "@vapor/react-material/Tabs";
import Tab from "@vapor/react-material/Tab";
import Box from "@vapor/react-material/Box";
import Skeleton from "@vapor/react-material/Skeleton";

import { TaxReportStatus } from "../../tax-reports-list/components/tax-reports-list-table/TaxReportStatus";
import { useTaxReportData } from "../providers/taxReportDataProvider";
import { useFiscaliSaasIframe } from "../../../services/fiscali-saas-iframe/FiscaliSaasIframeProvider";
import declarationTypeOptions from "../../components/declarationTypes.json";

const declarationOptionsMap = declarationTypeOptions
  .flatMap((x) => x.options)
  .reduce((objMap, option) => {
    objMap[option.value] = option.label;

    return objMap;
  }, {});

export const TaxReportPageSubheader = ({ style }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    taxReportData,
    taxReportVisibleContent,
    setTaxReportVisibleContent,
    possibleContents,
    taxReportStatus,
    headerMinimized
  } = useTaxReportData();
  const { getIframeData, fiscaliRequestPending } = useFiscaliSaasIframe();

  const setSelectedButton = (button) => {
    setTaxReportVisibleContent(button);
    if (button !== "model") {
      getIframeData();
    }
  };

  return (
    <Box
      sx={
        headerMinimized
          ? {
              boxShadow: "0px 0px 4px 0px",
              marginBottom: "1px"
            }
          : {}
      }
    >
      <Box
        sx={
          !headerMinimized
            ? {
                boxShadow: "none",
                borderBottom: "1px solid #BBC3C9",
                marginBottom: "1px"
              }
            : {}
        }
      >
        <Stack
          direction="column"
          sx={{ padding: headerMinimized ? "0px" : "4px 0" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            padding="0px 24px"
            sx={{
              ...style
            }}
          >
            {/* The variant="secondary" value causes a console error due to a vapor bug */}
            <Tabs
              sx={headerMinimized && { display: "none" }}
              variant="secondary"
              size="small"
              value={taxReportVisibleContent}
            >
              {Object.keys(possibleContents).map((button) => (
                <Tab
                  key={button}
                  value={button}
                  label={t(button)}
                  disabled={
                    fiscaliRequestPending && button !== taxReportVisibleContent
                  }
                  onClick={() => setSelectedButton(button)}
                />
              ))}
            </Tabs>
          </Stack>
        </Stack>
      </Box>
      {taxReportData ? (
        <Stack
          direction="row"
          alignItems="center"
          padding="8px 0px 8px 36px"
          gap="16px"
          flex="1 0 0"
        >
          <Typography
            color={theme.palette.primary.yankeesBlue}
            fontSize="16px"
            fontWeight="500"
            lineHeight="24px"
            marginRight="8px"
          >
            {declarationOptionsMap[taxReportData?.document?.taxReport]}
          </Typography>
          {/* TO DO: count the number of modules */}
          {/* ANSWER: Should be done through the company settings (modulesManagement) */}
          <Stack direction="row" alignItems="center">
            <TaxReportStatus
              row={{
                status:
                  taxReportData?.document?.states?.taxReport ?? taxReportStatus
              }}
              header={true}
            />
          </Stack>
          <VaporTag
            label={`${
              taxReportData?.document?.type
                ? t(taxReportData?.document?.type).toLocaleUpperCase()
                : ""
            }`}
            variant="standard"
            type="blueSapphire"
          />
        </Stack>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          padding="8px 0px 8px 36px"
          gap="16px"
          flex="1 0 0"
        >
          <Skeleton variant="text" width="5%" animation="wave" />
          <Skeleton variant="text" width="7%" animation="wave" />
          <Skeleton variant="text" width="6%" animation="wave" />
        </Stack>
      )}
    </Box>
  );
};
